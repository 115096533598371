import React from 'react'
import Input from '../../../UIComponents/Input/Input'
import './BloodSugar.css'
import {TestReportRightSection} from './TestReportRightSection'
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea'
import ReportFooter from './ReportFooter'
import { saveReports } from '../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field, updateReports} from '../../../redux/actions/Diagnostics/diagnostics'

const BloodSugar = () => {
  const dispatch =  useDispatch()
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e,i) => {
    console.log("indexvaluesss",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'BLOOD SUGAR & URINE SUGAR/KETONES')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'BLOOD SUGAR & URINE SUGAR/KETONES'){
        index = i
      }
      return item
    })

    const finalFilteredData = filterData[0]?.fields?.map((item,index) => {
      if(item?.particular === name && index === i){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  
  
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))
  }

  return (
    <div>
        <div className='blood-parent'>
            <div className='fasting-blood'>
                <div className='blood'>
                      <div className='col1 pad'>Fasting Blood Sugar</div>
                      <div className='col2 pad'>Sugar Value</div>
                      <Input className='col3 input-blood' type="numer" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} name="Sugar Value" onChange={(e)=>onInputChange(e,0)}/>
                      <div className='col4 pad'>mg/dl</div>  
                      <div className='col5 pad'>60-70</div>
                      <div className="col11"> </div>
                      <span className='line-bottom'></span>
                      <div className='col12 pad' onClick={() => onLabelClicked("Urine Sugar")} >Urine Sugar</div>
                      <div className='col13'><TextAreaAnt name="Urine Sugar"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}  onChange={(e)=>onInputChange(e,1)} /></div>
                      <span className='line-bottom-two'></span>
                      <div className='col14 pad' onClick={() => onLabelClicked("Urine Ketones")} >Urine Ketones</div>
                      <div className='col15 '><TextAreaAnt name="Urine Ketones"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,2)} /></div>
                      <span className='line-bottom-three'></span>
                      <div className='col16 pad' onClick={() => onLabelClicked("Hours After")}>Hours After</div>
                      <div className='col17 '><TextAreaAnt name="Hours After" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,3)}  /></div>
                      <span className='line-bottom-four'></span>
                      <div className='col18 pad'>Fasting Blood Sugar</div>
                      <div className='col19 pad'>Sugar Value</div>
                      <div className='col20' > <Input className="input-blood" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,4)} type="number" name="Sugar Value" /></div>
                      <div className='col21 pad'>mg/dl</div>  
                      <div className='col22 pad'>60-70</div>
                      <div className="col23"> </div>
                      <span className='line-bottom-five'></span>
                      <div className='col24 pad' onClick={() => onLabelClicked("Urine Sugar")} >Urine Sugar</div>
                      <div className='col25 '><TextAreaAnt name="Urine Sugar" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,5)}   /></div>
                      <span className='line-bottom-six'></span>
                      <div className='col26 pad' onClick={() => onLabelClicked("Urine Ketones")} >Urine Ketones</div>
                      <div className='col27 '><TextAreaAnt name="Urine Ketones" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,6)}  /></div>
                      <span className='line-bottom-seven'></span>
                  </div>
            </div>
        <div className='dictation-blood'>
          <TestReportRightSection />
        </div>
      </div>
      <div className='blood-footer'>
             <ReportFooter className="diet-comment" />
       </div>
      <div className='blood-method'>
          <h5>Method: Glucose Oxidase Peroxidase (GOD/POD)</h5>
          <p>Urine Sugar Interpretation:</p>
          <p>Trace:</p>
          <p>0.1 g/dl</p>
          <p>+:0.25g/dl</p>
          <p>++:0.5 g/dl</p>
          <p>+++ 1.0 g/dl</p>
          <p>++++2.0 g/dl</p>
      </div>
    </div>
  )
}

export default BloodSugar;