import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
const ViewVaccine = () => {
    const patientVaccine = useSelector(state => state.opdrecord.patientvaccine.patientVaccine)
    const [vaccineData, setVaccineData] = useState()


    useEffect(() => {
        const arr = ['Birth', '6 weeks', '10 weeks', '14 weeks', '6 months', '9 months', '9-12 months', '12 months', '15 months', '16 to 18 months', '18 months', '2 years', '4 to 6 years', '10 to 12 years']
        const resultArr = [];
        for (let i = 0; i < arr.length; i++) {
            let vaccArr = arr[i]
            console.log("resultArr", resultArr)
            console.log("arr[i]", vaccArr)
            let resultArray = patientVaccine.filter(item => item.agegrp === vaccArr)
            console.log("resultArray", resultArray)
            resultArr.push({ ageType: vaccArr, resultArray })
        }
        setVaccineData(resultArr)
        console.log("resultArr", resultArr)
    }, [patientVaccine])
    return (
        <div>
            <div className="vaccine-main-section">
                <div className="vaccine-title">
                    <h3>VACCINE</h3>
                </div>
                {vaccineData?.map((data, i) => (
                    <>
                        <div className="bcg-entire-section" >
                            <div className="vaccine-birth-title">
                                <h3>{data.ageType}</h3>
                            </div>
                            <div className="vaccine-input-section">
                                {data.resultArray.map(item => (
                                    <div className="bcggg-sec">
                                        <div className="bcg-input">
                                            <div className="bcg-title-input">
                                                <span style={{padding:"2px 0px 0px 0px"}}>
                                                    {item.admindate &&
                                                        <input className="vaccine-input1" type="checkbox"
                                                            checked={item.admindate ? true : false}
                                                        />}
                                                </span>
                                                <p><span className="vaccine-name-heading">{item.vaccine}</span></p>
                                            </div>
                                            <div className="bcg-delete-edit-btn-section">
                                                <p>{item.admindate}</p>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                ))}

            </div>
        </div >
    )
}

export default ViewVaccine
