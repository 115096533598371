import React from 'react'
import Input from '../../../UIComponents/Input/Input'
import './DietPlan.css'
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from './TestReportRightSection'
import ReportFooter from './ReportFooter'
import { saveReports } from '../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import {field, updateReports } from '../../../redux/actions/Diagnostics/diagnostics'
import { useState } from 'react'

 const DietPlan = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'DIET PLAN')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item.test === 'DIET PLAN'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
    const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value})) 
      }
  return (
    <div>
        <div className='diet'>
            <div className='diet-left'>
                <div className='diet-plan'>
                    <div className='diet1'></div>
                    <div className='diet2 diet-pad'>WEIGHT</div>
                    <div className='diet3'><Input type='number' name='WEIGHT' onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} className="input-diet" /></div>
                    <div className='diet4 diet-pad'>kg</div>
                    <div className='diet5'></div>
                    <span className='diet-line1'></span>
                    <div className='diet6'></div>
                    <div className='diet7 diet-pad'>HEIGHT</div>
                    <div className='diet8'><Input type='number' name='HEIGHT' onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} className="input-diet" /></div>
                    <div className='diet9 diet-pad'> m</div>
                    <div className='diet10'></div>
                    <span className='diet-line2'></span>
                    <div className='diet11'></div>
                    <div className='diet12 diet-pad'>BMI</div>
                    <div className='diet13'><Input type='number' name='BMI' onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} className="input-diet" /></div>
                    <div className='diet14 diet-pad'>cu micron</div>
                    <div className='diet15 diet-pad'> 18.5-25</div>
                    <span className='diet-line3'></span>
                    <div className='dite16 diet-pad' onClick={() => onLabelClicked("BEFORE BREAKFAST PLAN")} >BEFORE BREAKFAST PLAN</div>
                    <div className='diet17'> <TextAreaAnt name='BEFORE BREAKFAST PLAN'  value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='diet-line4'></span>
                    <div className='dite18 diet-pad' onClick={() => onLabelClicked("BEFORE BREAKFAST NOTE")} >BEFORE BREAKFAST NOTE</div>
                    <div className='diet19'> <TextAreaAnt name='BEFORE BREAKFAST NOTE'  value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='diet-line5'></span>
                    <div className='dite20 diet-pad' onClick={() => onLabelClicked(" BREAKFAST PLAN")} > BREAKFAST PLAN</div>
                    <div className='diet21'> <TextAreaAnt name='BREAKFAST PLAN'  value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='diet-line6'></span>
                    <div className='dite22 diet-pad' onClick={() => onLabelClicked("BREAKFAST NOTE")} > BREAKFAST NOTE</div>
                    <div className='diet23'> <TextAreaAnt name='BREAKFAST NOTE'  value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='diet-line7'></span>
                </div>
            </div>
            <div className='discription-rig'>
                <TestReportRightSection />
            </div>
        </div>
       <div className='diet-footer'>
             <ReportFooter className="diet-comment" />
       </div>
    </div>
  )
}

export default DietPlan
