import React from "react";
import "./MT.css";
import TextAreaAnt from "../../../../UIComponents/TextArea/TextArea";
import { TestReportRightSection } from "../TestReportRightSection";
import ReportFooter from "../ReportFooter";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field,updateReports} from '../../../../redux/actions/Diagnostics/diagnostics'

export const MT = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)  
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      setInputValue(value)
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'MT (MONTOUX INTRADERMAL TUBERCULIN TEST)')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'MT (MONTOUX INTRADERMAL TUBERCULIN TEST)'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))    }
  return (
    <div>
      <div className="MT-parent">
        <div className="MT-left">
          <div className="MT-grid">
            <div className="MT-1 MT-pad" onClick={() => onLabelClicked("DOSAGE")}>DOSAGE</div>
            <div className="MT-2 MT-text">
              <TextAreaAnt name="DOSAGE" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/>
            </div>
            <span className="MT-border"></span>
            <div className=" MT-pad" onClick={() => onLabelClicked("MONTOUX")}>MONTOUX</div>
            <div className=" MT-text">
              <TextAreaAnt name="MONTOUX" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active}/>
            </div>
            <span className="MT-border1"></span>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="MT-foot">
        <ReportFooter />
      </div>
      <div className="MT-method">
        <p>
          <b>INTERPRETATION:</b>
        </p>
        <p>
          POSITIVE: INDURATION 8 ´ 10 MM IN DIAMETER
          <br />
          DOUBTFUL: INDURATION BETWEEN 5 TO 9 MM IN DIAMETER
          <br />
          NEGATIVE: INDURATION 5 MM IN DIAMETER
        </p>

        <br />
        <p>
          <b>TECHNIQUE OF MANTOUX TEST :</b>
        </p>
        <p>
          MANTOUX TEST IS CARRIED OUT BY INTRADERMAL INJECTION OF 0.1 ML OF PPD
          CONTAINING 10 TUBERCULIN UNITS ON THE VOLAR ASPECT OF LEFT FOREARM
          THIS TEST IS FOR IN VIVO DETECTION OF SENSITISATION BY MYCOBACTERIUM
          TUBERCULOSIS.HENCE A POSITIVE TEST DOSE NOT INDICATE THE PRESENCE OF
          ACTIVE DISEASE.
        </p>
        <p>
          THE REACTIVITY TO TUBERCULIN MAY BE DEPRESSED OR SUPPRESSED FOR AS
          LONG AS FOUR WEEKS BY VIRAL INFECTION, LIVE VIRUS VACCINES, OR IF THE
          PATIENT IS ON CORTICOSTEROIDS,OR MALNOURISHED.PLEASE INTERPRET
          ACCORDINGLY.
        </p>
      </div>
    </div>
  );
};
