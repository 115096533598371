import React from 'react'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const Phosphorus = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      setInputValue(value)
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'PHOSPHORUS')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'PHOSPHORUS'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
        <div className='iron-parent'>
            <div className='iron-left'>
                <div className='iron-grid'>
                    <div className='iron1 h-pad'></div>
                    <div className='iron2 h-pad'>SERUM PHOSPHORUS</div>
                    <div className='iron3'><Input type='number' className={'iron-input'} name="SERUM PHOSPHORUS" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active}/></div>
                    <div className='iron4 h-pad'> mg/dl</div>
                    <div className='iron5 h-pad'>2.5-5.0</div>
                    <div className='iron6 h-pad'></div>
                    <span className='iron-border'></span>
                </div>
            </div>
                <div>
                    <TestReportRightSection />
                </div>
        </div>
       
        <div className='iron-foot'>
              <ReportFooter />
        </div>
        
    </div>
  )
}
