import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

// -----------call summary-----------
const callSummaryLoading = () => ({
  type: types.CALL_SUMMARY_LOADING,
});

const callSummarySuccess = (getPatient) => ({
  type: types.CALL_SUMMARY_SUCCESS,
  payload: getPatient,
});

const callSummaryError = (error) => ({
  type: types.CALL_SUMMARY_FAIL,
  payload: error,
});

// -----------------call log-----------------------
const callLogLoading = () => ({
  type: types.CALL_LOG_LOADING,
});

const callLogSuccess = (getPatient) => ({
  type: types.CALL_LOG_SUCCESS,
  payload: getPatient,
});

const callLogError = (error) => ({
  type: types.CALL_LOG_FAIL,
  payload: error,
});

// ---------------------call-made-today-------------
const callMadeTodayLoading = () => ({
  type: types.CALL_MADE_TODAY_LOADING,
});

const callMadeTodaySuccess = (data) => ({
  type: types.CALL_MADE_TODAY_SUCCESS,
  payload: data,
});

const callMadeTodayError = (error) => ({
  type: types.CALL_MADE_TODAY_FAIL,
  payload: error,
});

// ---------------------call-history-------------
const callHistoryLoading = () => ({
  type: types.CALL_HISTORY_LOADING,
});

const callHistorySuccess = (getPatient) => ({
  type: types.CALL_HISTORY_SUCCESS,
  payload: getPatient,
});

const callHistoryError = (error) => ({
  type: types.CALL_HISTORY_FAIL,
  payload: error,
});

// ---------------------call-delete-------------
const callDeleteLoading = () => ({
  type: types.CALL_DELETE_LOADING,
});

const callDeleteSuccess = (getPatient) => ({
  type: types.CALL_DELETE_SUCCESS,
  payload: getPatient,
});

const callDeleteError = (error) => ({
  type: types.CALL_DELETE_FAIL,
  payload: error,
});

// ---------------------call-plan-------------
const callPlanLoading = () => ({
  type: types.CALL_PLAN_LOADING,
});

const callPlanSuccess = (getPatient) => ({
  type: types.CALL_PLAN_SUCCESS,
  payload: getPatient,
});

const callPlanError = (error) => ({
  type: types.CALL_PLAN_FAIL,
  payload: error,
});

// -----------------call summary------------------
export const callSummary = (data) => {
  return (dispatch) => {
    dispatch(callSummaryLoading());

    client
      .post(
        `/doctor/call-summary`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(callSummarySuccess(resp.data));
        // toast.success("Patient fetched Successfully")
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(callSummaryError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// ----------------------call log---------------------------
export const callLog = (data) => {
  console.log("GDFHYJK", data);
  return (dispatch) => {
    dispatch(callLogLoading());

    client
      .post(
        `/doctor/call-log`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(callLogSuccess(resp.data));
        toast.success("New Patient added Successfully");
        dispatch(callMadeToday({ doctorIds: data.doctorIds }));
        console.log("kjaha", data.drname);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(callLogError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// ----------------call made today----------------------
export const callMadeToday = (data) => {
  return (dispatch) => {
    dispatch(callMadeTodayLoading());

    client
      .post(
        `/doctor/call-made-today`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(callMadeTodaySuccess(resp.data));
        // toast.success("Patient fetched Successfully")
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(callMadeTodayError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// ----------------------call plan---------------------------
export const callPlan = (data) => {
  return (dispatch) => {
    dispatch(callPlanLoading());

    client
      .post(
        `/doctor/call-plan`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(callPlanSuccess(resp.data));
        // toast.success("Patient fetched Successfully")
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(callPlanError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// ----------------------call history---------------------------
export const callHistory = (data) => {
  return (dispatch) => {
    dispatch(callHistoryLoading());

    client
      .post(
        `/doctor/call-history`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(callHistorySuccess(resp.data));
        // toast.success("Patient fetched Successfully")
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(callHistoryError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// ----------------------call delete---------------------------
export const callDelete = (data) => {
  return (dispatch) => {
    dispatch(callDeleteLoading());

    client
      .post(
        `/doctor/call-delete`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(callDeleteSuccess(resp.data));
        // toast.success(resp.data.message)
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(callDeleteError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};
