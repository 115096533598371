import React from 'react'
import './Sputum.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const Sputum = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)  
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e,i) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'SPUTUM AFB')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'SPUTUM AFB'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map((item,index) => {
      if(item.particular === name && index === i){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))      }
  return (
    <div>
        
    <div className='sputum-parent'>
      <div className='sputum-left'>
          <div className='sputum-grid'>
              <div className='sputum1 sputum-pad' onClick={() => onLabelClicked("SPUTUM A.F.B 1st DAY")}>SPUTUM A.F.B 1st DAY</div>
              <div className='sputum2 sputum-text'><TextAreaAnt name="SPUTUM A.F.B 1st DAY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,0)}  /></div>
              <span className='sputum-border'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked("ACID FAST BACILLI [A.F.B]")}>ACID FAST BACILLI [A.F.B]</div>
              <div className='sputum-text '><TextAreaAnt  name="ACID FAST BACILLI [A.F.B]" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,1)} /></div>
              <span className='sputum-border2'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked("SPUTUM A.F.B 2nd DAY")}>SPUTUM A.F.B 2nd DAY</div>
              <div className='sputum-text'><TextAreaAnt  name="SPUTUM A.F.B 2nd DAY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,2)} /></div>
              <span className='sputum-border3'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked("ACID FAST BACILLI [A.F.B]")}>ACID FAST BACILLI [A.F.B]</div>
              <div className='sputum-text '><TextAreaAnt name="ACID FAST BACILLI [A.F.B]" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,3)}  /></div>
              <span className='sputum-border4'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked("SPUTUM A.F.B 3rd DAY")}>SPUTUM A.F.B 3rd DAY</div>
              <div className='sputum-text '><TextAreaAnt name="SPUTUM A.F.B 3rd DAY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,4)}  /></div>
              <span className='sputum-border5'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked("ACID FAST BACILLI [A.F.B]")}>ACID FAST BACILLI [A.F.B]</div>
              <div className='sputum-text '><TextAreaAnt  name="ACID FAST BACILLI [A.F.B]" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,5)} /></div>
              <span className='sputum-border6'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked("REMARKS")}>REMARKS</div>
              <div className='sputum-text '><TextAreaAnt  name="REMARKS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={(e)=>onInputChange(e,6)}  /></div>
              <span className='sputum-border7'></span>
          </div>
      </div>
      <div>
          <TestReportRightSection />
      </div>
    </div>
    <div className='sputum-foot'>
        <ReportFooter />
    </div>
    <div className='sputum-method'>
      <h4>Method:</h4>
      <p>ZIEHL NEELSON STAIN FOR ACID FAST BACILLI</p>
      <h4>NOTE :</h4>
      <p>INVESTIGATION HAVE THEIR LIMITATIONS.PATHOLOGICAL/RADIOLOGICAL & OTHER INVESTIGATION NEVER CONFIRM THE FINAL DIAGNOSIS OF THER DISEASE. THEY ONLY HELP IN DIAGNOSIS THE DISEASE IN CORRELATION TO CLINICAL SYMPTOMS</p>
    </div>

</div>
  )
}
