import {
  CopyOutlined,
  DeleteFilled,
  EditFilled,
  InfoCircleFilled,
  PrinterOutlined,
  ShareAltOutlined,
  TagOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import { DatePicker, Modal, Switch, Tooltip } from "antd";
import copy from "copy-to-clipboard";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { default as deleteIcon } from "../../../images/delete-icon.svg";
import ProfileIcon from "../../../images/profileIcon.png";
import {
  accessToken,
  accessTokenNo,
} from "../../../redux/actions/appointments/accessToken";
import {
  invoiceSms,
  updateEventsAppointment,
} from "../../../redux/actions/appointments/appointments";
import { deleteAppointment } from "../../../redux/actions/appointments/deleteAppointment";
import {
  addInvoice,
  editInvoice,
} from "../../../redux/actions/appointments/editInvoice";
import {
  copyButton,
  getPatientAppointmet,
  getPatientModified,
  singlePatientToggle,
} from "../../../redux/actions/appointments/getPatient";
import { searchAdvPatient } from "../../../redux/actions/appointments/patientAdvSearch";
import { printInvoice } from "../../../redux/actions/appointments/printInvoice";
import {
  printPatientProfile,
  printPatientProfileToggle,
} from "../../../redux/actions/appointments/printPatientProfile";
import { printReportAppointment } from "../../../redux/actions/appointments/printReportAppointment";
import { searchPatient } from "../../../redux/actions/appointments/searchPatient";
import { setPartner } from "../../../redux/actions/appointments/setPartner";
import { updateAppointments } from "../../../redux/actions/appointments/updateAppointment";
import { updateAppointmentView } from "../../../redux/actions/appointments/updateAppointmentView";
import { LoadUpdateVisitOpdInvoice } from "../../../redux/actions/opd/UpdateVisitOpdInvoice";
import { getProfileDetails } from "../../../redux/actions/profile";
import Button from "../../../UIComponents/Button/Button";
import Divider from "../../../UIComponents/Divider/Divider";
import Input from "../../../UIComponents/Input/Input";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import { toast } from "../../../UIComponents/Toast";
import { baseUrl } from "../../../utils/Helper";
import "./MyCalendar.css";
import "./Search.css";
import {
  createToggle,
  clearSearchPatientSuccess,
} from "../../../redux/actions/appointments/searchPatient";
import { patientInvoiceInfo } from "../../../redux/actions/opd/patientopdinvoice";

const data = [
  {
    id: "File",
    name: "File",
  },
  {
    id: "Report",
    name: "Report",
  },
  {
    id: "Address",
    name: "Address",
  },
  {
    id: "OBD",
    name: "OBD",
  },
];
const walkinData = [
  {
    id: "W",
    name: "W",
  },
  {
    id: "A",
    name: "A",
  },
  {
    id: "O",
    name: "O",
  },
];
const paymentType = [
  {
    id: "Cash",
    name: "Cash",
  },
  {
    id: "Credit Card",
    name: "Credit Card",
  },
  {
    id: "Debit Card",
    name: "Debit Card",
  },
  {
    id: "Net Banking",
    name: "Net Banking",
  },

  {
    id: "Cheque",
    name: "Cheque",
  },
  {
    id: "Mobile Wallet",
    name: "Mobile Wallet",
  },
  {
    id: "Reciept",
    name: "Reciept",
  },
];
const Type = [
  {
    id: "Paid",
    name: "Paid",
  },
  {
    id: "Complimentary",
    name: "Complimentary",
  },
  {
    id: "Discounted",
    name: "Discounted",
  },
];
const option = [
  {
    id: "2",
    name: "two",
  },
  {},
];

export const MyCalendar = (props) => {
  const getScheduled = useSelector(
    (state) => state.appointments.getAppointment.getScheduled
  );
  console.log("schedule redux time", getScheduled);
  const doctorInfoDetail = useSelector((state) => state.profile.profileInfo);

  const updateEvent = useSelector(
    (state) => state.appointments.getAppointment.updateEvent
  );
  console.log("updateEvent", updateEvent);

  const profileInfo = useSelector(
    (state) => state.profile.profileInfo.locations
  );
  console.log("profileInfo", profileInfo);

  const getDate = useSelector(
    (state) => state.appointments.createPatient.getDate
  );
  console.log("getDate", getDate);

  const searchPatientData = useSelector(
    (state) => state.appointments.searchPatient.searchPatient
  );
  console.log("searchPatient in mycalendar", searchPatientData);

  const searchAdvPatientData = useSelector(
    (state) => state.appointments?.searchAdvPatient?.searchAdvPatient
  );
  console.log("searchAdvPatientData", searchAdvPatientData);

  const getAppointment = useSelector(
    (state) => state.appointments.getPatient.getPatient
  );
  console.log("getapp", getAppointment);

  const getPatientModifiedData = useSelector(
    (state) => state.appointments.getPatient.getPatientModifiedData
  );
  console.log("getPatientModifiedData", getPatientModifiedData);

  const printReport = useSelector(
    (state) => state.appointments.printReportAppointment.printReportAppointments
  );
  console.log("apppp", printReport);

  const accessTokenNumber = useSelector(
    (state) => state.appointments.accessToken.accessTokenNos
  );
  console.log("accessTokenNo", accessTokenNumber);

  const appointmentsData = useSelector(
    (state) => state.appointments.getAppointment.getAppointmentsDetails
  );
  console.log("testing appointmentsData", appointmentsData);

  const html = useSelector(
    (state) =>
      state.appointments?.printReportAppointment?.printReportAppointments
  );
  console.log("html", html);

  const printToggle = useSelector(
    (state) => state.appointments?.printPatientProfile?.printPatientToggle
  );
  console.log("printToggle", printToggle);

  const InvoiceDetails = useSelector(
    (state) => state.appointments.editInvoice.editIinvoiceData
  );
  console.log("InvoiceDetails", InvoiceDetails);

  const buttonAdd = useSelector(
    (state) => state.appointments.editInvoice.addButtons
  );
  console.log("buttonAdd", buttonAdd);

  const PrintProfile = useSelector(
    (state) => state.appointments?.printPatientProfile?.printPatientProfiles
  );
  console.log("PrintProfile", PrintProfile);

  const patientOpdInvoiceInfo = useSelector(
    (state) => state.opdrecord?.patientopdinvoice?.patientOpdInvoiceInfo
  );
  console.log("patientOpdInvoiceInfo", patientOpdInvoiceInfo);

  const printInvoiceInfo = useSelector(
    (state) => state.appointments.printInvoice.printInvoiceData
  );
  console.log("printInvoiceInfo", printInvoiceInfo);
  const getPatientinnnnn = useSelector(
    (state) => state.appointments.getPatient
  );
  console.log("getPatientinnnnn", getPatientinnnnn);
  const singleToggles = useSelector(
    (state) => state.appointments?.getPatient.singleToggle
  );
  console.log("singleToggles", singleToggles);
  const create = useSelector(
    (state) => state.appointments.searchPatient.createToggleButton
  );

  const doctorInfo = useSelector((state) => state?.profile?.profileInfo);
  console.log("doctorInfo", doctorInfo);
  const clinicName = useSelector((state) => state.profile.clinicLocations);
  const clinicId = useSelector((state) => state.profile.clinicId);
  console.log("clinicId", clinicId);
  const update = useSelector((state) => state.profile.updateProfile);
  console.log("update", update);

  const [events, setEvents] = useState("");
  const [model, setModle] = useState(false);
  const [deleteModel, setDeleteModle] = useState(false);
  const [title, setTitle] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [location, setLocation] = useState("");
  const [aptType, setaptType] = useState("");
  const [patientId, setPatientId] = useState("");
  const [aptId, setAptId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [patientGetModal, setPatientGetModal] = useState("");
  const [note, setNote] = useState("");
  const [locationId, setLocationId] = useState(
    clinicName?.length === 0
      ? doctorInfo?.locations?.[0]?.id
      : clinicName[0]?.id
  );
  const [timeslot, setTimeslot] = useState("");
  const [walkin, setWalkin] = useState(
    walkinData.length ? walkinData[0].id : ""
  );
  const [scheduledTime, setScheduledTime] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [department, setDepartment] = useState("");
  const [doctorIncharge, setDoctorIncharge] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const [mobile, setMobile] = useState("");
  const [type, setType] = useState("uhid");
  const [by, setBy] = useState("");
  const [param, setParam] = useState("");
  const [singlePatientData, setSinglePatientData] = useState("");
  const [uniqueno, setUniqueno] = useState("");
  const [patientMobile, setPatientMobile] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [typeMethod, setTypeMethod] = useState("");
  const [service, setService] = useState("");
  const [disc, setDisc] = useState("0");
  const [gross, setGross] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [relationship, setRelationship] = useState("");
  const [depart, setDepart] = useState("");
  const [post, setPost] = useState("");
  const [switchChecked, setSwitchChecked] = useState("");
  const [getSingleSlot, setGetSingleSlots] = useState("");
  const [slotTimings, setSlotTimings] = useState([]);
  const [newInvoce, setNewInvoice] = useState([]);
  const [paidPendingToggle, setPaidPendingToggle] = useState(true);
  //print
  const componentRef = useRef();
  const shareComponent = useRef();
  const invoicePrint = useRef();
  //print-ends

  //tooltip-text

  const totalNetValue = InvoiceDetails?.invoice?.map((item) =>
    Number(item.gross)
  );
  const sum = totalNetValue?.reduce((partialSum, a) => partialSum + a, 0);
  const totalDiscVal = InvoiceDetails?.invoice?.map((item) =>
    Number(item.discAmt)
  );
  const discountSums = totalDiscVal?.reduce(
    (partialDiscAmnt, d) => partialDiscAmnt + d,
    0
  );
  const discSum = discountSums ? Number(discountSums).toFixed(2) : 0;
  const net = InvoiceDetails?.invoice?.map((item) => Number(item.cost));
  const sumTotalNets = net?.reduce((partialNet, a) => partialNet + a, 0);
  const sumNet = sumTotalNets ? Number(sumTotalNets).toFixed(2) : 0;

  console.log("totalNetValue", net, sumNet, totalDiscVal);

  const token = <span>Assign Token</span>;
  const edit = <span>Edit Profile</span>;
  const deleteapp = <span>Delete Appointment</span>;
  const editapp = <span>Edit Appointment</span>;
  const partnerprof = <span>Partner Profile</span>;
  const copyprof = <span>Copy Profile</span>;
  const printprof = <span>Print Profile</span>;
  const printrep = <span>Print Report</span>;
  const editinv = <span>Print Invoice</span>;
  const editexttinv = <span>Edit Invoice</span>;
  const localizer = momentLocalizer(moment);
  const eventArr = [];
  const dispatch = useDispatch();
  const shareData = {
    aptId: aptId,
    doctorId: doctorId,
  };
  const updateAppointmentData = {
    aptType: walkin,
    aptId: aptId,
    doctorId: doctorId,
    locationId: getSingleSlot ? getSingleSlot[0]?.id : "",
    scheduledTime: scheduledTime,
    timeslot: timeslot,
    department: department,
    doctorIncharge: doctorIncharge,
    note: note,
  };

  const accessTokenData = {
    aptId: aptId,
    doctorId: doctorId,
    doctorIncharge: doctorIncharge,
    department: department,
  };

  useEffect(() => {
    if (clinicName.length) {
      setLocationId(clinicName?.[0]?.id);
    }
  }, [clinicName]);
  console.log("gnksdhgfkjshjf", clinicName, locationId);

  useEffect(() => {
    const SingleDayAppointment = appointmentsData?.filter(
      (item) => item?.scheduledTime === getScheduled?.scheduledTime
    );
    console.log("SingleDayAppointment", SingleDayAppointment);
  }, [appointmentsData]);

  useEffect(() => {
    const app = appointmentsData.map((item) => {
      const delivery_date_time = new Date(
        item.scheduledTime + " " + item.timeslot
      );
      const dateandtime = moment(delivery_date_time).format();
      const newDateObj = moment(dateandtime)
        .add(
          clinicName?.length === 0
            ? doctorInfo?.locations?.[0]?.slotDuration
            : clinicName[0]?.slotDuration,
          "m"
        )
        .toDate();
      return {
        title: item.patientName,
        start: new Date(dateandtime),
        end: new Date(newDateObj),
        locationName: item.locationName,
        aptType: item.aptType,
        patientId: item.patientId,
        aptId: item._id,
        doctorId: item.doctorId,
        uniqueno: item.uniqueno,
        patientMobile: item.patientMobile,
        token: item.token,
      };
    });
    dispatch(updateEventsAppointment(app));

    // appointmentsData?.map((item) => {
    //   const delivery_date_time = new Date(item.scheduledTime + ' ' + item.timeslot)
    //   const dateandtime = moment(delivery_date_time).format()
    //   const newDateObj = moment(dateandtime).add(clinicName?.length === 0 ? doctorInfo?.locations?.[0]?.slotDuration : clinicName[0]?.slotDuration, 'm').toDate();
    //   eventArr.push({
    //     title: item.patientName,
    //     start: new Date(dateandtime),
    //     end: new Date(newDateObj),
    //     locationName: item.locationName,
    //     aptType: item.aptType,
    //     patientId: item.patientId,
    //     aptId: item._id,
    //     doctorId: item.doctorId,
    //     uniqueno: item.uniqueno,
    //     patientMobile: item.patientMobile,
    //     token: item.token,
    //   })
    // })
  }, [appointmentsData]);
  console.log("updateevvvents", eventArr, patientId);

  useEffect(() => {
    console.log("updated events", events);
  }, [events]);

  useEffect(() => {
    const getSlots = profileInfo?.filter((item) => item.name === location);
    console.log("getSlots", getSlots);
    setGetSingleSlots(getSlots);
  }, [location]);

  useEffect(() => {
    dispatch(printPatientProfileToggle(true));
  }, []);

  useEffect(() => {
    dispatch(
      addInvoice({ ...InvoiceDetails, fees: sumNet, discountAmt: discSum })
    );
  }, [sumNet, discSum]);

  const selectedEvents = (e) => {
    console.log("jghfdghjkl", e);
    setModle(true);
    setTitle(e.title);
    const start = moment(e.start).format("Do MMM ddd");
    const end = moment(e.start).format("h:mm A");
    setStartdate(start);
    setEnddate(end);
    setLocation(e.locationName);
    setaptType(e.aptType);
    setPatientId(e.patientId);
    setAptId(e.aptId);
    setDoctorId(e.doctorId);
    setUniqueno(e.uniqueno);
    setPatientMobile(e.patientMobile);
    dispatch(accessTokenNo(e.token));
    dispatch(printPatientProfile({ patientId: e.patientId }));
    dispatch(updateAppointmentView({ aptId: e.aptId, doctorId: e.doctorId }));
    dispatch(printReportAppointment({ aptId: e.aptId }));
    dispatch(
      printInvoice({
        aptId: e.aptId,
        invoiceA: false,
        invoiceB: true,
        invoiceC: false,
      })
    );
    dispatch(patientInvoiceInfo({ aptId: e.aptId, full: true }, ""));
    console.log("eventsss", e);
  };

  const handleCancel = () => {
    setModle(false);
    setDeleteModle(false);
    setPatientGetModal(false);
    setTimeslot("");
    dispatch(printPatientProfileToggle(false));
  };

  const handleEidtCancel = () => {
    setSearchModal(false);
    setMobile("");
    setBy("");
    setParam("");
    dispatch(clearSearchPatientSuccess());
  };

  const deleteHandle = () => {
    setDeleteModle(true);
    setModle(false);
  };

  const infoHandle = (patientId) => {
    dispatch(getPatientAppointmet({ patientId: patientId }));
    dispatch(singlePatientToggle(true));
    dispatch(getPatientModified("editProfile"));
    dispatch(copyButton(false));
    console.log("kekmnkmekm", singleToggles);
    setModle(false);
  };

  const cancelHandle = () => {
    setDeleteModle(false);
    setModle(false);
  };

  const confirmHandle = () => {
    dispatch(deleteAppointment({ aptId, doctorId }, scheduledTime));
    setModle(false);
    setDeleteModle(false);
  };

  const eidtHandle = () => {
    setPatientGetModal(true);
  };

  const onWalkHandle = (value) => {
    setWalkin(value);
  };

  const onDateTimeHandle = (value, dateString) => {
    const scheduledTimess = moment(value).format("YYYY-MM-DD");
    console.log("hhhhh", value);
    setScheduledTime(scheduledTimess);
    setTimeslot("");
  };

  useEffect(() => {
    if ((scheduledTime, clinicName, doctorInfo)) {
      console.log("sgfsfgsgs", scheduledTime, clinicName);
      const getDay = moment(scheduledTime).format("dddd");
      console.log("values in calendar", getDay);
      if (getDay && clinicName && doctorInfo) {
        const getAllTimings = doctorInfo?.locations?.filter(
          (item) => item.id === clinicId
        );
        console.log("getAllTimings", getAllTimings);
        const timings =
          getAllTimings?.length === 0
            ? doctorInfo?.locations?.length &&
              doctorInfo?.locations?.[0]?.timings?.filter(
                (item) => item.day === getDay
              )[0]?.slots
            : getAllTimings?.[0]?.timings?.filter(
                (item) => item.day === getDay
              )[0]?.slots;
        let slots = timings?.map((arr) => {
          console.log(
            "inside map function",
            `${scheduledTime} ${arr}`,
            timings,
            getAllTimings
          );
          const selectedTime = `${scheduledTime} ${arr}`;
          return {
            name: arr,
            disabled: moment().isAfter(
              moment(selectedTime, "YYYY-MM-DD hh:mm A")
            ),
          };
        });
        console.log("timings", slots);
        setSlotTimings(slots);
      }
    }
  }, [scheduledTime, clinicName, doctorInfo]);

  const handleTimeSlot = (value) => {
    setTimeslot(value);
  };

  const onNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleOk = () => {
    if (!walkin || !scheduledTime) {
      toast.error("please enter all the input fields");
    } else if (!timeslot) {
      toast.error("please select time slot");
    } else {
      dispatch(updateAppointments(updateAppointmentData));
      setPatientGetModal(false);
      // setWalkin("")
      // setTimeslot('')
      // setScheduledTime('')
      setTimeslot("");
      setNote("");
    }
  };

  console.log("sjdddddfsjdh", location, walkin, scheduledTime, timeslot);

  const onUserHandle = () => {
    setSearchModal(true);
  };

  const searchOnChange = (e) => {
    setMobile(e.target.value);
  };
  const onEnterSearch = (event) => {
    if (event.key === "Enter") {
      dispatch(searchPatient({ mobile, type }));
      dispatch(createToggle(""));
    }
  };
  const OnSelectChange = (value) => {
    console.log("valuessss", value);
    setBy(value);
  };

  const tokenHandle = () => {
    dispatch(accessToken(accessTokenData, scheduledTime, "apt"));
    setModle(false);
  };

  const onEnterAdvSearch = (event) => {
    if (event.key === "Enter") {
      dispatch(searchAdvPatient({ param, by }));
      dispatch(createToggle(""));
    }
  };

  const onInputChange = (e) => {
    setParam(e.target.value);
  };

  const onSelectHandle = (id) => {
    console.log("who", id);
    const singlePatientSelect = searchPatientData?.find(
      (item) => item.id === id
    );
    setPatientId(singlePatientSelect.id);
    dispatch(setPartner({ for: patientId, who: id }));
    setSearchModal(false);
    setModle(false);
  };

  const copyHandle = () => {
    dispatch(copyButton(true));
    dispatch(getPatientAppointmet({ patientId: patientId }));
    dispatch(getPatientModified("copyProfile"));
    setModle(false);
    console.log("iddhere", copyButton);
  };

  const showModal = () => {
    dispatch(editInvoice({ aptId: aptId, full: true }));
    setIsModalVisible(true);
  };

  const handleInvoiceCancel = () => {
    setIsModalVisible(false);
  };
  const onPaymentMethodHandle = (value) => {
    setPaymentMethod(value);
    dispatch(addInvoice({ ...InvoiceDetails, paymentMode: value }));
  };
  const onTypeHandle = (value) => {
    setTypeMethod(value);
    if (value === "Paid") {
      setPaidPendingToggle(true);
      console.log("inside if else", paidPendingToggle);
      dispatch(addInvoice({ ...InvoiceDetails, visitType: value }));
    } else if (value === "Complimentary" || value === "Discounted") {
      console.log("inside else if", paidPendingToggle, value);
      setPaidPendingToggle(false);
      dispatch(addInvoice({ ...InvoiceDetails, visitType: value }));
    }
  };
  const serviceTypeHandler = (e) => {
    setService(e.target.value);
  };
  const discHandler = (e) => {
    if (e.target.value > 100) e.target.value = 100;
    setDisc(e.target.value);
  };
  const grossHandler = (e) => {
    setGross(e.target.value);
    var numVal1 = Number(e.target.value);
    var numVal2 = Number(disc) / 100;
    var num3 = numVal2 * numVal1;
    console.log("num3", num3);
    setDiscountValue(num3.toFixed(2));
    setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(2));
  };
  const discountHandler = (e) => {
    setDiscountValue(e.target.value);
  };
  const netHandle = (e) => {
    setTotalValue(e.target.value);
  };
  const onEmployeeIdChange = (e) => {
    dispatch(
      addInvoice({
        ...InvoiceDetails,
        insurance: {
          ...InvoiceDetails.insurance,
          setEmployeeId: e.target.value,
        },
      })
    );
  };
  const onCardHolderChange = (e) => {
    dispatch(
      addInvoice({
        ...InvoiceDetails,
        insurance: {
          ...InvoiceDetails.insurance,
          setCardHolder: e.target.value,
        },
      })
    );
  };
  const onRelationshipChange = (e) => {
    dispatch(
      addInvoice({
        ...InvoiceDetails,
        insurance: {
          ...InvoiceDetails.insurance,
          setRelationship: e.target.value,
        },
      })
    );
  };
  const onDepartmentChange = (e) => {
    dispatch(
      addInvoice({
        ...InvoiceDetails,
        insurance: { ...InvoiceDetails.insurance, setDepart: e.target.value },
      })
    );
  };
  const onPostChange = (e) => {
    dispatch(
      addInvoice({
        ...InvoiceDetails,
        insurance: { ...InvoiceDetails.insurance, setPost: e.target.value },
      })
    );
  };
  const InvoicehandleCancel = () => {
    setIsModalVisible(false);
    setDisc("0");
  };

  useEffect(() => {
    if (disc) {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3.toFixed(2));
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(2));
    }
  }, [disc]);

  const onDiscSubmit = (e) => {
    if (e.key === "Enter") {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3.toFixed(2));
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(2));
    }
  };
  window.onclick = (e) => {
    var numVal1 = Number(gross);
    var numVal2 = Number(disc) / 100;
    var num3 = numVal2 * numVal1;
    console.log("num3", num3);
    setDiscountValue(num3.toFixed(2));
    setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(2));
    console.log("window onclick", e);
  };
  const handleCopylink = () => {
    dispatch(getProfileDetails());
    const invoiceA = doctorInfoDetail.invoiceA
      ? doctorInfoDetail.invoiceA
      : false;
    const invoiceB = doctorInfoDetail.invoiceB
      ? doctorInfoDetail.invoiceB
      : false;
    const invoiceC = doctorInfoDetail.invoiceC
      ? doctorInfoDetail.invoiceC
      : false;
    const hipay = doctorInfoDetail?.hipay;
    const mypay = doctorInfoDetail?.mypay;
    let message = `${window.location.host}/opd-invoice/${patientOpdInvoiceInfo?._id}?alt=${invoiceA}&alp=${invoiceB}&alps=${invoiceC}`;
    toast.success("Link Copied");
    let msg = message;
    if (mypay) {
      msg = msg + " " + mypay;
    }
    if (hipay) {
      msg = msg + message + "processpayment/" + patientOpdInvoiceInfo?._id;
    }
    copy(msg).then(
      function (e) {},
      function (e) {}
    );
  };

  const onKeyDown = (e) => {
    console.log("fhdfhuds", e);
    if (e.key === "Tab") {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3.toFixed(2));
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(2));
    }
  };
  const grossValue = Number(gross).toFixed(2);
  const total = Number(totalValue).toFixed(2);
  const discValue = Number(disc).toFixed(2);
  const discAmount = Number(discountValue).toFixed(2);

  const onAddButtonHandle = () => {
    if (!service) {
      toast.error("Please enter service type");
    }
    // else if (!disc) {
    //   toast.error("Please enter discount");
    // }
    else if (!gross) {
      toast.error("Please enter gross");
    } else if (
      InvoiceDetails.hasOwnProperty("invoice") &&
      patientOpdInvoiceInfo?.invoice?.length
    ) {
      dispatch(
        addInvoice({
          ...InvoiceDetails,
          invoice: [
            ...InvoiceDetails.invoice,
            {
              service: service,
              cost: total,
              disc: discValue,
              discAmt: discAmount,
              gross: grossValue,
            },
          ],
        })
      );
      setService("");
      setGross("");
      setDisc("0");
      setDiscountValue("");
      setTotalValue("");
    } else {
      // setNewInvoice([...newInvoce, { service: service, cost: gross, disc: disc, discAmt: discountValue, gross: total }])
      dispatch(
        addInvoice({
          ...InvoiceDetails,
          invoice: [
            ...newInvoce,
            {
              service: service,
              cost: total,
              disc: discValue,
              discAmt: discAmount,
              gross: grossValue,
            },
          ],
        })
      );
      setService("");
      setGross("");
      setDisc("0");
      setDiscountValue("");
      setTotalValue("");
    }
  };
  const deleteServiceHandler = (index) => {
    dispatch(
      addInvoice({
        ...InvoiceDetails,
        invoice: [...InvoiceDetails.invoice].filter((item, i) => i !== index),
      })
    );
  };

  const onInvoiceSave = () => {
    dispatch(
      LoadUpdateVisitOpdInvoice({
        visit: InvoiceDetails,
        aptId: aptId,
        invoiceA: doctorInfoDetail.invoiceA ? doctorInfoDetail.invoiceA : false,
        invoiceB: doctorInfoDetail.invoiceB ? doctorInfoDetail.invoiceB : false,
        invoiceC: doctorInfoDetail.invoiceC ? doctorInfoDetail.invoiceC : false,
      })
    );
  };

  const switchHandler = (checked) => {
    console.log("checked", checked);
    setSwitchChecked(checked);
    dispatch(addInvoice({ ...InvoiceDetails, paymentStatus: checked }));
  };

  useEffect(() => {
    if (patientOpdInvoiceInfo) {
      console.log("inside if useEffect");
      setSwitchChecked(patientOpdInvoiceInfo?.paymentStatus);
    }
  }, [patientOpdInvoiceInfo]);

  const invoiceA = doctorInfoDetail.invoiceA
    ? doctorInfoDetail.invoiceA
    : false;
  const invoiceB = doctorInfoDetail.invoiceB
    ? doctorInfoDetail.invoiceB
    : false;
  const invoiceC = doctorInfoDetail.invoiceC
    ? doctorInfoDetail.invoiceC
    : false;
  const hipay = doctorInfoDetail?.hipay;
  const smsData = {
    visitId: InvoiceDetails?._id,
    invoiceA: invoiceA,
    invoiceB: invoiceB,
    invoiceC: invoiceC,
    patientId: InvoiceDetails.patientId,
    doctorId: InvoiceDetails?.doctorId,
    hipay: hipay,
  };

  const onSmsHandle = () => {
    dispatch(invoiceSms(smsData));
  };

  const onSelectLocationChange = (value) => {
    setLocationId(value);
  };
  console.log("gsdfgsdfgs", scheduledTime, mobile, by, param);

  return (
    <div>
      <div className="calendar-container">
        <Calendar
          defaultView="day"
          localizer={localizer}
          events={updateEvent}
          onSelectEvent={selectedEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          views={["day", "week", "month"]}
        />
      </div>
      <Modal
        title={title}
        visible={model}
        onCancel={handleCancel}
        width={550}
        className="appointment-modal-container"
      >
        <div>
          <p>Location: {location}</p>
          <div className="date-time">
            <p className="date">{startdate}</p>
            <p className="time">{enddate}</p>
            <p className="apt-type">{aptType}</p>
          </div>
          <div className="icons">
            {accessTokenNumber ? (
              <div>
                <span className="token">{accessTokenNumber}</span>
              </div>
            ) : (
              <div className="share-icon-div">
                <Tooltip placement="bottom" title={token}>
                  <UploadOutlined onClick={tokenHandle} className="icon" />
                </Tooltip>
              </div>
            )}
            <Tooltip placement="bottom" title={edit}>
              <InfoCircleFilled
                onClick={() => infoHandle(patientId)}
                className="icon"
              />
            </Tooltip>
            <Tooltip placement="bottom" title={deleteapp}>
              <DeleteFilled onClick={deleteHandle} className="icon" />
            </Tooltip>
            <Tooltip placement="bottom" title={editapp}>
              <EditFilled onClick={eidtHandle} className="icon" />
            </Tooltip>
            <Tooltip placement="bottom" title={partnerprof}>
              <UserOutlined onClick={onUserHandle} className="icon" />
            </Tooltip>
            <Tooltip placement="bottom" title={copyprof}>
              <CopyOutlined onClick={copyHandle} className="icon" />
            </Tooltip>
            <div className="share-icon-div">
              <ReactToPrint
                trigger={() => (
                  <Tooltip placement="bottom" title={printprof}>
                    <PrinterOutlined className="icon" />
                  </Tooltip>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div style={{ display: "none" }}>
              <div ref={componentRef}>
                <div dangerouslySetInnerHTML={{ __html: PrintProfile }}></div>
              </div>
            </div>
            <div className="share-icon-div">
              <ReactToPrint
                trigger={() => (
                  <Tooltip placement="bottom" title={printrep}>
                    <ShareAltOutlined className="icon" />
                  </Tooltip>
                )}
                content={() => shareComponent.current}
              />
            </div>
            <div style={{ display: "none" }}>
              <div ref={shareComponent}>
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
              </div>
            </div>

            <ReactToPrint
              trigger={() => (
                <Tooltip placement="bottom" title={editinv}>
                  <PrinterOutlined className="icon" />
                </Tooltip>
              )}
              content={() => invoicePrint.current}
            />
            <div style={{ display: "none" }}>
              <div ref={invoicePrint}>
                <div
                  dangerouslySetInnerHTML={{ __html: printInvoiceInfo }}
                ></div>
              </div>
            </div>
            <Tooltip placement="bottom" title={editexttinv}>
              <TagOutlined className="icon" onClick={showModal} />
            </Tooltip>
          </div>
        </div>
      </Modal>
      <Modal
        title="Delete Appointment"
        visible={deleteModel}
        onCancel={handleCancel}
        width={400}
        className="appointment-modal-container-delete"
      >
        <div className="deleteModal">
          <p>Patient Name: {title}</p>
          <button onClick={cancelHandle} className="cancel-button1">
            Cancel
          </button>
          <button onClick={confirmHandle} className="confirm-button">
            Confirm
          </button>
        </div>
      </Modal>

      <Modal
        title={title}
        visible={patientGetModal}
        onCancel={handleCancel}
        width={550}
        className="appointment-modal-container"
      >
        <div className="appointment-modal">
          <div className="appointment-inp">
            <p className="calls-input-label">Select Location</p>
            <Input
              data={profileInfo}
              className="appointment-location-input"
              value={
                clinicName?.length === 0
                  ? doctorInfo?.locations?.[0]?.name
                  : clinicName[0]?.name
              }
              placeholder="Live Title"
            />
            {/* <SingleSelect
              data={doctorInfo?.locations}
              placeholder="Select Location"
              onChange={onSelectLocationChange}
              value={locationId}
              className="appointment-location-select"
            /> */}
          </div>
          <div className="appointment-inp appointment-date-gap">
            <p className="calls-input-label">Date & Time</p>
            <div className="appoint-inp">
              <SingleSelect
                data={walkinData}
                width={26}
                placeholder="W"
                onChange={onWalkHandle}
                value={walkin}
              />
              <DatePicker
                onChange={onDateTimeHandle}
                value={
                  scheduledTime == "Invalid date" ? null : moment(scheduledTime)
                }
                className="appointment-datepicker"
                defaultValue={moment(scheduledTime)}
                placeholder="Select Date"
              />
              {slotTimings && slotTimings.length ? (
                <SingleSelect
                  data={slotTimings}
                  onChange={handleTimeSlot}
                  value={timeslot}
                  className="slot_to"
                  placeholder="Select Time"
                />
              ) : (
                <SingleSelect
                  onChange={handleTimeSlot}
                  value={timeslot}
                  className="slot_to"
                  placeholder="Select Time"
                />
              )}
            </div>
          </div>
          <div className="appointment-inp">
            <p>NOTE</p>
            <textarea
              onChange={onNoteChange}
              value={note}
              className="appoint-textarea"
              placeholder="Appointment Notes"
              rows="1"
              cols="50"
            ></textarea>
          </div>
          <div className="calls-modal-btn">
            <Button
              onClick={handleOk}
              children="Save"
              className="calls-modal-btn1"
            />
          </div>
        </div>
      </Modal>

      <Modal
        title={title}
        visible={searchModal}
        onCancel={handleEidtCancel}
        width={570}
        className="appointment-modal-container"
      >
        <div className="appointment-modal">
          <div className="appointment-inp">
            <Input
              className="search-input"
              placeholder="Enter Name or Phone#"
              type="text"
              value={mobile}
              suffix={<SearchOutlined />}
              onChange={searchOnChange}
              onKeyPress={onEnterSearch}
            />
          </div>
        </div>
        <div className="appointment-inp">
          <p className="adv-search">Adv Search</p>
          <div className="search-division">
            <SingleSelect
              data={data}
              className="search-select"
              placeholder="File"
              onChange={OnSelectChange}
              value={by}
            />
            <Input
              className="search-input1"
              placeholder="Seach term"
              type="search"
              suffix={<SearchOutlined />}
              onKeyPress={onEnterAdvSearch}
              onChange={onInputChange}
              value={param}
            />
          </div>
        </div>
        {searchPatientData ? (
          <div>
            {searchPatientData?.map((item, i) => (
              <div className="search-container">
                <div>
                  <p className="patient-heading">Patient</p>
                </div>
                <div className="search-patient-container">
                  <div>
                    <div className="name-phone">
                      <div>
                        <b>
                          <p>{item?.name}</p>
                        </b>
                        <p>{item?.address?.city}</p>
                      </div>
                      <div>
                        <p>Ph#{item?.mobile}</p>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-division">
                    <div>
                      <button
                        onClick={() => onSelectHandle(item.id)}
                        className="button-select"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}

        {searchAdvPatientData ? (
          <div>
            {searchAdvPatientData?.map((items) => (
              <div className="search-container">
                <div>
                  <p className="patient-heading">Patient</p>
                </div>
                <div className="search-patient-container">
                  <div>
                    <div className="name-phone">
                      <div>
                        <b>
                          <p>{items?.name}</p>
                        </b>
                        <p>{items?.address?.city}</p>
                      </div>
                      <div>
                        <p>Ph#{items?.mobile}</p>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-division">
                    <div>
                      <button className="button-select">Select</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </Modal>

      {/* invoice modal */}

      <Modal
        title=""
        visible={isModalVisible}
        onCancel={handleInvoiceCancel}
        width={920}
      >
        <div className="invoice-modal">
          <div className="invoice-header">
            <div>
              <h3 className="invoice-heading">EDIT INVOICE</h3>

              <div className="invoice-visit-date">
                <p>
                  VISIT DATE -{" "}
                  {moment(InvoiceDetails?.visitStart).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>

            {paidPendingToggle ? (
              <div className="invoice-toggle">
                <span className="invoice-toggle-label">Pending</span>
                <Switch
                  checked={
                    switchChecked && InvoiceDetails?.paymentStatus
                      ? true
                      : false
                  }
                  onChange={switchHandler}
                />
                <span className="invoice-toggle-label">paid</span>
              </div>
            ) : (
              ""
            )}
          </div>

          <hr className="hr-line"></hr>
          <div className="invoice-detail-container">
            <div className="invoice-profile-detail">
              <img src={ProfileIcon} alt="" />
              <div className="invoice-profile-detail-text">
                <h3>{InvoiceDetails?.patientName}</h3>
                <p>PH : 91{patientMobile}</p>
              </div>
            </div>
            <hr className="hr-line"></hr>

            <div>
              <div className="invoice-table">
                <div className="opd-invoice-headings">
                  <div className="service-type-sec">
                    <p className="opd-invoice-service">Mode</p>
                    <SingleSelect
                      data={paymentType}
                      value={paymentMethod}
                      onChange={onPaymentMethodHandle}
                      className={"select-language-input"}
                    />
                  </div>
                  <div className="service-type-sec">
                    <p className="opd-invoice-service">Type</p>
                    <SingleSelect
                      data={Type}
                      value={typeMethod}
                      onChange={onTypeHandle}
                      className={"select-language-input"}
                    />
                  </div>
                </div>
                <hr className="hr-line"></hr>
                <div className="opd-invoice-headings">
                  <div className="service-type-sec">
                    <p className="opd-invoice-service">Service List</p>
                  </div>
                  <div className="disc-gross">
                    <p className="opd-invoice-disc">Disc</p>
                    <p className="opd-invoice-gross">Gross</p>
                    <p className="opd-invoice-discount">Discount</p>
                    <p className="opd-invoice-action">Net</p>
                  </div>
                </div>
                <div>
                  <Divider />
                </div>
                {InvoiceDetails?.invoice?.map((item, i) => (
                  <div>
                    <table className="opd-discount-input-sec">
                      <p className="empty-p-tag">{item.service}</p>
                      <p className="opd-name">{item.disc}</p>
                      <p className="opd-days">{item.gross}</p>
                      <p className="opd-doseage">{item.discAmt}</p>
                      <p className="opd-cost">{item.cost}</p>
                      <div className="opd-delete-sec">
                        <img
                          src={deleteIcon}
                          onClick={() => deleteServiceHandler(i)}
                          alt="deleteIcon"
                        />
                      </div>{" "}
                    </table>
                  </div>
                ))}
              </div>
              <div className="invoice-input-container">
                <div className="invoice-input-one">
                  <p>Service Type</p>
                  <Input
                    className="invoice-input1"
                    placeholder="Select Medicine Name"
                    type="text"
                    value={service}
                    onChange={serviceTypeHandler}
                  />
                </div>
                <div className="invoice-input-one">
                  <p>Disc</p>
                  <Input
                    className="invoice-input2"
                    name="disc"
                    value={disc}
                    onChange={discHandler}
                    type="number"
                    placeholder="disc"
                  />
                </div>
                <div className="invoice-input-one">
                  <p>Gross</p>
                  <Input
                    className="invoice-input2"
                    name="gross"
                    value={gross}
                    onChange={grossHandler}
                    onKeyPress={onDiscSubmit}
                    onKeyDown={onKeyDown}
                    id="click"
                    type="number"
                  />
                </div>
                <div className="invoice-input-one">
                  <p>Discount</p>
                  <Input
                    className="invoice-input2"
                    name="discount"
                    disabled={true}
                    value={discountValue}
                    onChange={discountHandler}
                    type="number"
                  />
                </div>
                <div className="invoice-input-one">
                  <p>Net</p>
                  <Input
                    className="invoice-input3"
                    name="net"
                    disabled={true}
                    value={totalValue}
                    onChange={netHandle}
                    type="number"
                  />
                </div>
                <Button
                  children="ADD"
                  className="invoice-modal-btn"
                  onClick={onAddButtonHandle}
                />
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div>
              <hr className="hr-line"></hr>
              <div className="invoice-table-display-sec">
                <div className="diplaytotalDue">
                  <div className="display-total-pending">
                    <div className="payable-day">
                      <p className="paytoday">Payable Today:</p>
                      <p>{sumNet}</p>
                    </div>
                    <div className="pastpending">
                      <p className="pasttitle">Past Pending : </p>
                      <p>{InvoiceDetails?.pastPending}</p>
                    </div>
                    <div className="totalpend">
                      <p className="totalpendtitle">Total Pending:</p>
                      <p>{InvoiceDetails?.totalPending}</p>
                    </div>
                    <div className="totaldue">
                      <p>Total Due:</p>
                      <p className="totalsum">{sumNet}</p>
                    </div>
                    <div className="totalDisc">
                      <p>Discount:</p>
                      <p className="opd-intake">{discSum}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr-line"></hr>
            </div>
            <div className="invoice-input-container1">
              <div className="invoice-input-one">
                <p>Employee ID</p>
                <Input
                  className="invoice-input4"
                  placeholder="EmployeeId"
                  value={InvoiceDetails?.insurance?.setEmployeeId}
                  onChange={onEmployeeIdChange}
                />
              </div>
              <div className="invoice-input-one">
                <p>Card Holder</p>
                <Input
                  className="invoice-input4"
                  placeholder="CardHolder"
                  value={InvoiceDetails?.insurance?.setCardHolder}
                  onChange={onCardHolderChange}
                />
              </div>
              <div className="invoice-input-one">
                <p>Relationship</p>
                <Input
                  className="invoice-input4"
                  placeholder="Relationship"
                  value={InvoiceDetails?.insurance?.setRelationship}
                  onChange={onRelationshipChange}
                />
              </div>
              <div className="invoice-input-one">
                <p>Department</p>
                <Input
                  className="invoice-input4"
                  placeholder="Department"
                  value={InvoiceDetails?.insurance?.setDepart}
                  onChange={onDepartmentChange}
                />
              </div>
              <div className="invoice-input-one">
                <p>Post</p>
                <Input
                  className="invoice-input4"
                  placeholder="Post"
                  value={InvoiceDetails?.insurance?.setPost}
                  onChange={onPostChange}
                />
              </div>
            </div>

            <div className="invoice-btn-container">
              <Button
                children="SMS INVOICE"
                onClick={onSmsHandle}
                className="invoice-modal-btn1"
              />
              <Button
                children="COPY LINK"
                className="invoice-modal-btn2"
                onClick={handleCopylink}
              />
            </div>
          </div>
          <div className="calls-modal-btn">
            <Button
              onClick={onInvoiceSave}
              children="SAVE"
              className="calls-modal-btn1"
            />
            <Button
              onClick={InvoicehandleCancel}
              children="CANCEL"
              className="calls-modal-btn2"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
