import React from 'react'
import '../Dengue.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const UrineRoutine = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'URINE ROUTINE')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'URINE ROUTINE'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))

  }
  const stylesp = {
    fontSize:12,
     fontWeight:500
  }
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))      }
  return (
    <div>
        
          <div className='dengue-parent'>
            <div className='dengue-left'>
                <div className='dengue-grid'>
                    <div className='deng1 dng-pad' onClick={() => onLabelClicked("URINE CONTININE")}>URINE CONTININE</div>
                    <div className='deng2 dng-text'><TextAreaAnt name="URINE CONTININE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active}
 onChange={onInputChange} /></div>
                    <span className='dng-border'></span>
                </div>
            </div>
            <div>
                <TestReportRightSection />
            </div>
          </div>
          <div className='dng-foot'>
              <ReportFooter />
          </div>
          <div className='dng-method'>
              <h4>COMMENTS:</h4>
              <p style={stylesp}>NANO-CHECK RAPID TEST is a rapid, self-controlled immunoassay for the qualitative detection of otinine in human urine. The detection limit concentration (Cut-off) of test is cotinine at 200 ng/ml.this assay is intended for professional and in-vitro use only.</p>
              <h4>POSITIVE:</h4>
              <p style={stylesp}>One colored line appears on the control region ( C ),the absence of test line (T) is a presumptive positive result for cotinine:i.e. the specimen may contain continine or its analogous compounds at concentration above the cutt-off level. </p>
              <h4>NEGATIVE:</h4>
              <p style={stylesp}>Two colored lines will appear.The line in the test region (T) is the drug probe line, the line in the control region( C ) is the control line, which is used to indicate proper performance of the device. The color intencity of the test may be weaker or stronge,than that of the control line.negative result ones not indicate that the absolute absence if drug in the specimen,it only indicate the specimen dos not contain the drug at a concentration equal or above the cutt-off level in qualitative terms.</p>
            </div>
      </div>
  )
}
