import React from "react";
import "./UsgPelvic.css";
import { TestReportRightSection } from "../TestReportRightSection";
import ReportFooter from "../ReportFooter";
import TextAreaAnt from "../../../../UIComponents/TextArea/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function UsgPelvic() {
  const dispatch = useDispatch();
  const reportDataFields = useSelector((state) => state?.diagnosticsInvoiceSummary?.getdiagnostic);
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [inputValue, setInputValue] = useState("");

  const onInputChange = (e) => {
    console.log("e", e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(
      (item) => item?.test === "ULTRASONOGRAPHIC FINDINGS OF PELVIC ORGANS"
    );
    let index = 0;
    reportDataFields?.testReport?.map((item, i) => {
      if (item?.test === "ULTRASONOGRAPHIC FINDINGS OF PELVIC ORGANS") {
        index = i;
      }
      return item;
    });
    console.log("index", index);
    const finalFilteredData = filterData[0]?.fields?.map((item) => {
      if (item.particular === name) {
        item.result = value;
      }
      return item;
    });
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  };

  const onLabelClicked = (value) => {
    dispatch(field({fieldKey:value})) 
  };

  return (
    <div>
      <div className="usgPelvic-parent">
        <div className="usgPelvic-left">
          <div className="usgPelvic-grid">
            <div
              className="usgPelvic-1 usgPelvic-pad"
              onClick={() => onLabelClicked(" UTERUS")}
            >
              UTERUS
            </div>
            <div className="usgPelvic-2 usgPelvic-text">
            <TextAreaAnt name="UTERUS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="usgPelvic-border"></span>

            <div
              className=" usgPelvic-pad"
              onClick={() => onLabelClicked("OVARY")}
            >
              OVARY
            </div>
            <div className=" usgPelvic-text">
              <TextAreaAnt name="OVARY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="usgPelvic-border1"></span>

            <div
              className=" usgPelvic-pad"
              onClick={() => onLabelClicked("P.O.P")}
            >
              P.O.P
            </div>
            <div className=" usgPelvic-text">
              <TextAreaAnt name="P.O.P" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="usgPelvic-border2"></span>

            <div
              className=" usgPelvic-pad"
              onClick={() => onLabelClicked("KIDNEYS")}
            >
              KIDNEYS
            </div>
            <div className=" usgPelvic-text">
              <TextAreaAnt name="KIDNEYS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="usgPelvic-border3"></span>

            <div
              className=" usgPelvic-pad"
              onClick={() => onLabelClicked(" IMPRESSSION")}
            >
              IMPRESSSION
            </div>
            <div className=" usgPelvic-text">
              <TextAreaAnt name="IMPRESSSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="usgPelvic-border4"></span>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="usgPelvic-foot">
        <ReportFooter />
      </div>
    </div>
  );
}

export default UsgPelvic;
