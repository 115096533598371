import React from "react";
import AdminLoginPage from "../../components/Admin/AdminLogin/AdminLoginPage";

const AdminLogin = () => {
  return (
    <div>
      <AdminLoginPage />
    </div>
  );
};
export default AdminLogin;
