import * as types from '../../actionTypes';

const initialState = {
    saveCheckoutToggle: false,
    error: {},
    loading: false
}


export const sendPatientToDoctorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEND_PATIENT_TO_DOCTOR_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.SEND_PATIENT_TO_DOCTOR_SUCCESS:
            console.log("patient vaccine payload", action.payload)
            return {
                ...state,
                // patientVaccine: action.payload,
                loading: false,
                error: {}
            }
        case types.SEND_PATIENT_TO_DOCTOR_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                // patientVaccine: []
            }
        case types.CHECKOUT_PATIENT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.CHECKOUT_PATIENT_SUCCESS:
            console.log("patient vaccine payload", action.payload)
            return {
                ...state,
                loading: false,
                error: {}
            }
        case types.CHECKOUT_PATIENT_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case types.DOCTOR_PATIENT_CHECKOUT_TOGGLE:
            return {
                ...state,
                saveCheckoutToggle: action.payload,
                loading: false,
                error:{}
            }
        default:
            return state;
    }
} 