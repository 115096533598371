import "./Video.css";
import React, { Component, useState } from "react";
import { Button } from "antd";
import Room from "./Room";
import { useDispatch, useSelector } from "react-redux";
import { fetchTwilioToken } from "../../../redux/actions/video/videoCall";
import { useEffect } from "react";
import { loadSms } from "../../../redux/actions/video/videoCall";
import { toast } from '../../../UIComponents/Toast';

const { connect } = require("twilio-video");

const Video = () => {
	const dispatch = useDispatch();
	const { selectedPatient, allAppointment, twilioToken } = useSelector(
		({ opdrecord, videoCalls }) => {
			return {
				selectedPatient: opdrecord.patientlist?.selectedPatient,
				allAppointment: opdrecord?.patientreport?.storeAppointments,
				twilioToken: videoCalls.twilioToken?.token,
			};
		}
	);
	const [room, setRoom] = useState(null);
	const [token, setToken] = useState(null);
	const [roomId, setRoomId] = useState(null);

	useEffect(() => {
		generateRoomid()
	}, []);

	useEffect(() => {
		console.log("twilioToken", twilioToken)
		twilioToken && connectWithTwilio(twilioToken);
	}, [twilioToken]);

	const joinRoom = async () => {
		const { aptId } = selectedPatient;
		if (aptId && allAppointment[aptId]) {
			console.log("Found complete patient info", allAppointment[aptId]);

			const {
				appointment: { doctorName },
			} = allAppointment[aptId];
			dispatch(fetchTwilioToken(doctorName, roomId));
		}
	};

	const sendSms = () => {
		const link = window.location.origin + "/web-chat?room=" + roomId;
		console.log("checking link", link)
		dispatch(loadSms({
			link, patientId: selectedPatient?.patientId
		}))

	}

	const generateRoomid = () => {
		const { aptId } = selectedPatient;

		if (aptId && allAppointment?.[aptId]) {

			const {
				appointment: { patientMobile, doctorName },
			} = allAppointment[aptId];


			const roomId = patientMobile.substr(patientMobile.length - 4) + aptId;
			setRoomId(roomId);
		}
	};

	const returnToLobby = () => {
		setRoom(null);
	};

	const connectWithTwilio = async twilioToken => {
		try {
			const room = await connect(twilioToken, {
				name: "cool-room",
				audio: true,
				video: true,
			});
			console.log("Fetched room info for twilio", room);
			setToken(twilioToken);
			setRoom(room);
		} catch (err) {
			console.log("Error caught", err);
		}
	};

	const copyLink = () => {
		const link = window.location.origin + "/web-chat?room=" + roomId;
		let msg = "Join Conf: " + link;
		navigator.clipboard.writeText(msg);
		toast.success("Copied successfully")
	};

	console.log("room", room)
	return (
		<div className="app">
			<div className="start-copy-div">
				<Button onClick={joinRoom}>Start Call</Button>
				<Button onClick={sendSms}>SMS</Button>
				<Button onClick={copyLink}>Copy Link</Button>
			</div>
			<div>
				{room ? (
					<Room returnToLobby={returnToLobby} room={room} token={token} />
				) : <div className="video-placeholder"></div>}
			</div>
		</div>
	);
};

export default Video;
