import React, { useEffect, useState } from "react";
import { Avatar, Badge } from "antd";
import "./Header.css";
import userIcon from "../../images/header-user-icon.png";
import editIcon from "../../images/edit-icon.png";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { resetDoctorInfo, updateLoginStatus } from "../../redux/actions/login";
import { editVisible, getClinicId } from "../../redux/actions/profile";
import { selectedIndex } from "../../redux/actions/profile";
import { Modal, TimePicker } from "antd";
import InputField from "../../UIComponents/Input/Input";
import { Checkbox } from "antd";
import plusIcon from "../../images/plus-icon.svg";
import deleteIcon from "../../images/delete-icon.png";
import Button from "../../UIComponents/Button/Button";
import moment from "moment";
import SingleSelect from "../../UIComponents/Select/SingleSelect";
import { updateProfileDetails } from "../../redux/actions/profile";
import { Locations } from "../../redux/actions/profile";
import Cast from "./Cast";
import { showOpdReport } from "../../redux/actions/opd/patientreport";
import { toast } from "../../UIComponents/Toast";
import { updateAdminStatus } from "../../redux/actions/adminlogin";

const weeksData = [
  {
    id: "1",
    name: "Monday",
    isSelected: false,
  },
  {
    id: "2",
    name: "Tuesday",
    isSelected: false,
  },
  {
    id: "3",
    name: "Wednesday",
    isSelected: false,
  },
  {
    id: "4",
    name: "Thursday",
    isSelected: false,
  },
  {
    id: "5",
    name: "Friday",
    isSelected: false,
  },
  {
    id: "6",
    name: "Saturday",
    isSelected: false,
  },
  {
    id: "7",
    name: "Sunday",
    isSelected: false,
  },
];
let statecityList = {
  "Andhra Pradesh": ["Amravathi"],
  "Arunachal Pradesh": ["Itanagar"],
  Assam: ["Dispur"],
  Bihar: ["Patna"],
  Chandigarh: ["Chandigarh"],
  Chhattisgarh: ["Raipur"],
  Delhi: ["Delhi"],
  Goa: ["Panaji"],
  Gujarat: ["Ahmedabad", "Gandhinagar"],
  Haryana: ["Chandigarh", "Gurugram", "Faridabad", "Gurgaon"],
  "Himachal Pradesh": ["Shimla"],
  "Jammu & Kashmir": ["Srinagar", "Jammu"],
  Jharkhand: ["Ranchi"],
  Karnataka: ["Bangalore"],
  Kerala: ["Thiruvananthapuram"],
  "Madhya Pradesh": ["Bhopal"],
  Maharashtra: ["Mumbai", "Pune"],
  Manipur: ["Imphal"],
  Meghalaya: ["Shillong"],
  Mizoram: ["Aizawl"],
  Nagaland: ["Kohima"],
  Orissa: ["Bhubaneshwar"],
  Punjab: ["Chandigarh"],
  Rajasthan: ["Jaipur"],
  Sikkim: ["Gangtok"],
  "Tamil Nadu": ["Chennai"],
  Telangana: ["Hyderabad"],
  Tripura: ["Agartala"],
  "Uttar Pradesh": [
    "Noida",
    "Ghaziabad",
    "Meerut",
    "Vaishali",
    "Indirapuram",
    "Aligarh",
    "Bareilly",
    "Agra",
    "Jaunpur",
    "Mirzapur",
    "Bhadohi",
    "Mughalsarai",
    "Gazipur",
    "Ballia",
    "Azamgarh",
    "Mau",
    "Allahabad",
    "Deoria",
  ],
  Uttarakhand: ["Dehradun"],
  "West Bengal": ["Kolkata"],
};
const slotList = [
  {
    id: "5",
    name: "5",
  },
  {
    id: "10",
    name: "10",
  },
  {
    id: "15",
    name: "15",
  },
  {
    id: "20",
    name: "20",
  },
  {
    id: "25",
    name: "25",
  },
  {
    id: "30",
    name: "30",
  },
  {
    id: "35",
    name: "35",
  },
  {
    id: "40",
    name: "40",
  },
  {
    id: "45",
    name: "45",
  },
  {
    id: "50",
    name: "50",
  },
  {
    id: "55",
    name: "55",
  },
  {
    id: "60",
    name: "60",
  },
];
const statesList = Object.keys(statecityList);

const Header = () => {
  let dispatch = useDispatch();
  const history = useHistory();
  const clinicName = useSelector((state) => state.profile.clinicLocations);
  console.log("clinicNamesss", clinicName);
  const doctorInfo = useSelector((state) => state?.profile?.profileInfo);
  console.log("doctorInfo", doctorInfo);
  const seletedBranch = useSelector((state) => state.profile.selectedIndex);
  console.log("selectedBranch", seletedBranch);
  const editVisibleToggle = useSelector(
    (state) => state.profile.editVisisbleModal
  );
  console.log("editVisibleToggle", editVisibleToggle);
  const myDomainInfo = useSelector((state) => state.login.myDomainInfo);
  const clinicId = useSelector((state) => state.profile.clinicId);
  console.log("clinicId", clinicId);

  const [editcityList, setEditCityList] = useState([]);
  const [displayTiming, setdisplayTiming] = useState([
    {
      days: [],
      slots: [
        {
          start: "",
          end: "",
        },
      ],
    },
  ]);

  const onLogout = (e) => {
    dispatch(resetDoctorInfo());
    e.preventDefault();
    window.location.href = "/";
    // history.push('/doctor-app/login')
    dispatch(showOpdReport(false));
    dispatch(updateLoginStatus());
    dispatch(updateAdminStatus());
  };
  const onDoctorHandle = (id) => {
    dispatch(getClinicId(id));
    dispatch(
      Locations(doctorInfo?.locations?.filter((item) => item.id === id))
    );
  };
  console.log("clinicName", clinicName);

  const editModalClose = () => {
    dispatch(editVisible(false));
  };
  const handleEditChange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      console.log("value,e.target.value", value, e.target.value);
      const result = value.replace(/[^a-zA-Z\s]/g, "");
      dispatch(selectedIndex({ ...seletedBranch, [name]: result }));
    } else {
      dispatch(selectedIndex({ ...seletedBranch, [name]: value }));
      console.log("profile", seletedBranch);
    }
  };
  const menu = (
    <Menu
      items={[
        {
          label: <p className="dropdown-user-name">{doctorInfo?.name}</p>,
          icon: "N:",
          key: "0",
        },
        {
          label: <p className="dropdown-user-name">{doctorInfo?.mobile}</p>,
          icon: "P:",
          key: "1",
        },
        {
          label: <p className="dropdown-user-name">{doctorInfo?.email}</p>,
          icon: "E:",
          key: "2",
        },
        {
          type: "divider",
        },
        {
          label: <p onClick={onLogout}>Logout</p>,
          key: "3",
        },
        {
          type: "divider",
        },
      ]}
    />
  );
  const doctorsMenu = (
    <Menu
      items={doctorInfo?.locations?.map((item, i) => ({
        key: i,
        label: <p onClick={() => onDoctorHandle(item?.id)}>{item?.name}</p>,
      }))}
    />
  );
  // const clinicNames = doctorInfo?.locations?.map((item) => item.name.toString())

  const onEditHandle = (id) => {
    console.log("indehshshx", id);
    dispatch(editVisible(true));
    let singleBranch = doctorInfo.locations.find((item) => item.id === id);
    console.log("singleBranch", singleBranch);
    dispatch(selectedIndex(singleBranch));
  };
  const weekEditChange = (e, i) => {
    console.log("checked", e.target.checked, seletedBranch.displayTiming, i);
    let arr = [...seletedBranch.displayTiming];
    if (e.target.checked === true) {
      arr[i].days = [...arr[i].days, Number(e.target.value)];
      console.log("array days checked", arr[i].days);

      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr }));
    } else {
      arr[i].days = [...arr[i].days].filter(
        (item) => item !== Number(e.target.value)
      );
      console.log("array days unchecked", arr[i].days);
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr }));
    }
  };

  const startTimeEditOnChange = (time, timeString, i) => {
    let arr1 = [...seletedBranch.displayTiming];
    arr1[i].slots[0].start = `${timeString.split(":")[0]}:${
      timeString.split(":")[1]
    }`;
    dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }));
  };
  const endTimeEditOnChange = (time, timeString, i) => {
    let arr1 = [...seletedBranch.displayTiming];
    arr1[i].slots[0].end = `${timeString.split(":")[0]}:${
      timeString.split(":")[1]
    }`;
    dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }));
  };

  const startTimeEvngEditOnChange = (time, timeString, i) => {
    let arr1 = [...seletedBranch.displayTiming];
    console.log("sdjjd", arr1, i, timeString);
    if (arr1[i]?.slots[1]?.start || arr1[i]?.slots[1]?.end) {
      arr1[i].slots[1].start = `${timeString.split(":")[0]}:${
        timeString.split(":")[1]
      }`;
      console.log("dispatechedtime", arr1);
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }));
    } else {
      arr1[i]?.slots.push({
        start: `${timeString.split(":")[0]}:${timeString.split(":")[1]}`,
      });
      console.log("dispatechedtime", arr1);
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }));
    }
  };

  const endTimeEvngEditOnChange = (time, timeString, i) => {
    let arr1 = [...seletedBranch.displayTiming];
    if (arr1[i]?.slots[1]?.end || arr1[i]?.slots[1]?.start) {
      console.log("dispatechedtime", arr1);
      arr1[i].slots[1].end = `${timeString.split(":")[0]}:${
        timeString.split(":")[1]
      }`;
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }));
    } else {
      arr1[i].slots.push({
        end: `${timeString.split(":")[0]}:${timeString.split(":")[1]}`,
      });
      console.log("dispatechedtime", arr1);
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }));
    }
  };

  const handleEditState = (value) => {
    dispatch(selectedIndex({ ...seletedBranch, state: value }));
    const editCity = statecityList[value];
    setEditCityList(editCity);
    console.log("profile", seletedBranch);
  };
  const handleEditCity = (value) => {
    dispatch(selectedIndex({ ...seletedBranch, city: value }));
  };
  const handleEditSlotDuration = (value) => {
    dispatch(selectedIndex({ ...seletedBranch, slotDuration: value }));
  };
  const handleEditSubmit = () => {
    if (seletedBranch.pincode.length < 6) {
      toast.error("Please add valid pincode");
    } else if (seletedBranch.mobile.length < 10) {
      toast.error("Please add valid mobile number");
    } else {
      let foundIndex = doctorInfo.locations.findIndex(
        (item) => item.id === seletedBranch.id
      );
      let arr = [...doctorInfo.locations];
      arr[foundIndex] = seletedBranch;
      dispatch(
        updateProfileDetails(
          { ...doctorInfo, locations: arr },
          doctorInfo,
          seletedBranch
        )
      );
      doctorInfo.locations = arr;
      // dispatch(Locations(doctorInfo?.locations?.filter(item => item.id === seletedBranch.id)))
      dispatch(editVisible(false));
    }
  };
  console.log("seletedBranch", seletedBranch);
  const addEditSlotMethod = () => {
    dispatch(
      selectedIndex({
        ...seletedBranch,
        displayTiming: [
          ...seletedBranch.displayTiming,
          {
            days: [],
            slots: [
              {
                start: "",
                end: "",
              },
            ],
          },
        ],
      })
    );
  };
  const removeEditSlot = (index) => {
    if (seletedBranch.displayTiming.length !== 1)
      dispatch(
        selectedIndex({
          ...seletedBranch,
          displayTiming: [...seletedBranch.displayTiming].filter(
            (item, i) => i !== index
          ),
        })
      );
  };

  return (
    <div>
      <div
        className="header-container"
        style={{
          background: myDomainInfo?.DomainStyles?.buttonColor
            ? myDomainInfo?.DomainStyles?.buttonColor
            : "#007D9D",
        }}
      >
        <div className="header-left">
          <div className="avatar-name">
            <Dropdown overlay={doctorsMenu}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <h1>
                    <Avatar
                      style={{
                        backgroundColor: "#00576E",
                        color: "#FDC132",
                        textTransform: "capitalize",
                      }}
                      size="large"
                    >
                      {clinicName?.length === 0
                        ? doctorInfo?.locations?.[0]?.name?.[0]
                        : clinicName[0]?.name[0]}
                    </Avatar>
                  </h1>
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="clinic">
            <h1>
              {clinicName?.length === 0
                ? doctorInfo?.locations?.[0]?.name
                : clinicName[0]?.name}
            </h1>
            <p>
              {clinicName?.length === 0
                ? doctorInfo?.locations?.[0]?.city
                : clinicName[0]?.city}
              ,
              {clinicName?.length === 0
                ? doctorInfo?.locations?.[0]?.state
                : clinicName[0]?.state}
            </p>
          </div>
          {/* <div className="edit-pencil-sec">
            <p className="edit-icon-header"><img onClick={() => onEditHandle(clinicName.length === 0 ? doctorInfo?.locations?.[0].id : clinicName[0]?.id)} src={editIcon} alt="edit-icon" /></p>
          </div> */}
        </div>
        <div className="header-right">
          <div className="header-right">
            <div className="font-icon-section">
              <Cast />
            </div>
          </div>
          <div className="header-right user-info">
            <span>
              <img
                className="user-icon-header"
                src={userIcon}
                alt="user-icon"
              />
            </span>
            <div>
              <h5>{doctorInfo?.name}</h5>
              <p>
                {doctorInfo?.education},{doctorInfo?.speciality}
              </p>
            </div>
            <div className="doctor-dropdown">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a>
                  <Space>
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      {/* edit branch */}
      {editVisibleToggle && (
        <Modal
          title="EDIT BRANCH"
          centered
          visible={editVisibleToggle}
          onOk={() => dispatch(editVisible(false))}
          onCancel={editModalClose}
          width={1000}
        >
          <div>
            <div className="add-branch-modal-sec">
              <p>FACILITY NAME</p>
              <div className="add-branch-input">
                <InputField
                  type="text"
                  name="name"
                  value={
                    seletedBranch
                      ? seletedBranch.name
                      : doctorInfo?.locations?.[0]?.name
                  }
                  placeholder="Facility name"
                  onChange={handleEditChange}
                />
              </div>
            </div>

            <div className="add-branch-modal-sec">
              <p>TIME SLOTS FOR APPOINTMENTS</p>
              <div style={{ width: "70%" }}>
                {seletedBranch
                  ? seletedBranch?.displayTiming?.map((item, i) => (
                      <>
                        <div className="add-branch-profile-slot">
                          <div className="profile-day-check">
                            {weeksData.map((day) => (
                              <Checkbox
                                className="check-width"
                                checked={
                                  item.days.filter((e) => e == day.id).length >
                                  0
                                }
                                onChange={(e) => weekEditChange(e, i)}
                                name={day.name}
                                value={day.id}
                              >
                                {day.name}
                              </Checkbox>
                            ))}
                          </div>
                          <div className="modal-slot-icons">
                            <img
                              src={plusIcon}
                              alt="plus"
                              className="profile-modal-plus"
                              onClick={addEditSlotMethod}
                            />
                            <img
                              src={deleteIcon}
                              alt="delete"
                              className="medicine-table-delete"
                              onClick={() => removeEditSlot(i)}
                            />
                          </div>
                        </div>
                        <div className="profile-time-main-sec">
                          <div className="profile-mornig-sec">
                            <h4 className="morning-p">Morning</h4>
                            <div style={{ display: "flex", width: "100%" }}>
                              <div className="profile-mornig-sec">
                                <h4>Start Time</h4>
                                <TimePicker
                                  className="add-start-time"
                                  onChange={(time, timeString) =>
                                    startTimeEditOnChange(time, timeString, i)
                                  }
                                  placeholder=""
                                  defaultValue={
                                    item?.slots?.[0]?.start
                                      ? moment(item.slots[0].start, "HH:mm")
                                      : moment("00:00:00", "HH:mm")
                                  }
                                />
                              </div>
                              <div className="profile-mornig-sec">
                                <h4>End Time</h4>
                                <TimePicker
                                  className="add-start-time"
                                  onChange={(time, timeString) =>
                                    endTimeEditOnChange(time, timeString, i)
                                  }
                                  placeholder=""
                                  defaultValue={
                                    item?.slots?.[0]?.end
                                      ? moment(item.slots[0].end, "HH:mm")
                                      : moment("00:00:00", "HH:mm")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="profile-evng-sec">
                            <h4 className="morning-p">Evening</h4>
                            <div style={{ display: "flex", width: "100%" }}>
                              <div className="profile-mornig-sec">
                                <h4>Start Time</h4>
                                <TimePicker
                                  className="add-start-time"
                                  onChange={(time, timeString) =>
                                    startTimeEvngEditOnChange(
                                      time,
                                      timeString,
                                      i
                                    )
                                  }
                                  placeholder=""
                                  defaultValue={
                                    item?.slots?.[1]?.start
                                      ? moment(item.slots[1].start, "HH:mm")
                                      : moment("00:00:00", "HH:mm")
                                  }
                                />
                              </div>
                              <div className="profile-mornig-sec">
                                <h4>End Time</h4>
                                <TimePicker
                                  className="add-start-time"
                                  onChange={(time, timeString) =>
                                    endTimeEvngEditOnChange(time, timeString, i)
                                  }
                                  placeholder=""
                                  defaultValue={
                                    item?.slots?.[1]?.end
                                      ? moment(item.slots[1].end, "HH:mm")
                                      : moment("00:00:00", "HH:mm")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  : doctorInfo?.locations?.[0]?.displayTiming?.map(
                      (item, i) => (
                        <>
                          <div className="add-branch-profile-slot">
                            <div className="profile-day-check">
                              {weeksData.map((day) => (
                                <Checkbox
                                  className="check-width"
                                  checked={
                                    item.days.filter((e) => e == day.id)
                                      .length > 0
                                  }
                                  onChange={(e) => weekEditChange(e, i)}
                                  name={day.name}
                                  value={day.id}
                                >
                                  {day.name}
                                </Checkbox>
                              ))}
                            </div>
                            <div className="modal-slot-icons">
                              <img
                                src={plusIcon}
                                alt="plus"
                                className="profile-modal-plus"
                                onClick={addEditSlotMethod}
                              />
                              <img
                                src={deleteIcon}
                                alt="delete"
                                className="medicine-table-delete"
                                onClick={() => removeEditSlot(i)}
                              />
                            </div>
                          </div>
                          <div className="profile-time-main-sec">
                            <div className="profile-mornig-sec">
                              <h4 className="morning-p">Morning</h4>
                              <div style={{ display: "flex", width: "100%" }}>
                                <div className="profile-mornig-sec">
                                  <h4>Start Time</h4>
                                  <TimePicker
                                    className="add-start-time"
                                    onChange={(time, timeString) =>
                                      startTimeEditOnChange(time, timeString, i)
                                    }
                                    placeholder=""
                                    defaultValue={
                                      item?.slots?.[0]?.start
                                        ? moment(item.slots[0].start, "HH:mm")
                                        : moment("00:00:00", "HH:mm")
                                    }
                                  />
                                </div>
                                <div className="profile-mornig-sec">
                                  <h4>End Time</h4>
                                  <TimePicker
                                    className="add-start-time"
                                    onChange={(time, timeString) =>
                                      endTimeEditOnChange(time, timeString, i)
                                    }
                                    placeholder=""
                                    defaultValue={
                                      item?.slots?.[0]?.end
                                        ? moment(item.slots[0].end, "HH:mm")
                                        : moment("00:00:00", "HH:mm")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="profile-evng-sec">
                              <h4 className="morning-p">Evening</h4>
                              <div style={{ display: "flex", width: "100%" }}>
                                <div className="profile-mornig-sec">
                                  <h4>Start Time</h4>
                                  <TimePicker
                                    className="add-start-time"
                                    onChange={(time, timeString) =>
                                      startTimeEvngEditOnChange(
                                        time,
                                        timeString,
                                        i
                                      )
                                    }
                                    placeholder=""
                                    defaultValue={
                                      item?.slots?.[1]?.start
                                        ? moment(item.slots[1].start, "HH:mm")
                                        : moment("00:00:00", "HH:mm")
                                    }
                                  />
                                </div>
                                <div className="profile-mornig-sec">
                                  <h4>End Time</h4>
                                  <TimePicker
                                    className="add-start-time"
                                    onChange={(time, timeString) =>
                                      endTimeEvngEditOnChange(
                                        time,
                                        timeString,
                                        i
                                      )
                                    }
                                    placeholder=""
                                    defaultValue={
                                      item?.slots?.[1]?.end
                                        ? moment(item.slots[1].end, "HH:mm")
                                        : moment("00:00:00", "HH:mm")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}
              </div>
            </div>
            <div className="add-branch-modal-sec">
              <p>SLOT DURATION</p>
              <div className="add-branch-input">
                {" "}
                <SingleSelect
                  data={slotList}
                  value={
                    seletedBranch
                      ? seletedBranch.slotDuration
                      : doctorInfo?.locations?.[0]?.slotDuration
                  }
                  onChange={handleEditSlotDuration}
                />
              </div>
            </div>
            <div className="add-branch-modal-sec">
              <p>ADDRESS LINE 1</p>
              <div className="add-branch-input">
                <InputField
                  type="text"
                  name="address1"
                  value={
                    seletedBranch
                      ? seletedBranch.address1
                      : doctorInfo?.locations?.[0]?.address1
                  }
                  placeholder="Address Line 1"
                  onChange={handleEditChange}
                />
              </div>
            </div>
            <div className="add-branch-modal-sec">
              <p>ADDRESS LINE 2</p>
              <div className="add-branch-input">
                <InputField
                  placeholder="Address Line 2"
                  name="address2"
                  value={
                    seletedBranch
                      ? seletedBranch.address2
                      : doctorInfo?.locations?.[0]?.address2
                  }
                  onChange={handleEditChange}
                />
              </div>
            </div>
            <div className="add-branch-modal-sec">
              <p>STATE</p>
              <div className="add-branch-input">
                <SingleSelect
                  placeholder="Select State"
                  name="state"
                  value={
                    seletedBranch
                      ? seletedBranch.state
                      : doctorInfo?.locations?.[0]?.state
                  }
                  data={statesList}
                  onChange={handleEditState}
                />
              </div>
            </div>
            <div className="add-branch-modal-sec">
              <p>CITY</p>
              <div className="add-branch-input">
                <SingleSelect
                  placeholder="City"
                  value={
                    seletedBranch
                      ? seletedBranch.city
                      : doctorInfo?.locations?.[0]?.city
                  }
                  onChange={handleEditCity}
                  data={editcityList}
                />
              </div>
            </div>
            <div className="add-branch-modal-sec">
              <p>PINCODE</p>
              <div className="add-branch-input">
                {" "}
                <InputField
                  type="number"
                  value={
                    seletedBranch
                      ? seletedBranch.pincode
                      : doctorInfo?.locations?.[0]?.pincode
                  }
                  name="pincode"
                  onChange={handleEditChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                />
              </div>
            </div>
            <div className="add-branch-modal-sec">
              <p>CONTACT NUMBER</p>
              <div className="add-branch-input">
                <InputField
                  type="number"
                  placeholder="Apointment contact Number"
                  name="mobile"
                  value={
                    seletedBranch
                      ? seletedBranch.mobile
                      : doctorInfo?.locations?.[0]?.mobile
                  }
                  onChange={handleEditChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                />
              </div>
            </div>

            <div className="add-branch-modal-sec">
              <p></p>
              <div className="add-branch-modal-btn">
                <Button onClick={handleEditSubmit}>SAVE</Button>
                <Button
                  className={"add-branch-modal-cancel-btn"}
                  onClick={editModalClose}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
          {/* <div>
            <div className="add-branch-modal-sec">
              <p>FACILITY NAME</p>
              <div className="add-branch-input"><InputField type="text" name="name" value={seletedBranch ? seletedBranch.name : doctorInfo?.locations?.[0]?.name} placeholder="Facility name" onChange={handleEditChange} /></div>
            </div>

            <div className="add-branch-modal-sec">
              <p>TIME SLOTS FOR APPOINTMENTS</p>
              <div style={{ width: "70%" }}>
                {seletedBranch ? seletedBranch?.displayTiming?.map((item, i) => <><div className="add-branch-profile-slot">
                  <div className="profile-day-check">
                    {weeksData.map((day) => (
                      <Checkbox className="check-width" checked={item.days.filter(e => e == day.id).length > 0} onChange={(e) => weekEditChange(e, i)} name={day.name} value={day.id}>{day.name}</Checkbox>
                    ))}
                  </div>
                  <div className="modal-slot-icons">
                    <img src={plusIcon} alt="plus" className="profile-modal-plus" onClick={addSlotMethod} />
                    <img src={deleteIcon} alt="delete" className="medicine-table-delete" onClick={() => removeSlotAdd(i)} />

                  </div>

                </div>
                  <div className="modal-adding-slot-sec">
                    <div className="add-branch-modal-right-one">
                      <h5 className="add-branch-modal-slot-wd">SLOT NAME</h5>
                      <h5 className="add-branch-modal-start-time">START TIME</h5>
                      <h5 className="add-branch-modal-start-time" >END TIME</h5>
                      <h6></h6>

                    </div>

                    <div className="add-branch-modal-slot-details-one">
                      <InputField type="text" value={`slot ${i + 1}`} />
                      <div>
                        <TimePicker className="add-start-time" onChange={(time, timeString) => startTimeEditOnChange(time, timeString, i)} placeholder="" defaultValue={moment(item.slots[0].start, 'HH:mm')} />
                      </div>
                      <div>
                        <TimePicker className="add-start-time" onChange={(time, timeString) => endTimeEditOnChange(time, timeString, i)} defaultValue={moment(item.slots[0].end, 'HH:mm')} placeholder="" />
                      </div>
                    </div>
                  </div></>) : doctorInfo?.locations?.[0]?.displayTiming?.map((item, i) => <><div className="add-branch-profile-slot">
                    <div className="profile-day-check">
                      {weeksData.map((day) => (
                        <Checkbox className="check-width" checked={item.days.filter(e => e == day.id).length > 0} onChange={(e) => weekEditChange(e, i)} name={day.name} value={day.id}>{day.name}</Checkbox>
                      ))}
                    </div>
                    <div className="modal-slot-icons">
                      <img src={plusIcon} alt="plus" className="profile-modal-plus" onClick={addSlotMethod} />
                      <img src={deleteIcon} alt="delete" className="medicine-table-delete" onClick={() => removeSlotAdd(i)} />

                    </div>

                  </div>
                    <div className="modal-adding-slot-sec">
                      <div className="add-branch-modal-right-one">
                        <h5 className="add-branch-modal-slot-wd">SLOT NAME</h5>
                        <h5 className="add-branch-modal-start-time">START TIME</h5>
                        <h5 className="add-branch-modal-start-time" >END TIME</h5>
                        <h6></h6>

                      </div>

                      <div className="add-branch-modal-slot-details-one">
                        <InputField type="text" value={`slot ${i + 1}`} />
                        <div>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => startTimeEditOnChange(time, timeString, i)} placeholder="" defaultValue={moment(item.slots[0].start, 'HH:mm')} />
                        </div>
                        <div>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => endTimeEditOnChange(time, timeString, i)} defaultValue={moment(item.slots[0].end, 'HH:mm')} placeholder="" />
                        </div>
                      </div>
                    </div></>)}

              </div>

            </div>
            <div className="add-branch-modal-sec">
              <p>ADDRESS LINE 1</p>
              <div className="add-branch-input"><InputField type="text" name="address1" value={seletedBranch ? seletedBranch.address1 : doctorInfo?.locations?.[0]?.address1} placeholder="Address Line 1" onChange={handleEditChange} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>ADDRESS LINE 2</p>
              <div className="add-branch-input"><InputField placeholder="Address Line 2" name="address2" value={seletedBranch ? seletedBranch.address2 : doctorInfo?.locations?.[0]?.address2} onChange={handleEditChange} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>STATE</p>
              <div className="add-branch-input"><SingleSelect placeholder="Select State" name="state" value={seletedBranch ? seletedBranch.state : doctorInfo?.locations?.[0]?.state} data={statesList} onChange={handleEditState} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>CITY</p>
              <div className="add-branch-input"><SingleSelect placeholder="City" value={seletedBranch ? seletedBranch.city : doctorInfo?.locations?.[0]?.city} onChange={handleEditCity} data={editcityList} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>PINCODE</p>
              <div className="add-branch-input"> <InputField type="number" value={seletedBranch ? seletedBranch.pincode : doctorInfo?.locations?.[0]?.pincode} name="pincode" onChange={handleEditChange} onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
              }} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>CONTACT NUMBER</p>
              <div className="add-branch-input"><InputField type="number" placeholder="Apointment contact Number" name="mobile" value={seletedBranch ? seletedBranch.mobile : doctorInfo?.locations?.[0]?.mobile} onChange={handleEditChange} onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
              }} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>NO.OF SLOTS</p>
              <div className="add-branch-input"> <InputField typ="number" name="appointmentsPerSlot" value={seletedBranch ? seletedBranch.appointmentsPerSlot : doctorInfo?.locations?.[0]?.appointmentsPerSlot} onChange={handleEditChange} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>SLOT DURATION</p>
              <div className="add-branch-input"> <SingleSelect data={slotList} value={seletedBranch ? seletedBranch.slotDuration : doctorInfo?.locations?.[0]?.slotDuration} onChange={handleEditSlotDuration} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p></p>
              <div className="add-branch-modal-btn">
                <Button onClick={handleEditSubmit}>SAVE</Button>
                <Button className={"add-branch-modal-cancel-btn"} onClick={editModalClose}>CANCEL</Button>
              </div>
            </div>
          </div> */}
        </Modal>
      )}
    </div>
  );
};

export default Header;
