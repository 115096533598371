import React from 'react'
import Input from '../../../UIComponents/Input/Input'
import Button from '../../../UIComponents/Button/Button'
import "./Alternate.css"
import { alternateNumber } from '../../../redux/actions/admin/alternateNum'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'

const Alternate = () => {

  const dispatch = useDispatch();
  const [alternateAlert, setAlternateAlert] = useState('');

  const clear = () =>{
      setAlternateAlert('');
  }

  const onChangealert = (e) =>{
    setAlternateAlert(e.target.value)
  }
  const alertNum  = (e) =>{
    if(!alternateAlert){
      toast.error("please fill the input field")
    }
    else if(alternateAlert.length < 10){
      toast.error("enter valid mobile number")
    }
    else{
      e.preventDefault();
      dispatch(alternateNumber(alternateAlert));
      setAlternateAlert('');
    }
  }
  return (
    <div className='alt-container'>
        <h2 className='alt-title'>Use Alternate Number for Alert</h2>
     <div className='alt-section'>
     <div className='alt-inp'>
            <label for="mob" className='alt-label'>MOBILE NUMBER</label>
            <Input type="number" value={alternateAlert} onChange={onChangealert}  id="mob" className="alt-inp-one"  onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
          }} />
     </div>
     {/* <div className='alt-inp'>
            <label for="mob" className='alt-label'>ALTERNATE MOBILE NUMBER</label>
            <Input type="number" id="mob" className="alt-inp-two"/>
     </div> */}
        <div className='calls-modal-btn'>
         <Button onClick={alertNum}  children="SAVE" height="38px" width="90px" fontSize="15px" />
         <Button onClick={clear} className="marg-lef" children="CLEAR" height="38px" width="90px" fontSize="15px" bgColor="#ccc"/>
         </div>
     </div>
    </div>
  )
}

export default Alternate