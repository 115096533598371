import React from 'react'
import '../Electrolytes.css'
import Input from '../../../../UIComponents/Input/Input'
import { useDispatch, useSelector } from 'react-redux'
import { field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useState } from 'react'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'

export const Female = () => {
  const dispatch = useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  console.log("reportDataFields", reportDataFields);
  const singleReport = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic?.testReport)
  console.log("singleReport", singleReport);
  const setDiagnosticData = useSelector(state => state?.diagnosticsInvoiceSummary)
  console.log("setDiagnosticData", setDiagnosticData);
  const genderNamee = useSelector(state => state?.diagnosticsInvoiceSummary?.genderNames)
  console.log("genderNamee", genderNamee);
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [inputValue, setInputValue] = useState("")
  
  const onInputChange = (e) => {
    console.log("e", e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'ELECTROLYTES')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if (item?.test === 'ELECTROLYTES') {
        index = i
      }
      return item
    })
    console.log("fihjorinjiojninr", filterData);
    const filterGender = Object?.keys(filterData[0]?.map)
    .filter(key => genderNamee?.includes(key))
    .reduce((obj,key) => { 
      obj[key] = filterData[0]?.map[key]
      return obj[key]
    },{})
    const finalFilteredData = filterGender?.fields?.map(item => {
      if (item.particular === name) {
        item.result = value
      }
      return item
    })
    filterData[0].map.Female.fields = finalFilteredData
    reportDataFields.testReport[index] = filterData[0]
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) => {
    dispatch(field({ fieldKey: value }))
  }
  
  return (
    <div>
      <div className='electrolyte-grid'>
        <div className='elec1 '></div>
        <div className='elec2 male-pad'>Total Cholesterol</div>
        <div className='elec3'><Input className={"male-inp"} name="Total Cholesterol" value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[0]?.result} onChange={onInputChange} disabled={!reportDataFields.active} /></div>
        <div className='elec4 male-pad'>mg/dl</div>
        <div className='elec5 male-pad'>&lt; 200</div>
        <span className='elec-line'></span>
        <div className='elec7 '></div>
        <div className='elec8 male-pad'>	Triglycerides</div>
        <div className='elec9'><Input className={"male-inp"} name="Triglycerides" value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[1]?.result} onChange={onInputChange} disabled={!reportDataFields.active} /></div>
        <div className='elec10 male-pad'>mg/dl</div>
        <div className='elec11 male-pad'>&lt; 150</div>
        <span className='elec-line2'></span>
        <div className='elec13 '></div>
        <div className='elec14 male-pad'>LDL Cholesterol</div>
        <div className='elec15'><Input className={"male-inp"} name="LDL Cholesterol" value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[2]?.result} onChange={onInputChange} disabled={!reportDataFields.active} /></div>
        <div className='elec16 male-pad'>mg/dl</div>
        <div className='elec17 male-pad'>&lt; 100</div>
        <span className='elec-line3'></span>
        <div className='elec18 '></div>
        <div className='elec19 male-pad'>VLDL Cholesterol</div>
        <div className='elec20'><Input className={"male-inp"} name="VLDL Cholesterol" value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[3]?.result} onChange={onInputChange} disabled={!reportDataFields.active} /></div>
        <div className='elec21 male-pad'>mg/dl</div>
        <div className='elec22 male-pad'>&lt; 30</div>
        <span className='elec-line4'></span>
        <div className='elec23 '></div>
        <div className='elec24 male-pad'>HDL Cholesterol</div>
        <div className='elec25'><Input className={"male-inp"} name="HDL Cholesterol" value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[4]?.result} onChange={onInputChange} disabled={!reportDataFields.active} /></div>
        <div className='elec26 male-pad'>mg/dl</div>
        <div className='elec27 male-pad'>&lt; 50</div>
        <span className='elec-line5'></span>
        <div className='elec28 '></div>
        <div className='elec29 male-pad'> Non HDL Cholesterol</div>
        <div className='elec30'><Input className={"male-inp"} name="Non HDL Cholesterol" value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[5]?.result} onChange={onInputChange} disabled={!reportDataFields.active} /></div>
        <div className='elec31 male-pad'>mg/dl</div>
        <div className='elec32 male-pad'>&lt; 130</div>
        <span className='elec-line6'></span>
        <div className='elec33 '></div>
        <div className='elec34 male-pad'> LDL/HDL ratio</div>
        <div className='elec35'><Input className={"male-inp"} name="LDL/HDL ratio" value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[6]?.result} onChange={onInputChange} disabled={!reportDataFields.active} /></div>
        <div className='elec36 male-pad'>ngml</div>
        <div className='elec37 male-pad'>0.5 - 3.0</div>
        <span className='elec-line7'></span>
        <div className='elec38 '></div>
        <div className='elec39 male-pad'>Total Cholesterol / HDL ratio</div>
        <div className='elec40'><Input className={"male-inp"} name="Total Cholesterol / HDL ratio" value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[7]?.result} onChange={onInputChange} disabled={!reportDataFields.active} /></div>
        <div className='elec41 male-pad'>ngml</div>
        <div className='elec42 male-pad'>3.3 - 4.4</div>
        <span className='elec-line8'></span>
        <div className='elec43 male-pad' onClick={() => onLabelClicked("Remark")} > Remark</div>
        <div className='elec44'> <TextAreaAnt name='Remark' value={reportDataFields?.testReport?.[indexValue]?.map?.Female?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
        <span className='elec-line9'></span>

      </div>
    </div>
  )
}
