import React from "react";
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import "./abdomenAndKub.css"
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from "../ReportFooter";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


function AbdomenAndKub() {
  const dispatch =  useDispatch()
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.index)
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const [ inputValue, setInputValue ] = useState("")

  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value})) 
  }

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'ULTRASONOGRAPHIC FINDINGS OF WHOLE ABDOMEN & KUB REGION')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'ULTRASONOGRAPHIC FINDINGS OF WHOLE ABDOMEN & KUB REGION'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  
  return (

    <div>
      <div className="main-content">
        <div className="left-side-content">
          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("LIVER")}>LIVER</p>
            <span className="item13 ">
              <TextAreaAnt name="LIVER" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("GALL BLADDER")} >GALL BLADDER</p>
            <span className="item13 ">
              <TextAreaAnt name="GALL BLADDER" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}  />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("PV/CBD")} >PV/CBD</p>
            <span className="item13 ">
              <TextAreaAnt name="PV/CBD" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("SPLEEN")}>SPLEEN</p>
            <span className="item13 ">
              <TextAreaAnt name="SPLEEN" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("PANCREAS")}>PANCREAS</p>
            <span className="item13 ">
              <TextAreaAnt name="PANCREAS" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active}  />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("KIDNEYS")}  >KIDNEYS</p>
            <span className="item13 ">
              <TextAreaAnt name="KIDNEYS" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("URINARY BLADDER")} >URINARY BLADDER</p>
            <span className="item13 ">
              <TextAreaAnt name="URINARY BLADDER" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("UTERUS")} >UTERUS</p>
            <span className="item13 ">
              <TextAreaAnt name="UTERUS" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("ADNEXA")} >ADNEXA</p>
            <span className="item13 ">
              <TextAreaAnt name="ADNEXA" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} />
            </span>
          </div>

          <div className="usg-input">
            <p className="item12" onClick={() => onLabelClicked("IMPRESSION")} >IMPRESSION</p>
            <span className="item13 ">
              <TextAreaAnt name="" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} />
            </span>
          </div>
        </div>
            <div>
                <TestReportRightSection />
            </div>
      </div>
          <div className='diet-footer'>
                <ReportFooter  />
          </div>
    </div>

  );
}

export default AbdomenAndKub;
