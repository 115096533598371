import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import { DatePicker, Drawer, Modal, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import folder from "../../../images/Folder.png";
import lock from "../../../images/lock.png";
import Unlock from "../../../images/Unlock.png";
import { searchAdvPatient } from "../../../redux/actions/appointments/patientAdvSearch";
import { searchPatient } from "../../../redux/actions/appointments/searchPatient";
import { closeDiagnostics, getAllDiagnostic, getAllDiagnosticlist, getDiagnostics, getPatientDetails, getPatietClick, printDiagnosticsInvoices, printTestReports, reopenDiagnostics, resetGetDiagnostics, toggleButton, updateServiceDiagnostic } from "../../../redux/actions/Diagnostics/diagnostics";
import { getPatient, getPatientToggle, resetGetPatient } from "../../../redux/actions/Diagnostics/getPatient";
import Input from "../../../UIComponents/Input/Input";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import { AnalyticsModal } from "../DiagnosticTab/AnalyticsModal";
import "./DiagnosticPickLoaction.css";
import { resetSearchPatient } from "../../../redux/actions/appointments/searchPatient";
import { resetPatientSearchAdv } from "../../../redux/actions/appointments/patientAdvSearch";
import { toast } from '../../../UIComponents/Toast';
import { resetAddedData } from "../../../redux/actions/Diagnostics/diagnostics";
import { selectHandle } from "../../../redux/actions/Diagnostics/diagnostics";
import { detailSummaryDetails } from "../../../redux/actions/Diagnostics/invoiceSummary";
import { rangeStartDate, rangeEndDate } from "../../../redux/actions/Diagnostics/invoiceSummary";
const data = [
  {
    id: "File",
    name: "File",
  },
  {
    id: "Report",
    name: "Report",
  },
  {
    id: "Address",
    name: "Address",
  },
  {
    id: "DOB",
    name: "DOB",
  },
];
const DiagnosticPickLoaction = () => {
  const { RangePicker } = DatePicker;

  //ToolTips
  const allRep = <span>All Reports</span>;
  const reopenRep = <span>Reopen Record</span>;
  const closeRep = <span>Close Record</span>;

  const dispatch = useDispatch();
  const diagnosticData = useSelector((state) => state?.diagnosticsInvoiceSummary?.summary);
  const getAllDiagnosticData = useSelector((state) => state?.diagnosticsInvoiceSummary?.diagnosticlist);
  const profileInfo = useSelector((state) => state?.profile?.profileInfo);
  const searchPatientData = useSelector((state) => state?.appointments?.searchPatient?.searchPatient);
  console.log("jsdhiuxiux", searchPatientData);
  const doctorId = profileInfo?.id;
  const searchAdvPatientData = useSelector((state) => state?.appointments?.searchAdvPatient?.searchAdvPatient);
  const getDiagnosticData = useSelector((state) => state?.diagnosticsInvoiceSummary?.getdiagnostic);
  const reportDataFieldss = useSelector((state) => state?.diagnosticsInvoiceSummary?.getdiagnostic);
  const getSinglePatient = useSelector((state) => state?.getPatient?.getSinglePatient);
  const clearDate = useSelector((state) => state?.invoiceSummary?.clearDate)
  console.log("clearDate", clearDate);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [mobile, setMobile] = useState("");
  const [type, setType] = useState("uhid");
  const [by, setBy] = useState("");
  const [param, setParam] = useState("");
  const [analyticsModal, setAnalyticsModal] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [searchPatientClick, setSearchPatientClick] = useState(false);
  const [patientIdData, setPatientIdData] = useState({});
  const [todayDate, setTodayDate] = useState("");
  const [singleTId, setSingleTId] = useState("");
  const [getDate, setGetDate] = useState([]);

  const handleRangePicker = (date, dateString) => {
    const transactionDate = dateString;
    setGetDate(dateString);
    dispatch(getAllDiagnostic({ doctorId, transactionDate }));
    setTodayDate(transactionDate);
  };

  const lockHandle = (id) => {
    dispatch(
      closeDiagnostics({ doctorId, transactionId: id }, doctorId, getDate)
    );
  };

  const unlockHandle = (id, transactionId) => {
    dispatch(
      reopenDiagnostics(
        {
          modifiedBy: profileInfo?.name,
          transactionId: transactionId,
          doctorId: profileInfo?.id,
          patientId: id,
        },
        doctorId,
        getDate
      )
    );
  };

  const openModal = (id, transactionId) => {
    const getId = diagnosticData?.find((item) => item?._id === id);
    const singleTransactionId = diagnosticData?.find((item) => item?.transactionId === transactionId);
    setSingleTId(singleTransactionId?.transactionId);
    setPatientIdData(getId);
    dispatch(getAllDiagnosticlist({ doctorId, patientId: id }));
  };

  const openAnalyticsModal = () => {
    setAnalyticsModal(true);
  };

  const onPatientDetail = (id) => {
    dispatch(toggleButton(false));
    dispatch(getPatietClick(true));
    const single = diagnosticData?.find((item) => item._id === id);
    dispatch(getPatientDetails(single));
    const singlePatient = getAllDiagnosticData?.find((item) => item?._id === id);
    dispatch(getPatient({ patientId: singlePatient?.patient?.id }));
    dispatch(getDiagnostics({ doctorId: doctorId, transactionId: singlePatient?.transactionId }));
    dispatch(printDiagnosticsInvoices({ doctorId: doctorId, transactionId: singlePatient?.transactionId }));
    dispatch(printTestReports({ doctorId: doctorId, transactionId: singlePatient?.transactionId }));
    dispatch(resetGetPatient());
    setIsModalVisible(false);
    dispatch(getPatientToggle(true));
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
    setAnalyticsModal(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    dispatch(rangeStartDate([]))
    setIsModalVisible(false);
    setAnalyticsModal(false);
    dispatch(detailSummaryDetails())
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setMobile("");
    dispatch(resetSearchPatient())
    dispatch(resetPatientSearchAdv())
    setVisible(false);
  };

  const onSearchHandle = (e) => {
    setMobile(e.target.value);
  };
  const onInputChange = (e) => {
    setParam(e.target.value);
  };
  const OnSelectChange = (value) => {
    setBy(value);
  };
  const onEnterSearch = (event) => {
    if (event.key === 'Enter') {
      if (mobile.length === 0) {
        toast.error("please enter name or mobile")
      }
      else {
        dispatch(resetPatientSearchAdv())
        dispatch(searchPatient({ mobile, type }))
        setSearchData(true);
        setMobile("")
      }
    }
  }

  const onEnterAdvSearch = (event) => {
    if (event.key === 'Enter') {
      if (param.length === 0) {
        toast.error("please enter input")
      }
      else {
        dispatch(resetSearchPatient())
        dispatch(searchAdvPatient({ param, by }))
        setParam("")
        setBy("")
      }
    }
  };

  const ongetPatient = (id) => {
    dispatch(selectHandle('selected'))
    dispatch(getPatietClick(false));
    dispatch(toggleButton(true));
    dispatch(resetGetDiagnostics({}))
    const singlePtientData = searchPatientData?.find((item) => item?.id === id);
    const patientId = singlePtientData?.id;
    console.log("jgfevjae", patientId);
    dispatch(getPatientDetails(patientId));
    dispatch(getPatietClick(false));
    dispatch(getPatient({ patientId }));
    dispatch(updateServiceDiagnostic({}));
    setSearchPatientClick(true);
    setVisible(false);
    dispatch(resetSearchPatient())
    dispatch(resetPatientSearchAdv())
  };
  const ongetAdvanceSearch = (id) => {
    dispatch(selectHandle('selected'))
    dispatch(getPatietClick(false));
    dispatch(toggleButton(true));
    dispatch(resetGetDiagnostics({}))
    const singlePtientData = searchAdvPatientData?.find((item) => item?.id === id);
    const patientId = singlePtientData?.id;
    dispatch(getPatientDetails(patientId));
    dispatch(getPatietClick(false));
    dispatch(getPatient({ patientId }));
    dispatch(updateServiceDiagnostic({}));
    setSearchPatientClick(true);
    setVisible(false);
    dispatch(resetSearchPatient())
    dispatch(resetPatientSearchAdv())
  }
  return (
    <>
      <div className="pick-location-title-section">
        <div className="picklocation-title">
          <button onClick={showDrawer}>Search Patient</button>
          <button onClick={openAnalyticsModal}>Analytics</button>
        </div>
        <br></br>
        <div className="pick-date-section">
          <p className="pick-date-title">Dates</p>
        </div>
        <div className="diagnostic-to-date">
          <p className="date-title"></p>
          <DatePicker
            className="diagnostic-date"
            placeholder="DD/MM/YYYY"
            onChange={handleRangePicker}
            inputReadOnly
          />
        </div>
      </div>

      {diagnosticData?.map((item) =>
        moment(item?.createdTime).format("YYYY-MM-DD") === todayDate ? (
          <div className="diagnostic-patient-date-left-section">
            <div className="dot-date-section">
              <div>
                <p className="dot-bg"></p>
              </div>
              <div
                className="diagnostic-invoice-status-left-section"
              // onClick={() => onPatientDetail(item?._id)}
              >
                <div className="diagnostic">
                  <p>{item?.patient?.name}</p>
                  <p>{moment(item?.createdTime).format("DD MMM, YYYY")}</p>
                </div>
                <div className="diagnostic-patient-invoice">
                  <p>Invoice:{item?.invoiceNumber}</p>
                </div>
                <div className="diagnostic">
                  <p>
                    Status:{" "}
                    <span className="report-pending-heading">
                      Report Pending
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="delete-diagnostic-patient">
              {item?.active ? (
                <Tooltip placement="bottom" title={allRep}>
                  <div onClick={showModal}>
                    <img
                      className="diagnostics-folder"
                      onClick={() =>
                        openModal(item?.patient?.id, item?.transactionId)
                      }
                      src={folder}
                      alt="folderimage"
                    />{" "}
                  </div>
                </Tooltip>
              ) : (
                <></>
              )}
              {!item?.active ? (
                <Tooltip placement="bottom" title={reopenRep}>
                  <div>
                    <img
                      className="diagnostics-folder"
                      src={Unlock}
                      alt="unlockimage"
                      onClick={() =>
                        unlockHandle(item?.patient?.id, item?.transactionId)
                      }
                    />{" "}
                  </div>
                </Tooltip>
              ) : (
                <Tooltip placement="bottom" title={closeRep}>
                  <div>
                    <img
                      className="diagnostics-folder"
                      src={lock}
                      alt="lockimage"
                      onClick={() => lockHandle(item?.transactionId)}
                    />{" "}
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        ) : (
          <></>
        )
      )}
      {/* modal */}

      <Modal
        title={getAllDiagnosticData?.[0]?.patient?.name}
        visible={isModalVisible}
        onCancel={handleCancel}
        width={600}
        className="folder-modal"
      >
        {getAllDiagnosticData?.map((item) => (
          <>
            <div onClick={() => onPatientDetail(item?._id)}>
              <div className="folder-container">
                <div className="folder-section">
                  <p>{item?.patient?.name}</p>
                  <p>Invoice:{item?.invoiceNumber}</p>
                  <p>Report Ready</p>
                </div>
                <div className="folder-section">
                  <p>
                    {item?.patient?.gender} {item?.patient?.age}
                  </p>
                  <p>Ref.</p>
                </div>
                <div>
                  {!item.active ? (
                    <Tooltip placement="bottom" title={reopenRep}>
                      <div>
                        <img
                          className="diagnostics-folder"
                          src={Unlock}
                          alt="unlockImage"
                          onClick={() =>
                            unlockHandle(item?.patient?.id, item?.transactionId)
                          }
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="bottom" title={closeRep}>
                      <div>
                        <img
                          className="diagnostics-folder"
                          src={lock}
                          alt="lockImage"
                          onClick={() => lockHandle(item?.transactionId)}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
      </Modal>

      {/* Drawer */}
      <div className="drawer-container">
        <Drawer
          title="Basic Drawer"
          placement="left"
          onClose={onClose}
          visible={visible}
        >
          <p className="drawer-title">{<UserOutlined />} Search Patient</p>
          <p className="drawer-descrption">
            Please register patient in appointment panel. Use this panel to
            lookup registered patient.
          </p>
          <Input
            className="drawer-search-input"
            placeholder="Enter Name or Phone#"
            type="text"
            id="searchInput"
            onChange={onSearchHandle}
            onKeyPress={onEnterSearch}
            value={mobile}
            suffix={<SearchOutlined />}
          />
          <div className="drawer-search-division">
            <p className="drawer-adv-search">Adv Search</p>
            <SingleSelect
              data={data}
              className="search-select"
              placeholder="File"
              onChange={OnSelectChange}
              value={by}
            />
            <Input
              className="diag-search-input"
              placeholder="Seach term"
              type="search"
              onKeyPress={onEnterAdvSearch}
              onChange={onInputChange}
              suffix={<SearchOutlined />}
              value={param}
            />
          </div>
          <div>
            <p className="drawer-patient-heading">Patient</p>
          </div>
          <div className="drawer-search-container">

            {searchPatientData?.map((item, i) => (
              <div className="search-patient-container">
                <div>
                  <div className="name-phone">
                    <div>
                      <b>
                        <p>{item?.name}</p>
                      </b>
                      <p>{item?.address?.city}</p>
                      {item?.profileImage ? (
                        <div>
                          <img
                            src={item?.profileImage}
                            className="print-table-img"
                            alt="patient-photo"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      <p>Ph# {item?.mobile}</p>
                    </div>
                  </div>
                </div>
                <div className="drawer-buttons-division">
                  <div>
                    <button onClick={() => ongetPatient(item?.id)}>
                      Select
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {searchAdvPatientData?.map((item) => (
              <div className="search-patient-container">
                <div>
                  <div className="name-phone">
                    <div>
                      <b>
                        <p>{item?.name}</p>
                      </b>
                      <p>{item?.address?.city}</p>
                      {item?.profileImage ? (
                        <div>
                          <img
                            src={item?.profileImage}
                            className="print-table-img"
                            alt="patient-photo"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      <p>Ph# {item?.mobile}</p>
                    </div>
                  </div>
                </div>
                <div className="drawer-buttons-division">
                  <div>
                    <button onClick={() => ongetAdvanceSearch(item?.id)}>Select</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Drawer>
        {/* Analytics Button Modal */}
        <Modal
          title="Analytics"
          visible={analyticsModal}
          onCancel={handleCancel}
          width={1150}
        >
          <AnalyticsModal />
        </Modal>
      </div>
    </>
  );
};

export default DiagnosticPickLoaction;