import React from 'react'
import './Crp.css'
import Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

const Crp = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'CRP')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'CRP'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
    return (
        <div>
            <div className='crp-main'>
                <div className='crp-container'>
                    <div className='crp-grid-container'>
                        <div className='padd crp-1'></div>
                        <div className='padd crp-2'>C REACTIVE PROTEIN</div>
                        <Input className='aptinputs crp-3' type="text" name="C REACTIVE PROTEIN" value={reportDataFields?.testReport?.[indexValue]?.fields[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
                        <div className='padd crp-4'>mg/dl</div>
                        <div className='padd crp-5'>CHILD 2.8/ADULT up to 6/NB3WEEK 4.1</div>
                        <span className='bil-border1'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
            <div className='crp-footer'>
                <br></br>
                <p className='abg-note'>C-reactive protein (CRP).</p>
                <p className='abg-hct'>This is blood test marker for inflammation in the body. CRP is produced in the liver and its level is measured by testing the blood. CRP is classified as an acute phase reactent, which means that its level will rise in response to inflammation.</p>
            </div>
        </div>
    )
}


export default Crp