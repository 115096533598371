import React from "react";
import Input from "../../../../UIComponents/Input/Input";
import "./Immunology.css";
import { TestReportRightSection } from "../TestReportRightSection";
import ReportFooter from "../ReportFooter";
import TextAreaAnt from "../../../../UIComponents/TextArea/TextArea";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


function Immunology() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'IMMUNOLOGY')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'IMMUNOLOGY'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))     }

  return (
    <div>
      <div className="immunology-parent">
        <div className="immunology-left">
          <div className="immunology-grid">
            <div className="immunology-1 immunology-pad" onClick={() => onLabelClicked("BLOOD GROUP (ABO & Rh)")}>BLOOD GROUP (ABO & Rh)</div>
            <div className=" immunology-2 immunology-text">
              <TextAreaAnt name="BLOOD GROUP (ABO & Rh)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-1"></span>

            <div className="immunology-3 immunology-pad" onClick={() => onLabelClicked("RHEUMATHOID FACTOR")}>RHEUMATHOID FACTOR</div>
            <div className=" immunology-4 immunology-text">
              <TextAreaAnt name="RHEUMATHOID FACTOR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-2"></span>

            <div className="immunology-5 immunology-pad"></div>
            <div className="immunology-6 immunology-pad" >
              C REACTIVE PROTEIN
            </div>
            <div className="immunology-7">
              <Input type='number' className={"immunology-input"} name="C REACTIVE PROTEIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <div className="immunology-8 immunology-pad">IU/ML</div>
            <div className="immunology-9 immunology-pad"> 200</div>
            <span className="immunology-border-3"></span>

            <div className="immunology-10 immunology-pad" onClick={() => onLabelClicked("ASO TITRE")}>ASO TITRE</div>
            <div className=" immunology-11 immunology-text" >
              <TextAreaAnt name="ASO TITRE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-4"></span>

            <div className="immunology-12 immunology-pad" onClick={() => onLabelClicked("VDRL TEST")}>VDRL TEST</div>
            <div className=" immunology-13 immunology-text">
              <TextAreaAnt name="VDRL TEST" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-5"></span>

            <div className="immunology-14 immunology-pad" onClick={() => onLabelClicked("HIV I & II")}>HIV I & II</div>
            <div className=" immunology-15 immunology-text">
              <TextAreaAnt name="HIV I & II" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-6"></span>

            <div className="immunology-16 immunology-pad" onClick={() => onLabelClicked("PREGNENCY TEST")}>PREGNENCY TEST</div>
            <div className=" immunology-17 immunology-text">
              <TextAreaAnt name="PREGNENCY TEST" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-7"></span>

            <div className="immunology-18 immunology-pad" onClick={() => onLabelClicked("HBs ANTIGEN")}>HBs ANTIGEN</div>
            <div className=" immunology-19 immunology-text">
              <TextAreaAnt name="HBs ANTIGEN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-8"></span>

            <div className="immunology-20 immunology-pad" onClick={() => onLabelClicked("HCV")}>HCV</div>
            <div className=" immunology-21 immunology-text">
              <TextAreaAnt name="HCV" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-9"></span>

            <div className="immunology-22 immunology-pad" onClick={() => onLabelClicked("MY CODOT TEST")}>MY CODOT TEST</div>
            <div className=" immunology-23 immunology-text">
              <TextAreaAnt name="MY CODOT TEST" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-10"></span>

            <div className="immunology-24 immunology-pad" onClick={() => onLabelClicked("ENGUE ELISA IGM")}>ENGUE ELISA IGM</div>
            <div className=" immunology-25 immunology-text">
              <TextAreaAnt name="ENGUE ELISA IGM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-11"></span>

            <div className="immunology-26 immunology-pad" onClick={() => onLabelClicked("TYPHI DOT TEST IGG")}>
              TYPHI DOT TEST IGG
            </div>
            <div className=" immunology-27 immunology-text">
              <TextAreaAnt name="TYPHI DOT TEST IGG" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-12"></span>

            <div className="immunology-28 immunology-pad" onClick={() => onLabelClicked("TYPHI DOT TEST IGM")}>
              TYPHI DOT TEST IGM
            </div>
            <div className=" immunology-29 immunology-text">
              <TextAreaAnt name="TYPHI DOT TEST IGM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />{" "}
            </div>
            <span className="immunology-border-13"></span>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="immunology-foot">
        <ReportFooter />
      </div>
      <div className="immunology-p">
        <h4>Note:</h4>
        <p>
          These test have technical limitetion. Clinical Pathological
          interpretation is necessary
          <br />
          in case of any discrepancy test may be repeated immediately. This
          Report is not for medicolegal purpose.
        </p>
      </div>
    </div>
  );
}

export default Immunology;
