import './InvoiceTable.css';
import { Table } from 'antd';
import { useSelector } from 'react-redux';

const { Column, ColumnGroup } = Table;

const InvoiceTable = () => {
  const patientStatsTable = useSelector(state => state.analytics.patientStatsInfo)

    let new_M = 0
    let new_F = 0
    let new_T = 0
    let new_A = 0
    let Old_M = 0
    let Old_F = 0
    let Old_T = 0
    let Old_A = 0
    let Tot_M = 0
    let Tot_F = 0
    let Tot_T = 0
    let Tot_A = 0

    

    const n = patientStatsTable.map(e => {
      if(e.type === "Old"){
        if(e.gender === "M"){
          Old_M++
        }else{
          Old_F++
        } 
        Old_T++
      }
      else{
        if(e.gender === "M"){
          new_M++
        }else{
          new_F++
        }
        new_T++
      }
      if(e.gender === "M"){
        Tot_M++
      }else{
        Tot_F++
      }
      Tot_T++
    })
  
    const data = [
      {
        key: '1',
        Department: 'Dr. Jay Karkar',
        M: new_M,
        F: new_F,
        Total: new_T,
        Avg: Math.round((new_T/2)),
        M1: Old_M,
        F1: Old_F,
        Total1: Old_T,
        Avg1:Math.round((Old_T/2)),
        M2: Tot_M,
        F2: Tot_F,
        Total2: Tot_T,
        Avg2: Math.round((Tot_T/2)),
      },
      {
        key: '2',
        Department: 'Total',
        M: new_M,
        F: new_F,
        Total: new_T,
        Avg: Math.round((new_T/2)),
        M1: Old_M,
        F1: Old_F,
        Total1: Old_T,
        Avg1: Math.round((Old_T/2)),
        M2: Tot_M,
        F2: Tot_F,
        Total2: Tot_T,
        Avg2: Math.round((Tot_T/2)),
      },
    ];


  return (


    <div className='invoice-container'>
      <Table dataSource={data} pagination={{ current: 1, pageSize: data?.length + 1 }}>

        <ColumnGroup title="">
          <Column title="Department" dataIndex="Department" key="Department" />
        </ColumnGroup>
        <ColumnGroup title="New">
          <Column title="M" dataIndex="M" key="M"  />
          <Column title="F" dataIndex="F" key="F" />
          <Column title="Total" dataIndex="Total" key="Total" />
          <Column title="Avg" dataIndex="Avg" key="Avg" />
        </ColumnGroup>
        <ColumnGroup title="Old">
          <Column title="M" dataIndex="M1" key="M1" />
          <Column title="F" dataIndex="F1" key="F1" />
          <Column title="Total" dataIndex="Total1" key="Total1" />
          <Column title="Avg" dataIndex="Avg1" key="Avg1" />
        </ColumnGroup>
        <ColumnGroup title="Total">
          <Column title="M" dataIndex="M2" key="M2" />
          <Column title="F" dataIndex="F2" key="F2" />
          <Column title="Total" dataIndex="Total2" key="Total2" />
          <Column title="Avg" dataIndex="Avg2" key="Avg2" />
        </ColumnGroup>

      </Table>
    </div>
  )
};

export default InvoiceTable;