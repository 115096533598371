import * as types from '../../actionTypes';

const initialState = {
    patientAudio: [],
    getAudio: [],
    visitAudio: null,
    error: {},
    loading: false
}


export const patientAudioReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PATIENT_AUDIO:
            return {
                ...state,
                loading: true
            }
        case types.GET_PATIENT_AUDIO_SUCCESS:
            return {
                ...state,
                patientAudio: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_AUDIO_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                patientAudio: []
            }

        case types.GET_AUDIO_FILE:
            return {

                ...state,
                loading: true,
            }
        case types.GET_AUDIO_SUCCESS:
            return {
                ...state,
                getAudio: action.payload,
                loading: false,
                error: {}
            }

        case types.GET_AUDIO_FAIL:
            return {


                ...state,
                error: action.payload,
                loading: false,
                getAudio: []
            }
        case types.GET_VISIT_AUDIO_FILE:
            return {
                ...state,
                visitAudio: action.payload,
                loading: false,
            }
        case types.RESET_VISIT_AUDIO_FILE:
            return {
                ...state,
                visitAudio: action.payload,
                loading: false,
            }
        default:
            return state;
    }
}