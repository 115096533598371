import * as types from '../../actionTypes';

const initialState = {
    listValue: [],
    error: {},
    loading: {}
}


export const masterListValueReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MASTER_LIST_VALUE_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_MASTER_LIST_VALUE_SUCCESS:
            return {
                ...state,
                listValue: action.payload,
                loading: false
            }
        case types.GET_MASTER_LIST_VALUE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case types.STORE_MASTER_LIST_VALUE:
            return {
                ...state,
                listValue: [],
                loading: false
            }
        default:
            return state
    }
}