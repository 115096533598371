import React, { useEffect, useState } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../FollowUp/Followup.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import { Switch } from 'antd';
import moment from "moment";
import { DatePicker, version } from "antd";
import "antd/dist/antd.css";
import SingleSelect from '../../../../UIComponents/Select/SingleSelect'
import { useDispatch, useSelector } from 'react-redux';
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice';
import calenderLogo from '../../../../images/calender.svg';

const followUpDays = [
    {
        id: "3 Days",
        name: "3"
    },
    {
        id: "5 Days",
        name: "5"
    },
    {
        id: "7 Days",
        name: "7"
    },
    {
        id: "10 Days",
        name: "10"
    },
    {
        id: "14 Days",
        name: "14"
    },
    {
        id: "15 Days",
        name: "15"
    },
    {
        id: "20 Days",
        name: "20"
    },
    {
        id: "30 Days",
        name: "30"
    },
    {
        id: "50 Days",
        name: "50"
    },
    {
        id: "60 Days",
        name: "60"
    },
    {
        id: "90 Days",
        name: "90"
    },
    {
        id: "120 Days",
        name: "120"
    }, {
        id: "365 Days",
        name: "365"
    },
];
const followUpWeeks = [
    {
        id: "1 Week",
        name: "1 wk"
    },
    {
        id: "2 Week",
        name: "2 wk"
    },
    {
        id: "3 Week",
        name: "3 wk"
    },
    {
        id: "4 Week",
        name: "4 wk"
    },
    {
        id: "1 Month",
        name: "1 mth"
    },
    {
        id: "2 Month",
        name: "2 mth"
    },
    {
        id: "3 Month",
        name: "3 mth"
    },
    {
        id: "6 Month",
        name: "6 mth"
    },
]

function FollowUp() {
    const dispatch = useDispatch()
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    console.log("visitInfohshhss", visitInfo)

    const [nextaptdate, setNextaptDate] = useState("")
    const [nextaptdateOne, setNextaptDateOne] = useState("")
    const [nextaptdateTwo, setNextaptDateTwo] = useState("")

    const abc = visitInfo?.followupapt

    const onFollowUpDaysChange = (value) => {
        if (nextaptdate === "") {
            setNextaptDateOne(moment().add(value.split(' ')[0], 'days'));

        } else {
            setNextaptDateOne(moment(nextaptdate).add(value.split(' ')[0], 'days'));

        }
        dispatch(updateVisit({ ...visitInfo, followupapt: value }))
    }
    const onFollowUpWeeksChange = (value) => {
        if (value.split(' ')[1] === "Week") {
            if (nextaptdate === "") {
                setNextaptDateOne(moment().add(value.split(' ')[0] * 7, 'days'));

            } else {
                setNextaptDateOne(moment(nextaptdate).add(value.split(' ')[0] * 7, 'days'));
            }

        } else {
            if (nextaptdate === "") {
                setNextaptDateOne(moment().add(value.split(' ')[0], 'M'))

            } else {
                setNextaptDateOne(moment(nextaptdate).add(value.split(' ')[0], 'M'))

            }
        }
        dispatch(updateVisit({ ...visitInfo, followupapt: value }))
    }
    const onNextaptDateChange = (date, dateString) => {
        setNextaptDate(dateString)
        setNextaptDateTwo(dateString)

    }

    useEffect(() => {
        setNextaptDateTwo(nextaptdateOne)
    }, [nextaptdateOne])
    useEffect(() => {
        if (nextaptdateTwo) {
            dispatch(updateVisit({ ...visitInfo, nextaptdate: moment(nextaptdateTwo).format("DD/MM/YYYY") }))
        }
    }, [nextaptdateTwo])

    const onFollowUpChange = (checked) => {
        let sos = ""
        if (checked === true) {
            sos = "Y"
        } else {
            sos = true
        }
        dispatch(updateVisit({ ...visitInfo, followupSOS: sos }))

    }

    return (
        <div>
            <div className='follow-up-div'>
                <NumberButton className="numberButton-14">14</NumberButton>
                <div className='follow-up-14-div'>
                    <h6>FOLLOWUP</h6>
                    <p>SOS <Switch onChange={onFollowUpChange} checked={visitInfo.followupSOS === "Y" ? true : false} /></p>
                </div>
                <div className='follow-up-antSelect' >
                    <div className='follow-up-wd'>
                        <p></p>
                        <DatePicker placeholder='Pick a day' value={visitInfo?.nextaptdate && visitInfo?.nextaptdate !== "Invalid date" ? moment(visitInfo.nextaptdate, "DD/MM/YYYY") : ""} onChange={onNextaptDateChange} />
                    </div>
                    <div className='follow-up-wd-or-sec'>
                        <p>Days</p>
                        <div><SingleSelect data={followUpDays} placeholder="Select FollowUp" value={abc?.split(' ')[1] === "Days" ? abc?.split(' ')[0] : ""} onChange={onFollowUpDaysChange} /> </div>
                    </div>
                    <p style={{padding:"14px 14px 0px 5px"}}>OR</p>
                    <div className='follow-up-wd-or-sec'>
                        <p>Week or Month</p>
                        <SingleSelect data={followUpWeeks} onChange={onFollowUpWeeksChange} value={abc?.split(' ')[1] === "Week" ? abc?.split(' ')[0] : abc?.split(' ')[1] === "Month" ? abc.split(' ')[0] : ""} placeholder="Select FollowUp" />
                    </div>
                </div>
            </div>
            <Divider />
        </div>
    )
}

export default FollowUp