import * as types from "../../actionTypes";

import { client } from "../../../utils/axios";

export const getAllPatientsList = (payload) => ({
  type: types.GET_ALL_PATIENTS_LIST,
  payload,
});

const patientListLoading = () => ({
  type: types.GET_PATIENT_LIST_LOADING,
});

export const selectedPatientId = (payload) => ({
  type: types.GET_SELECTED_OPD_PATIENT,
  payload,
});

const patientListLoadingSuccess = (data) => ({
  type: types.GET_PATIENT_LIST_LOADING_SUCCESS,
  payload: data,
});
const patientListLoadingFail = (error) => ({
  type: types.GET_PATIENT_LIST_LOADING_FAIL,
  payload: error,
});

export const patientlist = (data) => {
  return function (dispatch) {
    dispatch(patientListLoading());
    client
      .post(
        `/doctor/get-my-patients`,
        data
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(patientListLoadingSuccess(resp.data));
        console.log("admin login data", resp.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(patientListLoadingFail(error));
      });
  };
};
