import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

export const printPatientProfileToggle = (payload) => ({
  type: types.PRINT_PATIENT_PROFILE_TOGGLE,
  payload,
});
const printPatientProfileLoading = () => ({
  type: types.PRINT_PATIENT_PROFILE_LOADING,
});

const printPatientProfiletSuccess = (data) => ({
  type: types.PRINT_PATIENT_PROFILE_SUCCESS,
  payload: data,
});
const printPatientProfileFail = (error) => ({
  type: types.PRINT_PATIENT_PROFILE_FAIL,
  payload: error,
});

export const printPatientProfile = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(printPatientProfileLoading());
    client
      .post(
        `/patient/print-patient-profile`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(printPatientProfiletSuccess(resp.data));
        document.getElementsByClassName("button-profile")[1].click();
      })
      .catch((error) => {
        dispatch(printPatientProfileFail(error));
      });
  };
};
