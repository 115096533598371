import React from 'react'
import '../SputumAFB/Sputum.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const WidalTest = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")
 
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'WIDAL TEST')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'WIDAL TEST'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))      }


  return (
    <div>
        
    <div className='sputum-parent'>
      <div className='sputum-left'>
          <div className='sputum-grid'>
              <div className='sputum1 sputum-pad' onClick={() => onLabelClicked("SALMONELLA TYPHI 'O' ")}>SALMONELLA TYPHI 'O'</div>
              <div className='sputum2 sputum-text'><TextAreaAnt name="SALMONELLA TYPHI 'O'" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='sputum-border'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked(" SALMONELLA TYPHI 'H'")}>SALMONELLA TYPHI 'H'</div>
              <div className='sputum-text '><TextAreaAnt name="SALMONELLA TYPHI 'H'" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='sputum-border2'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked(" SALMONELLA PARATYPHI 'AH'")}>SALMONELLA PARATYPHI 'AH'</div>
              <div className='sputum-text'><TextAreaAnt name="SALMONELLA PARATYPHI 'AH'" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='sputum-border3'></span>
              <div className='sputum-pad' onClick={() => onLabelClicked(" SALMONELLA PARATYPHI 'BH'")}>SALMONELLA PARATYPHI 'BH'</div>
              <div className='sputum-text '><TextAreaAnt name="SALMONELLA PARATYPHI 'BH'" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='sputum-border4'></span>
          </div>
      </div>
      <div>
          <TestReportRightSection />
      </div>
    </div>
    <div className='sputum-foot'>
        <ReportFooter />
    </div>
    <div className='sputum-method '>
      <h4>Note:</h4>
      <ul className='widal'>
        <li>Titres 1:80 and above of antigen 'O' antigen & 1:160 and above of 'H' antigen are significant</li>
        <li>Rising titres are significant</li>
        <li>The recommended Widal Test is by Tube Agglutination Method</li>
      </ul>
     
      <h4>Comments :</h4>
      <p>This test measures somatic O and flagellar H antibodies against Typhoid and paratyphoid bacilli. The agglutinins usually appear at the end of the first week of infection and increase steadily till 3rd or 4th week after which the decline starts. A positive Widal Test may occur because of typhoid vaccination or previous typhoid infection and in certain autoimmune diseases. Non specific febrile disease may cause this titre to increase (anamnestic reaction). The test may be falsely negative in cases of Enteric fever tested with antibiotics in the early stages. The test is recommended specially in the first week after infection in Bblood Culture.</p>
    </div>

</div>
  )
}
