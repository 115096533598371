import React from 'react'
import '../Iron/Iron.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


export const Vdrl = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'VDRL')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'VDRL'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))      }

  return (
    <div>
        
            <div className='dengue-parent'>
              <div className='dengue-left'>
                  <div className='dengue-grid'>
                      <div className='deng1 dng-pad' onClick={() => onLabelClicked(" V.D.R.L for Syphilis")}>V.D.R.L for Syphilis</div>
                      <div className='deng2 dng-text'><TextAreaAnt  name="V.D.R.L for Syphilis" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                      <span className='dng-border'></span>
                  </div>
              </div>
              <div>
                  <TestReportRightSection />
              </div>
            </div>
            <div className='dng-foot'>
                <ReportFooter />
            </div>
            <div className='dng-method'>
              <p><b>KIT USED: </b>CARBOGEN RPR CARD TEST</p>
              <p><b>REMARKS: </b> THE TEST WAS PERFORMED AGAINST KNOWN POSITIVE AND NEGATIVE CONTROLS.</p>
            </div>
       
    </div>
  )
}
