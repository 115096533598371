import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import "./AdminTab.css";
import Signup from "./Signup/Signup";
import UserSignup from "./UserSignup/UserSignup";
import DonotDisturb from "./DonotDisturb/DonotDisturb";
import DoctorStatistics from "./DoctorStatistics/DoctorStatistics";
import userIcon from "../../images/header-user-icon.svg";
import dropIcon from "../../images/drop-icon.svg";
import Alternate from "./Alternate Number/Alternate";
import SubDomain from "./SubDomain/SubDomain";
import MedicineMaster from "./MedicineMaster/MedicineMaster";
import { useDispatch } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { mastermedicine } from "../../redux/actions/medicinemaster/medicinemaster";
import { useSelector } from "react-redux";
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { useHistory } from "react-router-dom";
import { resetAdminData } from "../../redux/actions/adminlogin";
const { TabPane } = Tabs;

const AdminTabs = () => {
  const adminInfo = useSelector((state) => state.adminLogin.adminLoginInfo);
  console.log("adminInfo", adminInfo);
  const myDomainInfo = useSelector((state) => state.login.myDomainInfo);

  let dispatch = useDispatch();
  const history = useHistory();

  const medicineMasterClick = () => {
    dispatch(mastermedicine());
  };

  const onLogout = (e) => {
    dispatch(resetAdminData());
    e.preventDefault();
    window.location.href = "/";
    // history.push(' ')
  };
  const menu = (
    <Menu
      items={[
        {
          label: <p className="dropdown-user-name">{adminInfo?.user?.name}</p>,
          icon: "N:",
          key: "0",
        },
        {
          label: (
            <p className="dropdown-user-name">{adminInfo?.user?.mobile}</p>
          ),
          icon: "P:",
          key: "1",
        },
        {
          label: <p className="dropdown-user-name">{adminInfo?.user?.role}</p>,
          icon: "AD:",
          key: "2",
        },
        {
          type: "divider",
        },
        {
          label: (
            <p className="" onClick={onLogout}>
              Logout
            </p>
          ),
          key: "3",
        },
        {
          type: "divider",
        },
      ]}
    />
  );
  useEffect(() => {
    document.documentElement.style.setProperty(
      `--variablename`,
      myDomainInfo?.DomainStyles?.buttonColor
        ? myDomainInfo?.DomainStyles?.buttonColor
        : "#FDC132"
    );
  });

  // const tabChange = (key) => {
  //   history.push(`/tabs/${key}`);
  //   console.log("key", key);
  // };
  return (
    <div>
      <div className="admin-header-left">
        <div className="admin-head">
          <h1>ADMIN</h1>
        </div>
        <div className="admin-header-right">
          <div className="admin-name">
            <span>
              <img
                className="user-icon-header"
                width={20}
                src={userIcon}
                alt="user-icon"
              />
            </span>
            <div className="admin-align">
              <h4>{adminInfo?.user?.name}</h4>
              <p>ADMIN</p>
            </div>
          </div>
          <div className="arrow-img">
            <Dropdown
              className="admin-dropdown"
              overlay={menu}
              trigger={["click"]}
            >
              <a>
                <Space>
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
      <Router>
        <Switch>
          <Route
            path="/admin/:tab?"
            render={({ match, history }) => {
              return (
                <div className="admin-tabs">
                  <Switch>
                    <Tabs
                      defaultActiveKey={match.params.tab}
                      onChange={(key) => {
                        console.log(match);
                        history.push(`/admin/${key}`);
                      }}
                    >
                      {/* <div className="admin-tab-parent"> */}
                      <TabPane
                        key="doctor-signup"
                        tab={
                          <div className="admin-tab-list">
                            <p>Doctor</p>
                            <p>Signup</p>
                          </div>
                        }
                      >
                        <Signup />
                      </TabPane>
                      <TabPane
                        key="user-signup"
                        tab={
                          <div className="admin-tab-list">
                            <p>User</p> <p>Signup</p>
                          </div>
                        }
                      >
                        <UserSignup />
                      </TabPane>

                      <TabPane
                        key="donotdisturb"
                        tab={
                          <div className="admin-tab-list">
                            <p>Add Patient</p> <p> to Do Not Disturb List</p>
                          </div>
                        }
                      >
                        <DonotDisturb />
                      </TabPane>
                      {/* <TabPane
                  key="5"
                  tab={
                    <div className="admin-tab-list">
                      <p>Refresh doctors lists.</p> <p>(medicines + vitals etc)</p>
                    </div>
                  }
                >
                  Refresh doctors lists. (medicines + vitals etc)
                </TabPane> */}
                      <TabPane
                        key="alternatenumber"
                        tab={
                          <div className="admin-tab-list">
                            <p>Use alternate</p>
                            <p>Number for alert</p>
                          </div>
                        }
                      >
                        <Alternate />
                      </TabPane>
                      <TabPane
                        key="doctor-statistics"
                        tab={
                          <div className="admin-tab-list">
                            <p>Doctor</p>
                            <p>Statistics</p>
                          </div>
                        }
                      >
                        <DoctorStatistics />
                      </TabPane>
                      <TabPane
                        key="medicine-master"
                        tab={
                          <div
                            className="admin-tab-list"
                            onClick={medicineMasterClick}
                          >
                            <p>Medicine</p>
                            <p>Master</p>
                          </div>
                        }
                      >
                        <MedicineMaster />
                      </TabPane>
                      <TabPane
                        key="subdomain-register"
                        tab={
                          <div className="admin-tab-list last-tab">
                            <p>Subdomain</p>
                            <p>Register</p>
                          </div>
                        }
                      >
                        <SubDomain />
                      </TabPane>
                      {/* </div> */}
                    </Tabs>
                  </Switch>
                </div>
              );
            }}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default AdminTabs;
