import * as types from "../actionTypes";
import axios from "axios";
import { toast } from "../../UIComponents/Toast";
import SubDomainStyles from "../../utils/Style";
import Firebase from "firebase";
import { baseUrl } from "../../utils/Helper";
import { client } from "../../utils/axios";

export const resetDoctorInfo = (payload) => ({
  type: types.RESET_DOCTOR_INFO,
  payload,
});

export const storeDoctorId = (doctorId) => ({
  type: types.STORE_DOCTOR_ID,
  payload: doctorId,
});
const doctorLoginLoading = () => ({
  type: types.LOGIN_DOCTOR_LOADING,
});

const doctorLoginSuccess = (loginInfo) => ({
  type: types.LOGIN_DOCTOR_SUCCESS,
  payload: loginInfo,
});

const doctorLoginError = (error) => {
  return {
    type: types.LOGIN_DOCTOR_FAIL,
    payload: error,
  };
};

const myDomainLoading = () => ({
  type: types.MY_DOMAIN_LOADING,
});

const myDomainSuccess = (data) => ({
  type: types.MY_DOMAIN_SUCCESS,
  payload: data,
});

const myDomainFail = (error) => ({
  type: types.MY_DOMAIN_FAIL,
  payload: error,
});

export const updateLoginStatus = (data) => ({
  type: types.UPDATE_LOGIN_STATUS,
  payload: data,
});

let url = baseUrl();
export const loginDoctor = (data) => {
  console.log("domainbaseurl", `${url}doctor/login`);
  return function (dispatch) {
    dispatch(doctorLoginLoading());

    client
      .post("/doctor/login", data)
      .then((resp) => {
        dispatch(doctorLoginSuccess(resp.data));
        client.setJwtToken(resp.data.secure_token);
        dispatch(storeDoctorId(resp.data.id));
        console.log("locastorage doctor id", resp.data.id);
        Firebase.auth()
          .signInWithCustomToken(resp.data.firebase)
          .then(
            (onResolve) => {
              console.log("firebas001", "Passed Firebase Auth001", resp.data);
            },
            (onReject) => {
              console.log("firebas001", "Failed Firebase Auth");
            }
          );
        toast.success("Login Successful");
      })
      .catch((error) => {
        console.log("firebas001 error", error);
        if (error?.response?.data?.message)
          toast.error(`${error?.response?.data?.message}`);
        dispatch(doctorLoginError(error));
      });
  };
};

export const myDomainData = () => {
  return function (dispatch) {
    dispatch(myDomainLoading());
    axios
      .get(`${url}doctor/mydomain`)
      .then((resp) => {
        console.log("styles", resp.data.style);
        const DomainStyles = SubDomainStyles(resp.data.style);
        dispatch(myDomainSuccess({ DomainStyles, ...resp.data }));
        console.log("domaindata", resp.data);
      })
      .catch((error) => {
        dispatch(myDomainFail(error.response.data));
      });
  };
};
