import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './CpkMb.css'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

const CpkMb = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'CPK-MB')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'CPK-MB'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
    return (
        <div>
            <div className='cpkmb-main'>
                <div className='cpkmb-container'>
                    <form >
                    <div className='cpkmb-grid-container'>
                        <div className='padd'>BIO CHEMICAL TEST</div>
                        <div className='padd'>CPK-MB</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" name="CPK-MB" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
                        <div className='padd'>U/L</div>
                        <div className='padd'>25</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border1'></span>
                    </div>
                </form>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}


export default CpkMb