import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";
import {
  getAllDiagnostic,
  getDiagnostics,
  printDiagnosticsInvoices,
} from "./diagnostics";

const saveDiagnosticLoading = () => ({
  type: types.SAVE_DIAGNOSTIC_LOADING,
});

const saveDiagnosticSuccess = (saveDiagnostic) => ({
  type: types.SAVE_DIAGNOSTIC_SUCCESS,
  payload: saveDiagnostic,
});

const saveDiagnosticError = (error) => ({
  type: types.SAVE_DIAGNOSTIC_FAIL,
  payload: error,
});

export const resetDiagnosticData = (data) => ({
  type: types.RESET_DIAGNOSTIC_SUCCESS_DATA,
  payload: data,
});

export const saveDiagnostics = (data) => {
  console.log("saveDiagnostics", data);
  return (dispatch) => {
    dispatch(saveDiagnosticLoading());

    client
      .post(
        `/diagnostic/save-diagnostic`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(saveDiagnosticSuccess(resp.data));
        dispatch(
          getAllDiagnostic({
            doctorId: data.doctorId,
            transactionDate: data.createdDate,
          })
        );
        dispatch(
          getDiagnostics({
            doctorId: data.doctorId,
            transactionId: data.transactionId,
          })
        );
        dispatch(
          printDiagnosticsInvoices({
            doctorId: data.doctorId,
            transactionId: data.transactionId,
          })
        );
        toast.success("Report Saved Successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(saveDiagnosticError(error));
        toast.error("Report Save Failed");
      });
  };
};
