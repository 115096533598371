import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { loadMasterListValue } from "./masterListValue";
import { getDoctorListValue } from "../opd/patientreport";
import { client } from "../../../utils/axios";

export const saveMasterListValueLoading = () => ({
  type: types.SAVE_MASTER_LIST_VALUE_LOADING,
});

export const saveMasterListValueSuccess = (payload) => ({
  type: types.SAVE_MASTER_LIST_VALUE_SUCCESS,
  payload,
});

export const saveMasterListValueFail = (error) => ({
  type: types.SAVE_MASTER_LIST_VALUE_FAIL,
  payload: error,
});

export const saveMasterListValue = (data) => {
  return function (dispatch) {
    dispatch(saveMasterListValueLoading());
    client
      .post(
        `/doctor/save-list-value`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(saveMasterListValueSuccess(resp.data));
        dispatch(loadMasterListValue({ type: data.type }));
        dispatch(getDoctorListValue({ type: data.type }));
        toast.success("List updated");
      })
      .catch((error) => {
        dispatch(saveMasterListValueFail(error.response));
        toast.error(`${error.response.data.message}`);
      });
  };
};
