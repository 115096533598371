// import { dispatch } from 'react-hot-toast/dist/core/store';
import { toast } from "../../../UIComponents/Toast";
import * as types from "../../actionTypes";
import { getProfileDetails } from "../profile";
import { getAudioData } from "./patientaudio";
import { patientInvoiceInfo } from "./patientopdinvoice";
import { client } from "../../../utils/axios";

export const allReportsToggle = (payload) => ({
  type: types.ALL_REPORTS_TOGGLE,
  payload,
});
export const resetMedicineData = (payload) => ({
  type: types.RESET_MEDICINE_DATA,
  payload,
});
export const storeAllergyFromFirebase = (payload) => ({
  type: types.STORE_ALLERGENS_FROM_FIREBASE,
  payload,
});
export const storeVitalsFromFirebase = (payload) => ({
  type: types.STORE_VITALS_FROM_FIREBASE,
  payload,
});
export const storeClinicalFindingFromFirebase = (payload) => ({
  type: types.STORE_CLINICALFINDING_FROM_FIREBASE,
  payload,
});
export const storeRadiologicalFindingFirebase = (payload) => ({
  type: types.STORE_RADIOLOGICALFINDING_FROM_FIREBASE,
  payload,
});
export const storeLabtestsFromFirebase = (payload) => ({
  type: types.STORE_LABTESTS_FROM_FIREBASE,
  payload,
});
export const storeDiagnosisFromFirebase = (payload) => ({
  type: types.STORE_DIAGNOSIS_FROM_FIREBASE,
  payload,
});
export const storeProcedureFromFirebase = (payload) => ({
  type: types.STORE_PROCEDURE_FROM_FIREBASE,
  payload,
});

export const updateFormSpeechData = (payload) => ({
  type: types.UPDATE_FORM_SPEECH_DATA,
  payload,
});
export const autoOpenRecVisit = (payload) => ({
  type: types.AUTO_OPEN_REC_VISIT,
  payload,
});

export const storeAllAppointments = (payload) => ({
  type: types.STORE_APPOINTMENTS,
  payload,
});

export const getSingleVisit = (payload) => ({
  type: types.GET_SINGLE_VISIT,
  payload,
});
export const removeCertificateData = (payload) => ({
  type: types.REMOVE_CERTIFICATE_DATA,
  payload,
});
export const createFormArray = (payload) => ({
  type: types.CREATE_FORM_ARRAY,
  payload,
});
export const certificateToggleAction = (payload) => ({
  type: types.CERTIFICATE_TOGGLE,
  payload,
});
export const updateRiskFactors = (payload) => ({
  type: types.UPDATE_RISK_FACTORS,
  payload,
});
export const addMedicineOpd = (payload) => ({
  type: types.ADD_MEDICINE_OPD,
  payload,
});
export const setHeAlertToggle = (payload) => ({
  type: types.HE_ALERT_TOGGLE,
  payload,
});

export const showOpdReport = (payload) => ({
  type: types.SHOW_OPD_REPORT,
  payload,
});

export const updatePatientNote = (payload) => ({
  type: types.UPDATE_PATIENT_NOTE,
  payload,
});
export const getSingleVisitStatus = (payload) => ({
  type: types.GET_SINGLE_VISIT_STATUS,
  payload,
});

export const getSingleSelectSearch = (payload) => ({
  type: types.SINGLE_SELECT_SEARCH_VALUE,
  payload,
});

const patientReportLoading = () => ({
  type: types.GET_PATIENT_REPORT_LOADING,
});

const patientReportSuccess = (data) => ({
  type: types.GET_PATIENT_REPORT_SUCCESS,
  payload: data,
});
const patientReportFail = (error) => ({
  type: types.GET_PATIENT_REPORT_FAIL,
  payload: error,
});

export const getPatientProfileToggle = (data) => ({
  type: types.PATIENT_PROFILE_TOGGLE,
  payload: data,
});

const getPatientLoading = () => ({
  type: types.GET_PATIENT_OPD_LOADING,
});

const getPatientSuccess = (data) => ({
  type: types.GET_PATIENT_OPD_SUCCESS,
  payload: data,
});
const getPatientError = (error) => ({
  type: types.GET_PATIENT_OPD_FAIL,
  payload: error,
});

const getListValueLoading = () => ({
  type: types.GET_LIST_VALUE_LOADING,
});

const getListValueSuccess = (data) => {
  console.log("data in action creator", data);
  return {
    type: types.GET_LIST_VALUE_SUCCESS,
    payload: data,
  };
};
const getListValueError = (error) => ({
  type: types.GET_LIST_VALUE_FAIL,
  payload: error,
});

const getAllPatientVisitsLoading = () => ({
  type: types.GET_ALL_PATIENT_VISITS_LOADING,
});

const getAllPatientVisitsSuccess = (data) => {
  return {
    type: types.GET_ALL_PATIENT_VISITS_SUCCESS,
    payload: data,
  };
};
const getAllPatientVisitsError = (error) => ({
  type: types.GET_ALL_PATIENT_VISITS_FAIL,
  payload: error,
});

const getSuggestedMedicinesLoading = () => ({
  type: types.GET_SUGGESTED_MEDICINES_SUCCESS,
});

const getSuggestedMedicinesSuccess = (data) => {
  return {
    type: types.GET_SUGGESTED_MEDICINES_SUCCESS,
    payload: data,
  };
};
const getSuggestedMedicinesError = (error) => ({
  type: types.GET_SUGGESTED_MEDICINES_FAIL,
  payload: error,
});

const getSearchMedicineLoading = () => ({
  type: types.SEARCH_MEDICINE_LOADING,
});

const getSearchMedicineSuccess = (data) => {
  return {
    type: types.SEARCH_MEDICINE_SUCCESS,
    payload: data,
  };
};
const getSearchMedicineError = (error) => ({
  type: types.SEARCH_MEDICINE_FAIL,
  payload: error,
});

const getSelectedMedicineRemarksSuccess = (data) => ({
  type: types.SELECTED_MEDICINE_REMARKS_SUCCESS,
  payload: data,
});

const savePatientNoteLoading = () => ({
  type: types.SAVE_PATIENT_NOTE_LOADING,
});

const savePatientNoteSuccess = (data) => {
  return {
    type: types.SAVE_PATIENT_NOTE_SUCCESS,
    payload: data,
  };
};
const savePatientNoteError = (error) => ({
  type: types.SAVE_PATIENT_NOTE_FAIL,
  payload: error,
});

const getPatientNoteLoading = () => ({
  type: types.GET_PATIENT_NOTE_LOADING,
});

const getPatientNoteSuccess = (data) => {
  return {
    type: types.GET_PATIENT_NOTE_SUCCESS,
    payload: data,
  };
};
const getPatientNoteError = (error) => ({
  type: types.GET_PATIENT_NOTE_FAIL,
  payload: error,
});

const addBankSpecialNoteLoading = () => ({
  type: types.ADD_BANK_SPECIAL_NOTE_LOADING,
});

const addBankSpecialNoteSuccess = (data) => {
  return {
    type: types.ADD_BANK_SPECIAL_NOTE_SUCCESS,
    payload: data,
  };
};
const addBankSpecialNoteError = (error) => ({
  type: types.ADD_BANK_SPECIAL_NOTE_FAIL,
  payload: error,
});

const visitUpdateLoading = () => ({
  type: types.UPDATE_VISIT_LOADING,
});

const visitUpdateSuccess = (data) => {
  return {
    type: types.UPDATE_VISIT_SUCCESS,
    payload: data,
  };
};
const visitUpdateError = (error) => ({
  type: types.UPDATE_VISIT_FAIL,
  payload: error,
});

const removeBankSpecialNote = (data) => {
  return {
    type: types.REMOVE_BANK_SPECIAL_NOTE,
    payload: data,
  };
};

const uploadVisitImageSuccess = (data) => {
  return {
    type: types.UPLOAD_VISIT_IMAGE_SUCCESS,
    payload: data,
  };
};

const viewVisitImageSuccess = (data) => {
  return {
    type: types.VIEW_VISIT_IMAGE_SUCCESS,
    payload: data,
  };
};
const uploadVisitPdfSuccess = (data) => {
  return {
    type: types.UPLOAD_VISIT_PDF_SUCCESS,
    payload: data,
  };
};

const getPatientRiskLoading = () => ({
  type: types.GET_PATIENT_RISK_LOADING,
});

const getPatientRiskSuccess = (data) => {
  return {
    type: types.GET_PATIENT_RISK_SUCCESS,
    payload: data,
  };
};
const getPatientRiskError = (error) => ({
  type: types.GET_PATIENT_RISK_FAIL,
  payload: error,
});

const savePatientRiskSuccess = (data) => {
  return {
    type: types.SAVE_PATIENT_RISK_SUCCESS,
    payload: data,
  };
};

const closeVisitLoading = () => ({
  type: types.CLOSE_VISIT_LOADING,
});

const closeVisitSuccess = (data) => {
  return {
    type: types.CLOSE_VISIT_SUCCESS,
    payload: data,
  };
};
const closeVisitError = (error) => ({
  type: types.CLOSE_VISIT_FAIL,
  payload: error,
});

const openVisitLoading = () => ({
  type: types.OPEN_VISIT_LOADING,
});

const openVisitSuccess = (data) => {
  return {
    type: types.OPEN_VISIT_SUCCESS,
    payload: data,
  };
};
const openVisitError = (error) => ({
  type: types.OPEN_VISIT_FAIL,
  payload: error,
});

export const storeOpenVisitData = (data) => ({
  type: types.STORE_OPEN_VISIT_dATA,
  payload: data,
});

const getOpdFormSuccess = (data) => {
  return {
    type: types.GET_OPD_FORMS_SUCCESS,
    payload: data,
  };
};
const getVitalsChartSuccess = (data) => {
  return {
    type: types.GET_VITALS_CHART_SUCCESS,
    payload: data,
  };
};

const replicateTemplateLoading = () => ({
  type: types.REPLICATE_TEMPLATE_LOADING,
});

export const replicateTemplateSuccess = (data) => ({
  type: types.REPLICATE_TEMPLATE_SUCCESS,
  payload: data,
});

const replicateTemplateFail = (error) => ({
  type: types.REPLICATE_TEMPLATE_FAIL,
  payload: error,
});

const saveTemplateLoading = () => ({
  type: types.SAVE_TEMPLATE_LOADING,
});

const saveTemplateSuccess = (data) => ({
  type: types.SAVE_TEMPLATE_SUCCESS,
  payload: data,
});

const saveTemplateFail = (error) => ({
  type: types.SAVE_TEMPLATE_FAIL,
  payload: error,
});

const deleteTemplateLoading = () => ({
  type: types.DELETE_TEMPLATE_LOADING,
});

const deleteTemplateSuccess = (data) => ({
  type: types.DELETE_TEMPLATE_SUCCESS,
  payload: data,
});

const deleteTemplateFail = (error) => ({
  type: types.DELETE_TEMPLATE_FAIL,
  payload: error,
});

const visitPrintReportLoading = () => ({
  type: types.VISIT_PRINT_REPORT_LOADING,
});

const visitPrintReportSuccess = (data) => ({
  type: types.VISIT_PRINT_REPORT_SUCCESS,
  payload: data,
});

const visitPrintReportFail = (error) => ({
  type: types.VISIT_PRINT_REPORT_FAIL,
  payload: error,
});

const deleteUploadImageSuccess = (data) => ({
  type: types.DELETE_VISIT_IMAGE_SUCCESS,
  payload: data,
});

const allMultilistQueueSuccess = (data) => ({
  type: types.APPOINTMENTS_ALL_MULTI_QUEUE_SUCCESS,
  payload: data,
});

const selectedImagesSuccesss = (data) => ({
  type: types.GET_SELECTED_IMAGES_SUCCESS,
  payload: data,
});
const selectedDocumentsSuccesss = (data) => ({
  type: types.GET_SELECTED_DOCUMENTS_SUCCESS,
  payload: data,
});

const getPatientOpdReportSuccess = (data) => ({
  type: types.GET_PATIENT_OPD_REPORT_SUCCESS,
  payload: data,
});
const getSearchTermBymedicine = (data) => ({
  type: types.GET_SEARCH_TERM_SUCCESS,
  payload: data,
});

export const replicateVisitStatus = (payload) => ({
  type: types.REPLICATE_VISIT_STATUS,
  payload,
});

export const duplicateVisitLoading = () => ({
  type: types.DUPLICATE_VISIT_LOADING,
});

export const duplicateVisitSuccess = (data) => ({
  type: types.DUPLICATE_VISIT_SUCCESS,
  payload: data,
});

export const duplicateVisitFailure = (err) => ({
  type: types.DUPLICATE_VISIT_FAILURE,
  payload: err,
});


export const patientReport = (data) => {
  return function (dispatch) {
    dispatch(patientReportLoading());

    client
      .get(
        `/visit/view-all-visit-audio`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(patientReportSuccess(resp.data));
        console.log("patient audio data", resp.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(patientReportFail(error));
      });
  };
};

export const getOpdPatient = (data) => {
  console.log("data sent", data);
  return function (dispatch) {
    dispatch(getPatientLoading());

    client
      .post(
        `/patient/get-patient`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getPatientSuccess(resp.data));
        toast.success("Patient information fetched successfully");
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPatientError(error));
      });
  };
};

export const getDoctorListValue = (data) => {
  console.log("data in action", data);
  return function (dispatch) {
    dispatch(getListValueLoading());

    client
      .post(
        `/doctor/get-list-value`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        let res = { data: resp.data, type: data.type };
        dispatch(getListValueSuccess(res));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getListValueError(error));
      });
  };
};

export const getAllPatientPreviousVisits = (data) => {
  return function (dispatch) {
    dispatch(getAllPatientVisitsLoading());

    client
      .post(
        `/visit/get-my-patient-visits`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getAllPatientVisitsSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getAllPatientVisitsError(error));
      });
  };
};

export const getAllSuggestedMedicines = (data) => {
  return function (dispatch) {
    dispatch(getSuggestedMedicinesLoading());

    client
      .post(
        `/visit/get-suggested-medicines`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getSuggestedMedicinesSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getSuggestedMedicinesError(error));
      });
  };
};

export const getSearchedMedicines = (searchTerm, searchType) => {
  return function (dispatch) {
    dispatch(getSearchMedicineLoading());

    client
      .get(
        `/visit/search-medicine?searchTerm=${searchTerm}&type=${searchType}`
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((resp) => {
        dispatch(getSearchMedicineSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getSearchMedicineError(error));
      });
  };
};

export const getSelectedMedicineRemarks = (data) => {
  return function (dispatch) {
    client
      .post(
        `/visit/get-selected-medicines-remarks`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getSelectedMedicineRemarksSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getPatientNotes = (data) => {
  return function (dispatch) {
    dispatch(getPatientNoteLoading());

    client
      .post(
        `/patient/get-patient-note`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getPatientNoteSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPatientNoteError(error));
      });
  };
};

export const addPatientNote = (data) => {
  const patientId = data.patientId;

  return function (dispatch) {
    dispatch(savePatientNoteLoading());

    client
      .post(
        `/patient/save-patient-note`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(savePatientNoteSuccess(resp.data));
        dispatch(getPatientNotes({ patientId }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(savePatientNoteError(error));
      });
  };
};

export const addBankSpecialNote = (data, doctorId) => {
  return function (dispatch) {
    dispatch(addBankSpecialNoteLoading());

    client
      .post(
        `/doctor/add-bank-specialnote`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(addBankSpecialNoteSuccess(resp.data));
        toast.success("Added to Bank");
        dispatch(getProfileDetails({ doctorId }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addBankSpecialNoteError(error));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const closeVisit = (data, patientId) => {
  return function (dispatch) {
    dispatch(closeVisitLoading());
    client
      .post(
        `/visit/complete`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(closeVisitSuccess(resp.data));
        dispatch(getAllPatientPreviousVisits({ patientId }));
        dispatch(getAudioData(patientId));
        toast.success("Visit closed successfully");
        setTimeout(() => {
          dispatch(getSingleVisitStatus(true));
          dispatch(autoOpenRecVisit(data.visitId));
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeVisitError(error.response));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const openVisit = (data) => {
  return function (dispatch) {
    dispatch(openVisitLoading());
    client
      .post(
        `/visit/reopen`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(openVisitSuccess(resp.data));
        dispatch(patientInvoiceInfo({ aptId: data.aptId, full: true }));
        toast.success("Visit reopen successfully");
      })
      .catch((error) => {
        console.log(error);
        dispatch(openVisitError(error.response));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const addVisit = (data, button) => {
  console.log("close visit data", data);

  return function (dispatch) {
    dispatch(visitUpdateLoading());

    client
      .post(
        `/visit/update`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(visitUpdateSuccess(resp.data));
        toast.success("Visit Updated");
        if (button === "close visit") {
          dispatch(
            closeVisit(
              { aptId: data.aptId, visitId: data._id, doctorId: data.doctorId },
              data.patientId
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(visitUpdateError(error));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const appointmentsAllMultilistQueue = (data) => {
  return function (dispatch) {
    client
      .post(
        `/visit/get-appointments-all-multi-queue`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(allMultilistQueueSuccess(resp.data));
        toast.success("Successfully updated");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const removeBankSpecialNoteOpd = (data, doctorId) => {
  return function (dispatch) {
    dispatch(showOpdReport(true));
    client
      .post(
        `/doctor/remove-bank-specialnote`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(removeBankSpecialNote(resp.data));
        dispatch(getProfileDetails({ doctorId }));
        toast.success("Removed from Bank");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const uploadVisitImage = (upload, visitId) => {
  console.log("upload visit image", visitId);

  return function (dispatch) {
    client
      .post(
        `/visit/upload-visit-image`,
        upload
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(uploadVisitImageSuccess(resp.data));
        dispatch(viewVisitImage(visitId));
        dispatch(getPatientOpdReport(visitId));
        toast.success("Image added successfully");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const viewVisitImage = (visitId) => {
  return function (dispatch) {
    client
      .get(
        `/visit/view-visit-images?visitId=${visitId}&web=true`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(viewVisitImageSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const uploadVisitPdf = (upload, visitId) => {
  return function (dispatch) {
    client
      .post(
        `/visit/upload-visit-pdf-doc`,
        upload
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(uploadVisitPdfSuccess(resp.data));
        toast.success("Document added to visit");
        dispatch(viewVisitImage(visitId));
        dispatch(getPatientOpdReport(visitId));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const getPatientRisk = (data) => {
  return function (dispatch) {
    dispatch(getPatientRiskLoading());
    client
      .post(
        `/patient/get-patient-risk`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getPatientRiskSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPatientRiskError(error.response));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const savePatientRisk = (data) => {
  return function (dispatch) {
    client
      .post(
        `/patient/save-patient-risk`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(savePatientRiskSuccess(resp.data));
        dispatch(getPatientRisk({ patientId: data.patientId }));
        toast.success("Risk factors added successfully");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};
export const getOpdFormData = (data) => {
  return function (dispatch) {
    client
      .post(
        `/doctor/get-form`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getOpdFormSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const getVitalsChart = (data) => {
  return function (dispatch) {
    client
      .post(
        `/visit/get-vitals-chart`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getVitalsChartSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};
export const templateReplicated = (data) => {
  console.log("replication", data);
  return function (dispatch, getState) {
    dispatch(replicateTemplateLoading());
    client
      .post(
        `/visit/get-template`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(replicateTemplateSuccess(resp.data));
        console.log("sdfgsdfgsfgsdf", resp.data);
        toast.success("Template applied successfully");
        // dispatch(updateVisit({ ...getState()?.data?.visitInfo, clinicalFinding: resp?.data?.visit?.clinicalFinding, allergens: resp?.data?.visit?.allergens, diagnosis:resp?.data?.visit?.diagnosis, medicines:resp?.data?.visit?.medicines,radiologicalFinding:resp?.data?.visit?.radiologicalFinding,lab:resp?.data?.visit?.lab,specialNote:resp?.data?.visit?.specialNote}))
      })
      .catch((error) => {
        console.log(error);
        dispatch(replicateTemplateFail(error.response));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const templateSaved = (data) => {
  return function (dispatch) {
    dispatch(saveTemplateLoading());
    client
      .post(
        `/visit/save-template`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(saveTemplateSuccess(resp.data));
        dispatch(getProfileDetails());
        toast.success("Template saved successfully");
      })
      .catch((error) => {
        console.log(error);
        dispatch(saveTemplateFail(error.response));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const deleteTemplate = (data) => {
  return function (dispatch) {
    dispatch(deleteTemplateLoading());
    client
      .post(
        `/visit/delete-template`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(deleteTemplateSuccess(resp.data));
        dispatch(getProfileDetails());
        toast.success("Template deleted successfully");
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteTemplateFail(error.response));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const loadPrintReport = (data) => {
  return function (dispatch) {
    dispatch(visitPrintReportLoading());
    client
      .post(
        `/visit/print-report`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(visitPrintReportSuccess(resp.data));
        document.getElementsByClassName("prescription-btn")[1].click();
      })
      .catch((error) => {
        console.log(error);
        dispatch(visitPrintReportFail(error.response));
      });
  };
};
export const deleteUploadImage = (data) => {
  return function (dispatch) {
    client
      .post(
        `/visit/delete-visit-image`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(deleteUploadImageSuccess(resp.data));
        dispatch(viewVisitImage(data.visitId));
        dispatch(getPatientOpdReport(data.visitId));
        toast.success(`${resp.data.message}`);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const getSelectedImages = (data) => {
  return function (dispatch) {
    client
      .post(
        `/visit/get-selected-images`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(selectedImagesSuccesss(resp.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const getSelectedDocuments = (data) => {
  return function (dispatch) {
    client
      .post(
        `/visit/get-selected-documents`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(selectedDocumentsSuccesss(resp.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const getPatientOpdReport = (id) => {
  return function (dispatch) {
    client
      .get(
        `/patient/visits/view-report/${id}`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getPatientOpdReportSuccess(resp.data));
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(`${error.response.data.message}`);
        }
      });
  };
};

export const searchTermByMedicineGeneric = (searchTerm) => {
  const sear = searchTerm?.join("+");
  console.log("sear", sear, searchTerm);
  let searchTerm1 = encodeURIComponent(sear);

  return function (dispatch) {
    client
      .get(
        `/visit/search-medicine?searchTerm=${
          searchTerm ? searchTerm1 : ""
        }&type=2`
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((resp) => {
        dispatch(getSearchTermBymedicine(resp.data));
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(`${error.response.data.message}`);
        }
      });
  };
};

export const duplicateVisit = (data) => {
  console.log("fsadfsadfasd", data);
  return function (dispatch) {
    dispatch(duplicateVisitLoading());
    client
      .post(
        `/visit/duplicate-visit`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(duplicateVisitSuccess(resp.data));
        toast.success("Visit replicated successfully");
      })
      .catch((error) => {
        dispatch(duplicateVisitFailure(error.response));
        if (error?.response?.data?.message) {
          toast.error(`${error.response.data.message}`);
        }
      });
  };
};
