import React from 'react';
import { Pie, G2 } from '@ant-design/plots';
import { useSelector } from 'react-redux';

const DemoPie = () => {
  const { registerTheme } = G2;
  const doctorInvoiceSummary = useSelector(
    (state) => state.analytics.doctorInvoiceSummary
  );
  // console.log("doctorInvoiceSummary", doctorInvoiceSummary.paymentGroup);
  registerTheme('custom-theme', {
    colors10: [
      '#f2a78a',
      '#d36e2a',
      '#2498D1',
      '#BBBDE6',
      '#4045B2',
      '#21A97A',
      '#FF745A',
      '#007E99',
      '#FFA8A8',
      '#2391FF',
    ],
    colors20: [
      '#025DF4',
      '#DB6BCF',
      '#2498D1',
      '#BBBDE6',
      '#4045B2',
      '#21A97A',
      '#FF745A',
      '#007E99',
      '#FFA8A8',
      '#2391FF',
      '#FFC328',
      '#A0DC2C',
      '#946DFF',
      '#626681',
      '#EB4185',
      '#CD8150',
      '#36BCCB',
      '#327039',
      '#803488',
      '#83BC99',
    ],
  });

   const type=doctorInvoiceSummary?.paymentGroup?.map(item=>item.type)
  //  console.log("pie data", type);

   const value=doctorInvoiceSummary?.paymentGroup?.map(item=>item.val)
  //  console.log("pie data", type);
  const data = [
    {
      type: type?.[0],
      value: value?.[0],
    },
    {
      type: type?.[1],
      value: value?.[1],
    },
    {
      type: type?.[2],
      value: value?.[2],
    },
    {
      type: type?.[3],
      value: value?.[3],
    },
    {
      type: type?.[4],
      value: value?.[4],
    },
    
  ];
  const config = {
    appendPadding: 10,
    data,
    height:300,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    legend:false,
    legend: {
      layout: 'vertical',
      position: 'right'
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    theme: 'custom-theme',
  };
  return <Pie {...config} />;
};

export default DemoPie