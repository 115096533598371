import React, { useEffect, useState } from "react";
import "../RbiLogin/RbiLogin.css";
import InputField from "../../../UIComponents/Input/Input";
import { Button, Modal } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Input, Space } from "antd";
import PatientForm from "../PatientForm/PatientForm";
import { LogoContainer } from "../LogoContainer/LogoContainer";
import errorIcon from "../../../images/error-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setMobileNumber } from "../../../redux/actions/rbi";
import { baseUrl } from "../../../utils/Helper";
import axios from "axios";
import RelationshipCheckbox from "../FamilyMember/RelationshipCheckbox";

const RbiLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  let url = baseUrl();

  const [secondsLeft, setSecondsLeft] = useState(30); // 2 minutes countdown
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [showOtp, setShowOtp] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState(""); // Error message for OTP
  const [patientForm, setPatientForm] = useState(false);
  const [showRelationshipModal, setShowRelationshipModal] = useState(false);

  const [formData, setFormData] = useState({
    phone: "",
  });

  const [errors, setErrors] = useState({});

  const queryParams = new URLSearchParams(location.search);
  const emp = queryParams.get("emply");

  const relationship = useSelector((state) => state?.rbi?.relationship);

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    setOtp(newOtp);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index]) {
      // If backspace is pressed and current input is empty
      if (index > 0) {
        // Move focus to the previous input
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  // Extract query parameters

  const validateForm = () => {
    let validationErrors = {};

    if (!formData.phone) {
      validationErrors.phone = "Phone number cannot be empty";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleLogin = async () => {
    dispatch(setMobileNumber(formData.phone));

    if (validateForm()) {
      const employeeData = {
        mobile: formData.phone,
        guardian: "self",
      };

      try {
        const response = await axios.post(
          `${url}user/rbi/send-otp`,
          employeeData,
          {
            headers: {
              "Content-Type": "application/json",
              Credential: true,
            },
          }
        );

        console.log("response", response);

        if (response.status === 200 || response.status === 201) {
          setShowOtp(true);
        }
        // else {
        //   setErrorMessage("Failed to send OTP. Please try again.");
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [errorMess, setErrorMess] = useState("");

  const handleVerifyOtp = async () => {
    const enteredOtp = otp.join("");

    if (enteredOtp.length < 6) {
      setOtpErrorMessage("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      axios
        .post(`${url}user/rbi/verify-otp`, {
          mobile: formData.phone,
          otp: Number(enteredOtp),
          guardian: relationship ? relationship : "self",
        })
        .then((response) => {
          if (response.status === 200 || 201) {
            setPatientForm(true);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            setIsModalOpen(true);
            setErrorMess(error?.response?.data?.message);
          }

          if (error.response && error.response.status === 400) {
            setOtpErrorMessage("Incorrect OTP. Please try again.");
          } else {
            setOtpErrorMessage("An error occurred. Please try again later.");
          }
        });
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDone = () => {
    history.push("/ihbanarik");
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    // Only allow numbers and limit the length to 10
    if (/^\d{0,10}$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (secondsLeft > 0) {
      const timer = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear interval on component unmount
    } else {
      setIsButtonDisabled(false); // Enable the button after countdown ends
    }
  }, [secondsLeft]);
  const handleResend = async () => {
    setSecondsLeft(30); // Reset the countdown
    setIsButtonDisabled(true); // Disable the button again
    setOtp(["", "", "", "", "", ""]);
    const employeeData = {
      mobile: formData.phone,
    };
    try {
      const response = await axios.post(
        `${url}user/rbi/send-otp`,
        employeeData,
        {
          headers: {
            "Content-Type": "application/json",
            Credential: true,
          },
        }
      );

      console.log("response", response);

      if (response.status === 200 || response.status === 201) {
        setShowOtp(true);
      }
      // else {
      //   setErrorMessage("Failed to send OTP. Please try again.");
      // }
    } catch (error) {
      console.log(error);
    }
  };

  // Format the remaining time as mm:ss
  const formatTime = () => {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <>
      {!showOtp ? (
        <div className="rbi-main-content">
          <div className="helpline">
            <h3>Helpline : 7710090107</h3>
          </div>
          <div className="login-content">
            <div className="login-text">
              <h2>Login</h2>
              <h5>Please login to continue</h5>
              <div className="login-input">
                <p>Phone Number</p>
                <InputField
                  type="text"
                  maxLength={10}
                  id="phone"
                  name="phone"
                  placeholder="Enter Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange1}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>
              <div className="login-btn-secction">
                <h4>We will send an OTP to this number</h4>
                <Button className="login-btn" onClick={handleLogin}>
                  Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <>
            {!patientForm ? (
              <div className="rbi-main-content">
                <div className="login-content">
                  <div className="login-text">
                    <h2>Get Your Code</h2>
                    <h5>
                      Please enter the 6-digit OTP code sent to your mobile
                      number {formData.phone}
                    </h5>
                    <div className="otp-input">
                      <Space>
                        {otp.map((digit, index) => (
                          <Input
                            key={index}
                            id={`otp-input-${index}`}
                            value={digit}
                            type="number"
                            maxLength={1}
                            className="otp-box"
                            style={{
                              textAlign: "center",
                              padding: "10px",
                            }}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onChange={(e) =>
                              handleChange(e.target.value, index)
                            }
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onFocus={(e) => e.target.select()}
                          />
                        ))}
                      </Space>
                    </div>
                    {otpErrorMessage && (
                      <p className="error-message">{otpErrorMessage}</p>
                    )}
                    <div style={{ textAlign: "center", marginTop: "6px" }}>
                      <span>
                        <span
                          className="resend-otp"
                          onClick={!isButtonDisabled ? handleResend : null}
                          style={{
                            color: isButtonDisabled ? "gray" : "#1A8E6B",
                            cursor: isButtonDisabled
                              ? "not-allowed"
                              : "pointer",
                          }}
                        >
                          Resend
                        </span>{" "}
                        OTP{" "}
                        {isButtonDisabled && (
                          <span>in {`(${formatTime()})`}</span>
                        )}
                      </span>
                    </div>
                    <div className="login-btn-secction">
                      <Button className="login-btn" onClick={handleVerifyOtp}>
                        Verify OTP
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <PatientForm />
            )}
          </>
        </>
      )}

      {/* Success Modal55 */}
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={200}
        style={{ top: "50%", transform: "translateY(-50%)" }}
        maskClosable={false}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <div>
            <img
              className="user-icon-header"
              width={80}
              src={errorIcon}
              alt="user-icon"
            />
          </div>

          <h6>{errorMess}</h6>

          <Button className="done-btn" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RbiLogin;
