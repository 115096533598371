import * as types from "../../actionTypes";
import { getAppointments } from "./appointments";
import { toast } from "../../../UIComponents/Toast";
import { baseUrl } from "../../../utils/Helper";
import { client } from "../../../utils/axios";

const deleteAppointmentLoading = () => ({
  type: types.DELETE_APPOINTMENT_LOADING,
});

const deleteAppointmentSuccess = (data) => ({
  type: types.DELETE_APPOINTMENT_SUCCESS,
  payload: data,
});
const deleteAppointmentFail = (error) => ({
  type: types.DELETE_APPOINTMENT_FAIL,
  payload: error,
});

let url = baseUrl();
export const deleteAppointment = (data, scheduledTime) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(deleteAppointmentLoading());
    client
      .post(
        `/visit/delete-appointment`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(deleteAppointmentSuccess(resp.data));
        dispatch(getAppointments({ scheduledTime: scheduledTime }));
        toast.success("Appointment deleted");
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(deleteAppointmentFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
