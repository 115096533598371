import React from 'react'
import '../Electrolytes.css'
import Input from '../../../../UIComponents/Input/Input'
import { useDispatch,useSelector } from 'react-redux'
import {field,  updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useState } from 'react'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'

export const Pregnency = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    console.log("reportDataFields",reportDataFields);
    const singleReport = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic?.testReport)
    console.log("singleReport", singleReport);
    const setDiagnosticData = useSelector(state => state?.diagnosticsInvoiceSummary)
    console.log("setDiagnosticData",setDiagnosticData);
    const genderNamee = useSelector(state => state?.diagnosticsInvoiceSummary?.genderNames)
    console.log("genderNamee", genderNamee);
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      console.log("e", e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'ELECTROLYTES')
      let index = 0
      reportDataFields.testReport.map((item, i) => {
        if (item?.test === 'ELECTROLYTES') {
          index = i
        }
        return item
      })
      console.log("fihjorinjiojninr", filterData);
      const filterGender = Object?.keys(filterData[0]?.map)
      .filter(key => genderNamee?.includes(key))
      .reduce((obj,key) => { 
        obj[key] = filterData[0]?.map[key]
        return obj[key]
      },{})
      const finalFilteredData = filterGender?.fields?.map(item => {
        if (item.particular === name) {
          item.result = value
        }
        return item
      })
      filterData[0].map.Pregnancy.fields = finalFilteredData
      reportDataFields.testReport[index] = filterData[0]
      dispatch(updateReports(reportDataFields))
    }
      const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value})) 
      }
  return (
    <div>
            <div className='electrolyte-grid'>
                    <div className='elec1 perg'></div>
                    <div className='elec2 preg-height'>Total Cholesterol</div>
                    <div className='elec3 '><Input className={"preg-inp"}name="Total Cholesterol" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.map?.Pregnancy?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='elec4 preg-height'>mg/dl</div>
                    <div className='elec5 preg-pad '>1st TRIMESTER 141 – 210 <br /> 2nd TRIMESTER 176 – 299 <br />3rd TRIMESTER 219 - 349</div>
                    <span className='elec-line'></span>
                    <div className='elec7 perg'></div>
                    <div className='elec8 preg-height'>	Triglycerides</div>
                    <div className='elec9 '><Input className={"preg-inp"} name="Triglycerides" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.map?.Pregnancy?.fields?.[1]?.result}  disabled={!reportDataFields.active} /></div>
                    <div className='elec10 preg-height'>mg/dl</div>
                    <div className='elec11 preg-pad '>1st TRIMESTER 40 - 159<br />
2nd TRIMESTER 75 - 382 <br />
3rd TRIMESTER 131 - 453</div>
                    <span className='elec-line2'></span>
                    <div className='elec13 perg'></div>
                    <div className='elec14 preg-height'>LDL Cholesterol</div>
                    <div className='elec15 '><Input className={"preg-inp"} name="LDL Cholesterol" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.map?.Pregnancy?.fields?.[2]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='elec16 preg-height'>mg/dl</div>
                    <div className='elec17 preg-pad'>1st TRIMESTER 60 - 153 <br />
2nd TRIMESTER 77 - 184 <br />
3rd TRIMESTER 101 - 224</div>
                    <span className='elec-line3'></span>
                    <div className='elec18 perg '></div>
                    <div className='elec19 preg-height'>VLDL Cholesterol</div>
                    <div className='elec20'><Input className={"preg-inp"} name="VLDL Cholesterol" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.map?.Pregnancy?.fields?.[3]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='elec21 preg-height'>mg/dl</div>
                    <div className='elec22 preg-pad '>1st TRIMESTER 10 - 18 <br />
2nd TRIMESTER 13 - 23 <br />
3rd TRIMESTER 21 - 36</div>
                    <span className='elec-line4'></span>
                    <div className='elec23 perg'></div>
                    <div className='elec24 preg-height'>HDL Cholesterol</div>
                    <div className='elec25 '><Input className={"preg-inp"} name="HDL Cholesterol" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.map?.Pregnancy?.fields?.[4]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='elec26 preg-height'>mg/dl</div>
                    <div className='elec27 preg-pad '>1st TRIMESTER 40 - 78 <br />
2nd TRIMESTER 52 - 87 <br />
3rd TRIMESTER 48 - 87</div>
                    <span className='elec-line5'></span>
                    <div className='elec43 male-pad' onClick={() => onLabelClicked("Remark")} > Remark</div>
                    <div className='elec44'> <TextAreaAnt name='Remark'  value={reportDataFields?.testReport?.[indexValue]?.map?.Pregnancy?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='elec-line9'></span>
            </div>
    </div>
  )
}
