import * as types from '../../actionTypes';

const initialState = {
    getPatient: [],
    copyButtonToggle: false,
    getPatientModifiedData: "",
    singleToggle : false,
    error: {},
    loading: false,
}



export  const getPatientAppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PATIENT_APPOINTMENT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_PATIENT_APPOINTMENT_SUCCESS:
            console.log("actionddddd",action.payload)
            return {
                ...state,
                getPatient: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_APPOINTMENT_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                getPatient: []
            }
        case types.SINGLE_PATIENT_TOGGLE:
            return {
                ...state,
                singleToggle:action.payload,
                loading:false
            }
        case types.GET_PATIENT_APPOINTMENT_MODIFIED:
            return{
                ...state,
                getPatientModifiedData:action.payload,
                loading:false
            }
        case types.COPY_BUTTON:
            return{
                ...state,
                copyButtonToggle:action.payload,
                loading:false
            }
        case types.RESET_GET_PATIENT:
            return {
                ...state,
                getPatient: action.payload,
                loading: false
            }
        default:
            return state;
    }
}