import { act } from 'react-dom/test-utils';
import * as types from '../../actionTypes';

const initialState = {
    searchPatient: [],
    createToggleButton: '',
    error: {},
    loading: false,
}

export const searchPatientReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEARCH_PATIENT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.SEARCH_PATIENT_SUCCESS:
            console.log("actionddddd", action.payload)
            return {
                ...state,
                searchPatient: action.payload,
                loading: false,
                error: {}
            }
        case types.SEARCH_PATIENT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                searchPatient: []
            }
        case types.CREATE_TOGGLE:
            return {
                ...state,
                createToggleButton: action.payload,
                loading: false
            }
        case types.RESET_SEARCH_PATIENT:
            return {
                ...state,
                searchPatient: action.payload,
                loading: false
            }
        case types.CLEAR_SEARCH_PATIENT_SUCCESS:
            return {
                ...state,
                searchPatient: [],
                loading: false,
                error: {}
            }

        default:
            return state;
    }
}