import React from 'react'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import './Hcv.css'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field,updateReports} from '../../../../redux/actions/Diagnostics/diagnostics'

function Hcv() {
  
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      setInputValue(value)
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'HIV & HbSAG & HCV')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'HIV & HbSAG & HCV'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))    }
  return (
    <div>
        
            <div className='hcv-parent'>
              <div className='hcv-left'>
                  <div className='hcv-grid'>
                      <div className='hcv-1 dng-pad' onClick={() => onLabelClicked("HIV I")} >HIV I</div>
                      <div className='hcv-2 dng-text'><TextAreaAnt  name="HIV I"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active} /></div>
                      <span className='hcv-border'></span>
                      <div className='hcv-pad' onClick={() => onLabelClicked("HIV II")} >HIV II</div>
                      <div className='hcv-text '><TextAreaAnt name="HIV II"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active}/></div>
                      <span className='hcv-border2'></span>
                      <div className='hcv-pad' onClick={() => onLabelClicked("HBsAg")} >HBsAg</div>
                      <div className='hcv-text'><TextAreaAnt name="HBsAg"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active}/></div>
                      <span className='hcv-border3'></span>
                      <div className='hcv-pad' onClick={() => onLabelClicked("HCV")} >HCV</div>
                      <div className='hcv-text'><TextAreaAnt name="HCV"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result}  disabled={!reportDataFields.active}/></div>
                      <span className='hcv-border4'></span>
                  </div>
              </div>
              <div>
                  <TestReportRightSection />
              </div>
            </div>
            <div className='hcv-foot'>
                <ReportFooter />
            </div>
            <div className='lh-p'>
          
          <p>HIV 1 & HIV II: <span>A negative result indicates the likely absence of detectable antibodies to HIV I & HIV II in the specimen. A negative test result does not exclude the possibility of exposure to infection with HIV I & HIV II. A positive text should be confirmed with western blot technique.</span></p>
          <p>HBsAg: <span>METHOD: IMMUNOCHROMATOGRAPHY NOTE: THIS METHOD DETECTS ALL TEN SUBTYPES OF HBsAg</span></p>
          <p>HCV <span>METHOD: IMMUNOCHROMATOGRAPHY.KIT USED: CARBOGEN RPR CARD TEST </span></p>
          
        </div>
    </div>
  )
}

export default Hcv