import React from 'react'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './Chikungunya.css'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

 const Chikungunya = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'CHIKUNGUNYA-IgM')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'CHIKUNGUNYA-IgM'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }

    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))    }

  return (
    <div>
        <div className='chikungunya-main'>
            <div className='chikungunya-container'>
                <div className='chikungunya-grid-container'>
                    <div className='bmeal-1 padd' onClick={() => onLabelClicked("CHIKUNGUNYA-IgM")} >CHIKUNGUNYA-IgM</div>
                    <div className='bmeal-2'><TextAreaAnt name="CHIKUNGUNYA-IgM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='bmeal-border1'></span>
                </div>
            </div>
            <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
        </div>
        <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
            <div className='abg-footer'>
                <br></br>
                <p className='abg-note'>Method:</p>
                <p className='abg-hct'>RAPID TEST</p>
            </div>
    </div>
  )
}

export default Chikungunya
