import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

export const doctorPatientCheckoutToggle = (data) => ({
  type: types.DOCTOR_PATIENT_CHECKOUT_TOGGLE,
  payload: data,
});

const sendPatientDoctorLoading = () => ({
  type: types.SEND_PATIENT_TO_DOCTOR_LOADING,
});

const sendPatientDoctorSuccess = (data) => ({
  type: types.SEND_PATIENT_TO_DOCTOR_SUCCESS,
  payload: data,
});
const sendPatientDoctorFail = (error) => ({
  type: types.SEND_PATIENT_TO_DOCTOR_ERROR,
  payload: error,
});

const checkoutPatientLoading = () => ({
  type: types.CHECKOUT_PATIENT_LOADING,
});

const checkoutPatientSuccess = (data) => ({
  type: types.CHECKOUT_PATIENT_SUCCESS,
  payload: data,
});
const checkoutPatientFail = (error) => ({
  type: types.CHECKOUT_PATIENT_ERROR,
  payload: error,
});

export const loadSendPatientDoctor = (data) => {
  console.log("gsdfgsdfgsd", data);
  return function (dispatch) {
    dispatch(sendPatientDoctorLoading());
    client
      .post(
        `/visit/send-patient-to-doctor`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(sendPatientDoctorSuccess(resp.data));
        dispatch(doctorPatientCheckoutToggle(true));
        console.log("sendPatientDoctorSuccess", resp.data);
        toast.success(`Calling in ${data.patientName}`);
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendPatientDoctorFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const loadcheckoutPatient = (data, patientName) => {
  console.log("sdfgsd", data, patientName);
  return function (dispatch) {
    dispatch(checkoutPatientLoading());
    client
      .post(
        `/visit/checkout-patient`,
        data
        //  {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(checkoutPatientSuccess(resp.data));
        dispatch(doctorPatientCheckoutToggle(false));
        toast.success(`Checked out ${patientName}`);
      })
      .catch((error) => {
        console.log(error);
        dispatch(checkoutPatientFail(error.response));
        toast.error(`${error.response.data.message}`);
      });
  };
};
