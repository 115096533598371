import React from 'react'
import './Abg.css'
import Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'


export const Abg = () => {
    const dispatch =  useDispatch()

    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'ABG')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item.test === 'ABG'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index]  = filterData[0]
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    }
   
    return (
        <div>
            <div className='abg-main'>
                <div className='abg-container'>
                    <div className='abg-grid-container'>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>PH</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" name="PH"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  />
                        <div className='padd'>mmHg</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border1'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>PCO2</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" name="PCO2" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
                        <div className='padd'>mmHg</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border2'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>PO2</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} type="number" name="PO2" onChange={onInputChange} />
                        <div className='padd'>mmol/L</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border3'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>BEecf</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} name="BEecf" onChange={onInputChange} />
                        <div className='padd'>mmol/L</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border4'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>HCO3</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} name="HCO3" onChange={onInputChange} />
                        <div className='padd'>mmol/L</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border5'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>TCO2</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} name="TCO2" onChange={onInputChange} />
                        <div className='padd'>%</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border6'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>So2</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} name="So2" onChange={onInputChange} />
                        <div className='padd'>%</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border7'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>Na</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} name="Na" onChange={onInputChange} />
                        <div className='padd'>mmol/L</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border8'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>K</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} name="K" onChange={onInputChange} />
                        <div className='padd'>mmol/L</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border9'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>iCa</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} name="iCa" onChange={onInputChange} />
                        <div className='padd'>mmol/L</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border10'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>Hct</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} name="Hct" onChange={onInputChange} />
                        <div className='padd'>%PCV</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border11'></span>
                        <div className='padd'>ARTERIAL BLOOD GAS</div>
                        <div className='padd'>Hb*</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} name="Hb*" onChange={onInputChange} />
                        <div className='padd'>g/dl</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border12'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
            <div className='abg-footer'>
                <br></br>
                <p className='abg-note'>Note:</p>
                <p className='abg-hct'>*via Hct</p>
            </div>
        </div>
    )
}
