import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import AddList from "./AddList"
import { saveMasterListValue } from '../../redux/actions/doctorEditMaster/saveMasterList';
import { toast } from '../../UIComponents/Toast';

export default function AddListModal({ visible,type }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [shouldUpdate, setshouldUpdate] = useState(false);
    const [generic, setGeneric] = useState('')

    const dispatch = useDispatch()

    const listValue = useSelector(state => state.editMaster.masterListValueInfo.listValue)

    const handleOk = () => {
        console.log("handle ok triggered")
        if (!generic) {
            toast.error(`please enter ${type}`)
            console.log("inside if")
        }
        else {
            console.log("inside else")
            dispatch(saveMasterListValue({ type:type, list: [...listValue.list, generic] }));
            setGeneric('');
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (shouldUpdate) {
            setIsModalVisible(true);
        }
        setshouldUpdate(true);
    }, [visible]);

    return (
        <Modal visible={isModalVisible} onCancel={handleCancel} width="700px" className='edit-medicine-modal-div'>
            {type === "labtests" ? <h3>Add Lab Tests</h3> : type === "clinicalFinding" ? <h3>Add Clinical Findings</h3> : <h3>Add {type}</h3>}
            <AddList handleOk={handleOk} setGeneric={setGeneric} generic={generic} />
        </Modal>
    )
}
