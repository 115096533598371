import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

const invoiceSummaryLoading = () => ({
  type: types.INVOICE_SUMMARY_LOADING,
});

const invoiceSummarySuccess = (detail) => ({
  type: types.INVOICE_SUMMARY_SUCCESS,
  payload: detail,
});

const invoiceSummaryError = (error) => ({
  type: types.INVOICE_SUMMARY_FAIL,
  payload: error,
});
export const rangeStartDate = (payload) => ({
  type: types.START_DATE,
  payload,
});

// let url = baseUrl();

export const detailSummaryDetails = (fromDate, toDate, doctorId) => {
  console.log("name", fromDate, toDate, doctorId);
  return (dispatch) => {
    dispatch(invoiceSummaryLoading());

    client
      .get(
        `/diagnostic/diagnostic-invoice-summary?fromDate=${fromDate}&toDate=${toDate}&doctorId=${doctorId}`
        // { headers: { "Authorization": `Bearer ${secure_token}` } }
      )
      .then((resp) => {
        dispatch(invoiceSummarySuccess(resp.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(invoiceSummaryError(error));
      });
  };
};
