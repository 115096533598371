import React from 'react'
import { DatePicker } from 'antd';
import SingleSelect from '../../../UIComponents/Select/SingleSelect';
import Button from '../../../UIComponents/Button/Button';
import moment from 'moment';
import './Prescriptionanalysis.css'
import PrescriptionDiagnosistable from './PrescriptionDiagnosistable';
import PrescriptionLab from './PrescriptionLab';
import PrescriptionDiagnosisType from './PrescriptionDiagnosisType';
import PrescriptionProdcedure from './PrescriptionProdcedure';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { prescriptionAnalysisData } from '../../../redux/actions/analytics/analytics';
import { clearDropDownData, hideDataSuccess } from '../../../redux/actions/analytics/analytics';
import { toast } from '../../../UIComponents/Toast'
import { clearDataSuccess } from '../../../redux/actions/analytics/analytics';
import calenderLogo from '../../../images/calender.svg';



const data = [
    {
        id: "No data",
        name: "No data"
    },

];


const Prescriptionanalysis = () => {
    const { RangePicker } = DatePicker;
    let dispatch = useDispatch();
    const prescriptionAnalysisInfo = useSelector(state => state.analytics.prescriptionAnalysisInfo)
    console.log("prescriptionAnalysis", prescriptionAnalysisInfo);
    const hide = useSelector(state => state.analytics.hideData)
    const clear = useSelector(state => state.analytics.clearData)
    const dropDownInfo = useSelector(state => state.analytics.clearDropDowns)



    const handleDateChange = (date, dateString) => {
        dispatch(clearDataSuccess({ startDate: dateString[0], endDate: dateString[1] }))
    }

    const handleConsultantsChange = (value) => {
        dispatch(clearDropDownData({ ...dropDownInfo, consultant: value }))
    }



    const onGenerateprescriptionClick = () => {
        if (clear.startDate === null && clear.endDate === null) {
            toast.error("Please select the dates")
        } else {
            dispatch(prescriptionAnalysisData(clear.startDate, clear.endDate))
            dispatch(hideDataSuccess(true))

        }
    }


    return (
        <div>
            <div className="prescriptionanalysis-top-section">
                <div className="top-left-right-section">
                    <div className="prescription-top-left-section">
                        <div className="from-to-date">
                            <p className='date-title'>FROM/TO DATE</p>
                            <RangePicker className='pres-select-date' onChange={handleDateChange} value={clear.startDate !== null ? [moment(clear.startDate), moment(clear.endDate)] : [null, null]} />
                            <img className='analytics-calender' src={calenderLogo} alt='calender' />
                        </div>


                        <div className="prescript-consultant">
                            <p className="consultant-title">CONSULTANT </p>
                            <SingleSelect data={data} width="100%" onChange={handleConsultantsChange} value={dropDownInfo?.consultant} />
                        </div>
                        <div className="prescript-date">
                            <Button className="pres-generate-btn" onClick={onGenerateprescriptionClick}>GENERATE</Button>
                        </div>

                    </div>


                </div>
            </div>
            {hide ?
                <div className="prescription-bottom-section">
                    <div className="pre-bottom-main-section">
                        <div className="pre-main-first-section">
                            <div className="pres-diagnosis-first-sect">
                                <p className='diagnosis-title'>MEDICINES<span>({prescriptionAnalysisInfo?.medicines?.length})</span></p>
                                <div className="diagnosis-search-add">

                                </div>

                            </div>
                            <div className="diagnosis-table-section">
                                <PrescriptionDiagnosistable />
                            </div>
                        </div>
                        <div className="pre-diagnosis-type-section">
                            <div className="pres-diagnosis-type-first-sect">
                                <p className='diagnosis-type-title'>DIAGNOSIS<span>({prescriptionAnalysisInfo?.diagnosis?.length})</span></p>
                                <div className="diagnosis-search-add">

                                </div>

                            </div>
                            <div className="diagnosis-type-section">
                                <PrescriptionDiagnosisType />
                            </div>
                        </div>


                        <div className="pre-main-lab-section">
                            <div className="pres-diagnosis-first-sect">
                                <p className='diagnosis-title'>LAB<span>({prescriptionAnalysisInfo?.lab?.length})</span></p>
                                <div className="diagnosis-search-add">

                                </div>

                            </div>
                            <div className="diagnosis-table-section">
                                <PrescriptionLab />
                            </div>
                        </div>
                        <div className="pre-main-procedure-section">
                            <div className="pres-diagnosis-procedure-sect">
                                <p className='diagnosis-title'>PROCEDURE<span>({prescriptionAnalysisInfo?.procedure?.length})</span></p>
                                <div className="diagnosis-search-add">

                                </div>

                            </div>
                            <div className="procedure-table-section">
                                <PrescriptionProdcedure />
                            </div>
                        </div>
                    </div>
                </div>
                : ""}




        </div>
    )
}

export default Prescriptionanalysis