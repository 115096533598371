import * as types from "../../actionTypes";
import toast from "react-hot-toast";
import { client } from "../../../utils/axios";

const subDomianRegisterLoading = () => ({
  type: types.SUB_DOMAIN_REGISTER_LOADING,
});

const subDomianRegisterSuccess = (data) => ({
  type: types.SUB_DOMAIN_REGISTER_SUCCESS,
  payload: data,
});

const subDomianRegisterFail = (error) => ({
  type: types.SUB_DOMAIN_REGISTER_FAIL,
  payload: error,
});

const subDomainUpdateLoading = () => ({
  type: types.SUB_DOMAIN_UPDATE_LOADING,
});

const subDomainUpdateSuccess = (data) => ({
  type: types.SUB_DOMAIN_UPDATE_SUCCESS,
  payload: data,
});

const subDomainUpdateFail = (error) => ({
  type: types.SUB_DOMAIN_UPDATE_FAIL,
  payload: error,
});

const subDomainUserLoading = () => ({
  type: types.SUB_DOMAIN_USER_ADD_LOADING,
});

const subDomainUserSuccuess = (data) => ({
  type: types.SUB_DOMAIN_USER_ADD_SUCCESS,
  payload: data,
});

const subDomainUserFail = (error) => ({
  type: types.SUB_DOMAIN_USER_ADD_FAIL,
  payload: error,
});

const subDomainPicLoading = () => ({
  type: types.SUB_DOMAIN_PIC_LOADING,
});

const subDomainPicSuccuess = (data) => ({
  type: types.SUB_DOMAIN_PIC_SUCCESS,
  payload: data,
});

const subDomainPicFail = (error) => ({
  type: types.SUB_DOMAIN_PIC_FAIL,
  payload: error,
});

const subDomainStyleLoading = () => ({
  type: types.SUB_DOMAIN_STYLE_LOADING,
});

const subDomainStyleSuccuess = (data) => ({
  type: types.SUB_DOMAIN_STYLE_SUCCESS,
  payload: data,
});

const subDomainStyleFail = (error) => ({
  type: types.SUB_DOMAIN_STYLE_FAIL,
  payload: error,
});

// let url = baseUrl();
export const subDomainRegister = (data) => {
  return function (dispatch) {
    dispatch(subDomianRegisterLoading());
    client
      .post(
        "/admin/subdomain-register",
        { subdomain: data }
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((resp) => {
        dispatch(subDomianRegisterSuccess(resp.data));
        console.log("DomainRegister", resp.data);
        toast.success(resp.data.message);
      })
      .catch((error) => {
        dispatch(subDomianRegisterFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const subDomainUpdate = () => {
  return function (dispatch) {
    dispatch(subDomainUpdateLoading());
    client
      .get(
        `/admin/subdomain-register`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(subDomainUpdateSuccess(resp.data));
        console.log("zzz", resp.data);
      })
      .catch((error) => {
        dispatch(subDomainUpdateFail(error));
      });
  };
};

export const subdomainUser = (domain, user) => {
  return function (dispatch) {
    dispatch(subDomainUserLoading());
    client
      .post(
        `/admin/subdomain-adduser`,
        { domain, user }
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((resp) => {
        dispatch(subDomainUserSuccuess(resp.data));
        toast.success(resp.data.message);
      })
      .catch((error) => {
        dispatch(subDomainUserFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const subDomainProfilePic = (file) => {
  return function (dispatch) {
    dispatch(subDomainPicLoading());
    client
      .post(
        `/admin/subdomain-profileImage`,
        file
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(subDomainPicSuccuess(resp.data));
        toast.success(resp.data.message);
      })
      .catch((error) => {
        dispatch(subDomainPicFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const subDomainStyles = (domain, style) => {
  return function (dispatch) {
    dispatch(subDomainStyleLoading());
    client
      .post(
        `/admin/subdomain-updatestyle`,
        { domain, style }
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((resp) => {
        dispatch(subDomainStyleSuccuess(resp.data));
        toast.success(resp.data.message);
      })
      .catch((error) => {
        dispatch(subDomainStyleFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
