import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRouteAdmin = ({ component: Component, ...rest }) => {
  const { isAdminLogin } = useSelector(({ adminLogin }) => {
    return {
      isAdminLogin: adminLogin.isAdminLogin,
    };
  });

  const isValidDomain = () => {
    const { hostname } = window.location
    const hostnameParts = hostname.split('.');

    console.log("🚀 ~ isValidDomain ~ hostnameParts:", hostnameParts[0].includes(["www", "localhost"]), hostnameParts)

    if (["www", "localhost"].includes(hostnameParts[0])) return true
    return false;
  };

  console.log("isValidDomain", isValidDomain());
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isValidDomain()) return <Redirect to="/404" />
        if (!isAdminLogin && props.location.pathname !== "/admin/login") return <Redirect to="/admin/login" />
        return <Component {...props} />
      }
      }
    />
  );
};

export default PrivateRouteAdmin;
