import * as types from "../../actionTypes";

const initialState = {
  detail: [],
  error: {},
  loading: false,
  rangeDates: [],
};

export const detailSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INVOICE_SUMMARY_LOADING:
          return {
            ...state,
            loading: true,
          };
        case types.INVOICE_SUMMARY_SUCCESS:
          console.log("detail",action.payload)
          return {
            ...state,
            detail: action.payload,
            loading: false,
            error: {},
          };
        case types.INVOICE_SUMMARY_FAIL:
          return {
            ...state,
            error: action.payload,
            loading: false,
            detail: [],
          };
          case types.START_DATE:
            return {
              ...state,
              rangeDates: action.payload,
              loading: false,
              error: null
            }
      default:
        return state;
    }
  };