import React from 'react'
import '../Divider/Divider.css'

function Divider({className}) {
  return (
    <div>
        <hr className="horizontal"/>
        
    </div>
  )
}

export default Divider