import React from 'react';
import SingleSelect from '../../../UIComponents/Select/SingleSelect';
import { DatePicker } from 'antd';
import Button from '../../../UIComponents/Button/Button';
import Invoicelogo from '../../../images/Invoicelogo.png';
import InvoiceDetailsTable from '../InvoiceDetails/InvoiceDetailsTable';
import './InvoiceDetails.css';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { invoiceDetailsData } from '../../../redux/actions/analytics/analytics';
import { toast } from '../../../UIComponents/Toast'
import { clearDropDownData, hideDataSuccess } from '../../../redux/actions/analytics/analytics';
import { clearDataSuccess } from '../../../redux/actions/analytics/analytics';
import calenderLogo from '../../../images/calender.svg';


const payment = [
    {
        id: "All",
        name: "All"
    },
    {
        id: "Cash",
        name: "Cash"
    },
    {
        id: "Credit Card",
        name: "Credit Card"
    },
];


const InvoiceDetails = () => {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const loginInfo = useSelector((state) => state.login.loginInfo)
    const doctorId = loginInfo.id
    console.log("doctorid", doctorId);
    const profileInfo = useSelector(state => state.profile.profileInfo)
    const doctor = [
        {
            id: profileInfo?.id,
            name: profileInfo?.name
        }
    ];
    const hide = useSelector(state => state.analytics.hideData)
    const clear = useSelector(state => state.analytics.clearData)
    console.log("clear", clear);
    const dropDownInfo = useSelector(state => state.analytics.clearDropDowns)
    const [firstInvoiceCount, setFirstInvoiceCount] = useState(0);
    const [secondInvoiceCount, setSecondInvoiceCount] = useState(0);



    const invoiceCountsubtract = () => {
        setFirstInvoiceCount(firstInvoiceCount - 1);
    }

    const invoiceCountaddition = () => {
        setFirstInvoiceCount(firstInvoiceCount + 1);
    }

    const invoiceCountAdd = () => {
        setSecondInvoiceCount(secondInvoiceCount + 1);
    }
    const invoiceCountSubb = () => {
        setSecondInvoiceCount(secondInvoiceCount - 1);
    }
    const handleDoctorChange = (value) => {
        dispatch(clearDropDownData({ ...dropDownInfo, doctors: value }))
    }
    const handleInvoiceModeChange = (value) => {
        dispatch(clearDropDownData({ ...dropDownInfo, mode: value }))

    }

    const handleInvoiceLocationChange = (value) => {
        dispatch(clearDropDownData({ ...dropDownInfo, location: value }))

    }

    const handleInvoiceDateChange = (date, dateString) => {
        dispatch(clearDataSuccess({ startDate: dateString[0], endDate: dateString[1] }))
    }

    const onInvoiceGenerateClick = (e) => {
        if (!dropDownInfo.mode) {
            toast.error("Please select all the fields")
            dispatch(hideDataSuccess(false))

        }
        else if (!dropDownInfo.doctors) {
            toast.error("Please select all the fields")
        }
        else if (clear.startDate === null && clear.endDate === null) {
            toast.error("Please select all the fields")

        }
        else {

            console.log("onclick", onInvoiceGenerateClick);
            dispatch(invoiceDetailsData(clear.startDate, clear.endDate, dropDownInfo.location, dropDownInfo.mode, dropDownInfo.doctors, firstInvoiceCount, secondInvoiceCount))
            dispatch(hideDataSuccess(true))

        }
    }


    return (
        <div className='inv-main-container'>
            <div className="invoice-det-top-sec">
                <div className="invoice-det-main-sec">
                    <div className='inv-sub-container-one'>
                        <div className="prescript-consultant-one">
                            <p className="consultant-title">PICK LOCATION(Leave Blank for All)</p>
                            <SingleSelect data={profileInfo.locations} width="100%" value={dropDownInfo?.location} onChange={handleInvoiceLocationChange} />
                        </div>
                        <div className="prescript-consultant-one">
                            <p className="consultant-title">PAYMENT MODE</p>
                            <SingleSelect data={payment} width="100%" onChange={handleInvoiceModeChange} value={dropDownInfo?.mode} />
                        </div>
                        <div className='prescript-consultant-one'>
                            <p className='consultant-title'>DATES</p>
                            <RangePicker className='inv-dtl-pres-select-date' onChange={handleInvoiceDateChange} value={clear.startDate !== null ? [moment(clear.startDate), moment(clear.endDate)] : [null, null]} />
                            <img className='analytics-calender' src={calenderLogo} alt='calender' />
                        </div>
                        <div className='prescript-consultant-one'>
                            <p className="consultant-title">DOCTOR </p>
                            <SingleSelect data={doctor} width="100%" onChange={handleDoctorChange} value={dropDownInfo?.doctors} />
                        </div>

                    </div>
                    <div className='inv-sub-container-two'>
                        <p className="consultant-title">INVOICE</p>
                        <div className='prescript-consultant-section-two'>
                            <div className='count-btn-main-sec'>
                                <div className="count-btn counter-second-btns">
                                    <Button className={"invoice-sub-btn"} onClick={invoiceCountsubtract}><span className='minus-symbol'>-</span></Button>
                                    {firstInvoiceCount}
                                    <Button className={"invoice-add-btn"} onClick={invoiceCountaddition}>+</Button>
                                </div>
                                <div className="count-btn counter-second-btns">
                                    <Button className={"invoice-sub-btn"} onClick={invoiceCountSubb}><span className='minus-symbol'>-</span></Button>
                                    {secondInvoiceCount}
                                    <Button className={"invoice-add-btn"} onClick={invoiceCountAdd}>+</Button>
                                </div>
                            </div>
                            <div className='prescript-consultant-one-btn'>
                                <Button className="inv-det-pres-generate-btn" onClick={onInvoiceGenerateClick}>GENERATE</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='inv-sub-container-three'>


            </div>
            {hide ?
                <div>
                    <img className="invoice-logo" src={Invoicelogo} alt="Invoicelogo" />
                    <div>
                        <h2 className='inv-breakdown'>Invoice Breakdown </h2>
                        <p className='inv-service'>by service</p>
                    </div>
                    <InvoiceDetailsTable />
                    <br></br>
                </div>
                : ""}
        </div>
    )
}

export default InvoiceDetails