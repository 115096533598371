import React from 'react'
import './Haemogram.css'
import Input from '../../../UIComponents/Input/Input'
import { TestReportRightSection } from './TestReportRightSection'
import ReportFooter from './ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../redux/actions/Diagnostics/diagnostics'

export const Haemogram = () => {
    const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'HAEMOGRAM')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'HAEMOGRAM'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
        <div className='haemo-parent'>
            <div className='haemo-left'>
                <div className='haemo-grid'>
                    <div className='haemo1 h-pad'></div>
                    <div className='haemo2 h-pad'>Haemoglobin</div>
                    <div className='haemo3'><Input type='number' className={'haemo-input'}  name="Haemoglobin" onChange={onInputChange}value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='haemo4 h-pad'>g/dl</div>
                    <div className='haemo5 h-pad'>12-16</div>
                    <div className='haemo6 h-pad'></div>
                    <span className='haemo-border'></span>
                    <div className='h-pad'></div>
                    <div className='h-pad'>TLC</div>
                    <div className=''><Input type='number' className={'haemo-input'}  name="TLC" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='h-pad'>g/dl</div>
                    <div className='h-pad'>12-16</div>
                    <div className='h-pad'></div>
                    <span className='haemo-border1'></span>
                    <div className='h-pad'>DIFFERENTIAL COUNT</div>
                    <div className='h-pad'>Neutrophils</div>
                    <div className=''><Input type='number' className={'haemo-input'}  name="Neutrophils" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='h-pad'>g/dl</div>
                    <div className='h-pad'>12-16</div>
                    <div className='h-pad'></div>
                    <span className='haemo-border2'></span>
                    <div className='h-pad'>DIFFERENTIAL COUNT</div>
                    <div className='h-pad'>Lymphocytes</div>
                    <div className=''><Input type='number' className={'haemo-input'}  name="Lymphocytes" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='h-pad'>g/dl</div>
                    <div className='h-pad'>12-16</div>
                    <div className='h-pad'></div>
                    <span className='haemo-border3'></span>
                    <div className='h-pad'>DIFFERENTIAL COUNT</div>
                    <div className='h-pad'>Monocytes</div>
                    <div className=''><Input type='number' className={'haemo-input'}  name="Monocytes" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='h-pad'>g/dl</div>
                    <div className='h-pad'>12-16</div>
                    <div className='h-pad'></div>
                    <span className='haemo-border4'></span>
                    <div className='h-pad'>DIFFERENTIAL COUNT</div>
                    <div className='h-pad'>Eosinophil</div>
                    <div className=''><Input type='number' className={'haemo-input'}  name="Eosinophil" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='h-pad'>g/dl</div>
                    <div className='h-pad'>12-16</div>
                    <div className='h-pad'></div>
                    <span className='haemo-border5'></span>
                    <div className='h-pad'>DIFFERENTIAL COUNT</div>
                    <div className='h-pad'>Basophil</div>
                    <div className=''><Input type='number' className={'haemo-input'}  name="Basophil" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='h-pad'>g/dl</div>
                    <div className='h-pad'>12-16</div>
                    <div className='h-pad'></div>
                    <span className='haemo-border6'></span>
                    <div className='h-pad'>DIFFERENTIAL COUNT</div>
                    <div className='h-pad'>Bands</div>
                    <div className=''><Input type='number' className={'haemo-input'}  name="Bands" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='h-pad'>g/dl</div>
                    <div className='h-pad'>12-16</div>
                    <div className='h-pad'></div>
                    <span className='haemo-border7'></span>
                    <div className='h-pad'></div>
                    <div className='h-pad'>E.S.R.</div>
                    <div className=''><Input type='number' className={'haemo-input'}  name="E.S.R." onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='h-pad'>g/dl</div>
                    <div className='h-pad'>12-16</div>
                    <div className='h-pad'></div>
                    <span className='haemo-border8'></span>
                </div>
            </div>
                <div>
                    <TestReportRightSection />
                </div>
        </div>
        <div className='haemo-foot'>
              <ReportFooter />
        </div>
    </div>
  )
}
