import React, { useEffect } from 'react'
import editIcon from '../../../images/edit-icon.svg'
import assistantList from '../../../images/assistant-list-icon.svg'
import Button from '../../../UIComponents/Button/Button'
import InputField from '../../../UIComponents/Input/Input'
import './Signup.css'
import SingleSelect from '../../../UIComponents/Select/SingleSelect'
import { doctorSignUp, assistantDoc, assistantMap, editDoc, inputDoc, clearDoc, doctorEditing, rendDoc} from '../../../redux/actions/admin/doctorSignUp'
import { toast } from '../../../UIComponents/Toast'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'


const AssignAssistant = () => {

    const [name, setName] = useState('');
    const [ima, setIma] = useState('');
    const [docID, setDocID ] = useState('');

    const editDoctors = useSelector(state => state.doctorSignup.editDoctor)
    console.log("editDoctor", editDoctors);


    const assistantListDoc = useSelector(state => state.doctorSignup.assistantDoc)
    console.log("AssistantInfo", assistantListDoc?.assistants);

    const doctorInform = useSelector(state => state.doctorListDetails.doctorInform.data)
    console.log("doctorInform", doctorInform);

    const inputDoctor = useSelector(state => state.doctorSignup.inputDoctor)
    console.log("inputDoctor", inputDoctor)

    const nameChanger = inputDoctor.name.replace(/[^A-Za-z ]/gi, "")
    console.log("namechanger",nameChanger)
    const [assistantName, setAssistant] = useState('');

    const renduse = useSelector(state => state.doctorSignup.rend)
    console.log("rend", renduse)
  
    const dispatch = useDispatch();

    // const onChangeDocName = (e) =>{
    //     setDocName();
    // }
    const onChangeName = (e) => {
        let {name,value} = e.target
        dispatch(inputDoc({...inputDoctor,
            [name]:value,
            nameChanger
        }));
        console.log("input", inputDoc)
    }
    
   
    const onChangeEditName = (e) =>{
        setName(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }
    const onChangeEditIma = (e) =>{
        setIma(e.target.value)
    }
   
    const onChangeAssistant = (value) => {
        setAssistant(value);
        console.log(value);
    }

    const clearEdit = () => {
        dispatch(clearDoc())
        dispatch(editDoc(true));
    }
    const clearAssist = () => {
        setAssistant('');
    }

    const eidtDoc = () => {
        dispatch(editDoc(false))
        setName(doctorInform.name)
        setIma(doctorInform.ima)
        setDocID(doctorInform.id)
    }
    /////Assistant\\\\\\\\\\\


    useEffect(() => {
        dispatch(assistantDoc())

    }, []);

    const doctorEdited = (e) =>{
        if(!name || !ima){
            toast.error("Please Enter all input Fields")
        }
        else{
            e.preventDefault(e);
            dispatch(doctorEditing({name, ima, id:docID}))
            dispatch(rendDoc(false))
        }
    }

    const assistantofDoc = assistantListDoc?.assistants?.map(item => item.name)
    console.log("assistant data", assistantofDoc);


    const DrSignup = (e) => {
        if (!inputDoctor.name || !inputDoctor.mobile || !inputDoctor.fullname || !inputDoctor.ima || !inputDoctor.password || !inputDoctor.confirmPassword) {
            toast.error("Please enter all the input fields")
        }
        // else if(!namePatt.test(inputDoctor.name)){
        //     toast.error("Please enter a valid Name")
        // }
        else if (inputDoctor.password !== inputDoctor.confirmPassword) {
            toast.error("Please enter valid password")
        }
        else if (inputDoctor.mobile.length !== 10) {
            toast.error("Please enter valid mobile number")
        }
        else if (inputDoctor.password.length && inputDoctor.confirmPassword.length < 8) {
            toast.error("Your password needs to be at least 8 characters long")
        }
        else {
            e.preventDefault();
            dispatch(doctorSignUp(inputDoctor))
            dispatch(clearDoc())
        }
    }

    const assist = (e) => {
        if (!assistantName) {
            toast.error("Please select assistant")
        }
        else {
            e.preventDefault();
            dispatch(assistantMap({ doctor:doctorInform.id,  assistant:assistantName }))
            console.log(assistantName);
        }
    }



    return (
        <div>
            <div className='signup-wrapper'>
                <div className="each-doctor-list-container-left-sec">
                    <div className="each-doctor-head">
                        <div className="assistant-head">
                            <h3>{doctorInform && doctorInform.name}</h3>
                            <img src={editIcon} className="medicine-table-edit" onClick={eidtDoc} />
                        </div>
                    </div>
                    {/* <div className="each-doctor-record">
                        <p className='active-status'>Active Status</p>
                        <Switch defaultChecked className="assistant-switch-mt" /><span>YES</span>
                    </div> */}
                    <div className="each-doctor-record">
                        <p><span>Mobile</span> Number</p>
                        <h4>{doctorInform && doctorInform.mobile}</h4>
                    </div>
                    <div className="each-doctor-record">
                        <p>Full Name</p>
                        <h4>{doctorInform && doctorInform.name}</h4>
                    </div>
                    <div className="each-doctor-record">
                        <p>IMA</p>
                        <h4>{doctorInform && doctorInform.ima}</h4>
                    </div>
                    {/* <div className="each-doctor-record">
                        <p>Password</p>
                        <h4> *********</h4>
                    </div>
                    <div className="each-doctor-record">
                        <p>Confirm New Password</p>
                        <h4>*********</h4>
                    </div> */}
                    <div className="assistant-link-sec">
                        <h3>Add Doctor Assistant</h3>
                        <div className="assistant-link-fields">
                            {/* <p>Assistant</p> */}
                            <div className="assistant-field-wd"><SingleSelect placeholder="Select assistant from list " data={assistantofDoc} value={assistantName} onChange={onChangeAssistant} listHeight={120} name="assistantName" /></div>
                            {/* <img className="assistant-list-icon" src={assistantList} alt="assistant-list" /> */}
                        </div>
                    </div>
                    <div className="assistant-btn-sec">
                        <p></p>
                        <Button onClick={assist} className={"assistant-save-btn-left-sec"}>SAVE</Button>
                        <Button onClick={clearAssist} className={"assistant-cancel-btn"}>CLEAR</Button>
                    </div>
                </div>
                { editDoctors ?
                <div className="each-doctor-list-container-right-sec">
                    <div className="each-doctor-record-right">
                        <div className="assistant-head">
                              <h3>Create Doctor</h3>
                        </div>
                    </div>
                    <div className="each-doctor-record-right">
                        <p>Dr.Name</p>
                        <h4> <InputField type="text" name = 'name' value={nameChanger} onChange={onChangeName} placeholder="Dr. Jay Karkar" /></h4>
                    </div>
                    <div className="each-doctor-record-right">
                        <p>Mobile Number</p>
                        <h4><InputField type="number" name='mobile' value={inputDoctor.mobile} onChange={onChangeName} onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                        }} /></h4>
                    </div>
                    <div className="each-doctor-record-right">
                        <p>Full Name</p>
                        <h4><InputField type="text" name='fullname' value={inputDoctor.fullname} onChange={onChangeName} placeholder="Dr. Jay Karkar" /></h4>
                    </div>
                    <div className="each-doctor-record-right">
                        <p>IMA</p>
                        <h4><InputField type="text" name='ima' value={inputDoctor.ima} onChange={onChangeName} placeholder="2hgg-jhfhf3hh-ju2hg2gg233" /></h4>
                    </div>
                    <div className="each-doctor-record-right">
                        <p>Password</p>
                        <h4> <InputField type="password" name='password' value={inputDoctor.password} onChange={onChangeName} placeholder="*********" /></h4>
                    </div>
                    <div className= "each-doctor-record-right" >
                        <p>Confirm New Password</p>
                        <h4> <InputField type="password" name='confirmPassword' value={inputDoctor.confirmPassword} onChange={onChangeName} placeholder="*********" /></h4>
                    </div>

                    <div className="assistant-btn-sec">
                        <p></p>
                        <Button onClick={DrSignup} className={"assistant-save-btn-right-sec"}>SAVE</Button>
                        <Button onClick={clearEdit} className={"assistant-cancel-btn"}>CLEAR</Button>
                    </div>
                </div>
                :
                <div className="each-doctor-list-container-right-sec edit-doc">
                    <div >
                        <div className="assistant-head">
                            <h3>Edit Doctor</h3>
                        </div>
                        <div className="each-doctor-record-right-edit">
                            <p>Dr.Name</p>
                            <h4> <InputField type="text" name = 'name' value={name} onChange={onChangeEditName} /></h4>
                        </div>
                        <div className="each-doctor-record-right-edit">
                            <p>IMA</p>
                            <h4> <InputField type="text" name = 'name' value={ima} onChange={onChangeEditIma}  /></h4>
                        </div>
                        <div className="assistant-btn-sec edit-save">
                            <p></p>
                            <Button onClick={doctorEdited} className={"assistant-save-btn-right-sec"}>SAVE</Button>
                            <Button onClick={clearEdit} className={"assistant-cancel-btn"}>CANCEL</Button>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>

    )
}

export default AssignAssistant