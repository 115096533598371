import React, { useState } from 'react'
import Button from "../../../../UIComponents/Button/Button";
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import visitNote from '../../../../images/visit-note.png'
import { useSelector } from 'react-redux';
import { PatientHistory } from '../PatientHistory';
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { Modal } from "antd";



const ViewHistory = () => {

    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    console.log("historyNote", visitInfo)
    const singleVisit = useSelector(state => state.opdrecord.patientreport.singleVisit)
    console.log("historysinglevisit", singleVisit)
    const [activeVisit, setActiveVisit] = useState(true)
    const visitImage = useSelector(state => state.opdrecord.patientHistory.visitImage)
    console.log("visitImage", visitImage);
    const [isModalTwoVisible, setIsModalTwoVisible] = useState(false);
    const [url, setUrl] = useState()

    const onActiveVisitClick = () => {
        setActiveVisit(prevState => !prevState)
    }
    const showImageModal = (key) => {
        setIsModalTwoVisible(true)
        const findUrl = visitImage?.images?.find((url) => url.key === key)
        console.log("dss", findUrl);
        setUrl(findUrl)
      };
    
      const handleImageCancel = () => {
        setIsModalTwoVisible(false)
    
      };

    return (
        <div>

            <div className='history-container'>
                <div className='history-active-btn'>
                    <Button onClick={onActiveVisitClick}>Active Visit</Button>
                    {activeVisit === true ? <DownOutlined onClick={onActiveVisitClick} /> : <RightOutlined onClick={onActiveVisitClick} />}
                </div>

                {activeVisit === true ?
                    <div>
                        <div className='add-image-sec'>

                        </div>
                        {visitImage && visitImage?.images?.length>0 ?
                <div className='opd-image-sec'>
                  {visitImage?.images?.map(item => <div>
                    {/* <p className='opd-image-del' onClick={() => deleteVaultImage(item.key)}>Delete</p> */}
                    <img onClick={() => showImageModal(item.key)} style={{ "width": "150px", "height": "80px" }} src={item.url} alt="upload images" />
                  </div>)}
                  <Modal
                      title="  "
                      visible={isModalTwoVisible}
                      onCancel={handleImageCancel}
                      width={650}
                      height={1230}>
                      <div>
                        <div>
                          <img style={{ "width": "600px", "height": "400px" }} src={url?.url} alt="uploaded images" />
                        </div>
                      </div>
                    </Modal>
                </div>
                     : ""}
                        <div className='history-below-sec'>
                            <div>
                                <p><img width={20} src={visitNote} alt='visit note' />Visit Note</p>
                            </div>
                            <div>
                                <TextAreaAnt value={singleVisit?.vaultVisitNote} disabled={true} cols={100} placeholder="Note" />
                            </div>
                        </div>
                    </div> : ""}

            </div>


        </div>
    )
}


export default ViewHistory