import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../UIComponents/Button/Button";
import deleteIcon from "../../../images/delete-icon.svg";
import editIcon from "../../../images/edit-icon.svg";
import {
    loadVaccineSave
} from "../../../redux/actions/opd/VaccineSave";
import "../Vaccine/Vaccine.css";
import ViewVaccine from "./ViewVaccine";

function OpdVaccine() {
	let dispatch = useDispatch();
	const [vaccineData, setVaccineData] = useState();

	const patientVaccine = useSelector(
		state => state.opdrecord.patientvaccine.patientVaccine
	);
	const patientId = useSelector(
		state => state.opdrecord.patientlist.selectedPatient
	);
	const active = useSelector(state => state.opdrecord.patientreport.active);

	const handleEdit = (agegrp, vaccine) => {
		console.log(
			"🚀 ~ handleEdit ~ agegrp, vaccine:",
			agegrp,
			vaccine,
			vaccineData
		);
		const data = vaccineData.map(arr => {
			if (agegrp === arr.ageType) {
				const newResultArray = arr.resultArray.map(arr1 => {
					if (arr1.vaccine === vaccine) return { ...arr1, isEditable: true };
					else return { ...arr1, isEditable: false };
				});
				return { ...arr, resultArray: newResultArray };
			}
			return arr;
		});
		console.log("🚀 ~ data ~ data:", data);
		setVaccineData(data);
	};

	const handleDelete = item => {
		const data = vaccineData.map(arr => {
			if (item.agegrp === arr.ageType) {
				const newResultArray = arr.resultArray.map(arr1 => {
					if (arr1.vaccine === item.vaccine) return { ...arr1, admindate: "" };
					else return { ...arr1 };
				});
				return { ...arr, resultArray: newResultArray };
			}
			return arr;
		});
		console.log("🚀 ~ data ~ data:", data);
		setVaccineData(data);
	};

	const handleVacSave = () => {
		console.log("🚀 ~ handleVacSave ~ ():", vaccineData);
		let data = vaccineData.map(arr => arr.resultArray).flat();
		data = data.map(arr => {
			return {
				admindate: arr.admindate || "",
				agegrp: arr.agegrp,
				vaccine: arr.vaccine,
			};
		});

		const saveVaccineChartData = {
			patientId: patientId.patientId,
			chart: data,
		};
		console.log("🚀 ~ handleVacSave ~ data:", data);
		dispatch(loadVaccineSave(saveVaccineChartData));
	};

	useEffect(() => {
		if (patientVaccine && patientVaccine.length) {
			const arr = [...new Set(patientVaccine.map(item => item.agegrp))];
			const resultArr = [];
			for (let i = 0; i < arr.length; i++) {
				let vaccArr = arr[i];
				console.log("resultArr", resultArr);
				console.log("arr[i]", vaccArr);
				let resultArray = patientVaccine.filter(
					item => item.agegrp === vaccArr
				);
				console.log("resultArray", resultArray);
				resultArr.push({ ageType: vaccArr, resultArray });
			}
			setVaccineData(resultArr);
			console.log("resultArr", resultArr);
		}
	}, [patientVaccine]);

	const newDateHandleChange = (dateString, item) => {
		console.log("dateString", dateString, item);
		const admindate = dateString.format("DD/MM/YYYY");
		const data = vaccineData.map(arr => {
			if (item.agegrp === arr.ageType) {
				const newResultArray = arr.resultArray.map(arr1 => {
					if (arr1.vaccine === item.vaccine) return { ...arr1, admindate };
					else return { ...arr1 };
				});
				return { ...arr, resultArray: newResultArray };
			}
			return arr;
		});
		console.log("🚀 ~ data ~ data:", data);
		setVaccineData(data);
	};

	return (
		<div>
			{active ? (
				<ViewVaccine />
			) : (
				<div className="vaccine-main-section">
					<div className="vaccine-title">
						<h3>VACCINE</h3>
					</div>
					{vaccineData?.map((data, i) => (
						<>
							<div className="bcg-entire-section">
								<div className="vaccine-birth-title">
									<h3>{data.ageType}</h3>
								</div>
								<div className="vaccine-input-section">
									{data.resultArray.map(item => (
										<div className="bcggg-sec">
											<div className="bcg-input">
												<div className="bcg-title-input">
													<span>
														{item.admindate ? (
															<input
																className="vaccine-input1"
																type="checkbox"
																disabled
																checked={item.admindate ? true : false}
															/>
														) : (
															<p>{item.admindate}</p>
														)}
													</span>
													<p>
														<span className="vaccine-name-heading">
															{item.vaccine}
														</span>
													</p>
												</div>
												<div className="bcg-delete-edit-btn-section">
													{item.admindate ? (
														<span>
															<img
																className="calls-table-delete"
																src={deleteIcon}
																alt="delete"
																onClick={() => handleDelete(item)}
															/>
														</span>
													) : (
														<p>{item.admindate}</p>
													)}
													<span>
														<img
															src={editIcon}
															className="calls-table-edit"
															alt="edit"
															onClick={() =>
																handleEdit(item.agegrp, item.vaccine)
															}
														/>
													</span>
												</div>
											</div>
											<div>
												<div>
													{item.isEditable ? (
														<div>
															<div>
																<p>{item.admindate}</p>
																<DatePicker
																	placeholder="DD/MM/YYYY"
																	inputReadOnly
																	// value={moment(item?.admindate, 'YYYY-MM-DD')}
																	format="YYYY-MM-DD"
																	onChange={date =>
																		newDateHandleChange(date, item)
																	}
																/>
															</div>
														</div>
													) : (
														<p>{item.admindate}</p>
													)}
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</>
					))}
					<div className="vac-save-sec">
						<Button className={"vac-save-btn"} onClick={handleVacSave}>
							SAVE
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

export default OpdVaccine;
