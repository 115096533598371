import * as types from '../../actionTypes';

const initialState = {
    editMedicineList: [],
    singleMedicine:{},
    error: {},
    loading:{}
}


export const medicineListReducer = (state = initialState,action) => {
    switch(action.type) {
        case types.GET_MEDICINE_LIST_LOADING:
            return {
                ...state,
                loading:true
            }
        case types.GET_MEDICINE_LIST_SUCCESS:
            return {
                ...state,
                editMedicineList:action.payload,
                loading:false
            }
        case types.GET_MEDICINE_LIST_ERROR:
            return {
                ...state,
                error:action.payload,
                loading:false,
                editMedicineList:[]
            }
        case types.GET_SINGLE_MEDICINE_dETAILS:
            return{
                ...state,
                singleMedicine:action.payload,
                loading:false
            }
        default:
            return state
    }
}