import { Input } from 'antd';
import React from 'react';
const { TextArea } = Input;

const TextAreaAnt = (props) => (
  <>
    <TextArea name={props.name} rows={props.rows} cols={props.cols} value={props.value} onChange={props.onChange} disabled={props.disabled} placeholder={props.placeholder}/>

  </>
);

export default TextAreaAnt;