import * as types from '../../actionTypes';

const initialState = {
    OpdSmsInvoice: [],
    error: {},
    loading: false
}


export const OpdSmsInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_OPD_SMS_INVOICE:
            return {
                ...state,
                loading: true
            }
        case types.GET_OPD_SMS_INVOICE_SUCCESS:
            return {
                ...state,
                OpdSmsInvoice: action.payload,
                loading: false,
                OpdSmsInvoice:[],

                error: {}
            }
        case types.GET_OPD_SMS_INVOICE_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                OpdSmsInvoice:[]
            } 


        default:
            return state;
    }
}