/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Space } from "antd";
import axios from "axios";
import { baseUrl } from "../../../utils/Helper";
import FamilyForm from "../FamilyMember/FamilyMember";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import errorIcon from "../../../images/error-icon.png";

const FamilyMemberOtp = ({ mobileNumber }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpErrorMessage, setOtpErrorMessage] = useState(""); // Error message for OTP
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(40); // 2 minutes countdown
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const [familyMember, setFamilyMember] = useState(false);

  const relationship = useSelector((state) => state?.rbi?.relationship);

  const history = useHistory();

  const [formData, setFormData] = useState({
    phone: "",
  });

  let url = baseUrl();

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    setOtp(newOtp);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index]) {
      // If backspace is pressed and current input is empty
      if (index > 0) {
        // Move focus to the previous input
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handleResend = async () => {
    setSecondsLeft(40); // Reset the countdown
    setIsButtonDisabled(true); // Disable the button again
    setOtp(["", "", "", "", "", ""]);
    const employeeData = {
      mobile: mobileNumber,
      guardian: "self",
    };
    try {
      const response = await axios.post(
        `${url}user/rbi/send-otp`,
        employeeData,
        {
          headers: {
            "Content-Type": "application/json",
            Credential: true,
          },
        }
      );

      console.log("response", response);

      if (response.status === 200 || response.status === 201) {
        setShowOtp(true);
      }
      // else {
      //   setErrorMessage("Failed to send OTP. Please try again.");
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyOtp = async () => {
    const enteredOtp = otp.join("");

    if (enteredOtp.length < 6) {
      setOtpErrorMessage("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      axios
        .post(`${url}user/rbi/verify-otp`, {
          mobile: mobileNumber,
          otp: Number(enteredOtp),
          guardian: relationship,
        })
        .then((response) => {
          if (response.status === 200 || 201) {
            // setPatientForm(true);
            setFamilyMember(true);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            setIsModalOpen(true);
            setErrorMess(error?.response?.data?.message);
          }

          if (error.response && error.response.status === 400) {
            setOtpErrorMessage("Incorrect OTP. Please try again.");
          } else {
            setOtpErrorMessage("An error occurred. Please try again later.");
          }
        });
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  useEffect(() => {
    if (secondsLeft > 0) {
      const timer = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear interval on component unmount
    } else {
      setIsButtonDisabled(false); // Enable the button after countdown ends
    }
  }, [secondsLeft]);

  // Format the remaining time as mm:ss
  const formatTime = () => {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleDone = () => {
    history.push("/ihbanarik");
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {!familyMember ? (
        <div className="rbi-main-content">
          <div className="login-content">
            <div className="login-text">
              <h2>Get Your Code</h2>
              <h5>
                Please enter the 6-digit OTP code sent to your mobile number{" "}
                {/* {formData.phone} */}
                {mobileNumber}
              </h5>
              <div className="otp-input">
                <Space>
                  {otp.map((digit, index) => (
                    <Input
                      key={index}
                      id={`otp-input-${index}`}
                      value={digit}
                      type="number"
                      maxLength={1}
                      className="otp-box"
                      style={{
                        textAlign: "center",
                        padding: "10px",
                      }}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onChange={(e) => handleChange(e.target.value, index)}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onFocus={(e) => e.target.select()}
                    />
                  ))}
                </Space>
              </div>
              {otpErrorMessage && (
                <p className="error-message">{otpErrorMessage}</p>
              )}
              <div style={{ textAlign: "center", marginTop: "6px" }}>
                <span>
                  <span
                    className="resend-otp"
                    onClick={!isButtonDisabled ? handleResend : null}
                    style={{
                      color: isButtonDisabled ? "gray" : "#1A8E6B",
                      cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    Resend
                  </span>{" "}
                  OTP{" "}
                  {isButtonDisabled && <span>in {`(${formatTime()})`}</span>}
                </span>
              </div>
              <div className="login-btn-secction">
                <Button className="login-btn" onClick={handleVerifyOtp}>
                  Verify OTP
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FamilyForm />
      )}

      {/* Success Modal55 */}
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={200}
        style={{ top: "50%", transform: "translateY(-50%)" }}
        maskClosable={false}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <div>
            <img
              className="user-icon-header"
              width={80}
              src={errorIcon}
              alt="user-icon"
            />
          </div>

          <h6>{errorMess}</h6>

          <Button className="done-btn" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default FamilyMemberOtp;
