import React from 'react'
import './IntraVenous.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function IntraVenous() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)  
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'INTRA-VENOUS PYLOGRAPHY')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'INTRA-VENOUS PYLOGRAPHY'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index] = filterData[0]
      console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    
  }
  
  const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))     }

  return (
    <div>
    <div className="intraVenous-parent">
      <div className="intraVenous-left">
        <div className="intraVenous-grid">
          <div className="intraVenous-1 intraVenous-pad" onClick={() => onLabelClicked("PLAIN FILM")}>PLAIN FILM</div>
          <div className="intraVenous-2 intraVenous-text">
            <TextAreaAnt name="PLAIN FILM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
          </div>
          <span className="intraVenous-border"></span>

          <div className=" intraVenous-pad" onClick={() => onLabelClicked("CONTRAST FILM")}>CONTRAST FILM</div>
          <div className=" intraVenous-text">
            <TextAreaAnt name="CONTRAST FILM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
          </div>
          <span className="intraVenous-border1"></span>

          <div className=" intraVenous-pad" onClick={() => onLabelClicked("URINARY BLADDER")}>URINARY BLADDER</div>
          <div className=" intraVenous-text">
            <TextAreaAnt name="URINARY BLADDER" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
          </div>
          <span className="intraVenous-border2"></span>

          <div className=" intraVenous-pad" onClick={() => onLabelClicked("POST-VOID RESIDUAL")}>POST-VOID RESIDUAL</div>
          <div className=" intraVenous-text">
            <TextAreaAnt name="POST-VOID RESIDUAL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
          </div>
          <span className="intraVenous-border3"></span>

          <div className=" intraVenous-pad" onClick={() => onLabelClicked("IMPRESSION")}>IMPRESSION</div>
          <div className=" intraVenous-text">
            <TextAreaAnt  name="IMPRESSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
          </div>
          <span className="intraVenous-border4"></span>
        </div>
      </div>
      <div>
        <TestReportRightSection />
      </div>
    </div>
    <div className="intraVenous-foot">
      <ReportFooter />
    </div>
  </div>
  )
}

export default IntraVenous
