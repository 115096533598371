import React, { useEffect } from "react";
import ProfileDetails from "../../components/Profile/ProfileDetails/ProfileDetails";
import ProfileStyle from "../../components/Profile/ProfileStyle/ProfileStyle";
import VisitingAreas from "../../components/Profile/Visiting/VisitingAreas";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/actions/profile";
import { toast } from "../../UIComponents/Toast";
import "./Profile.css";
import useDoctorId from "../../utils/get-doctor-id";
const Profile = () => {
  const dispatch = useDispatch();
  const doctorId = useDoctorId();
  console.log("doctorId", doctorId);

  const profileInfo = useSelector((state) => state.profile.profileInfo);

  useEffect(() => {
    dispatch(getProfileDetails({ doctorId }));
  }, []);

  useEffect(() => {
    if (profileInfo?.id) {
      toast.success("Profile loaded");
    }
  }, [profileInfo?.id]);

  return (
    <div className="main-section">
      <div className="profile-section">
        <div className="profile-name">
          <h1>PROFILE</h1>
        </div>
        <div className="profile-middle-section">
          <div className="profile-left-sec">
            <ProfileDetails />
            <ProfileStyle />
          </div>
          <div className="profile-right-sec">
            <VisitingAreas />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
