import * as types from "../../actionTypes";
import { baseUrl } from "../../../utils/Helper";
// import { toast } from '../../UIComponents/Toast';
import { client } from "../../../utils/axios";

export const getMasterListValueLoading = () => ({
  type: types.GET_MASTER_LIST_VALUE_LOADING,
});

export const getMasterListValueSuccess = (payload) => ({
  type: types.GET_MASTER_LIST_VALUE_SUCCESS,
  payload,
});

export const getMasterListValueFail = (error) => ({
  type: types.GET_MASTER_LIST_VALUE_FAIL,
  payload: error,
});

export const storeMasterListValue = () => ({
  type: types.STORE_MASTER_LIST_VALUE,
});

export const loadMasterListValue = (data) => {
  return function (dispatch) {
    dispatch(getMasterListValueLoading());
    client
      .post(
        `/doctor/get-list-value`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getMasterListValueSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(getMasterListValueFail(error.response));
      });
  };
};
