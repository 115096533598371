import * as types from '../../actionTypes';

const initialState = {
    userSignup: [],
    error: {},
    loading: false
}

export const userSignUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_SIGN_UP_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.USER_SIGN_UP_SUCCESS:
            return {
                ...state,
                userSignup: action.playload,
                loading: false,
                error: {}
            }
        case types.USER_SIGN_UP_FAIL:
            return {
                ...state,
                error: action.playload,
                loading: false,
                userSignup: []
            }
            default: 
            return state
            
    }
}