import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './CustomLoader.css'

const CustomLoader = () => {
    const [loading, setLoading] = useState(false)

    const opd = useSelector(state => state.opdrecord.patientreport)
    const patient = useSelector(state => state.appointments.getPatient)
    console.log("djdjdjkdkd", opd)
    const login = useSelector(state => state.login)


    useEffect(() => {
        if (opd?.loading) {
            setLoading(true)
        } else if (patient?.loading) {
            setLoading(true)
        }
        else if (login?.loading) {
            setLoading(true)
        }
        else {
            setLoading(false)
        }
    }, [opd?.loading, patient?.loading, login?.loading])
    return (
        <>
            {loading &&
                <div className='CustomLoaderContainer'>
                    <div class="progress">
                        <div class="indeterminate"></div>
                    </div>
                </div>
            }
        </>
    )
}

export default CustomLoader