import React from 'react'
import './Paranasal.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function Paranasal() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'PARANASAL SINOUS P.A. VIEW')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'PARANASAL SINOUS P.A. VIEW'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields = finalFilteredData
      reportDataFields.testReport[index] = filterData[0]
      console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))}
  return (
    <div>
    <div className="paranasal-parent">
      <div className="paranasal-left">
        <div className="paranasal-grid">
          <div className="paranasal-1 paranasal-pad" onClick={() => onLabelClicked("PNS")}>PNS</div>
          <div className="paranasal-2 paranasal-text">
            <TextAreaAnt name="PNS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
          </div>
          <span className="paranasal-border"></span>
          <div className=" paranasal-pad" onClick={() => onLabelClicked("IMPRESSION")}>IMPRESSION</div>
          <div className=" paranasal-text">
            <TextAreaAnt  name="IMPRESSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
          </div>
          <span className="paranasal-border1"></span>
        </div>
      </div>
      <div>
        <TestReportRightSection />
      </div>
    </div>
    <div className="paranasal-foot">
      <ReportFooter />
    </div>
 
  </div>
  )
}
export default Paranasal
