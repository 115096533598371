import * as types from "../../actionTypes";
import {
  getPatientOpdReport,
  replicateVisitStatus,
  viewVisitImage,
} from "./patientreport";
import { vaultImagesData } from "./patienthistory";
import { getVisitAudio } from "./patientaudio";
import { client } from "../../../utils/axios";

export const addServiceDetails = (data) => ({
  type: types.ADD_SERVICE_DETAILS,
  payload: data,
});
export const addEmpCardPost = (data) => ({
  type: types.ADD_EMP_CARD_POST,
  payload: data,
});

export const updateVisit = (payload) => ({
  type: types.UPDATE_VISIT,
  payload,
});

const patientOpdInvoiceLoading = () => ({
  type: types.GET_OPD_PATIENT_INVOICE_LOADING,
});

const patientOpdInvoiceSuccess = (data) => ({
  type: types.GET_OPD_PATIENT_INVOICE_SUCCESS,
  payload: data,
});

const patientOpdInvoiceFail = (error) => ({
  type: types.GET_OPD_PATIENT_INVOICE_FAIL,
  payload: error,
});

export const patientInvoiceInfo = (data, opd) => {
  console.log("cjkdkdxkmdkmdx", data, opd);
  return function (dispatch) {
    dispatch(patientOpdInvoiceLoading());
    client
      .post(
        `/visit/get-my-patient-selected-visit`,
        data
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )

      .then((resp) => {
        dispatch(patientOpdInvoiceSuccess(resp.data));
        dispatch(replicateVisitStatus(resp.data));
        if (opd === "opd") {
          dispatch(viewVisitImage(resp.data._id));
          dispatch(vaultImagesData(resp.data._id));
          dispatch(getPatientOpdReport(resp.data._id));
          dispatch(getVisitAudio(resp.data._id));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(patientOpdInvoiceFail(error));
      });
  };
};
