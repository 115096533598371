import React from 'react'
import './Cbc.css'
import Button from "../../../UIComponents/Button/Button";
import Input from '../../../UIComponents/Input/Input'
import { TestReportRightSection } from './TestReportRightSection'
import calculateLogo from "../../../images/calculate.png"
import ReportFooter from './ReportFooter';
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../redux/actions/Diagnostics/diagnostics'

function Cbc() {
  const dispatch =  useDispatch()
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'CBC')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'CBC'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
 
  return (
    <div className='test-report-main'>
      <div className='test-report-container'>
        <div className='test-report-left-sec'>
          <div className='auto-button'>
            <Button><img width={20} src={calculateLogo} alt="calculate logo" />Auto Calculate</Button>
          </div>
          <div className="grid-container">
            <p className="item6"></p>
            <p className="item7">HAEMOGLOBIN</p>
            <Input className='item8 inputs' name="HAEMOGLOBIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">gm/dl</p>
            <p className="item10">12-13</p>
            <p className="item18"></p>
          </div>
          <div className="grid-container">
            <p className="item6"></p>
            <p className="item7">RBC COUNT</p>
            <Input className='item8 inputs' value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} name="RBC COUNT" onChange={onInputChange}/>
            <p className="item9">mil/cmm</p>
            <p className="item10">4.5-5</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6"></p>
            <p className="item7">TOTAL WBC COUNT</p>
            <Input className='item8 inputs' name="TOTAL WBC COUNT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">/cmm</p>
            <p className="item10">4000-11000</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">RED CELL ABSOLUTE VALUE</p>
            <p className="item7">PACKED CELL VOLUME</p>
            <Input className='item8 inputs' value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} name="PACKED CELL VOLUME" onChange={onInputChange}/>
            <p className="item9">%</p>
            <p className="item10">35-45</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">RED CELL ABSOLUTE VALUE</p>
            <p className="item7">MEAN CORPUSCULAR VOLUME</p>
            <Input className='item8 inputs' name="MEAN CORPUSCULAR VOLUME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">cu micron</p>
            <p className="item10">80-90</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">RED CELL ABSOLUTE VALUE</p>
            <p className="item7">MEAN CORPUSCULAR HAEMOGLOBIN</p>
            <Input className='item8 inputs' name="MEAN CORPUSCULAR HAEMOGLOBIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">picograms</p>
            <p className="item10">27-32</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">RED CELL ABSOLUTE VALUE</p>
            <p className="item7">MEAN CORPUSCULAR HB CON</p>
            <Input className='item8 inputs' name="MEAN CORPUSCULAR HB CON" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">g/dl</p>
            <p className="item10">32-36</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">DIFFERENTIAL COUNT</p>
            <p className="item7">NEUTROPHILS</p>
            <Input className='item8 inputs' name="NEUTROPHILS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">%</p>
            <p className="item10">40-70</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">DIFFERENTIAL COUNT</p>
            <p className="item7">LYMPHOCYTES</p>
            <Input className='item8 inputs' name="LYMPHOCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">%</p>
            <p className="item10">20-45</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">DIFFERENTIAL COUNT</p>
            <p className="item7">EOSINOPHIL</p>
            <Input className='item8 inputs' name="EOSINOPHIL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">%</p>
            <p className="item10">0-6</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">DIFFERENTIAL COUNT</p>
            <p className="item7">MONOCYTES</p>
            <Input className='item8 inputs'  name="MONOCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">%</p>
            <p className="item10">0-8</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">DIFFERENTIAL COUNT</p>
            <p className="item7">BASOPHILS</p>
            <Input className='item8 inputs' name="BASOPHILS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            <p className="item9">%</p>
            <p className="item10">0-1</p>
            <p className="item18"></p>

          </div>
          <div className="grid-container">
            <p className="item6">PERIPHERAL SMEAR EXAMINATION</p>
            <p className="item7">PLATELETS</p>
            <Input className='item8 inputs' value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} name="PLATELETS" onChange={onInputChange}/>
            <p className="item9">/cmm</p>
            <p className="item10">150000-450000</p>
            <p className="item18"></p>

          </div>
          <br></br>

        </div>


        <div className='cbc-right-sec'>
          <TestReportRightSection />
        </div>
      </div>
      <div>
        <ReportFooter/>
      </div>
    </div>
  )
}

export default Cbc