import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

const printInvoiceLoading = () => ({
  type: types.PRINT_INVOICE_LOADING,
});

const printInvoiceSuccess = (data) => ({
  type: types.PRINT_INVOICE_SUCCESS,
  payload: data,
});
const printInvoiceFail = (error) => ({
  type: types.PRINT_INVOICE_FAIL,
  payload: error,
});

export const printInvoice = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(printInvoiceLoading());
    client
      .post(
        `/visit/print-invoice-appointment-view`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(printInvoiceSuccess(resp.data));
        console.log("invoicedata", resp.data);
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(printInvoiceFail(error));
      });
  };
};
