import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

const patientVaccineLoading = () => ({
  type: types.GET_PATIENT_VACCINE_LOADING,
});

const patientVaccineSuccess = (data) => ({
  type: types.GET_PATIENT_VACCINE_SUCCESS,
  payload: data,
});
const patientVaccineFail = (error) => ({
  type: types.GET_PATIENT_VACCINE_FAIL,
  payload: error,
});

export const patientvaccine = (data) => {
  console.log("fasdfas", data);
  return function (dispatch) {
    dispatch(patientVaccineLoading());

    client
      .post(
        `/visit/get-vaccination-chart`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(patientVaccineSuccess(resp.data));
        console.log("patient vaccine data", resp.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(patientVaccineFail(error));
      });
  };
};
