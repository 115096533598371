import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientAppointmet } from "../../../redux/actions/appointments/getPatient";
import { printInvoice } from "../../../redux/actions/appointments/printInvoice";
import { getAudioData } from "../../../redux/actions/opd/patientaudio";
import { singleAppointmentData } from "../../../redux/actions/opd/patienthistory";
import { selectedPatientId } from "../../../redux/actions/opd/patientlist";
import { patientInvoiceInfo } from "../../../redux/actions/opd/patientopdinvoice";
import {
	createFormArray,
	getAllPatientPreviousVisits,
	getOpdPatient,
	getPatientNotes,
	getPatientRisk,
	getSingleVisitStatus,
	removeCertificateData,
	showOpdReport,
	updateFormSpeechData,
} from "../../../redux/actions/opd/patientreport";
import { patientvaccine } from "../../../redux/actions/opd/patientvaccine";
import { UploadOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { accessToken } from "../../../redux/actions/appointments/accessToken";
import { getAllPatientsList } from "../../../redux/actions/opd/patientlist";

import "./Patient.css";
import InputField from "../../../UIComponents/Input/Input";

function PatientList() {
	const dispatch = useDispatch();
	const appointmentList = useSelector(
		state => state?.getAppointment?.getAppointmentsDetails
	);
	console.log("apointment list", appointmentList);
	const patientInfo = useSelector(
		state => state?.opdrecord?.patientreport?.patientInfo
	);
	console.log("patient info", patientInfo);
	const selectedPatientInfo = useSelector(
		state => state?.opdrecord?.patientlist?.selectedPatient
	);
	console.log("selectedPatientInfo", selectedPatientInfo);
	const visitInfo = useSelector(
		state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo
	);
	console.log("visitInfo", visitInfo);
	const visitImage = useSelector(
		state => state.opdrecord.patientHistory.visitImage
	);
	console.log("visitImage", visitImage);
	const appointments = useSelector(
		state => state?.opdrecord?.patientreport?.storeAppointments
	);
	console.log("storeAppointments", appointments);
	const singleAppointment = useSelector(
		state => state.opdrecord.patientHistory.singleAppointment
	);
	console.log("singleAppointment", singleAppointment);
	const getActivePatientId = useSelector(
		state => state.opdrecord.patientlist.selectedPatient
	);
	console.log("getActivePatientId", getActivePatientId);
	const printInvoiceDetails = useSelector(
		state => state.appointments.printInvoice.printInvoiceData
	);
	console.log("printInvoiceDetails", printInvoiceDetails);
	const myDomainInfo = useSelector(state => state.login.myDomainInfo);

	const [arrApp, setArrApp] = useState([]);
	const [showSearchPatient, setShowSearchPatient] = useState(false)
	const [searchPatientKey, setSearchPatientKey] = useState('')

	const getPatientId = (patientId, aptId) => {
		dispatch(removeCertificateData({}));
		dispatch(selectedPatientId({ patientId: patientId, aptId: aptId }));
		dispatch(getOpdPatient({ patientId }));
		dispatch(getPatientRisk({ patientId }));
		dispatch(getAllPatientPreviousVisits({ patientId }));
		dispatch(patientvaccine({ patientId }));
		// dispatch(getSingleVisitStatus(false))
		dispatch(getPatientNotes({ patientId }));
		dispatch(patientInvoiceInfo({ aptId, full: true }, "opd"));
		dispatch(showOpdReport(true));
		dispatch(getAudioData(patientId));
		dispatch(updateFormSpeechData(""));
		dispatch(getPatientAppointmet({ patientId: patientId }));
		dispatch(
			printInvoice({
				aptId: aptId,
				invoiceA: false,
				invoiceB: true,
				invoiceC: false,
			})
		);
		dispatch(createFormArray([]));
	};

	//sorting patient list
	const onSort = ([...columnNames]) => {
		if (appointments !== null) {
			const arrayApp = Object.values(appointments);
			arrayApp.sort((a, b) => {
        for (let columnName of columnNames) {
            if (a[columnName] > b[columnName]) return -1;
            if (a[columnName] < b[columnName]) return 1;
        }
        return 0;
    });
			setArrApp(arrayApp);
			dispatch(getAllPatientsList(arrayApp));
			const singleAptInfo = arrayApp?.find(
				item => item?.appointment?.patientId === getActivePatientId?.patientId
			);
			dispatch(singleAppointmentData(singleAptInfo));
		}
	};

	const getTokenNumber = aptId => {
		console.log("appointmentidfjf", aptId);
		arrApp?.map((item, e) => {
			if (item.appointment._id === aptId) {
				console.log("itemdddd", item);
				dispatch(
					accessToken({
						aptId: item.appointment._id,
						doctorId: item.appointment.doctorId,
						doctorIncharge: "",
						department: "",
					})
				);
			}
		});
	};

	const filterSearchPatients = () => {
		if (!searchPatientKey) return onSort("timeslot", "active");
		if (appointments) {
			let sortedPatients = Object.values(appointments);
			sortedPatients.sort((a, b) => {
				return a.appointment['timeslot'] > b.appointment['timeslot']
					? -1
					: b.appointment['timeslot'] > a.appointment['timeslot']
						? 1
						: 0;
			});
			sortedPatients = sortedPatients.filter(arr => {
				let hasKey = arr.appointment.patientName.toLowerCase().includes(searchPatientKey)
				// hasKey = arr.appointment.patientMobile.toLowerCase().includes(searchPatientKey)
				return hasKey
			})
			setArrApp(sortedPatients);
		}
	}

	useEffect(() => {
		onSort("timeslot", "active");
	}, [appointments]);
	useEffect(() => {
		if (getActivePatientId) {
			const singleAptInfo = arrApp?.find(
				item => item?.appointment?.patientId === getActivePatientId?.patientId
			);
			dispatch(singleAppointmentData(singleAptInfo));
		}
	}, [getActivePatientId]);
	useEffect(() => {
		if (singleAppointment?.appointment?.active === true) {
			dispatch(getSingleVisitStatus(false));
		} else {
			dispatch(getSingleVisitStatus(true));
		}
	}, [singleAppointment]);
	useEffect(() => {
		filterSearchPatients()
	}, [searchPatientKey])

	return (
		<div>
			<div className="OPD-Records">
				<div className="record">
					<h4>
						OPD <span>Records</span>
					</h4>
				</div>
				<div className="all-patients">
					<div className="all-patients-container"
						style={{
							background: myDomainInfo?.DomainStyles?.buttonColor
								? myDomainInfo?.DomainStyles?.buttonColor
								: "#007D9D",
						}}
					>
						{showSearchPatient ? (
							<div className="patient-search-input-container">
								<div className="patient-search-input">
									<InputField
										value={searchPatientKey}
										onChange={(e) => setSearchPatientKey(e.target.value)}
										type="text"
										placeholder={"Patient Name"}
									/>
								</div>
								<CloseOutlined className="patient-search-icon"
									onClick={() => setShowSearchPatient(false)} />
							</div>
						) : (
							<div className="all-patients-heading">
								<h6 className="patients-list">
									Patients List
								</h6>
								<div>
									<SearchOutlined
										className="patient-search-icon"
										onClick={() => {
											setShowSearchPatient(true)
											setSearchPatientKey('')
										}}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="opd-patient-list-sec">
						{arrApp?.map((item, i) =>
							item?.appointment?.patientName ? (
								<div
									className={
										item.appointment.active
											? "opd-patient-list-active"
											: "opd-patient-list-inactive"
									}
								>
									<span style={{ padding: "0 5px" }}>
										{item?.appointment?.token ? (
											item?.appointment?.token
										) : (
											<UploadOutlined
												onClick={() => getTokenNumber(item.appointment._id)}
											/>
										)}
									</span>{" "}
									<div
										style={{
											display: 'inline',
											textOverflow: 'ellipsis',
											overflow: 'hidden',
											width: "calc(80%)",
											whiteSpace: 'nowrap',
										}}
										onClick={() =>
											getPatientId(
												item.appointment.patientId,
												item.appointment._id
											)
										}
									>
										{item?.appointment?.patientName}
									</div>
								</div>
							) : (
								""
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PatientList;
