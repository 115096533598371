import React from "react";
import { useSelector } from "react-redux";

const PrintReport = () => {
  const testReportData = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.printTestReport
  );

  return (
    <div>
     {testReportData ? <div className="certificate-data" dangerouslySetInnerHTML={{ __html:testReportData }}></div>:""}
    </div>
  );
};

export default PrintReport;
