import React from 'react'
import '../Dengue.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const BariumEnema = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")
 
  const onInputChange = (e) => {
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'BARIUM ENEMA')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'BARIUM ENEMA'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields = finalFilteredData
    reportDataFields.testReport[index] = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))    
  }

  return (
    <div>
            <div className='dengue-parent'>
            <div className='dengue-left'>
                <div className='dengue-grid'>
                    <div className='deng1 dng-pad' onClick={() => onLabelClicked(" BARIUM ENEMA")}>BARIUM ENEMA</div>
                    <div className='deng2 dng-text'><TextAreaAnt  name="BARIUM ENEMA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
                    <span className='dng-border'></span>
                    <div className='dng-pad' onClick={() => onLabelClicked(" IMPRESSION")}>IMPRESSION</div>
                    <div className='dng-text '><TextAreaAnt name="IMPRESSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
                    <span className='dng-border2'></span>
                    
                </div>
            </div>
            <div>
                <TestReportRightSection />
            </div>
            </div>
            <div className='dng-foot'>
                <ReportFooter />
            </div>
    </div>
  )
}
