import { Button, Checkbox, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import rightIcon from "../../../images/correct-Icon.png";
import InputField from "../../../UIComponents/Input/Input";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import { baseUrl } from "../../../utils/Helper";
import { pincodeData } from "../../RbiForm/rbiData";
import "../FamilyMember/FamilyMember.css";

const serviceData = [
  { id: "Serving Employee", name: "Serving Employee" },
  { id: "Retired Employee", name: "Retired Employee" },
];

const genderData = [
  { id: "M", name: "M" },
  { id: "F", name: "F" },
  { id: "NA", name: "NA" },
];

const FamilyForm = () => {
  const history = useHistory();
  const location = useLocation();
  let url = baseUrl();
  const [isDisabled, setIsDisabled] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const emp = queryParams.get("emply");

  const relationship = useSelector((state) => state?.rbi?.relationship);
  const mobileNumber = useSelector((state) => state?.rbi?.number);
  const [patientData, setPatientData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [relationshipName, setRelationshipName] = useState("");
  const [relationshipDob, setRelationshipDob] = useState("");
  const [relationshipGender, setRelationshipGender] = useState("");

  const [genderdata, setGenderdata] = useState("");
  const [service, setServie] = useState("");
  const [isCheked, setIsChecked] = useState(null);
  const [isTcModalOpen, setIsTcModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [serviceDatas, setServicedata] = useState(null);
  // const [pincodeDatas, setPincodedata] = useState(null);
  const [pincodedata, setPincodeData] = useState();

  const handleTcOk = () => {
    setIsTcModalOpen(false);
  };

  const onChange = (e) => {
    setIsChecked(e.target.checked);
    setIsTcModalOpen(e.target.checked);
  };

  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    relationship: "",
    dob: "",
    age: "",
    occupation: "",
    currentAddress: "",
    pincode: "",
  });

  const [errors, setErrors] = useState({});

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDone = () => {
    history.push("/ihbanarik");
  };

  const handleChangePincode = (value) => {
    setPincodeData(
      value ? value.toString() : patientData?.data?.address?.pincode
    );
  };
  const handleGender = (value) => {
    setGenderdata(value.toString() || relationshipGender);
    if (value) {
      setError("");
    }
  };

  // Automatically set gender based on the relationship
  useEffect(() => {
    if (relationship === "Father") {
      setGenderdata("M");
      setIsDisabled(true);
    } else if (relationship === "Mother") {
      setGenderdata("F");
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [relationship]);

  const handleChangeService = (value) => {
    setServie(value.toString());
    // if (value) {
    //   setError("");
    // }
  };

  useEffect(() => {
    if (patientData?.data?.address?.address1 && !formData.currentAddress) {
      setFormData((prevData) => ({
        ...prevData,
        currentAddress: patientData.data.address.address1,
      }));
    }
  }, [patientData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let validationErrors = {};

    if (!formData.name && !relationshipName) {
      validationErrors.name = "Name is required";
    }
    // if (!genderdata) {
    //   validationErrors.genderdata = "Please select gender";
    // }

    // if (!formData.currentAddress) {
    //   validationErrors.currentAddress = "Address is required";
    // }
    if (!formData.dob && !relationshipDob) {
      validationErrors.dob = "Dob is required";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const doctorNumber = process.env.REACT_APP_RBI_DOCTOR_NUMBER;

  const convertToDateFormat = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // This is for displaying dd-mm-yyyy
  };

  const formatForInputField = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${year}-${month}-${day}`; // yyyy-mm-dd format for InputField
  };

  const dateString = relationshipDob;
  const formattedDate = convertToDateFormat(dateString); // This is for display purposes
  const inputFormattedDate = formatForInputField(dateString); // Use this format for input field

  const handleSubmit = async () => {
    // Submit the form or perform your desired action
    if (validateForm()) {
      const employeeData = {
        // name: formData.name,
        name: formData.name || relationshipName,
        // mobile: mobileNumber,
        mobile: patientData?.data?.mobile || "",
        // designation: serviceDatas?.name || service,
        designation: patientData?.data?.designation || "",
        // employeeId: empId || "",
        employeeId: patientData?.data?.members[0]?.employeeId || "",
        address: {
          // address1: formData.currentAddress,
          address1:
            // formData.currentAddress || patientData?.data?.address?.address1,
            formData.currentAddress
              ? formData.currentAddress
              : patientData?.data?.address?.address1,
          // formData.currentAddress,
          city: "Mumbai",
          state: "Maharashtra",
          // pincode: pincodedata,
          // pincode: patientData?.data?.address?.pincode || pincodedata,
          pincode: pincodedata,
          country: "India",
        },

        dob: formData?.dob.split("-").reverse().join("/") || formattedDate,
        occupation: "",
        age: "",
        gender: genderdata || relationshipGender,
        guardian: relationship, //self,father,mother/spouse/child
        relationship: relationship, //
        doctorNumber: doctorNumber,
      };

      try {
        setIsSubmitting(true);

        const response = await axios.post(
          `${url}user/rbi/add-patient`,
          employeeData,
          {
            headers: {
              "Content-Type": "application/json",
              Credential: true,
            },
          }
        );

        if (response.status === 201) {
          // You can adjust based on the API's success code
          setIsModalOpen(true); // Show success modal
        } else {
          setIsErrorModalOpen(true); // Show error modal
        }
        if (response.status === 400) {
        }
      } catch (error) {
        setIsErrorModalOpen(true); // Show error modal on network error
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    const autoSelectedService = serviceData.find(
      (item) => item.name === patientData?.data?.designation
    );
    setServicedata(autoSelectedService);
  }, [serviceDatas]);

  useEffect(() => {
    const autoelectPincode = pincodeData.find(
      (item) => item.name === patientData?.data?.address?.pincode
    );
    setPincodeData(autoelectPincode?.name);
  }, [patientData]);

  useEffect(() => {
    const filterName = patientData?.data?.members?.filter(
      (elm) => elm?.guardian === relationship
    );
    console.log("filterName", filterName, filterName?.[0]?.name);
    setRelationshipName(filterName?.[0]?.name);
    setRelationshipDob(filterName?.[0]?.dob);
    setRelationshipGender(filterName?.[0]?.gender);
  }, [patientData]);
  useEffect(() => {
    const getGender = patientData?.data?.members?.filter(
      (elm) => elm?.guardian === relationship
    );
    console.log("getGender", getGender);
    // setRelationshipName(filterName?.[0]?.name);
  }, [patientData]);

  useEffect(() => {
    const fetchPatientData = async () => {
      setIsLoading(true); // Start loading

      let data = JSON.stringify({
        mobile: mobileNumber,
        guardian: "self",
      });

      let config = {
        method: "post",
        url: `${url}user/rbi/find-patient`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        setPatientData(response.data); // Store the patient data from the response
      } catch (error) {
        setError(error.message); // Store any error encountered
      } finally {
        setIsLoading(false); // Stop loading after the request completes
      }
    };

    fetchPatientData(); // Make the API call when the component mounts
  }, []);
  console.log("relationshipName", relationshipName);

  return (
    <div>
      <div className="rbi-main-content">
        {emp === "family-member" ? (
          <div style={{ fontWeight: "400", padding: "5px", color: "white" }}>
            Patient Details
          </div>
        ) : null}

        <div className="login-content">
          <div className="login-text">
            {relationship === "child" ? (
              <div className="login-input">
                <p>Name of Dependent</p>
                <InputField
                  type="text"
                  maxLength={35}
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  className="phone-input-style"
                  value={formData.name}
                  // value={formData.name}
                  onChange={handleChange}
                  // disabled={relationshipName}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
            ) : (
              <div className="login-input">
                <p>Name of Dependent</p>
                <InputField
                  type="text"
                  maxLength={35}
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  className="phone-input-style"
                  value={formData.name || relationshipName}
                  // value={formData.name}
                  onChange={handleChange}
                  disabled={relationshipName}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
            )}

            <div className="login-input">
              <p>Gender</p>
              <SingleSelect
                data={genderData}
                onChange={handleGender}
                // value={genderdata || null}
                value={genderdata || relationshipGender}
                placeholder="Select Gender"
                id="gender"
                disabled={isDisabled}
              />
              {errors.genderdata && (
                <span className="error">{errors.genderdata}</span>
              )}
            </div>
            {!["Father", "Mother", "Child"].includes(relationship) && (
              <div className="login-input">
                <p>Service status of Employee</p>
                <SingleSelect
                  data={serviceData}
                  onChange={handleChangeService}
                  value={patientData?.data?.designation || null}
                  placeholder="Select Service"
                  id="service"
                  disabled
                />
              </div>
            )}

            <div className="login-input">
              <p>Relationship with Employee</p>
              <InputField
                value={relationship}
                onChange={handleChange}
                type="text"
                maxLength={35}
                id="name"
                name="relationship"
                placeholder="Enter Relationship"
                className="phone-input-style"
              />
              {/* {errors.relationship && (
                <span className="error">{errors.relationship}</span>
              )} */}
            </div>
            <div className="login-input">
              <p> Employee ID (6 digit SAMADHAN ID)</p>
              <InputField
                value={patientData?.data?.members[0]?.employeeId}
                onChange={handleChange}
                type="text"
                maxLength={35}
                id="name"
                name="relationship"
                placeholder="Enter Employee ID"
                className="phone-input-style"
              />
              {/* {errors.relationship && (
                <span className="error">{errors.relationship}</span>
              )} */}
            </div>
            {/* <div style={{ display: "flex", gap: "10px" }}> */}
            <div>
              <div className="login-input">
                <p>Date of Birth of Dependent </p>
                <InputField
                  value={formData.dob || inputFormattedDate}
                  onChange={handleChange}
                  type="date"
                  maxLength={35}
                  name="dob"
                  id="dob"
                  placeholder="Enter dob"
                  className="phone-input-style"
                />
                {errors.dob && <span className="error">{errors.dob}</span>}
              </div>
            </div>

            <div className="login-input">
              <p>Current Address</p>
              <InputField
                value={formData.currentAddress}
                onChange={handleChange}
                type="text"
                id="currentAddress"
                placeholder="Enter Your Address"
                className="phone-input-style"
                name="currentAddress"
              />
              {errors.currentAddress && (
                <span className="error">{errors.currentAddress}</span>
              )}
            </div>
            <div className="login-input">
              <p>Pincode</p>

              <SingleSelect
                data={pincodeData}
                onChange={handleChangePincode}
                value={pincodedata || ""}
                // value={patientData?.data?.address?.pincode || pincodedata}
                placeholder="Select Pincode"
                id="pincode"
              />
              {/* {errors.pincode && (
                <span className="error">{errors.pincode}</span>
              )} */}
              {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
              {/* {errors.pincodedata && (
                <span className="error">{errors.pincodedata}</span>
              )} */}
            </div>
            <div style={{ paddingTop: "10px" }}>
              <Checkbox onChange={onChange}>
                I agree to the Terms & Conditions
              </Checkbox>
            </div>
            <div className="login-btn-secction">
              <Button
                className="login-btn"
                // onClick={showModal}
                onClick={handleSubmit}
                disabled={isCheked ? false : true}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        // title="Basic Modal"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: "50%", transform: "translateY(-50%)" }}
        maskClosable={false}
        width={250}
      >
        <div style={{ padding: "5px", textAlign: "center" }}>
          <div>
            <img
              className="user-icon-header"
              width={100}
              src={rightIcon}
              alt="user-icon"
            />
          </div>
          <h5>Your Submission has been sent Successfully</h5>
          <Button className="done-btn" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
      {/* tc Modal */}
      <Modal
        visible={isTcModalOpen}
        onOk={handleTcOk}
        onCancel={handleTcOk}
        closable={false}
        style={{ top: "50%", transform: "translateY(-50%)" }}
        maskClosable={false}
      >
        <div style={{ padding: "5px" }}>
          <h5>
            <p>
              {" "}
              1. I want to avail home delivery of Medicines on credit slip from
              Apollo Pharmacies Ltd.{" "}
            </p>
            <p>
              2. I understand that the details that I furnish here will be
              shared with Apollo Pharmacies Ltd and outside the Bank. I agree
              that I give my consent for sharing this information.
            </p>
            <p>
              3. I want the eligible medicines to be issued under the credit
              slip facility.
            </p>
            <p>
              4. I submit that I will avail medicines only for personal use of
              self and eligible dependents.
            </p>
            <p>
              5. I will check the medicines for expiry date and provide
              appropriate confirmation at the time of delivery, including
              handing over the Prescription slip.
            </p>
          </h5>
          <div style={{ textAlign: "center" }}>
            <Button className="done-btn" onClick={handleTcOk}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FamilyForm;
