import React from 'react'
import './Signup.css'
import tickBlack from '../../../images/black-tick.svg'
import AssignAssistant from './AssignAssistant'
import SearchIcon from '../../../images/search-icon.svg'
import Button from '../../../UIComponents/Button/Button'
import { DoctorList, doctorInformation, initializeDoc, editDoc, rendDoc  } from '../../../redux/actions/admin/doctorSignUp'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import clear from '../../../images/clear-symbol.png'

const Signup = () => {

    const [searchDr, setSearchDr] = useState('');
    const [filterResult, setFilterResult] = useState();
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectDoctor, setSelectDoctor] = useState('');
    const [cancelSearch, setCancelSearch] = useState(false);
    const [inputsearch, setInputSearch] = useState(false);

    const dispatch = useDispatch()
    const doctorListInfo = useSelector(state => state.doctorListDetails.doctorList)
    console.log("doctorInfo", doctorListInfo);
    console.log("doctor ID", doctorListInfo.doctors);
    const myDomainInfo = useSelector(state => state.login.myDomainInfo)

    const renduse = useSelector(state => state.doctorSignup.rend)
    console.log("rend", renduse)

    useEffect(() => {
        dispatch(DoctorList())
    }, []);

    useEffect(() => {
        setFilterResult(doctorListInfo?.doctors)
    }, [doctorListInfo])

    useEffect(() => {
        if (renduse && doctorListInfo?.doctors) {
            doctorSelect(doctorListInfo?.doctors[0].id)
            setSelectedItem(doctorListInfo?.doctors[0].id)
        }
        return () => dispatch(rendDoc(false))
    }, [renduse,doctorListInfo])

    useEffect(() => {
        document.documentElement.style.setProperty(`--variablename`, myDomainInfo?.DomainStyles?.buttonColor ? myDomainInfo.DomainStyles.buttonColor : "#FDC132");
    },[])
    useEffect(()=>{
        ref.current.focus();
    })

    const searchDoctor = (searchValue) => {
        console.log("searchValue", searchValue)
        setSearchDr(searchValue)
        if (searchValue) {
            const filteredDoctor = doctorListInfo.doctors?.filter((item)=>{
                return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
            })
            console.log('filteredDoctor',filteredDoctor)
   
            setFilterResult(filteredDoctor)
        }
        else {
            setFilterResult(doctorListInfo.doctors)
        }
    }
    const onChangeSearch = (e) => {
        searchDoctor(e.target.value)
        setCancelSearch(true)
    }

    const doctorSelect = (e) => {
        dispatch(doctorInformation(e))
        setSelectedItem(true)
        setSelectDoctor(e)
        dispatch(editDoc(true));

    }


    const docInitialize = (e) => {
        e.preventDefault();
        dispatch(initializeDoc({ doctor: selectDoctor }))
        // dispatch(DoctorList())
    }

    const ref = useRef(null);

    const clearSearch = () => {
        ref.current.value = null;
        setFilterResult(doctorListInfo.doctors)
        setCancelSearch(false)
        setInputSearch(false);
    }

    const searchDoc = () => {
        // ref.current.focus();
        setInputSearch(true);
        setCancelSearch(true);
    }

    console.log("filterResult",filterResult)
    return (
        <div className="doctor-signup-container">
            <div className="doctor-signup-head">
                <h1>Doctor Signup</h1>
                <Button className={"initialize-button"} onClick={docInitialize}>INITIALIZE DOCTOR</Button>
            </div>
            <div class="signup-wrapper">
                <div className="doctor-signup-left-sec">
                    <div className="doctorlist-head">

                        <h3>Doctor List</h3>
                        <div className='doctor-search'>
                            <span className='dr-search'>{cancelSearch ? <img className='clear-input' src={clear} onClick={clearSearch} alt="clear" /> : <img className="icon-sr" onClick={searchDoc} src={SearchIcon} alt="" />}
                            </span>

                        </div>
                        <input className={inputsearch ? 'input-search' : 'input-search-alt'} ref={ref} type="search" onChange={onChangeSearch} placeholder='search doctor...' />

                    </div>
                    <div className="doctorlist-items-sec">
                        {/* {searchDr.length > 1 ? (
                            <div>
                                {filterResult?.map((item) => (
                                    <div className={selectedItem == item.id ? "doctor-list" : "doctor-list-item"} key={item.id} onClick={() => {
                                        doctorSelect(item.id)
                                        setSelectedItem(item.id)
                                    }}>
                                        <h4 >{item.name}</h4>
                                        <img className={selectedItem == item.id ? "black-tick-active" : "black-tick"} src={tickBlack} alt="tick-black" />
                                    </div>
                                ))}
                            </div>
                        ) :
                            ( */}
                        <div>
                            {filterResult?.map((item) => (
                                <div className={selectedItem == item.id ? "doctor-list" : "doctor-list-item black-tick.active"} key={item.id} onClick={() => {
                                    doctorSelect(item.id)
                                    setSelectedItem(item.id)
                                }
                                } >
                                    <h4>{item.name}</h4>
                                    <img className={selectedItem == item.id ? "black-tick-active" : "black-tick"} src={tickBlack} alt="tick-black" />

                                </div>

                            ))} </div>
                        {/* )
                        } */}


                    </div>
                </div>
                <div className="signup-right-sec">
                    <AssignAssistant />

                </div>
            </div>
        </div>
    )
}

export default Signup