import React, { useEffect, useState } from "react";
import doctorLogo from "../../images/defaultImage.jpg";
import userId from "../../images/userId-icon.png";
import passwordIcon from "../../images/password-icon.png";
import "./LoginPage.css";
import { useDispatch, useSelector } from "react-redux";
import { loginDoctor, myDomainData } from "../../redux/actions/login";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/Helper";
import uuid from "react-uuid";
const LoginPage = () => {
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.login);
  const myDomainInfo = useSelector((state) => state.login.myDomainInfo);
  console.log("mydomain", myDomainInfo);
  const history = useHistory();
  let url = baseUrl();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const usernameOnchange = (e) => {
    console.log(e);
    setUsername(e.target.value);
  };
  const passwordOnchange = (e) => {
    setPassword(e.target.value);
  };

  const loginDataSumbit = (e) => {
    e.preventDefault();
    dispatch(loginDoctor({ username, password }));
  };
  const loginKeySubmit = (e) => {
    if (e.key === "Enter") {
      dispatch(loginDoctor({ username, password }));
    }
  };

  useEffect(() => {
    if (loginDetails?.loginInfo?.status === 200) {
      history.push("/opd");
    }
  }, [loginDetails, history]);

  useEffect(() => {
    dispatch(myDomainData());
  }, []);

  return (
    <div className="login-container">
      <div>
        <div className="login-details">
          <div className="doctor-image-login">
            {myDomainInfo === null ? (
              <img src={doctorLogo} alt="profileImage" />
            ) : (
              <img
                src={`${url}img/profile/${
                  myDomainInfo?.subdomain
                }.jpg?lastmod=${uuid()}`}
                alt="subdomain"
              />
            )}
          </div>
          <div>
            <img src={userId} alt="user-id" className="login-icons" />
            <input
              type="text"
              placeholder="User Id"
              value={username}
              onChange={usernameOnchange}
              onKeyPress={loginKeySubmit}
            />
          </div>
          <div>
            <img src={passwordIcon} alt="password" className="login-icons-1" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={passwordOnchange}
              onKeyPress={loginKeySubmit}
            />
          </div>
          <button onClick={loginDataSumbit}>Login</button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
