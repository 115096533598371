import * as types from "../../actionTypes";
import { baseUrl } from "../../../utils/Helper";
import { client } from "../../../utils/axios";

export const resetGetPatient = (payload) => ({
  type: types.RESET_GET_PATIENT,
  payload,
});
export const copyButton = (payload) => ({
  type: types.COPY_BUTTON,
  payload,
});
export const getPatientModified = (payload) => ({
  type: types.GET_PATIENT_APPOINTMENT_MODIFIED,
  payload,
});

export const singlePatientToggle = (payload) => ({
  type: types.SINGLE_PATIENT_TOGGLE,
  payload,
});

const getPatientLoading = () => ({
  type: types.GET_PATIENT_APPOINTMENT_LOADING,
});

export const getPatientSuccess = (data) => ({
  type: types.GET_PATIENT_APPOINTMENT_SUCCESS,
  payload: data,
});
const getPatientFail = (error) => ({
  type: types.GET_PATIENT_APPOINTMENT_FAIL,
  payload: error,
});

let url = baseUrl();

export const getPatientAppointmet = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(getPatientLoading());
    client
      .post(
        `/patient/get-patient`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getPatientSuccess(resp.data));
        // dispatch(getPatientModified({ ...resp.data, guardian: resp.data?.name, name: '' }))
      })
      .catch((error) => {
        dispatch(getPatientFail(error));
      });
  };
};
