import React from 'react'
import '../Iron/Iron.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const ClinicalBloodChemestry = (e) => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'CLINICAL BLOOD BIOCHEMISTRY')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'CLINICAL BLOOD BIOCHEMISTRY'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields = finalFilteredData
    reportDataFields.testReport[index] = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
    <div className='iron-parent'>
        <div className='iron-left'>
            <div className='clinical-blood'>
                <div className='iron1 h-pad'></div>
                <div className='iron2 h-pad clinic'>PLASMA SUGAR FASTING</div>
                <div className='iron3'><Input type='number' name="PLASMA SUGAR FASTING" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className='iron4 h-pad'> mg/dl</div>
                <div className='iron5 h-pad'>60 – 100</div>
                <div className='iron6 h-pad'></div>
                <span className='iron-border'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>PLASMA SUGAR RANDOM</div>
                <div className=''><Input type='number' name="PLASMA SUGAR RANDOM"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mg/dl</div>
                <div className=' h-pad'>	60 – 130</div>
                <div className=' h-pad'></div>
                <span className='iron-border1'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>PLASMA SUGAR PP (2 hrs after meal)</div>
                <div className='in-pad'><Input type='number' name="PLASMA SUGAR PP (2 hrs after meal)"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mg/dl</div>
                <div className=' h-pad'>60 – 160</div>
                <div className=' h-pad'></div>
                <span className='iron-border2'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>PLASMA SUGAR PP (1/2 hrs after 75 gm glucose)</div>
                <div className='in-pad'><Input type='number' name="PLASMA SUGAR PP (1/2 hrs after 75 gm glucose"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>60 – 180</div>
                <div className=' h-pad'></div>
                <span className='iron-border3'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>PLASMA SUGAR PP (1 hrs after 75 gm glucose)</div>
                <div className='in-pad'><Input type='number' name="PLASMA SUGAR PP (1 hrs after 75 gm glucose"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>60 – 180</div>
                <div className=' h-pad'></div>
                <span className='iron-border4'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>PLASMA SUGAR PP (1 & 1/2 hrs after 75 gm glucose)</div>
                <div className='in-pad'><Input type='number' name="PLASMA SUGAR PP  (1 & 1/2 hrs after 75 gm glucose)"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>60 – 160</div>
                <div className=' h-pad'></div>
                <span className='iron-border5'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>PLASMA SUGAR PP (2 hrs after 75 gm glucose)</div>
                <div className='in-pad'><Input type='number' name="PLASMA SUGAR PP (2 hrs after 75 gm glucose"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mg/dl</div>
                <div className=' h-pad'>60 – 160</div>
                <div className=' h-pad'></div>
                <span className='iron-border6'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>PLASMA SUGAR PP (1 & 1/2 hrs after 75 gm glucose)</div>
                <div className='in-pad'><Input type='number' name="PLASMA SUGAR PP (2 & 1/2  hrs after 75 gm"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mg/dl</div>
                <div className=' h-pad'>60 – 160</div>
                <div className=' h-pad'></div>
                <span className='iron-border7 '></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>SERUM UREA</div>
                <div className=''><Input type='number' name="SERUM UREA"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mg/dl</div>
                <div className=' h-pad'>15 - 45</div>
                <div className=' h-pad'></div>
                <span className='iron-border8'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>BLOOD UREA NITROGEN</div>
                <div className=''><Input type='number' name="BLOOD UREA NITROGEN"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>8 - 23</div>
                <div className=' h-pad'></div>
                <span className='iron-border9'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>SERUM CREATININE</div>
                <div className=''><Input type='number' name="SERUM CREATININE"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>0.5 - 1</div>
                <div className=' h-pad'></div>
                <span className='iron-border10'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>SERUM URIC ACID</div>
                <div className=''><Input type='number' name="SERUM URIC ACID"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>2.7 - 7.3</div>
                <div className=' h-pad'></div>
                <span className='iron-border11'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>S. IRON</div>
                <div className=''><Input type='number' name="S. IRON"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> micro gm/dl</div>
                <div className=' h-pad'>59 - 160</div>
                <div className=' h-pad'></div>
                <span className='iron-border12'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>TIBC</div>
                <div className=''><Input type='number' name="TIBC"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>micro gm/dl</div>
                <div className=' h-pad'>228 - 428</div>
                <div className=' h-pad'></div>
                <span className='iron-border13'></span>
            </div>
        </div>
            <div>
                <TestReportRightSection />
            </div>
    </div>

    <div className='iron-foot'>
        <ReportFooter />
    </div>
    <div className='irn-method'>
      <h4>Note:</h4>
      
      <p>These test have technical limitetion. Clinical Pathological interpretation is necessary <br />
in case of any discrepancy test may be repeated immediately. This Report is not for medicolegal purpose.</p>
    </div>
</div>
  )
}
