import * as types from "../../../actionTypes";

const initialState ={
    doctorList: [],
    doctorInform:[],
    error: {},
    loading: false
} 

export const doctorListReducer = (state= initialState, action) => {
    switch(action.type){
        case types.DOCTOR_LIST_LOADING:
            return{
                ...state,
                loading:true
            }
        case types.DOCTOR_LIST_SUCCESS:
            return{
                ...state,
                doctorList:action.payload,
                loading:false,
                error:{}
            }
        case types.DOCTOR_LIST_FAIL:
            return{
                ...state,
                errro:action.payload,
                loading:false,
                doctorList:[]
            
            }
        case types.DOCTOR_INFO_LOADING:
            return{
                ...state,
                loading:true
            }
        case types.DOCTOR_INFO_SUCCESS:
            return{
                ...state,
                doctorInform:action.payload,
                loading:false,
                error:{}
            }
        case types.DOCTOR_INFO_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                doctorInform:[]
            }
            default:
                return state
    }
}