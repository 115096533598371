import * as types from "../../actionTypes";
import toast from "react-hot-toast";
import qs from "qs";
import { client } from "../../../utils/axios";

export const alternateNumLoading = () => ({
  type: types.ALTERNATE_NUM_LOADING,
});

export const alternateNumSuccess = (data) => ({
  type: types.ALTERNATE_NUM_SUCCESS,
  payload: data,
});

export const alternateNumFail = (error) => ({
  type: types.ALTERNATE_NUM_FAIL,
  payload: error,
});

export const alternateNumber = (data) => {
  return function (dispatch) {
    dispatch(alternateNumLoading());
    client
      .post(
        "/admin/use-alternate",
        qs.stringify(data)
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(alternateNumSuccess(resp.data));
        console.log("alternate", resp.data);
        toast.success("preference updated for");
      })
      .catch((error) => {
        console.log(error);
        dispatch(alternateNumFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
