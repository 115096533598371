import * as types from '../../actionTypes';

const initialState = {
    patientHistoryData: {},
    visitImage: [],
    singleAppointment: {},
    error: {},
    historyImage: [],
    loading: false
}


export const patientHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPLOAD_VISIT_IMAGE_VAULT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.UPLOAD_VISIT_IMAGE_VAULT_SUCCESS:
            return {
                ...state,
                patientHistoryData: action.payload,
                loading: false,
                error: {}
            }
        case types.UPLOAD_VISIT_IMAGE_VAULT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                patientHistoryData: {}
            }
        case types.VIEW_VISIT_VAULT_IMAGES_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.VIEW_VISIT_VAULT_IMAGES_SUCCESS:
            return {
                ...state,
                visitImage: action.payload,
                loading: false,
                error: {}
            }
        case types.VIEW_VISIT_VAULT_IMAGES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case types.GET_SINGLE_APPOINTMENT:
            return {
                ...state,
                singleAppointment: action.payload,
                loading: false,

            }
        case types.HISTORY_IMAGE_SUCCESS:
            return {
                ...state,
                historyImage: action.payload,
            }
        case types.VAULT_IMAGES_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        default:
            return state;
    }
}