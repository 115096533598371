import React from 'react'
import './LFT.css'
import Input from '../../../../UIComponents/Input/Input'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function Lft() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state.diagnosticsInvoiceSummary.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'LFT')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'LFT'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
    <div className='lft-parent'>
        <div className='lft-left'>
            <div className='lft-grid'>
                <div className='lft-1 l-pad'></div>
                <div className='lft-2 l-pad'>TOTAL BILIRUBIN</div>
                <div className='lft-3'><Input type='number' className={'lft-input'} name="TOTAL BILIRUBIN" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='lft-4 l-pad'>mg/dl</div>
                <div className='lft-5 l-pad'>0.1-1.2</div>
                <div className='lft-6 l-pad'></div>
                <span className='lft-border'></span>
                <div className='l-pad'></div>
                <div className='l-pad'>DIRECT BILIRUBIN</div>
                <div className=''><Input type='number' className={'lft-input'} name="DIRECT BILIRUBIN" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>mg/dl</div>
                <div className='l-pad'>0-0.4</div>
                <div className='l-pad'></div>
                <span className='lft-border1'></span>
                <div className='l-pad'></div>
                <div className='l-pad'>INDIRECT BILIRUBIN</div>
                <div className=''><Input type='number' className={'lft-input'} name="INDIRECT BILIRUBIN" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>mg/dl</div>
                <div className='l-pad'>0.1-0.8</div>
                <div className='l-pad'></div>
                <span className='lft-border2'></span>
                <div className='l-pad'></div>
                <div className='l-pad'>SGOT (AST)</div>
                <div className=''><Input type='number' className={'lft-input'} name="SGOT (AST)" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>U/L</div>
                <div className='l-pad'>5-38</div>
                <div className='l-pad'></div>
                <span className='lft-border3'></span>
                <div className='l-pad'></div>
                <div className='l-pad'>SGPT (ALT)</div>
                <div className=''><Input type='number' className={'lft-input'} name="SGPT (ALT)" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>U/L</div>
                <div className='l-pad'>5-40</div>
                <div className='l-pad'></div>
                <span className='lft-border4'></span>
                <div className='l-pad'></div>
                <div className='l-pad'>ALKALINE PHOSPHATASE</div>
                <div className=''><Input type='number' className={'lft-input'} name="ALKALINE PHOSPHATASE" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>U/L</div>
                <div className='l-pad'>Adult 54-128/ child 56-358</div>
                <div className='l-pad'></div>
                <span className='lft-border5'></span>
                <div className='l-pad'>TOTAL PROTEIN</div>
                <div className='l-pad'>PROTEIN</div>
                <div className=''><Input type='number' className={'lft-input'} name="PROTEIN" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>gm/dl</div>
                <div className='l-pad'>6-8</div>
                <div className='l-pad'></div>
                <span className='lft-border6'></span>
                <div className='l-pad'>TOTAL PROTEIN</div>
                <div className='l-pad'>ALBUMIN</div>
                <div className=''><Input type='number' className={'lft-input'} name="ALBUMIN" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>gm/dl</div>
                <div className='l-pad'>3.2-4.5</div>
                <div className='l-pad'></div>
                <span className='lft-border7'></span>
                <div className='l-pad'>TOTAL PROTEIN</div>
                <div className='l-pad'>GLOBULIN</div>
                <div className=''><Input type='number' className={'lft-input'} name="GLOBULIN" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>gm/dl</div>
                <div className='l-pad'>2.3-3.5</div>
                <div className='l-pad'></div>
                <span className='lft-border8'></span>
                <div className='l-pad'>TOTAL PROTEIN</div>
                <div className='l-pad'>A:G RATIO</div>
                <div className=''><Input type='number' className={'lft-input'} name="A:G RATIO" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='l-pad'>gm/dl</div>
                <div className='l-pad'>1.1-2.1</div>
                <div className='l-pad'></div>
                <span className='lft-border9'></span>
            </div>
        </div>
            <div>
                <TestReportRightSection />
            </div>
    </div>
    <div className='lft-foot'>
          <ReportFooter />
    </div>
    <div className='lft-p'>
          <h4>Note:</h4>
          <p>INVESTIGATION HAVE THEIR LIMITATION. PATHOLOGICAL/RADIOLOGICAL&OTHER INVESTIGATION NEVER CONFIRM THE FINAL DIAGNOSIS OF THE DISEASE THEY ONLY HELP IN DIAGNOSING THE DISEASE IN CORRELATION TO CLINICAL SYMPTOMS & OTHER RELATED TESTS PLEASE INTERPRET ACCORDINGLY.</p>
          
        </div>
</div>
  )
}

export default Lft