import * as types from '../actionTypes';

const initialState = {
    profileInfo: [],
    updateProfile: [],
    clinicLocations: [],
    loading: false,
    editVisisbleModal: false,
    clinicId:"",
    error: {},
    selectedIndex: {}
}

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PROFILE_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profileInfo: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                profileInfo: []
            }
        case types.UPDATE_PROFILE_LOADING:
            return {
                ...state,
                loading: true
            }

        case types.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateProfile: action.payload,
                loading: false,
                error: {}
            }
        case types.UPDATE_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                profileInfo: [],
                updateProfile: []
            }

        case types.UPDATE_PROFILE_DATA:
            return {
                ...state,
                profileInfo: action.payload,
            }
        case types.SELECTED_INDEX:
            return {
                ...state,
                selectedIndex: action.payload,
            }
        case types.EDIT_VISIBLE:
            return {
                ...state,
                editVisisbleModal: action.payload,
                loading: false
            }
        case types.CLINIC_LOCATIONS:
            return {
                ...state,
                clinicLocations: action.payload,
                loading: false
            }
        case types.CLINIC_LOCATION_ID:
            return {
                ...state,
                clinicId: action.payload,
                loading: false
            }
        case types.DOCTOR_PROFILE_THEME_APPLY:
            return {
                ...state,
                loading: false
            }
        case types.DOCTOR_PROFILE_UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }

}