import React from 'react'
import './SerumBilirubin.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const SerumBilirubin = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'SERUM BILIRUBIN')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'SERUM BILIRUBIN'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index]  = filterData[0]
      console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
        <div className='bili-parent'>
            <div className='bili-left'>
                <div className='bili-grid'>
                    <div className='bili1 h-pad'></div>
                    <div className='bili2 h-pad'>S. BILURUBIN</div>
                    <div className='bili3'><Input type='number' className={'bili-input'} name="S. BILURUBIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active}onChange={onInputChange} /></div>
                    <div className='bili4 h-pad'> MG%</div>
                    <div className='bili5 h-pad'>0.1 - 1.2</div>
                    <div className='bili6 h-pad'></div>
                    <span className='bili-border'></span>
                    <div className=' h-pad'></div>
                    <div className=' h-pad'>SGPT</div>
                    <div className=''><Input type='number' className={'bili-input'} name="SGPT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}onChange={onInputChange} /></div>
                    <div className=' h-pad'> IU/L</div>
                    <div className=' h-pad'>5 - 40</div>
                    <div className=' h-pad'></div>
                    <span className='bili-border2'></span>
                    <div className=' h-pad'></div>
                    <div className=' h-pad'>SGOPT</div>
                    <div className=''><Input type='number' className={'bili-input'} name="SGOPT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <div className=' h-pad'>  IU/L</div>
                    <div className=' h-pad'>5 - 40</div>
                    <div className=' h-pad'></div>
                    <span className='bili-border3'></span>
                </div>
            </div>
                <div>
                    <TestReportRightSection />
                </div>
        </div>
       
        <div className='bili-foot'>
              <ReportFooter />
        </div>
    </div>
  )
}

