import React from 'react'
import './LipidProfile.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import calculateGrayLogo from "../../../../images/calculate-gray.png";
import Button from "../../../../UIComponents/Button/Button";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


export const LipidProfile = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      setInputValue(value)
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'LIPID PROFILE')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'LIPID PROFILE'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
            <div className='add-cal-btn'>
                <Button><img width={20} src={calculateGrayLogo} alt="calculate img"/>Auto Calculate</Button>
            </div>
        <div className='lipid-parent'>
            <div className='lipid-left'>
                <div className='lipid-grid'>
                    <div className='lipid1 h-pad'></div>
                    <div className='lipid2 h-pad'>S CHOLESTEROL</div>
                    <div className='lipid3'><Input type='number' className={'lipid-input'} name="S CHOLESTEROL" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='lipid4 h-pad'> mg/dl</div>
                    <div className='lipid5 h-pad'>100-175</div>
                    <div className='lipid6 h-pad'></div>
                    <span className='lipid-border'></span>
                    <div className=' h-pad'></div>
                    <div className=' h-pad'>S TRIGLYCERIDES</div>
                    <div className=''><Input type='number' className={'lipid-input'} name="S TRIGLYCERIDES" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className=' h-pad'> mg/dl</div>
                    <div className=' h-pad'>25-150</div>
                    <div className=' h-pad'></div>
                    <span className='lipid-border1'></span>
                    <div className=' h-pad'></div>
                    <div className=' h-pad'>HDL CHOLESTEROL</div>
                    <div className=''><Input type='number' className={'lipid-input'} name="HDL CHOLESTEROL" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className=' h-pad'> mg/dl</div>
                    <div className=' h-pad'>30-70</div>
                    <div className=' h-pad'></div>
                    <span className='lipid-border2'></span>
                    <div className=' h-pad'></div>
                    <div className=' h-pad'>VLDL CHOLESTEROL</div>
                    <div className='back'><Input type='number' className={'back-input'} name="VLDL CHOLESTEROL" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className=' h-pad'> mg/dl</div>
                    <div className=' h-pad'>7-35</div>
                    <div className=' h-pad'></div>
                    <span className='lipid-border3'></span>
                    <div className=' h-pad'></div>
                    <div className=' h-pad'>LDL CHOLESTEROL</div>
                    <div className='back'><Input type='number' className={'back-input'} name="LDL CHOLESTEROL" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className=' h-pad'> mg/dl</div>
                    <div className=' h-pad'>70-130</div>
                    <div className=' h-pad'></div>
                    <span className='lipid-border4'></span>
                    <div className=' h-pad'></div>
                    <div className=' h-pad'>S CHOLESTEROL/HDLC Ratio</div>
                    <div className='back'><Input type='number' className={'back-input'} name="S CHOLESTEROL/HDLC Ratio" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className=' h-pad'> %</div>
                    <div className=' h-pad'></div>
                    <div className=' h-pad'></div>
                    <span className='lipid-border5'></span>
                </div>
            </div>
                <div>
                    <TestReportRightSection />
                </div>
        </div>
       
        <div className='lipid-foot'>
              <ReportFooter />
        </div>
        <div className='lipid-method'>
              <h4>INTERPRETATION</h4>
              <p>HIGH HDL CHOLESTEROL & LOW LDL CHOLESTROL  VALUES ARE GOOD FOR HEART   CONVERAELY, LOW HDL CHOLESTEROL & HIGH LDL CHOLESTEROL VALUES ARE BAD FOR HEART</p>
            </div>
    </div>
  )
}
