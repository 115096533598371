import React from 'react'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './embryologyReport.css'
import {field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'

export const EmbryologyReport = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")

    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))    }
      
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'EMBRYOLOGY REPORT')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'EMBRYOLOGY REPORT'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
   
    return (
        <div>
            <div className='embryo-main'>
                <div className='embryo-container'>
                    <div className='embryo-grid-container'>
                        <div className='embryo-1 padd' onClick={() => onLabelClicked("ART PROCEDURE")} >ART PROCEDURE</div>
                        <div className='embryo-2'><TextAreaAnt name ="ART PROCEDURE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active}  onChange={onInputChange} /></div>
                        <span className='embryo-border1'></span>
                        <div className='embryo-3 padd' onClick={() => onLabelClicked("DATE OF EMBRYO TRANSFER")} >DATE OF EMBRYO TRANSFER</div>
                        <div className='embryo-4'><TextAreaAnt name ="DATE OF EMBRYO TRANSFER" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='embryo-border2'></span>
                        <div className='embryo-5 padd' onClick={() => onLabelClicked("NO. OF EMBRYOS TRANSFERRED WITH GRADING")}>NO. OF EMBRYOS TRANSFERRED WITH GRADING</div>
                        <div className='embryo-6'><TextAreaAnt name ="NO. OF EMBRYOS TRANSFERRED WITH GRADING" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='embryo-border3'></span>
                        <div className='embryo-7 padd' onClick={() => onLabelClicked("NO OF FROZEN EMBRYOS LEFT")} >NO OF FROZEN EMBRYOS LEFT</div>
                        <div className='embryo-8'><TextAreaAnt name ="NO OF FROZEN EMBRYOS LEFT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active}  onChange={onInputChange} /></div>
                        <span className='embryo-border4'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}
