import React, { useState } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import InputField from '../../../../UIComponents/Input/Input'
import '../Note/Note.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import CommonButton from '../../../../UIComponents/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { addPatientNote, updatePatientNote } from '../../../../redux/actions/opd/patientreport'
import { Input } from 'antd'

const { TextArea } = Input;
function Note() {
    const dispatch=useDispatch()
    const patientNote = useSelector(state => state.opdrecord.patientreport.patientNote)
    console.log("patient note", patientNote)
    const patientId = useSelector(state => state.opdrecord.patientlist.selectedPatient)
    console.log("patient id in notes",patientId)
    const onPatientNoteChange = (e) => {
        dispatch(updatePatientNote({...patientNote,note:e.target.value}))
    }
    const submitPatientNote=()=>{
        dispatch(addPatientNote({patientId:patientId.patientId,note:patientNote.note}))
    }


    return (
        <div>
            <div className='note-div'>
                <NumberButton className="numberButton-4">4</NumberButton>
                <div className='note-4-div'>
                    <h6>NOTE</h6>
                </div>
                <div className='note-antSelect'>
                    <TextArea placeholder="Write Note" className={'note-input-field'} value={patientNote?.note} onChange={onPatientNoteChange}  />
                    <CommonButton className={'note-save-btn'} onClick={submitPatientNote}>SAVE</CommonButton>
                </div>
            </div>
            <div>
                <Divider />
            </div>
        </div>
    )
}

export default Note