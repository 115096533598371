import * as types from "../../actionTypes";
import { mastermedicine } from "./medicinemaster";
import { client } from "../../../utils/axios";

const medicineActivateLoading = () => ({
  type: types.MEDICINE_ACTIVATE_LOADING,
});

const medicineActivateSuccess = (data) => ({
  type: types.MEDICINE_ACTIVATE_SUCCESS,
  payload: data,
});
const medicineActivateFail = (error) => ({
  type: types.MEDICINE_ACTIVATE_FAIL,
  payload: error,
});

export const medicineactivate = (data) => {
  console.log("api call", data);
  return function (dispatch) {
    dispatch(medicineActivateLoading());
    client
      .post(
        `/admin/medicine-activate`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(medicineActivateSuccess(resp.data));
        console.log("medicine activate data", resp.data);
        dispatch(mastermedicine("search%5Bvalue%5D=&start=0&length=100"));
      })
      .catch((error) => {
        console.log("error from medicineactivate", error.response);
        dispatch(medicineActivateFail(error));
      });
  };
};
