import React, { useEffect, useState } from 'react';
import './Visiting.css';
import Button from '../../../UIComponents/Button/Button';
import { Switch, Modal } from 'antd'
import InputField from '../../../UIComponents/Input/Input';
import { TimePicker } from 'antd';
import moment from 'moment'
import SingleSelect from '../../../UIComponents/Select/SingleSelect';
import { Checkbox } from 'antd';
import deleteIcon from '../../../images/delete-icon.png';
import editIcon from '../../../images/edit-icon.png';
import plusIcon from '../../../images/plus-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectedIndex, updateProfileData, updateProfileDetails } from '../../../redux/actions/profile'
import { toast } from '../../../UIComponents/Toast';
import { editVisible } from '../../../redux/actions/profile'
import { ConsoleSqlOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
const slotList = [
  {
    id: "5",
    name: "5"
  },
  {
    id: "10",
    name: "10"
  },
  {
    id: "15",
    name: "15"
  },
  {
    id: "20",
    name: "20"
  },
  {
    id: "25",
    name: "25"
  },
  {
    id: "30",
    name: "30"
  },
  {
    id: "35",
    name: "35"
  },
  {
    id: "40",
    name: "40"
  },
  {
    id: "45",
    name: "45"
  },
  {
    id: "50",
    name: "50"
  },
  {
    id: "55",
    name: "55"
  },
  {
    id: "60",
    name: "60"
  },
]

const weeksData = [
  {
    id: "1",
    name: "Monday",
    isSelected: false
  },
  {
    id: "2",
    name: "Tuesday",
    isSelected: false

  },
  {
    id: "3",
    name: "Wednesday",
    isSelected: false

  },
  {
    id: "4",
    name: "Thursday",
    isSelected: false

  },
  {
    id: "5",
    name: "Friday",
    isSelected: false

  },
  {
    id: "6",
    name: "Saturday",
    isSelected: false

  },
  {
    id: "7",
    name: "Sunday",
    isSelected: false

  },

]

let statecityList = {
  "Andhra Pradesh": ["Amravathi"],
  "Arunachal Pradesh": ["Itanagar"],
  "Assam": ["Dispur"],
  "Bihar": ["Patna"],
  "Chandigarh": ["Chandigarh"],
  "Chhattisgarh": ["Raipur"],
  Delhi: ["Delhi"],
  Goa: ["Panaji"],
  Gujarat: ["Ahmedabad", "Gandhinagar"],
  Haryana: ["Chandigarh", "Gurugram", "Faridabad", "Gurgaon"],
  "Himachal Pradesh": ["Shimla"],
  "Jammu & Kashmir": ["Srinagar", "Jammu"],
  Jharkhand: ["Ranchi"],
  Karnataka: ["Bangalore"],
  Kerala: ["Thiruvananthapuram"],
  "Madhya Pradesh": ["Bhopal"],
  Maharashtra: ["Mumbai", "Pune"],
  Manipur: ["Imphal"],
  Meghalaya: ["Shillong"],
  Mizoram: ["Aizawl"],
  Nagaland: ["Kohima"],
  Orissa: ["Bhubaneshwar"],
  Punjab: ["Chandigarh"],
  Rajasthan: ["Jaipur"],
  Sikkim: ["Gangtok"],
  "Tamil Nadu": ["Chennai"],
  Telangana: ["Hyderabad"],
  "Andhra Pradesh": ["Vizianagaram"],
  Tripura: ["Agartala"],
  "Uttar Pradesh": [
    "Noida",
    "Ghaziabad",
    "Meerut",
    "Vaishali",
    "Indirapuram",
    "Aligarh",
    "Bareilly",
    "Agra",
    "Jaunpur",
    "Mirzapur",
    "Bhadohi",
    "Mughalsarai",
    "Gazipur",
    "Ballia",
    "Azamgarh",
    "Mau",
    "Allahabad",
    "Deoria",
  ],
  Uttarakhand: ["Dehradun"],
  "West Bengal": ["Kolkata"],
}

const statesList = Object.keys(statecityList);

const VisitingAreas = () => {
  const dispatch = useDispatch()

  const profileInfo = useSelector(state => state.profile.profileInfo)
  console.log("profileInfoddddddd", profileInfo);
  const update = useSelector(state => state.profile.updateProfile)
  console.log("update", update)
  const error = useSelector(state => state.profile.error)
  console.log("error", error)
  const seletedBranch = useSelector(state => state.profile.selectedIndex)
  console.log("selectedBranch", seletedBranch)
  const editVisibleToggle = useSelector(state => state.profile.editVisisbleModal)
  console.log("editVisibleToggle", editVisibleToggle);

  const [visible, setVisible] = useState(false);
  const [editcityList, setEditCityList] = useState([])
  const [noOfSlots, setNoOfSlots] = useState("")
  const [isState, setIsState] = useState("")
  const [isCity, setIsCity] = useState([])
  const [finalCity, setFinalCity] = useState("")
  const [days, setDays] = useState([])
  const [editDays, setEditDays] = useState([])

  const [displayTiming, setdisplayTiming] = useState([{
    days: [],
    slots: [
      {
        start: "",
        end: ""
      },
      {
        start: "",
        end: ""
      }
    ]
  },])
  const [locations, setLocation] = useState(
    {
      name: "",
      address1: "",
      address2: "",
      mobile: "",
      appointmentsPerSlot: "",
      displayTiming: [],
      city: "",
      state: "",
      slotDuration: "",
      pincode: "",
    }
  )


  const addNew = () => {
    setVisible(true)
  }
  const modalClose = () => {
    setVisible(false)
    setLocation([])
    setIsState("")
    setNoOfSlots("")
    setLocation([])
    setFinalCity("")
    setDays([])
    setdisplayTiming([{
      days: [],
      slots: [
        {
          start: "",
          end: ""
        },
        {
          start: "",
          end: ""
        }
      ]
    }])
  }
  const [evngDay, setEvngDay] = useState([])
  const editModalClose = () => {
    dispatch(editVisible(false))
    setEditDays([])
  }
  useEffect(() => {
    console.log("displaytimings", displayTiming)
  }, [displayTiming])
  useEffect(() => {

    setLocation({ ...locations, slotDuration: noOfSlots, state: isState, city: finalCity, displayTiming: displayTiming })
    console.log("locationsdisplay", locations)
  }, [noOfSlots, isState, finalCity, displayTiming])

  const handleNoOfSlots = (value) => {
    setNoOfSlots(value)
  }
  const handleStateChange = (value) => {
    setIsState(value)
    const city1 = statecityList[value]
    setIsCity(city1)

  }
  const handleCityChange = (value) => {
    setFinalCity(value)
  }

  const onDispalyChange = (e, i) => {
    console.log("checked days", e.target.value, i, displayTiming);
    let arr = [...displayTiming]
    if (e.target.checked === true) {
      arr[i].days = [...arr[i].days, e.target.value]
      setDays([...days, { selectedIndex: i, selectedDay: e.target.value }]);
      setdisplayTiming(arr)
    } else {
      arr[i].days = [...arr[i].days].filter(item => item !== e.target.value)
      setDays([...days.filter(item => item.selectedDay !== e.target.value)]);
      setdisplayTiming(arr)
    }

  }

  const startTimeOnChange = (time, timeString, i) => {
    let arr1 = [...displayTiming]
    arr1[i].slots[0].start = `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
    setdisplayTiming(arr1)


  }
  const endTimeOnChange = (time, timeString, i) => {
    let arr1 = [...displayTiming]
    arr1[i].slots[0].end = `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
    setdisplayTiming(arr1)
  }
  const startTimeEvngOnChange = (time, timeString, i) => {
    let arr1 = [...displayTiming]
    arr1[i].slots[1].start = `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
    setdisplayTiming(arr1)


  }
  const endTimeEvngOnChange = (time, timeString, i) => {
    let arr1 = [...displayTiming]
    arr1[i].slots[1].end = `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
    setdisplayTiming(arr1)
  }


  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      const result = value.replace(/[^a-zA-Z\s]/g, '');
      setLocation({ ...locations, [name]: result })
    }
    else {
      setLocation({ ...locations, [name]: value })
    }
  }


  const handleAddBranch = (e) => {
    e.preventDefault()
    if (!locations.name || !locations.address1 || !locations.address2 || !locations.city || !locations.mobile || !locations.pincode || !locations.slotDuration) {
      toast.error("All fields are required")
    }
    else if (locations.pincode.length < 6) {
      toast.error("Please add valid Pincode")

    }
    else if (locations.mobile.length < 10) {
      toast.error("Please add valid mobile number")

    }
    else {
      if (profileInfo.hasOwnProperty('locations')) {
        let loc = profileInfo?.locations
        let lastLoc = locations
        let tempDisplay = lastLoc.displayTiming.map(item => {
          return {
            ...item, slots: item.slots.filter(e => {
              if (e.start !== "" || e.end !== "") {
                return e
              }
            })
          }
        })

        lastLoc = { ...lastLoc, displayTiming: tempDisplay }
        console.log("lastloc", lastLoc, tempDisplay)
        loc?.push(lastLoc)
        dispatch(updateProfileData({ ...profileInfo, locations: [loc] }))
        dispatch(updateProfileDetails(profileInfo))
        setVisible(false)
        setLocation([])
        setIsState("")
        setFinalCity("")
        setNoOfSlots("")
        setDays([])
        setdisplayTiming([{
          days: [],
          slots: [
            {
              start: "",
              end: ""
            },
            {
              start: "",
              end: ""
            }
          ]
        }])
      } else {
        let lastLoc = locations
        let tempDisplay = lastLoc.displayTiming.map(item => {
          return {
            ...item, slots: item.slots.filter(e => {
              if (e.start !== "" || e.end !== "") {
                return e
              }
            })
          }
        })

        lastLoc = { ...lastLoc, displayTiming: tempDisplay }
        console.log("lastloc", lastLoc, tempDisplay)
        dispatch(updateProfileData({ ...profileInfo, locations: [lastLoc] }))
        console.log("sjkdkdkkd", profileInfo)
        // dispatch(updateProfileDetails(profileInfo))
        setVisible(false)
        // if(profileInfo.hasOwnProperty("gender")|| profileInfo.hasOwnProperty("email")|| profileInfo.hasOwnProperty("gender"))
        toast.error("Please fill Profile Details as well and save details")
        setLocation([])
        setIsState("")
        setFinalCity("")
        setNoOfSlots("")
        setDays([])
        setdisplayTiming([{
          days: [],
          slots: [
            {
              start: "",
              end: ""
            },
            {
              start: "",
              end: ""
            }
          ]
        }])

      }

    }


  }

  const addSlotMethod = () => {
    setdisplayTiming([...displayTiming, {
      days: [],
      slots: [
        {
          start: "",
          end: ""
        },
        {
          start: "",
          end: ""
        }
      ]
    }])

  }

  const removeSlotAdd = index => {
    if (displayTiming.length !== 1)
      setdisplayTiming([...displayTiming].filter((item, i) => i !== index))
  };

  const deleteBranch = (index) => {
    if (profileInfo?.locations?.length !== 1)
      dispatch(updateProfileData({ ...profileInfo, locations: [...profileInfo.locations].filter((item, i) => i !== index) }))
  }

  const editBranch = (index) => {
    dispatch(editVisible(true))
    let singleBranch = profileInfo.locations.find((item, i) => i === index)
    dispatch(selectedIndex(singleBranch))
  }

  const handleEditChange = (e) => {
    let { name, value } = e.target
    console.log("fdsfsgfsgfsdf", name, value)
    if (name === "name") {
      const result = value.replace(/[^a-zA-Z\s]/g, '');
      console.log("fdssdfsdfsfsdfs", result)
      dispatch(selectedIndex({ ...seletedBranch, [name]: result }))
    }
    else {
      dispatch(selectedIndex({ ...seletedBranch, [name]: value }))
    }
  }
  const handleEditState = (value) => {
    dispatch(selectedIndex({ ...seletedBranch, state: value }))
    const editCity = statecityList[value]
    setEditCityList(editCity)
  }
  const handleEditCity = (value) => {
    dispatch(selectedIndex({ ...seletedBranch, city: value }))
  }
  const handleEditSlotDuration = (value) => {
    dispatch(selectedIndex({ ...seletedBranch, slotDuration: value }))

  }
  const weekEditChange = (e, i) => {
    console.log("checked", e.target.checked, seletedBranch.displayTiming, i)
    let arr = [...seletedBranch.displayTiming]
    if (e.target.checked === true) {
      console.log("editdisplaytimingtrue", arr)
      arr[i].days = [...arr[i].days, Number(e.target.value)]
      setEditDays([...editDays, { selectedIndex: i, selectedDay: e.target.value }]);
      arr[i - 1].days = [...arr[i - 1].days].filter(item => item !== Number(e.target.value))
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr }))
    } else {
      console.log("editdisplaytimingfalse", arr)
      arr[i].days = [...arr[i].days].filter(item => item !== Number(e.target.value))
      setEditDays([...editDays.filter(item => item.selectedDay !== e.target.value)]);
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr }))
    }
  }

  const startTimeEditOnChange = (time, timeString, i) => {
    let arr1 = [...seletedBranch.displayTiming]
    arr1[i].slots[0].start = `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
    dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }))
  }
  const endTimeEditOnChange = (time, timeString, i) => {
    let arr1 = [...seletedBranch.displayTiming]
    arr1[i].slots[0].end = `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
    dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }))
  }
  const startTimeEvngEditOnChange = (time, timeString, i) => {
    let arr1 = [...seletedBranch.displayTiming]
    console.log("sdjjd", arr1, i, timeString)
    if (arr1[i]?.slots[1]?.start || arr1[i]?.slots[1]?.end) {
      arr1[i].slots[1].start = `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
      console.log("dispatechedtime", arr1)
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }))
    } else {
      arr1[i]?.slots.push({ start: `${timeString.split(':')[0]}:${timeString.split(':')[1]}` })
      console.log("dispatechedtime", arr1)
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }))

    }
  }

  const endTimeEvngEditOnChange = (time, timeString, i) => {
    let arr1 = [...seletedBranch.displayTiming]
    if (arr1[i]?.slots[1]?.end || arr1[i]?.slots[1]?.start) {
      arr1[i].slots[1].end = `${timeString.split(':')[0]}:${timeString.split(':')[1]}`
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }))
    } else {
      arr1[i].slots.push({ end: `${timeString.split(':')[0]}:${timeString.split(':')[1]}` })
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: arr1 }))


    }
  }

  const handleEditSubmit = () => {
    if (seletedBranch.pincode.length < 6) {
      toast.error("Please add valid pincode")
    }
    else if (seletedBranch.mobile.length < 10) {
      toast.error("Please add valid mobile number")

    } else {
      let foundIndex = profileInfo.locations.findIndex(item => item.id === seletedBranch.id);
      let arr = [...profileInfo.locations]
      let lastLoc = seletedBranch
      let tempDisplay = lastLoc.displayTiming.map(item => {
        return {
          ...item, slots: item.slots.filter(e => {
            if (e.start !== "" || e.end !== "") {
              return e
            }
          })
        }
      })

      lastLoc = { ...lastLoc, displayTiming: tempDisplay }
      arr[foundIndex] = lastLoc
      console.log("edit display timings", lastLoc)
      dispatch(updateProfileDetails({ ...profileInfo, locations: arr }))
      dispatch(editVisible(false))
      setEditDays([])
    }


  }
  const addEditSlotMethod = () => {
    dispatch(selectedIndex({
      ...seletedBranch, displayTiming: [...seletedBranch.displayTiming, {
        days: [],
        slots: [
          {
            start: "",
            end: ""
          },
        ]
      }]
    }))

  }
  const removeEditSlot = (index) => {
    if (seletedBranch.displayTiming.length !== 1)
      dispatch(selectedIndex({ ...seletedBranch, displayTiming: [...seletedBranch.displayTiming].filter((item, i) => i !== index) }))
  }
  useEffect(() => {
    if (seletedBranch) {
      let arr = [];
      seletedBranch?.displayTiming?.map((e, i) => {
        e?.days?.map(item => {
          return arr.push({ selectedIndex: i, selectedDay: item?.toString() });
        })
      })
      setEditDays(arr)
    }
    console.log("djfjfjfj", editDays)
  }, [seletedBranch])


  const { name, address1, address2, mobile, pincode, appointmentsPerSlot, city } = locations

  return (
    <div>
      <div className="branch-name">
        <h1>VISITING AREAS</h1>
        <Button className={"new-branch-btn"} onClick={addNew}>ADD NEW BRANCH</Button>
      </div>

      {profileInfo && profileInfo ? profileInfo?.locations?.map((area, i) => (
        <div className="vising-areas-section">
          <div className="visiting-icon-sec">
            <span><img src={deleteIcon} alt="delete" className="medicine-table-delete" onClick={() => deleteBranch(i)} /></span>
            <span><img src={editIcon} alt="edit" className="medicine-table-edit" onClick={() => editBranch(i)} /></span>

          </div>
          <div className="add-branch-modal-sec">
            <p>FACILITY NAME</p>
            <div className="add-branch-input"><InputField type="text" name="name" value={area.name} placeholder="Facility name" maxLength={35} /></div>
          </div>
          <div className="add-branch-modal-sec">
            <p>TIME SLOTS FOR APPOINTMENTS</p>
            <div style={{ width: "70%" }}>
              {area?.displayTiming?.map((item, i) => <>
                <div className="add-branch-profile-slot">

                  <div className="profile-day-check">
                    {weeksData.map((day) => (
                      <Checkbox className="check-width" checked={item.days.filter(e => e == day.id).length > 0} name={day.name} value={day.id}>{day.name}</Checkbox>
                    ))}
                  </div>

                </div>
                <div className='profile-time-main-sec'>

                  <div className='profile-mornig-sec'>
                    <h4 className='morning-p'>Morning</h4>
                    <div style={{ display: "flex", width: '100%' }}>
                      <div className='profile-mornig-sec'>
                        <h4>Start Time</h4>
                        <InputField className="add-start-time" value={moment(item?.slots?.[0].start, "HH:mm").format("HH:mm:ss a")} placeholder="" />
                      </div>
                      <div className='profile-mornig-sec'>
                        <h4>End Time</h4>
                        <InputField className="add-start-time" value={moment(item?.slots?.[0].end, "HH:mm").format("HH:mm:ss a")} placeholder="" />
                      </div>

                    </div>
                  </div>
                  <div className='profile-evng-sec'>
                    <h4 className='morning-p'>Evening</h4>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div className='profile-mornig-sec'>
                        <h4>Start Time</h4>
                        <InputField className="add-start-time" value={item?.slots?.[1]?.start ? moment(item?.slots?.[1]?.start, "HH:mm").format("HH:mm:ss a") : "00:00:00"} placeholder="" />

                      </div>
                      <div className='profile-mornig-sec'>
                        <h4>End Time</h4>
                        <InputField className="add-start-time" value={item?.slots?.[1]?.end ? moment(item?.slots?.[1]?.end, "HH:mm").format("HH:mm:ss a") : "00:00:00"} placeholder="" />
                      </div>

                    </div>
                  </div>
                </div>

              </>)}

            </div>

          </div>
          <div className="add-branch-modal-sec">
            <p>SLOT DURATION</p>
            <div className="add-branch-input"> <InputField placeholder="Slot duration" value={area.slotDuration} /></div>
          </div>

          <div className="add-branch-modal-sec">
            <p>ADDRESS LINE 1</p>
            <div className="add-branch-input"><InputField type="text" name="address1" value={area.address1} placeholder="Address Line 1" /></div>
          </div>
          <div className="add-branch-modal-sec">
            <p>ADDRESS LINE 2</p>
            <div className="add-branch-input"><InputField placeholder="Address Line 2" name="address2" value={area.address2} /></div>
          </div>
          <div className="add-branch-modal-sec">
            <p>STATE</p>
            <div className="add-branch-input"><InputField placeholder="Select State" value={area.state} /></div>
          </div>
          <div className="add-branch-modal-sec">
            <p>CITY</p>
            <div className="add-branch-input"><InputField placeholder="City" value={area.city} /></div>
          </div>
          <div className="add-branch-modal-sec">
            <p>PINCODE</p>
            <div className="add-branch-input"> <InputField type="number" value={area.pincode} name="pincode" onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
            }} /></div>
          </div>
          <div className="add-branch-modal-sec">
            <p>CONTACT NUMBER</p>
            <div className="add-branch-input"><InputField type="number" placeholder="Apointment contact Number" name="mobile" value={area.mobile} onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
            }} /></div>
          </div>

        </div>

      )) : ""}

      {
        visible && <Modal
          title="NEW BRANCH"
          centered
          visible={visible}
          className='addbranch-response'
          onOk={() => editVisible(false)}
          onCancel={modalClose}
          width={1000}
        // style={{marginTop:'85px'}}
        >
          <div>
            <div className="add-branch-modal-sec">
              <p>FACILITY NAME</p>
              <div className="add-branch-input"><InputField type="text" name="name" value={name} placeholder="Facility name" onChange={handleChange} maxLength={35} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>TIME SLOTS FOR APPOINTMENTS</p>
              <div style={{ width: "70%" }}>
                {displayTiming.map((item, i) => <>
                  <div className="add-branch-profile-slot">

                    <div className="profile-day-check">
                      {weeksData.map((day) => (
                        <Checkbox disabled={days.find(({ selectedDay, selectedIndex }) => {
                          if (selectedDay === day.id && selectedIndex === i) {
                            return false
                          } else {
                            if (selectedDay === day.id) {
                              return true
                            } else return false
                          }
                        })} className="check-width" onChange={(e) => onDispalyChange(e, i)} name={day.name} value={day.id}>{day.name}</Checkbox>
                      ))}
                    </div>
                    <div className="modal-slot-icons">
                      <img src={plusIcon} alt="plus" className="profile-modal-plus" onClick={addSlotMethod} />
                      <img src={deleteIcon} alt="delete" className="medicine-table-delete" onClick={() => removeSlotAdd(i)} />

                    </div>

                  </div>
                  <div className='profile-time-main-sec'>

                    <div className='profile-mornig-sec'>
                      <h4 className='morning-p'>Morning</h4>
                      <div style={{ display: "flex", width: '100%' }}>
                        <div className='profile-mornig-sec'>
                          <h4>Start Time</h4>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => startTimeOnChange(time, timeString, i)} placeholder="" defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />

                        </div>
                        <div className='profile-mornig-sec'>
                          <h4>End Time</h4>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => endTimeOnChange(time, timeString, i)} placeholder="" defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
                        </div>

                      </div>
                    </div>
                    <div className='profile-evng-sec'>
                      <h4 className='morning-p'>Evening</h4>
                      <div style={{ display: "flex", width: "100%" }}>
                        <div className='profile-mornig-sec'>
                          <h4>Start Time</h4>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => startTimeEvngOnChange(time, timeString, i)} placeholder="" defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />

                        </div>
                        <div className='profile-mornig-sec'>
                          <h4>End Time</h4>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => endTimeEvngOnChange(time, timeString, i)} placeholder="" defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
                        </div>

                      </div>
                    </div>
                  </div>
                </>)}

              </div>

            </div>
            <div className="add-branch-modal-sec">
              <p>SLOT DURATION</p>
              <div className="add-branch-input"> <SingleSelect data={slotList} value={noOfSlots} onChange={handleNoOfSlots} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>ADDRESS LINE 1</p>
              <div className="add-branch-input"><InputField type="text" name="address1" value={address1} placeholder="Address Line 1" onChange={handleChange} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>ADDRESS LINE 2</p>
              <div className="add-branch-input"><InputField placeholder="Address Line 2" name="address2" value={address2} onChange={handleChange} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>STATE</p>
              <div className="add-branch-input"><SingleSelect placeholder="Select State" data={statesList} onChange={handleStateChange} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>CITY</p>
              <div className="add-branch-input"><SingleSelect placeholder="City" value={finalCity} onChange={handleCityChange} data={isCity} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>PINCODE</p>
              <div className="add-branch-input"> <InputField type="number" value={pincode} name="pincode" onChange={handleChange} onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
              }} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>CONTACT NUMBER</p>
              <div className="add-branch-input"><InputField type="number" placeholder="Appointment Contact Number" name="mobile" value={mobile} onChange={handleChange} onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
              }} /></div>
            </div>


            <div className="add-branch-modal-sec">
              <p></p>
              <div className="add-branch-modal-btn">
                <Button onClick={handleAddBranch}>SAVE</Button>
                <Button className={"add-branch-modal-cancel-btn"} onClick={modalClose}>CANCEL</Button>
              </div>
            </div>
          </div>
        </Modal>
      }

      {/* edit branch */}
      {
        editVisibleToggle && <Modal
          title="EDIT BRANCH"
          centered
          visible={editVisibleToggle}
          onOk={() => dispatch(editVisible(false))}
          onCancel={editModalClose}
          width={1000}
        >
          <div>
            <div className="add-branch-modal-sec">
              <p>FACILITY NAME</p>
              <div className="add-branch-input"><InputField type="text" name="name" value={seletedBranch?.name} placeholder="Facility name" onChange={handleEditChange} maxLength={35} /></div>
            </div>

            <div className="add-branch-modal-sec">
              <p>TIME SLOTS FOR APPOINTMENTS</p>
              <div style={{ width: "70%" }}>
                {seletedBranch?.displayTiming?.map((item, i) => <><div className="add-branch-profile-slot">
                  <div className="profile-day-check">
                    {weeksData.map((day) => (
                      <Checkbox className="check-width" disabled={editDays.find(({ selectedDay, selectedIndex }) => {
                        if (selectedDay === day.id && selectedIndex === i) {
                          return false
                        } else {
                          if (selectedDay === day.id) {
                            return true
                          } else return false
                        }
                      })} checked={item.days.filter(e => e == day.id).length > 0} onChange={(e) => weekEditChange(e, i)} name={day.name} value={day.id}>{day.name}</Checkbox>
                    ))}
                  </div>
                  <div className="modal-slot-icons">
                    <img src={plusIcon} alt="plus" className="profile-modal-plus" onClick={addEditSlotMethod} />
                    <img src={deleteIcon} alt="delete" className="medicine-table-delete" onClick={() => removeEditSlot(i)} />
                  </div>
                </div>
                  <div className='profile-time-main-sec'>

                    <div className='profile-mornig-sec'>
                      <h4 className='morning-p'>Morning</h4>
                      <div style={{ display: "flex", width: '100%' }}>
                        <div className='profile-mornig-sec'>
                          <h4>Start Time</h4>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => startTimeEditOnChange(time, timeString, i)} placeholder="" defaultValue={item?.slots?.[0]?.start ? moment(item.slots[0].start, 'HH:mm') : moment("00:00:00", 'HH:mm')} />

                        </div>
                        <div className='profile-mornig-sec'>
                          <h4>End Time</h4>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => endTimeEditOnChange(time, timeString, i)} placeholder="" defaultValue={item?.slots?.[0]?.end ? moment(item.slots[0].end, 'HH:mm') : moment("00:00:00", 'HH:mm')} />
                        </div>

                      </div>
                    </div>
                    <div className='profile-evng-sec'>
                      <h4 className='morning-p'>Evening</h4>
                      <div style={{ display: "flex", width: "100%" }}>
                        <div className='profile-mornig-sec'>
                          <h4>Start Time</h4>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => startTimeEvngEditOnChange(time, timeString, i)} placeholder="" defaultValue={item?.slots?.[1]?.start ? moment(item.slots[1].start, 'HH:mm') : moment("00:00:00", 'HH:mm')} />

                        </div>
                        <div className='profile-mornig-sec'>
                          <h4>End Time</h4>
                          <TimePicker className="add-start-time" onChange={(time, timeString) => endTimeEvngEditOnChange(time, timeString, i)} placeholder="" defaultValue={item?.slots?.[1]?.end ? moment(item.slots[1].end, 'HH:mm') : moment("00:00:00", 'HH:mm')} />
                        </div>

                      </div>
                    </div>
                  </div>

                </>)}

              </div>

            </div>
            <div className="add-branch-modal-sec">
              <p>SLOT DURATION</p>
              <div className="add-branch-input"> <SingleSelect data={slotList} value={seletedBranch?.slotDuration} onChange={handleEditSlotDuration} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>ADDRESS LINE 1</p>
              <div className="add-branch-input"><InputField type="text" name="address1" value={seletedBranch?.address1} placeholder="Address Line 1" onChange={handleEditChange} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>ADDRESS LINE 2</p>
              <div className="add-branch-input"><InputField placeholder="Address Line 2" name="address2" value={seletedBranch?.address2} onChange={handleEditChange} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>STATE</p>
              <div className="add-branch-input"><SingleSelect placeholder="Select State" name="state" value={seletedBranch?.state} data={statesList} onChange={handleEditState} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>CITY</p>
              <div className="add-branch-input"><SingleSelect placeholder="City" value={seletedBranch?.city} onChange={handleEditCity} data={editcityList} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>PINCODE</p>
              <div className="add-branch-input"> <InputField type="number" value={seletedBranch?.pincode} name="pincode" onChange={handleEditChange} onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
              }} /></div>
            </div>
            <div className="add-branch-modal-sec">
              <p>CONTACT NUMBER</p>
              <div className="add-branch-input"><InputField type="number" placeholder="Appointment Contact Number" name="mobile" value={seletedBranch?.mobile} onChange={handleEditChange} onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
              }} /></div>
            </div>

            <div className="add-branch-modal-sec">
              <p></p>
              <div className="add-branch-modal-btn">
                <Button onClick={handleEditSubmit}>SAVE</Button>
                <Button className={"add-branch-modal-cancel-btn"} onClick={editModalClose}>CANCEL</Button>
              </div>
            </div>
          </div>
        </Modal>
      }

    </div >
  )
}

export default VisitingAreas