import React from 'react'
import './BloodUrea.css'
import Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'


 const BloodUrea = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'BLOOD UREA & S. CREATININE')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'BLOOD UREA & S. CREATININE'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index]  = filterData[0]
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    }
   
  return (
    <div>
        <div className='burea-main'>
            <div className='burea-container'>
                <div className='burea-grid-container'>
                    <div className='padd burea-1'>BIO CHEMICAL TEST</div>
                    <div className='padd burea-2'>BLOOD UREA</div>
                    <Input className='aptinputs burea-3' type="number" name="BLOOD UREA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active}  onChange={onInputChange} />
                    <div className='padd burea-4'>mg/dl</div>
                    <div className='padd burea-5'>13-45</div>
                    <div className='padd burea-6'></div>
                    <span className='bil-border1'></span>
                    <div className='padd burea-7'>BIO CHEMICAL TEST</div>
                    <div className='padd burea-8'>S. CREATININE</div>
                    <div className='burea-9'><Input className='aptinputs ' type="number" name="S. CREATININE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}  onChange={onInputChange} /></div>
                    <div className='padd burea-10'>mg/dl</div>
                    <div className='padd burea-11'>0.5-1.5</div>
                    <div className='padd burea-12'></div>
                    <span className='bil-border2'></span>
                </div>
            </div>
            <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
        </div>
        <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
    </div>
  )
}
 export default BloodUrea