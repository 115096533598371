import React from 'react';
import { useSelector } from 'react-redux';
import '../PatientAppointments/PatientAppointments.css';
import { MyCalendar } from './MyCalendar';
import Search from './Search';

const PatientAppointments = () => {
  const create = useSelector(state => state.appointments.searchPatient.createToggleButton)
  console.log("dkjnjkdnjknd",create);
  const searchPatientInfo = useSelector(state => state.appointments.searchPatient.searchPatient)
  const searchAdvPatientData = useSelector(state => state.appointments.searchAdvPatient.searchAdvPatient)

  return (
    <div>
      <div className='appointments-heading'>
        <h5>APPOINTMENTS</h5>
      </div>
      <Search />
      {create === '' ? <MyCalendar /> : ""}
    </div>

  )
}

export default PatientAppointments