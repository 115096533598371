import React, { useEffect } from "react";
import { Tabs } from "antd";
import AbdomenAndKub from "./UsgAbdomen/abdomenAndKub";
import "./DiagnosticTab.css";
import { useDispatch, useSelector } from "react-redux";
import Cbc from "./Cbc";
import BloodSugar from "./BloodSugar";
import IvfSemen from "./IvfSemen";
import DietPlan from "./DietPlan";
import { BloodGroup } from "./BloodGroup/BloodGroup";
import { EmbryologyReport } from "./EmbryologyReport/embryologyReport";
import { BariumMeal } from "./BariumMeal/BariumMeal";
import { DnaFragmentation } from "./DnaFragmentation/DnaFragmentation";
import { BilirubinDirectIndirect } from "./BilirubinDirectIndirect/BilirubinDirectIndirect";
import { AmylaseLipase } from "./AmylaseLipase/AmylaseLipase";
import { Abg } from "./Abg/Abg";
import { Aptt } from "./Aptt/Aptt";
import { Aec } from "./Aec/Aec";
import { IuiReport } from "./IuiReport/IuiReport";
import { PatientProfile } from "./PatientProfile";
import { VitalityTestReport } from "./VitalityTestReport/VitalityTestReport";
import EmbryologyAdv from "./EmbryologyAdv/EmbryologyAdv";
import { Dengue } from "./Dengue";
import { Electrolytes } from "./Electrolytes";
import { Entrochek } from "./Entrochek";
import { Fsh } from "./Fsh";
import { Gbp } from "./Gbp";
import { Gtt } from "./Gtt";
import { Haemogram } from "./Haemogram";
import { Hbsag } from "./Hbsag";
import { Hb } from "./Hb/Hb";
import { Hiv } from "./Hiv/Hiv";
import { LipidProfile } from "./LipidProfile/LipidProfile";
import { Iron } from "./Iron/Iron";
import { Pct } from "./PCT/Pct";
import { Phosphorus } from "./Phosporos/Phosphorus";
import { Platelet } from "./Platelet count/Platelet";
import { ProLactin } from "./ProLactin/ProLactin";
import { Reticulocyte } from "./Reticulocyte/Reticulocyte";
import { Rft } from "./RFT/Rft";
import { SemenAnylasis } from "./SemenAnylasis/SemenAnylasis";
import { SerumBilirubin } from "./SerumBilirubin/SerumBilirubin";
import { SerumCalcium } from "./SerumCalcium/SerumCalcium";
import { Sputum } from "./SputumAFB/Sputum";
import { StoolTest } from "./StoolTest/StoolTest";
import { BariumEnema } from "./BariumEnema/BariumEnema";
import { BariumSwallow } from "./BariumSwallow/BariumSwallow";
import { Upt } from "./Upt/Upt";
import { UricAcid } from "./UricAcid/UricAcid";
import { UrineRoutine } from "./UrineRoutine/UrineRoutine";
import { Vdrl } from "./Vdrl/Vdrl";
import { VitaminD } from "./VitaminD/VitaminD";
import { WidalTest } from "./WidalTest/WidalTest";
import { FormF } from "./FormF/FormF";
import { XrayBariumMeal } from "./XrayBariumMeal/XrayBariumMeal";
import { XrayCervical } from "./XrayCervical/XrayCervical";
import { XrayChest } from "./XrayChestPA/XrayChest";
import { XrayDorsalSpine } from "./XrayDorsalSpine/XrayDorsalSpine";
import { XrayErect } from "./XrayErectAbdomen/XrayErect";
import { XrayMastoid } from "./XrayMastoid/XrayMastoid";
import { XraySkullAp } from "./XraySkullAp/XraySkullAp";
import { Enzymes } from "./Enzymes/Enzymes";
import { DengueKit } from "./DengueKit/DengueKit";
import { ElectrolytesHari } from "./ElectrolytesHari/ElectrolytesHari";
import { GbpTest } from "./GbpTest/GbpTest";
import { ClinicalBloodChemestry } from "./ClinicalBioChemestry/ClinicalBloodChemestry";
import { Haemotology } from "./Haemotology/Haemotology";
import { LipidProfileHari } from "./LipidProfileHari/LipidProfileHari";
import BloodUrea from "./BloodUrea/BloodUrea";
import Chikungunya from "./Chikungunya/Chikungunya";
import Bsf from "./Bsf/Bsf";
import Bsr from "./Bsr/Bsr";
import BsfBspp from "./BsfBspp/BsfBspp";
import CpkMb from "./CpkMb/CpkMb";
import Crp from "./Crp/Crp";
import Csf from "./Csf/Csf";
import Lh from "./LH/LH";
import Lft from "./LFT/LFT";
import Hcv from "./Hcv/Hcv";
import Hba from "./Hba/Hba";
import { MT } from "./MT/MT";
import { MalariaParasite } from "./MalariaParasite/MalariaParasite";
import { ObstetricUsgTest11 } from "./ObstetricUsgTest11/ObstetricUsgTest11";
import { MpAntigen } from "./MpAntigen/MpAntigen";
import { Typhi } from "./Typhi/Typhi";
import ThyroidProfile from "./ThyroidProfile/ThyroidProfile";
import TotalProtein from "./TotalProtein/TotalProtein";
import Troponin from "./Troponin/Troponin";
import TumarMarker from "./TumarMarker/TumarMarker";
import Thyroid from "./Thyroid/Thyroid";
import AbdomenAndPelvic from "./AbdomenAndPelvic/AbdomenAndPelvic";
import Cranial from "./Cranial/Cranial";
import UsgPelvic from "./UsgPelvic/UsgPelvic";
import Paranasal from "./Paranasal/Paranasal";
import IntraVenous from "./IntraVenous/IntraVenous";
import Mcu from "./Mcu/Mcu";
import ObstetricUsg from "./ObstetricUsg/ObstetricUsg";
import DoNotUseWhy from "./DoNotUseWhy/DoNotUseWhy";
import DoNotUse from "./DoNotUse/DoNotUse";
import DoNotUseTwo from "./DoNotUseTwo/DoNotUseTwo";
import StoolExamination from "./StoolExamination/StoolExamination";
import UrineAnalysis from "./UrineAnalysis/UrineAnalysis";
import LiverFunctionTest from "./LiverFunctionTest/LiverFunctionTest";
import HariSemenAnalysis from "./HariSemenAnalysis/HariSemenAnalysis";
import Immunology from "./Immunology/Immunology";
import HariWidalTest from "./HariWidalTest/HariWidalTest";
import {reportName,singleReportData,indexValue} from "../../../redux/actions/Diagnostics/diagnostics";
import { imageUrl } from "../../../redux/actions/Diagnostics/diagnostics";
import { getPatietClick,toggleButton } from "../../../redux/actions/Diagnostics/diagnostics";
const { TabPane } = Tabs;


const DiagnosticTab = () => {
  const patientClick = useSelector((state) => state?.diagnosticsInvoiceSummary);
  const {getdiagnostic: getDiagnosticDatas} = useSelector((state) => state?.diagnosticsInvoiceSummary);
  console.log("getDiagnosticDatas", getDiagnosticDatas);
  const ReportNamee = useSelector((state) => state?.diagnosticsInvoiceSummary?.reportNames);
  console.log("patientClick",patientClick);

  const dispatch = useDispatch();
  
   const onTabHandle = (e) => {
     dispatch(toggleButton(false));
     dispatch(getPatietClick(true));
    console.log("singleReportName", e);
    dispatch(imageUrl([]));
    dispatch(reportName(e));
    let reportOfSingle = getDiagnosticDatas?.testReport?.length === 0 ? "" : getDiagnosticDatas?.testReport?.filter((item) => item?.test === e);
    console.log("iouheoihoeihe", reportOfSingle);
    dispatch(singleReportData(reportOfSingle));
    let indexOfReport =getDiagnosticDatas?.testReport?.length === 0 ? "" : getDiagnosticDatas?.testReport?.findIndex((item) => item?.test === e) ;
    dispatch(indexValue(indexOfReport));
  };

  return (
    <div className="card-container">
      <Tabs onChange={onTabHandle} type="card" activeKey={ReportNamee}>
        {getDiagnosticDatas && getDiagnosticDatas?.invoice?.map((item, index) => (
          <TabPane tab={item?.test} key={item?.test}>
            {item?.test === "DNA FRAGMENTATION TEST" ? (<DnaFragmentation />) : ("")}
            {item?.test === "EMBRYOLOGY REPORT" ? <EmbryologyReport /> : ""}
            {item?.test === "EMBRYOLOGY REPORT ADV" ? <EmbryologyAdv /> : ""}
            {item?.test === "CBC" ? <Cbc /> : ""}
            {item?.test === "BLOOD SUGAR & URINE SUGAR/KETONES" ? (<BloodSugar />) : ("")}
            {item?.test ==="ULTRASONOGRAPHIC FINDINGS OF WHOLE ABDOMEN & KUB REGION" ? (<AbdomenAndKub />) : ("")}
            {item?.test === "IVF SEMEN ANALYSIS" ? <IvfSemen /> : ""}
            {item?.test === "DIET PLAN" ? <DietPlan /> : ""}
            {item?.test === "BLOOD GROUP & RH" ? <BloodGroup /> : ""}
            {item?.test === "BARIUM MEAL FOR STOMACH DUODENUM" ? ( <BariumMeal />) : ("")}
            {item?.test === "BILIRUBIN DIRECT & INDIRECT" ? (<BilirubinDirectIndirect /> ) : ("")}
            {item?.test === "APTT" ? <Aptt /> : ""}
            {item?.test === "AMYLASE LIPASE" ? <AmylaseLipase /> : ""}
            {item?.test === "ABG" ? <Abg /> : ""}
            {item?.test === "AEC" ? <Aec /> : ""}
            {item?.test === "IUI REPORT" ? <IuiReport /> : ""}
            {item?.test === "PATIENT PROFILE" ? <PatientProfile /> : ""}
            {item?.test === "VITALITY TEST REPORT" ? (<VitalityTestReport />) : ("")}
            {item?.test === "BLOOD UREA & S. CREATININE" ? <BloodUrea /> : ""}
            {item?.test === "CHIKUNGUNYA-IgM" ? <Chikungunya /> : ""}
            {item?.test === "BSF" ? <Bsf /> : ""}
            {item?.test === "BSR" ? <Bsr /> : ""}
            {item?.test === "BSF BSPP" ? <BsfBspp /> : ""}
            {item?.test === "CPK-MB" ? <CpkMb /> : ""}
            {item?.test === "CRP" ? <Crp /> : ""}
            {item?.test === "CSF (CEREBRO SPINAL FLUID)" ? <Csf /> : ""}
            {item?.test === "CSF (CEREBRO SPINAL FLUID)" ? <Csf /> : ""}
            {item?.test === "ELECTROLYTES" ? <Electrolytes /> : ""}
            {item?.test === "ENTROCHEK" ? <Entrochek /> : ""}
            {item?.test === "DENGUE IgG-IgM" ? <Dengue /> : ""}
            {item?.test === "FSH" ? <Fsh /> : ""}
            {item?.test === "GBP" ? <Gbp /> : ""}
            {item?.test === "GTT" ? <Gtt /> : ""}
            {item?.test === "HAEMOGRAM" ? <Haemogram /> : ""}
            {item?.test === "HBsAg" ? <Hbsag /> : ""}
            {item?.test === "LH" ? <Lh /> : ""}
            {item?.test === "LFT" ? <Lft /> : ""}
            {item?.test === "HIV & HbSAG & HCV" ? <Hcv /> : ""}
            {item?.test === "HbA1c" ? <Hba /> : ""}
            {item?.test === "MT (MONTOUX INTRADERMAL TUBERCULIN TEST)" ? (<MT />) : ("")}
            {item?.test === "MALARIA PARASITE" ? <MalariaParasite /> : ""}
            {item?.test === "OBSTETRIC ULTRASONOGRAPHY 11test" ? (<ObstetricUsgTest11 />) : ("")}
            {item?.test === "MP ANTIGEN" ? <MpAntigen /> : ""}
            {item?.test === "TYPHI-DOT" ? <Typhi /> : ""}
            {item?.test === "TUMOR MARKER" ? <TumarMarker /> : ""}
            {item?.test === "TOTAL PROTEIN & SERUM ALBUMIN" ? (<TotalProtein />) : ("")}
            {item?.test === "TROPONIN I PLUS" ? <Troponin /> : ""}
            {item?.test === "THYROID PROFILE" ? <ThyroidProfile /> : ""}
            {item?.test === "THYROID" ? <Thyroid /> : ""}
            {item?.test === "OBSTETRIC ULTRASONOGRAPHY" ? <ObstetricUsg /> : ""}
            {item?.test === "PARANASAL SINOUS P.A. VIEW" ? <Paranasal /> : ""}
            {item?.test === "INTRA-VENOUS PYLOGRAPHY" ? <IntraVenous /> : ""}
            {item?.test === "WIDAL TEST" ? <WidalTest /> : ""}
            {item?.test === "M.C.U." ? <Mcu /> : ""}
            {item?.test === "ULTRASONOGRAPHIC FINDINGS OF CRANIAL" ? (<Cranial />) : ("")}
            {item?.test === "ULTRASONOGRAPHIC FINDINGS OF PELVIC ORGANS" ? (<UsgPelvic />) : ("")}
            {item?.test ==="ULTRASONOGRAPHIC FINDINGS OF WHOLE ABDOMEN & PELVIC ORGANS" ? (<AbdomenAndPelvic />) : ("")}
            {item?.test === "DO NOT USE" ? <DoNotUse /> : ""}
            {item?.test === "DO NOT USE TWO" ? <DoNotUseTwo /> : ""}
            {item?.test === "DO NOT USE WHY" ? <DoNotUseWhy /> : ""}
            {item?.test === "Hb" ? <Hb /> : ""}
            {item?.test === "HIV" ? <Hiv /> : ""}
            {item?.test === "LIPID PROFILE" ? <LipidProfile /> : ""}
            {item?.test === "IRON" ? <Iron /> : ""}
            {item?.test === "PCT" ? <Pct /> : ""}
            {item?.test === "PHOSPHORUS" ? <Phosphorus /> : ""}
            {item?.test === "PLATELET COUNT" ? <Platelet /> : ""}
            {item?.test === "PROLACTIN" ? <ProLactin /> : ""}
            {item?.test === "RETICULOCYTE COUNT" ? <Reticulocyte /> : ""}
            {item?.test === "RFT" ? <Rft /> : ""}
            {item?.test === "SEMEN ANALYSIS" ? <SemenAnylasis /> : ""}
            {item?.test === "SERUM BILIRUBIN" ? <SerumBilirubin /> : ""}
            {item?.test === "SERUM CALCIUM" ? <SerumCalcium /> : ""}
            {item?.test === "SPUTUM AFB" ? <Sputum /> : ""}
            {item?.test === "STOOL TEST FOR ROUTINE & MICROSCOPY" ? (<StoolTest />) : ("")}
            {item?.test === "BARIUM ENEMA" ? <BariumEnema /> : ""}
            {item?.test === "BARIUM SWALLOW" ? <BariumSwallow /> : ""}
            {item?.test === "UPT" ? <Upt /> : ""}
            {item?.test === "URIC ACID" ? <UricAcid /> : ""}
            {item?.test === "URINE ROUTINE" ? <UrineRoutine /> : ""}
            {item?.test === "VDRL" ? <Vdrl /> : ""}
            {item?.test === "VITAMIN D" ? <VitaminD /> : ""}
            {item?.test === "FORM F" ? <FormF /> : ""}
            {item?.test === "X-RAY BARIUM MEAL FOLLOW THROUGH" ? (<XrayBariumMeal /> ) : ("")}
            {item?.test === "X-RAY CERVICAL SPINE AP/LAT" ? (<XrayCervical />) : ("")}
            {item?.test === "X-RAY CHEST P.A. VIEW" ? <XrayChest /> : ""}
            {item?.test === "X-RAY DORSAL SPINE AP/LAT" ? ( <XrayDorsalSpine /> ) : ( "" )}
            {item?.test === "X-RAY ERECT ABDOMEN" ? <XrayErect /> : ""}
            {item?.test === "X-RAY MASTOID" ? <XrayMastoid /> : ""}
            {item?.test === "X-RAY SKULL AP/LATERAL VIEW" ? (<XraySkullAp />) : ("")}
            {item?.test === "ENZYMES" ? <Enzymes /> : ""}
            {item?.test === "Hari DENGUE KIT" ? <DengueKit /> : ""}
            {item?.test === "Hari ELECTROLYTES" ? <ElectrolytesHari /> : ""}
            {item?.test === "GBP TEST" ? <GbpTest /> : ""}
            {item?.test === "CLINICAL BLOOD BIOCHEMISTRY" ? (<ClinicalBloodChemestry />) : ("")}
            {item?.test === "HAEMATOLOGY" ? <Haemotology /> : ""}
            {item?.test === "Hari LIPID PROFILE" ? <LipidProfileHari /> : ""}
            {item?.test === "STOOL EXAMINATION" ? <StoolExamination /> : ""}
            {item?.test === "URINE ANALYSIS" ? <UrineAnalysis /> : ""}
            {item?.test === "LIVER FUNCTION TEST" ? <LiverFunctionTest /> : ""}
            {item?.test === "Hari SEMEN ANALYSIS" ? <HariSemenAnalysis /> : ""}
            {item?.test === "Hari WIDAL TEST" ? <HariWidalTest /> : ""}
            {item?.test === "IMMUNOLOGY" ? <Immunology /> : ""}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default DiagnosticTab;
