import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import './HariSemenAnalysis.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function HariSemenAnalysis() {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'Hari SEMEN ANALYSIS')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'Hari SEMEN ANALYSIS'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
        <div className='hariSemenAnalysis-parent'>
          <div className='hariSemenAnalysis-left'>
            <div className='hariSemenAnalysis-grid'>

            <div className='hariSemenAnalysis-1 hariSemenAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='hariSemenAnalysis-2 hariSemenAnalysis-pad'>VOLUME</div>
                <div className='hariSemenAnalysis-3'><Input type='number' className={'hariSemenAnalysis-input'} name="VOLUME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-4 hariSemenAnalysis-pad'>ml</div>
                <div className='hariSemenAnalysis-5 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-1'></span>

                <div className='hariSemenAnalysis-6 hariSemenAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='hariSemenAnalysis-7 hariSemenAnalysis-pad'>COLOUR & APPEARANCE</div>
                <div className='hariSemenAnalysis-8'><Input type='number' className={'hariSemenAnalysis-input'} name="COLOUR & APPEARANCE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-9 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-10 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-2'></span>

                <div className='hariSemenAnalysis-11 hariSemenAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='hariSemenAnalysis-12 hariSemenAnalysis-pad'>VISCOCITY</div>
                <div className='hariSemenAnalysis-13'><Input type='number' className={'hariSemenAnalysis-input'} name="VISCOCITY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-14 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-15 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-3'></span>

                <div className='hariSemenAnalysis-16 hariSemenAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='hariSemenAnalysis-17 hariSemenAnalysis-pad'>ODOUR</div>
                <div className='hariSemenAnalysis-18'><Input type='number' className={'hariSemenAnalysis-input'} name="ODOUR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-19 hariSemenAnalysis-pad'>millions/ml</div>
                <div className='hariSemenAnalysis-20 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-4'></span>

                <div className='hariSemenAnalysis-21 hariSemenAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='hariSemenAnalysis-22 hariSemenAnalysis-pad'>COAGULATION TIME</div>
                <div className='hariSemenAnalysis-23'><Input type='number' className={'hariSemenAnalysis-input'} name="COAGULATION TIME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-24 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-25 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-5'></span>

                <div className='hariSemenAnalysis-26 hariSemenAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='hariSemenAnalysis-27 hariSemenAnalysis-pad'>LIQUEFACTION TIME</div>
                <div className='hariSemenAnalysis-28'><Input type='number' className={'hariSemenAnalysis-input'} name="LIQUEFACTION TIME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-29 hariSemenAnalysis-pad'>%</div>
                <div className='hariSemenAnalysis-30 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-6'></span>

                <div className='hariSemenAnalysis-31 hariSemenAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='hariSemenAnalysis-32 hariSemenAnalysis-pad'>pH</div>
                <div className='hariSemenAnalysis-33'><Input type='number' className={'hariSemenAnalysis-input'} name="pH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-34 hariSemenAnalysis-pad'>%</div>
                <div className='hariSemenAnalysis-35 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-7'></span>

                <div className='hariSemenAnalysis-36 hariSemenAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='hariSemenAnalysis-37 hariSemenAnalysis-pad'>SEMEN FRUCTOSE</div>
                <div className='hariSemenAnalysis-38'><Input type='number' className={'hariSemenAnalysis-input'} name="SEMEN FRUCTOSE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-39 hariSemenAnalysis-pad'>%</div>
                <div className='hariSemenAnalysis-40 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-8'></span>

                <div className='hariSemenAnalysis-41 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-42 hariSemenAnalysis-pad'>TOTAL SPERM COUNT</div>
                <div className='hariSemenAnalysis-43'><Input type='number' className={'hariSemenAnalysis-input'} name="TOTAL SPERM COUNT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
                <div className='hariSemenAnalysis-44 hariSemenAnalysis-pad'> </div>
                <div className='hariSemenAnalysis-45 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-9'></span>

                <div className='hariSemenAnalysis-46 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-47 hariSemenAnalysis-pad'>MOTILITY</div>
                <div className='hariSemenAnalysis-48'><Input type='number' className={'hariSemenAnalysis-input'} name="MOTILITY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-49 hariSemenAnalysis-pad'>%</div>
                <div className='hariSemenAnalysis-50 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-10'></span>

                <div className='hariSemenAnalysis-51 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-52 hariSemenAnalysis-pad'>ACTIVE MOTILE</div>
                <div className='hariSemenAnalysis-53'><Input type='number' className={'hariSemenAnalysis-input'} name="ACTIVE MOTILE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-54 hariSemenAnalysis-pad'>%</div>
                <div className='hariSemenAnalysis-55 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-11'></span>


                <div className='hariSemenAnalysis-56 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-57 hariSemenAnalysis-pad'>SLUGGISHLY MOTILE</div>
                <div className='hariSemenAnalysis-58'><Input type='number' className={'hariSemenAnalysis-input'} name="SLUGGISHLY MOTILE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-59 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-60 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-12'></span>

                <div className='hariSemenAnalysis-61 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-62 hariSemenAnalysis-pad'>NON MOTILE</div>
                <div className='hariSemenAnalysis-63'><Input type='number' className={'hariSemenAnalysis-input'}name="NON MOTILE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
                <div className='hariSemenAnalysis-64 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-65 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-13'></span>

                <div className='hariSemenAnalysis-66 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-67 hariSemenAnalysis-pad'>MORPHOLOGY</div>
                <div className='hariSemenAnalysis-68'><Input type='number' className={'hariSemenAnalysis-input'} name="MORPHOLOGY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-69 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-70 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-14'></span>

                <div className='hariSemenAnalysis-71 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-72 hariSemenAnalysis-pad'>NORMAL</div>
                <div className='hariSemenAnalysis-73'><Input type='number' className={'hariSemenAnalysis-input'} name="NORMAL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-74 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-75 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-15'></span>
                
                <div className='hariSemenAnalysis-76 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-77 hariSemenAnalysis-pad'>ABNORMAL</div>
                <div className='hariSemenAnalysis-78'><Input type='number' className={'hariSemenAnalysis-input'} name="ABNORMAL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[15]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-79 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-80 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-16'></span>

                <div className='hariSemenAnalysis-81 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-82 hariSemenAnalysis-pad'>PUS CELLS</div>
                <div className='hariSemenAnalysis-83'><Input type='number' className={'hariSemenAnalysis-input'} name="PUS CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[16]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-84 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-85 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-17'></span>

                <div className='hariSemenAnalysis-86 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-87 hariSemenAnalysis-pad'>ERYTHROCYTES</div>
                <div className='hariSemenAnalysis-88'><Input type='number' className={'hariSemenAnalysis-input'} name="ERYTHROCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[17]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-89 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-90 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-18'></span>

                <div className='hariSemenAnalysis-91 hariSemenAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='hariSemenAnalysis-92 hariSemenAnalysis-pad'>EPITHELIAL CELLS</div>
                <div className='hariSemenAnalysis-93'><Input type='number' className={'hariSemenAnalysis-input'} name="EPITHELIAL CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[18]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='hariSemenAnalysis-94 hariSemenAnalysis-pad'></div>
                <div className='hariSemenAnalysis-95 hariSemenAnalysis-pad'></div>
                <span className='hariSemenAnalysis-border-19'></span>
                

            </div>
          </div>
          <div>
              <TestReportRightSection />
          </div>
        </div>
        <div className='hariSemenAnalysis-foot'>
            <ReportFooter />
        </div>
        <div className='hariSemenAnalysis-p'>
          <h4>Note:</h4>
          <p>These test have technical limitetion. Clinical Pathological interpretation is necessary <br/> in case of any discrepancy test may be repeated immediately. This Report is not for medicolegal purpose.</p>
          
        </div>
       
    </div>
  )
}

export default HariSemenAnalysis
