import * as types from '../../actionTypes';

const initialState = {
    editMasterList: [],
    error: {},
    loading:{}
}


export const masterListReducer = (state = initialState,action) => {
    switch(action.type) {
        case types.GET_MASTER_LIST_LOADING:
            return {
                ...state,
                loading:true
            }
        case types.GET_MASTER_LIST_SUCCESS:
            return {
                ...state,
                editMasterList:action.payload,
                loading:false
            }
        case types.GET_MASTER_LIST_FAIL:
            return {
                ...state,
                error:action.payload,
                loading:false,
                editMedicineList:[]
            }
        default:
            return state
    }
}