import React from 'react'
import './Calls.css'
import Call from '../../components/Calls/Calls'
function Calls() {
  return (
    <div className="main-section">
      <Call />
    </div>
  )
}

export default Calls