import React, { useEffect, useRef, useState } from 'react'
import './PatientHistory.css'
import Button from "../../../UIComponents/Button/Button";
import calculateGrayLogo from "../../../images/calculate-gray.png";
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea'
import { Modal } from "antd";
import ImageEditor from './ImageEditor';
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import visitNote from '../../../images/visit-note.png'
import saveLogo from "../../../images/save-icon.png"
import '../../Diagnostics/DiagnosticTab/TestReportRightSection.css'
import { useDispatch, useSelector } from 'react-redux';
import { deleteVaultImages, patientHistory } from '../../../redux/actions/opd/patienthistory'
import { updateVisit } from '../../../redux/actions/opd/patientopdinvoice';
import ViewHistory from './ViewHistory/ViewHistory';
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import Compressor from 'compressorjs';
import { toast } from '../../../UIComponents/Toast';





export const PatientHistory = () => {
  const rootEl = React.useRef(null);
  const tui = useRef();
  const dispatch = useDispatch()
  const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
  console.log("historyNote", visitInfo)
  const active = useSelector(state => state.opdrecord.patientreport.active)
  console.log("single visit status", active)
  const visitImage = useSelector(state => state.opdrecord.patientHistory.visitImage)
  console.log("visitImage", visitImage);


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeVisit, setActiveVisit] = useState(true)
  const [isModalTwoVisible, setIsModalTwoVisible] = useState(false);
  const [url, setUrl] = useState()
  const [loadedImage, setLoadedImage] = useState(true)


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const imageEditorOptions = {
    // sort of option properties.
  };

  const handleImageSaveClick = () => {
    setLoadedImage(true)
    console.log("On click save",rootEl.current);
    console.log(rootEl.current);
   
    const canvas = document.getElementsByClassName("lower-canvas")[0];
    const dataURL = canvas.toDataURL();


    let arr = canvas.toDataURL().split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let file = new File([u8arr], 'sample.jpg', { type: mime });

    new Compressor(file, {
      quality: 0.8,
      maxWidth: 400,
      maxHeight: 400,
      success(result) {
        console.log("result",result);
          const formData = new FormData();
          formData.append("files", result, result.name);
          formData.append('aptId', visitInfo?.aptId)
          formData.append('visitId', visitInfo?._id)
          dispatch(patientHistory(formData))
        },
      error(err) {
        console.log("ffffffffffffffff", err);
      },
    
    });
  
    setIsModalVisible(false);
  }


  const showImageModal = (key) => {
    setIsModalTwoVisible(true)
    const findUrl = visitImage?.images?.find((url) => url.key === key)
    console.log("dss", findUrl);
    setUrl(findUrl)
  };

  const handleImageCancel = () => {
    setIsModalTwoVisible(false)

  };


  const onActiveVisitClick = () => {
    setActiveVisit(prevState => !prevState)
  }


  const onNoteChange = (e) => {
    console.log("note", e.target.value);
    dispatch(updateVisit({ ...visitInfo, vaultVisitNote: e.target.value }))
  }


  const deleteVaultImage = (vaultfileName) => {
    dispatch(deleteVaultImages({ vaultfileName, visitId: visitInfo?._id }))
  }
  useEffect(() => {
    console.log('ref ===>', tui)
  }, [tui])

  const onChangeImageLoad = e => {
    const file = e?.target?.files[0];
    if (file) {
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        toast.error("Invalid image format")
        console.log('Invalid image format');
        // add your validations here
        setLoadedImage(true)
      } else {
        setLoadedImage(false)
      }
    }
  }


  return (
    <div>
      {active ? <div>
        <ViewHistory />
      </div>
        :
        <div>
          <div className='history-container'>
            <div className='history-active-btn'>
              <Button onClick={onActiveVisitClick}>Active Visit</Button>

              {activeVisit === true ? <DownOutlined onClick={onActiveVisitClick} /> : <RightOutlined onClick={onActiveVisitClick} />}
            </div>
            {activeVisit === true ?
              <div>
                <div className='add-image-btn-history'>
                  <Button onClick={showModal}><img width={20} src={calculateGrayLogo} alt="calculate-logo" />Add Image</Button>
                </div>
                {visitImage && visitImage?.images?.length > 0 ?
                  <div className='opd-image-sec'>
                    {visitImage?.images?.map(item => <div>
                      <p className='opd-image-del' onClick={() => deleteVaultImage(item.key)}>Delete</p>
                      <img onClick={() => showImageModal(item.key)} style={{ "width": "150px", "height": "80px" }} src={item.url} alt="upload images" />
                    </div>)}
                    <Modal
                      title="  "
                      visible={isModalTwoVisible}
                      onCancel={handleImageCancel}
                      width={650}
                      height={1230}>
                      <div>
                        <div>
                          <img style={{ "width": "600px", "height": "400px" }} src={url?.url} alt="uploaded images" />
                        </div>
                      </div>
                    </Modal>
                  </div>
                  : ""}
                <div className='history-below-sec'>
                  <div>
                    <p><img width={20} src={visitNote} alt='visit note' />Visit Note</p>
                  </div>
                  <div>
                    <TextAreaAnt onChange={onNoteChange} value={visitInfo?.vaultVisitNote} cols={100} placeholder="Note" />
                  </div>
                </div>

              </div> : ""}
            <Modal
              title="Image Processor"
              visible={isModalVisible}
              onCancel={handleCancel}
              width={1150}>
              <div>
                <p>Click load to select image file. Once edited click save to confirm edits. You can download image for your future reference as well</p>
                <div className='patient-history-save'>
                  <Button disabled={loadedImage ? true : false} onClick={handleImageSaveClick}><img width={20} src={saveLogo} alt="save-logo" />Save</Button> 
                </div>
                <div>
                  <div ref={rootEl} className='image-editor' />
                  <ImageEditor includeUI={
                    {
                      loadImage: {
                        path: 'data:image/jpeg',
                        name: 'SampleImage',
                      },
                      uiSize: {
                        width: "92%",
                        height: "700px"
                      },
                      menu: ["shape", "filter", "text", "rotate", 'crop', "flip"],
                      initMenu: '',
                      menuBarPosition: 'bottom',
                      cssMaxWidth: 700,
                      cssMaxHeight: 500,
                      selectionStyle: {
                        cornerSize: 20,
                        rotatingPointOffset: 70
                        }
                      }
                    }
                    onChange={onChangeImageLoad}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      }
    </div>
  )
}
