import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

export const getPatientToggle = (payload) => ({
  type: types.GET_PROFILE_TOGGLE,
  payload,
});
export const resetGetPatient = (payload) => ({
  type: types.RESET_GET_PATIENT,
  payload,
});
const getPatientLoading = () => ({
  type: types.GET_PATIENT_LOADING,
});

const getPatientSuccess = (getPatient) => ({
  type: types.GET_PATIENT_SUCCESS,
  payload: getPatient,
});

const getPatientError = (error) => ({
  type: types.GET_PATIENT_FAIL,
  payload: error,
});

// let url = baseUrl();
export const getPatient = (data) => {
  return (dispatch) => {
    dispatch(getPatientLoading());
    client
      .post(
        `/patient/get-patient`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getPatientSuccess(resp.data));
        dispatch(getPatientToggle(false));
        toast.success("Patient fetched Successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getPatientError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};
