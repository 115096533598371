import React from 'react'
import '../Iron/Iron.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import {updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useState } from 'react'

export const VitaminD = () => {
  const dispatch = useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

   const onChangeHandle = (e) => {
    const { value, name } = e.target;

    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'VITAMIN D')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'VITAMIN D'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
        <div className='iron-parent'>
            <div className='iron-left'>
                <div className='iron-grid'>
                    <div className='iron1 h-pad'></div>
                    <div className='iron2 h-pad'>VITAMIN-D</div>
                    <div className='iron3'><Input type='number' onChange={onChangeHandle} name='VITAMIN-D' className={'iron-input'} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active} /></div>
                    <div className='iron4 h-pad'> ng/ml</div>
                    <div className='iron5 h-pad'></div>
                    <div className='iron6 h-pad'></div>
                    <span className='iron-border'></span>
                </div>
            </div>
                <div>
                    <TestReportRightSection />
                </div>
        </div>
    
        <div className='iron-foot'>
            <ReportFooter />
        </div>
        <div className='irn-method'>
              <h4>Noramal Range:</h4>
              <ul>
                <li>&lt; 20.0 DEFICIENCY</li>
                <li>20.0-30.0 INSUDDICIENCY</li>
                <li>30.0-100.0 SUFFICIENCY</li>
                <li>&gt; 100.0 TOXICITY</li>
              </ul>
              <p>The test is performed using VIDAS (ELFA) methods.</p><br/>
              <p>Vitamin D is best known for its role in calcium and bone metabolism but emerging research indicates that low levels of Vitamin D may be associated with increased risk of some cancers, type 2 diabetes mellitus, multiple sclerosis, cardiovascular desease, rheumatoid arthritis, dipression, Alzheimer s, desease, infection preeclampsia, cesarian deliveries and neurocongnitive dysfunction. Vitamin D regulates the expression of a vast array of genes in tissues including immune cells,the vasculature,muscle and reproductive organs. Vitamin D insufficiancy is comman and dificiancy can have adverse health effects at any stage of life.</p>
         </div>
    </div>
  )
}
