import React, { useState, useEffect } from 'react'
import Button from "../../../UIComponents/Button/Button";
import Input from '../../../UIComponents/Input/Input';
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import mikeLogo from "../../../images/mike.png"
import saveLogo from "../../../images/save-icon.png"
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Switch } from 'antd';
import './TestReportRightSection.css'
import { toast } from '../../../UIComponents/Toast';
import { useSelector , useDispatch } from 'react-redux';
import { addBankDiagnostics } from '../../../redux/actions/Diagnostics/diagnostics';

export const TestReportRightSection = () => {
   const dispatch = useDispatch();
    const fieldNames = useSelector(state => state?.diagnosticsInvoiceSummary?.fieldNames)
    console.log("jhdgcjydcjy",fieldNames?.fieldKey);
    const doctorId = useSelector(state => state.profile.profileInfo?.id)
    console.log("fieldNames",fieldNames);
    const ReportNamee = useSelector((state) => state?.diagnosticsInvoiceSummary?.reportNames);
    console.log("ReportNamee", ReportNamee);
   
    const {
        transcript,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        toast("Browser doesn't support speech recognition.")
    }
    const [finalData, setFinalData] = useState(transcript)
    const [start, setStart] = useState(false)
    const [dictationKey, setDictatonKey] = useState('')

        const startButton = () => {
        if (!start) {
            resetTranscript()
            setStart(true)
            SpeechRecognition?.startListening({ language: 'en-IN', continuous: true })
        }
    }

    const stopSpeech = () => {
        setStart(false)
        SpeechRecognition?.stopListening()
    }

    const hangeleTextChange = (e) => {
        setFinalData(e.target.value)
    }
    
    useEffect(() => {
        setFinalData(transcript)
    }, [transcript])

    const onKeyHandle = (e) => {
        setDictatonKey(e.target.value)
    }
    const dictationData = {doctorId: doctorId,
        field: fieldNames, 
        key: dictationKey,
        test: ReportNamee,
        value: finalData
    }
    
    const saveAddBank = () => {
        dispatch(addBankDiagnostics(dictationData))
    }
    return (
        <div className='report-right-main'>
            <div className='report-btn-right-sec'>
                <div>{start === false ? <Button onClick={startButton}><img width={20} src={mikeLogo} alt="mic-logo"/>Dictation</Button> : <button onClick={stopSpeech} >Stop</button>}</div>
            </div>
            <div>
            <p>{fieldNames?.fieldKey ? fieldNames?.fieldKey : ''}</p>
            </div>
            <div className='test-report-input'>
                <TextAreaAnt style="max-width:100%;" value={finalData} placeholder="Description" onChange={hangeleTextChange} cols={25} rows={3} />
            </div>

            <div className='test-report-inputs'>
                <Input className='test-report-input' type="name" placeholder="Name" onChange={onKeyHandle} value={dictationKey} />
            </div>
            <div className='report-save-btn-right'>
                <Button onClick={saveAddBank}><img width={20} src={saveLogo} alt="save-logo"/>Save</Button>
            </div>
            <div>
                <SingleSelect
                    className={"tesinput"}
                    placeholder="Primary Sign"
                    width="100%"
                />
            </div>
            <div className='test-report-select'>
                <SingleSelect
                    placeholder="Secondary Sign"
                    width="100%"
                />
            </div>
            <div>
                <p>Head Hospital</p>
            </div>
            <div className='report-switch-right-sec'>
                <Switch size="default" />
            </div>
        </div>
    )
}
