import * as types from "../../actionTypes";
import qs from "qs";
import { toast } from "../../../UIComponents/Toast";
import { assistantDoc } from "./doctorSignUp";
import { baseUrl } from "../../../utils/Helper";
import { client } from "../../../utils/axios";

const userSignupLoading = () => ({
  type: types.USER_SIGN_UP_LOADING,
});

const userSignupSuccess = (data) => ({
  type: types.USER_SIGN_UP_SUCCESS,
  payload: data,
});

const userSignupFail = (error) => ({
  type: types.USER_SIGN_UP_FAIL,
  payload: error,
});

export const userSignUp = (data) => {
  return function (dispatch) {
    dispatch(userSignupLoading());
    client
      .post(
        `/user/signup`,
        qs.stringify(data)
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(userSignupSuccess(resp.data));
        console.log("Admin sign up data", resp.data);
        toast.success("User created successfully");
        dispatch(assistantDoc());
      })
      .catch((error) => {
        console.log(error);
        dispatch(userSignupFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
