import React from 'react'
import './Prescriptionanalysis.css'
import './PrescriptionDiagnosisType.css'
import { useSelector } from 'react-redux/es/exports'
const PrescriptionDiagnosisType = () => {

  const prescriptionAnalysisInfo = useSelector(state => state.analytics.prescriptionAnalysisInfo)


  return (
    <div className='pres-diagnosis-type-table'>
      {prescriptionAnalysisInfo?.diagnosis?.map(item => (
        <>
      <div className="diagnosis-type-section">
        <div className='diagnosis-section'>
       <span className='diagnosis-type-name'>{item.name}</span>
        <span className="pres-diagnosis-type-count">{item.value}</span>
        </div>
      </div>
     
      </>
      ))}

    </div>
  )
}

export default PrescriptionDiagnosisType