import React, { useEffect, useState } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../Vitals/Vitals.css'
import InputField from '../../../../UIComponents/Input/Input'
import { Divider, Modal } from 'antd'
import deleteIcon from '../../../../images/delete-icon.png'
import SingleSelect from '../../../../UIComponents/Select/SingleSelect'
import Button from '../../../../UIComponents/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getDoctorListValue, getVitalsChart } from '../../../../redux/actions/opd/patientreport'
import { toast } from '../../../../UIComponents/Toast'
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice'


function Vitals() {
    const dispatch = useDispatch()
    const vitalsList = useSelector(state => state.opdrecord.patientreport.listValue.vitals)
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    console.log("visitInfo", visitInfo)
    const vitalsChart = useSelector(state => state.opdrecord.patientreport.vitalsChart)
    console.log('vitals chart', vitalsChart)
    const speechAfter = useSelector(state => state.appointments.getAppointment.speechs)
    console.log("speechAfter", speechAfter);
    const vitalsSpeech = useSelector(state => state?.appointments?.getAppointment?.speechForVital)
    console.log("vitalsSpeech", vitalsSpeech);

    const [value, setValue] = useState("");
    const [vital, setVital] = useState("")
    const [finalVital, setFinalVital] = useState([])
    const [visible, setVisible] = useState(false)


    const showVitalModal = () => {
        setVisible(true)
        dispatch(getVitalsChart({ patientId: visitInfo?.patientId }))
    }
    const cancelVisitMoal = () => {
        setVisible(false)
    }


    const onVitalChange = (value) => {
        setVital(value)
    }
    const OnVitalValueChange = (e) => {
        setValue(e.target.value)
    }
    const vitalAdd = () => {
        if (!vital || !value) {
            toast.error("Please enter vital fields")
        } else {
            if (visitInfo.hasOwnProperty("vitals") && visitInfo?.vitals?.length) {
                dispatch(updateVisit({ ...visitInfo, vitals: [...visitInfo.vitals, { name: vital, value: value }] }))
                setVital("")
                setValue("")
            } else {
                setFinalVital([...finalVital, { name: vital, value: value }])
                dispatch(updateVisit({ ...visitInfo, vitals: [...finalVital, { name: vital, value: value }] }))
                setVital("")
                setValue("")
            }

        }
    }
    // useEffect(() => {
    //     setFinalVital([...finalVital,{ vital: vitalsSpeech?.vital, value: vitalsSpeech?.value}]) 
    //     // dispatch(updateVisit({ ...visitInfo, vitals: [...finalVital, { vital: vitalsSpeech?.vital, value: vitalsSpeech?.value }] }))

    //     console.log("finalaaaa",finalVital);
    // },[vitalsSpeech])

    const deleteVital = (index) => {
        setFinalVital([...finalVital].filter((item, i) => i !== index))
        dispatch(updateVisit({ ...visitInfo, vitals: [...visitInfo.vitals].filter((item, i) => i !== index) }))
    }

    const bmiCal = () => {
        if (visitInfo.hasOwnProperty("vitals")) {
            if (visitInfo?.vitals?.filter(item => item?.name === "Wt. (Kg)" || item?.name === "Ht. (m)").length < 2) {
                toast.error("Wt. (Kg) or Ht. (m) ")
            } else {
                const weightIndex = visitInfo?.vitals?.findIndex(item => item?.name === "Wt. (Kg)")
                let weight = visitInfo?.vitals[weightIndex].value
                const heightIndex = visitInfo?.vitals?.findIndex(item => item?.name === "Ht. (m)")
                let height = visitInfo?.vitals[heightIndex].value
                let bmiVal = ((Number(weight) / Number(height) / Number(height)).toFixed(1))
                // setFinalVital([...finalVital, { name: 'bmi', value: bmiVal }])
                dispatch(updateVisit({ ...visitInfo, vitals: [...visitInfo.vitals, { name: 'bmi', value: bmiVal }] }))
            }

        } else {

            if (finalVital.filter(item => item?.name === "Wt. (Kg)" || item?.name === "Ht. (m)").length < 2) {
                toast.error("Wt. (Kg) or Ht. (m) ")
            } else {
                const weightIndex = finalVital.findIndex(item => item?.name === "Wt. (Kg)")
                let weight = finalVital[weightIndex].value
                const heightIndex = finalVital.findIndex(item => item?.name === "Ht. (m)")
                let height = finalVital[heightIndex].value
                let bmiVal = ((Number(weight) / Number(height) / Number(height)).toFixed(1))
                setFinalVital([...finalVital, { name: 'bmi', value: bmiVal }])
                dispatch(updateVisit({ ...visitInfo, vitals: [...finalVital, { name: 'bmi', value: bmiVal }] }))
            }
        }
    }
    const whrCal = () => {
        if (visitInfo.hasOwnProperty("vitals")) {
            if (visitInfo?.vitals?.filter(item => item?.name === "Hip (cm)" || item?.name === "Waist (cm)").length < 2) {
                toast.error("Hip (cm) or Waist (cm) ")
            } else {
                const hipIndex = visitInfo?.vitals?.findIndex(item => item?.name === "Hip (cm)")
                let hip = visitInfo?.vitals?.[hipIndex].value
                const waistindex = visitInfo?.vitals?.findIndex(item => item?.name === "Waist (cm)")
                let waist = visitInfo?.vitals?.[waistindex].value
                let whrVal = ((Number(waist) / Number(hip)).toFixed(1))
                // setFinalVital([...finalVital, { name: 'whr', value: whrVal }])
                dispatch(updateVisit({ ...visitInfo, vitals: [...visitInfo.vitals, { name: 'whr', value: whrVal }] }))

            }

        } else {

            if (finalVital.filter(item => item?.name === "Hip (cm)" || item?.name === "Waist (cm)").length < 2) {
                toast.error("Hip (cm) or Waist (cm) ")
            } else {
                const hipIndex = finalVital.findIndex(item => item?.name === "Hip (cm)")
                let hip = finalVital[hipIndex].value
                const waistindex = finalVital.findIndex(item => item?.name === "Waist (cm)")
                let waist = finalVital[waistindex].value
                let whrVal = ((Number(waist) / Number(hip)).toFixed(1))
                setFinalVital([...finalVital, { name: 'whr', value: whrVal }])
                dispatch(updateVisit({ ...visitInfo, vitals: [...finalVital, { name: 'whr', value: whrVal }] }))

            }
        }


    }
    useEffect(() => {
        dispatch(getDoctorListValue({ type: "vitals" }))
    }, [])

    return (
        <div>
            <div className='vitals-div'>
                <NumberButton>3</NumberButton>
                <div className='vitals-3-div'>
                    <h6>VITALS</h6>
                    <div className='vitals-bmi-whr-div'>
                        <p onClick={showVitalModal}>Vital Chart</p>
                        <p onClick={bmiCal}>BMI</p>
                        <p onClick={whrCal}>WHR</p>
                    </div>
                </div>
                <div className='vital-antSelect'>
                    <div className='vital-headings'>
                        <p className='vital-name'>Vital</p>
                        <p className='vital-days'>value</p>
                        <p className='vital-action'>Action</p>
                    </div>
                    <div>
                        <Divider />
                    </div>

                    {visitInfo?.vitals?.map((item, i) => (
                        <div className='vital-names-days'>
                            <p className='vital-bp-name'>{item?.name}</p>
                            <p className='vital-value'>{item?.value}</p>
                            <p className='vital-delect-sec' onClick={() => deleteVital(i)}> <img src={deleteIcon} alt="deleteIcon" /></p>
                        </div>))}

                    <div className="vitals-value-component">
                        <div className="vitals-opd-wd">
                            <p>vitals</p>
                            <SingleSelect data={vitalsList?.data?.list} value={vital} onChange={onVitalChange} />
                        </div>
                        <div className="vitals-opd-wd" >
                            <p>value</p>
                            <InputField type="text" value={value} onChange={OnVitalValueChange} />
                        </div>
                        <div className="vitals-opd-wd-btn">
                            <Button onClick={vitalAdd}>Add</Button>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <Divider />
            </div>

            {
                visible && <Modal
                    title="Vitals Chart"
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={cancelVisitMoal}
                    width={1000}
                >
                    <div>
                        <table style={{ "width": "100%" }}>
                            <tr>

                                {vitalsChart?.vitalschart?.[0].map(item => <td><div style={{ "paddding": "2px" }}>{item}</div></td>)}

                            </tr>

                            {vitalsChart?.vitalschart?.slice(1).map((item, i) => <tr>{item.map(name => <td><div style={{ "paddding": "2px" }}>{name}</div></td>)}</tr>)}

                        </table>


                    </div>
                </Modal>
            }
        </div>
    )
}

export default Vitals