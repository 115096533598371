import { Button, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { LogoContainer } from "../LogoContainer/LogoContainer";
import RbiLogin from "../RbiLogin/RbiLogin";
import "../RbiLogin/RbiLogin.css";
import { setRelationship } from "../../../redux/actions/rbi";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../utils/Helper";
import FamilyMemberOtp from "../RbiLogin/familyMemberOtp";

const RelationshipCheckbox = ({ mobileNumber }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [patientData, setPatientData] = useState(null);
  console.log("patientData==>", patientData);

  const dispatch = useDispatch();
  let url = baseUrl();

  const options = [
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Spouse", value: "Spouse" },
    { label: "Child", value: "child" },
  ];

  // Show only "Spouse" if the designation is "Retired Employee"
  const filteredOptions =
    patientData?.data?.designation === "Retired Employee"
      ? options.filter((option) => option.value === "Spouse")
      : options.filter((option) => option.value);

  console.log("filteredOptions", filteredOptions);

  const handleSelect = (value) => {
    setSelectedValue(value);
    dispatch(setRelationship(value));
  };

  // Common styles for radio div
  const getRadioStyle = (isSelected) => ({
    backgroundColor: isSelected ? " #feb818" : "#F5F5F5",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    padding: "4px",
    cursor: "pointer",
    width: "100px",
    display: "flex",
    // justifyContent: "center"
  });

  const handleFamilyMembers = () => {
    if (selectedValue) {
      setShowOtp(true);
    }
  };

  useEffect(() => {
    const fetchPatientData = async () => {
      let data = JSON.stringify({
        mobile: mobileNumber,
        guardian: "self",
      });

      let config = {
        method: "post",
        url: `${url}user/rbi/find-patient`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        setPatientData(response.data); // Store the patient data from the response
      } catch (error) {
        console.log(error.message); // Store any error encountered
      }
    };

    fetchPatientData(); // Make the API call when the component mounts
  }, [mobileNumber]);

  return (
    <div>
      {!showOtp ? (
        // <FamilyMemberOtp />
        <div>
          <div className="rbi-main-content">
            <div className="helpline">
              <h3>Helpline : 7710090107</h3>
            </div>

            <div className="login-content">
              <div className="login-text">
                <h2>Select your relationship with the employee</h2>
                <h5>Select one of the below</h5>
                <div>
                  {[0, 2].map((startIndex) => (
                    <div
                      key={startIndex}
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: startIndex > 0 ? "20px" : "0",
                      }}
                    >
                      {filteredOptions
                        .slice(startIndex, startIndex + 2)
                        .map((option) => (
                          // {options.slice(startIndex, startIndex + 2).map((option) => (
                          <div
                            key={option.value}
                            onClick={() => handleSelect(option.value)}
                            style={getRadioStyle(
                              selectedValue === option.value
                            )}
                          >
                            <Radio checked={selectedValue === option.value}>
                              {option.label}
                            </Radio>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>

                <div className="login-btn-secction">
                  <Button className="login-btn" onClick={handleFamilyMembers}>
                    Get Started
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <FamilyMemberOtp mobileNumber={mobileNumber} />
        </div>
      )}
    </div>
  );
};

export default RelationshipCheckbox;
