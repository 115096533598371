import React from 'react'
import './BloodGroup.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


export const BloodGroup = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
  
    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'BLOOD GROUP & RH')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item.test === 'BLOOD GROUP & RH'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index]  = filterData[0]
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    }
    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))    }
    
    return (
        <div>
            <div className='bgroup-main'>
                <div className='bgroup-container'>
                    <div className='bgroup-grid-container'>
                        <div className='bgroup-1 padd' onClick={() => onLabelClicked("BLOOD GROUP")} >BLOOD GROUP</div>
                        <div className='bgroup-2'><TextAreaAnt name="BLOOD GROUP" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active}  onChange={onInputChange}/></div>
                        <span className='bgroup-border1'></span>
                        <div className='bgroup-3 padd' onClick={() => onLabelClicked("RH")} >RH</div>
                        <div className='bgroup-4'><TextAreaAnt name="RH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}  onChange={onInputChange}/></div>
                        <span className='bgroup-border2'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}
