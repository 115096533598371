import { log } from "@antv/g2plot/lib/utils";
import * as types from "../../actionTypes";

const initialState = {
  getSinglePatient: {},
  patientToggle:true,
  error: {},
  loading: false,
};
export const getPatientReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PATIENT_LOADING:
          return {
            ...state,
            loading: true,
          };
        case types.GET_PATIENT_SUCCESS:
          console.log("getSinglePatient",action.payload)
          return {
            ...state,
            getSinglePatient: action.payload,
            loading: false,
            error: {},
          };
        case types.GET_PATIENT_FAIL:
          return {
            ...state,
            error: action.payload,
            loading: false,
            getSinglePatient: [],
          };
        case types.RESET_GET_PATIENT:
          return {
            ...state,
            getSinglePatient: action.payload,
            loading: false
          }
        case types.GET_PROFILE_TOGGLE:
          console.log("patientToggle",action.payload)
          return {
            ...state,
            patientToggle:action.payload,
            loading:false
          }
      default:
        return state;
    }
  };