import React from 'react'
import './DoNotUseTwo.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function DoNotUseTwo() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e,i) => {
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'DO NOT USE TWO')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'DO NOT USE TWO'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0]?.fields?.map((item,index) => {
      if(item.particular === name && index === i){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))}

  return (
    <div>
      <div className="doNotUseTwo-parent">
        <div className="doNotUseTwo-left">
          <div className="doNotUseTwo-grid">
            <div className="doNotUseTwo-1 doNotUseTwo-pad" onClick={() => onLabelClicked("No. of Oocytes Expected")}>No. of Oocytes Expected</div>
            <div className="doNotUseTwo-2 doNotUseTwo-text">
              <TextAreaAnt name="No. of Oocytes Expected" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,0)} />
            </div>
            <span className="doNotUseTwo-border"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("No. of Oocytes Retrieved")}>No. of Oocytes Retrieved</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="No. of Oocytes Retrieved" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,1)} />
            </div>
            <span className="doNotUseTwo-border1"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Total M2")}>Total M2</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Total M2" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,2)} />
            </div>
            <span className="doNotUseTwo-border2"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Total M1")}>Total M1</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Total M1" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,3)} />
            </div>
            <span className="doNotUseTwo-border3"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("GV")}>GV</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="GV" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,4)} />
            </div>
            <span className="doNotUseTwo-border4"></span>

            <div className="doNotUseTwo-pad" onClick={() => onLabelClicked("")}>Oocyte Quality</div>
            <div className="doNotUseTwo-text">
              <TextAreaAnt name="Oocyte Quality" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,5)} />
            </div>
            <span className="doNotUseTwo-border5"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Semen Source")}>Semen Source</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Semen Source" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,6)} />
            </div>
            <span className="doNotUseTwo-border6"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Volume")}>Volume</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Volume" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,7)} />
            </div>
            <span className="doNotUseTwo-border7"></span>
            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Total Count")}>Total Count</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Total Count" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,8)} />
            </div>
            <span className="doNotUseTwo-border8"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Motility")}>Motility</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Motility" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,9)} />
            </div>
            <span className="doNotUseTwo-border9"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Progressive Motility (a + b)")}>Progressive Motility (a + b)</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Progressive Motility (a + b)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,10)} />
            </div>
            <span className="doNotUseTwo-border10"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Volume")}>Volume</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Volume" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,11)} />
            </div>
            <span className="doNotUseTwo-border11"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Total Count")}>Total Count</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Total Count" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,12)} />
            </div>
            <span className="doNotUseTwo-border12"></span>

            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Motility")}>Motility</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Motility" value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,13)} />
            </div>
            <span className="doNotUseTwo-border13"></span>
            <div className=" doNotUseTwo-pad" onClick={() => onLabelClicked("Progressive Motility (a + b)")}>Progressive Motility (a + b)</div>
            <div className=" doNotUseTwo-text">
              <TextAreaAnt name="Progressive Motility (a + b)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active} onChange={(e) =>onInputChange(e,14)} />
            </div>
            <span className="doNotUseTwo-border13"></span>

            <div className=" doNotUse-pad"></div>
            <div className=" doNotUseTwo-no-data">No Data              
            </div>
            <span className="doNotUse-border14"></span>

            <div className=" doNotUse-pad"></div>
            <div className=" doNotUseTwo-no-data">No Data              
            </div>
            <span className="doNotUse-border15"></span>
            
            <div className=" doNotUse-pad"></div>
            <div className=" doNotUseTwo-no-data">No Data              
            </div>
            <span className="doNotUse-border16"></span>
           
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="doNotUseTwo-foot">
        <ReportFooter />
      </div>
    </div>
  )
}

export default DoNotUseTwo
