import * as types from '../../actionTypes';

const initialState = {
    error: {},
    loading: {}
}


export const deleteMedicineListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_MEDICINIE_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.DELETE_MEDICINIE_LIST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_MEDICINIE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state
    }
}