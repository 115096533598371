const SubDomainStyles = (domainStyle) => {
    switch (domainStyle) {
        case "index":
            return {
                buttonColor: "#007D9D",
                buttonTextColor: "#000"
            }
            break;
        case "indexMagenta":
            return {
                buttonColor: "#D80073",
                buttonTextColor: "#ffff"
            }
            break;
        case "indexGreen":
            return {
                buttonColor: "#60A917",
                buttonTextColor: "#000"
            }
            break;
        case "indexblue":
            return {
                buttonColor: "#2979ff",
                buttonTextColor: "#000"
            }
            break;
        case "indexRed":
            return {
                buttonColor: "#ef5350",
                buttonTextColor: "#000"
            }
            break;
        case "indexPurple":
            return {
                buttonColor: "#ab47bc",
                buttonTextColor: "#000"
            }
            break;
        case "indexOrange":
            return {
                buttonColor: "#f57c00",
                buttonTextColor: "#000"
            }
            break;
        case "indexBrown":
            return {
                buttonColor: "#795548",
                buttonTextColor: "#000"
            }
            break;
        case "indexMaroon":
            return {
                buttonColor: "#800000",
                buttonTextColor: "#000"
            }
            break;
        case "indexNavy":
            return {
                buttonColor: "#000080",
                buttonTextColor: "#000"
            }
            break;
        case "indexOlive":
            return {
                buttonColor: "#808000",
                buttonTextColor: "#000"
            }
            break;
        case "indexCharcoal":
            return {
                buttonColor: "#36454F",
                buttonTextColor: "#000"
            }
            break;

        default:
            break;
    }
}

export default SubDomainStyles