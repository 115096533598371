import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

const Form = () => {
    const formInfo = useSelector(state => state.opdrecord.patientreport.form)
    const patientInfo = useSelector(state => state.opdrecord.patientreport.patientInfo)
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    const certificateToggle = useSelector(state => state.opdrecord.patientreport.certificateToggle)

    const chunks = formInfo?.form?.split('--')
    const htmlContent = chunks?.[0] + "" + patientInfo?.name + "" + chunks?.[2] + "" + patientInfo?.age + "" + chunks?.[4] + "" + visitInfo?.doctorName + "" + chunks?.[6] + "" + patientInfo?.name + "" + chunks?.[8]  + "" + chunks?.[10] + "" + visitInfo?.doctorName + "" + chunks?.[12] + "" + moment(visitInfo?.visitStart).format("YYYY-MM-DD  ") + "" + chunks?.[14]
    const chunks1 = formInfo?.form?.split('--')
    const htmlContent1 = chunks1?.[0] + "" + visitInfo?.doctorName + "" + chunks1?.[2] + "" + patientInfo?.name + "" + chunks1?.[4] + "" + moment(visitInfo?.visitStart).format("YYYY-MM-DD  ") + "" + chunks1?.[6]
    return (
        <div >
            {formInfo.form ? <div className='certificate-data' dangerouslySetInnerHTML={{ __html: certificateToggle ? htmlContent : htmlContent1 }}></div> : ""}

        </div>
    )
}

export default Form
