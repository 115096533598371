import React from 'react'
import './EmbryologyAdv.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea';
import Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection';
import ReportFooter from '../ReportFooter';
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field , updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function EmbryologyAdv() {
  const dispatch =  useDispatch()
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  console.log("reportDataFields",reportDataFields);

  const [ inputValue, setInputValue ] = useState("")
  const [ tableValueOne, setTableValueOne ] = useState("")
  const [ tableValueTwo, setTableValueTwo ] = useState("")
  
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'EMBRYOLOGY REPORT ADV')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'EMBRYOLOGY REPORT ADV'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onTablesChange = (e) => {
    const { value, name } = e.target;
    console.log('onTableChange function', name, value)
    const [table, type, index] = name.split('.')
    console.log('table, type, index', table, type, index)
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'EMBRYOLOGY REPORT ADV')
        let ind = 0
        reportDataFields.testReport.map((item, i) => {
          if(item.test === 'EMBRYOLOGY REPORT ADV'){
            ind = i
          }
          return item
        })
        console.log("index",ind,filterData[0].table[0].data);
        const filterDataa = filterData[0].table[0].data.map((item,i) => {
          console.log("eokrlklmr",item);
          if(item.particular == table){
            return {
              ...item,
              [type]: value
            }
          }return item
        })
        filterData[0].table[0].data = filterDataa
        console.log("finaldata",filterData[0].table[0].data);
        setTableValueOne(filterData[0].table[0].data)
        dispatch(updateReports(reportDataFields))
  }
  const onTableChange = (e) => {
    const { value, name } = e.target;
    console.log('onTableChange function', name, value)
    const [table, type, index] = name.split('.')
    console.log('table, type, index', table, type, index)
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'EMBRYOLOGY REPORT ADV')
    let ind = 0
    reportDataFields.testReport.map((item, i) => {
      if(item.test === 'EMBRYOLOGY REPORT ADV'){
        ind = i
      }
      return item
    })
    console.log("index",ind,filterData[0].table);
    const fill = filterData[0].table.filter(item => item.particular == table)
    console.log("filtered filter",fill);
    const finaldataaa = fill[0]?.data.map((item,i) => {
      if(i == index){
        return {
          ...item,
          [type]: value
        }
      }return item
    })
    fill[0].data = finaldataaa
    console.log("finaldata",reportDataFields?.testReport?.[ind]?.table?.[1]?.data); 
    setTableValueTwo(reportDataFields?.testReport?.[ind]?.table?.[1]?.data)
    dispatch(updateReports(reportDataFields))
  }
      
  return (
    <div>
            <div className='embr-main-container'>
                <div className='embr-main-left-sec'>
                    <div className='embr-container'>
                        <div className='embr-1 padd' onClick={() => onLabelClicked("IVF / ICSI / TESA")} >IVF / ICSI / TESA</div>
                        <div className='embr-2'><TextAreaAnt name="IVF / ICSI / TESA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active}  onChange={onInputChange} /></div>
                        <span className='embr-border1' onClick={() => onLabelClicked("Embryologist")} ></span>
                        <div className='embr-3 padd'>Embryologist</div>
                        <div className='embr-4'><TextAreaAnt name="Embryologist" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='embr-border2'></span>
                        <div className='embr-5 padd' onClick={() => onLabelClicked("Assisstant Embryologist")}  >Assisstant Embryologist</div>
                        <div className='embr-6'><TextAreaAnt  name="Assisstant Embryologist" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border3'></span>
                        <div className='embr-7 padd' onClick={() => onLabelClicked("OPU Date & Time")} >OPU Date & Time</div>
                        <div className='embr-8'><TextAreaAnt  name="OPU Date & Time" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='embr-border4'></span>
                        <div className='embr-9 padd' onClick={() => onLabelClicked("OPU By Surgeon")} >OPU By Surgeon</div>
                        <div className='embr-10'><TextAreaAnt  name="OPU By Surgeon" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='embr-border5'></span>
                        <div className='embr-11 padd'  onClick={() => onLabelClicked("Insemination Date & Time")} >Insemination Date & Time</div>
                        <div className='embr-12'><TextAreaAnt  name="Insemination Date & Time" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='embr-border6'></span>
                        <div className='embr-13 padd' onClick={() => onLabelClicked("Assistant")}>Assistant</div>
                        <div className='embr-14'><TextAreaAnt  name="Assistant" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active}  /></div>
                        <span className='embr-border7'></span>
                        <div className='embr-15 padd' onClick={() => onLabelClicked("No. of Oocytes Expected")} >No. of Oocytes Expected</div>
                        <div className='embr-16'><TextAreaAnt  name="No. of Oocytes Expected" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border8'></span>
                        <div className='embr-17 padd' onClick={() => onLabelClicked("No. of Oocytes Retrieved")} >No. of Oocytes Retrieved</div>
                        <div className='embr-18'><TextAreaAnt name="No. of Oocytes Retrieved" onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border9'></span>
                        <div className='embr-19 padd' onClick={() => onLabelClicked("Total M2")} >Total M2</div>
                        <div className='embr-20'><TextAreaAnt name="Total M2" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border10'></span>
                        <div className='embr-21 padd' onClick={() => onLabelClicked("Total M1")} >Total M1</div>
                        <div className='embr-22'><TextAreaAnt  name="Total M1" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border11'></span>
                        <div className='embr-23 padd'  onClick={() => onLabelClicked("GV")} >GV</div>
                        <div className='embr-24'><TextAreaAnt name="GV" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border12'></span>
                        <div className='embr-25 padd' onClick={() => onLabelClicked("Oocyte Quality")} >Oocyte Quality</div>
                        <div className='embr-26'><TextAreaAnt name="Oocyte Quality" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border13'></span>
                        <div className='embr-27 padd' onClick={() => onLabelClicked("Embryo Transfer Date")} >Embryo Transfer Date</div>
                        <div className='embr-28'><TextAreaAnt name="Embryo Transfer Date" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active}  /></div>
                        <span className='embr-border14'></span>
                        <div className='embr-29 padd' onClick={() => onLabelClicked("Embryo Transfer By")} >Embryo Transfer By</div>
                        <div className='embr-30'><TextAreaAnt name="Embryo Transfer By" onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border15'></span>
                        <div className='embr-31 padd' onClick={() => onLabelClicked("Endometrium Thickness")} >Endometrium Thickness</div>
                        <div className='embr-32'><TextAreaAnt name="Endometrium Thickness" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[15]?.result} disabled={!reportDataFields.active}  /></div>
                        <span className='embr-border16'></span>
                        <div className='embr-33 padd' onClick={() => onLabelClicked("No. of Embryos Transferred")} >No. of Embryos Transferred</div>
                        <div className='embr-34'><TextAreaAnt name="No. of Embryos Transferred" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[16]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border17'></span>
                        <div className='embr-35 padd' onClick={() => onLabelClicked(" Embryo Grading")} >Embryo Grading</div>
                        <div className='embr-36'><TextAreaAnt name="Embryo Grading" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[17]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border18'></span>
                        <div className='embr-37 padd' onClick={() => onLabelClicked("Transfer Ease")} >Transfer Ease</div>
                        <div className='embr-38'><TextAreaAnt name="Transfer Ease" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[18]?.result} disabled={!reportDataFields.active}  /></div>
                        <span className='embr-border19'></span>
                        <div className='embr-39 padd' onClick={() => onLabelClicked("No. of Embryo Frozen")} >No. of Embryo Frozen</div>
                        <div className='embr-40'><TextAreaAnt name="No. of Embryo Frozen" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[19]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border20'></span>
                        <div className='embr-41 padd' onClick={() => onLabelClicked("Sleeve No. & Colour")} >Sleeve No. & Colour</div>
                        <div className='embr-42'><TextAreaAnt name="Sleeve No. & Colour" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[20]?.result} disabled={!reportDataFields.active}  /></div>
                        <span className='embr-border21'></span>
                        <div className='embr-43 padd' onClick={() => onLabelClicked("Grading")}  >Grading</div>
                        <div className='embr-44'><TextAreaAnt name="Grading" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[21]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border22'></span>
                        <div className='embr-45 padd' onClick={() => onLabelClicked("Location")} >Location</div>
                        <div className='embr-46'><TextAreaAnt name="Location" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[22]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border23'></span>
                        <div className='embr-47 padd' onClick={() => onLabelClicked("Semen Source")} >Semen Source</div>
                        <div className='embr-48'><TextAreaAnt name="Semen Source" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[23]?.result} disabled={!reportDataFields.active} /></div>
                        <span className='embr-border24'></span>                   
                        <div className='embr-49 padd'>Pre Wash</div>
                        <div className='embr-50 padd'>Post Wash</div>             
                        <span className='embr-border25'></span>

                        <div className='embr-51 padd'>Volume</div>             
                        <div className='embr-52'><Input type='number' className='embr-5-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.prewash} disabled={!reportDataFields.active} name="Volume.prewash.0" onChange={onTablesChange}/></div>
                        <div className='embr-53'><Input type='number' className='embr-5-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.postwash} disabled={!reportDataFields.active} name="Volume.postwash.0" onChange={onTablesChange}/></div>           
                        <span className='embr-border26'></span>
                        <div className='embr-54 padd'>Total Count</div>             
                        <div className='embr-55'><Input type='number' className='embr-5-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.prewash} disabled={!reportDataFields.active} name="Total Count.prewash.1" onChange={onTablesChange}/></div>
                        <div className='embr-56'><Input type='number' className='embr-5-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.postwash} disabled={!reportDataFields.active} name="Total Count.postwash.1" onChange={onTablesChange}/></div>           
                        <span className='embr-border27'></span>
                        <div className='embr-57 padd'>Motility</div>             
                        <div className='embr-58'><Input type='number' className='embr-5-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.prewash} disabled={!reportDataFields.active} name="Motility.prewash.2" onChange={onTablesChange}/></div>
                        <div className='embr-59'><Input type='number' className='embr-5-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.postwash} disabled={!reportDataFields.active} name="Motility.postwash.2" onChange={onTablesChange}/></div>           
                        <span className='embr-border28'></span>
                        <div className='embr-60 padd'>Progressive Motility (a + b)</div>             
                        <div className='embr-61'><Input type='number' className='embr-5-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.prewash} disabled={!reportDataFields.active} name="Progressive Motility (a + b).prewash.3" onChange={onTablesChange}/></div>
                        <div className='embr-62'><Input type='number' className='embr-5-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.postwash} disabled={!reportDataFields.active} name="Progressive Motility (a + b).postwash.3" onChange={onTablesChange}/></div>           
                        <span className='embr-border29'></span>

                        <div className='embr-63 padd'>Embryo No</div>
                        <div className='embr-64 padd'>Day 1</div>
                        <div className='embr-65 padd'>Day 2</div>
                        <div className='embr-66 padd'>Day 3</div>
                        <div className='embr-67 padd'>Day 4</div>
                        <div className='embr-68 padd'>Day 5</div>
                        <div className='embr-69 padd'>Utility</div>
                        <span className='embr-border30'></span>
                        <div className='embr-70 '><Input className='embr-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.embryono} disabled={!reportDataFields.active} type="number" name="FERTILIZATION AND EMBRYO DEVELOPMENT.embryono.0" onChange={onTableChange} /></div>
                        <div className='embr-71 '><Input className='embr-input' value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.day1} disabled={!reportDataFields.active} type="number" name="FERTILIZATION AND EMBRYO DEVELOPMENT.day1.0" onChange={onTableChange} /></div>
                        <div className='embr-72 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.day2} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day2.0" onChange={onTableChange} /></div>
                        <div className='embr-73 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.day3} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day3.0" onChange={onTableChange} /></div>
                        <div className='embr-74 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.day4} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day4.0" onChange={onTableChange} /></div>
                        <div className='embr-75 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.day5} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day5.0" onChange={onTableChange} /></div>
                        <div className='embr-76 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.utilization} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.utilization.0" onChange={onTableChange} /></div>
                        <span className='embr-border31'></span>

                        <div className='embr-77 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.embryono} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.embryono.1" onChange={onTableChange} /></div>
                        <div className='embr-78 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.day1} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day1.1" onChange={onTableChange} /></div>
                        <div className='embr-79 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.day2} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day2.1" onChange={onTableChange} /></div>
                        <div className='embr-80 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.day3} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day3.1" onChange={onTableChange} /></div>
                        <div className='embr-81 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.day4} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day4.1" onChange={onTableChange} /></div>
                        <div className='embr-82 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.day5} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day5.1" onChange={onTableChange} /></div>
                        <div className='embr-83 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.utilization} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.utilization.1" onChange={onTableChange} /></div>
                        <span className='embr-border32'></span>

                        <div className='embr-84 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.embryono} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.embryono.2" onChange={onTableChange} /></div>
                        <div className='embr-85 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.day1} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day1.2" onChange={onTableChange} /></div>
                        <div className='embr-86 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.day2} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day2.2" onChange={onTableChange} /></div>
                        <div className='embr-87 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.day3} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day3.2" onChange={onTableChange} /></div>
                        <div className='embr-88 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.day4} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day4.2" onChange={onTableChange} /></div>
                        <div className='embr-89 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.day5} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day5.2" onChange={onTableChange} /></div>
                        <div className='embr-90 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.utilization} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.utilization.2" onChange={onTableChange} /></div>

                        <span className='embr-border33'></span>

                        <div className='embr-91 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.embryono} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.embryono.3" onChange={onTableChange} /></div>
                        <div className='embr-92 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.day1} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day1.3" onChange={onTableChange} /></div>
                        <div className='embr-93 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.day2} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day2.3" onChange={onTableChange} /></div>
                        <div className='embr-94 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.day3} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day3.3" onChange={onTableChange} /></div>
                        <div className='embr-95 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.day4} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day4.3" onChange={onTableChange} /></div>
                        <div className='embr-96 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.day5} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day5.3" onChange={onTableChange} /></div>
                        <div className='embr-97 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.utilization} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.utilization.3" onChange={onTableChange} /></div>
                        <span className='embr-border34'></span>

                        <div className='embr-98 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[4]?.embryono} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.embryono.4" onChange={onTableChange} /></div>
                        <div className='embr-99 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[4]?.day1} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day1.4" onChange={onTableChange} /></div>
                        <div className='embr-100 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[4]?.day2} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day2.4" onChange={onTableChange} /></div>
                        <div className='embr-101 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[4]?.day3} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day3.4" onChange={onTableChange} /></div>
                        <div className='embr-102 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[4]?.day4} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day4.4" onChange={onTableChange} /></div>
                        <div className='embr-103 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[4]?.day5} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day5.4" onChange={onTableChange} /></div>
                        <div className='embr-104 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[4]?.utilization} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.utilization.4" onChange={onTableChange} /></div>

                        <span className='embr-border35'></span>
                        <div className='embr-105 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[5]?.embryono} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.embryono.5" onChange={onTableChange} /></div>
                        <div className='embr-106 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[5]?.day1} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day1.5" onChange={onTableChange} /></div>
                        <div className='embr-107 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[5]?.day2} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day2.5" onChange={onTableChange} /></div>
                        <div className='embr-108 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[5]?.day3} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day3.5" onChange={onTableChange} /></div>
                        <div className='embr-109 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[5]?.day4} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day4.5" onChange={onTableChange} /></div>
                        <div className='embr-110 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[5]?.day5} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day5.5" onChange={onTableChange} /></div>
                        <div className='embr-111 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[5]?.utilization} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.utilization.5" onChange={onTableChange} /></div>
                        <span className='embr-border36'></span>

                        <div className='embr-112 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[6]?.embryono} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.embryono.6" onChange={onTableChange} /></div>
                        <div className='embr-113 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[6]?.day1} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day1.6" onChange={onTableChange} /></div>
                        <div className='embr-114 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[6]?.day2} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day2.6" onChange={onTableChange} /></div>
                        <div className='embr-115 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[6]?.day3} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day3.6" onChange={onTableChange} /></div>
                        <div className='embr-116 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[6]?.day4} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day4.6" onChange={onTableChange} /></div>
                        <div className='embr-117 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[6]?.day5} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.day5.6" onChange={onTableChange} /></div>
                        <div className='embr-118 '><Input className='embr-input' type="number" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[6]?.utilization} disabled={!reportDataFields.active} name="FERTILIZATION AND EMBRYO DEVELOPMENT.utilization.6" onChange={onTableChange} /></div>
                        <span className='embr-border37'></span>
                        
                    </div>

                </div>
                <div>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='embr-footer'>
                <ReportFooter />
            </div>
        </div>

  )
}

export default EmbryologyAdv