import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { getPatientProfileToggle } from "../opd/patientreport";
import { getAppointments } from "./appointments";
import moment from "moment";
import { client } from "../../../utils/axios";

const savePatientLoading = () => ({
  type: types.SAVE_PATIENT_LOADING,
});

const savePatientSuccess = (data) => ({
  type: types.SAVE_PATIENT_SUCCESS,
  payload: data,
});
const savePatientFail = (error) => ({
  type: types.SAVE_PATIENT_FAIL,
  payload: error,
});

export const savePatient = (data) => {
  console.log("testing key 1111", data);
  return function (dispatch) {
    dispatch(savePatientLoading());
    client
      .post(
        `/patient/save-patient-address`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        console.log("Appointment confirmed", resp.data, data.getPatient);
        dispatch(savePatientSuccess(resp.data));
        dispatch(
          getAppointments({ scheduledTime: moment().format("YYYY-MM-DD") })
        );
        dispatch(getPatientProfileToggle(false));
        // dispatch(getPatientAppointmet({ patientId:data.patientId }))
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(savePatientFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
