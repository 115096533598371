import React from 'react'
import './DoNotUseWhy.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea';
import Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection';
import ReportFooter from '../ReportFooter';
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


function DoNotUseWhy() {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)    
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'DO NOT USE WHY')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'DO NOT USE WHY'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
     filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
    const onLabelClicked = (value) =>{
          dispatch(field({fieldKey:value}))        }
  return (
    <div>
            <div className='dnu-why-main-container'>
                <div className='dnu-why-main-left-sec'>
                    <div className='dnu-why-container'>
                        <div className='dnu-why-1 padd' onClick={() => onLabelClicked("No. of Oocytes Expected ")}>No. of Oocytes Expected</div>
                        <div className='dnu-why-2'><TextAreaAnt name="No. of Oocytes Expected" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='dnu-why-border1'></span>
                        <div className='dnu-why-3 padd' onClick={() => onLabelClicked("No. of Oocytes Retrieved ")}>No. of Oocytes Retrieved</div>
                        <div className='dnu-why-4'><TextAreaAnt name="No. of Oocytes Retrieved" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='dnu-why-border2'></span>
                        <div className='dnu-why-5 padd' onClick={() => onLabelClicked("Total M2 ")}>Total M2</div>
                        <div className='dnu-why-6'><TextAreaAnt name="Total M2" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border3'></span>
                        <div className='dnu-why-7 padd' onClick={() => onLabelClicked("Total M1 ")}>Total M1</div>
                        <div className='dnu-why-8'><TextAreaAnt name="Total M1" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='dnu-why-border4'></span>
                        <div className='dnu-why-9 padd' onClick={() => onLabelClicked(" GV")}>GV</div>
                        <div className='dnu-why-10'><TextAreaAnt name="GV" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='dnu-why-border5'></span>
                        <div className='dnu-why-11 padd' onClick={() => onLabelClicked("Oocyte Quality ")}>Oocyte Quality</div>
                        <div className='dnu-why-12'><TextAreaAnt name="Oocyte Quality" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border6'></span>
                        <div className='dnu-why-13 padd' onClick={() => onLabelClicked(" Semen Source")}>Semen Source</div>
                        <div className='dnu-why-14'><TextAreaAnt name="Semen Source" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='dnu-why-border7'></span>
                        <div className='dnu-why-15 padd' onClick={() => onLabelClicked("Volume ")}>Volume</div>
                        <div className='dnu-why-16'><TextAreaAnt name="Volume" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border8'></span>
                        <div className='dnu-why-17 padd' onClick={() => onLabelClicked(" Total Count")}>Total Count</div>
                        <div className='dnu-why-18'><TextAreaAnt name="Total Count" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border9'></span>
                        <div className='dnu-why-19 padd' onClick={() => onLabelClicked("Motility ")}>Motility</div>
                        <div className='dnu-why-20'><TextAreaAnt name="Motility" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border10'></span>
                        <div className='dnu-why-21 padd' onClick={() => onLabelClicked("Progressive Motility (a + b) ")}>Progressive Motility (a + b)</div>
                        <div className='dnu-why-22'><TextAreaAnt name="Progressive Motility (a + b)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border11'></span>
                        <div className='dnu-why-23 padd' onClick={() => onLabelClicked("Volume")}>Volume</div>
                        <div className='dnu-why-24'><TextAreaAnt name="Volume" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border12'></span>
                        <div className='dnu-why-25 padd' onClick={() => onLabelClicked(" Total Count")}>Total Count</div>
                        <div className='dnu-why-26'><TextAreaAnt name="Total Count" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border13'></span>
                        <div className='dnu-why-27 padd' onClick={() => onLabelClicked(" Motility")}>Motility</div>
                        <div className='dnu-why-28'><TextAreaAnt name="Motility" value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border14'></span>
                        <div className='dnu-why-29 padd' onClick={() => onLabelClicked(" Progressive Motility (a + b)")}>Progressive Motility (a + b)</div>
                        <div className='dnu-why-30'><TextAreaAnt name="Progressive Motility (a + b)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='dnu-why-border15'></span>

                        <div className='dnu-why-31 padd'>Pre Wash</div>
                        <div className='dnu-why-32 padd'>Post Wash</div>             
                        <span className='dnu-why-border16'></span>
                        <div className='dnu-why-33 padd'>Total Count</div>             
                        <div className='dnu-why-34'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-35'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border17'></span>
                        <div className='dnu-why-36 padd'>Motility</div>             
                        <div className='dnu-why-37'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-38'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border18'></span>
                        <div className='dnu-why-39 padd'>Rapid Linear Progression</div>             
                        <div className='dnu-why-40'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-41'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border19'></span>
                        <div className='dnu-why-42 padd'>Slow Non Linear Progression</div>             
                        <div className='dnu-why-43'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-44'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border20'></span>

                        <div className='dnu-why-45 padd'>Non Progressive Motility</div>             
                        <div className='dnu-why-46'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-47'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border21'></span>
                        <div className='dnu-why-48 padd'>Immotile</div>             
                        <div className='dnu-why-49'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-50'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border22'></span>
                        <div className='dnu-why-51 padd'>Method used for Preparation</div>             
                        <div className='dnu-why-52'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-53'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border23'></span>
                        <div className='dnu-why-54 padd'>Pus Cell</div>             
                        <div className='dnu-why-55'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-56'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border24'></span>
                        <div className='dnu-why-57 padd'>Epithelial Cells</div>             
                        <div className='dnu-why-58'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-59'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border25'></span>
                        <div className='dnu-why-60 padd'>Morphology</div>             
                        <div className='dnu-why-61'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-62'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border26'></span>
                        <div className='dnu-why-63 padd'>Agglutination</div>             
                        <div className='dnu-why-64'><Input type="number" className='dnu-why-5-input' /></div>
                        <div className='dnu-why-65'><Input type="number" className='dnu-why-5-input' /></div>           
                        <span className='dnu-why-border27'></span>
                        

                        <div className='dnu-why-66 padd'>Embryo No</div>
                        <div className='dnu-why-67 padd'>Day 1</div>
                        <div className='dnu-why-68 padd'>Day 2</div>
                        <div className='dnu-why-69 padd'>Day 3</div>
                        <div className='dnu-why-70 padd'>Day 4</div>
                        <div className='dnu-why-71 padd'>Day 5</div>
                        <div className='dnu-why-72 padd'>Utility</div>
                        <span className='dnu-why-border28'></span>
                        <div className='dnu-why-73 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-74 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-75 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-76 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-77 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-78 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-79 '><Input className='dnu-why-input' type="number" /></div>
                        <span className='dnu-why-border29'></span>

                        <div className='dnu-why-80 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-81 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-82 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-83 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-84 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-85 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-86 '><Input className='dnu-why-input' type="number" /></div>
                        <span className='dnu-why-border30'></span>

                        <div className='dnu-why-87 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-88 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-89 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-90 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-91 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-92 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-93 '><Input className='dnu-why-input' type="number" /></div>

                        <span className='dnu-why-border31'></span>

                        <div className='dnu-why-94 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-95 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-96 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-97 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-98 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-99 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-100 '><Input className='dnu-why-input' type="number" /></div>

                        <span className='dnu-why-border32'></span>

                        <div className='dnu-why-101 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-102'><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-103 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-104 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-105 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-106 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-107 '><Input className='dnu-why-input' type="number" /></div>

                        <span className='dnu-why-border33'></span>
                        <div className='dnu-why-108 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-109 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-110 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-111 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-112 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-113 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-114 '><Input className='dnu-why-input' type="number" /></div>
                        <span className='dnu-why-border35'></span>

                        <div className='dnu-why-115 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-116 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-117 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-118 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-119 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-120 '><Input className='dnu-why-input' type="number" /></div>
                        <div className='dnu-why-121 '><Input className='dnu-why-input' type="number" /></div>
                        <span className='dnu-why-border36'></span>
                        
                    </div>

                </div>
                <div>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='dnu-why-footer'>
                <ReportFooter />
            </div>
        </div>

  )
}

export default DoNotUseWhy