import "./App.css";
import { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Analytics from "./pages/Analytics/Analytics";
import Opd from "./pages/OPD/Opd";
import Appointments from "./pages/Appointments/Appointments";
import Diagnostics from "./pages/Diagnostics/Diagnostics";
import Calls from "./pages/Calls/Calls";
import Profile from "./pages/Profile/Profile";
import EditMasters from "./pages/EditMasters/EditMasters";
import EditMedicines from "./pages/EditMedicines/EditMedicines";
import Login from "./pages/Login/Login";
import NotFound from "../src/components/NotFound/NotFound";
import Admin from "./pages/Admin/Admin";
import Home from "../src/components/Home/Home";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import NewJoin from "./components/Opd/Video/NewJoin";
import PrivateRoute from "./components/routes/PrivateRoute";
import PrivateRouteAdmin from "./components/routes/PrivateRouteAdmin";
import { useSelector, useDispatch } from "react-redux";
import { myDomainData } from "./redux/actions/login";
import RblForm from "./pages/RbiForm/RbiForm";
import MainPage from "./pages/RbiForm/MainPage";

function App() {
  const dispatch = useDispatch();
  const myDomainInfo = useSelector((state) => state.login.myDomainInfo);
  useEffect(() => {
    if (myDomainInfo === null) {
      dispatch(myDomainData());
    }
  }, [myDomainInfo]);

  return (
    <div className="App">
      <Switch>
        {/* <Route path="/" component={Home} exact /> */}
        <Route path="/ihbanarik" component={MainPage} exact />
        <Route path="/doctor-app/login" component={Login} exact />
        <PrivateRoute path="/analytics" component={Analytics} exact />
        <PrivateRoute path="/opd" component={Opd} exact />
        <PrivateRoute path="/appointments" component={Appointments} exact />
        <PrivateRoute path="/diagnostics" component={Diagnostics} />
        <PrivateRoute path="/calls" component={Calls} exact />
        <PrivateRoute path="/profile" component={Profile} exact />
        <PrivateRoute path="/editmasters" component={EditMasters} exact />
        <PrivateRoute path="/editmedicines" component={EditMedicines} exact />
        <PrivateRouteAdmin path="/admin" component={Admin} exact />
        <PrivateRouteAdmin path="/admin/login" component={AdminLogin} exact />
        <Route path="/web-chat/:roomname/:token">
          <NewJoin />
        </Route>
        <Route path="/404" component={NotFound} />
        <Route component={() => <Redirect to="/404" />} />
      </Switch>
    </div>
  );
}

export default App;
