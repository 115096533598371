import React, { useState, useEffect } from "react";
import "./DoctorStatistics.css";
import Table from "../../../UIComponents/Table/Table";
import Button from '../../../UIComponents/Button/Button'
import { DatePicker, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { doctorstatistics, getLastCronJobStatus } from "../../../redux/actions/doctorstatistics/doctorstatistics";
import moment from "moment";
const columns = [
  {
    title: "DOCTOR",
    dataIndex: "name",
  },
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "NO. OF VISITS",
    dataIndex: "noOfVisits",
  },
  {
    title: "MARKED FOR FULFILLMENT",
    dataIndex: "noOfVisitsMarkedForFulfillment",
  },
  // {
  //   key: "x",
  //   render: () => <div><img className="medicine-table-delete" src={deleteIcon} alt="delete" /></div>
  // }
];
const doctorIds = ['2a0e5fe2-f8af-4a0e-8b8e-a3d07582caa9', '4d312f7b-e36b-491a-93f3-cc8606575a26']

const DoctorStatistics = () => {
  const dispatch = useDispatch();
  const [doctors, setDoctors] = useState("");
  const [date, setDate] = useState("")
  const { RangePicker } = DatePicker;

  const doctorStatisticsInfo = useSelector(
    (state) => state.doctorStatistics.doctorStatisticsInfo.doctors
  );
  const cronJobStatus = useSelector(
    (state) => state.doctorStatistics.cronJobStatus
  );
  console.log("doctorStatisticsInfo", doctorStatisticsInfo, cronJobStatus);

  const handleDateChange = (dateString) => {
    if (!dateString) {
      setDoctors('')
      return false;
    }
    if (dateString && dateString.length) {
      const fromDate = dateString[0].startOf('day').toISOString()
      const toDate = dateString[1].startOf('day').add(1, 'days').toISOString()
      console.log("dateString", fromDate, toDate);
      dispatch(doctorstatistics(fromDate, toDate));
    };
  };

  useEffect(() => {
    if (doctorStatisticsInfo && doctorStatisticsInfo.length) {

      const data = doctorStatisticsInfo.filter(arr => {
        const blacklistedDoctors = doctorIds.includes(arr.id)
        if (arr.noOfVisits && !blacklistedDoctors) return true
        return false;
      })
      setDoctors(data);
    }
  }, [doctorStatisticsInfo]);

  useEffect(() => {
    dispatch(getLastCronJobStatus())
  }, [])

  return (
    <div className="statistics-container">
      <div className="doctor-statistics-container">
        <h2>Doctor Statistics</h2>
        <div className="doctor-statistics-last-cron">
          <div><strong>Last Cron Job:</strong> {moment(cronJobStatus).format('DD-MM-YYYY HH:mm:ss')}</div>

          <Button onClick={() => dispatch(getLastCronJobStatus())} className={"doctor-statistics-run-cron"}>Run</Button>
        </div>
      </div>
      <div className="statistics-calender">
        <div className="statistics-calenders">
          <div className="fromdate-date-heading">
            <p>From Date</p>
          <p>To Date</p>
          </div>
          
          <RangePicker
            onChange={handleDateChange}
          />
        </div>
      </div>
      <div className='dia-table-scroll'>
        <Table columns={columns} data={doctors} pagination={false} scroll={{y: 515}} />
      </div>
    </div>
  );
};

export default DoctorStatistics;
