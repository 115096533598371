import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DiagnosticPatientInvoiceStatus from "../../components/Diagnostics/DiagnosticPatientProfile/DiagnosticPatientInvoiceStatus";
import DiagnosticPatientProfile from "../../components/Diagnostics/DiagnosticPatientProfile/DiagnosticPatientProfile";
import DiagnosticPickLoaction from "../../components/Diagnostics/DiagnosticPatientProfile/DiagnosticPickLoaction";
import DiagnosticTab from "../../components/Diagnostics/DiagnosticTab/DiagnosticTab";
import { getProfileDetails } from "../../redux/actions/profile";
import { getDiagnosticProfile } from "../../redux/actions/Diagnostics/diagnostics";
import { getPatient } from "../../redux/actions/Diagnostics/getPatient";
import { getDiagnostics } from "../../redux/actions/Diagnostics/diagnostics";
import { resetGetPatient } from "../../redux/actions/Diagnostics/getPatient";
import "./Diagnostics.css";
import {
  toggleButton,
  getPatietClick,
  updateServiceDiagnostic,
} from "../../redux/actions/Diagnostics/diagnostics";
import { resetGetDiagnostics } from "../../redux/actions/Diagnostics/diagnostics";
import useDoctorId from "../../utils/get-doctor-id";
const Diagnostics = () => {
  const dispatch = useDispatch();
  const getFiltererdTests = useSelector(
    (state) => state.diagnosticsInvoiceSummary?.getDiagnosticsProfile?.rates
  );
  const profileInfo = useSelector((state) => state.profile?.profileInfo);
  // const doctorId = profileInfo?.id;
  const doctorId = useDoctorId();
  console.log("doctorId", doctorId);

  useEffect(() => {
    dispatch(getDiagnosticProfile(doctorId));
    dispatch(resetGetPatient());
    dispatch(resetGetDiagnostics());
    dispatch(toggleButton(false));
    dispatch(getPatietClick(false));
  }, []);
  useEffect(() => {
    if (profileInfo.length === 0) {
      dispatch(getProfileDetails({ doctorId }));
    }
  }, [profileInfo]);

  return (
    <div className="main-section">
      <div className="diagnostic-heading">
        <h2 className="diagnostic-heading-title">DIAGNOSTIC</h2>
      </div>
      <div className="diagnostics-left-right-main-section">
        <div className="diagnostics-left-section">
          <div className="diagnostic-pickloaction-section">
            <DiagnosticPickLoaction />
          </div>
        </div>
        <div className="diagnostics-right-section">
          <div className="diagnostic-patient-section">
            <DiagnosticPatientProfile />
          </div>
          <div className="diagnostic-profile-inovice-status-section">
            <DiagnosticPatientInvoiceStatus />
          </div>
          <div className="diagnostic-tab-section">
            <DiagnosticTab />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diagnostics;
