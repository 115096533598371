import React, { useState, useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import "./MedicineMaster.css";
import Table from "../../../UIComponents/Table/Table";
import Button from "../../../UIComponents/Button/Button";
import deleteIcon from "../../../images/Delete-red.svg";
import Input from "../../../UIComponents/Input/Input";
import { SearchOutlined } from "@ant-design/icons";
import UpdateImage from "../../../images/Update-button.svg";
import DownloadImage from "../../../images/Download-button.svg";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { mastermedicine } from "../../../redux/actions/medicinemaster/medicinemaster";
import { medicinedownload } from "../../../redux/actions/medicinemaster/medicinedownload";
import { medicineactivate } from "../../../redux/actions/medicinemaster/medicineactivate";
import { medicinedeactivate } from "../../../redux/actions/medicinemaster/medicinedeactivate";
import { medicineAdd } from "../../../redux/actions/medicinemaster/medicineadd";

const MedicineMaster = () => {
  const [rendConst, setRendConst] = useState(true);
  console.log("rendConst", rendConst);
  let fileInputRef = useRef();
  const rendFUn = (value) => {
    console.log("sdsdsdsdvalll", value);
    return (
      <div>
      <Checkbox
        onChange={(e) => checkBoxHandler(value[1], e)}
        value={checkSku}
      ></Checkbox>
    </div>)
  }
  const rendFalseFUn = (value) => {
    setRendConst(true)
    return (
      <div>
      <Checkbox
        checked={false}
        onChange={(e) => checkBoxHandler(value[1], e)}
        value={checkSku}
      ></Checkbox>
    </div>)
  }
  const columns = [
    {
      title:"",
      dataIndex:"icons"
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: rendConst?rendFUn:rendFalseFUn,
    },

    {
      title: "SKU",
      dataIndex: "1",
    },
    {
      title: "ITEM NAME",
      dataIndex: "2",
    },
    {
      title: "MOU",
      dataIndex: "3",
    },
    {
      title: "",
      dataIndex: "icons",
    },
    {
      title: "MRP",
      dataIndex: "4",
    },
    {
      title: "",
      dataIndex: "icons",
    },
    {
      title: "",
      dataIndex: "icons",
    },
    {
      title: "STATUS",
      dataIndex: "5",
    },
    {
      title: "",
      dataIndex: "icons",
    },
    {
      title: "",
      dataIndex: "icons",
    },
    {
      title: "",
      dataIndex: "icons",
    },
  ];

  let dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [checkSku, setCheckSku] = useState([]);
  const [checked, setChecked] = useState(false);
  const [newSku, setNewSku] = useState([]);
  const [news, setNews] = useState();
  const [unCheck, setUnCheck] = useState();
  const [checkArr, setCheckArr] = useState([])

  const medicineMasterInfo = useSelector(
    (state) => state.medicineMaster.medicineMasterInfo
  );
  console.log("medicineMasterInfo", medicineMasterInfo);

  const searchItem = (Value) => {
    setSearchInput(Value);
    if (searchInput !== "") {
      const filterData = medicineMasterInfo.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredData(filterData);
    } else {
      setFilteredData(apiData);
    }
  };

  // useEffect(() => {
  //   dispatch(mastermedicine("search%5Bvalue%5D=&start=0&length=100"));
  // }, []);

  useEffect(() => {
    if (medicineMasterInfo && medicineMasterInfo?.data) {
      setApiData(medicineMasterInfo.data);
    }
  }, [medicineMasterInfo]);

  // const download = () => {
  //   dispatch(medicinedownload("search%5Bvalue%5D=&start=0&length=100"));
  // };

  console.log("fhkdhfskjdhoutside", checkSku);

  const activateButton = () => {
    console.log("fhkdhfskjdh", checkSku);
    dispatch(medicineactivate({ sku: checkSku }));
    // setCheckSku("");
    setChecked(false);
    toast.success("Activated");
    if(checkSku.length > 0){
      console.log('jsdsd',checkSku)
      // checkSku.map(item => {
      //     console.log('jksdh', item);
      //     let arr = [...checkSku].filter((i) => i !== item);
      //     setCheckSku(arr);
      //     return item
      // } )
    }
    setRendConst(false)
    setCheckSku([])
  };

  const deactivate = {
    sku: checkSku,
  };

  const deactivateButton = () => {
    console.log("deactivate", deactivate);
    dispatch(medicinedeactivate(deactivate));
    toast.success("Deactivated");
    // setCheckSku("");
    setRendConst(false)
    setCheckSku([])
  };

  const checkBoxHandler = (value, e) => {
    console.log("ghghgh", value, e);
    
    if (e.target.checked) {
      setCheckSku([...checkSku, value]);
      setChecked(e?.target.checked);
    } else {
      console.log(value, checkSku);
      let arr = [...checkSku].filter((item) => item !== value);
      setCheckSku(arr);

    }
  };

 

  

  console.log("fsdafasdf", checkSku);
  // const uploadButton = () => {
  //   dispatch(medicineAdd());
  //   console.log("uploadButton", uploadButton);
  // };

  // const uploadFiles = () => {
  //   document.getElementById("selectFile").click();
  // };

  // uploading a CSV file
  const changeFileHandler = (event) => {
    console.log("Checking event", event.target.files);
    console.log("targettedFiles", event.target.files[0]);

    const fileExtension = event.target.files[0].name.split(".").pop();
    console.log("fileExtension", fileExtension);

    if (fileExtension === "csv") {
      const formData = new FormData();
      formData.append(
        "skucsv",
        event.target.files[0],
        event.target.files[0].name
      );
      console.log("formData", formData.append);
      dispatch(medicineAdd(formData));
    } else {
      toast.error("please Upload CSV file only.");
    }
  };

  console.log("api00001", apiData);
  // uploading CSV file DATA
  const csvContent = medicineMasterInfo?.data;
  console.log("csvContent", csvContent);
  const downloadTemplete = () => {
    dispatch(medicinedownload());
  };
  return (
    <div>
      <div className="medicine-master-container">
        <p>Medicine Master</p>
        <div className="medicine-master-header">
          <Input
            onChange={(e) => {
              if (e.target.value) {
                dispatch(
                  mastermedicine({
                    search: {
                      value: e.target.value
                    },
                    start: 0,
                    length: 0
                  })
                );
              }
            }}
            placeholder="Search"
            type="search"
            className="medicine-master-search-input"
            suffix={<SearchOutlined />}
          />

          <Button className="download-button-grey" onClick={downloadTemplete}>
            <img src={DownloadImage} className="download-button-icon" alt="" />
            DOWNLOAD
          </Button>

          <Button
            className="upload-button-grey"
            onClick={() => fileInputRef.current.click()}
          >
            UPLOAD MEDICINE MASTER
          </Button>

          <input
            onChange={changeFileHandler}
            multiple={false}
            ref={fileInputRef}
            type="file"
            hidden
          />
          <Button className="active-button-green" onClick={activateButton}>
            ACTIVE
          </Button>

          <Button className="deactive-button-black" onClick={deactivateButton}>
            DEACTIVE
          </Button>
          <div className="active-deactive-buttons"></div>
          {/* <Button className="update-button-grey">
            <img src={UpdateImage} className="update-button-icon" alt="" />
            UPDATE
          </Button> */}
        </div>

        <Table columns={columns} data={apiData} />
      </div>
    </div>
  );
};

export default MedicineMaster;
