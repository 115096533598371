import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { loadMedicineList } from "./medicineList";
import { client } from "../../../utils/axios";

export const deleteMedicineListLoading = () => ({
  type: types.DELETE_MEDICINIE_LIST_LOADING,
});

export const deleteMedicineListSuccess = (payload) => ({
  type: types.DELETE_MEDICINIE_LIST_SUCCESS,
  payload,
});

export const deleteMedicineListError = (error) => ({
  type: types.DELETE_MEDICINIE_LIST_FAIL,
  payload: error,
});

export const deleteMedicineList = (data) => {
  return function (dispatch) {
    dispatch(deleteMedicineListLoading());
    client
      .post(
        `/doctor/remove-med-item`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(deleteMedicineListSuccess(resp.data));
        dispatch(loadMedicineList());
        toast.success("medicine deleted successfully");
      })
      .catch((error) => {
        dispatch(deleteMedicineListError(error.response));
      });
  };
};
