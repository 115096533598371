import React, { useState, useEffect } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../RadiologicalFindings/RadiologicalFindings.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import MultiSelect from '../../../../UIComponents/MultiSelect/MultiSelect'
import { useSelector, useDispatch } from 'react-redux'
import { getDoctorListValue } from '../../../../redux/actions/opd/patientreport';
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice'



function RadiologicalFindings() {
    const dispatch = useDispatch()
    const radiologicalList = useSelector(state => state.opdrecord.patientreport.listValue.radiologicalFinding)

    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)

    const [state, setState] = useState([])


    const onRadiologicalChange = (value) => {
        const arr = value.map(item => ({
            name: item
        }))
        dispatch(updateVisit({ ...visitInfo, radiologicalFinding: arr }))
    }

    useEffect(() => {
        dispatch(getDoctorListValue({ type: "radiologicalFinding" }))
    }, [])

    useEffect(() => {
        if (visitInfo?.radiologicalFinding) {
            const filteredData = visitInfo?.radiologicalFinding?.filter(item =>
                radiologicalList?.data?.list?.includes(item.name)
            )
            console.log("filteredData", filteredData)
            setState(filteredData)
        }
    }, [visitInfo?.radiologicalFinding, radiologicalList?.data?.list])

    return (
        <div>
            <div className='radiologicalFindings-div'>
                <NumberButton className="numberButton-7">7</NumberButton>
                <div className='radiologicalFindings-7-div'>
                    <h6>RADIOLOGICAL FINDINGS</h6>
                </div>
                <div className='radiologicalFindings-antSelect'>
                    <MultiSelect data={radiologicalList?.data?.list} value={state?.map(item => item.name)} onChange={onRadiologicalChange} placeholder="Select Radiological Findings" className={'radiological-findings-multiselect'} />
                </div>
            </div>
            <Divider />
        </div>
    )
}

export default RadiologicalFindings