import React, { useState, useEffect } from "react";
import "./Analytics.css";
import Analytictab from "../../components/Analytics/Analyticstabs/Analytictab";
import PrescriptionAnalysisExport from "./PrescriptionAnalysisExport";
import { useDispatch } from "react-redux/es/exports";
import { getProfileDetails } from "../../redux/actions/profile";
import useDoctorId from "../../utils/get-doctor-id";

const Analytics = () => {
  const [prescription, setPrescription] = useState(false);
  const dispatch = useDispatch();
  const doctorId = useDoctorId();
  console.log("doctorId", doctorId);

  useEffect(() => {
    dispatch(getProfileDetails({doctorId}));
  }, []);

  return (
    <div className="main-section">
      <div className="analytic-title-bg">
        <h1 className="analytic-title">ANALYTICS</h1>
        {/* <button onClick={() => setIsToggled(!isToggled)}>test</button> */}

        {prescription ? <PrescriptionAnalysisExport /> : null}
      </div>
      <div>
        <Analytictab setPrescription={setPrescription} />
      </div>
    </div>
  );
};

export default Analytics;
