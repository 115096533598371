import React from 'react'
import '../Iron/Iron.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import '../SemenAnylasis/SemenAnylasis.css'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const Haemotology = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'HAEMATOLOGY')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'HAEMATOLOGY'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
    <div className='iron-parent'>
        <div className='iron-left'>
            <div className='clinical-blood'>
                <div className='iron1 h-pad'></div>
                <div className='iron2 h-pad clinic'>HAEMOGLOBIN</div>
                <div className='iron3'><Input type='number' className={'iron-input'} name="HAEMOGLOBIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='iron4 h-pad'> gm/dl</div>
                <div className='iron5 h-pad'>11.5 to 16.4</div>
                <div className='iron6 h-pad'></div>
                <span className='iron-border'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad clinic'>TOTAL LEUKOCYTE COUNT</div>
                <div className=''><Input type='number' className={'iron-input'} name="TOTAL LEUKOCYTE COUNT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='h-pad'>/cumm</div>
                <div className=' h-pad'>4000 to 11000</div>
                <div className=' h-pad'></div>
                <span className='iron-border1'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>NEUTROPHILS</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="NEUTROPHILS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'> %</div>
                <div className=' g-pad'>45 to 75</div>
                <div className=' h-pad'></div>
                <span className='iron-border2'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>LYMPHOCYTES</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="LYMPHOCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='g-pad'>%</div>
                <div className=' g-pad'>20 to 50</div>
                <div className=' h-pad'></div>
                <span className='iron-border3'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>EOSINOPHILS</div>
                <div className='in-pad haemo-w'><Input type='number' className={'iron-input'} name="EOSINOPHILS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='g-pad'>%</div>
                <div className=' g-pad'>1 to 6</div>
                <div className=' h-pad'></div>
                <span className='iron-border4'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>MONOCYTES</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="MONOCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='g-pad'>%</div>
                <div className=' g-pad'>2 to 10</div>
                <div className=' h-pad'></div>
                <span className='iron-border5'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>BASOPHILS</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="BASOPHILS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'> %</div>
                <div className=' g-pad'>&lt; 1</div>
                <div className=' h-pad'></div>
                <span className='iron-border6'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>ESR (Wintrobe's)</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="ESR (Wintrobe's)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='g-pad'> mm first hour</div>
                <div className=' g-pad'>0 to 20</div>
                <div className=' h-pad'></div>
                <span className='iron-border7 '></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>PCV</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="PCV" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'> %</div>
                <div className=' g-pad'>36 to 47</div>
                <div className=' h-pad'></div>
                <span className='iron-border8'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>CORRECTED ESR</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="CORRECTED ESR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>mm first hour</div>
                <div className=' h-pad'></div>
                <div className=' h-pad'></div>
                <span className='iron-border9'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>MCH</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="MCH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>pg</div>
                <div className=' g-pad'>27 to 32</div>
                <div className=' h-pad'></div>
                <span className='iron-border10'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>MCHC</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="MCHC" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>gm/dl of RBC</div>
                <div className=' g-pad'>30 to 35</div>
                <div className=' h-pad'></div>
                <span className='iron-border11'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>MCV</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="MCV" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>fl</div>
                <div className=' g-pad'>76 to 96</div>
                <div className=' h-pad'></div>
                <span className='iron-border12'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>TRBC</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="TRBC" value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>millions/cumm</div>
                <div className=' g-pad'>4.5 to 6.5</div>
                <div className=' h-pad'></div>
                <span className='iron-border13'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>RETICULOCYTES</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="RETICULOCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>m%</div>
                <div className=' g-pad'>0.5 to 1.5</div>
                <div className=' h-pad'></div>
                <span className='iron-border14'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>PLATELETS COUNT</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="PLATELETS COUNT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[15]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>lacs/cumn</div>
                <div className=' g-pad'>1.5 to 4</div>
                <div className=' h-pad'></div>
                <span className='iron-border15'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>ABSOLUTE NEUTROPHILS COUNT</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="ABSOLUTE NEUTROPHILS COUNT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[16]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>/cumm</div>
                <div className=' g-pad'>1600 to 7500</div>
                <div className=' h-pad'></div>
                <span className='iron-border16'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>ABSOLUTE EOSINOPHILS COUNT</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="ABSOLUTE EOSINOPHILS COUNT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[17]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>/cumm</div>
                <div className=' g-pad'>40 to 440</div>
                <div className=' h-pad'></div>
                <span className='iron-border17'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>BLEEDING TIME</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="BLEEDING TIME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[18]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>minutes</div>
                <div className=' g-pad'>1 to 6</div>
                <div className=' h-pad'></div>
                <span className='iron-border18'></span>
                <div className=' h-pad haemo-w'>DIFFERENTIAL LEUKOCYTE COUNT</div>
                <div className=' h-pad clinic'>CLOTTING TIME</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="CLOTTING TIME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[19]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>minutes</div>
                <div className=' g-pad'>5 to 10</div>
                <div className=' h-pad'></div>
                <span className='iron-border19'></span>
                <div className=' h-pad haemo-w'>PROTHROMBIN TIME RATIO</div>
                <div className=' h-pad clinic'>PATIENT TIME</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="PATIENT TIME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[20]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>seconds</div>
                <div className=' g-pad'></div>
                <div className=' h-pad'></div>
                <span className='iron-border20'></span>
                <div className=' h-pad haemo-w'>PROTHROMBIN TIME RATIO</div>
                <div className=' h-pad clinic'>CONTROL TIME</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="CONTROL TIME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[21]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'>seconds</div>
                <div className=' g-pad'></div>
                <div className=' h-pad'></div>
                <span className='iron-border21'></span>
                <div className=' h-pad haemo-w'>PROTHROMBIN TIME RATIO</div>
                <div className=' h-pad clinic'>PROTHROMBIN TIME RATIO</div>
                <div className='in-pad'><Input type='number' className={'iron-input'} name="PROTHROMBIN TIME RATIO" value={reportDataFields?.testReport?.[indexValue]?.fields?.[22]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' g-pad'></div>
                <div className=' g-pad'></div>
                <div className=' h-pad'></div>
                <span className='iron-border22'></span>
                <div className='semen-pad iron7'>INTERNATIONAL NORMALISED RATIO</div>
                <div className='semen-text iron8 '><TextAreaAnt name="INTERNATIONAL NORMALISED RATIO" value={reportDataFields?.testReport?.[indexValue]?.fields?.[23]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
                <span className='iron-border23'></span>
                <div className='semen-pad iron9'>GBP FOR MP/MF</div>
                <div className='semen-text  iron10'><TextAreaAnt name="GBP FOR MP/MF" value={reportDataFields?.testReport?.[indexValue]?.fields?.[24]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
                <span className='iron-border24'></span>
                <div className='semen-pad iron11'>PMALARIAL CARD TEST (SEROLOGICAL)</div>
                <div className='semen-text  iron12'><TextAreaAnt name="PMALARIAL CARD TEST (SEROLOGICAL)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[25]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <span className='iron-border25'></span>
                <div className='semen-pad iron13'>MP FOR KIT</div>
                <div className='semen-text  iron14'><TextAreaAnt name="MP FOR KIT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[26]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <span className='iron-border26'></span>
               
            </div>
        </div>
            <div>
                <TestReportRightSection />
            </div>
    </div>

    <div className='iron-foot'>
        <ReportFooter />
    </div>
    <div className='irn-method'>
      <h4>Note:</h4>
      
      <p>These test have technical limitetion. Clinical Pathological interpretation is necessary <br />
in case of any discrepancy test may be repeated immediately. This Report is not for medicolegal purpose.</p>
    </div>
</div>
  )
}
