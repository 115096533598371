import React from 'react'
import './HariWidalTest.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import Input from '../../../../UIComponents/Input/Input'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function HariWidalTest() {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")
    const [ tableValueOne, setTableValueOne ] = useState("")

    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'Hari WIDAL TEST')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'Hari WIDAL TEST'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))
     }

      const onTableChange = (e) => {
        const { value, name } = e.target;
        console.log('onTableChange function', name, value)
        const [table, type, index] = name.split('.')
        console.log('table, type, index', table, type, index)
        const filterData = reportDataFields?.testReport?.filter(item => item.test === 'Hari WIDAL TEST')
        let ind = 0
        reportDataFields.testReport.map((item, i) => {
          if(item.test === 'Hari WIDAL TEST'){
            ind = i
          }
          return item
        })
        console.log("index",ind,filterData[0].table);
        const fill = filterData[0].table.filter(item => item.particular == table)
        console.log("filtered filter",fill);
        const finaldataaa = fill[0]?.data.map((item,i) => {
          if(i == index){
            console.log("finallllldaata",item,index,i);
            return {
              ...item,
              [type]: value
            }
          }return item
        })
        fill[0].data = finaldataaa
        console.log("finaldata",reportDataFields); 
        setTableValueOne(reportDataFields?.testReport?.[ind]?.table?.[0]?.data)
        dispatch(updateReports(reportDataFields))
      }

  return (
    <div>
      <div className="hariWidalTest-parent">
        <div className="hariWidalTest-left">
          <div className="hariWidalTest-grid">
            <div className="hariWidalTest-1 hariWidalTest-pad"onClick={() => onLabelClicked("INTERPRETATION")}>INTERPRETATION</div>
            <div className="hariWidalTest-2 hariWidalTest-text">
              <TextAreaAnt name="INTERPRETATION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} onChange={onInputChange} disabled={!reportDataFields.active}  />
            </div>
            <span className="hariWidalTest-border-text"></span>
          </div>
<br/>
          <div className='hariWidalTest-grid-two'>
            <div className="hariWidalTest-1 hariWidalTest-pad">Date of OPU</div>
            <div className="hariWidalTest-2 hariWidalTest-pad">Patient ID</div>
            <div className="hariWidalTest-3 hariWidalTest-pad">Patient name & Blood Group</div>
            <div className="hariWidalTest-4 hariWidalTest-pad">Partner Name & Blood Group</div>
            <div className="hariWidalTest-5 hariWidalTest-pad">Stimulation</div>
            <div className="hariWidalTest-6 hariWidalTest-pad">Pick up needle</div>
            <div className="hariWidalTest-7 hariWidalTest-pad">Ovum Pick-Up By</div>
            <div className="hariWidalTest-8 hariWidalTest-pad">Ovum Pick- Up Witness</div>

            <div className="hariWidalTest-9 hariWidalTest-pad">Screening by</div>
            <div className="hariWidalTest-10 hariWidalTest-pad">Screening Witness</div>
            <div className="hariWidalTest-11 hariWidalTest-pad">COCs Retrieved</div>
            <div className="hariWidalTest-12 hariWidalTest-pad">IVF /ICSI</div>
            <div className="hariWidalTest-13 hariWidalTest-pad">Embryologist</div>
            <div className="hariWidalTest-14 hariWidalTest-pad">Witness</div>
            <div className="hariWidalTest-15 hariWidalTest-pad">Denuding time</div>
            <div className="hariWidalTest-16 hariWidalTest-pad">MIIs</div>

            <div className="hariWidalTest-17 hariWidalTest-pad">Incubator</div>
            <div className="hariWidalTest-18 hariWidalTest-pad">Semen Source</div>
            <div className="hariWidalTest-19 hariWidalTest-pad">Semen Sample Preparation</div>
            <div className="hariWidalTest-20 hariWidalTest-pad">ICSI time</div>
            <div className="hariWidalTest-21 hariWidalTest-pad">2 PNs(16-18 hr)</div>
            <div className="hariWidalTest-22 hariWidalTest-pad">2 cell(24 hrs)</div>
            <div className="hariWidalTest-23 hariWidalTest-pad">4 Cell (42-44hrs)</div>
            <div className="hariWidalTest-24 hariWidalTest-pad">8 cell(66 hrs)</div>

            <div className="hariWidalTest-25 hariWidalTest-pad">Morula</div>
            <div className="hariWidalTest-26 hariWidalTest-pad">Blastocyst</div>
            <div className="hariWidalTest-27 hariWidalTest-pad">No. Of Embryos Frozen</div>
            <div className="hariWidalTest-28 hariWidalTest-pad">Embryo Grading</div>
            <div className="hariWidalTest-29 hariWidalTest-pad">DATE OF ET</div>
            <div className="hariWidalTest-30 hariWidalTest-pad">ET BY</div>
            <div className="hariWidalTest-31 hariWidalTest-pad">Dish Witness</div>
            <div className="hariWidalTest-32 hariWidalTest-pad">Catheter Used</div>
            <div className='hariWidalTest-33 hariWidalTest-pad'>No of Embryos transfered</div>
            <div className='hariWidalTest-34 hariWidalTest-pad'>Embryo Grading</div>
            <span className="hariWidalTest-border"></span>

            <div className='hariWidalTest-one-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-one-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-one-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-one-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-one-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-one-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-one-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-one-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-one-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-1"></span>

            <div className='hariWidalTest-two-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-two-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-two-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-two-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-two-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-two-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-two-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-two-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-two-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-2"></span>

            <div className='hariWidalTest-three-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-three-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-three-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-three-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-three-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-three-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-three-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-three-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-three-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-3"></span>

            <div className='hariWidalTest-four-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-four-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-four-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-four-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-four-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-four-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-four-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-four-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-four-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-4"></span>

            <div className='hariWidalTest-five-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-five-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-five-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-five-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-five-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-five-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-five-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-five-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-five-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.4" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-5"></span>

            <div className='hariWidalTest-six-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-six-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-six-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-six-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-six-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-six-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-six-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-six-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-six-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.5" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-6"></span>

            <div className='hariWidalTest-seven-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-seven-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-seven-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-seven-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-seven-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-seven-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-seven-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-seven-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-seven-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.6" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-7"></span>

            <div className='hariWidalTest-eight-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-eight-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-eight-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-eight-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-eight-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-eight-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-eight-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-eight-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-eight-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.7" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-8"></span>

            <div className='hariWidalTest-nine-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-nine-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-nine-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-nine-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-nine-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-nine-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-nine-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-nine-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-nine-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.8" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-9"></span>

            <div className='hariWidalTest-ten-input-1' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofopu.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.dateofopu} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-ten-input-2' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.patientid.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.patientid} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-ten-input-3' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ptname.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.ptname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-ten-input-4' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.prname.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.prname} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-ten-input-5' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.stimulation.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.stimulation} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-ten-input-6' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.pickupneedle.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.pickupneedle} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-ten-input-7' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickby.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.ovumpickby} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
            <div className='hariWidalTest-ten-input-8' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ovumpickwitness.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.ovumpickwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-9' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningby.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.screeningby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-10' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.screeningwitness.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.screeningwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-11' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.cocsretrieved.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.cocsretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-12' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.ivficsi.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.ivficsi} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-13' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryologist.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.embryologist} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-14' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.witness.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.witness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-15' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.denudingtime.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.denudingtime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-16' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.miis.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.miis} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-17' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.incubator.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.incubator} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-18' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensource.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.semensource} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-19' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.semensamplepreparation.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.semensamplepreparation} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-20' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.icsitime.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.icsitime} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-21' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2pns.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.['2pns']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-22' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.2cell.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.['2cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-23' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.4cell.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.['4cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-24' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.8cell.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.['8cell']} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-25' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.morula.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.morula} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-26' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.blastocyst.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.blastocyst} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-27' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryosfrozen.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.noofembryosfrozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-28' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-29' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.etby.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.etby} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-30' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dateofet.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.dateofet} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-31' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.dishwitness.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.dishwitness} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-32' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.catheterused.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.catheterused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-33' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.noofembryostransfered.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.noofembryostransfered} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <div className='hariWidalTest-ten-input-34' ><Input type='number' className='hariWidalTest-input' type="text" name="MASTER.embryograding.9" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[9]?.embryograding} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
            <span className="hariWidalTest-border-10"></span>


          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="hariWidalTest-foot">
        <ReportFooter />
      </div>
    </div>
  )
}

export default HariWidalTest
