import React from 'react'
import './Csf.css'
import Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'

const Csf = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const singleReport = useSelector(state => state.diagnosticsInvoiceSummary.getdiagnostic?.testReport)
  const setDiagnosticData = useSelector(state => state?.diagnosticsInvoiceSummary)
  const [ inputValue, setInputValue ] = useState("")
  
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
   const onInputChange = (e) => {
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'CSF (CEREBRO SPINAL FLUID)')
    let index = 0
    reportDataFields.testReport.map((item, i) => {
      if(item?.test === 'CSF (CEREBRO SPINAL FLUID)'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  

    return (
        <div>
            <div className='csf-main'>
                <div className='csf-container'>
                    <div className='csf-grid-container'>
                        <div className='padd csf-1'>CHEMICAL EXAMINATION</div>
                        <div className='padd csf-2'>PROTEIN</div>
                        <Input className='aptinputs csf-3' type="text" name="PROTEIN" onChange={onInputChange} value={reportDataFields?.testReport[indexValue]?.fields[0]?.result}  disabled={!reportDataFields.active} />
                        <div className='padd csf-4'>mg/dl</div>
                        <div className='padd csf-5'></div>
                        <div className='padd csf-6'></div>
                        <span className='bil-border1'></span>
                        <div className='padd csf-7'>CHEMICAL EXAMINATION</div>
                        <div className='padd csf-8'>ADA(Adenosine Deaminage)</div>
                       <div className='csf-9'> <Input className='aptinputs' type="text" name="ADA(Adenosine Deaminage)" onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active} /></div>
                        <div className='padd csf-10'>U/L</div>
                        <div className='padd csf-11'>11</div>
                        <div className='padd csf-12'></div>
                        <span className='bil-border2'></span>
                        <div className='padd csf-13'>CHEMICAL EXAMINATION</div>
                        <div className='padd csf-14'>SUGAR</div>
                      <div className='csf-15'> <Input className='aptinputs' type="text" name="SUGAR" onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active} /></div>
                        <div className='padd csf-16'>mg/dl</div>
                        <div className='padd csf-17'></div>
                        <div className='padd csf-18'></div>
                        <span className='bil-border3'></span>
                        <div className='padd csf-19'>CYTOLOGICAL EXAMINATION</div>
                        <div className='padd csf-20'>TOTAL LEUCOCYTE</div>
                      <div className='csf-21'> <Input className='aptinputs' type="text" name="TOTAL LEUCOCYTE" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result}  disabled={!reportDataFields.active} /></div>
                        <div className='padd csf-22'>cells/cu mm</div>
                        <div className='padd csf-23'></div>
                        <div className='padd csf-24'></div>
                        <span className='bil-border4'></span>
                        <div className='padd csf-25'></div>
                        <div className='padd csf-26'>Lymphocytes</div>
                      <div className='csf-27'> <Input className='aptinputs' type="text" name="Lymphocytes" onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result}  disabled={!reportDataFields.active}/></div>
                        <div className='padd csf-28'>%</div>
                        <div className='padd csf-29'></div>
                        <div className='padd csf-30'></div>
                        <span className='bil-border5'></span>
                        <div className='padd csf-31'></div>
                        <div className='padd csf-32'>Polymorphs</div>
                      <div className='csf-33'> <Input className='aptinputs' type="text" name="Polymorphs" onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result}  disabled={!reportDataFields.active}/></div>
                        <div className='padd csf-34'>%</div>
                        <div className='padd csf-35'></div>
                        <div className='padd csf-36'></div>
                        <span className='bil-border6'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}



export default Csf