import React, { useEffect, useState } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import SingleSelect from '../../../../UIComponents/Select/SingleSelect'
import '../Template/Template.css'
import CommonButton from '../../../../UIComponents/Button/Button'
import InputField from '../../../../UIComponents/Input/Input'
import { Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTemplate } from '../../../../redux/actions/opd/patientreport'
import { templateSaved } from '../../../../redux/actions/opd/patientreport'
import { templateReplicated } from '../../../../redux/actions/opd/patientreport'
import { toast } from '../../../../UIComponents/Toast'


function Template() {
    let dispatch = useDispatch()
    const profileInfo = useSelector(state => state.profile.profileInfo)
    console.log("profile info in opd", profileInfo);
    const templete = profileInfo?.templates?.map(item => item.key)
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    console.log("hnhgfjhfh", visitInfo)
    const selectedPatientInfo = useSelector(state => state?.opdrecord?.patientlist?.selectedPatient)
    console.log("selectedPatientInfo", selectedPatientInfo)
    const replicated = useSelector(state => state.opdrecord.patientreport.replicated)
    console.log("replicated", replicated)

    const [tempValue, setTempValue] = useState('')
    const [newName, setNewName] = useState()

    const templateValueHandler = (value) => {
        console.log("valugdfgdfe", value)
        setTempValue(value)
    }

    const deleteHandler = () => {
        if (!tempValue) {
            toast.error('Please select template')
        }
        else {
            dispatch(deleteTemplate({ template: tempValue }))
            setTempValue('')
        }
    }

    const inputChangeHandler = (e) => {
        setNewName(e.target.value)
    }

    // patientNote


    const newTemplate = {
        key: newName,
        value: {
            medicines: visitInfo?.medicines,
            diagnosis: visitInfo?.diagnosis,
            allergens: visitInfo?.allergens,
            clinicalFinding: visitInfo?.clinicalFinding,
            lab: visitInfo?.lab,
            radiologicalFinding: visitInfo?.radiologicalFinding,
            specialNote: visitInfo?.specialNote,
            procedure: visitInfo?.procedure,
            narratives: visitInfo?.variableForNarratives,
        }
    }

    console.log("newtemplate", newTemplate)

    const saveHandler = () => {
        if (!newTemplate.key) {
            toast.error('please enter new template name')
        }
        else if (!newTemplate.value.medicines) {
            toast.error('please enter medicines')
        }
        else {
            dispatch(templateSaved(newTemplate))
            setNewName('')
        }
    }

    const applyHanlder = () => {
        dispatch(templateReplicated({ patientId: selectedPatientInfo?.patientId, template: tempValue }))
        setTempValue('')
    }
    return (
        <div>
            <div className='template-div'>
                <NumberButton>2</NumberButton>
                <div className='template-2-div'>
                    <h6>TEMPLATE</h6>
                </div>
                <div className='template-antSelect'>
                    <SingleSelect data={templete || []} placeholder="Select template" className={'template-multiselect'} value={tempValue || undefined} onChange={templateValueHandler} />
                </div>
                <CommonButton className={'template-apply-btn'} onClick={applyHanlder} >APPLY</CommonButton>
                <CommonButton className={'template-delete-btn'} onClick={deleteHandler}>DELETE</CommonButton>
                <div className='template-antSelect'>
                    <InputField placeholder="New Template Name" value={newName} className={'template-input'} onChange={inputChangeHandler} />
                </div>
                <CommonButton className={'template-save-btn'} onClick={saveHandler}>SAVE</CommonButton>
            </div>
            <Divider width="90%" margin="0px" />
        </div>
    )
}

export default Template