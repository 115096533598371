import React, { useEffect, useState } from 'react'
import './Mcu.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { useDispatch, useSelector } from 'react-redux'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function Mcu() {
  const dispatch = useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [inputValue, setInputValue] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'M.C.U.')
    console.log("filterData",filterData);
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'M.C.U.'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[0]?.fields);
    setInputValue(reportDataFields?.testReport[0]?.fields)
    dispatch(updateReports(reportDataFields))
    console.log("saveReportss",inputValue);
  }

  const onLabelClicked = (value) => {
    dispatch(field({fieldKey:value}))  }

  return (
    <div>
      <div className="mcu-parent">
        <div className="mcu-left">
          <div className="mcu-grid">
            <div className="mcu-1 mcu-pad" onClick={() => onLabelClicked("MICTURATING CYSTO")}>MICTURATING CYSTO</div>
            <div className="mcu-2 mcu-text">
              <TextAreaAnt name='MICTURATING CYSTO' value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="mcu-border"></span>
            <div className=" mcu-pad">IMPRESSION</div>
            <div className=" mcu-text">
              <TextAreaAnt name='IMPRESSION'value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="mcu-border1"></span>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="mcu-foot">
        <ReportFooter />
      </div>


    </div>
  )
}

export default Mcu
