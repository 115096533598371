import React from 'react'
import '../SputumAFB/Sputum.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const XrayCervical = (e) => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'X-RAY CERVICAL SPINE AP/LAT')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'X-RAY CERVICAL SPINE AP/LAT'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
   filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))      }
    
  return (
    <div>
        
        <div className='sputum-parent'>
          <div className='sputum-left'>
              <div className='sputum-grid'>
                  <div className='sputum1 sputum-pad' onClick={() => onLabelClicked("C.SPINE ")}>C.SPINE</div>
                  <div className='sputum2 sputum-text'><TextAreaAnt name="C.SPINE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                  <span className='sputum-border'></span>
                  <div className='sputum-pad' onClick={() => onLabelClicked("IMPRESSION ")}>IMPRESSION</div>
                  <div className='sputum-text '><TextAreaAnt name="IMPRESSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                  <span className='sputum-border2'></span>
              </div>
          </div>
          <div>
              <TestReportRightSection />
          </div>
        </div>
        <div className='sputum-foot'>
            <ReportFooter />
        </div>
    </div>
  )
}
