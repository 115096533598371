import React, { useRef, useEffect, useState } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../Files/File.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import Button from '../../../../UIComponents/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedDocuments, getSelectedImages, uploadVisitImage, uploadVisitPdf, viewVisitImage } from '../../../../redux/actions/opd/patientreport'
import { toast } from '../../../../UIComponents/Toast'
import { deleteUploadImage } from '../../../../redux/actions/opd/patientreport'
import { Modal } from 'antd'
import moment from 'moment'



function File() {
    const imageRef = useRef();
    const fileRef = useRef();
    const dispatch = useDispatch()
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    const images = useSelector(state => state.opdrecord.patientreport.images)
    console.log("imagesindociments", images)
    const previousVisits = useSelector(state => state.opdrecord.patientreport.previousVisits)
    const scannedImages = useSelector(state => state.opdrecord.patientreport.selectedImages)
    const scannedDocuments = useSelector(state => state.opdrecord.patientreport.selectedDocuments)
    const opdReportInfo = useSelector(state => state.opdrecord.patientreport.opdReport)
    // const fileName = useSelector(state => state.opdrecord.patientreport.fileName)




    const [fileName, setFileName] = useState([])
    const [visibleImages, setVisibleImages] = useState(false)
    const [visibleDocuments, setVisibleDocuments] = useState(false)
    const [imageView, setImageView] = useState(false)
    const [singleImage, setSingleImage] = useState(null)



    const changeImageHandler = (e) => {
        console.log("images in onChange", e.target.files)
        const fileExtension = e.target.files[0].name.split('.').pop();
        let fileSize = e.target.files[0].size
        let formData = new FormData();
        if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('visitId', visitInfo?._id)
                formData.append('files', e.target.files[i], e.target.files[i].name)

            }
            dispatch(uploadVisitImage(formData, visitInfo?._id))
            e.target.value = null

        } else {
            toast.error("Please select an image")
            e.target.value = null
        }


    }
    const changeFileHandler = (e) => {
        console.log("files in onChange", e.target.files[0].name)
        let formData = new FormData();
        const fileExtension = e.target.files[0].name.split('.').pop();
        if (fileExtension === "pdf") {
            for (let i = 0; i < e.target.files.length; i++) {
                setFileName([...fileName, e.target.files[i].name])
                formData.append('visitId', visitInfo?._id)
                formData.append('file', e.target.files[i], e.target.files[i].name)

            }
            dispatch(uploadVisitPdf(formData, visitInfo?._id))
            e.target.value = null
        } else {
            toast.error("Please select pdf")
            e.target.value = null

        }

    }



    const removePdf = (index) => {
        setFileName(fileName.filter((item, i) => i !== index))
    }
    const deleteUploadedImage = (fileName) => {
        dispatch(deleteUploadImage({ fileName, visitId: visitInfo?._id }))
    }
    const patientImagesArray = []
    previousVisits?.map((item, i) => {
        if (item.fileName) {
            item.fileName.map(e => {
                return patientImagesArray.push({ visitDate: item.visitStart, files: e })
            })
        }
    })
    opdReportInfo?.visit?.fileName?.map((item) => {
        return patientImagesArray.push({ visitDate: opdReportInfo.visit.scheduledTime, files: item })
    })

    const selectedImagesModal = () => {
        setVisibleImages(true)
        dispatch(getSelectedImages({ files: patientImagesArray }))

    }
    console.log("patientImagesArray", patientImagesArray)
    let d = [];
    scannedImages?.images?.map(item => {
        let m = patientImagesArray?.find(c => { return c.files === item.key });
        d.push({ url: item.url, key: item.key, date: m?.visitDate });
    });

    const groupBy = (collection, property) => {
        var i = 0, val, index,
            values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    }
    let patientImagesArrayURLs = groupBy(d, "date");
    console.log("patientImagesArrayURLs", patientImagesArrayURLs)
    let revPatientImagesArrayURLs = [...patientImagesArrayURLs].reverse()


    const patientDocumentsArray = []
    previousVisits?.map((item, i) => {
        if (item.documentName) {
            item.documentName.map(e => {
                return patientDocumentsArray.push({ visitDate: item.visitStart, files: e })
            })
        }
    })
    opdReportInfo?.visit?.documentName?.map((item) => {
        return patientDocumentsArray.push({ visitDate: opdReportInfo.visit.scheduledTime, files: item })
    })
    console.log("patientDocumentsArray", patientDocumentsArray)
    const selectedDocumentsModal = () => {
        setVisibleDocuments(true)
        dispatch(getSelectedDocuments({ files: patientDocumentsArray }))
    }

    let doc = [];
    scannedDocuments?.documents?.map(item => {
        let m1 = patientDocumentsArray?.find(c => { return c.files === item.key });
        doc.push({ url: item.url, key: item.key, date: m1?.visitDate });
    });
    let patientDocumentsArrayURLs = groupBy(doc, "date");
    console.log("patientDocumentsArrayURLs", patientDocumentsArrayURLs)
    let revPatientDocumentsArrayURLs = [...patientDocumentsArrayURLs].reverse()

    useEffect(() => {
        patientImagesArrayURLs?.sort((a, b) => {
            return ((a.date) > (b.date)) ? -1 : (((b.date) > (a.date)) ? 1 : 0)
        });

    }, [patientImagesArrayURLs])
    useEffect(() => {
        patientDocumentsArrayURLs?.sort((a, b) => {
            return ((moment(a.date).format("YYYY-MM-DD")) > (moment(b.date).format("YYYY-MM-DD"))) ? -1 : (((moment(b.date).format("YYYY-MM-DD")) > (moment(a.date).format("YYYY-MM-DD"))) ? 1 : 0)
        });

    }, [patientDocumentsArrayURLs])
    useEffect(() => {
        setFileName([])
    }, [visitInfo?._id])
    function add3Dots(string) {
        var dots = "...";
        var limit = 15
        if (string ? string.length > limit : "") {
            string = string.substring(0, limit) + dots;
        }

        return string;
    }
    const viewSingleImage = (id) => {
        setImageView(true)
        setSingleImage(images.images.find(item => item.key === id))
        console.log("singleImage", singleImage)
    }
    return (
        <div>
            <div className='file-div'>
                <div className='upload-visit-sec'>
                    <div className="file-upload-num"><NumberButton className="numberButton-13">13</NumberButton></div>

                    <div className='opd-file-img-button-sec'>
                        <div className='opd-file-img-button-sec-1'>
                            <Button onClick={() => imageRef.current.click()} >
                                UPLOAD IMAGE
                            </Button>
                            <input type='file' onChange={changeImageHandler} multiple ref={imageRef} accept="image/*" hidden />

                        </div>
                        <div className='opd-file-img-button-sec-1'>
                            <Button onClick={() => fileRef.current.click()} >
                                UPLOAD FILES

                            </Button>
                            <input type='file' onChange={changeFileHandler} accept=".pdf" multiple ref={fileRef} hidden />

                        </div>
                        <div className='opd-file-img-button-sec-1'>
                            <Button onClick={selectedImagesModal}>
                                All Images
                            </Button>
                        </div>
                        <div className='opd-file-img-button-sec-1'>
                            <Button onClick={selectedDocumentsModal}>
                                All Documents
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='upload-visit-sec'>
                    <div className="file-upload-num-1"></div>
                    <div className='opd-file-img-button-sec-1'>

                    </div>

                    <div className='file-upload-image'>
                        {fileName?.map((item, i) => (<p><span>{add3Dots(item)}</span><span className='opd-pdf-remove' onClick={() => removePdf(i)}>X</span></p>))}
                    </div>
                </div>
                {images && images?.images?.length > 0 ?
                    <div className='opd-image-sec'>
                        {images?.images.map(item => <div>
                            <p className='opd-image-del' onClick={() => deleteUploadedImage(item.key)}>Delete</p>
                            <img style={{ "width": "150px", "height": "80px" }} src={item.url} alt="upload images" onClick={() => viewSingleImage(item.key)} />
                        </div>)}
                    </div>
                    : ""}
            </div>
            <Divider />
            {
                visibleImages && <Modal
                    title="All Scanned Images"
                    centered
                    visible={visibleImages}
                    onOk={() => setVisibleImages(false)}
                    onCancel={() => setVisibleImages(false)}
                    width={1000}
                >
                    {revPatientImagesArrayURLs.length ? <div className='selected-images-modal'>
                        {revPatientImagesArrayURLs?.map((item) => <div className='selcted-image-date-sec'><p>{moment(item[0].date).format("YYYY-MM-DD")}</p>{item.map((e) => <img src={e.url} />)}</div>)}

                    </div> : ""}
                </Modal>
            }
            {
                visibleDocuments && <Modal
                    title="All Scanned Documents"
                    centered
                    visible={visibleDocuments}
                    onOk={() => setVisibleDocuments(false)}
                    onCancel={() => setVisibleDocuments(false)}
                    width={1000}
                >
                    {revPatientDocumentsArrayURLs.length ? <div className='selected-images-modal'>
                        {revPatientDocumentsArrayURLs?.map((item) => <div className='selected-document-sec'><p>{moment(item[0].date).format("YYYY-MM-DD")}</p><div className='opd-selected-doc'>{item.map((e) => <div className='pdf-show-sec'><div style={{ marginRight: "20px" }}><embed src={e.url} /></div> <div><a href={e.url} target="_blank" >View</a></div></div>)}</div></div>)}
                    </div> : ''}
                </Modal>
            }
            {
                imageView &&
                <Modal
                    centered
                    visible={imageView}
                    onOk={() => setImageView(false)}
                    onCancel={() => setImageView(false)}
                    width={1000}
                >
                    <div className='single-image-sec'>
                        <img style={{ width: "100%" }} src={singleImage.url} />
                    </div>

                </Modal>
            }

        </div>
    )
}

export default File