import { toast } from "../../../UIComponents/Toast";
import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

export const resetAddedData = (payload) => ({
  type: types.RESET_ADDED_DATA,
  payload,
});
export const selectHandle = (payload) => ({
  type: types.SELECT_HANDLE,
  payload,
});
export const resetGetDiagnostics = (payload) => ({
  type: types.RESET_GET_DIAGNOSTICS,
  payload,
});
export const getPatientDetails = (payload) => ({
  type: types.GET_PATIENT_DETAILS,
  payload,
});
export const toggleButton = (payload) => ({
  type: types.TOGGLE_BUTTON,
  payload,
});
export const getPatietClick = (data) => ({
  type: types.GET_PATIENT_CLICK,
  payload: data,
});
export const addServiceDiagnostic = (data) => ({
  type: types.ADD_DIAGNOSTIC_SERVICE,
  payload: data,
});
export const reportName = (data) => ({
  type: types.REPORT_NAME,
  payload: data,
});

export const createDiagnostics = (data) => ({
  type: types.CREATE_DIAGNOSTIC_SERVICE,
  payload: data,
});
export const genderName = (data) => ({
  type: types.GENDER_NAME,
  payload: data,
});
export const updateServiceDiagnostic = (data) => ({
  type: types.UPDATE_DIAGNOSTIC_SERVICE,
  payload: data,
});
export const saveReports = (data) => ({
  type: types.SAVE_REPORT,
  payload: data,
});
export const imageUrl = (data) => ({
  type: types.IMAGE_URL,
  payload: data,
});
export const updateReports = (data) => ({
  type: types.UPDATE_REPORT,
  payload: data,
});

const getAllDiagnosticLoading = () => ({
  type: types.GET_ALL_DIAGNOSTIC_LOADING,
});

const getAllDiagnosticSuccess = (summary) => ({
  type: types.GET_ALL_DIAGNOSTIC_SUCCESS,
  payload: summary,
});

const getAllDiagnosticError = (error) => ({
  type: types.GET_ALL_DIAGNOSTIC_FAIL,
  payload: error,
});
//close diagnosti'cs
const closeDiagnosticLoading = () => ({
  type: types.CLOSE_DIAGNOSTICS_LOADING,
});

const closeDiagnosticSuccess = (diagnosticlist) => ({
  type: types.CLOSE_DIAGNOSTICS_SUCCESS,
  payload: diagnosticlist,
});

const closeDiagnosticError = (error) => ({
  type: types.CLOSE_DIAGNOSTICS_FAIL,
  payload: error,
});
// get all diagnostic
const getAllDiagnosticListLoading = () => ({
  type: types.GET_ALL_DIAGNOSTIC_LIST_LOADING,
});

const getAllDiagnosticListSuccess = (summary) => ({
  type: types.GET_ALL_DIAGNOSTIC_LIST_SUCCESS,
  payload: summary,
});

const getAllDiagnosticListError = (error) => ({
  type: types.GET_ALL_DIAGNOSTIC_LIST_FAIL,
  payload: error,
});
// get diagnostic
const getDiagnosticLoading = () => ({
  type: types.GET_DIAGNOSTIC_LOADING,
});

const getDiagnosticSuccess = (summary) => {
  console.log("log of get daignostics");
  return {
    type: types.GET_DIAGNOSTIC_SUCCESS,
    payload: summary,
  };
};

const getDiagnosticError = (error) => ({
  type: types.GET_DIAGNOSTIC_FAIL,
  payload: error,
});
export const field = (data) => ({
  type: types.FIELD,
  payload: data,
});
export const singleReportData = (data) => ({
  type: types.SINGLE_REPORT_DATA,
  payload: data,
});
export const indexValue = (data) => ({
  type: types.INDEX_VALUE,
  payload: data,
});

// get diagnostic profile
const getDiagnosticProfileLoading = () => ({
  type: types.GET_DIAGNOSTIC_PROFILE_LOADING,
});
const getDiagnosticProfileSuccess = (getDiagnosticsProfile) => ({
  type: types.GET_DIAGNOSTIC_PROFILE_SUCCESS,
  payload: getDiagnosticsProfile,
});
const getDiagnosticProfileError = (error) => ({
  type: types.GET_DIAGNOSTIC_PROFILE_FAIL,
  payload: error,
});
//save diagnostic report
const saveDiagnosticReportLoading = () => ({
  type: types.SAVE_DIAGNOSTIC_REPORT_LOADING,
});
const saveDiagnosticReportSuccess = (saveDiagnosticReport) => ({
  type: types.SAVE_DIAGNOSTIC_REPORT_SUCCESS,
  payload: saveDiagnosticReport,
});
const saveDiagnosticReportError = (error) => ({
  type: types.SAVE_DIAGNOSTIC_REPORT_FAIL,
  payload: error,
});

export const deleteApiImage = (payload) => ({
  type: types.DELETE_API_IMAGE,
  payload,
});

// REOPEN DIAGNOSTICS REPORTS
const reopenDiagnosticLoading = () => ({
  type: types.REOPEN_DIAGNOSTIC_REPORT_LOADING,
});
const reopenDiagnosticSuccess = (reopenDiagnostic) => ({
  type: types.REOPEN_DIAGNOSTIC_REPORT_SUCCESS,
  payload: reopenDiagnostic,
});
const reopenDiagnosticError = (error) => ({
  type: types.REOPEN_DIAGNOSTIC_REPORT_FAIL,
  payload: error,
});
// PRINT TEST REPORT
const printTestReportLoading = () => ({
  type: types.PRINT_TEST_REPORTS_LOADING,
});
const printTestReportSuccess = (printTestReport) => ({
  type: types.PRINT_TEST_REPORTS_SUCCESS,
  payload: printTestReport,
});
const printTestReportError = (error) => ({
  type: types.PRINT_TEST_REPORTS_FAIL,
  payload: error,
});
//PRINT DIIAGNOSTICS INVOICE
const printDiagnosticInvoiceLoading = () => ({
  type: types.PRINT_DIAGNOSTICS_INVOICE_LOADING,
});
const printDiagnosticInvoiceSuccess = (printDiagnosticsInvoice) => ({
  type: types.PRINT_DIAGNOSTICS_INVOICE_SUCCESS,
  payload: printDiagnosticsInvoice,
});
const printDiagnosticInvoiceError = (error) => ({
  type: types.PRINT_DIAGNOSTICS_INVOICE_FAIL,
  payload: error,
});

// add bank diagnostics
const addBankDiagnosticsLoading = () => ({
  type: types.ADD_BANK_DIAGNOSTICS_LOADING,
});
const addBankDiagnosticsSuccess = (addBankDiagnostic) => ({
  type: types.ADD_BANK_DIAGNOSTICS_SUCCESS,
  payload: addBankDiagnostic,
});
const addBankDiagnosticsError = (error) => ({
  type: types.ADD_BANK_DIAGNOSTICS_FAIL,
  payload: error,
});

// send sms diagnostics
const sendSmsDiagnosticsLoading = () => ({
  type: types.SEND_SMS_DIAGNOSTICS_LOADING,
});
const sendSmsDiagnosticsSuccess = (addBankDiagnostic) => ({
  type: types.SEND_SMS_DIAGNOSTICS_SUCCESS,
  payload: addBankDiagnostic,
});
const sendSmsDiagnosticsError = (error) => ({
  type: types.SEND_SMS_DIAGNOSTICS_FAIL,
  payload: error,
});

// get diagnostic list

export const getAllDiagnostic = (data) => {
  return (dispatch) => {
    dispatch(getAllDiagnosticLoading());

    client
      .post(
        `/diagnostic/get-dates-diagnostic`,
        data
        // { headers: { Authorization: `Bearer ${secure_token}` } }
        // {
        //   withCredentials: true,
        // }
      )
      .then((resp) => {
        dispatch(getAllDiagnosticSuccess(resp.data));
        toast.success("Data fetched Successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getAllDiagnosticError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

//close diagnostics

export const closeDiagnostics = (data, doctorId, getDate) => {
  return (dispatch) => {
    dispatch(closeDiagnosticLoading());

    client
      .post(
        `/diagnostic/close-diagnostic`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(closeDiagnosticSuccess(resp.data));
        dispatch(getAllDiagnostic({ doctorId, transactionDate: getDate }));
        dispatch(
          getDiagnostics({
            doctorId: data.doctorId,
            transactionId: data.transactionId,
          })
        );
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(closeDiagnosticError(error));
      });
  };
};

// get all diagnostic

export const getAllDiagnosticlist = (data) => {
  return (dispatch) => {
    dispatch(getAllDiagnosticListLoading());

    client
      .post(
        `/diagnostic/get-all-diagnostic-list`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getAllDiagnosticListSuccess(resp.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getAllDiagnosticListError(error));
      });
  };
};
// get diagnostic

export const getDiagnostics = (data) => {
  return (dispatch) => {
    dispatch(getDiagnosticLoading());
    client
      .post(
        `/diagnostic/get-diagnostic`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getDiagnosticSuccess(resp.data));
        dispatch(updateServiceDiagnostic(resp.data));
        console.log("getdksk", resp.data);
        toast.success("Report fetched Successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getDiagnosticError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};
// get diagnostic profile
export const getDiagnosticProfile = (doctorId) => {
  return (dispatch) => {
    dispatch(getDiagnosticProfileLoading());
    client
      .get(
        `/diagnostic/get-diagnostic-profile?doctorId=${doctorId}`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getDiagnosticProfileSuccess(resp.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getDiagnosticProfileError(error));
      });
  };
};

// save diagnostic report

export const saveDiagnosticReport = (data, doctorId, newtransactionId) => {
  console.log("jnejnkjneje", doctorId);
  return (dispatch) => {
    dispatch(saveDiagnosticReportLoading());
    client
      .post(
        `/diagnostic/save-diagnostic-report`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(saveDiagnosticReportSuccess(resp.data));
        dispatch(
          printTestReports({ doctorId, transactionId: newtransactionId })
        );

        toast.success("Report saved Successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(saveDiagnosticReportError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// reopen diagnostics reports

export const reopenDiagnostics = (data, doctorId, getDate) => {
  return (dispatch) => {
    dispatch(reopenDiagnosticLoading());
    client
      .post(
        `/diagnostic/reopen-diagnostic`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(reopenDiagnosticSuccess(resp.data));
        dispatch(getAllDiagnostic({ doctorId, transactionDate: getDate }));
        dispatch(
          getDiagnostics({ doctorId, transactionId: data.transactionId })
        );
        toast.success("Report reopened Successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(reopenDiagnosticError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

//PRINT TEST REPORT

export const printTestReports = (data) => {
  return (dispatch) => {
    dispatch(printTestReportLoading());
    client
      .post(
        `/diagnostic/print-diagnostic-report`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(printTestReportSuccess(resp.data));
        // toast.success("Report printed Successfully")
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(printTestReportError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// PRINT DIAGNOSTICS INVOICE

export const printDiagnosticsInvoices = (data) => {
  return (dispatch) => {
    dispatch(printDiagnosticInvoiceLoading());
    client
      .post(
        `/diagnostic/print-diagnostic-invoice`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(printDiagnosticInvoiceSuccess(resp.data));
        // toast.success("Invoice printed Successfully")
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(printDiagnosticInvoiceError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// add bank diagnostics

export const addBankDiagnostics = (data) => {
  return (dispatch) => {
    dispatch(addBankDiagnosticsLoading());
    client
      .post(
        `/diagnostic/add-bank-diagnostic`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(addBankDiagnosticsSuccess(resp.data));
        toast.success("Added to Bank");
        toast.success("Diagnostic profile updated");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(addBankDiagnosticsError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

// send sms diagnostics

export const sendSmsDiagnostic = (data) => {
  return (dispatch) => {
    dispatch(sendSmsDiagnosticsLoading());
    client
      .post(
        `/diagnostic/send-sms-diagnostic`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(sendSmsDiagnosticsSuccess(resp.data));
        toast.success("Sms sent successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(sendSmsDiagnosticsError(error));
        // toast.error(`${error.response.data.message}`)
      });
  };
};
