import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { getAppointments } from "./appointments";
import { client } from "../../../utils/axios";

export const accessTokenNo = (payload) => ({
  type: types.ACCESS_TOKEN_NO,
  payload,
});
const accessTokenLoading = () => ({
  type: types.ACCESS_TOKEN_LOADING,
});

const accessTokenSuccess = (data) => ({
  type: types.ACCESS_TOKEN_SUCCESS,
  payload: data,
});
const accessTokenPatientFail = (error) => ({
  type: types.ACCESS_TOKEN_FAIL,
  payload: error,
});

export const accessToken = (data, scheduledTime, apt) => {
  // let url = baseUrl();
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(accessTokenLoading());
    client
      .post(
        `/visit/assign-token`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(accessTokenSuccess(resp.data));
        toast.success("Token assigned");
        if (apt === "apt") {
          dispatch(getAppointments({ scheduledTime: scheduledTime }));
        }
      })
      .catch((error) => {
        console.log("error1111", error);
        toast.error(`${error.response.data.message}`);
      });
  };
};
