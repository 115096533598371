import React from 'react'
import './Gbp.css'
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from './TestReportRightSection'
import ReportFooter from './ReportFooter'
import { saveReports } from '../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field,updateReports} from '../../../redux/actions/Diagnostics/diagnostics'

export const Gbp = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'GBP')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'GBP'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }
  return (
    <div>
        
            <div className='gbp-parent'>
              <div className='gbp-left'>
                  <div className='gbp-grid'>
                      <div className='gbp1 gbp-pad' onClick={() => onLabelClicked("GENERAL BLOOD PICTURE")} >GENERAL BLOOD PICTURE</div>
                      <div className='gbp2 gbp-text'><TextAreaAnt name="GENERAL BLOOD PICTURE"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active} /></div>
                      <span className='gbp-border'></span>
                  </div>
              </div>
              <div>
                  <TestReportRightSection />
              </div>
            </div>
            <div className='gbp-foot'>
                <ReportFooter />
            </div>
            <div className='gbp-method'>
              <h4>Method:</h4>
              <p>RAPID TEST</p>
            </div>
       
    </div>
  )
}
