import React from 'react'
import './IvfSemen.css'
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea';
import Input from '../../../UIComponents/Input/Input'
import ReportFooter from './ReportFooter';
import { TestReportRightSection } from './TestReportRightSection'
import {field, updateReports } from '../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'

const IvfSemen = () => {
  const dispatch =  useDispatch()
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'IVF SEMEN ANALYSIS')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'IVF SEMEN ANALYSIS'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    console.log("ashdgasudtusad",setInputValue);
    dispatch(updateReports(reportDataFields))
    console.log("odjsh",reportDataFields?.testReport[index]?.fields[0].result);
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }
    return (
        <div>
            <div className='ivf-main-container'>
                <div className='ivf-main-left-sec'>
                    <div className='ivf-container'>
                        <div className='ivf1 padd' onClick={() => onLabelClicked("Method of Collection")}>Method of Collection</div>
                        <div className='ivf2'><TextAreaAnt name="Method of Collection"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='ivf-border1'></span>
                        <div className='ivf3 padd' onClick={() => onLabelClicked("Abstinence")}>Abstinence</div>
                        <div className='ivf4'><TextAreaAnt name="Abstinence"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='ivf-border2'></span>
                        <div className='ivf5 padd' onClick={() => onLabelClicked("Time of Collection")}>Time of Collection</div>
                        <div className='ivf6'><TextAreaAnt name="Time of Collection" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='ivf-border3'></span>
                        <div className='ivf7 padd' onClick={() => onLabelClicked("Colour")} >Colour</div>
                        <div className='ivf8'><TextAreaAnt name="Colour" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active}  onChange={onInputChange}/></div>
                        <span className='ivf-border4'></span>
                        <div className='ivf9 padd' onClick={() => onLabelClicked("Viscosity")} >Viscosity</div>
                        <div className='ivf10'><TextAreaAnt name="Viscosity" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='ivf-border5'></span>
                        <div className='ivf11 padd' onClick={() => onLabelClicked("Odour")}>Odour</div>
                        <div className='ivf12'><TextAreaAnt name="Odour" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='ivf-border6'></span>
                        <div className='ivf13 padd' onClick={() => onLabelClicked("Liquefaction Time")}  >Liquefaction Time</div>
                        <div className='ivf14'><TextAreaAnt name="Liquefaction Time" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='ivf-border7'></span>
                        <div className='ivf15 padd' onClick={() => onLabelClicked("pH")}>pH</div>
                        <div className='ivf16'><TextAreaAnt name="pH"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                        <span className='ivf-border8'></span>
                        <div className='ivf17 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf18 padd'>Total Count</div>
                        <div className='ivf19'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active}  name="Total Count" onChange={onInputChange} /></div>
                        <div className='ivf20 padd'>million/ml</div>
                        <div className='ivf21 padd'>10-100</div>
                        <div className='ivf22 padd'></div>
                        <span className='ivf-border9'></span>
                        <div className='ivf23 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf24 padd'>Total Count of Ejaculate</div>
                        <div className='ivf25'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} name="Total Count of Ejaculate" onChange={onInputChange} /></div>
                        <div className='ivf26 padd'>million</div>
                        <div className='ivf27 padd'></div>
                        <div className='ivf28 padd'></div>
                        <span className='ivf-border10'></span>
                        <div className='ivf29 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf30 padd'>Motility</div>
                        <div className='ivf31'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} name="Motility"  onChange={onInputChange} /></div>
                        <div className='ivf32 padd'>%</div>
                        <div className='ivf33 padd'></div>
                        <div className='ivf34 padd'></div>
                        <span className='ivf-border11'></span>
                        <div className='ivf35 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf36 padd'>Rapid linear progression</div>
                        <div className='ivf37'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} name="Rapid linear progression" onChange={onInputChange} /></div>
                        <div className='ivf38 padd'>%</div>
                        <div className='ivf39 padd'></div>
                        <div className='ivf40 padd'></div>
                        <span className='ivf-border12'></span>
                        <div className='ivf41 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf42 padd'>Slow or non-linear progression</div>
                        <div className='ivf43'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active}  name="Slow or non-linear progression" onChange={onInputChange} /></div>
                        <div className='ivf44 padd'>%</div>
                        <div className='ivf45 padd'></div>
                        <div className='ivf46 padd'></div>
                        <span className='ivf-border13'></span>
                        <div className='ivf47 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf48 padd'>Non-progressive Motility</div>
                        <div className='ivf49'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active}  name="Non-progressive Motility" onChange={onInputChange} /></div>
                        <div className='ivf50 padd'>%</div>
                        <div className='ivf51 padd'></div>
                        <div className='ivf52 padd'></div>
                        <span className='ivf-border14'></span>
                        <div className='ivf53 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf54 padd'>Immotile</div>
                        <div className='ivf55'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active}  name="Immotile" onChange={onInputChange}/></div>
                        <div className='ivf56 padd'>%</div>
                        <div className='ivf57 padd'></div>
                        <div className='ivf58 padd'></div>
                        <span className='ivf-border15'></span>
                        <div className='ivf59 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf60 padd'>Pus Cells</div>
                        <div className='ivf61'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[15]?.result} disabled={!reportDataFields.active} name="Pus Cells" onChange={onInputChange} /></div>
                        <div className='ivf62 padd'>%</div>
                        <div className='ivf63 padd'></div>
                        <div className='ivf64 padd'></div>
                        <span className='ivf-border16'></span>
                        <div className='ivf65 padd'>MICROSCOPIC EXAMINATION</div>
                        <div className='ivf66 padd'>Agglutination</div>
                        <div className='ivf67'><Input type='number' className='ivf5-input' value={reportDataFields?.testReport?.[indexValue]?.fields?.[16]?.result} disabled={!reportDataFields.active} name="Agglutination" onChange={onInputChange} /></div>
                        <div className='ivf68 padd'>/hpf</div>
                        <div className='ivf69 padd'></div>
                        <div className='ivf70 padd'></div>
                        <span className='ivf-border17'></span>
                    </div>

                </div>
                <div>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}

export default IvfSemen