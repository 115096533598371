import * as types from "../../actionTypes";

const initialState = {
  summary: [],
  getPatientDetail: {},
  diagnosticlist: [],
  getdiagnostic: {},
  addService: [],
  updateService: [],
  imageUrls: '',
  reportNames: [],
  patientClick: false,
  addedData: {},
  getDiagnosticsProfile: {},
  saveDiagnosticsReport: {},
  saveReport:{},
  updateReport:{},
  reopenReport:{},
  printTestReport:null,
  printDiagnosticsInvoice:{},
  genderNames: {},
  indexValue:0,
  error: {},
  loading: false,
  fieldNames: {},
  singleReport: {},
  smsDiagnostics:{},
  toggleButton: false,
  resetAdded: '',
  select:  ''
};

export const invoiceSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_DIAGNOSTIC_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_DIAGNOSTIC_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        loading: false,
        error: {},
      };
    case types.GET_ALL_DIAGNOSTIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        summary: []
      };
    case types.CLOSE_DIAGNOSTICS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CLOSE_DIAGNOSTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
      };
    case types.CLOSE_DIAGNOSTICS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.GET_ALL_DIAGNOSTIC_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_DIAGNOSTIC_LIST_SUCCESS:
      return {
        ...state,
        diagnosticlist: action.payload,
        loading: false,
        error: {},
      };
    case types.GET_ALL_DIAGNOSTIC_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        diagnosticlist: [],
      };
    case types.GET_DIAGNOSTIC_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_DIAGNOSTIC_SUCCESS:
      console.log("log of reducer",action);
      return {
        ...state,
        getdiagnostic: action.payload,
        loading: false,
        error: {},
      };
    case types.GET_DIAGNOSTIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        getdiagnostic: {},
      };
    case types.GET_PATIENT_CLICK:
      return {
        ...state,
        patientClick: action.payload,
        loading: false
      }
    case types.ADD_DIAGNOSTIC_SERVICE:
      return {
        ...state,
        getdiagnostic: action.payload,
        loading: false
      }
    case types.UPDATE_DIAGNOSTIC_SERVICE:
      console.log("useDispatch", action.payload);
      return {
        ...state,
        updateService: action.payload,
        loading: false
      }
    case types.CREATE_DIAGNOSTIC_SERVICE:
      console.log("testing in reducer",action.payload)
      return {
        ...state,
        addedData: action.payload,
        loading: false
      }
    case types.SELECT_BUTTON_TOGGLE:
      return {
        ...state,
        selectPatientToggled: action.payload,
        loading: false
      }
    case types.GET_PATIENT_DETAILS:
      return {
        ...state,
        getPatientDetail: action.payload,
        loading: false
      }
    case types.GET_DIAGNOSTIC_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_DIAGNOSTIC_PROFILE_SUCCESS:
      return {
        ...state,
        getDiagnosticsProfile: action.payload,
        loading: false,
        error: {},
      };
    case types.GET_DIAGNOSTIC_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        getDiagnosticsProfile: {},
      };
    case types.SAVE_DIAGNOSTIC_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SAVE_DIAGNOSTIC_REPORT_SUCCESS:
      return {
        ...state,
        saveDiagnosticsReport: action.payload,
        loading: false,
        error: {},
      };
    case types.SAVE_DIAGNOSTIC_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.SAVE_REPORT:
      return {
        ...state,
        saveReport: action.payload,
        loading: false,
      };
    case types.UPDATE_REPORT:
      return {
        ...state,
        updateReport: action.payload,
        loading: false
      }
    case types.IMAGE_URL:
      return {
        ...state,
        imageUrls: action.payload,
        loading: false
      }
    case types.REPORT_NAME:
      return {
        ...state,
        reportNames: action.payload,
        loading: false
      }
    case types.REOPEN_DIAGNOSTIC_REPORT_FAIL:
      return {
        ...state,
        loading: true,
      };
    case types.REOPEN_DIAGNOSTIC_REPORT_SUCCESS:
      return {
        ...state,
        reopenReport: action.payload,
        loading: false,
        error: {},
      };
    case types.REOPEN_DIAGNOSTIC_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.PRINT_TEST_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.PRINT_TEST_REPORTS_SUCCESS:
      return {
        ...state,
        printTestReport: action.payload,
        loading: false,
        error: {},
      };
    case types.PRINT_TEST_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.PRINT_DIAGNOSTICS_INVOICE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.PRINT_DIAGNOSTICS_INVOICE_SUCCESS:
      return {
        ...state,
        printDiagnosticsInvoice: action.payload,
        loading: false,
        error: {},
      };
    case types.DELETE_API_IMAGE :
    return {
      ...state,
      singleReport: action.payload,
      loading: false
    }
    case types.PRINT_DIAGNOSTICS_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.INDEX_VALUE:
      return{
        ...state,
        indexValue:action.payload,
        loading:false
      }
    case types.GENDER_NAME:
      return{
        ...state,
        genderNames:action.payload,
        loading:false
      }
    case types.FIELD:
      return{
        ...state,
        fieldNames: action.payload,
        loading: false
      }
    case types.SINGLE_REPORT_DATA:
      return {
        ...state,
        singleReport: action.payload,
        loading: false
      }
    case types.SEND_SMS_DIAGNOSTICS_LOADING:
      return{
        ...state,
        loading: true,
      };
    case types.SEND_SMS_DIAGNOSTICS_SUCCESS:
      return {
        ...state,
        smsDiagnostics: action.payload,
        loading: false,
        error: {},
      };
    case types.SEND_SMS_DIAGNOSTICS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.TOGGLE_BUTTON:
      return {
        ...state,
        toggleButton: action.payload,
        loading: false,
      }
    case types.RESET_GET_DIAGNOSTICS:
      return {
        ...state,
        getdiagnostic: action.payload,
        loading: false,
      }
    case types.RESET_ADDED_DATA:
      return {
        ...state,
        resetAdded: action.payload,
        loading: false,
      }
    case types.SELECT_HANDLE:
      return {
        ...state,
        select: action.payload,
        loading: false,
      }
    default:
      return state;
  }
};