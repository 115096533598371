import React, { useEffect } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../Forward/Forward.css'
import { Switch } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice'


function Forward() {
    const dispatch = useDispatch()
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)

    const onForwardChange = (checked) => {
        console.log("checked", checked)
        // dispatch(updateVisit({ ...visitInfo,markMedForFulfillment:checked}))
    }
   

    return (
        <div>
            {/* <div className='forward-div'>
                <NumberButton className="numberButton-15">15</NumberButton>
                <div className='forward-15-div'>
                    <h6>FORWARD</h6>
                </div>
                <div className='forward-antSelect'>
                    <Switch width="100px" onChange={onForwardChange} disabled={true} checked={true} />
                </div>
            </div> */}
        </div>
    )
}

export default Forward