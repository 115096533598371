import * as types from "../../actionTypes";

const initialState = {
  summary: [],
  log: [],
  history: [],
  madetoday: [],
  delete: [],
  plan: [],
  error: {},
  loading: false,
};
export const callSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_SUMMARY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CALL_SUMMARY_SUCCESS:
      console.log("detail", action.payload);
      return {
        ...state,
        summary: action.payload,
        loading: false,
        error: {},
      };
    case types.CALL_SUMMARY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        summary: [],
      };
    default:
      return state;
  }
};

// Call Log

export const callLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_LOG_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CALL_LOG_SUCCESS:
      console.log("detail", action.payload);
      return {
        ...state,
        log: action.payload,
        loading: false,
        error: {},
      };
    case types.CALL_LOG_FAIL:
      return {
        ...state,
        error: action.payload,
        log: false,
        detail: [],
      };
    default:
      return state;
  }
};

// Call made today

export const callMadeTodayReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_MADE_TODAY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CALL_MADE_TODAY_SUCCESS:
      console.log("detail", action.payload);
      return {
        ...state,
        madetoday: action.payload,
        loading: false,
        error: {},
      };
    case types.CALL_MADE_TODAY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        madetoday: [],
      };
    default:
      return state;
  }
};

// Call History

export const callHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_HISTORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CALL_HISTORY_SUCCESS:
      console.log("detail", action.payload);
      return {
        ...state,
        history: action.payload,
        loading: false,
        error: {},
      };
    case types.CALL_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        history: [],
      };
    default:
      return state;
  }
};

// Call plan

export const callPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_PLAN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CALL_PLAN_SUCCESS:
      console.log("detail", action.payload);
      return {
        ...state,
        plan: action.payload,
        loading: false,
        error: {},
      };
    case types.CALL_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        plan: [],
      };
    default:
      return state;
  }
};

// Call Delete

export const callDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_DELETE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CALL_DELETE_SUCCESS:
      console.log("detail", action.payload);
      return {
        ...state,
        delete: action.payload,
        loading: false,
        error: {},
      };
    case types.CALL_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        delete: [],
      };
    default:
      return state;
  }
};
