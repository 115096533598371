import React from "react";
import Input from "../../../../UIComponents/Input/Input";
import "./Troponin.css";
import { TestReportRightSection } from "../TestReportRightSection";
import ReportFooter from "../ReportFooter";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function Troponin() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'TROPONIN I PLUS')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'TROPONIN I PLUS'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
        reportDataFields.testReport[index]  = filterData[0]
        console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
        setInputValue(reportDataFields?.testReport[index]?.fields)
        dispatch(updateReports(reportDataFields))
    
  }
 
  return (
    <div>
      <div className="troponin-parent">
        <div className="troponin-left">
          <div className="troponin-grid">
            <div className="troponin-1"></div>
            <div className="troponin-2 troponin-pad">Troponin I Plus</div>
            <div className="troponin-3">
              <Input type='number' className={"troponin-input"}   name="Troponin I Plus" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            </div>
            <div className="troponin-4 troponin-pad">ng/ml</div>
            <div className="troponin-5"></div>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="troponin-foot">
        <ReportFooter />
      </div>
      <div className="troponin-p">
        <h4>Reference Range</h4>
        <table className="troponin-table">
          <tr>
            <td>Concentration(ng/mL)</td>
            <td>Interpretation</td>
          </tr>
          <tr>
            <td>0.04</td>
            <td>Normal</td>
          </tr>
          <tr>
            <td>0.04 - 0.39</td>
            <td>Elevated above the 99th percentile of a healthy population.</td>
          </tr>
          <tr>
            <td>0.40</td>
            <td>Probable myocardial infarction.</td>
          </tr>
        </table>
      </div>
      <div className="troponin-msr">
        Troponin is a type of protein complex made up of three individual
        proteins. They interact to control striated muscle contraction, i.e.,
        cardiac and skeletal muscle. They are released into the blood when heart
        muscle cells have been damaged.
        <br />
        Measured using Optical Fluorescence Method
      </div>
    </div>
  );
}

export default Troponin;
