import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

const updateAppointmentViewLoading = () => ({
  type: types.UPDATE_APPOINTMENT_VIEW_LOADING,
});

const updateAppointmentViewSuccess = (data) => ({
  type: types.UPDATE_APPOINTMENT_VIEW_SUCCESS,
  payload: data,
});
const updateAppointmentViewFail = (error) => ({
  type: types.UPDATE_APPOINTMENT_VIEW_FAIL,
  payload: error,
});

export const updateAppointmentView = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(updateAppointmentViewLoading());
    client
      .post(
        `/visit/update-appointment-view`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(updateAppointmentViewSuccess(resp.data));
        console.log("Appointment updated", resp.data);
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(updateAppointmentViewFail(error));
      });
  };
};
