import React from 'react'
import './StoolExamination.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function StoolExamination() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'STOOL EXAMINATION')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'STOOL EXAMINATION'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
   filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))      }
  return (
    <div>
      <div className="stoolExamination-parent">
        <div className="stoolExamination-left">
          <div className="stoolExamination-grid">
            <div className="stoolExamination-1 stoolExamination-pad" onClick={() => onLabelClicked("COLOUR")}>COLOUR</div>
            <div className="stoolExamination-2 stoolExamination-text">
              <TextAreaAnt name="COLOUR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border"></span>

            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("ODOUR")}>ODOUR</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="ODOUR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border1"></span>

            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("CONSISTENCY")}>CONSISTENCY</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="CONSISTENCY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border2"></span>

            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("MUCUS")}>MUCUS</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="MUCUS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border3"></span>

            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("BLOOD")}>BLOOD</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="BLOOD" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border4"></span>

            <div className="stoolExamination-pad" onClick={() => onLabelClicked("PARASITES")}>PARASITES</div>
            <div className="stoolExamination-text">
              <TextAreaAnt name="PARASITES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border5"></span>

            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("Ph")}>Ph</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="Ph" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border6"></span>
            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("SUGAR (Reducing Substance)")}>SUGAR (Reducing Substance)</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="SUGAR (Reducing Substance)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border7"></span>
            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("OCCULT BLOOD TEST")}>OCCULT BLOOD TEST</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="OCCULT BLOOD TEST" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border8"></span>
            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("EPITHILIAL CELLS")}>EPITHILIAL CELLS</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="EPITHILIAL CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border9"></span>
            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("PUS CELLS")}>PUS CELLS</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="PUS CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border10"></span>
            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("MACROPHAGES")}>MACROPHAGES</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="MACROPHAGES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border11"></span>
            <div className=" stoolExamination-pad" onClick={() => onLabelClicked("ERYTHROCYTES")}>ERYTHROCYTES</div>
            <div className=" stoolExamination-text">
              <TextAreaAnt name="ERYTHROCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="stoolExamination-border12"></span>

          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="stoolExamination-foot">
        <ReportFooter />
      </div>
    </div>
  )
}

export default StoolExamination
