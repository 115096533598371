import React from 'react'
import {  useEffect } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import CreatePatient from '../../components/Appointments/CreatePatient/CreatePatient'
import PatientAppointments from '../../components/Appointments/PatientAppointments/PatientAppointments'
import '../Appointments/Appointments.css'
import { getAppointments } from '../../redux/actions/appointments/appointments'
import { resetGetPatient } from '../../redux/actions/Diagnostics/getPatient'
import { getPatientModified } from '../../redux/actions/appointments/getPatient'
import { resetSearchPatient } from '../../redux/actions/appointments/searchPatient'
import { singlePatientToggle } from '../../redux/actions/appointments/getPatient'
import { resetPatientSearchAdv } from '../../redux/actions/appointments/patientAdvSearch'
import { copyButton } from '../../redux/actions/appointments/getPatient'
import { createToggle } from '../../redux/actions/appointments/searchPatient'

const Appointments = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetGetPatient());
    dispatch(createToggle(''))
    dispatch(getPatientModified({}))
    dispatch(singlePatientToggle(false))
    dispatch(resetSearchPatient())
    dispatch(resetPatientSearchAdv())
    dispatch(copyButton(false))
    dispatch(singlePatientToggle(false))
    dispatch(getAppointments({ scheduledTime: moment().format("YYYY-MM-DD") }))
  }, [resetGetPatient,getPatientModified])
  return (
    <div className='main-section'>
      <div className='appointments'>
        <div className='patient-appointments-main-div'>
          <PatientAppointments />
        </div>
        <div className='create-patient-main-div'>
          <CreatePatient />
        </div>

      </div>
    </div>
  )
}

export default Appointments