import React,{useEffect,useRef} from 'react'
import { DatePicker, Switch } from 'antd';
import { useState } from 'react';
import './AnalyticsModal.css'
import whiteprinter from '../../../images/printer.svg'
import whitedownload from '../../../images/download-new.svg'
import Table from '../../../UIComponents/Table/Table'
import { detailSummaryDetails } from '../../../redux/actions/Diagnostics/invoiceSummary'
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReactToPrint from 'react-to-print';
import { rangeStartDate,rangeEndDate } from '../../../redux/actions/Diagnostics/invoiceSummary';
const columns = [
    {
      title:'#',
      dataIndex:'invoiceNumber',
      width:'',
      
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width:'',

    },
    {
        title: 'Patient',
        dataIndex: 'name',
        width:'',
  
      },
      {
        title: 'Sex',
        dataIndex: 'gender',
        width:'',
  
      },
      {
        title: 'Age',
        dataIndex: 'age',
        width:'',
  
      },
      {
        title: 'Payable',
        dataIndex: 'netTotal',
        width:'',
  
      },
      {
        title: 'Received',
        dataIndex: 'payed',
        width:'',
  
      },
      {
        title: 'Mode',
        dataIndex: 'paymentMode',
        width:'',
  
      },
      {
        title: 'Referal',
        dataIndex: 'referral',
        width:'',
  
      },
      {
        title: 'Hospital',
        dataIndex: 'hospitalReferral',
        width:'',
  
      },

    
  ];
  const column = [
    {
      title: '#',
      dataIndex: 'invoiceNumber',
      width:'',
      
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width:'',

    },
    {
        title: 'Patient',
        dataIndex: 'name',
        width:'',
  
      },
      {
        title: 'Test',
        dataIndex: 'test',
        width:'',
  
      },
      {
        title: 'Charge',
        dataIndex: 'cost',
        width:'',
  
      },
      {
        title: 'Referal',
        dataIndex: 'referral',
        width:'',
  
      },
      {
        title: 'Hospital',
        dataIndex: 'hospitalReferral',
        width:'',
  
      },
  ];
 
export const AnalyticsModal = () => {
  const componentRef = useRef();
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const profileInfo = useSelector(state => state?.profile?.profileInfo)
    const invoiceData = useSelector((state) => state?.invoiceSummary?.detail);
    const summaryForVariable = useSelector((state) => state)
    console.log("summaryForVariable",summaryForVariable?.invoiceSummary);
    const doctorId = profileInfo?.id;

    const [DetailSummary, setDetailSummary] = useState()
    const [invoice,setInvoice] = useState('')

    const clearDate = useSelector((state) => state?.invoiceSummary?.rangeDates)
    console.log("clearDate",clearDate);

    useEffect(() => {
      const tableArray = [];
      {
        invoiceData?.map((item, i) => (
          item?.invoice?.map((item1, j) => {
            console.log("tableArray", { ...item, ...item1,...item?.invoiceSummary,...item?.patient });
            tableArray.push({ ...item, ...item1, _id_custom: `${i}+${j}`, ...item?.invoiceSummary,...item?.patient, date:moment(item?.transactionTime).format('YYYY-MM-DD hh:mm a')})
          })
        ))
      }
      setInvoice(tableArray)
    }, [invoiceData])
    const handleDetailSummaryChange = (checked) => {
        setDetailSummary(checked)
    }
 
    const handleTablechange = (dates,dateString) => {
      dispatch(rangeStartDate(dates))
      dispatch(detailSummaryDetails(dateString[0],dateString[1],doctorId))
    }

  return (
    <div>
        <div className='ultrasono-dates-details'>
            <div>
                <p>Dates</p>
                <RangePicker  value={clearDate} onChange={handleTablechange} />
            </div>
            <div className='analytics-switch'>
                <p>Detail/Summary</p>
                <Switch checked={DetailSummary} size="default" onChange={handleDetailSummaryChange} />
            </div>
            <div>
                <p>Print Report</p>
                <div className='printer-download-logo'>
                    <div className="print-icon-class">
                          <ReactToPrint
                          trigger={() =>  <span><img src={whiteprinter} alt="print img" /></span>}
                          content={() => componentRef.current} />
                    </div>
                </div>
            </div>
        </div>
        {DetailSummary?
        <div className='ultrasono-table' >
          <div className='ultrasono-table-one' ref={componentRef}>
            <Table  columns={columns} data={invoice} pagination={false} />
            </div>
        </div>
        :
        <div className='ultrasono-table' >
          <div className='ultrasono-table-one' ref={componentRef}>
            <Table columns={column} data={invoice} pagination={false} />
            </div>
        </div>
         }
    </div>
  )
}