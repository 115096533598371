import * as types from "../actionTypes";

const initialState = {
  loginInfo: [],
  isDoctorLogin: false,
  doctorId: "",
  error: {},
  loading: false,
  myDomainInfo: null,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_DOCTOR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_DOCTOR_SUCCESS:
      return {
        ...state,
        loginInfo: action.payload,
        isDoctorLogin: true,
        loading: false,
        error: {},
      };
    case types.LOGIN_DOCTOR_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loginInfo: [],
      };
    case types.RESET_DOCTOR_INFO:
      return {
        ...state,
        loginInfo: action.payload,
        loading: false,
      };
    case types.MY_DOMAIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.MY_DOMAIN_SUCCESS:
      return {
        ...state,
        myDomainInfo: action.payload,
        loading: false,
        error: {},
      };
    case types.MY_DOMAIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.UPDATE_LOGIN_STATUS:
      return {
        ...state,
        isDoctorLogin: false,
        loading: false,
      };
    case types.STORE_DOCTOR_ID:
      return {
        ...state,
        doctorId: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
