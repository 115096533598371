import * as types from "../../actionTypes"

const initialState = {
    subDomainResister:[],
    domainUpdate:[],
    domainUser:[],
    domainPic:[],
    domainStyle:[]
}

export const subDomainReducer = (state =initialState, action) => {
    switch(action.type){
        case types.SUB_DOMAIN_REGISTER_LOADING:
            return{
                ...state,
                loading:true
            }
        case types.SUB_DOMAIN_REGISTER_SUCCESS:
            return{
                ...state,
                subDomainReducer:action.payload,
                loading:false,
                error:{}
            }
        case types.SUB_DOMAIN_REGISTER_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                subDomainReducer:[]
            }
        case types.SUB_DOMAIN_UPDATE_LOADING:
            return{
                ...state,
                loading:true
            }
        case types.SUB_DOMAIN_UPDATE_SUCCESS:
            return{
                ...state,
                domainUpdate:action.payload,
                loading:false,
                error:{}
            }
        case types.SUB_DOMAIN_UPDATE_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                domainUpdate:[]
            }
        case types.SUB_DOMAIN_USER_ADD_LOADING:
            return{
                ...state,
                loading:true
            }
        case types.SUB_DOMAIN_USER_ADD_SUCCESS:
            return{
                ...state,
                domainUser:action.payload,
                loading:false,
                error:{}
            }
        case types.SUB_DOMAIN_USER_ADD_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                domainUser:[]
            }
        case types.SUB_DOMAIN_PIC_LOADING:
            return{
                ...state,
                loading:true
            }
        case types.SUB_DOMAIN_PIC_SUCCESS:
            return{
                ...state,
                domainPic:action.payload,
                loading:false,
                error:{}
            }
        case types.SUB_DOMAIN_PIC_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                domainPic:[]
            }
        case types.SUB_DOMAIN_STYLE_LOADING:
            return{
                ...state,
                loading:true
            }
        case types.SUB_DOMAIN_STYLE_SUCCESS:
            return{
                ...state,
                domainStyle:action.payload,
                error:{},
                loading:false
            }
        case types.SUB_DOMAIN_STYLE_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                domainStyle:[]
            }
        default:
            return state
    }
}
