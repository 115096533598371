import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import './UrineAnalysis.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function UrineAnalysis() {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e,i) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'URINE ANALYSIS')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'URINE ANALYSIS'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map((item,index) => {
        if(item.particular === name && index === i){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
        <div className='urineAnalysis-parent'>
          <div className='urineAnalysis-left'>
            <div className='urineAnalysis-grid'>

            <div className='urineAnalysis-1 urineAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='urineAnalysis-2 urineAnalysis-pad'>COLOUR</div>
                <div className='urineAnalysis-3'><Input type='number' className={'urineAnalysis-input'} name="COLOUR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,0)}  /></div>
                <div className='urineAnalysis-4 urineAnalysis-pad'>ml</div>
                <div className='urineAnalysis-5 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-1'></span>

                <div className='urineAnalysis-6 urineAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='urineAnalysis-7 urineAnalysis-pad'>	APPEARANCE</div>
                <div className='urineAnalysis-8'><Input type='number' className={'urineAnalysis-input'} name="APPEARANCE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,1)} /></div>
                <div className='urineAnalysis-9 urineAnalysis-pad'></div>
                <div className='urineAnalysis-10 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-2'></span>

                <div className='urineAnalysis-11 urineAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='urineAnalysis-12 urineAnalysis-pad'>SEDIMENT</div>
                <div className='urineAnalysis-13'><Input type='number' className={'urineAnalysis-input'}  name="SEDIMENT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,2)}  /></div>
                <div className='urineAnalysis-14 urineAnalysis-pad'></div>
                <div className='urineAnalysis-15 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-3'></span>

                <div className='urineAnalysis-16 urineAnalysis-pad'>PHYSICAL EXAMINATION</div>
                <div className='urineAnalysis-17 urineAnalysis-pad'>SPECIFIC GRAVITY</div>
                <div className='urineAnalysis-18'><Input type='number' className={'urineAnalysis-input'} name="SPECIFIC GRAVITY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,3)}  /></div>
                <div className='urineAnalysis-19 urineAnalysis-pad'></div>
                <div className='urineAnalysis-20 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-4'></span>

                <div className='urineAnalysis-21 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-22 urineAnalysis-pad'>REACTION</div>
                <div className='urineAnalysis-23'><Input type='number' className={'urineAnalysis-input'} name="REACTION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,4)} /></div>
                <div className='urineAnalysis-24 urineAnalysis-pad'></div>
                <div className='urineAnalysis-25 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-5'></span>

                <div className='urineAnalysis-26 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-27 urineAnalysis-pad'>pH</div>
                <div className='urineAnalysis-28'><Input type='number' className={'urineAnalysis-input'}name="pH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,5)}  /></div>
                <div className='urineAnalysis-29 urineAnalysis-pad'></div>
                <div className='urineAnalysis-30 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-6'></span>

                <div className='urineAnalysis-31 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-32 urineAnalysis-pad'>PROTEIN (Semi Quantitative)</div>
                <div className='urineAnalysis-33'><Input type='number' className={'urineAnalysis-input'} name="PROTEIN (Semi Quantitative)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,6)}  /></div>
                <div className='urineAnalysis-34 urineAnalysis-pad'></div>
                <div className='urineAnalysis-35 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-7'></span>

                <div className='urineAnalysis-36 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-37 urineAnalysis-pad'>PROTEIN (Quantitative)</div>
                <div className='urineAnalysis-38'><Input type='number' className={'urineAnalysis-input'} name="PROTEIN (Quantitative)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,7)} /></div>
                <div className='urineAnalysis-39 urineAnalysis-pad'>ml/dl</div>
                <div className='urineAnalysis-40 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-8'></span>

                <div className='urineAnalysis-41 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-42 urineAnalysis-pad'>MICRO ALBUMIN</div>
                <div className='urineAnalysis-43'><Input type='number' className={'urineAnalysis-input'} name="MICRO ALBUMIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,8)}  /></div>
                <div className='urineAnalysis-44 urineAnalysis-pad'>ml/dl</div>
                <div className='urineAnalysis-45 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-9'></span>

                <div className='urineAnalysis-46 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-47 urineAnalysis-pad'>BENCE JONE'S PROTEIN</div>
                <div className='urineAnalysis-48'><Input type='number' className={'urineAnalysis-input'} name="BENCE JONE'S PROTEIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,9)} /></div>
                <div className='urineAnalysis-49 urineAnalysis-pad'></div>
                <div className='urineAnalysis-50 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-10'></span>

                <div className='urineAnalysis-51 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-52 urineAnalysis-pad'>SUGAR (Random Sample)</div>
                <div className='urineAnalysis-53'><Input type='number' className={'urineAnalysis-input'} name="SUGAR (Random Sample)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,10)}  /></div>
                <div className='urineAnalysis-54 urineAnalysis-pad'></div>
                <div className='urineAnalysis-55 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-11'></span>


                <div className='urineAnalysis-56 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-57 urineAnalysis-pad'>SUGAR (Fasting Sample)</div>
                <div className='urineAnalysis-58'><Input type='number' className={'urineAnalysis-input'} name="SUGAR (Fasting Sample)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,11)}  /></div>
                <div className='urineAnalysis-59 urineAnalysis-pad'></div>
                <div className='urineAnalysis-60 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-12'></span>

                <div className='urineAnalysis-61 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-62 urineAnalysis-pad'>SUGAR (PP Sample)</div>
                <div className='urineAnalysis-63'><Input type='number' className={'urineAnalysis-input'} name="SUGAR (PP Sample)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,12)}  /></div>
                <div className='urineAnalysis-64 urineAnalysis-pad'></div>
                <div className='urineAnalysis-65 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-13'></span>

                <div className='urineAnalysis-66 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-67 urineAnalysis-pad'>BLOOD</div>
                <div className='urineAnalysis-68'><Input type='number' className={'urineAnalysis-input'} name="BLOOD" value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,13)}  /></div>
                <div className='urineAnalysis-69 urineAnalysis-pad'></div>
                <div className='urineAnalysis-70 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-14'></span>

                <div className='urineAnalysis-71 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-72 urineAnalysis-pad'>CHYLE</div>
                <div className='urineAnalysis-73'><Input type='number' className={'urineAnalysis-input'} name="CHYLE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,14)}  /></div>
                <div className='urineAnalysis-74 urineAnalysis-pad'></div>
                <div className='urineAnalysis-75 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-15'></span>

                <div className='urineAnalysis-76 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-77 urineAnalysis-pad'>KETONE BODIES</div>
                <div className='urineAnalysis-78'><Input type='number' className={'urineAnalysis-input'} name="KETONE BODIES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[15]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,15)}  /></div>
                <div className='urineAnalysis-79 urineAnalysis-pad'></div>
                <div className='urineAnalysis-80 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-16'></span>

                <div className='urineAnalysis-81 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-82 urineAnalysis-pad'>BILE SALT</div>
                <div className='urineAnalysis-83'><Input type='number' className={'urineAnalysis-input'} name="BILE SALT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[16]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,16)}  /></div>
                <div className='urineAnalysis-84 urineAnalysis-pad'></div>
                <div className='urineAnalysis-85 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-17'></span>

                <div className='urineAnalysis-86 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-87 urineAnalysis-pad'>UROBILINOGEN</div>
                <div className='urineAnalysis-88'><Input type='number' className={'urineAnalysis-input'} name="UROBILINOGEN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[17]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,17)}  /></div>
                <div className='urineAnalysis-89 urineAnalysis-pad'></div>
                <div className='urineAnalysis-90 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-18'></span>

                <div className='urineAnalysis-91 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-92 urineAnalysis-pad'>PROPHOBILINOGEN</div>
                <div className='urineAnalysis-93'><Input type='number' className={'urineAnalysis-input'} name="PROPHOBILINOGEN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[18]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,18)}  /></div>
                <div className='urineAnalysis-94 urineAnalysis-pad'></div>
                <div className='urineAnalysis-95 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-19'></span>

                <div className='urineAnalysis-96 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-97 urineAnalysis-pad'>HAEMOGLOBIN</div>
                <div className='urineAnalysis-98'><Input type='number' className={'urineAnalysis-input'} name="HAEMOGLOBIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[19]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,19)}  /></div>
                <div className='urineAnalysis-99 urineAnalysis-pad'></div>
                <div className='urineAnalysis-100 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-20'></span>

                <div className='urineAnalysis-101 urineAnalysis-pad'>CHEMICAL EXAMINATION</div>
                <div className='urineAnalysis-102 urineAnalysis-pad'>MYOGLOBIN</div>
                <div className='urineAnalysis-103'><Input type='number' className={'urineAnalysis-input'} name="MYOGLOBIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[20]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,20)}  /></div>
                <div className='urineAnalysis-104 urineAnalysis-pad'></div>
                <div className='urineAnalysis-105 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-21'></span>

                <div className='urineAnalysis-106 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-107 urineAnalysis-pad'>HAEMOGLOBIN</div>
                <div className='urineAnalysis-108'><Input type='number' className={'urineAnalysis-input'} name="HAEMOGLOBIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[21]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,21)}  /></div>
                <div className='urineAnalysis-109 urineAnalysis-pad'></div>
                <div className='urineAnalysis-110 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-22'></span>

                <div className='urineAnalysis-111 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-112 urineAnalysis-pad'>LEUKOCYTES</div>
                <div className='urineAnalysis-113'><Input type='number' className={'urineAnalysis-input'} name="LEUKOCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[22]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,22)}  /></div>
                <div className='urineAnalysis-114 urineAnalysis-pad'></div>
                <div className='urineAnalysis-115 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-23'></span>

                <div className='urineAnalysis-116 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-117 urineAnalysis-pad'>EPITHILIAL CELLS</div>
                <div className='urineAnalysis-118'><Input type='number' className={'urineAnalysis-input'} name="EPITHILIAL CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[23]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,23)}  /></div>
                <div className='urineAnalysis-119 urineAnalysis-pad'></div>
                <div className='urineAnalysis-120 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-24'></span>

                <div className='urineAnalysis-121 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-122 urineAnalysis-pad'>ERYTHROCYTES</div>
                <div className='urineAnalysis-123'><Input type='number' className={'urineAnalysis-input'} name="ERYTHROCYTES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[24]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,24)}  /></div>
                <div className='urineAnalysis-124 urineAnalysis-pad'></div>
                <div className='urineAnalysis-125 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-25'></span>

                <div className='urineAnalysis-126 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-127 urineAnalysis-pad'>CAST</div>
                <div className='urineAnalysis-128'><Input type='number' className={'urineAnalysis-input'} name="CAST" value={reportDataFields?.testReport?.[indexValue]?.fields?.[25]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,25)}  /></div>
                <div className='urineAnalysis-129 urineAnalysis-pad'></div>
                <div className='urineAnalysis-130 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-26'></span>

                <div className='urineAnalysis-131 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-132 urineAnalysis-pad'>CRYSTAL</div>
                <div className='urineAnalysis-133'><Input type='number' className={'urineAnalysis-input'} name="CRYSTAL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[26]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,26)}  /></div>
                <div className='urineAnalysis-134 urineAnalysis-pad'></div>
                <div className='urineAnalysis-135 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-27'></span>

                <div className='urineAnalysis-136 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-137 urineAnalysis-pad'>BACTERIA</div>
                <div className='urineAnalysis-138'><Input type='number' className={'urineAnalysis-input'} name="BACTERIA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[27]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,27)}  /></div>
                <div className='urineAnalysis-139 urineAnalysis-pad'></div>
                <div className='urineAnalysis-140 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-28'></span>


                <div className='urineAnalysis-141 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-142 urineAnalysis-pad'>FUNGUS</div>
                <div className='urineAnalysis-143'><Input type='number' className={'urineAnalysis-input'} name="FUNGUS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[28]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,28)}  /></div>
                <div className='urineAnalysis-144 urineAnalysis-pad'></div>
                <div className='urineAnalysis-145 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-29'></span>

                <div className='urineAnalysis-146 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-147 urineAnalysis-pad'>PARASITES</div>
                <div className='urineAnalysis-148'><Input type='number' className={'urineAnalysis-input'} name="PARASITES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[29]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,29)} /></div>
                <div className='urineAnalysis-149 urineAnalysis-pad'></div>
                <div className='urineAnalysis-150 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-30'></span>
                
                <div className='urineAnalysis-151 urineAnalysis-pad'>MICROSCOPIC EXAMINATION</div>
                <div className='urineAnalysis-152 urineAnalysis-pad'>OTHERS</div>
                <div className='urineAnalysis-153'><Input type='number' className={'urineAnalysis-input'} name="OTHERS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[30]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,30)} /></div>
                <div className='urineAnalysis-154 urineAnalysis-pad'></div>
                <div className='urineAnalysis-155 urineAnalysis-pad'></div>
                <span className='urineAnalysis-border-31'></span>

                

            </div>
          </div>
          <div>
              <TestReportRightSection />
          </div>
        </div>
        <div className='urineAnalysis-foot'>
            <ReportFooter />
        </div>
        <div className='urineAnalysis-p'>
          <h4>Note:</h4>
          <p>The test is performed using VIDAS (ELFA) methods.</p>
          
        </div>
       
    </div>
  )
}

export default UrineAnalysis
