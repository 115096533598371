import * as types from '../../actionTypes';

const initialState = {
    getAppointmentsDetails: [],
    getScheduled : {},
    updateEvent:[],
    error: {},
    loading: false,
    speechs: "",
    speechForDaignostics: "",
    speechForVital: "",
    smsInvoice: {},
    narrativesData: "",
}


export  const getAppointmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_APPOINTMENTS_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_APPOINTMENTS_SUCCESS:
            console.log("action",action.payload)
            return {
                ...state,
                getAppointmentsDetails: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_APPOINTMENTS_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                getAppointmentsDetails: []
            }
        case types.INVOICE_SMS_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.INVOICE_SMS_SUCCESS:
            return {
                ...state,
                smsInvoice: action.payload,
                loading: false
            }
        case types.INVOICE_SMS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                smsInvoice: {}
            }
        case types.GET_SCHEDULED_APPOINTMENTS:
            return {
                ...state,
                getScheduled:action.payload,
                loading:false
            }
        case types.UPDATE_EVENTS:
            return {
                ...state,
                updateEvent:action.payload,
                loading:false
            }
        case types.SPEECH:
            return {
                ...state,
                speechs: action.payload,
                loading: false
            }
        case types.SPEECH_FOR_DIAGNOSTIC:
            return {
                ...state,
                speechForDaignostics: action.payload,
                loading: false
            }
        case types.SPEECH_FOR_VITALS:
            return {
                ...state,
                speechForVital: action.payload,
                loading: false
            }
        case types.NARRATIVES_FINAL_DATA:
            return {
                ...state,
                narrativesData: action.payload,
                loading: false
            }

        default:
            return state;
    }
}