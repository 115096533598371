import * as types from '../../actionTypes';

const initialState = {
    printPatientProfiles: [],
    printPatientToggle: false,
    error: {},
    loading: false,
}



export  const printPatientProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PRINT_PATIENT_PROFILE_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.PRINT_PATIENT_PROFILE_SUCCESS:
            console.log("actionddddd",action.payload)
            return {
                ...state,
                printPatientProfiles: action.payload,
                loading: false,
                error: {}
            }
        case types.PRINT_PATIENT_PROFILE_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                printPatientProfiles: []
            }
        case types.PRINT_PATIENT_PROFILE_TOGGLE:
            return{
                ...state,
                printPatientToggle: action.payload,
                loading: false
            }
        
        default:
            return state;
    }
}