import React, { useEffect, useState } from "react";
import InputField from "../../../UIComponents/Input/Input";
import { Radio, Switch } from "antd";
import { useSelector, useDispatch } from "react-redux";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import "./ProfileDetails.css";
import Button from "../../../UIComponents/Button/Button";
import {
  updateProfileDetails,
  updateProfileData,
} from "../../../redux/actions/profile";
import { toast } from "../../../UIComponents/Toast";
const data = [
  {
    id: "General Physician",
    name: "General Physician",
  },
  {
    id: "General Surgeon",
    name: "General Surgeon",
  },
  {
    id: "E.N.T.",
    name: "E.N.T.",
  },
  {
    id: "Paediastrician",
    name: "Paediastrician",
  },
  {
    id: "Dermatologist",
    name: "Dermatologist",
  },
  {
    id: "Gynecologist",
    name: "Gynecologist",
  },
  {
    id: "Gastroenterologist",
    name: "Gastroenterologist",
  },
  {
    id: "Orthopaedist",
    name: "Orthopaedist",
  },
  {
    id: "Urologist",
    name: "Urologist",
  },
  {
    id: "Neurologist",
    name: "Neurologist",
  },
  {
    id: "Psychiatrist",
    name: "Psychiatrist",
  },
  {
    id: "Cardiologist",
    name: "Cardiologist",
  },
  {
    id: "Homeopathist",
    name: "Homeopathist",
  },
  {
    id: "Dentist",
    name: "Dentist",
  },
  {
    id: "Physiotherapist",
    name: "Physiotherapist",
  },

  {
    id: "Oncologist",
    name: "Oncologist",
  },
  {
    id: "Ophthalmologist",
    name: "Ophthalmologist",
  },
  {
    id: "Plastic Surgeon",
    name: "Plastic Surgeon",
  },
  {
    id: "Pain Management",
    name: "Pain Management",
  },
  {
    id: "Pulmonologist",
    name: "Pulmonologist",
  },
];
const ProfileDetails = () => {
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => state.profile.profileInfo);
  console.log("get profile info", profileInfo);

  const handleChange = (e) => {
    let { name, value } = e.target;
    // if (name === "education") {
    //   console.log("value,e.target.value", value, e.target.value);
    //   const result = value.replace(/[^a-zA-Z\s]/g, "");
    //   dispatch(updateProfileData({ ...profileInfo, [name]: result }));
    // } else {
    dispatch(updateProfileData({ ...profileInfo, [name]: value }));
    // }
  };

  const onSpecialityChange = (value) => {
    dispatch(updateProfileData({ ...profileInfo, speciality: value }));
  };

  const handleCustomMarginChange = (checked) => {
    dispatch(
      updateProfileData({ ...profileInfo, customPageMarginFlag: checked })
    );
  };
  const handleCustomHeaderChange = (checked) => {
    dispatch(updateProfileData({ ...profileInfo, customHeader: checked }));
  };
  const handleHideFooterChange = (checked) => {
    dispatch(updateProfileData({ ...profileInfo, hideFooter: checked }));
  };

  const onSaveProfile = (e) => {
    e.preventDefault();
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (profileInfo.hasOwnProperty("locations")) {
      if (
        !profileInfo.gender ||
        !profileInfo.email ||
        !profileInfo.education ||
        !profileInfo.speciality ||
        !profileInfo.experience
      ) {
        toast.error("Please fill all details");
      } else if (regex.test(profileInfo.email) === false) {
        toast.error("Please enter valid Email Id");
      } else {
        dispatch(updateProfileDetails(profileInfo));
      }
    } else {
      toast.error("Please add branch as well");
    }
  };
  return (
    <div className="profile-details-sec">
      <div className="pro-detail-heading">
        <h4>Profile Details</h4>
        <h1>-</h1>
      </div>
      <div className="profile-details-left">
        <div className="each-profile-record">
          <p>Name</p>
          <InputField type="text" name="name" value={profileInfo?.name} />
        </div>
        <div className="each-profile-record">
          <p>Gender</p>
          <div style={{ paddingTop: "3px" }}>
            <Radio.Group
              name="gender"
              value={profileInfo?.gender}
              onChange={handleChange}
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="each-profile-record">
          <p>Phone</p>
          <h4>+91 {profileInfo?.mobile}</h4>
        </div>
        <div className="each-profile-record">
          <p>Email</p>
          <InputField
            type="email"
            name="email"
            value={profileInfo?.email}
            onChange={handleChange}
          />
        </div>
        <div className="each-profile-record">
          <p>Education Background</p>
          <InputField
            type="text"
            name="education"
            value={profileInfo?.education}
            onChange={handleChange}
            maxLength={40}
          />
        </div>
        <div className="each-profile-record">
          <p>Speciality</p>
          <div style={{ width: "199px" }}>
            {" "}
            <SingleSelect
              name="speciality"
              value={profileInfo?.speciality}
              onChange={onSpecialityChange}
              data={data}
            />
          </div>
        </div>
        <div className="each-profile-record">
          <p>Experience Summary</p>
          <InputField
            type="text"
            name="experience"
            value={profileInfo?.experience}
            onChange={handleChange}
          />
        </div>
        <div className="each-profile-record">
          <p>Custom Header</p>
          <Switch
            checked={profileInfo?.customHeader}
            onChange={handleCustomHeaderChange}
          />
        </div>
        <div className="each-profile-record">
          <p>Custom Margin</p>
          <Switch
            checked={profileInfo?.customPageMarginFlag}
            onChange={handleCustomMarginChange}
          />
        </div>
        <div className="each-profile-record">
          <p>Hide Footer</p>
          <Switch
            checked={profileInfo?.hideFooter}
            onChange={handleHideFooterChange}
          />
        </div>
        <div className="save-profile-btn-sec">
          <Button className={"save-profile-btn"} onClick={onSaveProfile}>
            SAVE PROFILE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
