import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

export const resetTwilioToken = () => ({
  type: types.RESET_TWILIO_TOKEN,
});

const makeVideoCallLoading = () => ({
  type: types.MAKE_VIDEO_CALL_LOADING,
});

const makeVideoCallSuccess = (data) => ({
  type: types.MAKE_VIDEO_CALL_SUCCESS,
  payload: data,
});
const makeVideoCallFail = (error) => ({
  type: types.MAKE_VIDEO_CALL_FAIL,
  payload: error,
});

const sendSmsLoading = () => ({
  type: types.SEND_SMS_LOADING,
});

const sendSmsSuccess = (data) => ({
  type: types.SEND_SMS_SUCCESS,
  payload: data,
});
const sendSmsFail = (error) => ({
  type: types.SEND_SMS_FAIL,
  payload: error,
});


export const fetchTwilioToken = (doctorName, roomNo) => {
  return function (dispatch) {
    dispatch(makeVideoCallLoading());
    client
      .get(
        `/doctor/chat-token?identity=${doctorName}&room=${roomNo}`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(makeVideoCallSuccess(resp.data));
        console.log("patient audio data", resp.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(makeVideoCallFail(error));
      });
  };
};

export const loadSms = (data) => {
  return function (dispatch) {
    dispatch(sendSmsLoading());

    client
      .post(
        `/doctor/send-sms-conf-link`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(sendSmsSuccess(resp.data));
        console.log("patient audio data", resp.data);
        toast.success("SMS sent successfully");
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendSmsFail(error));
      });
  };
};
