import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

const doctorstatisticsLoading = () => ({
  type: types.DOCTOR_STATISTICS_LOADING,
});

const doctorstatisticsSuccess = (data) => ({
  type: types.DOCTOR_STATISTICS_SUCCESS,
  payload: data,
});
const doctorstatisticsFail = (error) => ({
  type: types.DOCTOR_STATISTICS_FAIL,
  payload: error,
});

const cronJobStatusSuccess = (data) => ({
  type: types.CRON_JOB_STATUS_SUCCESS,
  payload: data,
})
const cronJobStatusFail = (data) => ({
  type: types.CRON_JOB_STATUS_FAIL,
  payload: data,
})

export const doctorstatistics = (fromDate, toDate) => {
  console.log("api checking", fromDate, toDate);
  console.log("api call", fromDate, toDate);
  return function (dispatch) {
    dispatch(doctorstatisticsLoading());
    client
      .get(
        `/admin/doctor-statistics?fromDate=${fromDate}&toDate=${toDate}`
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((resp) => {
        dispatch(doctorstatisticsSuccess(resp.data));
        console.log("Doctor statistics data", resp.data);
      })
      .catch((error) => {
        console.log("error from doctor statistics", error.response);
        dispatch(doctorstatisticsFail(error));
      });
  };
};

export const getLastCronJobStatus = () => {
  return function (dispatch) {
    dispatch(doctorstatisticsLoading());
    client
      .get(
        "/admin/get-last-cron-status"
      )
      .then((resp) => {
        dispatch(cronJobStatusSuccess(resp.data));
        console.log("Doctor statistics data", resp.data);
      })
      .catch((error) => {
        console.log("error from doctor statistics", error.response);
        dispatch(cronJobStatusFail(error));
      });
  };

}
