import React from 'react'
import './Analytics.css'



const PrescriptionAnalysisExport = () => {
  return (
    <div className="prescriptionanalysisexport-section">
        <div className="export-tit">
        {/* <p>EXPORT REPORT</p> */}
        </div>
        <div className="pre-select-file">
                        {/* <SingleSelect data={data} /> */}
                    </div>
                    {/* <div class="export-btn-pres"><button class="export-btn-title">EXPORT</button></div> */}
   
</div>
  )
}

export default PrescriptionAnalysisExport