import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  Legend,
} from "recharts";
import moment from "moment";




export default function PatientVisitsgraph() {
  const doctorInvoiceSummary = useSelector(
    (state) => state.analytics.doctorInvoiceSummary
  );

  const graphToggle = useSelector((state) => state.analytics.graphToggle)

  let data1 = []
  doctorInvoiceSummary?.data?.xAxis[0]?.data?.map((item, i) => {
    return data1.push({ month: item, visits: doctorInvoiceSummary?.data?.series[0]?.data[i] })
  });

  const getDataForYears = (dateStart1, dateEnd1) => {
    var dateStart = moment(dateStart1);
    var dateEnd = moment(dateEnd1);
    var y1 = dateStart.format('Y');
    var y2 = dateEnd.format('Y');

    var timeValues = [];
    const yearlyDates = () => {
      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        timeValues.push(dateStart.format('YYYY-MM'));
        dateStart.add(1, 'month');
      }
    }
    yearlyDates()
    let year1 = []

    data1?.map((item) => {
      const temp = timeValues.filter(t => {
        if (item.month.split("/")[2] === y1 || item.month.split("/")[2] === y2) {
          return 1
        }
      })
      if (temp.length > 0) {
        year1.push({ month: item.month, visits: item.visits })
      }
      return null;
    })
    return year1;
  }
  var dateStart1 = moment().subtract(1, "years").format("YYYY-MM-DD").toString();
  var dateEnd1 = moment().subtract(0, "years").format("YYYY-MM-DD").toString();
  let year1 = getDataForYears(dateStart1, dateEnd1)
  let y1 = moment(dateStart1).format('Y') + " - " + moment(dateEnd1).format('Y');

  var dateStart2 = moment().subtract(2, "years").format("YYYY-MM-DD").toString();
  var dateEnd2 = moment().subtract(1, "years").format("YYYY-MM-DD").toString();
  let year2 = getDataForYears(dateStart2, dateEnd2)
  let y2 = moment(dateStart2).format('Y') + " - " + moment(dateEnd2).format('Y');

  const year1Visits = year1.map(item => item.visits)
  const year1VisitsSum = year1Visits.reduce((partialSum, a) => partialSum + a, 0);

  const year2Visits = year2.map(item => item.visits)
  const year2VisitsSum = year2Visits.reduce((partialSum, a) => partialSum + a, 0);

  const yearData = [
    {
      month: y2,
      visits: year2VisitsSum
    },
    {
      month: y1,
      visits: year1VisitsSum
    }
  ]



  const month1 = []
  const month2 = []
  const month3 = []
  const month4 = []
  const month5 = []
  const month6 = []
  const month7 = []
  const month8 = []
  const month9 = []
  const month10 = []
  const month11 = []
  const month12 = []



  data1?.map((item) => {
    if (item.month.split("/")[1] === "01") {
      return month1.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "02") {
      return month2.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "03") {
      return month3.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "04") {
      return month4.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "05") {
      return month5.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "06") {
      return month6.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "07") {
      return month7.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "08") {
      return month8.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "09") {
      return month9.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "10") {
      return month10.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "11") {
      return month11.push({ month: item.month, visits: item.visits })
    }
    if (item.month.split("/")[1] === "12") {
      return month12.push({ month: item.month, visits: item.visits })
    }

  })

  const month1Visits = month1.map(item => item.visits)
  const month1VisitsSum = month1Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month2Visits = month2.map(item => item.visits)
  const month2VisitsSum = month2Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month3Visits = month3.map(item => item.visits)
  const month3VisitsSum = month3Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month4Visits = month4.map(item => item.visits)
  const month4VisitsSum = month4Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month5Visits = month5.map(item => item.visits)
  const month5VisitsSum = month5Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month6Visits = month6.map(item => item.visits)
  const month6VisitsSum = month6Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month7Visits = month7.map(item => item.visits)
  const month7VisitsSum = month7Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month8Visits = month8.map(item => item.visits)
  const month8VisitsSum = month8Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month9Visits = month9.map(item => item.visits)
  const month9VisitsSum = month9Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month10Visits = month10.map(item => item.visits)
  const month10VisitsSum = month10Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month11Visits = month11.map(item => item.visits)
  const month11VisitsSum = month11Visits.reduce((partialSum, a) => partialSum + a, 0);

  const month12Visits = month12.map(item => item.visits)
  const month12VisitsSum = month12Visits.reduce((partialSum, a) => partialSum + a, 0);

  // console.log("monthsplit", month1)
  const monthlyData = [{
    month: "Jan",
    visits: month1VisitsSum
  },
  {
    month: "Feb",
    visits: month2VisitsSum
  },
  {
    month: "Mar",
    visits: month3VisitsSum
  },
  {
    month: "Apr",
    visits: month4VisitsSum
  },
  {
    month: "May",
    visits: month5VisitsSum
  },
  {
    month: "Jun",
    visits: month6VisitsSum
  },
  {
    month: "Jul",
    visits: month7VisitsSum
  },
  {
    month: "Aug",
    visits: month8VisitsSum
  },
  {
    month: "Sept",
    visits: month9VisitsSum
  },
  {
    month: "Oct",
    visits: month10VisitsSum
  },
  {
    month: "Nov",
    visits: month11VisitsSum
  },
  {
    month: "Dec",
    visits: month12VisitsSum
  },
  ]


  return (
    <BarChart
      width={800}
      height={340}
      data={graphToggle === "dialy" ? data1 : graphToggle === "monthly" ? monthlyData : graphToggle === "yearly" ? yearData : data1}
      margin={{
        top: 30,
        right: 30,
        left: 10,
        bottom: 5,


      }}
    >

      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend />

      <Bar dataKey="visits" barSize={25} stackId="a" fill="#FDC689" />
      <LabelList dataKey="visits" />
    </BarChart>
  );
}
