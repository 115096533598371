import * as types from "../../actionTypes";
import { printInvoice } from "../appointments/printInvoice";
import { toast } from "../../../UIComponents/Toast";
import { patientInvoiceInfo } from "./patientopdinvoice";
import { client } from "../../../utils/axios";

const UpdateVisitOpdInvoice = () => ({
  type: types.GET_OPD_SMS_INVOICE,
});

const UpdateVisitOpdInvoiceSuccess = (data) => ({
  type: types.GET_OPD_SMS_INVOICE_SUCCESS,
  payload: data,
});
const UpdateVisitOpdInvoiceFail = (error) => ({
  type: types.GET_OPD_SMS_INVOICE_FAIL,
  payload: error,
});

export const LoadUpdateVisitOpdInvoice = (data, aptId) => {
  return function (dispatch) {
    dispatch(UpdateVisitOpdInvoice());
    console.log("data sent", aptId);
    client
      .post(
        `/visit/update-visit-invoice`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(UpdateVisitOpdInvoiceSuccess(resp.data));
        toast.success("Invoice updated Successfully");
        dispatch(
          printInvoice({
            aptId: data.aptId,
            invoiceA: data.invoiceA,
            invoiceB: data.invoiceB,
            invoiceC: data.invoiceC,
          })
        );
        console.log("update visit invoice data", resp.data, data);
        dispatch(patientInvoiceInfo({ aptId: data?.aptId, full: true }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(UpdateVisitOpdInvoiceFail(error));
      });
  };
};
