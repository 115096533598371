import axios from "axios";
import { baseUrl } from "./Helper";
import { Toast, toast } from "../UIComponents/Toast";

const url = baseUrl();
let token;

const client = axios.create({
  baseURL: url,

  // timeout: 1000 * 10,
  headers: {
    "Content-Type": "application/json",
    // "X-Master-Key":
    //   "$2b$10$W04ZB2u2j1eoe7G1ndoEAOliyJ/.ANz/4eIKHqYS28qoXVmBzY/8q",
    // "X-Access-Key":
    //   "$2b$10$/wMkcMy7KXbR4.t9sJiqyezD56JRcfHhmWEiIzn/eEGoExf9ynbLS",
  },
});

client.setJwtToken = (newToken) => {
  token = newToken;
};

client.setSessionId = (SessionId) => {
  console.log("setSessionId => SessionId =>", SessionId);

  //  session = SessionId
};

client.interceptors.request.use(
  (request) => requestHandler(request, "server"),
  (error) => errorHandler(error, "server")
);

client.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error, "server")
);
console.log("checking the token", token);
const requestHandler = (request, type) => {
  console.log("checking the type", type, token, request);

  //   if (token && !blacklist.includes(request.url)) {
  request.headers.Authorization = `Bearer ${token}`;
  //   }
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error, type) => {
  console.log(type);

  const isUnauthorized = error?.response?.status === 401;
  if (isUnauthorized) clearSession();
};

const clearSession = () => {
  client
    .post("/api/signout/signout")
    .then((response) => {
      if (response.status === 200) {
        toast.error("You're Session Has Expired");
        setTimeout(() => (window.location = "/"), 2000);
      }
    })
    .catch((err) => {});
};

export { client };
