import { ConsoleSqlOutlined } from "@ant-design/icons";
import { DatePicker, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import deleteIcon from "../../../images/Delete-red.svg";
import { updateServiceDiagnostic } from "../../../redux/actions/Diagnostics/diagnostics";
import { saveDiagnostics } from "../../../redux/actions/Diagnostics/saveDiagnostic";
import Button from "../../../UIComponents/Button/Button";
import Input from "../../../UIComponents/Input/Input";
import Select from "../../../UIComponents/Select/SingleSelect";
import { toast } from "../../../UIComponents/Toast";
import "./BookEdit.css";

const collectedPlace = [
  {
    id: "Lab",
    name: "Lab",
  },
  {
    id: "Hospital",
    name: "Hospital",
  },
  {
    id: "Home",
    name: "Home",
  },
  {
    id: "Clinic",
    name: "Clinic",
  },
  {
    id: "Office",
    name: "Office",
  },
  {
    id: "Other",
    name: "Other",
  },
];
const hospital = [
  {
    id: 1,
    name: "Doctor",
  },
  {
    id: 2,
    name: "Doctor one",
  },
  {
    id: 3,
    name: "Doctor two",
  },
];

const paymentType = [
  {
    id: "Cash",
    name: "Cash",
  },
  {
    id: "Credit Card",
    name: "Credit Card",
  },
  {
    id: "Debit Card",
    name: "Debit Card",
  },
  {
    id: "Net Banking",
    name: "Net Banking",
  },

  {
    id: "Cheque",
    name: "Cheque",
  },
  {
    id: "Mobile Wallet",
    name: "Mobile Wallet",
  },
  {
    id: "Reciept",
    name: "Reciept",
  },
];

const BookEdit = (props) => {
  const getDiagnosticData = useSelector(
    (state) => state.diagnosticsInvoiceSummary.getdiagnostic
  );
  const saveDiagnostic = useSelector(
    (state) => state.saveDiagnostic.saveDiagnostic
  );
  console.log("saveDiagnostic", saveDiagnostic);
  const updateService = useSelector(
    (state) => state.diagnosticsInvoiceSummary.updateService
  );
  console.log("checking for tests", updateService);
  const profileInfo = useSelector((state) => state.profile.profileInfo);
  const getFiltererdTests = useSelector(
    (state) => state.diagnosticsInvoiceSummary?.getDiagnosticsProfile?.rates
  );
  console.log("filrterrate", getFiltererdTests);
  const getSinglePatient = useSelector(
    (state) => state?.getPatient?.getSinglePatient
  );
  console.log("getSinglePatientkui", getSinglePatient);

  const [disc, setDisc] = useState(0);
  const [gross, setGross] = useState();
  const [totalValue, setTotalValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [test, setTest] = useState();
  const [group, setGroup] = useState();
  const [net, setNet] = useState();
  const [types, setTypes] = useState();
  const [typeArray, setTypeArray] = useState();
  const [groupArray, setGroupArray] = useState();
  const [advanceDisc, setAdvanceDisc] = useState();
  const [finalDisc, setFinalDisc] = useState();
  const [finalNet, setFinalNet] = useState();
  const [advDiscPer, setAdvDiscPer] = useState();
  const [typeData, setTypeData] = useState();
  const [sumnet, setSumNet] = useState("");
  const [sumgross, setSumGross] = useState("");
  const [sumdisc, setSumDisc] = useState("");
  useEffect(() => {
    if (Object.keys(updateService)) {
      const totalnet = updateService?.invoice?.map((item) =>
        Number(item?.cost)
      );
      const net = totalnet?.reduce(
        (partialSum, a) => Number(partialSum + a),
        0
      );
      const totalgross = updateService?.invoice?.map((item) =>
        Number(item?.gross)
      );
      const gross = totalgross?.reduce((partialSum, a) => partialSum + a, 0);
      const totaldisc = updateService?.invoice?.map((item) =>
        Number(item?.discAmt)
      );
      const disc = totaldisc?.reduce((partialSum, a) => partialSum + a, 0);
      setSumNet(net);
      setSumGross(gross);
      setSumDisc(disc);
    }
  }, [updateService]);

  // const totalnet = updateService?.invoice?.map((item) => Number(item?.cost));
  // const sumnet = totalnet?.reduce((partialSum, a) => Number(partialSum + a), 0);
  // const totalgross = updateService?.invoice?.map((item) => Number(item?.gross));
  // const sumgross = totalgross?.reduce((partialSum, a) => partialSum + a, 0);
  // const totaldisc = updateService?.invoice?.map((item) =>
  //   Number(item?.discAmt)
  // );
  // const sumdisc = totaldisc?.reduce((partialSum, a) => partialSum + a, 0);
  console.log("sumnet gross and disc", sumnet, sumgross, sumdisc);
  console.log("finalnet gross and disc", finalNet, finalDisc);
  const testingarray = typeArray?.map((item) => item.test);
  const grouparray = groupArray?.map((item) => item.group);
  const dateFormat = "DD-MM-YYYY hh:mm:s A";
  const transactionId = getDiagnosticData?.transactionId;
  const doctorId = profileInfo?.id;
  const format = "DD-MM-YYYY h:mm A";
  let dispatch = useDispatch();
  const groupArrays = getFiltererdTests?.rates.filter(
    (item) => item.test === test
  );
  const discValue = Number(disc).toFixed(2);
  const grossValue = Number(gross).toFixed(2);
  const costValue = Number(totalValue).toFixed(2);
  const discAmtValue = Number(discountValue).toFixed(2);

  useEffect(() => {
    let typeArrray = getFiltererdTests?.rates?.map((item) => item?.type);
    let singleType = [...new Set(typeArrray)];
        setTypeData(singleType);
    console.log("typeArrray", singleType);

    const testArray = getFiltererdTests?.rates?.filter(
      (item) => item?.type === types
    );
    setTypeArray(testArray);
    const groupArray = getFiltererdTests?.rates?.filter(
      (item) => item.test === test
    );
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoiceSummary: {
          ...updateService?.invoiceSummary,
          grossTotal: sumgross,
          discountTotal: finalDisc ? finalDisc : sumdisc,
          netTotal: finalNet ? finalNet : sumnet,
        },
      })
    );
    setGroupArray(groupArray);
  }, [types, test]);

  useEffect(() => {
    console.log("sumnet gross and disc", sumnet, sumgross, sumdisc);

    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoiceSummary: {
          ...updateService?.invoiceSummary,
          grossTotal: sumgross,
          discountTotal: finalDisc ? finalDisc : sumdisc,
          netTotal: finalNet ? finalNet : sumnet,
        },
      })
    );
  }, [sumnet, finalNet]);

  console.log("updateService", updateService);

  useEffect(() => {
    if (saveDiagnostic?.status === 200) {
      props.handleCancel();
    }
  }, [saveDiagnostic]);
  const diagnosticsSave = () => {
    if (!updateService?.transactionDate) {
      toast.error("Please add transactionDate");
    } else if (!updateService?.reportHead?.samplecollecteddate) {
      toast.error("Please add sample collected date");
    } else if (!updateService?.reportHead?.samplereceiveddate) {
      toast.error("Please add sample received date");
    } else if (!updateService?.reportHead?.reportreleasedate) {
      toast.error("Please add sample release date");
    } else if (!updateService?.reportHead?.samplecollectedat) {
      toast.error("Please add sample collected place");
    }
    //  else if (!updateService?.invoiceSummary?.crno) {
    //   toast.error("Please enter cr no");
    // }
    else if (updateService?.invoice?.length === 0) {
      if (!disc) {
        toast.error("Please add disc%");
      } else if (!gross) {
        toast.error("Please add gross");
      }
    } else {
      console.log("updateService", updateService);
      const { testReport, ...rest } = updateService;
      dispatch(saveDiagnostics(rest));
    }
  };
  const discHandler = (e) => {
    console.log("e.target.value", e.target.value);
    if (e.target.value <= 100) {
      console.log("inside disc handler");
      setDisc(e.target.value);
      var value1 = Number(e.target.value) / 100;
      var value2 = Number(gross);
      var value3 = value2 * value1;
      setDiscountValue(value3.toFixed(2));
      setTotalValue(Number(value2 - (value2 * value1).toFixed(2)));
    }
  };
  const grossHandler = (e) => {
    setGross(e.target.value);
    var numVal1 = Number(e.target.value);
    var numVal2 = Number(disc) / 100;
    var num3 = numVal2 * numVal1;
    console.log("num3", num3);
    setDiscountValue(Number(num3.toFixed(2)));
    setTotalValue(Number(numVal1 - (numVal1 * numVal2).toFixed(2)));
  };
  const discountHandler = (e) => {
    setDiscountValue(e.target.value);
  };
  const serviceTypeHandler = (value) => {
    setTest(value);
    setGross(groupArrays[0]?.rate || 0);
    setTotalValue(groupArrays[0]?.rate || 0);
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        originalTests: [...updateService?.testReport?.map((item) => item.test)],
      })
    );
  };
  const groupTypeHandler = (value) => {
    setGroup(value);
  };
  const onTypeHandler = (value) => {
    setTypes(value);
    setTest("");
    setGroup("");
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoiceSummary: {
          ...updateService?.invoiceSummary,
          grossTotal: sumgross,
          discountTotal: sumdisc,
          netTotal: Number(sumnet),
        },
      })
    );
  };

  const onDiscSubmit = (e) => {
    if (e.key === "Enter") {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      setDiscountValue(Number(num3.toFixed(2)));
      setTotalValue(Number(numVal1 - (numVal1 * numVal2).toFixed(1)));
    }
  };
  const onAdvanceDiscHandle = (e) => {
    if (e.target.value > 100) e.target.value = 100;
    setAdvDiscPer(e.target.value);
    var n1 = Number(e.target.value) / 100;
    var n2 = Number(sumnet);
    var n3 = n1 * n2;
    setAdvanceDisc(n3.toFixed(2));
    const advDisc = (sumdisc + n3).toFixed(2);
    setFinalDisc(Number(advDisc));
    const net = (sumnet - n3).toFixed(2).toString();
    setFinalNet(Number(net));
    console.log("before calc", sumnet - n3);
  };
  console.log("after calc", finalNet);
  window.onclick = (e) => {
    var numVal1 = Number(gross);
    var numVal2 = Number(disc) / 100;
    var num3 = numVal2 * numVal1;
    setDiscountValue(num3.toFixed(2));
    setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(1));
  };
  const onKeyDown = (e) => {
    if (e.key === "Tab") {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      setDiscountValue(num3.toFixed(2));
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(1));
    }
  };

  const addServices = () => {
    const ifTestExists = updateService?.invoice?.some(
      (item) => item.test === test
    );
    const testToggle = ifTestExists ? ifTestExists : false;
    console.log("ifTestExists", ifTestExists, testToggle, test, updateService);
    if (!types) {
      toast.error("Please enter all the input fields");
    } else if (!test) {
      toast.error("Please enter all the input fields");
    } else if (testToggle) {
      toast.error("Selected test already exists.");
    } else if (!gross) {
      toast.error("Please enter gross");
    } else {
      dispatch(
        updateServiceDiagnostic({
          ...updateService,
          invoice: [
            ...updateService?.invoice,
            {
              test: test,
              group: group,
              disc: discValue,
              gross: grossValue,
              discAmt: discAmtValue,
              cost: costValue,
            },
          ],
        })
      );
      setAdvDiscPer("");
      setAdvanceDisc("");
      setFinalNet("");
      setFinalDisc("");
      setTest("");
      setDisc("0");
      setGross("");
      setDiscountValue("");
      setTypes("");
      setGroup("");
      setNet("");
      setTotalValue("");
    }
  };
  const handleCancel = () => {
    setTest("");
    setDisc("0");
    setGross("");
    setDiscountValue("");
    setTypes("");
    setGroup("");
    setNet("");
    setTotalValue("");
  };
  const deleteServiceData = (index) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoice: [...updateService?.invoice].filter((item, i) => i !== index),
      })
    );
    setAdvDiscPer("");
    setAdvanceDisc("");
    setFinalNet("");
    setFinalDisc("");
  };
  const onTransitionChange = (date, dateString) => {
    dispatch(
      updateServiceDiagnostic({ ...updateService, transactionDate: dateString })
    );
  };
  const onDateTimeHandle = (date, dateString) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        reportHead: {
          ...updateService?.reportHead,
          samplecollecteddate: dateString,
        },
      })
    );
  };
  const onReleasedDateHandle = (date, dateString) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        reportHead: {
          ...updateService?.reportHead,
          samplereceiveddate: dateString,
        },
      })
    );
  };
  const onReportReleasedDateHandle = (date, dateString) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        reportHead: {
          ...updateService?.reportHead,
          reportreleasedate: dateString,
        },
      })
    );
  };
  const onPlaceHandle = (value) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        reportHead: { ...updateService?.reportHead, samplecollectedat: value },
      })
    );
  };
  const doctorReferalHandler = (value) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoiceSummary: {
          ...updateService?.invoiceSummary,
          doctorreferral: value,
        },
      })
    );
  };
  const hospitalReferalHandle = (value) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoiceSummary: {
          ...updateService?.invoiceSummary,
          hospitalreferral: value,
        },
      })
    );
  };
  const crNoHandle = (e) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoiceSummary: {
          ...updateService?.invoiceSummary,
          crno: e.target.value,
        },
      })
    );
  };
  const onPaymentHandle = (value) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoiceSummary: {
          ...updateService?.invoiceSummary,
          paymentMode: value,
        },
      })
    );
  };
  const onCashHandle = (e) => {
    dispatch(
      updateServiceDiagnostic({
        ...updateService,
        invoiceSummary: {
          ...updateService?.invoiceSummary,
          payed: Number(e.target.value),
        },
      })
    );
  };

  return (
    <div>
      <div className="book-edit-container">
        <div className="book-edit-left-section">
          <div className="left-section-inputs">
            <p>Transaction Date</p>
            <div>
              <Space direction="vertical" size={12}>
                <DatePicker
                  showTime={{
                    format: "HH:MM:S a",
                  }}
                  value={
                    updateService?.transactionDate !== ""
                      ? moment(updateService?.transactionDate)
                      : ""
                  }
                  onChange={onTransitionChange}
                />
              </Space>
            </div>
          </div>
          <div className="left-section-inputs">
            <p>Sample Collected On (SCT)</p>
            <Space direction="vertical" size={12}>
              <DatePicker
                showTime={{
                  format: "h:mm a",
                }}
                onChange={onDateTimeHandle}
                value={
                  updateService?.reportHead?.samplecollecteddate
                    ? moment(
                        updateService?.reportHead?.samplecollecteddate,
                        dateFormat
                      )
                    : ""
                }
                use12Hours
                format={format}
              />
            </Space>
          </div>

          <div className="left-section-inputs">
            <p>Sample Collected Place</p>
            <p></p>
            <Select
              className={"left-inputs"}
              value={updateService?.reportHead?.samplecollectedat}
              data={collectedPlace}
              onChange={onPlaceHandle}
            ></Select>
          </div>
          <div className="left-section-inputs">
            <p>Sample Recieved On (SRT)</p>
            <Space direction="vertical" size={12}>
              <DatePicker
                showTime={{
                  format: "h:mm a",
                }}
                onChange={onReleasedDateHandle}
                value={
                  updateService?.reportHead?.samplereceiveddate
                    ? moment(
                        updateService?.reportHead?.samplereceiveddate,
                        dateFormat
                      )
                    : ""
                }
                use12Hours
                format={format}
              />
            </Space>
          </div>
          <div className="left-section-inputs">
            <p>Report Released On (RRT)</p>
            <Space direction="vertical" size={30}>
              <DatePicker
                showTime={{
                  format: "h:mm a",
                }}
                onChange={onReportReleasedDateHandle}
                value={
                  updateService?.reportHead?.reportreleasedate
                    ? moment(
                        updateService?.reportHead?.reportreleasedate,
                        dateFormat
                      )
                    : ""
                }
                use12Hours
                format={format}
              />
            </Space>
          </div>
          <div className="left-section-inputs">
            <p>Doctor Referral</p>
            <Select
              className={"left-inputs"}
              placeholder="Doctor"
              // data={doctor}
              value={updateService?.invoiceSummary?.referral}
              disabled={updateService?.invoiceSummary?.referral ? true : false}
              onchange={doctorReferalHandler}
            ></Select>
          </div>
          <div className="left-section-inputs">
            <p>Hospital Referral</p>
            <Select
              className={"left-inputs"}
              placeholder="Hospital"
              // data={hospital}
              value={updateService?.invoiceSummary?.hospitalReferral}
              disabled={
                updateService?.invoiceSummary?.hospitalReferral ? true : false
              }
              onchange={hospitalReferalHandle}
            ></Select>
          </div>
          <div className="left-section-inputs">
            <p>CR #</p>
            <Input
              className={"left-inputs"}
              value={updateService?.invoiceSummary?.crno}
              onChange={crNoHandle}
            />
          </div>
        </div>

        <div className="book-edit-right-section">
          <h3>Services</h3>
          <div className="type-test-group-container">
            <div className="type-div">
              <p>Type</p>
              <Select
                value={types}
                data={typeData}
                onChange={onTypeHandler}
              ></Select>
            </div>
            <div className="test-div">
              <p>Test</p>
              <Select
                data={testingarray}
                value={test}
                onChange={serviceTypeHandler}
              ></Select>
            </div>
            <div className="group-div">
              <p>Group</p>
              <Select
                data={grouparray}
                value={grouparray}
                onChange={groupTypeHandler}
              ></Select>
            </div>
          </div>

          <div className="disc-rate-discount-net-container">
            <div className="inputs-sec">
              <div className="disc-content">
                <p>Disc %</p>
                <Input type="number" value={disc} onChange={discHandler} />
              </div>
              <div className="disc-content">
                <p>Rate</p>
                <Input
                  type="number"
                  value={gross}
                  onChange={grossHandler}
                  onKeyPress={onDiscSubmit}
                  onKeyDown={onKeyDown}
                />
              </div>
              <div className="disc-content">
                <p>Discount</p>
                <Input
                  disabled={true}
                  type="number"
                  value={discountValue}
                  onChange={discountHandler}
                />
              </div>
              <div className="disc-content">
                <p>Net</p>
                <Input disabled={true} type="number" value={totalValue} />
              </div>
            </div>
            <div className="button-sec">
              <Button
                children="ADD"
                className="book-edit-add-button"
                onClick={addServices}
              />
            </div>
          </div>

          <div className="book-edit-table-container">
            <table className="book-edit-services-table">
              <tr className="book-edit-services-head-row">
                <th className="book-edit-services-head">Services</th>
                <th>%</th>
                <th>Rate</th>
                <th>Disc</th>
                <th>Net</th>
              </tr>
              {updateService?.invoice?.map((item, i) => (
                <tr className="book-edit-services-data-row">
                  <td>
                    {item?.test} <br></br> {item?.group}
                  </td>
                  <td>
                    <Input value={item?.disc} />
                  </td>
                  <td>
                    <Input value={item?.gross} />
                  </td>
                  <td>
                    <Input value={item?.discAmt} />
                  </td>
                  <td>
                    <Input value={item?.cost} />
                  </td>
                  <td>
                    <img
                      className="book-edit-table-delete"
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => deleteServiceData(i)}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div className="disc-rate-net-container">
            <div className="overall-disc-content">
              <p>Discount %</p>
              <Input
                type="number"
                value={advDiscPer}
                onChange={onAdvanceDiscHandle}
              />
            </div>

            <div className="overall-disc-content">
              <p className="book-edit-font-size">Discount Amount</p>
              <Input type="number" disabled={true} value={advanceDisc} />
            </div>
            <div className="net-gross-disc-containter">
              {finalNet ? (
                <p>
                  Net - {finalNet ? finalNet : sumnet ? sumnet.toFixed(2) : "0"}
                </p>
              ) : (
                <p>Net - {sumnet ? sumnet.toFixed(2) : "0"}</p>
              )}
              <span>|</span>{" "}
              <p>Gross - {sumgross ? sumgross.toFixed(2) : "0"} </p>
              <span>|</span>{" "}
              {finalDisc ? (
                <p>Disc - {finalDisc ? finalDisc : "0"} </p>
              ) : (
                <p>Disc - {sumdisc ? sumdisc.toFixed(2) : "0"} </p>
              )}
            </div>
          </div>

          <div className="book-edit-cash-type-container">
            <p>Type</p>
            <div className="book-edit-cash-input">
              <Select
                className={"cash-input"}
                data={paymentType}
                onChange={onPaymentHandle}
              ></Select>
              <div className="book-edit-cash-grey-input">
                <Input onChange={onCashHandle} />
              </div>
            </div>
            <div className="book-edit-button-container">
              <Button
                onClick={diagnosticsSave}
                className="book-edit-save-button"
              >
                SAVE & UPDATE
              </Button>
              <Button
                onClick={handleCancel}
                className="book-edit-cancel-button"
              >
                CLEAR
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookEdit;
