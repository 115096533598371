import * as types from "../actionTypes";

const initialState = {
  number: "",
  relationship: "",
  empId: "",
  address: "",
};

export const rbiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RBI_MOBILE_NUMBER:
      return {
        ...state,
        number: action.payload,
      };
    case types.GET_RELATIONSHIP:
      return {
        ...state,
        relationship: action.payload,
      };
    case types.EMP_ID:
      return {
        ...state,
        empId: action.payload,
      };
    case types.CURRENT_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    default:
      return state;
  }
};
