import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import moment from "moment";
import { clearDatesOnTab, dailyCollection } from "../../../redux/actions/analytics/analytics";
import SingleSelect from '../../../UIComponents/Select/SingleSelect';
import { DatePicker } from 'antd';
import Button from '../../../UIComponents/Button/Button';
import Invoicelogo from '../../../images/Invoicelogo.png';
import './VisitAnalysis.css';
import '../Patientstats/InvoiceTable.css';
import { Table } from 'antd';
import { toast } from '../../../UIComponents/Toast'
import { clearDropDownData, hideDataSuccess } from '../../../redux/actions/analytics/analytics';
import calenderLogo from '../../../images/calender.svg';
import { useEffect } from "react";

const { Column, ColumnGroup } = Table;

const data = [
  {
    id: "1",
    name: "No data"
  },

];



const VisitAnalysis = () => {
  let dispatch = useDispatch();
  const dailyCollectionInfo = useSelector(state => state.analytics.dailyCollectionInfo);
  console.log("dasd",dailyCollectionInfo);
  const hide = useSelector(state => state.analytics.hideData)
  const clearDates = useSelector(state => state.analytics.clearDates)
  const dropDownInfo = useSelector(state => state.analytics.clearDropDowns)
  const [consultant, setConsultant] = useState("");
  const [hash, setHash] = useState([]);

  const handleDateChange = (date, dateString) => {
    dispatch(clearDatesOnTab(dateString))
  };

  const handleConsultantChange = (value) => {
    dispatch(clearDropDownData({ ...dropDownInfo, consultant: value }))
  };
  console.log("one", consultant);

  useEffect(() => {
  let finaldailyCollectionInfo= dailyCollectionInfo?.map((item, i) => {
      return {...item,hash:`${i+1}`}
    })
    console.log("finaldailyCollectionInfo",finaldailyCollectionInfo)
    setHash(finaldailyCollectionInfo)
  },[dailyCollectionInfo])



  const onGeneratedailyCollectionClick = () => {
    if (!clearDates) {
      toast.error("Please select the dates")
      dispatch(hideDataSuccess(false))
    } else {
      dispatch(dailyCollection(clearDates));
      dispatch(hideDataSuccess(true))
    }
  };




  return (
    <div>
      <div className="prescription-top-left-sec">
        <div className="from-to-date">
          <p className='date-title'>DATES</p>
          <DatePicker placeholder="DD/MM/YYYY" value={clearDates !== "" ? moment(clearDates) : ""} onChange={handleDateChange} />
          <img className='analytics-calender' src={calenderLogo} alt='calender' />

        </div>
        <div className="prescript-consultant">
          <p className="consultant-title">CONSULTANT</p>
          <SingleSelect data={data} width="100%" onChange={handleConsultantChange} value={dropDownInfo?.consultant} />
        </div>
        <div className="prescript-date">
          <Button className="pres-generate-btn" onClick={onGeneratedailyCollectionClick}>GENERATE</Button>
        </div>
      </div>
      {hide ?
        <div>
          <img className="invoice-logo" src={Invoicelogo} alt="Invoicelogo" />
          <p className='date-range'><b>Date Range:</b> {clearDates}</p>

          <br></br>
          <div className='invoice-container'>

            <Table dataSource={hash} pagination={{ current: 1, pageSize: dailyCollectionInfo?.length + 1 }}>

              <ColumnGroup title="">
                <Column title="#" dataIndex="hash" key="hash" />
                <Column title="Patient" dataIndex="patientName" key="Patient" className="table-col" />
                <Column title="Age/Sex" dataIndex="age" key="AgeSex" className="table-col1" />
                <Column title="ID" dataIndex="patientId" key="ID" className="table-col" />
                <Column title="City" dataIndex="city" key="City" className="table-col" />
                <Column title="Mobile" dataIndex="mobile" key="Mobile" className="table-col" />
                <Column title="Type" dataIndex="type" key="Type" className="table-col" />
                {/* <Column title="Diagnosis" dataIndex="diagnosis" key="Diagnosis" /> */}
                <Column title="In" dataIndex="In" key="In" className="table-col" />
                <Column title="Start" dataIndex="consultationStart" key="Start" className="table-col" />
                <Column title="End" dataIndex="consultationEnd" key="End" className="table-col" />
                <Column title="Time(m)" dataIndex="timeslot" key="Time" className="table-col" />
                <Column title="Doctor" dataIndex="doctorName" key="Doctor" className="table-col" />
                <Column title="Status" dataIndex="status" key="Status" className="table-col" />
              </ColumnGroup>


            </Table>
          </div>
        </div>
        : ""}
    </div>
  )
}

export default VisitAnalysis