import './Analytictab.css';
import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import Invoicesummary from '../Invoicesummary/Invoicesummary';
import Prescriptionanalysis from '../Prescriptionanalysis/Prescriptionanalysis';
import InvoiceDetails from '../InvoiceDetails/InvoiceDetails';
import Patientstats from '../Patientstats/Patientstats';
import VisitAnalysis from '../Visitanalysis/VisitAnalysis';
import DailyCollection from '../Dailycollection/DailyCollection';
import invoicesummaryblue from '../../../images/invoicesummaryblue.svg';
import invoicedetailsblue from '../../../images/invoicedetailsblue.svg';
import preanablue from '../../../images/preanablue.svg';
import invoicesummarywhite3 from '../../../images/invoicesummarywhite3.svg';
import preanawhite from '../../../images/presanawhite.svg'
import invoicedetailwhite from '../../../images/invoicedetailwhite.svg';
import patientwhitestatsicon from '../../../images/patientwhitestatsicon.svg';
import visitanawhite from '../../../images/visitanawhite.svg';
import visitanablue from '../../../images/visitanablue.svg';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataSuccess, clearDropDownData, hideDataSuccess } from '../../../redux/actions/analytics/analytics';
import dailywhitecollec from '../../../images/dailywhitecollec.svg'
import dailybluecollec from '../../../images/dailybluecollec.svg'
import patientbluestatsicon from '../../../images/patientbluestatsicon.svg'
import { clearDatesOnTab } from '../../../redux/actions/analytics/analytics';
import { exportToggleSuccess } from '../../../redux/actions/analytics/analytics';




const Analytictab = ({ setPrescription }) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const clear = useSelector(state => state.analytics.clearData)
  console.log("clear", clear);
  const myDomainInfo = useSelector(state => state.login.myDomainInfo)
  const [active, setActive] = useState("1")

  function callback(key) {
    console.log(key);
    setActive(key)
  }

 
  const prescriptionClick = () => {
    setPrescription(true)
    dispatch(clearDataSuccess({
      startDate: null,
      endDate: null
    }))
    // console.log("clicked")
  }

  const prescriptionClick2 = () => {
    setPrescription(false)
    dispatch(hideDataSuccess(false))
    dispatch(clearDataSuccess({
      startDate: null,
      endDate: null
    }))
    dispatch(clearDropDownData({ doctors: "", mode: "", location: "", consultant: "" }))
    dispatch(clearDatesOnTab(""))
    dispatch(exportToggleSuccess(false))


  }
  useEffect(() => {
    document.documentElement.style.setProperty(`--variablename`, myDomainInfo?.DomainStyles?.buttonColor ? myDomainInfo?.DomainStyles?.buttonColor : "#00576E");
  }, [])

  return (
    <div className='analytic-nav-bar'  >
      <div className="analytic-tab" >
        <Tabs defaultActiveKey={active} className='mytabs' onChange={callback} centered >

          <TabPane
            key="1"
            tab={
              <span className='invoice-summary-icon-title-tab'>
                {active === "1" ? <img src={invoicesummarywhite3} alt="invoice-active" /> : <img src={invoicesummaryblue} alt="invoice-summary" />}
                <p className='analytic-tab-title' onClick={() => prescriptionClick2()}> Invoice summary</p>
              </span>
            }


          >
            <Invoicesummary />
          </TabPane>
          <TabPane
            tab={
              <span className='invoice-detail-icon-tab' onClick={() => prescriptionClick2()}>
                {active === "2" ? <img src={invoicedetailwhite} alt="invoicedetail-active" /> : <img src={invoicedetailsblue} alt="invoice-detail" />}

                <p className='analytic-tab-title' >   Invoice Details</p>
              </span>
            }
            key="2"
          >
            <InvoiceDetails />
          </TabPane>


          <TabPane
            tab={
              <span className='patient-stats-icon-tab' onClick={() => prescriptionClick2()}>
                {active === "3" ? <img src={patientwhitestatsicon} alt="patientstas-active" /> : <img src={patientbluestatsicon} alt="invoice-detail" />}

                <p className='analytic-tab-title'  >  Patient Stats</p>
              </span>
            }
            key="3"
          >
            <Patientstats />
          </TabPane>

          <TabPane
            tab={
              <span className='prescription-analysis-icon-tab' onClick={() => prescriptionClick()} >
                {active === "4" ? <img src={preanawhite} alt="presc-active" /> : <img src={preanablue} alt="invoice-detail" />}

                <p className='analytic-tab-title' >Prescription   Analysis</p>
              </span>
            }
            key="4"


          >
            <Prescriptionanalysis />
          </TabPane>

          <TabPane
            tab={
              <span className='visit-analysis-icon-title-tab' onClick={() => prescriptionClick2()}>
                {active === "5" ? <img src={visitanawhite} alt="visit-active" /> : <img src={visitanablue} alt="invoice-detail" />}

                <p className='analytic-tab-title'>  Visit Analysis</p>
              </span>
            }
            key="5"
          >
            <VisitAnalysis />
          </TabPane>

          <TabPane className="daily-analysis-icon-title-tab"
            tab={
              <span className='daily-collection-icon-title-tab' onClick={() => prescriptionClick2()} >

                <p className='daily-con'>
                  {active === "6" ? <img src={dailywhitecollec} alt="collection-active" /> : <img src={dailybluecollec} alt="invoice-detail" />}
                </p>


                <p className='analytic-tab-title'> Daily Collection  </p>
              </span>
            }
            key="6"
          >
            <DailyCollection />
          </TabPane>

        </Tabs>

      </div>
    </div>
  )
}

export default Analytictab