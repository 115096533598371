import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { baseUrl } from "../../../utils/Helper";
import { client } from "../../../utils/axios";

export const addInvoice = (payload) => ({
  type: types.ADD_BUTTON,
  payload,
});
const editInvoiceLoading = () => ({
  type: types.EDIT_INVOICE_LOADING,
});

const editInvoiceSuccess = (data) => ({
  type: types.EDIT_INVOICE_SUCCESS,
  payload: data,
});
const editInvoiceFail = (error) => ({
  type: types.EDIT_INVOICE_FAIL,
  payload: error,
});

export const editInvoice = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(editInvoiceLoading());
    client
      .post(
        `/visit/get-my-patient-selected-visit`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(editInvoiceSuccess(resp.data));
        toast.success("Edited invoice Successfully");
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(editInvoiceFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
