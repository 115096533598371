import React from 'react'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './DnaFragmentation.css'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


export const DnaFragmentation = () => {
  const dispatch =  useDispatch()
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const [ inputValue, setInputValue ] = useState("")
  
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }

  const onInputChange = (e) => {
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'DNA FRAGMENTATION TEST')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'DNA FRAGMENTATION TEST'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
        <div className='dna-main'>
            <div className='dna-container'>
                <div className='dna-grid-container'>
                    <div className='dna-1 padd' onClick={() => onLabelClicked("NAME OF THE KIT")} >NAME OF THE KIT</div>
                    <div className='dna-2'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} name="NAME OF THE KIT"  onChange={onInputChange} /></div>
                    <span className='dna-border1'></span>
                    <div className='dna-3 padd' onClick={() => onLabelClicked("Date of Exp")} >Date of Exp</div>
                    <div className='dna-4'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}  name="Date of Exp"  onChange={onInputChange} /></div>
                    <span className='dna-border2'></span>
                    <div className='dna-5 padd'  onClick={() => onLabelClicked("DNA Fragmentation Test Done after")}>DNA Fragmentation Test Done after</div>
                    <div className='dna-6'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} name="DNA Fragmentation Test Done after" onChange={onInputChange} /></div>
                    <span className='dna-border3'></span>
                    <div className='dna-7 padd' onClick={() => onLabelClicked("No. of Sperm Evaluated")} >No. of Sperm Evaluated</div>
                    <div className='dna-8'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} name="No. of Sperm Evaluated"  onChange={onInputChange} /></div>
                    <span className='dna-border4'></span>
                    <div className='dna-9 padd' onClick={() => onLabelClicked("No. of Sperm With Non-Fragmented DNA")} >No. of Sperm With Non-Fragmented DNA</div>
                    <div className='dna-10'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} name="No. of Sperm With Non-Fragmented DNA"  onChange={onInputChange} /></div>
                    <span className='dna-border5'></span>
                    <div className='dna-11 padd' onClick={() => onLabelClicked("No. of Sperm With Fragmented DNA")} >No. of Sperm With Fragmented DNA</div>
                    <div className='dna-12'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} name="No. of Sperm With Fragmented DNA"  onChange={onInputChange} /></div>
                    <span className='dna-border6'></span>
                    <div className='dna-13 padd' onClick={() => onLabelClicked("No. of Degraded Sperm")}  >No. of Degraded Sperm</div>
                    <div className='dna-14'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} name="No. of Degraded Sperm" onChange={onInputChange} /></div>
                    <span className='dna-border7'></span>
                    <div className='dna-15 padd'  onClick={() => onLabelClicked("DNA Fragmentation Index")} >DNA Fragmentation Index</div>
                    <div className='dna-16'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} name="DNA Fragmentation Index" onChange={onInputChange} /></div>
                    <span className='dna-border8'></span>
                    <div className='dna-17 padd' onClick={() => onLabelClicked("Normal Range")} >Normal Range</div>
                    <div className='dna-18'><TextAreaAnt  value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} name="Normal Range"  onChange={onInputChange} /></div>
                    <span className='dna-border9'></span>
                    <div className='dna-19 padd' onClick={() => onLabelClicked("Remarks")} >Remarks</div>
                    <div className='dna-20'><TextAreaAnt value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} name="Remarks"  onChange={onInputChange} /></div>
                    <span className='dna-border10'></span>
                </div>
            </div>
            <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
        </div>
        <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
    </div>
  )
}
