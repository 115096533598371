import React from 'react'
import './PrescriptionDiagnosistable.css'
import { useSelector } from 'react-redux/es/exports'
const PrescriptionDiagnosistable = () => {

  const prescriptionAnalysisInfo = useSelector(state => state.analytics.prescriptionAnalysisInfo)
  const hide = useSelector(state => state.analytics.hideData)


  return (
    <div>

   { hide ?
    <div className='pres-diagnosis-first-table'>
   

      {prescriptionAnalysisInfo?.medicines?.map(item => (

        <div className="diagnosis-medicine-capsule-section">
          <div className='diagnosis-section'>

            <p className='diagnosis-medicine-capsule'>{item.name}</p>
            <p className="pres-diagnosis-medicine-capsule-count">{item.value}</p>


          </div>

        </div>
      ))}
      

    </div>
    : "" }
    </div>
  )
}

export default PrescriptionDiagnosistable