import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './Aptt.css'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const Aptt = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
  
    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'APTT')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item.test === 'APTT'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
   
    return (
        <div>
            <div className='apt-main'>
                <div className='apt-container'>
                    <div className='apt-grid-container'>
                        <div className='padd'>ACTIVATED PARTIAL THROMBOPLASTIN TIME</div>
                        <div className='padd'>APTT</div>
                        <Input className='aptinputs' type="number"   name="APTT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
                        <div className='padd'>SECOND</div>
                        <div className='padd'>21-33</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border1'></span>
                        <div className='padd'>ACTIVATED PARTIAL THROMBOPLASTIN TIME</div>
                        <div className='padd'>CONTROL</div>
                        <Input className='aptinputs' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}  name="CONTROL" onChange={onInputChange}/>
                        <div className='padd'>SECOND</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border2'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}
