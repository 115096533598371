import { Modal, Radio, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import deleteIcon from "../../../images/delete-icon.png";
import photoAttach from "../../../images/photo-attach.png";
import { getUrl } from "../../../redux/actions/appointments/createPatient";
import "../CreatePatient/CreatePatient.css";
import NewPatient from "./NewPatient";
import { toast } from "../../../UIComponents/Toast";

const CreatePatient = () => {
  const imageUrl = useSelector(
    (state) => state.appointments?.createPatient.ImageUrl
  );
  const getSinglePatientData = useSelector(
    (state) => state.appointments.getPatient.getPatient?.profileImage
  );
  console.log("getSinglePatientData", getSinglePatientData);

  let dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [webCam, setWebCam] = useState(false);
  const [photo, setPhoto] = useState("");
  const [attachedPhoto, setAttachedPhoto] = useState("");
  const [attached, setAttached] = useState(false);
  const [photoModel, setPhotoModel] = useState(false);
  const [photoCheck, setPhotoCheck] = useState(true);
  const webcamRef = React.useRef(null);
  const inputFileRef = React.useRef();

  //Tooltip
  const clearing = <span>Remove Image</span>;
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  useEffect(() => {
    console.log("inputvalue", inputFileRef);
  }, [inputFileRef]);

  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
    if (e.target.value === "photo") {
      setPhotoModel(true);
      setWebCam(true);
    }
    if (e.target.value === "attach") {
      inputFileRef.current.click();
    }
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("imgg", imageSrc);
    dispatch(getUrl({ image: imageSrc }));
  }, [webcamRef]);

  const onImageUpload = (e) => {
    console.log("images in onChange", e.target.files);
    const fileExtension = e.target.files[0].name.split(".").pop();
    if (
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg"
    ) {
      var input = document.getElementById("inputFile");
      var fReader = new FileReader();
      fReader.readAsDataURL(input.files[0]);
      console.log("ijejijeijije", input.files);
      fReader.onloadend = function (event) {
        dispatch(getUrl({ photo: event.target.result }));
        e.target.value = null;
        console.log("imagesrc", event.target.result, input.files[0]);
        setAttached(true);
      };
    } else {
      toast.error("Please select an image");
      e.target.value = null;
    }
  };

  const handleCancel = () => {
    if (webCam == true) {
      setWebCam(false);
      setTimeout(() => {
        setPhotoModel(false);
      }, 10);
    }
  };

  const onDeleteHandle = () => {
    dispatch(getUrl({ image: "" }));
    dispatch(getUrl({ photo: "" }));
    setValue(null);
  };
  const onSave = () => {
    if (webCam == true) {
      setWebCam(false);
      setTimeout(() => {
        setPhotoModel(false);
      }, 10);
    }
  };

  return (
    <div>
      <div className="empty-header">
        <p></p>
      </div>
      <div className="creating-patient-div">
        <div className="patient-attach-photo-div">
          <h2>CREATE/EDIT PATIENT</h2>
          <div style={{ display: "none" }}>
            <input
              type="file"
              ref={inputFileRef}
              id="inputFile"
              accept="image/*"
              onChangeCapture={onImageUpload}
            />
          </div>
          <div className="photo-atttach-div">
            <div className="customer-photo-div">
              {getSinglePatientData ? (
                <img
                  src={
                    getSinglePatientData ? getSinglePatientData : photoAttach
                  }
                  className="photo"
                  alt="phoattachto-"
                />
              ) : value == "attach" ? (
                imageUrl.photo ? (
                  <img
                    src={
                      getSinglePatientData || imageUrl.photo
                        ? getSinglePatientData || imageUrl?.photo
                        : photoAttach
                    }
                    className="photo"
                    alt="phoattachto-"
                  />
                ) : (
                  <img
                    src={photoAttach}
                    className="default-img"
                    alt="photo-attach"
                  />
                )
              ) : imageUrl?.image ? (
                <img
                  src={imageUrl?.image ? imageUrl?.image : photoAttach}
                  className="photo"
                />
              ) : (
                <img
                  src={photoAttach}
                  className="default-img"
                  alt="photo-attach"
                />
              )}
            </div>
            <div className="attach">
              <Radio.Group onChange={onChangeRadio} value={value} name="Photo">
                <Radio value={"photo"}>Photo</Radio>
                <Radio value={"attach"}>Attach</Radio>
              </Radio.Group>
            </div>
            <div className="new-patient-delete-icon-div">
              <Tooltip placement="bottom" title={clearing}>
                <img
                  src={deleteIcon}
                  onClick={onDeleteHandle}
                  alt="deleteIcon"
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div>
        <NewPatient />
      </div>
      <Modal
        title="Capture"
        visible={photoModel}
        onCancel={handleCancel}
        width={500}
        className="appointment-modal-container"
      >
        <div>
          {webCam ? (
            imageUrl.image ? (
              <div>
                <img
                  src={imageUrl.image ? imageUrl?.image : ""}
                  alt="photo-attach"
                />
                <div className="buttons">
                  <button
                    className="retake-button"
                    onClick={() => dispatch(getUrl({ image: "" }))}
                  >
                    Retake
                  </button>
                  <button className="retake-button" onClick={onSave}>
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <Webcam
                  className="video-capture"
                  audio={false}
                  height={250}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={1280}
                  videoConstraints={videoConstraints}
                />
                <button className="capture-button" onClick={capture}>
                  Capture photo
                </button>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CreatePatient;
