import React, { useState, useEffect } from "react";
import "./DonotDisturb.css";
import Table from "../../../UIComponents/Table/Table";
import Button from "../../../UIComponents/Button/Button";
import deleteIcon from "../../../images/delete-icon.svg";
import { Modal } from "antd";
import Input from "../../../UIComponents/Input/Input";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import { useDispatch, useSelector } from "react-redux";
// import { alternateNumber } from '../../../redux/actions/admin/alternateNum'
import { patientdnd } from "../../../redux/actions/patientDND/patientdnd";
import { patientsubmit } from "../../../redux/actions/patientDND/patientsubmit";
import toast from "react-hot-toast";

// const data = [
//   {
//     key: 1,
//     location: "Hyderabad",
//     phoneNumber: "9855566656",
//     name: "Evan",
//     icons: "",
//   },
//   {
//     key: 2,
//     location: "Bangalore",
//     phoneNumber: "9866566652",
//     name: "France",
//   },
//   {
//     key: 3,
//     location: "Warangal",
//     phoneNumber: "9588466633",
//     name: "Ulysses",
//   },
//   {
//     key: 4,
//     location: "Anantapur",
//     phoneNumber: "9855566656",
//     name: "Tyisha",
//   },
//   {
//     key: 5,
//     location: "Dharmavaram",
//     phoneNumber: "9866566652",
//     name: "Eric",
//   },
//   {
//     key: 6,
//     location: "Hyderabad",
//     phoneNumber: "9588466633",
//     name: "Marg",
//   },
//   {
//     key: 7,
//     location: "Bangalore",
//     phoneNumber: "9855566656",
//     name: "Laquita",
//   },
//   {
//     key: 8,
//     location: "Warangal",
//     phoneNumber: "9866566652",
//     name: "Lura",
//   },
//   {
//     key: 9,
//     location: "Anantapur",
//     phoneNumber: "9588466633",
//     name: "Yuette",
//   },
//   {
//     key: 10,
//     location: "Dharmavaram",
//     phoneNumber: "9855566656",
//     name: "Fernanda",
//   },
//   {
//     key: 11,
//     location: "Guntur",
//     phoneNumber: "9866566652",
//     name: "Charlesetta",
//   },
// ];

function DonotDisturb() {
  const columns = [
    {
      title:"",
      dataIndex:"icons"
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "mobile",
    },
    {
      title: "NAME",
      dataIndex: "name",
    },
    {
      title: "LOCATION",
      dataIndex: "location",
    },
    {
      key: "x",
      render: (value) => (
        <div>
          <img
            className="medicine-table-delete"
            src={deleteIcon}
            dnd="delete"
            onClick={() => dndPatientDelete(value)}
          />
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  // delete code for patient
  const dndPatientDelete = (k) => {
    dispatch(patientsubmit({ mobile: k.mobile, active: false }));
  };

  // -------------------------------

  const patientDndInfo = useSelector(
    (state) => state.patientDnd.patientDndInfo.dndlist
  );
  console.log("patientDndInfo", patientDndInfo);

  useEffect(() => {
    dispatch(patientdnd());
  }, []);

  const [dndAlert, setDndAlert] = useState("");

  const clear = () => {
    setDndAlert("");
  };

  const onChangealert = (e) => {
    setDndAlert(e.target.value);
  };
  const alertNum = (e) => {
    if (!dndAlert) {
      toast.error("please fill the input field");
    } else if (dndAlert.length < 10) {
      toast.error("enter valid mobile number");
    } else {
      e.preventDefault();
      dispatch(patientsubmit({ mobile: dndAlert, active: true }));
      setIsModalVisible(false);
      clear();
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setDndAlert("");
    setIsModalVisible(false);
    
  };
  return (
    <div>
      <div className="dnd-top-sec">
        <h2>DND</h2>
        <Button onClick={showModal}>ADD NEW</Button>
      </div>
      <div className="dnd-container">
        <Table columns={columns} data={patientDndInfo} />
      </div>
      {/*------------------ from here modal code----------------- */}
      <Modal
        className="dnd-modal"
        title="DND"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={700}
      >
        <div className="dnd-container-two">
          <div className="dnd-section">
            <div className="dnd-inp">
              <label for="mob" className="dnd-label">
                MOBILE NUMBER
              </label>
              <Input
                value={dndAlert}
                onChange={onChangealert}
                id="mob"
                type="number"
                className="dnd-inp-one"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
              />
            </div>

            <div className="calls-modal-btn">
              <Button
                onClick={alertNum}
                children="SAVE"
                height="38px"
                width="90px"
                fontSize="15px"
              />
              <Button
                onClick={clear}
                children="CLEAR"
                height="38px"
                width="90px"
                fontSize="15px"
                bgColor="#ccc"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DonotDisturb;
