import React, { useState, useEffect } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../Procedures/Procedures.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import MultiSelect from '../../../../UIComponents/MultiSelect/MultiSelect'
import { getDoctorListValue } from '../../../../redux/actions/opd/patientreport';
import { useSelector, useDispatch } from 'react-redux'
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice'
import { Modal } from 'antd';
import { loadMasterListValue } from '../../../../redux/actions/doctorEditMaster/masterListValue'
import AddListModal from '../../../EditMasters/AddListModal'


function Procedures() {
    const dispatch = useDispatch()
    const procedureList = useSelector(state => state.opdrecord.patientreport.listValue.procedure)
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    const [openModal, setOpenModal] = useState(false)

    const onProcedureChange = (value) => {
        const arr = value.map(item => ({
            name: item
        }))
        console.log('djjdkjfkfk',arr)
        dispatch(updateVisit({ ...visitInfo, procedure: arr }))
    }
    const onChangeProcedureNextVisit = (value) => {
        const arr = value.map(item => ({
            name: item
        }))
        dispatch(updateVisit({ ...visitInfo, procedureNextVisit: arr }))
    }


    const addNewHandler = () => {
        setOpenModal(!openModal)
        dispatch(loadMasterListValue({ type: "procedure" }))
    }
    useEffect(() => {
        dispatch(getDoctorListValue({ type: "procedure" }))

    }, [])

    return (
        <div>
            <div>
                <div className='procedures-div'>
                    <NumberButton className="numberButton-11">11</NumberButton>
                    <div className='procedures-11-div'>
                        <h6>PROCEDURES</h6>
                        <p onClick={addNewHandler} className="add-new-classname">Add New</p>
                    </div>
                    <div className='procedures-antSelect'>
                        <MultiSelect data={procedureList?.data?.list} value={visitInfo?.procedure?.map(item => item.name)} onChange={onProcedureChange} placeholder="Select Procedures" className={'procedures-multiselect'} />
                    </div>
                </div>
                <div className='procedures-div'>
                    <NumberButton className="numberButton-11">11.1</NumberButton>
                    <div className='procedures-11-div'>
                        <h6>PROCEDURES For Next Visit</h6>
                    </div>
                    <div className='procedures-antSelect'>
                        <MultiSelect data={procedureList?.data?.list} value={visitInfo?.procedureNextVisit?.map(item => item.name)} onChange={onChangeProcedureNextVisit} placeholder="Select Procedures For Next Visit" className={'procedures-multiselect'} />
                    </div>
                </div>
                <Divider />
            </div>
            <AddListModal visible={openModal} type="procedure" />
        </div>
    )
}

export default Procedures