import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

const setPartnerLoading = () => ({
  type: types.SET_PARTNER_LOADING,
});

const setpartnerSuccess = (data) => ({
  type: types.SET_PARTNER_SUCCESS,
  payload: data,
});
const setPartnerFail = (error) => ({
  type: types.SET_PARTNER_FAIL,
  payload: error,
});

export const setPartner = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(setPartnerLoading());
    client
      .post(
        `/patient/set-partner`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(setpartnerSuccess(resp.data));
        console.log("appointments data", resp.data);
        toast.success("Updated patient partner");
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(setPartnerFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
