import React, { useState } from "react";
import "antd/dist/antd.css";
import { Select } from "antd";
import '../MultiSelect/MultiSelect.css'

const { Option } = Select;
const MultiSelect = (props) => {

  function onSearch(val) {
    console.log("search:", val);
  }
  let options = props.data?.map((data, index) => (
    <Option key={index} value={data.id || data}>
      {data.name || data}
    </Option>
  ));


  return (
    <Select
      mode="multiple"
      placeholder={props.placeholder}
      allowClear
      showArrow={true}
      className={props.className}
      value={props.value}
      onSearch={onSearch}
      style={{ width: props.width, padding: props.padding, margin: props.margin }}
      onChange={props.onChange}
      disabled={props.disabled}
      dropdownClassName={props.dropdownClassName}
    >
      {options}
    </Select>
  );
};
export default MultiSelect;
