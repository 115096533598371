import React, { useEffect, useState } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import MultiSelect from '../../../../UIComponents/MultiSelect/MultiSelect'
import '../Allergy/Allergy.css'
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorListValue } from '../../../../redux/actions/opd/patientreport';
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice';

function Allergy() {
  const dispatch = useDispatch()
  const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
  console.log("patientOpdInvoiceInfo", visitInfo)
  const allergyList = useSelector(state => state.opdrecord.patientreport.listValue.allergens)
  console.log("allergyList", allergyList)

  const [state, setState] = useState([])


  const onAllergyChange = (value) => {
    console.log("jhfdjadfads", value)
    const arr = value.map(item => ({
      name: item
    }))
    console.log("kjdasjkasdkakha", arr)
    dispatch(updateVisit({ ...visitInfo, allergens: arr }))

  }

  useEffect(() => {
    dispatch(getDoctorListValue({ type: "allergens" }))
  }, [])

  useEffect(() => {
    if (visitInfo?.allergens) {
      const filteredData = visitInfo?.allergens?.filter(item =>
        allergyList?.data?.list?.includes(item.name)
      )
      console.log("filteredData", filteredData)
      setState(filteredData)
    }
  }, [visitInfo?.allergens, allergyList?.data?.list])

  return (
    <div className='allergy-div'>
      <NumberButton>1</NumberButton>
      <div className='allergy-intelorance'>
        <h6>ALLERGY/INTOLERANCE</h6>
      </div>
      <div className='allergy-antSelect'>
        <MultiSelect data={allergyList?.data?.list} value={state.map(item => item.name)} onChange={onAllergyChange} placeholder="Select Allergy" className={'allergy-multiselect'} style={{ zIndex: 1 }} />
      </div>
    </div>
  )
}

export default Allergy