import React, { useEffect, useState } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../ClinicalFindings/ClinicalFindings.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import MultiSelect from '../../../../UIComponents/MultiSelect/MultiSelect'
import { getDoctorListValue } from '../../../../redux/actions/opd/patientreport';
import { useSelector, useDispatch } from 'react-redux'
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice'
import { loadMasterListValue } from '../../../../redux/actions/doctorEditMaster/masterListValue'
import { allReportsToggle } from '../../../../redux/actions/opd/patientreport'
import AddListModal from '../../../EditMasters/AddListModal'



function ClinicalFindings() {
    const dispatch = useDispatch()
    const clinicalList = useSelector(state => state.opdrecord.patientreport.listValue.clinicalFinding)
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)

    const [openModal, setOpenModal] = useState(false)
    const [state, setState] = useState([])


    const onClinicalChange = (value) => {
        const arr = value.map(item => ({
            name: item
        }))
        dispatch(updateVisit({ ...visitInfo, clinicalFinding: arr }))
    }

    const addNewHandler = () => {
        setOpenModal(!openModal)
        dispatch(loadMasterListValue({ type: 'clinicalFinding' }))
    }
    useEffect(() => {
        dispatch(getDoctorListValue({ type: 'clinicalFinding' }))
    }, [])

    useEffect(() => {
        if (visitInfo?.clinicalFinding) {
            const filteredData = visitInfo?.clinicalFinding?.filter(item =>
                clinicalList?.data?.list?.includes(item.name)
            )
            console.log("filteredData", filteredData)
            setState(filteredData)
        }
    }, [visitInfo?.clinicalFinding, clinicalList?.data?.list])


    return (
        <div>
            <div>
                <div className='clinicalFindings-div'>
                    <NumberButton className="numberButton-6">6</NumberButton>
                    <div className='clinicalFindings-6-div'>
                        <h6>CLINICAL FINDINGS</h6>
                        <p onClick={addNewHandler} className="add-new-classname">Add New</p>
                    </div>
                    <div className='clinicalFindings-antSelect'>
                        <MultiSelect data={clinicalList?.data?.list} value={state?.map(item => item.name)} onChange={onClinicalChange} placeholder="Select Clinical Findings" className={'clinical-findings-multiselect'} />
                    </div>
                </div>
                <Divider />
            </div>
            <AddListModal visible={openModal} type="clinicalFinding" />
        </div>
    )
}

export default ClinicalFindings