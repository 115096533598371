import React, { useEffect } from "react";
// import Input from "../../UIComponents/Input/Input";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import Button from "../../UIComponents/Button/Button";
import Table from "../../UIComponents/Table/Table";
import SingleSelect from "../../UIComponents/Select/SingleSelect";
import "./Calls.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import totalcalls from "../../../src/images/totalcalls.svg";
import totalfollowups from "../../../src/images/totalfollowups.svg";
import deleteIcon from "../../../src/images/delete-icon.svg";
import editIcon from "../../../src/images/edit-icon.png";
import calls from "../../../src/images/calls.jpg";
import { Input, Space } from "antd";
import moment from "moment";
import {
  callMadeToday,
  callSummary,
  callPlan,
  callHistory,
} from "../../redux/actions/call/call";
import { getProfileDetails } from "../../redux/actions/profile";
import { DatePicker } from "antd";
import { DoctorList } from "../../redux/actions/admin/doctorSignUp";
import { callLog } from "../../redux/actions/call/call";
import toast from "react-hot-toast";
import useDoctorId from "../../utils/get-doctor-id";
// import { dispatch } from "react-hot-toast/dist/core/store";
const { Search } = Input;

const columns = [
  {
    title: "",
    dataIndex: "icons",
  },
  {
    title: "NAME",
    dataIndex: "name",
  },
  {
    title: "MOBILE NO.",
    dataIndex: "mobile",
  },
  {
    title: "CITY",
    dataIndex: "city",
  },
  {
    title: "CALL TYPE",
    dataIndex: "callType",
  },
  {
    title: "COMMENTS",
    dataIndex: "comments",
  },
  {
    title: "OPS",
    dataIndex: "ops",
  },
  {
    title: "STATUS",
    dataIndex: "status",
  },
  {
    title: "",
    dataIndex: "",
    key: "x",
  },
];

// const data = [
//   {
//     key: 1,
//     name: "Ranjit kumar",
//     mobile: 21515191981891,
//     city: "London, Park Lane no.",
//     callType: "Patient",
//     comments: "Health checkup",
//     ops: "",
//     status: "1:45:00 Remaining",
//   },
//   {
//     key: 2,
//     name: "Subramanyam",
//     mobile: 5491981988848,
//     city: "London, Park Lane no.",
//     callType: "Patient",
//     comments: "Health checkup",
//     ops: "",
//     status: "Completed",
//     render: () => (
//       <div className="medicine-table-icons">
//         <img className="medicine-table-delete" src={deleteIcon} alt="delete" />
//         <img src={editIcon} className="medicine-table-edit" alt="edit" />
//       </div>
//     ),
//   },
//   {
//     key: 3,
//     name: "",
//     mobile: "",
//     city: "",
//     callType: "",
//     comments: "",
//     ops: "",
//     status: "",
//   },
//   {
//     key: 4,
//     name: "",
//     mobile: "",
//     city: "",
//     callType: "",
//     comments: "",
//     ops: "",
//     status: "",
//   },
//   {
//     key: 5,
//     name: "",
//     mobile: "",
//     city: "",
//     callType: "",
//     comments: "",
//     ops: "",
//     status: "",
//   },
//   {
//     key: 6,
//     name: "",
//     mobile: "",
//     city: "",
//     callType: "",
//     comments: "",
//     ops: "",
//     status: "",
//   },
//   {
//     key: 7,
//     name: "",
//     mobile: "",
//     city: "",
//     callType: "",
//     comments: "",
//     ops: "",
//     status: "",
//   },
//   {
//     key: 8,
//     name: "",
//     mobile: "",
//     city: "",
//     callType: "",
//     comments: "",
//     ops: "",
//     status: "",
//   },
//   {
//     key: 9,
//     name: "",
//     mobile: "",
//     city: "",
//     callType: "",
//     comments: "",
//     ops: "",
//     status: "",
//   },
//   {
//     key: 10,
//     name: "",
//     mobile: "",
//     city: "",
//     callType: "",
//     comments: "",
//     ops: "",
//     status: "",
//   },
// ];

function Calls() {
  const dispatch = useDispatch();

  const profileInfo = useSelector((state) => state.profile.profileInfo);
  console.log("profileInfo", profileInfo);

  // call made today
  const madetoday = useSelector((state) => state.callMadeToday.madetoday);
  console.log("call made today", madetoday);

  // call history
  const history = useSelector((state) => state.callHistory.history);
  console.log("call history", history);
  const myDomainInfo = useSelector((state) => state.login.myDomainInfo);
  const doctorId = useDoctorId();
  console.log("doctorId", doctorId);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [drname, setDrName] = useState("");
  const [mobile, setMobile] = useState("");
  const [personName, setPersonName] = useState("");
  const [city, setCity] = useState("");
  const [date, setDate] = useState(null);
  const [input, setInput] = useState("");
  const [convert, setConvert] = useState(false);
  const [dnd, setDnd] = useState(true);
  const [follow, setFollow] = useState(0);
  const [shrink, setShrink] = useState(false);
  const [searchresult, setSearchresult] = useState("");

  const onSearch = (value) => console.log(value);
  // ADD NEW CODE
  const onChangeDrname = (value) => {
    console.log("value", value);
    setDrName(value);
  };
  const onChangeMobile = (e) => {
    setMobile(e.target.value);
    console.log("mobile value", e.target.value);
  };
  const onChangePerson = (e) => {
    setPersonName(e.target.value.replace(/[^A-Za-z ]/gi, ""));
  };
  const onChangeCity = (e) => {
    setCity(e.target.value.replace(/[^A-Za-z ]/gi, ""));
  };

  const onChangeInput = (e) => {
    setInput(e.target.value);
  };
  const onChangeConvert = () => {
    setConvert(true);
  };
  const onChangeDnd = () => {
    setDnd(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const onChangeSearch = (e) => {
    if (e.target.value.length === 0) {
      setSearchresult(null);
      setShrink(false);
    } else {
      setSearchresult(e.target.value);
    }
    console.log("search value", searchresult);
  };

  const searchResultFinal = (e) => {
    if (searchresult) {
      dispatch(callHistory(testing));
      setShrink(true);
    }
  };

  const closeResult = () => {
    setShrink(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDrName(null);
    setMobile(null);
    setPersonName(null);
    setDate(null);
    setCity(null);
    setInput(null);
  };

  useEffect(() => {
    if (profileInfo.length === 0) {
      dispatch(getProfileDetails({ doctorId }));
    }
  }, [profileInfo]);

  const doctorData = [
    {
      id: profileInfo?.id,
      name: profileInfo?.name,
    },
  ];
  const onOk = (value) => {
    setDate(value);
    console.log("on ok", value);
  };

  const newDate = new Date().toISOString();
  console.log("kdjfhshadkjadf", newDate);
  const addNew = (e) => {
    e.preventDefault();
    if (!drname || !mobile || !personName || !date || !city || !input) {
      toast.error("All fields are required");
    } else if (mobile.length !== 10) {
      toast.error("Enter Valid mobile Number");
    } else {
      dispatch(
        callLog({
          call: {
            name: personName,
            mobile: mobile,
            comments: input,
            converted: convert,
            followup: date,
            city: city,
            dnd: dnd,
            userId: drname,
            callDate: newDate,
          },
          doctorIds: [drname],
        })
      );
      setIsModalVisible(false);
      setMobile(null);
      setPersonName(null);
      setDate(null);
      setCity(null);
      setInput(null);
    }
  };

  const testing = {
    searchQuery: searchresult,
    doctorIds: [profileInfo.id],
  };
  const maaz = useSelector((state) => state.callSummary);
  console.log("maaz", maaz);

  useEffect(() => {
    dispatch(callMadeToday({ doctorIds: [profileInfo?.id] }));
  }, []);

  const followUps = (madetoday) => {
    let follow = 0;
    {
      madetoday.map((item) => {
        if (item.converted) {
          follow = follow + 1;
        }
      });
    }
    return follow;
  };
  console.log("follow", follow);

  return (
    <div className="calls-container">
      <div className="profile-name">
        <h1>CALLS</h1>
      </div>
      <div className="calls-container2">
        <div className="calls-container3">
          <div className="calls-container4">
            <h1>My Calls</h1>

            <p className="totalcalls-section">
              <span className="totalcal-icon">
                <img src={totalcalls} alt="" />
              </span>
              <span>
                <p>TOTAL CALLS</p>
                <p className="total-call-text">{madetoday.length}</p>
              </span>
            </p>
            <p className="totalfollowups-section">
              <span className="totalfollowup-icon">
                <img src={totalfollowups} alt="" />
              </span>
              <span>
                <p>FOLLOW UPS</p>
                <p className="follow-up-text">{followUps(madetoday)}</p>
              </span>
            </p>
          </div>
          <div className="calls-container5">
            <Button
              className="addnewbtn"
              onClick={showModal}
              children="Add New"
            />
            <Search
              className="calls-input1"
              placeholder="Call History"
              allowClear
              onChange={onChangeSearch}
              onSearch={searchResultFinal}
              style={{
                width: 200,
              }}
            />
          </div>
        </div>
      </div>
      {/* main section  */}
      <div className="search-table-flex">
        {/* left section */}
        <div className={shrink ? "calls-shrink-table" : "calls-table"}>
          <Table columns={columns} data={madetoday} />
        </div>
        {/* right section  */}
        <div className={shrink ? "search-result" : "no-search"}>
          <div
            className="result-heading"
            style={{
              background: myDomainInfo?.DomainStyles?.buttonColor
                ? myDomainInfo?.DomainStyles?.buttonColor
                : "#00576E",
            }}
          >
            <h3>RESULTS</h3>
          </div>

          {history && history.length
            ? history.map((item, index) => (
                <div key={index} className="search-pop">
                  <p>Name: {item?.name}</p>
                  <p>Patient: {item?._id}</p>
                  <p>Mobile: {item?.mobile}</p>
                  <p>Comments: {item?.comments}</p>
                  <p>
                    Call Date: {moment(item?.callDate).format("DD-MM-yyyy")}
                  </p>
                </div>
              ))
            : null}
        </div>
      </div>

      {/* from here modal code */}
      <Modal
        title="CREATE CALL"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={700}
      >
        <div className="calls-modal">
          <div className="calls-inp">
            <label for="name" className="calls-input-label marg">
              NAME
            </label>
            <SingleSelect
              data={doctorData}
              width={150}
              listHeight={150}
              paddingLeft={82}
              placeholder="Select Name"
              value={drname}
              onChange={onChangeDrname}
            />
            {/* <img
              src={calls}
              style={{ marginLeft: "10px" }}
              width="35px"
              height="31px"
              alt=""
            /> */}
          </div>
          <br></br>
          <div className="calls-inp">
            <label for="mob" className="calls-input-label">
              MOBILE NUMBER
            </label>
            <Input
              id="mob"
              type="number"
              value={mobile}
              onChange={onChangeMobile}
              className="calls-modal-inp1"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
            />
          </div>
          <br></br>
          <div className="calls-inp">
            <label for="person" className="calls-input-label">
              PERSON NAME
            </label>
            <Input
              id="person"
              value={personName}
              onChange={onChangePerson}
              className="calls-modal-inp2"
            />
          </div>
          <br></br>
          <div className="calls-inp">
            <label for="city" className="calls-input-label">
              CITY
            </label>
            <Input
              id="city"
              value={city}
              onChange={onChangeCity}
              className="calls-modal-inp3"
            />
          </div>
          <br></br>
          <div className="calls-inp">
            <label for="date" className="calls-input-label">
              DATE / TIME
            </label>
            {/* <Input id="date" value={date} onChange={onChangeDate}   className="calls-modal-inp4" /> */}
            <DatePicker
              value={date}
              showTime
              className="calls-date"
              onChange={onOk}
            />
          </div>
          <br></br>
          <div className="calls-inp">
            <label for="input" className="calls-input-label">
              INPUT
            </label>
            <Input
              id="input"
              value={input}
              onChange={onChangeInput}
              className="calls-modal-inp5"
            />
          </div>
          <br></br>
          <br></br>
          <span className="toggle-label">CONVERT</span>
          <label class="switch">
            <span className="calls-n">N</span>
            <input type="checkbox" value={convert} onClick={onChangeConvert} />
            <span class="slider round"></span>
          </label>
          <span className="calls-y">Y</span>
          <br></br>
          <br></br>
          <span className="toggle-label1">DND</span>
          <label class="switch">
            <span className="calls-n">N</span>
            <input
              type="checkbox"
              defaultChecked
              value={dnd}
              onClick={onChangeDnd}
            />
            <span class="slider round"></span>
          </label>
          <span className="calls-y">Y</span>
          <br></br>
          <div className="calls-modal-btn">
            <Button
              onClick={addNew}
              children="Save"
              className="calls-modal-btn1"
            />
            <Button
              onClick={handleCancel}
              children="Cancel"
              className="calls-modal-btn2"
            />
          </div>
          <br></br>
        </div>
      </Modal>
    </div>
  );
}

export default Calls;
