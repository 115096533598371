import * as types from '../../actionTypes';

const initialState = {
    editIinvoiceData: [],
    addButton: [],
    error: {},
    loading: false,
}

export  const editInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.EDIT_INVOICE_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.EDIT_INVOICE_SUCCESS:
            console.log("actionddddd",action.payload)
            return {
                ...state,
                editIinvoiceData: action.payload,
                loading: false,
                error: {}
            }
        case types.EDIT_INVOICE_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                editIinvoiceData: []
            }
        case types.ADD_BUTTON:
            return{
                ...state,
                editIinvoiceData:action.payload,
                loading: false
            }
        default:
            return state;
    }
}