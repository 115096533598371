import React, { useState, useRef } from "react";
import "./Invoicesummary.css";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import Button from "../../../UIComponents/Button/Button";
import { DatePicker } from "antd";
import DemoPie from "./InvoicePieChart";
import PatientVisitsgraph from "./PatientVisitsgraph";
import CommonTable from "../../../UIComponents/Table/Table";
import anafileexport from "../../../images/anafileexport.svg";
import moment from "moment";
import { doctorInvoice, graphToggleSuccess } from "../../../redux/actions/analytics/analytics";
import { useDispatch, useSelector } from "react-redux";
import calenderLogo from '../../../images/calender.svg';
import { CSVLink } from "react-csv";
import { clearDropDownData, hideDataSuccess, clearDataSuccess } from "../../../redux/actions/analytics/analytics";
import { toast } from '../../../UIComponents/Toast'
import { useEffect } from "react";
import { log } from "@antv/g2plot/lib/utils";


const columns = [
  {
    title: "INV",
    dataIndex: "invoiceNumber",
  },
  {
    title: "DOCTOR",
    dataIndex: "doctorName",
  },
  {
    title: "PATIENT",
    dataIndex: "patientName",
  },
  {
    title: "DATE",
    dataIndex: "visitStart",
  },
  {
    title: "FEE",
    dataIndex: "fees",
  },
  {
    title: "COMMENTS",
    dataIndex: "comments",
  },
  {
    title: "MODE",
    dataIndex: "paymentMode",
  },
  {
    title: "REFERAL",
    dataIndex: "referance",
  },
  {
    title: "USER",
    dataIndex: "lastUpdatedBy",
  },
];

const data = [
  {
    id: "Export CSV",
    name: "Export CSV",
  },
  {
    id: "Export Doctor Referral",
    name: "Export Doctor Referral",
  },
  {
    id: "Export Hospital Referral",
    name: "Export Hospital Referral",
  },
  {
    id: "Export Summary CSV",
    name: "Export Summary CSV",
  },


];

const paymentMode = [
  {
    id: "All",
    name: "All"
  },
  {
    id: "Online",
    name: "Online"
  },
  {
    id: "Cash",
    name: "Cash"
  },
];



const Invoicesummary = () => {
  let dispatch = useDispatch();
  const csvLink = useRef()
  const doctorInvoiceSummary = useSelector(
    (state) => state.analytics.doctorInvoiceSummary
  );
  console.log("doctorInvoiceSummary", doctorInvoiceSummary);
  const profileInfo = useSelector(state => state.profile.profileInfo)
  const doctor = [
    {
      id: profileInfo?.id,
      name: profileInfo?.name
    }
  ];

  const dropDownInfo = useSelector(state => state.analytics.clearDropDowns)
  const exportToggleData = useSelector(state => state.analytics.exportToggleData)
  const hide = useSelector(state => state.analytics.hideData)
  let clear = useSelector(state => state.analytics.clearData)
  const { RangePicker } = DatePicker;
  const [sinvCount, setSinvCount] = useState(0);
  const [einvCount, setEinvCount] = useState(0);
  const [fileType, setFileType] = useState()
  const [payMode, setPayMode] = useState("");
  const [invoiceLocation, setInvoiceLocation] = useState("");
  const [daily, setDaily] = useState(false)
  const [monthly, setMonthly] = useState(false);
  const [yearly, setYearly] = useState(false);
  const [csvInfo, setCsvInfo] = useState('')
  const [modifiedInfoSummary, setModifiedInfoSummary] = useState('')
  console.log("modifiedInfoSummary", modifiedInfoSummary)

  const invoiceCountsubtract = () => {
    setSinvCount(sinvCount - 1);
  };

  const invoiceaddition = () => {
    setSinvCount(sinvCount + 1);
  };

  const invoiceCountSub = () => {
    setEinvCount(einvCount - 1);
  };
  const invoiceCountAdd = () => {
    setEinvCount(einvCount + 1);
  };

  const handleDoctorChange = (value) => {
    dispatch(clearDropDownData({ ...dropDownInfo, doctors: value }))
  }

  const handleModeChange = (value) => {
    dispatch(clearDropDownData({ ...dropDownInfo, mode: value }))
  };

  const handleLocationChange = (value) => {
    dispatch(clearDropDownData({ ...dropDownInfo, location: value }))
  };


  const handleDateChange = (date, dateString) => {
    dispatch(clearDataSuccess({ startDate: dateString[0], endDate: dateString[1] }))
  };


  const onGenerateClick = (e) => {
    setYearly(false)
    setDaily(false)
    setMonthly(false)
    dispatch(graphToggleSuccess("other"))
    console.log("onclick", onGenerateClick);
    if (!dropDownInfo.mode) {
      toast.error("Please select all the fields")
      dispatch(hideDataSuccess(false))
    }
    else if (!dropDownInfo.doctors) {
      toast.error("Please select all the fields")
    }
    else if (clear.startDate === null && clear.endDate === null) {
      toast.error("Please select all the fields")

    }
    else {
      dispatch(
        doctorInvoice(clear.startDate, clear.endDate, dropDownInfo.location, dropDownInfo.mode, sinvCount, einvCount),
        dispatch(hideDataSuccess(true)),
        setPayMode(''),
        setInvoiceLocation(''),
        setCsvInfo('')

      );
    };
  }
  const headersExportcsv = [
    { label: "Visit Date", key: "visitStart" },
    { label: "Invoice", key: "invoiceNumber" },
    { label: "Doctor", key: "doctorName" },
    { label: "Patient", key: "patientName" },
    { label: "Visit Date", key: "visitStart" },
    { label: "Fees", key: "fees" },
    { label: "Comment", key: "comment" },
    { label: "Mode", key: "paymentMode" },
    { label: "Referral", key: "referral" },
    { label: "Hospital Referrel", key: "hospitalReferral" },
    { label: "cr no", key: "crno" },
    { label: "User", key: "lastUpdatedBy" },
    { label: "Token", key: "token" },
    { label: "Doctor Incharge", key: "doctorIncharge" },
    { label: "Department", key: "department" },
  ];
  const headersDoctorReferralcsv = [
    { label: "Sr", key: "invoiceNumber" },
    { label: "Referrer", key: "referrer" },
    { label: "Referral", key: "referral" },
    { label: "Fees", key: "fees" },
  ]

  const headersHospitalReferralcsv = [
    { label: "Sr", key: "invoiceNumber" },
    { label: "Referrer", key: "referrer" },
    { label: "Referral", key: "referral" },
    { label: "Fees", key: "fees" },
  ]

  const headersExportSummarycsv = [
    { label: "Date", key: "visitStart" },
    { label: "Gross", key: "gross" },
    { label: "Discount", key: "discountAmt" },
    { label: "Net", key: "fees" },
  ]


  const csvData = doctorInvoiceSummary?.invoices


  useEffect(() => {
    if (doctorInvoiceSummary?.invoices?.length) {
      let array = []
      let grossData = doctorInvoiceSummary?.invoices.map(item => {
        array.push({
          ...item, 'gross': !item.fees && !item.discountAmt ? 0 : Number(item.fees) + Number(item.discountAmt), visitStart: moment(item.visitStart, 'YYYY-MM-DD').format('YYYY-MM-DD ')
        }
        )
        setCsvInfo(array)
      })
    }
  }, [doctorInvoiceSummary?.invoices])


  const onDateSort = (data) => {
    if (data?.length) {
      const dates = Object.values(data)
      dates.sort((a, b) => {
        // return new Date(b.visitStart) - new Date(a.visitStart);
        return a.invoiceNumber > b.invoiceNumber ? -1 : b.invoiceNumber > a.invoiceNumber ? 1 : 0

      })
      setModifiedInfoSummary(dates)
    }
    else {
      setModifiedInfoSummary([])
    }
  }
  useEffect(() => {
    if (csvInfo?.length) {
      const invoicesummary = [...csvInfo]
      onDateSort(invoicesummary)
    }
    else {
      onDateSort()
    }
  }, [csvInfo])


  const handleDailyChange = (e) => {
    setDaily(true)
    setMonthly(false)
    setYearly(false)
    let toDailyDate = moment().format('YYYY-MM-DD');
    let fromDailyDate = moment().subtract(7, 'd').format('YYYY-MM-DD');

    clear.startDate = fromDailyDate.toString()
    clear.endDate = toDailyDate.toString()
    dispatch(graphToggleSuccess("dialy"))

    dispatch(doctorInvoice(fromDailyDate, toDailyDate, dropDownInfo.location, dropDownInfo.mode, sinvCount, einvCount))
  }

  const handleMonthlyChange = (e) => {
    setMonthly(true)
    setDaily(false)
    setYearly(false)
    let monthly = moment().subtract(12, 'months').format('YYYY-MM-DD')
    let FromMonth = monthly.split('-')[0] + '-' + monthly.split('-')[1] + '-01'
    let toMonth = moment().startOf('month').format('YYYY-MM-DD');
    clear.startDate = FromMonth.toString()
    clear.endDate = toMonth.toString()
    dispatch(graphToggleSuccess("monthly"))
    dispatch(doctorInvoice(FromMonth, toMonth, dropDownInfo.location, dropDownInfo.mode, sinvCount, einvCount))
  }

  const handleYearlyChange = (e) => {
    setYearly(true)
    setDaily(false)
    setMonthly(false)
    console.log("yearly", e);
    let yearly = moment().subtract(2, 'years').format('YYYY-MM-DD')
    let fromYear = yearly.split('-')[0] + '-' + yearly.split('-')[1] + '-01'
    console.log("nm", fromYear);
    let toYear = moment().startOf('month').format('YYYY-MM-DD');
    clear.startDate = fromYear.toString()
    clear.endDate = toYear.toString()
    dispatch(graphToggleSuccess("yearly"))
    dispatch(doctorInvoice(fromYear, toYear, dropDownInfo.location, dropDownInfo.mode, sinvCount, einvCount))

  }


  const fileTypeHandler = (value) => {
    console.log("value", value)
    dispatch(clearDropDownData({ ...dropDownInfo, exportFile: value }))
    setFileType(value)
  }
  const exportClick = () => {
    if (exportToggleData && fileType) {
      csvLink.current.link.click()
      dispatch(clearDropDownData({ ...dropDownInfo, exportFile: '' }))
      setFileType('')
      // abcd()
    }
    else {
      toast.error("Please select any option to export")
    }
  }



  return (
    <div className="invoice-summary-container">
      <div className="invoice-top">
        <div className="invoice-pick-location">
          <div className="invoice-top-first-section">
            <div className="main-div">
              <p className="pick-title">PICK LOCATION (Leave Blank for All)</p>
              <SingleSelect
                data={profileInfo.locations}
                onChange={handleLocationChange} value={dropDownInfo?.location}
              />
            </div>
            <div className="main-div">
              <p className="pick-title">PAYMENT MODE</p>
              <SingleSelect
                data={paymentMode}
                onChange={handleModeChange}
                width="100%" value={dropDownInfo?.mode}
              />
            </div>
            <div className="main-div-calender">
              <p className="pick-title">FROM/TO DATE</p>
              <RangePicker onChange={handleDateChange} value={clear.startDate !== null ? [moment(clear.startDate), moment(clear.endDate)] : [null, null]} />
              <img className='analytics-calender' src={calenderLogo} alt='calender' />
            </div>
          </div>
          <div className="invoice-top-second-section">
            <div className="main-div">
              <p className="pick-title">DOCTOR</p>
              <div className="doctor-select-dropdown-btn">
                <SingleSelect
                  data={doctor} onChange={handleDoctorChange} value={dropDownInfo?.doctors}
                  width="100%"
                />
              </div>
            </div>
            <div className="main-div">
              <p className="pick-title">INVOICE</p>
              <div className="count-area">
                <div className="count-btn counter-first-btns">
                  <Button
                    className={"invoice-sub-btn"}
                    onClick={invoiceCountsubtract}
                  >
                    <span className="minus-symbol">-</span>
                  </Button>
                  {sinvCount}
                  <Button
                    className={"invoice-add-btn"}
                    onClick={invoiceaddition}
                  >
                    +
                  </Button>
                </div>
                <div className="count-btn counter-second-btns">
                  <Button
                    className={"invoice-sub-btn"}
                    onClick={invoiceCountSub}
                  >
                    -
                  </Button>
                  <span className="num-counter"> {einvCount}</span>
                  <Button
                    className={"invoice-add-btn"}
                    onClick={invoiceCountAdd}
                  >
                    +
                  </Button>
                </div>
              </div>
            </div>
            <div className="main-div-btn">
              <p className="generate-btn-label">
                <Button
                  className={"invoice-generate-btn"}
                  onClick={onGenerateClick}
                >
                  GENERATE
                </Button>
              </p>
            </div>
          </div>
        </div>
        <div className="download-file-selection">
          <div className="export-icon">
            <span className="invoice-detail-icon">
              <img src={anafileexport} alt="export" />
            </span>

            <div className="export-title">
              <p>EXPORT REPORT </p>
            </div>
          </div>
          <div className="select-file">
            <SingleSelect data={data} width="100%" onChange={fileTypeHandler} value={dropDownInfo?.exportFile} />
          </div>
          <div className="export-btn">
            <Button onClick={exportClick} className="export-btn-title">EXPORT</Button>
            <CSVLink data={csvInfo ? csvInfo : ''} ref={csvLink} headers={fileType === "Export CSV" ? headersExportcsv : fileType === "Export Doctor Referral" ? headersDoctorReferralcsv : fileType === "Export Hospital Referral" ? headersHospitalReferralcsv : fileType === "Export Summary CSV" ? headersExportSummarycsv : ''} filename={fileType}></CSVLink>
          </div>
        </div>
      </div>
      {hide ?
        <div>
          <div className="invoice-middle-section">
            <div className="middle-main-section">
              <div className="invoice-middle-left-section">
                <div className="invoice-middle-left-first-section">
                  <div className="patient-visits-title">
                  </div>

                  <div className="calendar-select-btn-section">
                    <Button className={daily ? "invoice-monthly-btn" : "invoice-daily-btn"} onClick={handleDailyChange}>Weekly</Button>
                    <Button className={monthly ? "invoice-monthly-btn" : "invoice-daily-btn"} onClick={handleMonthlyChange}>Monthly</Button>
                    <Button className={yearly ? "invoice-monthly-btn" : "invoice-daily-btn"} onClick={handleYearlyChange}>Yearly</Button>
                  </div>
                  <div className="total-bookings-section">
                    <div className="book-section">
                    </div>
                  </div>
                  <div className="visit-section">
                    <div className="visit-graph">
                    </div>
                  </div>
                </div>

                <div className="invoice-middle-left-second-section">
                  <div className="middle-second-section">
                    <div className="graph-data">
                      <PatientVisitsgraph />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fee-payment-mode-section">
                <div className="fee-payment-mode-main-section">
                  <div className="fee-pay-title">
                    <p>FEE PAYMENT MODE </p>
                  </div>
                  <div className="fee-pay-main">
                    {doctorInvoiceSummary?.paymentGroup?.map(item => (
                      <div className="fee-pay-online">
                      </div>
                    ))}
                  </div>
                </div>
                <div className="piechart-container">
                  <DemoPie />
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-bottom-section">
            <CommonTable data={modifiedInfoSummary} columns={columns} />
          </div>
        </div>
        : ""}

    </div >
  );
};

export default Invoicesummary;
