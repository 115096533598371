import React from 'react'
import '../Electrolytes.css'
import Input from '../../../../UIComponents/Input/Input'
import { useDispatch,useSelector } from 'react-redux'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useState } from 'react'
import Button from '../../../../UIComponents/Button/Button'
import calculateLogo from '../../../../images/calculate.png'

    const Elec = () => {
      const dispatch =  useDispatch()
      const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
      const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
      const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
        console.log("ejrnroromomr",e.target);
        const { value, name } = e.target;
        const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'ELECTROLYTES')
        let index = 0
        reportDataFields?.testReport?.map((item, i) => {
          if(item?.test === 'ELECTROLYTES'){
            index = i
          }
          return item
        })
        console.log("index",filterData);

        const finalFilteredData = filterData[0]?.fields?.map(item => {
          if(item.particular === name){
            item.result = value
          }
          return item
        })
      filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index]  = filterData[0]
      console.log("knklenknek",reportDataFields?.testReport);
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
      }
  return (
    <div>
       <div className='auto-button'>
                <Button><img width={20} src={calculateLogo} alt="Calculate image"/>Auto Calculate</Button>
                </div>
                <div className='electrolyte-grid'>
                    <div className='elec1 elecc-pad'>EXAMINATION OF ELECTROLYTES</div>
                    <div className='elec2 elec-pad'>SERUM SODIUM [NA+]</div>
                    <div className='elec3'><Input type='number' className={"elec-input"} name="SERUM SODIUM [NA+]" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='elec4 elec-pad'>mEq/l</div>
                    <div className='elec5 elec-pad'>135-155</div>
                    <span className='elec-line'></span>
                    <div className='elec7 elecc-pad'>EXAMINATION OF ELECTROLYTES</div>
                    <div className='elec8 elec-pad'>	SERUM POTASSIUM [K+]</div>
                    <div className='elec9'><Input type='number' className={"elec-input"} name="SERUM POTASSIUM [K+]" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active} /></div>
                    <div className='elec10 elec-pad'>mEq/l</div>
                    <div className='elec11 elec-pad'>135-155</div>
                    <span className='elec-line2'></span>
                    <div className='elec13 elecc-pad'>EXAMINATION OF ELECTROLYTES</div>
                    <div className='elec14 elec-pad'>SERUM CALCIUM</div>
                    <div className='elec15'><Input type='number' className={"elec-input"} name="SERUM CALCIUM" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='elec16 elec-pad'>mEq/l</div>
                    <div className='elec17 elec-pad'>135-155</div>
                    <span className='elec-line3'></span>
                </div>
    </div>
  )
}

export default Elec