import React from 'react'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const Pct = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'PCT')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'PCT'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
        <div className='iron-parent'>
            <div className='iron-left'>
                <div className='iron-grid'>
                    <div className='iron1 h-pad'></div>
                    <div className='iron2 h-pad'>Procalcitonin (PCT)</div>
                    <div className='iron3'><Input type='number' className={'iron-input'} name="Procalcitonin (PCT)" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} /></div>
                    <div className='iron4 h-pad'> ngml</div>
                    <div className='iron5 h-pad'>&lt;0.2</div>
                    <div className='iron6 h-pad'></div>
                    <span className='iron-border'></span>
                </div>
            </div>
                <div>
                    <TestReportRightSection />
                </div>
        </div>
       
        <div className='iron-foot'>
              <ReportFooter />
        </div>
        <div className='irn-method'>
              <h4>Conditions associated with mildly elevated serum Procalcitonin(PCT) <br /> levels (0.15-2 ng/ml) include the following:</h4>
              <ul>
                <li>Localized mild-to-moderate bacterial infection</li>
                <li>Noninfectious systemic inflammatory response</li>
                <li>Untreated end-stage renal failure</li>
              </ul>
              <h4>Conditions associated with elevated serum Procalcitonin(PCT)  levels <br />(&lt; 2 ng/ml) include the following:</h4>
              <ul>
                <li>Bacterial sepsis</li>
                <li>Severe Localized Bacterial infections(eg, severe pneumonia, meningTs,peritonits)</li>
                <li>Severe noninfectious inflammatory stimul (eg, major bums, severe trauma, acute multi organ failure, major abdominal or cardiothoracic surgary)   </li>
                <li>Meduliary Thyroid carcinoma (may exceed 10,000 ng/ml)</li>
              </ul>
              <h4>PCT  (&gt; 10)</h4>
              <ul>
                <li>Severe bacterial or septick shock</li>
              </ul>
            </div>
    </div>
  )
}


