import React, { useEffect,useState } from 'react'
import './DiagnosticPatientProfile.css'
import { useSelector } from "react-redux";


const DiagnosticPatientProfile = () => {
  const getSinglePatient = useSelector(state => state?.getPatient?.getSinglePatient)
  const getDiagnosticData = useSelector((state) => state?.diagnosticsInvoiceSummary.getdiagnostic?.patient);
  console.log("getDiagnosticData",getDiagnosticData);
  const getPatientDetailsData = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const profileInfo = useSelector(state => state?.profile?.profileInfo)
  const doctorId = profileInfo?.id;
  const [uniqueNo,setUniqueno] = useState("")

  const getUniqueNo = (uni, docId) => {
    if (typeof uni === "number") return uni
    else {
      const doctorKeys = Object?.keys(uni)
      console.log('doctorKeys', doctorKeys)
      const hasDocId = doctorKeys?.includes(docId)
      console.log('hasDocId', hasDocId)
      if (hasDocId) return uni[docId]
      else return ''
    }
  }

  useEffect(() => {
    if(getDiagnosticData || getSinglePatient) {
      const ui = getDiagnosticData?.patient || getSinglePatient?.uniqueno  ? getDiagnosticData?.uniqueno || getSinglePatient?.uniqueno : ''
      const uni = getUniqueNo(ui,doctorId)
      setUniqueno(uni)
    }
  },[ getDiagnosticData,getSinglePatient])

  return (
    <div>
      <div className="diagnostic-patient-details">
        <div className="diagnostic-patient-details">
          <div className="diagnostic-uhid-name-blood-address">
            <div className="diagnostic-uhid-left-details">
              <p>UHID :</p>
              <p>Patient Name :</p>
              <p>Guardian :</p>
              <p>Blood Group :</p>
              <p>Address :</p>
            </div>

            {getSinglePatient || getPatientDetailsData?
              <div className="diagnostic-uhid-right-details">
                <p>{uniqueNo ? uniqueNo : <p>{ uniqueNo ? uniqueNo : <br></br> }</p>}</p>
                <p>{getSinglePatient?.name ? getSinglePatient?.name : <p>{getPatientDetailsData?.patient?.name ? getPatientDetailsData?.patient?.name : <br></br>}</p>}</p>
                <p>{getSinglePatient?.guardian ? getSinglePatient?.guardian : <p>{getPatientDetailsData?.patient?.guardian ? getPatientDetailsData?.patient?.guardian : <br></br>}</p>}</p>
                <p>{getSinglePatient?.bloodgroup ? getSinglePatient?.bloodgroup : <p>{getPatientDetailsData?.patient?.bloodgroup ? getPatientDetailsData?.patient?.bloodgroup : <br></br>}</p>}</p>
                <p>{getSinglePatient?.address?.city ? getSinglePatient?.address?.city : <p>{getPatientDetailsData?.patient?.address?.city}{getPatientDetailsData?.patient?.address?.state}</p>}{getSinglePatient?.address?.state ? getSinglePatient?.address?.state : <br></br>}</p>
              </div>
              : null}
          </div>

          <div className="diagnostic-phone-age-email-section">
            <div className="diagnostic-phone-email-left-section">
              <p>Phone :</p>
              <p>Age/Sex :</p>
              <p>Email ID :</p>
              <p>ID :</p>
            </div>

            {getSinglePatient || getPatientDetailsData?
              <div className="diagnostic-phone-email-right-section">
                <p>{getSinglePatient?.mobile ? getSinglePatient?.mobile : <p>{getPatientDetailsData?.patient?.mobile ? getPatientDetailsData?.patient?.mobile : <br></br>}</p>}</p>
                <p><span>{getSinglePatient?.age ? getSinglePatient?.age : <span>{getPatientDetailsData?.patient?.age ? getPatientDetailsData?.patient?.age : <br></br>}</span>}</span> <span>{getPatientDetailsData?.patient?.gender? getPatientDetailsData?.patient?.gender : <span>{getSinglePatient?.gender ? getSinglePatient?.gender :<br></br>} </span>}</span></p>
                <p>{getSinglePatient?.emailid ? getSinglePatient?.emailid : <p>{getPatientDetailsData?.patient?.emailid ? getPatientDetailsData?.patient?.emailid : <br></br> }</p>}</p>
                <p>{getSinglePatient?.idno ? getSinglePatient?.idno :<p>{getPatientDetailsData?.idno}</p>}</p>
              </div>
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagnosticPatientProfile