import * as types from '../../actionTypes';

const initialState = {
    createPatient: [],
    fileNo: [],
    getDate: '',
    ImageUrl: [],
    getStatusCode: '',
    error: {},
    createPatientToggle: false,
    newPatientModalToggle: false,
    loading: false,
}



export const createPatientReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_PATIENT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.CREATE_PATIENT_SUCCESS:
            console.log("action", action.payload)
            return {
                ...state,
                createPatient: action.payload,
                loading: false,
                error: {}
            }
        case types.CREATE_PATIENT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                createPatient: []
            }
        case types.GET_CREATE_PATIENT_STATUS:
            return {
                ...state,
                getStatusCode: action.payload,
                loading: false
            }
        case types.FILE_NO_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.FILE_NO_SUCCESS:
            return {
                ...state,
                fileNo: action.payload,
                loading: false,
                error: {}
            }
        case types.FILE_NO_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                fileNo: []
            }
        case types.GET_DATE:
            return {
                ...state,
                getDate: action.payload,
                loading: false
            }
        case types.GET_URL:
            return {
                ...state,
                ImageUrl: action.payload,
                loading: false
            }
        case types.CREATE_PATIENT_TOGGLE:
            return {
                ...state,
                createPatientToggle: action.payload,
                loading: false
            }
        case types.NEW_PATIENT_TOGGLE:
            return {
                ...state,
                newPatientModalToggle: action.payload,
                loading: false
            }
        default:
            return state;
    }
}