import * as types from '../../actionTypes';

const initialState = {
    patientVaccine: [],
    error: {},
    loading: false
}


export const patientVaccineReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PATIENT_VACCINE_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_PATIENT_VACCINE_SUCCESS:
            console.log("patient vaccine payload",action.payload)
            return {
                ...state,
                patientVaccine: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_VACCINE_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                patientVaccine:[]
            } 


        default:
            return state;
    }
} 