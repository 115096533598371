import * as types from '../../actionTypes';

const initialState = {
    patientReport: [],
    patientInfo: {},
    error: {},
    listValue: {},
    previousVisits: [],
    singleVisit: {},
    active: false,
    singleSearch: "",
    searchMedList: [],
    medicine: [],
    reportShow: false,
    suggestedMedicines: [],
    selectedMedicineRemarks: [],
    images: [],
    imageAdd: "",
    patientToggle: false,
    vitalsChart: {},
    riskFactors: {},
    form: {},
    formArr: [],
    certificateToggle: null,
    patientNote: null,
    replicated: {},
    printReport: [],
    multilistQueue: [],
    selectedImages: [],
    selectedDocuments: [],
    storeAppointments: [],
    autoRecPatientId: null,
    opdReport: {},
    searchMedicineByTerm: [],
    speechData: "",
    allergens: [],
    vitals: [],
    radiologicalFinding: [],
    clinicalFinding: [],
    diagnosis: [],
    labtests: [],
    procedure: [],
    toggling: false,
    alertToggle: false,
    replicateStatus: {},
    loading: false

}


export const patientReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PATIENT_REPORT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_PATIENT_REPORT_SUCCESS:
            return {
                ...state,
                patientReport: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_REPORT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                patientReport: []
            }
        case types.GET_PATIENT_OPD_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.GET_PATIENT_OPD_SUCCESS:
            return {
                ...state,
                patientInfo: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_OPD_FAIL:
            return {
                ...state,
                patientInfo: {},
                loading: false,
                error: action.payload
            }
        case types.GET_LIST_VALUE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.GET_LIST_VALUE_SUCCESS:
            let curValue = state.listValue
            curValue[action.payload.type] = action.payload
            return {
                ...state,
                listValue: curValue,
                loading: false,
                error: {}
            }
        case types.GET_LIST_VALUE_FAIL:
            return {
                ...state,
                listValue: {},
                loading: false,
                error: action.payload
            }
        case types.GET_ALL_PATIENT_VISITS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.GET_ALL_PATIENT_VISITS_SUCCESS:
            return {
                ...state,
                previousVisits: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_ALL_PATIENT_VISITS_FAIL:
            return {
                ...state,
                previousVisits: [],
                loading: false,
                error: action.payload
            }
        case types.GET_SINGLE_VISIT:
            return {
                ...state,
                singleVisit: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_SINGLE_VISIT_STATUS:
            return {
                ...state,
                active: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_SUGGESTED_MEDICINES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.GET_SUGGESTED_MEDICINES_SUCCESS:
            return {
                ...state,
                suggestedMedicines: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_SUGGESTED_MEDICINES_FAIL:
            return {
                ...state,
                suggestedMedicines: [],
                loading: false,
                error: action.payload
            }
        case types.SINGLE_SELECT_SEARCH_VALUE:
            return {
                ...state,
                singleSearch: action.payload,
                loading: false,
                error: {}
            }
        case types.SEARCH_MEDICINE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.SEARCH_MEDICINE_SUCCESS:
            return {
                ...state,
                searchMedList: action.payload,
                loading: false,
                error: {}
            }
        case types.SEARCH_MEDICINE_FAIL:
            return {
                ...state,
                searchMedList: [],
                loading: false,
                error: action.payload
            }
        case types.SELECTED_MEDICINE_REMARKS_SUCCESS:
            return {
                ...state,
                selectedMedicineRemarks: action.payload,
                loading: false,
                error: {}
            }

        case types.SAVE_PATIENT_NOTE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.SAVE_PATIENT_NOTE_SUCCESS:
            return {
                ...state,
                patientNote: action.payload,
                loading: false,
                error: {}
            }
        case types.SAVE_PATIENT_NOTE_FAIL:
            return {
                ...state,
                patientNote: null,
                loading: false,
                error: action.payload
            }
        case types.GET_PATIENT_NOTE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.GET_PATIENT_NOTE_SUCCESS:
            return {
                ...state,
                patientNote: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_NOTE_FAIL:
            return {
                ...state,
                patientNote: null,
                loading: false,
                error: action.payload
            }
        case types.UPDATE_PATIENT_NOTE:
            return {
                ...state,
                patientNote: action.payload,
                loading: false,
                error: {}
            }
        case types.ADD_MEDICINE_OPD:
            return {
                ...state,
                medicine: action.payload,
                loading: false,
                error: {}
            }

        case types.UPDATE_VISIT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.UPDATE_VISIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {}
            }
        case types.UPDATE_VISIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case types.SHOW_OPD_REPORT:
            return {
                ...state,
                loading: false,
                reportShow: action.payload,
                error: {}
            }
        case types.REMOVE_BANK_SPECIAL_NOTE:
            return {
                ...state,
                loading: false,
                error: {}
            }
        case types.UPLOAD_VISIT_IMAGE_SUCCESS:
            return {
                ...state,
                imageAdd: action.payload,
                loading: false,
                error: {}
            }
        case types.VIEW_VISIT_IMAGE_SUCCESS:
            return {
                ...state,
                images: action.payload,
                loading: false,
                error: {}
            }
        case types.UPLOAD_VISIT_PDF_SUCCESS:
            return {
                ...state,
                imageAdd: action.payload,
                loading: false,
                error: {}
            }
        case types.PATIENT_PROFILE_TOGGLE:
            return {
                ...state,
                patientToggle: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_RISK_SUCCESS:
            return {
                ...state,
                riskFactors: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_RISK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                riskFactors: {}
            }
        case types.SAVE_PATIENT_RISK_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
            }
        case types.UPDATE_RISK_FACTORS:
            return {
                ...state,
                riskFactors: action.payload,
                loading: false,
                error: {},
            }
        case types.CLOSE_VISIT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.CLOSE_VISIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {}
            }
        case types.CLOSE_VISIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case types.OPEN_VISIT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.OPEN_VISIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {}
            }
        case types.OPEN_VISIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case types.GET_OPD_FORMS_SUCCESS:
            return {
                ...state,
                form: action.payload,
                loading: false,
            }
        case types.CERTIFICATE_TOGGLE:
            return {
                ...state,
                certificateToggle: action.payload,
                loading: false,
            }
        case types.REMOVE_CERTIFICATE_DATA:
            return {
                ...state,
                form: action.payload,
                loading: false,
            }
        case types.CREATE_FORM_ARRAY:
            return {
                ...state,
                formArr: action.payload,
                loading: false,
            }
        case types.GET_VITALS_CHART_SUCCESS:
            return {
                ...state,
                vitalsChart: action.payload,
                loading: false
            }
        case types.REPLICATE_TEMPLATE_LOADING:
            return {
                ...state,
                loading: false,
            }
        case types.REPLICATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                replicated: action.payload,
                loading: false,
            }
        case types.REPLICATE_TEMPLATE_FAIL:
            return {
                ...state,
                loading: false,
            }
        case types.SAVE_TEMPLATE_LOADING:
            return {
                ...state,
                loading: false,
            }
        case types.SAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.SAVE_TEMPLATE_FAIL:
            return {
                ...state,
                loading: false,
            }
        case types.DELETE_TEMPLATE_LOADING:
            return {
                ...state,
                loading: false,
            }
        case types.DELETE_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.DELETE_TEMPLATE_FAIL:
            return {
                ...state,
                loading: false,
            }
        case types.VISIT_PRINT_REPORT_LOADING:
            return {
                ...state,
                loading: false,
            }
        case types.VISIT_PRINT_REPORT_SUCCESS:
            return {
                ...state,
                printReport: action.payload,
                loading: false,
            }
        case types.VISIT_PRINT_REPORT_FAIL:
            return {
                ...state,
                loading: false,
            }
        case types.DELETE_VISIT_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.APPOINTMENTS_ALL_MULTI_QUEUE_SUCCESS:
            return {
                ...state,
                multilistQueue: action.payload,
                loading: false
            }
        case types.ALL_REPORTS_TOGGLE:
            return {
                ...state,
                toggling: action.payload,
                loading: false,
            }
        case types.HE_ALERT_TOGGLE:
            return {
                ...state,
                alertToggle: action.payload,
                loading: false,
            }
        case types.GET_SELECTED_IMAGES_SUCCESS:
            return {
                ...state,
                selectedImages: action.payload,
                loading: false,
            }
        case types.STORE_APPOINTMENTS:
            return {
                ...state,
                storeAppointments: action.payload,
                loading: false,
            }
        case types.GET_SELECTED_DOCUMENTS_SUCCESS:
            return {
                ...state,
                selectedDocuments: action.payload,
                loading: false,
            }
        case types.AUTO_OPEN_REC_VISIT:
            return {
                ...state,
                autoRecPatientId: action.payload,
                loading: false,
            }
        case types.GET_PATIENT_OPD_REPORT_SUCCESS:
            return {
                ...state,
                opdReport: action.payload,
                loading: false,
            }
        case types.UPDATE_FORM_SPEECH_DATA:
            return {
                ...state,
                speechData: action.payload,
                loading: false,
            }
        case types.GET_SEARCH_TERM_SUCCESS:
            return {
                ...state,
                searchMedicineByTerm: action.payload,
                loading: false,
            }
        case types.RESET_MEDICINE_DATA:
            return {
                ...state,
                searchMedList: action.payload,
                loading: false,
            }
        case types.STORE_ALLERGENS_FROM_FIREBASE:
            return {
                ...state,
                allergens: action.payload,
                loading: false,
            }
        case types.STORE_CLINICALFINDING_FROM_FIREBASE:
            return {
                ...state,
                clinicalFinding: action.payload,
                loading: false,
            }
        case types.STORE_DIAGNOSIS_FROM_FIREBASE:
            return {
                ...state,
                diagnosis: action.payload,
                loading: false,
            }
        case types.STORE_PROCEDURE_FROM_FIREBASE:
            return {
                ...state,
                procedure: action.payload,
                loading: false,
            }
        case types.STORE_RADIOLOGICALFINDING_FROM_FIREBASE:
            return {
                ...state,
                radiologicalFinding: action.payload,
                loading: false,
            }
        case types.STORE_VITALS_FROM_FIREBASE:
            return {
                ...state,
                vitals: action.payload,
                loading: false,
            }
        case types.STORE_LABTESTS_FROM_FIREBASE:
            return {
                ...state,
                labtests: action.payload,
                loading: false,
            }
        case types.REPLICATE_VISIT_STATUS:
            return {
                ...state,
                replicateStatus: action.payload,
                loading: false
            }
        case types.DUPLICATE_VISIT_LOADING:
            return {
                ...state,
                loading:false
            }
        case types.DUPLICATE_VISIT_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case types.DUPLICATE_VISIT_FAILURE:
            return {
                ...state,
                loading:false
            }
        default:
            return state;
    }
}