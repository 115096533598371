import React from 'react'
import './Electrolytes.css'
import { TestReportRightSection } from './TestReportRightSection'
import ReportFooter from './ReportFooter'
import Elec from './Electrolytes/Elec'
import { Male } from './Electrolytes/Male'
import { Female } from './Electrolytes/Female'
import { Pregnency } from './Electrolytes/Pregnency'
import { useState } from 'react'
import { genderName } from '../../../redux/actions/Diagnostics/diagnostics'
import { useSelector,useDispatch } from 'react-redux'

export const Electrolytes = () => {
    const dispatch = useDispatch() 
    const [maleElec, setMaleElec] = useState(false);
    const [femaleElec, setFemaleElec ] = useState(false);
    const [PregnencyElec, setPregnencyElec] = useState(false);
    const [elect, setElect] = useState(true);

    const maleOn = (value) =>{
      setMaleElec(true);
      setFemaleElec(false);
      setPregnencyElec(false);
      setElect(false);
      dispatch(genderName(value))
      console.log("emnoimnoeimiomne",value);

    }

    const femaleOn = (value) =>{
      setMaleElec(false);
      setFemaleElec(true);
      setPregnencyElec(false);
      setElect(false);
      dispatch(genderName(value))

    }

    const PregnancyOn = (value) =>{
      setMaleElec(false);
      setFemaleElec(false);
      setPregnencyElec(true);
      setElect(false);
      dispatch(genderName(value))

    }

   
  return (
    <div>
        <div className='electrolyte-parent'>
        <div className='electrolyte-left'>
          {elect ? <Elec /> : ''}
            {maleElec ? <Male /> : '' }
            {femaleElec ? <Female /> :'' }
            {PregnencyElec ? <Pregnency /> : '' }
            </div>
             
            <div className=''>
                <TestReportRightSection />
            </div>
        </div>
        <div className='elec-gender'>
              <button className='gen' onClick={() => maleOn('Male')}>Male</button>
              <button className='gen' onClick={() => femaleOn('Female')}>Female</button>
              <button className='gen' onClick={() => PregnancyOn('Pregnancy')}>Pregnancy</button>
        </div>
        <div className='elec-foot'>
              <ReportFooter />
              <div>
              {elect ? '' : ''}
              {maleElec ? <div>
                <h3>Note:</h3>
                <p>Guidelines of National Cholesterol Education Program Adult Treatment Panel III (Values in mg/dl)</p>
                <br />
                <div>
                  <h3>Interpretation:</h3>
                </div>
                
                  <table>
                    <tbody className='tab-elec'>
                        <tr>
                          <th>1. Total Cholestrol</th>
                          <th>1. Triglycerides</th>
                          <th>1. Cholestrol / HDL Ratio</th>
                        </tr>
                        <tr>
                           <td valign="top">Desirable &lt; 200 <br />Boderline high 200 – 239 <br />High &gt; 240</td>
                           <td valign="top">Normal &lt; 150 <br />Boderline high 150 – 199<br />High 200 – 499 <br />Very High &gt; 500</td>
                            <td valign="top">Low Risk 3.3 – 4.4 <br />Average Risk 4.5 – 7.0 <br />Medium Risk 7.1 – 11.0 <br />High Risk &gt; 11.0</td>
                        </tr>
                        <br />
                        <tr>
                          <th>2. LDL optimal &gt; 100</th>
                          <th>2. Non HDL Cholestrol</th>
                          <th>2. LDL / HDL Ratio</th>
                        </tr>
                        <tr>
                           <td valign="top">Near optimal 100 – 129<br />Boderline high 130 – 159 <br />High 160 – 189 <br /> Very High &gt; 190</td>
                           <td valign="top">Desirable &lt; 130 <br />Above Desirable 130 – 159 <br />Boderline High 160 – 189 <br />High 190 – 219 <br />Very High &gt; 220</td>
                            <td valign="top">Low Risk 0.5 – 3.0 <br /> Boderline Risk 3.1 – 6 <br /> High Risk &gt; 6.0</td>
                        </tr>
                    </tbody>
                  </table>
                 </div> : '' }
              {femaleElec ? <div><h3>Note:</h3>
                <p>Guidelines of National Cholesterol Education Program Adult Treatment Panel III (Values in mg/dl)</p>
                <br />
                <div>
                  <h3>Interpretation:</h3>
                </div>
                
                  <table>
                    <tbody className='tab-elec'>
                        <tr>
                          <th>1. Total Cholestrol</th>
                          <th>1. Triglycerides</th>
                          <th>1. Cholestrol / HDL Ratio</th>
                        </tr>
                        <tr>
                           <td valign="top">Desirable &lt; 200 <br />Boderline high 200 – 239 <br />High &gt; 240</td>
                           <td valign="top">Normal &lt; 150 <br />Boderline high 150 – 199<br />High 200 – 499 <br />Very High &gt; 500</td>
                            <td valign="top">Low Risk 3.3 – 4.4 <br />Average Risk 4.5 – 7.0 <br />Medium Risk 7.1 – 11.0 <br />High Risk &gt; 11.0</td>
                        </tr>
                        <br />
                        <tr>
                          <th>2. LDL optimal &gt; 100</th>
                          <th>2. Non HDL Cholestrol</th>
                          <th>2. LDL / HDL Ratio</th>
                        </tr>
                        <tr>
                           <td valign="top">Near optimal 100 – 129<br />Boderline high 130 – 159 <br />High 160 – 189 <br /> Very High &gt; 190</td>
                           <td valign="top">Desirable &lt; 130 <br />Above Desirable 130 – 159 <br />Boderline High 160 – 189 <br />High 190 – 219 <br />Very High &gt; 220</td>
                            <td valign="top">Low Risk 0.5 – 3.0 <br /> Boderline Risk 3.1 – 6 <br /> High Risk &gt; 6.0</td>
                        </tr>
                    </tbody>
                  </table> </div> :'' }
              {PregnencyElec ? <div>
                <h3>Note:</h3>
                <p>Guidelines of National Cholesterol Education Program Adult Treatment Panel III (Values in mg/dl)</p>
               </div> : '' }
              </div>
        </div>
    </div>
  )
}