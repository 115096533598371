import React, { useState } from 'react'
import '../SemenAnylasis/SemenAnylasis.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const FormF = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'FORM F')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'FORM F'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index] = filterData[0]
      console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    
  }
 
  const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))     
    }
   return (
    <div>     
    <div className='semen-parent'>
      <div className='semen-left'>
          <div className='semen-grid'>
              <div className='semen1 semen-pad' onClick={() => onLabelClicked(" Form F No")}>Form F No</div>
              <div className='semen2 semen-text form2'><TextAreaAnt  value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border'></span>
              <div className='form-pad' onClick={() => onLabelClicked(" Reason For U/S")}>Reason For U/S</div>
              <div className='formtxt form2'><TextAreaAnt multiline={true} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
              <span className='semen-border2'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("LMP ")}>LMP</div>
              <div className='semen-text'><TextAreaAnt name="LMP" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border3'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("EDOD ")}>EDOD</div>
              <div className='semen-text '><TextAreaAnt name="EDOD" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border4'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" No of Foetus")}>No of Foetus</div>
              <div className='semen-text '><TextAreaAnt name="No of Foetus" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border5'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" Condition")}> Condition</div>
              <div className='semen-text '><TextAreaAnt name="Condition" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border6'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("Position/Presentation ")}>  Position/Presentation</div>
              <div className='semen-text '><TextAreaAnt name="Position/Presentation" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border7'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" L/E")}> L/E {"{PH}"}</div>
              <div className='semen-text '><TextAreaAnt name="L/E " value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border8'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" BPD")}> BPD </div>
              <div className='semen-text '><TextAreaAnt name="BPD" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border9'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" F/L ")}> F/L</div>
              <div className='semen-text '><TextAreaAnt name="" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border10'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("CRI ")}> CRI</div>
              <div className='semen-text '><TextAreaAnt name="CRI" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border11'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("AC ")}> AC</div>
              <div className='semen-text '><TextAreaAnt name="AC" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border12'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("Fetal Heart ")}> Fetal Heart</div>
              <div className='semen-text '><TextAreaAnt name="Fetal Heart" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border13'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" Fetal Heart Rate")}> Fetal Heart Rate</div>
              <div className='semen-text '><TextAreaAnt name="Fetal Heart Rate" value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border14'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("Limb Movement ")}> Limb Movement</div>
              <div className='semen-text '><TextAreaAnt name="Limb Movement" value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border15'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("Liquir Amini ")}> Liquir Amini</div>
              <div className='semen-text '><TextAreaAnt name="Liquir Amini" value={reportDataFields?.testReport?.[indexValue]?.fields?.[15]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border16'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" Placenta Position")}> Placenta Position</div>
              <div className='semen-text '><TextAreaAnt name="Placenta Position" value={reportDataFields?.testReport?.[indexValue]?.fields?.[16]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border17'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("Approximate Weight ")}> Approximate Weight</div>
              <div className='semen-text '><TextAreaAnt name="Approximate Weight" value={reportDataFields?.testReport?.[indexValue]?.fields?.[17]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border18'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("MATURITY GRADE ")}>MATURITY GRADE</div>
              <div className='semen-text '><TextAreaAnt name="MATURITY GRADE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[18]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border19'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("FETAL ABNORMALITIES ")}>FETAL ABNORMALITIES</div>
              <div className='semen-text '><TextAreaAnt name="FETAL ABNORMALITIES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[19]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border20'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("DIAGNOSIS ")}>DIAGNOSIS</div>
              <div className='semen-text '><TextAreaAnt name="DIAGNOSIS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[20]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border21'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("ADVISED ")}>ADVISED</div>
              <div className='semen-text '><TextAreaAnt name="ADVISED" value={reportDataFields?.testReport?.[indexValue]?.fields?.[21]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border22'></span>
          </div>
      </div>
      <div>
          <TestReportRightSection />
      </div>
    </div>
    <div className='semen-foot'>
        <ReportFooter />
    </div>
    <div className='semen-method'>
              <h4>DECLARATION:</h4>
              <p>I hereby declare that while conducting ultrasound on I have neither detected nor disclosed the sex of foetus to anybody in any manner.</p>
        </div>
</div>
  )
}
