import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import './Thyroid.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function Thyroid() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'THYROID')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'THYROID'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
 
  return (
    <div>
        <div className='thyroid-parent'>
          <div className='thyroid-left'>
            <div className='thyroid-grid'>
                <div className='thyroid-1'></div>
                <div className='thyroid-2 thyroid-pad'>TSH</div>
                <div className='thyroid-3'><Input type='number' className={'thyroid-input'}  name="TSH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='thyroid-4 thyroid-pad'>MICRO IU/ML</div>
                <div className='thyroid-5'>0.35 - 5.50</div>
            </div>
          </div>
          <div>
              <TestReportRightSection />
          </div>
        </div>
        <div className='thyroid-foot'>
            <ReportFooter />
        </div>
        
    </div>
  )
}

export default Thyroid
