import * as types from "../../../actionTypes";

const initialState = {
  doctorSignUpInfo: [],
  assistantDoc: [],
  assistantMapping: [],
  initializeDoctor: [],
  inputDoctor: {
    name: "",
    mobile: "",
    fullname: "",
    ima: "",
    password: "",
    confirmPassword: "",
  },
  editDoctor: true,
  rend: true,
  doctorEdit: [],
  error: {},
  loading: false,
};

export const doctorSignUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_DOCTOR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_DOCTOR_SUCCESS:
      return {
        ...state,
        doctorSignUpInfo: action.payload,
        loading: false,
        error: {},
      };
    case types.CREATE_DOCTOR_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        doctorSignUpInfo: [],
      };
    case types.ASSISTANT_TO_DOC_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.ASSISTANT_TO_DOC_SUCCESS:
      return {
        ...state,
        assistantDoc: action.payload,
        loading: false,
        error: {},
      };
    case types.ASSISTANT_TO_DOC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        assistantDoc: [],
      };
    case types.ASSISTANT_MAPPED_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.ASSISTANT_MAPPED_SUCCESS:
      return {
        ...state,
        assistantMapping: action.payload,
        loading: false,
        error: {},
      };
    case types.ASSISTANT_MAPPED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        assistantMapping: [],
      };
    case types.DOCTOR_INITIALIZE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.DOCTOR_INITIALIZE_SUCCESS:
      return {
        ...state,
        initializeDoctor: action.payload,
        loading: false,
        error: {},
      };
    case types.DOCTOR_INITIALIZE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        initializeDoctor: [],
      };
    case types.DOC_EDIT:
      return {
        ...state,
        editDoctor: action.payload,
      };
    case types.DOC_INPUT:
      return {
        ...state,
        inputDoctor: action.payload,
      };
    case types.DOC_CLEAR:
      return {
        ...state,
        inputDoctor: {
          name: "",
          mobile: "",
          fullname: "",
          ima: "",
          password: "",
          confirmPassword: "",
        },
      };
    case types.EDIT_DOCTOR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_DOCTOR_SUCCESS:
      return {
        ...state,
        doctorEdit: action.payload,
        loading: false,
        error: {},
      };
    case types.EDIT_DOCTOR_FAIL:
      return {
        ...state,
        error: action.payload,
        doctorEdit: [],
        loading: false,
      };
    case types.DOC_REND:
      return {
        ...state,
        rend: action.payload,
      };
    default:
      return state;
  }
};
