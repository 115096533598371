import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import micImage from "../../../images/mic-new.png";
import muteImage from "../../../images/mute.png";
import opdprofileicon from "../../../images/opdprofileicon.svg";
import videoImage from "../../../images/video-new.png";
import { updateVisit } from "../../../redux/actions/opd/patientopdinvoice";
import { getPatientProfileToggle } from "../../../redux/actions/opd/patientreport";
import { LOGIN_DOCTOR_FAIL } from "../../../redux/actionTypes";
import { toast } from "../../../UIComponents/Toast";
import NewPatient from "../../Appointments/CreatePatient/NewPatient";
import "../PatientProfile/PatientProfile.css";
import Video from "../Video/Video";
import { getPatientAppointmet } from "../../../redux/actions/appointments/getPatient";
import { getPatientModified } from "../../../redux/actions/appointments/getPatient";
import moment from "moment";
import { resetTwilioToken } from "../../../redux/actions/video/videoCall";

function PatientProfile() {
  let dispatch = useDispatch();
  const [call, setCall] = useState(false);
  const [start, setStart] = useState(false);
  const [dictationKey, setDictatonKey] = useState("");
  const [apiData, setApiData] = useState("");
  const [speechAWSTranscribeKey, setSpeechAWSTranscribeKey] = useState(null);
  const [finalVital, setFinalVital] = useState([]);
  const [diagnosisFromTranscript, setDiagnosisFromTranscript] = useState([]);
  const [age, setAge] = useState("");

  const patientInfo = useSelector(
    (state) => state.opdrecord.patientreport.patientInfo
  );
  console.log("patient info", patientInfo);

  const patientToggle = useSelector(
    (state) => state.opdrecord.patientreport.patientToggle
  );
  console.log("patientToggle", patientToggle);

  const getSinglePatientData = useSelector(
    (state) => state.appointments.getPatient.getPatient
  );
  console.log("getSinglePatientData", getSinglePatientData);
  const active = useSelector((state) => state.opdrecord.patientreport.active);
  console.log("single visit status", active);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const patientProfileEdit = () => {
    dispatch(getPatientProfileToggle(true));
    dispatch(getPatientModified("editProfile"));
  };

  const handleOk = () => {
    dispatch(getPatientProfileToggle(false));
  };

  const handleCancel = () => {
    dispatch(getPatientProfileToggle(false));
  };

  const viewMoreHandler = () => {
    setIsModalVisible(true);
  };

  const handleViewOk = () => {
    setIsModalVisible(false);
  };

  const handleViewCancel = () => {
    setIsModalVisible(false);
  };

  const videoCallHandler = () => {
    setCall(true);
  };
  const handleCancelVideo = (returnToLobby, disconnect) => {
    setCall(false);
    document.getElementById("leaveRooms")?.click();
    dispatch(resetTwilioToken());
  };
  const handleOkVideo = () => {
    setCall(false);
  };

  //speechRecg
  const fieldNames = useSelector(
    (state) => state?.diagnosticsInvoiceSummary.fieldName
  );
  const doctorId = useSelector((state) => state.profile.profileInfo?.id);
  console.log("fieldNames", fieldNames);
  const ReportNamee = useSelector(
    (state) => state.diagnosticsInvoiceSummary.reportNames
  );
  console.log("ReportNamee", ReportNamee);
  const visitInfo = useSelector(
    (state) => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo
  );
  console.log("visitInfo", visitInfo);
  const afterCommand = useSelector(
    (state) => state?.appointments?.getAppointment?.speechs
  );
  console.log("afterCommand", afterCommand);
  const vitalsSpeech = useSelector(
    (state) => state?.appointments?.getAppointment?.speechForVital
  );
  console.log("vitalsSpeech", vitalsSpeech);
  const patientData = useSelector(
    (state) => state?.appointments?.getPatient?.getPatient
  );
  console.log("patientData", patientData);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    toast("Browser doesn't support speech recognition.");
  }

  const startButton = () => {
    if (!start) {
      resetTranscript();
      setStart(true);
      console.log("listening");
      SpeechRecognition.startListening({ language: "en-IN", continuous: true });
    }
  };
  const stopSpeech = () => {
    setStart(false);
    SpeechRecognition.stopListening();
  };
  console.log("ijeijijijejie", visitInfo, diagnosisFromTranscript);
  const updateDiagnosis = (key) => {
    console.log(
      "🚀 ~ file: PatientProfile.js ~ line 119 ~ updateDiagnosis ~ key",
      key,
      diagnosisFromTranscript,
      visitInfo
    );
    if (key === "diagnosis") {
      const newDiagnosis = diagnosisFromTranscript.map((item) => {
        return { name: item };
      });
      console.log("after appending", newDiagnosis);

      const conactedArray = visitInfo?.diagnosis
        ? visitInfo?.diagnosis.concat(newDiagnosis)
        : newDiagnosis;

      console.log("after appending", conactedArray);
      dispatch(updateVisit({ ...visitInfo, diagnosis: conactedArray }));
      console.log("after appending", newDiagnosis, visitInfo);
      setSpeechAWSTranscribeKey(null);
    }
  };

  console.log("after appending visitInfo", visitInfo, speechAWSTranscribeKey);

  useEffect(() => {
    // setFinalData(transcript)
    console.log(
      "transcriptInsideUseEffech",
      transcript,
      ["stop"].includes(transcript),
      speechAWSTranscribeKey
    );
    // TODO: Convert this into a function
    if (transcript.includes("stop")) {
      console.log("stop keyword detected");
      toast.success("stopped");
      updateDiagnosis(speechAWSTranscribeKey);
      setStart(false);
      SpeechRecognition.stopListening();
      if (speechAWSTranscribeKey !== "dignosis") {
        setSpeechAWSTranscribeKey(null);
      }
      stopSpeech();
      resetTranscript();
      //SpeechRecognition.stopListening()
    } else if (
      ["vital", "narrative", "diagnosis", "medicine"].includes(transcript)
    ) {
      console.log("this is transcript", transcript);
      console.log("inside condition");
      toast.success("Listening for " + transcript);
      setSpeechAWSTranscribeKey(transcript);
      resetTranscript();
      // dispatch(speech(transcript))
    } else if (speechAWSTranscribeKey) {
      console.log(
        "Capturing all the words spoken after hotkey",
        transcript,
        speechAWSTranscribeKey
      );
      if (speechAWSTranscribeKey === "narrative") {
        console.log("this is narrative");
        console.log("Listening for - " + speechAWSTranscribeKey, transcript);
        dispatch(updateVisit({ ...visitInfo, narratives: transcript }));
        console.log("narrativeAfterSpeach", visitInfo);
      } else if (speechAWSTranscribeKey === "diagnosis") {
        console.log(
          "diagnosis inside else if",
          speechAWSTranscribeKey,
          transcript
        );
        const arrSplit = transcript.split(" ");
        setDiagnosisFromTranscript(arrSplit);
      } else if (speechAWSTranscribeKey === "vital") {
        console.log("Listening for - " + speechAWSTranscribeKey, transcript);
        if (transcript) {
          axios
            .get("https://api.wit.ai/message?v=20210330", {
              params: {
                q: transcript,
              },
              headers: {
                Authorization: `Bearer ${"UIMW6VZ3BIBKF5QQPFEKHCZFODU56YFZ"}`,
              },
            })
            .then((response) => {
              console.log("dijiejije", response.data);
              let name, value;
              console.log(
                "Before if condition - parameter",
                response.data?.entities?.["parameter:parameter"][0]?.value
              );
              console.log(
                "Before if condition - value",
                response.data?.entities?.["value:value"][0]?.value
              );
              if (response.data?.entities?.["parameter:parameter"][0]?.value) {
                console.log(
                  "Im inside if condition paramerter",
                  response.data?.entities?.["parameter:parameter"][0]?.value
                );
                name =
                  response.data?.entities?.["parameter:parameter"][0]?.value;
              }
              if (response.data?.entities?.["value:value"][0]?.value)
                value = response.data?.entities?.["value:value"][0]?.value;
              setFinalVital([...finalVital, { name, value }]);
              let visitinfoVitals;
              if (visitInfo.vitals) {
                visitinfoVitals = [...visitInfo.vitals, { name, value }];
              } else {
                visitinfoVitals = [{ name, value }];
              }
              console.log("neinine", visitinfoVitals);
              dispatch(updateVisit({ ...visitInfo, vitals: visitinfoVitals }));
              resetTranscript();
            })
            .catch((error) => {
              resetTranscript();
            });
        }
      } else if (speechAWSTranscribeKey === "medicine") {
        axios
          .get("https://api.wit.ai/message?v=20210330", {
            params: {
              q: "Ozimin twice daily for 8days",
            },
            headers: {
              Authorization: `Bearer LKR77QNCYR73MZCCSJ2JSDQ2NKLXPLWT`,
            },
          })
          .then((response) => {
            console.log("response for medicine speech", response.data);
            const { entities } = response.data;
            resetTranscript();

            let name, days, freq, time, visitinfoMedicines;
            if (entities["name:name"] && entities["name:name"][0].value) {
              name = entities["name:name"][0].value;
            }
            if (entities["days:days"] && entities["days:days"][0].value)
              days = entities["days:days"][0].value;
            if (entities["freq:freq"] && entities["freq:freq"][0].value)
              freq = entities["freq:freq"][0].value;
            if (entities["timing:timing"] && entities["timing:timing"][0].value)
              time = entities["timing:timing"][0].value;
            console.log(
              "checking all the values for medicine",
              name,
              days,
              freq,
              time,
              entities
            );
            let frequency;
            let medicineTimingsSplit;
            let timing;
            if (response.data && response.data.entities) {
              const name = response.data?.entities["name:name"][0].value;
              if (
                entities &&
                entities["freq:freq"] &&
                entities["freq:freq"].length
              ) {
                const frequenyMed = entities["freq:freq"][0].value;
                switch (frequenyMed) {
                  case "once":
                    frequency = "OD";
                    medicineTimingsSplit = ["Morning"];
                    timing = "Morning";
                    break;
                  case "twice":
                    frequency = "BD";
                    medicineTimingsSplit = ["Morning", "Night"];
                    timing = "Morning,Night";
                    break;
                  case "thrice":
                    frequency = "TDS";
                    medicineTimingsSplit = [];
                    timing = "NA";
                    break;
                  case "every":
                    frequency = "OD";
                    medicineTimingsSplit = ["Morning"];
                    timing = "Morning";
                    break;
                  default:
                    return;
                }
              }
            }
            if (visitInfo.medicines) {
              visitinfoMedicines = [
                ...visitInfo.medicines,
                {
                  name: name,
                  days: days,
                  freq: frequency ? frequency : freq,
                  timing: time,
                },
              ];
            } else {
              visitinfoMedicines = [
                {
                  name: name,
                  days: days,
                  freq: frequency ? frequency : freq,
                  timing: time,
                },
              ];
            }
            console.log("visitinfoMedicines", visitinfoMedicines);
            dispatch(
              updateVisit({ ...visitInfo, medicines: visitinfoMedicines })
            );
          })
          .catch((error) => {
            console.log("erorrro", error);
          });
      }
    }
  }, [transcript]);

  const ageCal = () => {
    const age = patientData?.age;
    if (age) {
      const [value, type] = age.split(" ");
      switch (type) {
        case "Wk":
          if (value > 4) {
            const month = parseInt(moment.duration(value, "week").as("months"));
            setAge(month + " Months");
          } else return setAge(value + " Weeks");
          break;
        case "Mth":
          if (value > 12) {
            const year = parseInt(moment.duration(value, "month").as("years"));
            setAge(year + " Years");
          } else return setAge(value + " Months");
          break;
        case "Yr":
          return setAge(value + " Years");
          break;
        default:
          return;
      }
    } else return "";
  };

  useEffect(() => {
    ageCal();
  }, [patientData]);

  return (
    <div className="patient-profile-main">
      <div className="patient-profile">
        <div className="profile">
          <img src={opdprofileicon} alt="" className="user-icon" />
          <div className="patient-details-container">
            <div className="profile">
              <p className="patient-name">{patientData?.name}</p>
              <EditOutlined
                className="edit-icon"
                onClick={patientProfileEdit}
              />
            </div>
            <div className="patient-details">
              <p>
                {patientData?.gender === "M" ? (
                  <span>Male</span>
                ) : (
                  <span>Female</span>
                )}{" "}
                <span>|</span> {age} old <span>|</span> PH : 91{" "}
                {patientData?.mobile} <span>|</span>{" "}
                {patientData?.address?.address1
                  ? `${patientData?.address?.address1},`
                  : ""}
                {patientData?.address?.address2
                  ? `${patientData?.address?.address2},`
                  : ""}
                {patientData?.address?.city},{patientData?.address?.state}
                <span onClick={viewMoreHandler} className="view-more-p">
                  View More
                </span>
              </p>
            </div>
          </div>
          <div className="speech">
            <div>
              {start === false ? (
                <img
                  src={muteImage}
                  onClick={startButton}
                  alt="mute"
                  className={
                    active ? "disable-video speech-icon" : "speech-icon"
                  }
                />
              ) : (
                <img
                  src={micImage}
                  onClick={stopSpeech}
                  alt="mic"
                  className={
                    active ? "disable-video speech-icon" : "speech-icon"
                  }
                />
              )}
            </div>
            <p className="mic">MIC</p>
            {/* <div className='test-report-input'>
                <TextAreaAnt style="max-width:100%;" value={finalData} placeholder="Description" onChange={hangeleTextChange} cols={25} rows={3} />
                </div> */}

            {/* <img src={micImage} onClick={startButton} className="speech-icon" /> */}
          </div>
          <div className="video">
            <img
              src={videoImage}
              onClick={videoCallHandler}
              alt="video"
              className={
                active ? "disable-video video-camera-icon" : "video-camera-icon"
              }
            />
            <p className="mic">VIDEO CALL</p>
            {call ? (
              <Modal
                visible={call}
                onOk={handleOkVideo}
                onCancel={handleCancelVideo}
                width="400px"
                className="edit-medicine-modal-div"
              >
                <Video />
              </Modal>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {patientToggle ? (
        // width=900px previous value
        <Modal
          width="900px"
          className="bigdevice"
          visible={patientToggle}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <h1 className="edit-patient-modal">Edit Patient</h1>
          <NewPatient />
        </Modal>
      ) : (
        ""
      )}

      {isModalVisible ? (
        <div>
          <Modal
            width="800px"
            visible={isModalVisible}
            onOk={handleViewOk}
            onCancel={handleViewCancel}
          >
            <div className="patient-details-modal">
              <h1>Patient Details</h1>
              <div className="patient-details-modal-first-div">
                <div>
                  <h3>Name</h3>
                  <p>{getSinglePatientData?.name}</p>
                </div>
                <div>
                  <h3>Email</h3>
                  <p>{getSinglePatientData?.emailid}</p>
                </div>
                <div>
                  <h3>Age</h3>
                  <p>{getSinglePatientData?.age}</p>
                </div>
                <div>
                  <h3>Mobile Number</h3>
                  <p>{getSinglePatientData?.mobile}</p>
                </div>
              </div>
              <div className="patient-details-modal-first-div">
                <div>
                  <h3>Date of Birth</h3>
                  <p>{getSinglePatientData?.dob}</p>
                </div>
                <div>
                  <h3>Alternate Number</h3>
                  <p>{getSinglePatientData?.alternatePhone}</p>
                </div>
                <div>
                  <h3>Blood Group</h3>
                  <p>{getSinglePatientData?.bloodgroup}</p>
                </div>
                <div>
                  <h3></h3>
                  <p></p>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default PatientProfile;
