import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './Bsf.css'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'

const Bsf = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'BSF')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item.test === 'BSF'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields = finalFilteredData
      reportDataFields.testReport[index]  = filterData[0]
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))  
    }
   
    return (
        <div>
            <div className='bsf-main'>
                <div className='bsf-container'>
                    <div className='bsf-grid-container'>
                        <div className='padd'>FASTING BLOOD SUGAR EXAMINATION</div>
                        <div className='padd'>FASTING BLOOD GLUCOSE</div>
                        <div className='padd'></div>
                        <Input className='aptinputs' type="number" name="FASTING BLOOD GLUCOSE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
                        <div className='padd'>mg/dl</div>
                        <div className='padd'>60-110</div>
                        <div className='padd'></div>
                        <div className='padd'></div>
                        <span className='bil-border1'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}


export default Bsf