import React from 'react'
import Medicine from '../../components/EditMedicines/Medicine'
import './EditMedicines.css'
const EditMedicines = () => {
  return (
    <div className="main-section">
      <div className="editmedicine-section">
        <div className="edit-name" >
          <h1>EDIT MEDICINE</h1>
        </div>
        <div>
          <Medicine />
        </div>
       
      </div>
    </div>
  )
}

export default EditMedicines