import React, { useEffect } from 'react';
import './UserSignup.css';
import Button from '../../../UIComponents/Button/Button';
import InputField from '../../../UIComponents/Input/Input';
import SingleSelect from '../../../UIComponents/Select/SingleSelect';
import { useState } from 'react';
import { userSignUp } from '../../../redux/actions/admin/userSignup'
import { useDispatch } from 'react-redux';
import { toast } from '../../../UIComponents/Toast'


const data = [{
  id: 'assistant',
  name: 'assistant'
}, {
  id: 'converter',
  name: 'converter'
}
];

function UserSignup() {
  const [mobile, setNumber] = useState('');
  const [name, setFullName] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();

  const onChangeNumber = (e) => {
    setNumber(e.target.value);
  }
  const onChangeName = (e) => {
    setFullName(e.target.value.replace(/[^A-Za-z ]/gi, ""));
  }
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  }
  const onChangeConfirmPass = (e) => {
    setConfirmPassword(e.target.value);
  }
  const onChangeRole = (value) => {
    setRole(value);
    console.log("called");
  }

  useEffect(()=>{
    setPassword('');
    setRole()},
  [])


  const signUp = (e) => {
    if (!mobile || !name || !role || !password || !confirmPassword) {
      toast.error("Please enter all the input fields")
    }
    else if(password !== confirmPassword) {
      toast.error("Please enter valid password")
    }
    else if(mobile.length !== 10){
      toast.error("Please enter valid number")
    }
    else if(password.length && confirmPassword.length < 8){
      toast.error("Your password needs to be at least 8 characters long")
    }
    else {
      e.preventDefault();
      dispatch(userSignUp({ mobile, name, role, password, confirmPassword }))
      console.log(mobile, name, role, password, confirmPassword);
      setNumber('');
      setFullName('');
      setRole('');
      setPassword('');
      setConfirmPassword('');
    }
  }

  const cancel = () =>{
    setNumber('');
    setFullName('');
    setRole('');
    setPassword('');
    setConfirmPassword('');
  }

  return (
    <div className='user-container'>
      <h1 className='user-title'>
        User Signup
      </h1>

      <div className="user-subcontainer">

        <div className="user-record">
          <p>Mobile Number</p>
          <h4> <InputField type="number" value={mobile} onChange={onChangeNumber} onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
          }} /></h4>
        </div>
        <div className="user-record">
          <p>Full Name</p>
          <h4><InputField type="text" value={name} onChange={onChangeName} /></h4>
        </div>
        <div className="user-record">
          <p>Role</p>
          <SingleSelect data={data} className="role" placeholder="Assistant" value={role} onChange={onChangeRole} />
        </div>
        <div className="user-record">
          <p>Password</p>
          <h4><InputField type="password" value={password} onChange={onChangePassword} /></h4>
        </div>
        <div className="user-record">
          <p>Confirm New Password</p>
          <h4> <InputField type="password" value={confirmPassword} onChange={onChangeConfirmPass} /></h4>
        </div>

        <div className='calls-modal-btn'>
          <Button onClick={signUp} children="SIGN UP" className="signup-button1" />
          <Button children="CLEAR" onClick={cancel} className="signup-button2" />
        </div>
      </div>
    </div>
  )
}

export default UserSignup