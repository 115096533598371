import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import './LiverFunctionTest.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function LiverFunctionTest() {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'LIVER FUNCTION TEST')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'LIVER FUNCTION TEST'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index]  = filterData[0]
      console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
        <div className='liverFunctionTest-parent'>
          <div className='liverFunctionTest-left'>
            <div className='liverFunctionTest-grid'>

            <div className='liverFunctionTest-1 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-2 liverFunctionTest-pad'>S. BILIRUBIN TOTAL</div>
                <div className='liverFunctionTest-3'><Input type='number' className={'liverFunctionTest-input'} name="S. BILIRUBIN TOTAL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-4 liverFunctionTest-pad'>mg/dl</div>
                <div className='liverFunctionTest-5 liverFunctionTest-pad'>0.1 to 1.2</div>
                <span className='liverFunctionTest-border-1'></span>

                <div className='liverFunctionTest-6 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-7 liverFunctionTest-pad'>	S. BILIRUBIN CONJUGATED</div>
                <div className='liverFunctionTest-8'><Input type='number' className={'liverFunctionTest-input'} name="S. BILIRUBIN CONJUGATED" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-9 liverFunctionTest-pad'>mg/dl</div>
                <div className='liverFunctionTest-10 liverFunctionTest-pad'>0 to 0.3</div>
                <span className='liverFunctionTest-border-2'></span>

                <div className='liverFunctionTest-11 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-12 liverFunctionTest-pad'>S. BILIRUBIN UNCONJUGATED</div>
                <div className='liverFunctionTest-13'><Input type='number' className={'liverFunctionTest-input'} name="S. BILIRUBIN UNCONJUGATED" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-14 liverFunctionTest-pad'>mg/dl</div>
                <div className='liverFunctionTest-15 liverFunctionTest-pad'>0.1 to 1</div>
                <span className='liverFunctionTest-border-3'></span>

                <div className='liverFunctionTest-16 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-17 liverFunctionTest-pad'>SGPT</div>
                <div className='liverFunctionTest-18'><Input type='number' className={'liverFunctionTest-input'} name="SGPT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-19 liverFunctionTest-pad'>IU/L</div>
                <div className='liverFunctionTest-20 liverFunctionTest-pad'>0 to 40</div>
                <span className='liverFunctionTest-border-4'></span>

                <div className='liverFunctionTest-21 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-22 liverFunctionTest-pad'>SGOT</div>
                <div className='liverFunctionTest-23'><Input type='number' className={'liverFunctionTest-input'} name="SGOT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-24 liverFunctionTest-pad'>IU/L</div>
                <div className='liverFunctionTest-25 liverFunctionTest-pad'>0 to 40</div>
                <span className='liverFunctionTest-border-5'></span>

                <div className='liverFunctionTest-26 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-27 liverFunctionTest-pad'>S. ALKALINIE PHOSPHATASE</div>
                <div className='liverFunctionTest-28'><Input type='number' className={'liverFunctionTest-input'} name="S. ALKALINIE PHOSPHATASE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-29 liverFunctionTest-pad'>IU/L</div>
                <div className='liverFunctionTest-30 liverFunctionTest-pad'>52 to 126</div>
                <span className='liverFunctionTest-border-6'></span>

                <div className='liverFunctionTest-31 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-32 liverFunctionTest-pad'>S. TOTAL PROTEIN</div>
                <div className='liverFunctionTest-33'><Input type='number' className={'liverFunctionTest-input'} name="S. TOTAL PROTEIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-34 liverFunctionTest-pad'>gm/dl</div>
                <div className='liverFunctionTest-35 liverFunctionTest-pad'>6.4 to 7</div>
                <span className='liverFunctionTest-border-7'></span>

                <div className='liverFunctionTest-36 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-37 liverFunctionTest-pad'>S. ALBUMIN</div>
                <div className='liverFunctionTest-38'><Input type='number' className={'liverFunctionTest-input'} name="S. ALBUMIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-39 liverFunctionTest-pad'>gm/dl</div>
                <div className='liverFunctionTest-40 liverFunctionTest-pad'>3.5 to 5.2</div>
                <span className='liverFunctionTest-border-8'></span>

                <div className='liverFunctionTest-41 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-42 liverFunctionTest-pad'>S. GLOBULIN</div>
                <div className='liverFunctionTest-43'><Input type='number' className={'liverFunctionTest-input'} name="S. GLOBULIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-44 liverFunctionTest-pad'>gm/dl</div>
                <div className='liverFunctionTest-45 liverFunctionTest-pad'>2.3 to 3.5</div>
                <span className='liverFunctionTest-border-9'></span>

                <div className='liverFunctionTest-46 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-47 liverFunctionTest-pad'>ALB./GLB. RATIO</div>
                <div className='liverFunctionTest-48'><Input type='number' className={'liverFunctionTest-input'} name="ALB./GLB. RATIO" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-49 liverFunctionTest-pad'> </div>
                <div className='liverFunctionTest-50 liverFunctionTest-pad'>less then 1</div>
                <span className='liverFunctionTest-border-10'></span>

                <div className='liverFunctionTest-51 liverFunctionTest-pad'></div>
                <div className='liverFunctionTest-52 liverFunctionTest-pad'>S. GAMMA G T</div>
                <div className='liverFunctionTest-53'><Input type='number' className={'liverFunctionTest-input'} name="S. GAMMA G T" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='liverFunctionTest-54 liverFunctionTest-pad'>IU/L</div>
                <div className='liverFunctionTest-55 liverFunctionTest-pad'>5 to 40</div>
                <span className='liverFunctionTest-border-11'></span>

                
            </div>
          </div>
          <div>
              <TestReportRightSection />
          </div>
        </div>
        <div className='liverFunctionTest-foot'>
            <ReportFooter />
        </div>
        <div className='liverFunctionTest-p'>
          <h4>Note:</h4>
          <p>These test have technical limitetion. Clinical Pathological interpretation is necessary <br/> in case of any discrepancy test may be repeated immediately. This Report is not for medicolegal purpose.</p>
          
        </div>
       
    </div>
  )
}

export default LiverFunctionTest
