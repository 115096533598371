import React from "react";
import Input from "../../../../UIComponents/Input/Input";
import "./Hba.css";
import { TestReportRightSection } from "../TestReportRightSection";
import ReportFooter from "../ReportFooter";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function Hba() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'HbA1c')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'HbA1c'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
  return (
    <div>
      <div className="hba-parent">
        <div className="hba-left">
          <div className="hba-grid">
            <div className="hba-1 hba-pad">HAEMATOLOGY</div>
            <div className="hba-2 hba-pad">GLYCOCYLATED Hb (HbA1c)</div>
            <div className="hba-3">
              <Input className={"hba-input"} type='number' name="GLYCOCYLATED Hb (HbA1c)" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/>
            </div>
            <div className="hba-4 hba-pad">%</div>
            <div className="hba-5"></div>
            <div className="hba-6"></div>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="hba-foot">
        <ReportFooter />
      </div>
      <div className="hba-p">
        <h4>COMMENTS</h4>
        <p>
          Hamemoglobin A1c correlates with an average of plasma glucose values
          over the previous 3 to 10 weeks. The measure of HbA1c is therefore a
          reflection of glucose control over a far longer period than a blood
          glucose value and it remains unaffected by the short term fluctation
          in blood sugar levels.
        </p>
        <p>NOTE:- These values are for non pregnant individuals.</p>
        <p>INTERPRETATION:-</p>
        <table className="hba-table">
          <tr>
            <td>NORMAL REFERENCE VALUES</td>
            <td>GHb%</td>
            <td>HbA1c%</td>
          </tr>
          <tr>
            <td>NORMAL</td>
            <td> 8.0 %</td>
            <td> 6.0-7.0 %</td>
          </tr>
          <tr>
            <td>GOOD CONTROL</td>
            <td> 8.0 %</td>
            <td> 6.0-7.0 %</td>
          </tr>
          <tr>
            <td>FAIR CONTROL</td>
            <td> 8.0 %</td>
            <td> 6.0-7.0 %</td>
          </tr>
          <tr>
            <td>POOR CONTROL</td>
            <td> 10.0 %</td>
            <td> 8.0 %</td>
          </tr>
        </table>
      </div>
      <div className="hba-msr">
        + High risk of developing long term complications such as retinopathy,
        Nephropathy,neuropathy and cardiopathy.Action suggested depends on
        individual patient circumstances
        <br />
        ++Some danger of hypoglycemic reaction in type 1 diabetics.Some glucose
        in tolerant individuals and 'Sub-clinical' diabetics may demonstrate
        (el-evated) HbA1c in this area.
      </div>
    </div>
  );
}

export default Hba;
