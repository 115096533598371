import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

const patientHistoryLoading = () => ({
  type: types.UPLOAD_VISIT_IMAGE_VAULT_LOADING,
});

const patientHistorySuccess = (data) => ({
  type: types.UPLOAD_VISIT_IMAGE_VAULT_SUCCESS,
  payload: data,
});
const patientHistoryFail = (error) => ({
  type: types.UPLOAD_VISIT_IMAGE_VAULT_FAIL,
  payload: error,
});

const visitVaultImagesLoading = () => ({
  type: types.VIEW_VISIT_VAULT_IMAGES_LOADING,
});

const visitVaultImagesSuccess = (data) => ({
  type: types.VIEW_VISIT_VAULT_IMAGES_SUCCESS,
  payload: data,
});
const visitVaultImagesFail = (error) => ({
  type: types.VIEW_VISIT_VAULT_IMAGES_FAIL,
  payload: error,
});

const vaultImagesDeleteSuccess = (data) => ({
  type: types.VAULT_IMAGES_DELETE_SUCCESS,
  payload: data,
});

export const singleAppointmentData = (data) => ({
  type: types.GET_SINGLE_APPOINTMENT,
  payload: data,
});

export const getHistoryImages = (payload) => ({
  tyep: types.HISTORY_IMAGE_SUCCESS,
  payload,
});

export const patientHistory = (data) => {
  console.log("data sent", data);
  return function (dispatch) {
    dispatch(patientHistoryLoading());

    client
      .post(
        `/visit/upload-visit-image-vault`,
        data
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(patientHistorySuccess(resp.data));
        toast.success("Image added to visit");
      })
      .catch((error) => {
        console.log(error);
        dispatch(patientHistoryFail(error));
        if (error?.response?.data?.message)
          toast.error(`${error.response.data.message}`);
      });
  };
};

export const vaultImagesData = (visitId) => {
  console.log("data sent", visitId);
  return function (dispatch) {
    dispatch(visitVaultImagesLoading());

    client
      .get(
        `/visit/view-visit-vault-images?visitId=${visitId}`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(visitVaultImagesSuccess(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(visitVaultImagesFail(error));
      });
  };
};

export const deleteVaultImages = (data) => {
  return function (dispatch) {
    client
      .post(
        `/visit/delete-visit-image-vault`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(vaultImagesDeleteSuccess(resp.data));
        dispatch(vaultImagesData(data.visitId));
        toast.success("Image deleted");
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
