import React from 'react';
import ReactDOM from 'react-dom';
import { Toast } from './UIComponents/Toast'
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux'
import store from './redux/store'

console.log('Inside index.js process.env.NODE_ENV', process.env.NODE_ENV)

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <Toast />

      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

