import { client } from "../../../utils/axios";

export const medicinedownload = (data) => {
  console.log("api call", data);
  return function (dispatch) {
    client({
      url: `/admin/download-sampleTemplate`, //your url
      method: "POST",
      responseType: "blob",
      // withCredentials: true,
      // headers: { Authorization: `Bearer ${secure_token}` },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Sample.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.log(e));
  };
};
