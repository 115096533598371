import { Table} from 'antd';
import React,{useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import './Table.css';

// const CommonTable=({columns,data,pagination})=> {
const CommonTable=({columns,data,pagination,scroll,rows})=> {
  const myDomainInfo = useSelector(state => state.login.myDomainInfo)

//   const[selectedRowKeys,setSelectedRowKeys]=useState([])
//   const[loading,setLoading]=useState(false)
 

//  const start = () => {
//    setLoading(true)
//     setTimeout(() => {
//      setLoading(false)
//      setSelectedRowKeys([])
//     }, 1000);
//   }; `

//   const onSelectChange = selectedRowKeys => {
//     console.log('selectedRowKeys changed: ', selectedRowKeys);
//    setSelectedRowKeys(selectedRowKeys)
//   };

//     const hasSelected = selectedRowKeys.length > 0;
// const check = className.map(index => index)

useEffect(() => {
  document.documentElement.style.setProperty(`--variablename`, myDomainInfo?.DomainStyles?.buttonColor ? myDomainInfo?.DomainStyles?.buttonColor : "#00576E");
},[])

    return (
      <div>
        {/* <Table columns={columns} dataSource={data} pagination={pagination} /> */}
        <Table columns={columns} rows={rows} dataSource={data} pagination={pagination} scroll={scroll}/>
      </div>
    );
}

export default CommonTable;