import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

export const narrativesFinalData = (payload) => ({
  type: types.NARRATIVES_FINAL_DATA,
  payload,
});
export const getScheduledAppointments = (payload) => ({
  type: types.GET_SCHEDULED_APPOINTMENTS,
  payload,
});
export const updateEventsAppointment = (payload) => ({
  type: types.UPDATE_EVENTS,
  payload,
});
export const invoiceSmsLoading = () => ({
  type: types.INVOICE_SMS_LOADING,
});
export const invoiceSmsSuccess = () => ({
  type: types.INVOICE_SMS_SUCCESS,
});
export const invoiceSmsFail = () => ({
  type: types.INVOICE_SMS_FAIL,
});

const getAppointmentsLoading = () => ({
  type: types.GET_APPOINTMENTS_LOADING,
});

const getAppointmentsSuccess = (data) => ({
  type: types.GET_APPOINTMENTS_SUCCESS,
  payload: data,
});
const getAppointmentsFail = (error) => ({
  type: types.GET_APPOINTMENTS_FAIL,
  payload: error,
});
export const speech = (payload) => ({
  type: types.SPEECH,
  payload,
});
export const speechForDiagnostic = (payload) => ({
  type: types.SPEECH_FOR_DIAGNOSTIC,
  payload,
});
export const speechForVitals = (payload) => ({
  type: types.SPEECH_FOR_VITALS,
  payload,
});

// let url = baseUrl();
export const getAppointments = (data) => {
  return function (dispatch) {
    dispatch(getAppointmentsLoading());
    client
      .post(
        `/visit/get-appointments-all`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        console.log("resp.data");
        dispatch(getAppointmentsSuccess(resp.data));
        toast.success("All open appointments listed");
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(getAppointmentsFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const invoiceSms = (data) => {
  return function (dispatch) {
    dispatch(invoiceSmsLoading());
    client
      .post(
        `/visit/send-sms-invoice`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        console.log("resp.data");
        dispatch(invoiceSmsSuccess(resp.data));
        toast.success("Sms sent successfully");
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(invoiceSmsFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
