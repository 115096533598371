import * as types from '../../actionTypes';

const initialState = {
    error: {},
    loading: false
}


export const addMedicineListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_MEDICINIE_LIST_LOADING:
            return {
                ...state,
                loading: false
            }
        case types.ADD_MEDICINIE_LIST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case types.ADD_MEDICINIE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state
    }
}