import * as types from '../../actionTypes';

const initialState = {
    VaccineDetailsSave: [],
    updateVaccine:[],
    error: {},
    loading: false
}


export const VaccineSaveData = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_VACCINE_SAVE:
            return {
                ...state,
                loading: true
            }
        case types.GET_VACCINE_SAVE_SUCCESS:
            return {
                ...state,
                VaccineDetailsSave: action.payload,
                loading: false,
                VaccineDetailsSave:[],

                error: {}
            }
        case types.GET_VACCINE_SAVE_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                VaccineDetailsSave:[]
            } 
        case types.UPDATE_VACCINE_DATE:
            return {
                ...state,
                updateVaccine:action.payload,
                loading:false
            }


        default:
            return state;
    }
}