import React from 'react'
import { useSelector } from 'react-redux/es/exports'

const PrescriptionLab = () => {
  const prescriptionAnalysisInfo = useSelector(state => state.analytics.prescriptionAnalysisInfo)
  const hide = useSelector(state => state.analytics.hideData)

  return (

<div>
  { hide ? 
    <div className='pres-lab'>
      {
        prescriptionAnalysisInfo?.lab?.map(item => (
          <div className="diagnosis-lab-title">
            <div className='diagnosis-section'>
              <span className='diagnosis-lab-names'>{item.name}</span>
              <span className="pres-diagnosis-lab-num">{item.value}</span>
            </div>

          </div>

        ))
      }
    </div >
    : ""}
    </div>

  )
}

export default PrescriptionLab
