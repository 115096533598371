import { Checkbox, Form, Input, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../UIComponents/Button/Button";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../../../UIComponents/Toast";
import { updateVisit } from "../../../../redux/actions/opd/patientopdinvoice";
import "./GlassPrescription.css";

const EditableContext = React.createContext(null);

const defaultColumns = [
	{
		title: "SPH",
		dataIndex: "lsph",
		width: "12.5%",
		editable: true,
	},
	{
		title: "CYL.",
		dataIndex: "lcyl",
		width: "12.5%",
		editable: true,
	},
	{
		title: "AXIS.",
		dataIndex: "laxis",
		width: "12.5%",
		editable: true,
	},
	{
		title: "V.A.",
		dataIndex: "lva",
		width: "12.5%",
		editable: true,
	},
	{
		title: "SPH",
		dataIndex: "rsph",
		width: "12.5%",
		editable: true,
	},
	{
		title: "CYL.",
		dataIndex: "rcyl",
		width: "12.5%",
		editable: true,
	},
	{
		title: "AXIS.",
		dataIndex: "raxis",
		width: "12.5%",
		editable: true,
	},
	{
		title: "V.A.",
		dataIndex: "rva",
		width: "12.5%",
		editable: true,
	},
];
const defaultRowData = {
	key: 1,
	lsph: "",
	lcyl: "",
	laxis: "",
	lva: "",
	rsph: "",
	rcyl: "",
	raxis: "",
	rva: "",
};

const lensAdvisedList = [
	{
		name: 'Unifocal'
	},
	{
		name: 'Biofocal'
	},
	{
		name: 'Progressive'
	},
	{
		name: 'Hi-Index'
	},
	{
		name: 'Anti Roflective'
	},
	{
		name: 'Photo Cromatic'
	},
	{
		name: 'CR'
	},
]


const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};
const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);
	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		});
	};
	const save = async () => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			handleSave({
				...record,
				...values,
			});
		} catch (errInfo) {
			console.log("Save failed:", errInfo);
		}
	};
	let childNode = children;
	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{
					margin: 0,
				}}
				name={dataIndex}
			>
				<Input ref={inputRef} onPressEnter={save} onBlur={save} />
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}
	return <td {...restProps}>{childNode}</td>;
};
const GlassPrescription = ({ closeModal }) => {
	const dispatch = useDispatch();
	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};
	const columns = defaultColumns.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: record => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});

	const [dataSource, setDataSource] = useState([]);
	const [selectedLens, setSelectedLens] = useState([])


	const visitInfo = useSelector(
		state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo
	);

	useEffect(() => {
		initGlassPrescription()
	}, [])

	const handleSave = row => {
		const newData = [...dataSource];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setDataSource(newData);
	};

	const addGlassPrescription = () => {
		dispatch(updateVisit({
			...visitInfo,
			glassPrescriptions: dataSource,
			lensAdvised: selectedLens
		}))
		toast.success("Prescription Updated successfully");
	}

	const initGlassPrescription = () => {
		if (visitInfo.glassPrescriptions && visitInfo.glassPrescriptions.length) {
			setDataSource(visitInfo.glassPrescriptions)
		} else {
			setDataSource([defaultRowData])
		}
		if (visitInfo.lensAdvised && visitInfo.lensAdvised.length) {
			setSelectedLens(visitInfo.lensAdvised)
		}
	}

	const onChangeLens = (value) => {
		setSelectedLens(prevSelectedLens => {
			if (prevSelectedLens.includes(value)) {
				return prevSelectedLens.filter(item => item !== value);
			} else {
				return [...prevSelectedLens, value];
			}
		});
	}


	return (
		<div>
			<h3 className="pres-add-title">ADD GLASS PRESCRIPTION</h3>
			<Table
				components={components}
				rowClassName={() => "glass-prescription-editable-row"}
				bordered
				dataSource={dataSource}
				columns={columns}
				pagination={false}
				title={() => (
					<div className="glass-prescription-title">
						<h3>Right Eye</h3>
						<h3>Left Eye</h3>
					</div>
				)}
			/>
			<div className="glass-prescription-lens-advised">
				<h4>Lens Advised</h4>
				{lensAdvisedList.map(lens => (
					<Checkbox
						key={lens.name}
						checked={selectedLens.includes(lens.name)}
						onChange={(e) => onChangeLens(e.target.value)}
						name={lens.name}
						value={lens.name}
					>
						{console.log('inside render selectedLens', selectedLens.includes(lens.name))}
						{lens.name}
					</Checkbox>
				))}
			</div>
			<div className="glass-prescription-action-div">
				<Button onClick={addGlassPrescription}>ADD</Button>
				<Button
					className={"add-branch-modal-cancel-btn"}
					onClick={() => closeModal(false)}
				>
					CANCEL
				</Button>
			</div>
		</div>
	);
};
export default GlassPrescription;
