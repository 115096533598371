import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import { DatePicker, Modal } from "antd";
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { searchCreateAppointment } from '../../../redux/actions/appointments/addAppointment';
import { newPatientModalToggle } from '../../../redux/actions/appointments/createPatient';
import { getPatientAppointmet, singlePatientToggle } from '../../../redux/actions/appointments/getPatient';
import { searchAdvPatient } from '../../../redux/actions/appointments/patientAdvSearch';
import { searchPatient } from '../../../redux/actions/appointments/searchPatient';
import Button from '../../../UIComponents/Button/Button';
import Input from '../../../UIComponents/Input/Input';
import SingleSelect from '../../../UIComponents/Select/SingleSelect';
import { toast } from '../../../UIComponents/Toast';
import './MyCalendar.css';
import './Search.css';
import { getPatientModified } from "../../../redux/actions/appointments/getPatient";
import { resetGetPatient } from "../../../redux/actions/appointments/getPatient";
import { createToggle } from "../../../redux/actions/appointments/searchPatient";
import { resetSearchPatient } from "../../../redux/actions/appointments/searchPatient";
import { resetPatientSearchAdv } from "../../../redux/actions/appointments/patientAdvSearch";
import { copyButton } from "../../../redux/actions/appointments/getPatient";
import { Locations } from "../../../redux/actions/profile";
import CommonButton from "../../../UIComponents/Button/Button";
import { printPatientProfile } from "../../../redux/actions/appointments/printPatientProfile";

const data = [
  {
    id: "File",
    name: "File"
  },
  {
    id: "Report",
    name: "Report"
  },
  {
    id: "Address",
    name: "Address"
  },
  {
    id: "OBD",
    name: "OBD"
  }
];

const walkinData = [
  {
    id: "W",
    name: "W"
  },
  {
    id: "A",
    name: "A"
  },
  {
    id: "O",
    name: "O"
  }
];

const Search = () => {
  const doctorInfo = useSelector(state => state.profile.profileInfo)
  console.log("doctorInfo", doctorInfo);
  const clinicName = useSelector(state => state.profile.clinicLocations)
  console.log("clinicName", clinicName)
  const profileInfo = useSelector(state => state.profile.profileInfo.locations)
  const searchPatientInfo = useSelector(state => state.appointments.searchPatient.searchPatient)
  const getSinglePatientData = useSelector(state => state.appointments.getPatient.getPatient)
  const searchAdvPatientData = useSelector(state => state.appointments.searchAdvPatient.searchAdvPatient)
  const singleToggles = useSelector(state => state.appointments.getPatient.singleToggle)
  const PrintProfile = useSelector(state => state.appointments?.printPatientProfile?.printPatientProfiles)
  const newToggless = useSelector(state => state.appointments.createPatient.newPatientModalToggle)
  const loginInfo = useSelector((state) => state.login.loginInfo);
  const seletedBranch = useSelector(state => state.profile.selectedIndex)
  const create = useSelector(state => state.appointments.searchPatient.createToggleButton)
  console.log("`searchPatientInfo`", searchPatientInfo, searchAdvPatientData, create)
  const clinicId = useSelector(state => state.profile.clinicId)
  console.log("clinicId", clinicId);
  const update = useSelector(state => state.profile.updateProfile)
  console.log("update", update, PrintProfile)

  const [mobile, setMobile] = useState("")
  const [type, setType] = useState("uhid")
  const [by, setBy] = useState("")
  const [param, setParam] = useState("")
  const [note, setNote] = useState("")
  const [locationId, setLocationId] = useState(clinicName?.length === 0 ? doctorInfo?.locations?.[0]?.id : clinicName[0]?.id)
  const [patientId, setPatientId] = useState("")
  const [walkin, setWalkin] = useState(walkinData.length ? walkinData[0].id : '')
  const [scheduledTime, setScheduledTime] = useState(moment().format("YYYY-MM-DD"))
  const [timeslot, setTimeslot] = useState("")
  const [singlePatientData, setSinglePatientData] = useState('')
  const [seachDefault, setSearchDefault] = useState(true)
  const [getSingleSlot, setGetSingleSlots] = useState('')
  const [slotTimings, setSlotTimings] = useState([])
  const dispatch = useDispatch();
  //print
  const profileRef = useRef();
  //print-ends
  const doctorId = loginInfo.id;
  const doctorName = loginInfo.name
  const addAppointmentData =
  {
    "aptType": walkin,
    "patientId": patientId,
    "doctorId": doctorId,
    "locationId": locationId,
    "scheduledTime": scheduledTime,
    "timeslot": timeslot,
    "lastUpdatedBy": doctorName,
    "department": null,
    "doctorIncharge": null,
    "invoiceTemplate": null,
    "note": note
  }

  console.log("shgkdffffskhfgsh", locationId, clinicName)
  useEffect(() => {
    const getSlots = profileInfo?.filter(item => item.id === locationId)
    console.log("getSlots", getSlots)
    setGetSingleSlots(getSlots)
  }, [locationId])

  useEffect(() => {
    if (clinicName.length) {
      setLocationId(clinicName?.[0]?.id)
    }
  }, [clinicName])

  const searchOnChange = (e) => {
    setMobile(e.target.value.trim())
  }

  const OnSelectChange = (value) => {
    console.log("valuessss", value)
    setBy(value)
  }

  const onInputChange = (e) => {
    setParam(e.target.value)
  }

  const onEnterSearch = (event) => {
    if (event.key === 'Enter') {
      if (mobile.length === 0) {
        toast.error("Please Enter Name/Mobile No.")
      }
      else {
        dispatch(resetPatientSearchAdv())
        dispatch(searchPatient({ mobile, type }))
        dispatch(createToggle('search'))
        setMobile("")
      }
    }
    else return dispatch(createToggle(''))
  }

  const onEnterAdvSearch = (event) => {
    if (event.key === 'Enter') {
      if (param.length === 0) {
        toast.error("Please Enter Input")
      }
      else {
        dispatch(resetSearchPatient())
        dispatch(searchAdvPatient({ param, by }))
        dispatch(createToggle('search'))
        setParam("")
        setBy("")
      }
    }
    else return dispatch(createToggle(''))
  }

  const onWalkHandle = (value) => {
    setWalkin(value)
  }
  const onNoteChange = (e) => {
    setNote(e.target.value)
  }

  const onDateTimeHandle = (value, dateString) => {
    const scheduledTimess = moment(value).format('YYYY-MM-DD')
    console.log("hhhhh", value);
    setScheduledTime(scheduledTimess)
    setTimeslot('')
  };

  console.log(",dnfgkdjfgdfgs", scheduledTime, getSingleSlot, clinicName, doctorInfo, profileInfo)
  useEffect(() => {
    if (scheduledTime, clinicName, doctorInfo) {
      console.log("sgfsfgsgs", scheduledTime, clinicName)
      const getDay = moment(scheduledTime).format('dddd')
      console.log("values in calendar", getDay)
      if (getDay && clinicName && doctorInfo) {
        const getAllTimings = doctorInfo?.locations?.filter(item => item.id === clinicId)
        console.log("getAllTimings", getAllTimings)
        const timings = getAllTimings?.length === 0 ? doctorInfo?.locations?.length && doctorInfo?.locations?.[0]?.timings?.filter(item => item.day === getDay)[0]?.slots : getAllTimings?.[0]?.timings?.filter(item => item.day === getDay)[0]?.slots
        let slots = timings?.map(arr => {
          console.log("inside map function", `${scheduledTime} ${arr}`, timings, getAllTimings)
          const selectedTime = `${scheduledTime} ${arr}`
          return {
            name: arr,
            disabled: moment().isAfter(moment(selectedTime, 'YYYY-MM-DD hh:mm A'))
          }
        })
        console.log("timings", slots)
        setSlotTimings(slots)
      }
    }
  }, [scheduledTime, clinicName, doctorInfo])

  const handleTimeSlot = (value) => {
    setTimeslot(value)
  }

  const handleCancel = () => {
    dispatch(newPatientModalToggle(false))
    setLocationId(clinicName?.length === 0 ? doctorInfo?.locations?.[0]?.id : clinicName[0]?.id)
    setWalkin(walkinData.length ? walkinData[0].id : '')
    setScheduledTime(moment().format("YYYY-MM-DD"))
    setTimeslot("")
  };

  const onSelectHandle = (id) => {
    const singlePatientSelect = searchPatientInfo?.find(item => item.id === id)
    console.log("singlePatientSelect", singlePatientSelect)
    setSinglePatientData(singlePatientSelect)
    dispatch(newPatientModalToggle(true))
    setPatientId(singlePatientSelect.id)
  }

  const onSelectAdvHandle = (id) => {
    const singlePatientSelect = searchAdvPatientData?.find(item => item.id === id)
    console.log("singlePatientSelect", singlePatientSelect)
    setSinglePatientData(singlePatientSelect)
    dispatch(newPatientModalToggle(true))
    setPatientId(singlePatientSelect.id)
  }

  const handleOk = () => {
    if (!walkin || !scheduledTime) {
      toast.error("please enter all the input fields")
    }
    else if (!timeslot) {
      toast.error("please select time slot")
    }
    else {
      dispatch(searchCreateAppointment(addAppointmentData))
      setTimeslot("")
      setNote('')
    }
  }

  const onEditHandle = (id) => {
    console.log("idddd", id);
    dispatch(getPatientAppointmet({ patientId: id }))
    dispatch(singlePatientToggle(true))
    dispatch(getPatientModified("editProfile"))
    console.log("kekmnkmekm", singleToggles);
  }

  const onCloseHandle = () => {
    setMobile("")
    dispatch(resetSearchPatient())
    dispatch(resetPatientSearchAdv())
    dispatch(resetGetPatient());
    dispatch(getPatientModified({}))
    getPatientAppointmet({})
    dispatch(createToggle(''))
    dispatch(singlePatientToggle(false))
    dispatch(copyButton(false))
  }

  console.log("scheduled", scheduledTime, timeslot);

  const onProfileClick = (id) => {
    console.log("id on profile click", id)
    dispatch(printPatientProfile({ patientId: id }))
  }

  return (
    <div>
      <p className='search-patient-header'>Search Patient</p>
      <Input
        className="search-input"
        placeholder="Enter Name or Phone#"
        type="text"
        suffix={<SearchOutlined />}
        onChange={searchOnChange}
        onKeyPress={onEnterSearch}
        value={mobile}
      />
      {create === 'search' ? <Button onClick={onCloseHandle} className='close-icon'>Close</Button> : ""}

      <p className='adv-search'>Adv Search</p>
      <div className='search-division'>
        <SingleSelect
          data={data}
          className="search-select"
          placeholder="File"
          onChange={OnSelectChange}
          value={by}
        />
        <Input
          className="search-input1"
          placeholder="Seach term"
          type="search"
          suffix={<SearchOutlined />}
          onKeyPress={onEnterAdvSearch}
          onChange={onInputChange}
          value={param}
        />
      </div>

      {searchPatientInfo && create === 'search' ? <div>
        <p className='patient-heading'>Patient</p>
        {searchPatientInfo?.map((item, index) => (
          <div className='search-container' key={index}>
            <div className='search-patient-container'>
              <div>
                <div className='name-phone'>
                  <div >
                    <b><p>{item?.name}</p></b>
                    <p>{item?.address?.city}</p>
                    {item.profileImage ? <div>
                      <img src={item?.profileImage} className='print-table-img' alt="patient-photo" /></div> : <></>}
                  </div>
                  <div><p>Ph#{item?.mobile}</p></div></div>
              </div>
              <div className='buttons-division'>
                <div>
                  <button onClick={() => onSelectHandle(item.id)} className='button-select'>Select</button>
                  <button className='button-edit' onClick={() => onEditHandle(item.id)}>Edit</button>
                </div>
                <div onClick={() => onProfileClick(item.id)}>
                  <ReactToPrint
                    trigger={() => <CommonButton className='button-profile'>Profile</CommonButton>}
                  />
                </div>
                <div style={{ display: 'none' }}>
                  <ReactToPrint
                    trigger={() =>
                      <CommonButton className='button-profile' id="dummyClick">
                        Profile
                      </CommonButton>
                    }
                    content={() =>
                      profileRef.current
                    }
                  />
                </div>
                <div style={{ display: "none" }} className='previewReport-iframe-div'>
                  <div ref={profileRef}>
                    <div className="report-style" dangerouslySetInnerHTML={{ __html: PrintProfile }}></div>
                  </div>
                </div>
              </div>
            </div></div>
        ))} </div> : <></>
      }

      {
        searchAdvPatientData && create === 'search' ? <div>
          {searchAdvPatientData?.map(items => (
            <div className='search-container'>
              <div><p className='patient-heading'>Patient</p></div>
              <div className='search-patient-container'>
                <div>
                  <div className='name-phone'>
                    <div >
                      <b><p>{items?.name}</p></b>
                      <p>{items?.address?.city}</p>
                      {items?.profileImage ? <div>
                        <img src={items?.profileImage} className='print-table-img' alt="patient-photo" /></div> : <></>}
                    </div>
                    <div><p>Ph#{items.mobile}</p></div></div>
                </div>
                <div className='buttons-division'><div><button onClick={() => onSelectAdvHandle(items.id)} className='button-select'>Select</button><button className='button-edit' onClick={() => onEditHandle(items.id)}>Edit</button></div>
                  <div>
                    <ReactToPrint
                      trigger={() => <button className='button-profile'>Profile</button>}

                      content={() => profileRef.current}
                    />
                    <div style={{ display: "none" }}>
                      <div ref={profileRef}>
                        <div dangerouslySetInnerHTML={{ __html: PrintProfile }}></div>

                        {/* <iframe srcDoc={} className='iframe' ></iframe> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div></div>
          ))}</div> : <></>
      }
      <Modal
        title={singlePatientData.name}
        visible={newToggless}
        onCancel={handleCancel}
        width={550}
        className="appointment-modal-container"
      >
        <div className="appointment-modal">
          <div className="appointment-inp">
            <p className="calls-input-label">Select Location</p>
            <Input
              className="appointment-location-input"
              value={clinicName?.length === 0 ? doctorInfo?.locations?.[0]?.name : clinicName[0]?.name}
              placeholder="Live Title"
            />
            {/* <SingleSelect
              data={doctorInfo?.locations}
              placeholder="Select Location"
              onChange={onSelectLocationChange}
              value={locationId}
              className="appointment-location-select"
            /> */}
          </div>
          <div className="appointment-inp appointment-date-gap">
            <p className="calls-input-label">Date & Time</p>
            <div className='appoint-inp'>
              <SingleSelect
                data={walkinData}
                width={56}
                placeholder="W"
                onChange={onWalkHandle}
                value={walkin}
              />
              <DatePicker
                onChange={onDateTimeHandle}
                value={scheduledTime == "Invalid date" ? null : moment(scheduledTime)}
                className="appointment-datepicker"
                defaultValue={moment(scheduledTime)}
                placeholder="Select Date"
              />
              {console.log("slotTimings", slotTimings)}
              {slotTimings && slotTimings.length ? (
                <SingleSelect
                  data={slotTimings}
                  onChange={handleTimeSlot}
                  value={timeslot}
                  className="slot_to"
                  placeholder="Select Time"
                />
              ) : <SingleSelect
                onChange={handleTimeSlot}
                value={timeslot}
                className="slot_to"
                placeholder="Select Time"
              />}
            </div>
          </div>
          <div className="appointment-inp">
            <p>NOTE</p>
            <textarea onChange={onNoteChange} value={note} className='appoint-textarea' placeholder='Appointment Notes' rows="1" cols="50"></textarea>
          </div>
          <div className="calls-modal-btn">
            <Button
              onClick={handleOk}
              children="Save"
              className="calls-modal-btn1"
            />
          </div>
        </div>
      </Modal>
    </div >
  )
}

export default Search