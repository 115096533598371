import React, { Component } from 'react';
import './Video.css';
import Participant from './Participant';


class Room extends Component {
    constructor(props) {
        super(props);
        console.log("props", this.props)

        this.state = {
            remoteParticipants: Array.from(this.props.room.participants.values())
        }
        this.leaveRoom = this.leaveRoom.bind(this);
        this.copyConfLink = this.copyConfLink.bind(this);
    }

    componentDidMount() {
        // Add event listeners for future remote participants coming or going
        this.props.room.on('participantConnected', participant => this.addParticipant(participant));
        this.props.room.on('participantDisconnected', participant => this.removeParticipant(participant));

        console.log("this.room",this.props.room.name)
        window.addEventListener("beforeunload", this.leaveRoom);
        this.room = (this.callhead)?.substr((this.callhead).length - 4) + this.room;
        this.link = window.location.origin + "/web-chat/" + this.props.room.name +"/"+this.props.token;
    }

    async copyConfLink() {
        let context = this;
        let msg = 'Join Conf: ' + this.link + ' Report: https://bit.ly/3g3wrKV';
        await navigator.clipboard.writeText(msg)
    }

    componentWillUnmount() {
        this.leaveRoom();
    }

    addParticipant(participant) {
        console.log(`${participant.identity} has joined the room.`);

        this.setState({
            remoteParticipants: [...this.state.remoteParticipants, participant]
        });
    }

    removeParticipant(participant) {
        console.log(`${participant.identity} has left the room`);

        this.setState({
            remoteParticipants: this.state.remoteParticipants.filter(p => p.identity !== participant.identity)
        });
    }

    leaveRoom() {
        this.props.room.disconnect();
        this.props.returnToLobby();
    }

    render() {
        return (
            <div className="room">
                <div className="participants">
                    <Participant key={this.props.room.localParticipant.identity} localParticipant="true" participant={this.props.room.localParticipant} />
                    {
                        this.state.remoteParticipants.map(participant =>
                            <Participant key={participant.identity} participant={participant} />
                        )
                    }
                </div>
                <button id="leaveRooms" onClick={this.leaveRoom} className="leave-room-btn">Leave Room</button>
            </div>
        );
    }
}

export default Room;
