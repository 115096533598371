import React, { Component } from "react";
import "./NewJoin.css";
import { VideoCameraOutlined } from "@ant-design/icons";
import Room from "./Room";

const { connect } = require("twilio-video");

class NewJoin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      room: null,
      token:
        "eyJqdGkiOiJTSzZmZDhlOWJmMDZjZmQ0NTYxYTkzYmFjMDllNTQ4NTJjLTE2NjA5MTQwNzMiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJEciBKYXkgS2Fya2FyIiwidmlkZW8iOnsicm9vbSI6ImNvb2wtcm9vbSJ9fSwiaWF0IjoxNjYwOTE0MDczLCJleHAiOjE2NjA5MTU4NzMsImlzcyI6IlNLNmZkOGU5YmYwNmNmZDQ1NjFhOTNiYWMwOWU1NDg1MmMiLCJzdWIiOiJBQ2E3ZTNkNmM4ZWEzMTIzNzQzMzc5OWQwNmIyZjE4NGI0In0.xjptsvDbfjdgx9DuoA66erGWWpxY_nEpJE0ZGsr9w-k",
    };
    // this.joinRoom = this.joinRoom.bind(this);
    this.onVideoClick = this.onVideoClick.bind(this);
  }

  // let name = new URLSearchParams('roomnamw')
  // console.log('name',name)
  async onVideoClick() {
    const room = await connect(this.state.token, {
      name: "cool-room",
      audio: true,
      video: true,
    });
    this.setState({ room: room });
  }

  render() {
    return (
      <div>
        <div className="apollo-video-conference-div">
          <h1>Apollo Video Conference</h1>
        </div>
        <div className="patient-room-join">
          <div className="new-join-message">
            <p>If you consent to this call press connect</p>
          </div>
          <div className="video-icon">
            <VideoCameraOutlined
              className="video"
              onClick={this.onVideoClick}
            />
          </div>
          <div>
            {this.state.room !== null ? (
              <Room
                returnToLobby={this.returnToLobby}
                room={this.state.room}
                token={this.state.token}
              />
            ) : (
              ""
            )}
          </div>
          {/* <div>
                        <iframe width='500' height='500' src={this.state.room} allowFullScreen></iframe>
                    </div> */}
        </div>
        <div>{/* <Button onClick={this.joinRoom}>Start Call</Button> */}</div>
      </div>
    );
  }
}

export default NewJoin;
