import * as types from "../actionTypes";
import { toast } from "../../UIComponents/Toast";
import { myDomainData } from "./login";
import { client } from "../../utils/axios";

export const Locations = (payload) => ({
  type: types.CLINIC_LOCATIONS,
  payload,
});
export const getClinicId = (payload) => ({
  type: types.CLINIC_LOCATION_ID,
  payload,
});
export const editVisible = (payload) => ({
  type: types.EDIT_VISIBLE,
  payload,
});
export const updateProfileData = (payload) => ({
  type: types.UPDATE_PROFILE_DATA,
  payload,
});
export const selectedIndex = (payload) => ({
  type: types.SELECTED_INDEX,
  payload,
});

const doctorProfileThemeApplySuccess = (payload) => ({
  type: types.DOCTOR_PROFILE_THEME_APPLY,
  payload,
});

const getProfileInfoLoading = () => {
  return {
    type: types.GET_PROFILE_LOADING,
  };
};
const getProfileInfoSuccess = (profileInfo) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload: profileInfo,
});
const getProfileInfoFail = (error) => ({
  type: types.GET_PROFILE_FAIL,
  payload: error,
});

const updateProfileLoading = () => ({
  type: types.UPDATE_PROFILE_LOADING,
});
const updateProfileSuccess = (data) => ({
  type: types.UPDATE_PROFILE_SUCCESS,
  payload: data,
});
const updateProfileFail = (error) => ({
  type: types.UPDATE_PROFILE_FAIL,
  payload: error,
});

const uploadDoctorProfileSuccess = (data) => ({
  type: types.DOCTOR_PROFILE_UPLOAD_IMAGE_SUCCESS,
  payload: data,
});

export const getProfileDetails = (data, seletedBranch) => {
  return function (dispatch) {
    dispatch(getProfileInfoLoading());
    client
      .post(
        `/doctor/get-profile`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getProfileInfoSuccess(resp.data));
        console.log("dghksdjgsldhglkshd", resp.data);
        if (seletedBranch) {
          dispatch(
            Locations(
              resp.data?.locations?.filter(
                (item) => item.id === seletedBranch.id
              )
            )
          );
        }
      })
      .catch((error) => {
        dispatch(getProfileInfoFail(error.response));
        // toast.error(`${error.response.data.message}`)
      });
  };
};

export const updateProfileDetails = (data, seletedBranch) => {
  return function (dispatch) {
    dispatch(updateProfileLoading());
    client
      .post(
        `/doctor/update-profile`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(updateProfileSuccess(resp.data));
        dispatch(getProfileDetails({ doctorId: data.id }, seletedBranch));
        toast.success("Profile updated");
        // if (clinicId) {
        //     dispatch(Locations(doctorInfo?.locations?.filter(item => item.id === clinicId)))
        // }
      })
      .catch((error) => {
        dispatch(updateProfileFail(error.response));
        dispatch(getProfileDetails({ doctorId: data.id }));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const doctorProfileThemeApply = (data) => {
  return function (dispatch) {
    client
      .post(
        `/doctor/subdomain-style`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(doctorProfileThemeApplySuccess(resp.data));
        dispatch(myDomainData());
        toast.success("Style Updated");
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const uploadDoctorProfileImage = (upload) => {
  return function (dispatch) {
    client
      .post(
        `/doctor/upload-profile-image`,
        upload
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(uploadDoctorProfileSuccess(resp.data));
        dispatch(myDomainData());
        toast.success(`${resp.data.message}`);
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };
};
