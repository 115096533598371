import React, { useEffect, useState } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../Narratives/Narratives.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import { EditorState, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg"
import "draft-js/dist/Draft.css";
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice';
import { useDispatch, useSelector } from 'react-redux';
import { narrativesFinalData } from '../../../../redux/actions/appointments/appointments';
function Narratives() {
    const dispatch = useDispatch()
    // const [sample,setSample] = useState(setEditorState(EditorState.createWithContent(ContentState.createFromText(`${visitInfo?.narratives}`))))
    const [narratives, setNarratives] = useState(EditorState.createEmpty())
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    const afterCommand = useSelector(state => state?.appointments?.getAppointment?.speechs)
    console.log("afterCommand", afterCommand);

    const onEditorStateChange = (editorState) => {
        console.log('onEditorStateChange function', editorState.getCurrentContent().getPlainText(''))
        let text = editorState.getCurrentContent().getPlainText()
        dispatch(updateVisit({ ...visitInfo, variableForNarratives: text }))
        dispatch(narrativesFinalData(text))
        setNarratives(editorState)
    }


    useEffect(() => {
        console.log('Insideuseeffectnarratives', visitInfo?.narratives)
        if (visitInfo?.narratives) {
            setNarratives(EditorState.createWithContent(ContentState.createFromText(`${visitInfo?.narratives}`)))
        } else {
            setNarratives(EditorState.createWithContent(ContentState.createFromText('')))
        }
    }, [visitInfo?._id,visitInfo?.narratives])

    return (
        <div>
            <div className='narratives-div'>
                <NumberButton className="numberButton-5">5</NumberButton>
                <div className='narratives-5-div'>
                    <h6>NARRATIVES</h6>
                </div>
                <div className="speacial-notes-editor">
                    <Editor
                        editorState={narratives}
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Enter text"
                    />
                </div>
            </div>
            <div>
                <Divider />
            </div>
        </div>
    )
}

export default Narratives