import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { getAppointments } from "./appointments";
import { client } from "../../../utils/axios";

const updateAppointmentLoading = () => ({
  type: types.UPDATE_APPOINTMENT_LOADING,
});

const updateAppointmentSuccess = (data) => ({
  type: types.UPDATE_APPOINTMENT_SUCCESS,
  payload: data,
});
const updateAppointmentFail = (error) => ({
  type: types.UPDATE_APPOINTMENT_FAIL,
  payload: error,
});


export const updateAppointments = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(updateAppointmentLoading());
    client
      .post(
        `/visit/update-appointment`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(updateAppointmentSuccess(resp.data));
        dispatch(getAppointments({ scheduledTime: data.scheduledTime }));
        toast.success("Appointment resheduled");
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(updateAppointmentFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
