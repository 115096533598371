import * as types from '../../actionTypes';

const initialState = {
    doctorInvoiceSummary: [],
    error: {},
    graphToggle: null,
    loading: false,
    exportToggleData:false,
    monthlysplit:[],
    dailyCollectionInfo: [],
    clearDates:"",
    error: {},
    loading: false,
    invoiceDetailsInfo: [],
    updateInvoice : [],
    error: {},
    loading: false,
    patientStatsInfo: [],
    hideData: false,
    clearData: {
        startDate: null,
        endDate: null
    },
    clearDropDowns: {
        mode: '',
        location: '',
        doctors: '',
        exportFile:'',
        consultant:'',
    },
    error: {},
    loading: false,
    prescriptionAnalysisInfo: [],
    error: {},
    loading: false
}

export const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DOCTOR_INVOICE_SUMMARY_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_DOCTOR_INVOICE_SUMMARY_SUCCESS:
            return {
                ...state,
                doctorInvoiceSummary: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_DOCTOR_INVOICE_SUMMARY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
            case types.BAR_GRAPH_TOGGLE:
            return {
                ...state,
                graphToggle: action.payload,
                loading: false,
            }
            case types.EXPORT_TOGGLE:
                return {
                    ...state,
                    exportToggleData: action.payload,
                    loading: false,
                }
                case types.INVOICE_DETAILS_LOADING:
                    return {
                        ...state,
                        loading: true
                    }
                case types.INVOICE_DETAILS_SUCCESS:
                    return {
                        ...state,
                        invoiceDetailsInfo: action.payload,
                        loading: false,
                        error: {}
                    }
                case types.INVOICE_DETAILS_FAIL:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                    }
                case types.UPDATE_INVOICE_DETAILS:
                    return{
                        ...state,
                        updateInvoice:action.payload,
                        loading:false
                    }
                    case types.DAILY_COLLECTION_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.DAILY_COLLECTION_SUCCESS:
            return {
                ...state,
                dailyCollectionInfo: action.payload,
                loading: false,
                error: {}
            }
        case types.DAILY_COLLECTION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
            case types.CLEAR_DATES_TABCLICK:
                return {
                    ...state,
                    clearDates: action.payload,
                    loading: false,
                }
                case types.PATIENT_STATS_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.PATIENT_STATS_SUCCESS:
            return {
                ...state,
                patientStatsInfo: action.payload,
                loading: false,
                error: {}
            }
        case types.PATIENT_STATS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case types.HIDE_DATAON_TABCLICK:
            return {
                ...state,
                hideData: action.payload,
                loading: false,
            }
        case types.CLEAR_DATAON_TABCLICK:
            return {
                ...state,
                clearData: action.payload,
                loading: false,
            }
        case types.CLEAR_DROPDOWN:
            return {
                ...state,
                clearDropDowns: action.payload,
                loading: false,
            }
            case types.PRESCRIPTION_ANALYSIS_LOADING:
                return {
                    ...state,
                    loading: true
                }
            case types.PRESCRIPTION_ANALYSIS_SUCCESS:
                return {
                    ...state,
                    prescriptionAnalysisInfo: action.payload,
                    loading: false,
                    error: {}
                }
            case types.PRESCRIPTION_ANALYSIS_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }

        default:
            return state
    }
}