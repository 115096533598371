import * as types from '../../actionTypes';

const initialState = {
    patientSubmitInfo: [],
    error: {},
    loading: false
}


export const patientSubmitReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PATIENT_SUBMIT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.PATIENT_SUBMIT_SUCCESS:
            console.log("action.payload",action.payload)
            return {
                ...state,
                patientSubmitInfo: action.payload,
                loading: false,
                error: {}
            }
        case types.PATIENT_SUBMIT_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
            } 


        default:
            return state;
    }
}