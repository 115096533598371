import { DatePicker, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import uuid from "react-uuid";
import deleteIcon from "../../../images/Delete-red.svg";
import {
  createDiagnostics,
  resetAddedData,
} from "../../../redux/actions/Diagnostics/diagnostics";
import { saveDiagnostics } from "../../../redux/actions/Diagnostics/saveDiagnostic";
import Button from "../../../UIComponents/Button/Button";
import Input from "../../../UIComponents/Input/Input";
import Select from "../../../UIComponents/Select/SingleSelect";
import { toast } from "../../../UIComponents/Toast";
import "./BookEdit.css";

const collectedPlace = [
  {
    id: "Lab",
    name: "Lab",
  },
  {
    id: "Hospital",
    name: "Hospital",
  },
  {
    id: "Home",
    name: "Home",
  },
  {
    id: "Clinic",
    name: "Clinic",
  },
  {
    id: "Office",
    name: "Office",
  },
  {
    id: "Other",
    name: "Other",
  },
];
const hospital = [
  {
    id: 1,
    name: "Doctor",
  },
  {
    id: 2,
    name: "Doctor one",
  },
  {
    id: 3,
    name: "Doctor two",
  },
];

const paymentType = [
  {
    id: "Cash",
    name: "Cash",
  },
  {
    id: "Credit Card",
    name: "Credit Card",
  },
  {
    id: "Debit Card",
    name: "Debit Card",
  },
  {
    id: "Net Banking",
    name: "Net Banking",
  },

  {
    id: "Cheque",
    name: "Cheque",
  },
  {
    id: "Mobile Wallet",
    name: "Mobile Wallet",
  },
  {
    id: "Reciept",
    name: "Reciept",
  },
];

const SearchBookEdit = (props) => {
  let dispatch = useDispatch();
  const newTransaction = uuid();
  const format = "YYYY-MM-DD h:mm A";
  const dateFormat = "YYYY-MM-DD hh:mm:s A";

  const getDiagnosticData = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.getdiagnostic
  );
  console.log("getDiagnosticData", getDiagnosticData);
  const getSinglePatient = useSelector(
    (state) => state?.getPatient?.getSinglePatient
  );
  console.log("getSinglePatientkui", getSinglePatient);
  const addedData = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.addedData
  );
  console.log("addedData", addedData);
  const profileInfo = useSelector((state) => state?.profile?.profileInfo);
  const getFiltererdTests = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.getDiagnosticsProfile?.rates
  );
  const resetData = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.resetAdded
  );
  console.log("resetData", resetData);
  const handleSelect = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.select
  );
  console.log("handleSelect", handleSelect);

  const [disc, setDisc] = useState();
  const [gross, setGross] = useState();
  const [totalValue, setTotalValue] = useState();
  const [discountValue, setDiscountValue] = useState();
  const [test, setTest] = useState();
  const [group, setGroup] = useState();
  const [net, setNet] = useState();
  const [advanceDisc, setAdvanceDisc] = useState();
  const [types, setTypes] = useState();
  const [typeArray, setTypeArray] = useState();
  const [groupArray, setGroupArray] = useState();
  const [addButton, setAddButton] = useState(false);
  const [finalDisc, setFinalDisc] = useState();
  const [finalNet, setFinalNet] = useState();
  const [dateOne, setDateOne] = useState();
  const [dateTwo, setDateTwo] = useState();
  const [dateThree, setDateThree] = useState();
  const [dateFour, setDateFour] = useState();
  const [advDiscPer, setAdvDiscPer] = useState();
  const [typeData, setTypeData] = useState();
  const [sumnet, setSumNet] = useState("");
  const [sumgross, setSumGross] = useState("");
  const [sumdisc, setSumDisc] = useState("");

  useEffect(() => {
    if (Object.keys(addedData)) {
      const totalnet = addedData?.invoice?.map((item) => Number(item?.cost));
      const net = totalnet?.reduce(
        (partialSum, a) => Number(partialSum + a),
        0
      );
      const totalgross = addedData?.invoice?.map((item) => Number(item?.gross));
      const gross = totalgross?.reduce((partialSum, a) => partialSum + a, 0);
      const totaldisc = addedData?.invoice?.map((item) =>
        Number(item?.discAmt)
      );
      const disc = totaldisc?.reduce((partialSum, a) => partialSum + a, 0);
      setSumNet(net);
      setSumGross(gross);
      setSumDisc(disc);
    }
  }, [addedData]);

  const transactionId = getDiagnosticData?.transactionId;
  const doctorId = profileInfo?.id;

  // const totalnet = addedData?.invoice?.map((item) => Number(item?.cost));
  // const sumnet = totalnet?.reduce((partialSum, a) => partialSum + a, 0);

  // const totalgross = addedData?.invoice?.map((item) => Number(item?.gross));
  // const sumgross = totalgross?.reduce((partialSum, a) => partialSum + a, 0);

  // const totaldisc = addedData?.invoice?.map((item) => Number(item?.discAmt));
  // const sumdisc = totaldisc?.reduce((partialSum, a) => partialSum + a, 0);

  const testingarray = typeArray?.map((item) => item.test);
  const grouparray = groupArray?.map((item) => item.group);

  const groupArrays = getFiltererdTests?.rates?.filter(
    (item) => item.test === test
  );
  const discValue = Number(disc).toFixed(2);
  const grossValue = Number(gross).toFixed(2);
  const costValue = Number(totalValue).toFixed(2);
  const discAmtValue = Number(discountValue).toFixed(2);

  const getUniqueNo = (uni, docId) => {
    const doctorKeys = Object?.keys(uni);
    console.log("doctorKeys", doctorKeys);
    const hasDocId = doctorKeys?.includes(docId);
    console.log("hasDocId", hasDocId);
    if (hasDocId) return uni[docId];
    else if (typeof uni === "number") return uni;
    else return "";
  };

  console.log("addeddata in addservices", addedData, getSinglePatient);
  useEffect(() => {
    if (getSinglePatient) {
      const { uniqueno, ...rest } = getSinglePatient;
      console.log("uniqueno,...rest", getSinglePatient?.uniqueno, rest);
      const uniqueNo = getUniqueNo(getSinglePatient?.uniqueno, doctorId);
      const option = { ...rest, uniqueno: uniqueNo };
      console.log("option", option);
      dispatch(
        createDiagnostics({
          ...addedData,
          patient: option,
          doctorId: doctorId,
          active: true,
          invoiceNumber: 0,
          transactionId: newTransaction,
          invoice: addedData?.invoice ? [...addedData?.invoice] : [],
          testReport: [],
        })
      );
    } else return "";
  }, [getDiagnosticData, getSinglePatient, transactionId]);

  useEffect(() => {
    let typeArrray = getFiltererdTests?.rates?.map((item) => item?.type);
    let singleType = [...new Set(typeArrray)];
    setTypeData(singleType);
    const testArray = getFiltererdTests?.rates?.filter(
      (item) => item?.type === types
    );
    setTypeArray(testArray);
    const groupArray = getFiltererdTests?.rates?.filter(
      (item) => item?.test === test
    );
    setGroupArray(groupArray);
  }, [types, test]);

  useEffect(() => {
    if (handleSelect === "selected") {
      setDateOne("");
      setDateTwo("");
      setDateThree("");
      setDateFour("");
      setDisc(0);
      setGross(0);
      setDiscountValue(0);
      setTotalValue(0);
      setAdvanceDisc("");
      setTypes("");
      setGroup("");
      setTest("");
      setAdvDiscPer("");
      setFinalDisc("");
      setFinalNet("");
    }
  }, [handleSelect]);

  useEffect(() => {
    if (sumnet) {
      dispatch(
        createDiagnostics({
          ...addedData,
          invoiceSummary: {
            ...addedData?.invoiceSummary,
            grossTotal: sumgross,
            discountTotal: finalDisc ? finalDisc : sumdisc,
            netTotal: finalNet ? finalNet : sumnet,
          },
        })
      );
    }
  }, [sumnet, finalDisc]);

  const discHandler = (e) => {
    if (e.target.value <= 100) {
      setDisc(e.target.value);
      var value1 = Number(e.target.value) / 100;
      var value2 = Number(gross);
      var value3 = value2 * value1;
      setDiscountValue(value3.toFixed(2));
      setTotalValue(Number(value2 - (value2 * value1).toFixed(2)));
    }
  };
  const grossHandler = (e) => {
    setGross(e.target.value);
    var numVal1 = Number(e.target.value);
    var numVal2 = Number(disc) / 100;
    var num3 = numVal2 * numVal1;
    console.log("num3", num3);
    setDiscountValue(num3.toFixed(2));
    setTotalValue(Number(numVal1 - (numVal1 * numVal2).toFixed(2)));
  };
  const discountHandler = (e) => {
    setDiscountValue(e.target.value);
  };
  const serviceTypeHandler = (value) => {
    setTest(value);
    setGross(Number(groupArrays[0]?.rate || 0));
    setTotalValue(Number(groupArrays[0]?.rate || 0));
    dispatch(createDiagnostics({ ...addedData, originalTests: null }));
  };
  console.log("iejeijiej", gross);
  const groupTypeHandler = (value) => {
    setGroup(value);
  };
  const totalNet = finalDisc ? finalDisc : sumdisc;
  const totalDisc = finalNet ? finalNet : sumnet;

  const onTypeHandler = (value) => {
    setTypes(value);
    dispatch(
      createDiagnostics({
        ...addedData,
        invoiceSummary: {
          ...addedData?.invoiceSummary,
          grossTotal: sumgross,
          discountTotal: totalDisc,
          netTotal: totalNet,
        },
        invoice: addedData?.invoice ? [...addedData?.invoice] : [],
      })
    );
  };
  const onNetHandler = (e) => {
    setTotalValue(e.target.value);
  };

  const onDiscSubmit = (e) => {
    if (e.key === "Enter") {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3.toFixed(1));
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(1));
    }
  };
  const onAdvanceDiscHandle = (e) => {
    setAdvDiscPer(e.target.value);
    var n1 = Number(e.target.value) / 100;
    var n2 = Number(sumnet);
    var n3 = n1 * n2;
    setAdvanceDisc(n3.toFixed(2));
    const advDisc = (sumdisc + n3).toFixed(2);
    setFinalDisc(Number(advDisc));
    const net = (sumnet - n3).toFixed(2).toString();
    setFinalNet(Number(net));
  };

  const addServices = () => {
    console.log("button clicked add");
    if (!types) {
      toast.error("Please select type");
    } else if (!test) {
      toast.error("Please select test");
    } else if (!gross) {
      toast.error("Please enter gross");
    } else {
      console.log("inside addservices else");
      console.log("addeddata in addservices", addedData);
      setAddButton(true);
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3.toFixed(2));
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(1));
      dispatch(
        createDiagnostics({
          ...addedData,
          invoice: [
            ...addedData?.invoice,
            {
              test: test,
              group: group,
              disc: discValue,
              gross: grossValue,
              discAmt: discAmtValue,
              cost: costValue,
            },
          ],
        })
      );
      setAdvDiscPer("");
      setAdvanceDisc("");
      setFinalNet("");
      setFinalDisc("");
      setTest("");
      setDisc("");
      setGross("");
      setDiscountValue("");
      setTypes("");
      setGroup("");
      setTotalValue("");
    }
  };

  const deleteServiceData = (index) => {
    dispatch(
      createDiagnostics({
        ...addedData,
        invoice: [...addedData?.invoice].filter((item, i) => i !== index),
      })
    );
    setAdvDiscPer("");
    setAdvanceDisc("");
    setFinalNet("");
    setFinalDisc("");
  };

  const onTransitionChange = (dateString) => {
    setDateOne(dateString);
    const transDate = moment(dateString).format();
    const date = moment(dateString).format("YYYY-MM-DD");
    dispatch(
      createDiagnostics({
        ...addedData,
        transactionDate: transDate,
        createdDate: date,
        createdTime: transDate,
      })
    );
  };

  const onDateTimeHandle = (dateString) => {
    setDateTwo(dateString);
    const collectDate = moment(dateString).format();
    dispatch(
      createDiagnostics({
        ...addedData,
        reportHead: {
          ...addedData?.reportHead,
          samplecollecteddate: collectDate,
        },
      })
    );
  };
  const onReleasedDateHandle = (dateString) => {
    setDateThree(dateString);
    const recieveDate = moment(dateString).format();
    dispatch(
      createDiagnostics({
        ...addedData,
        reportHead: {
          ...addedData?.reportHead,
          samplereceiveddate: recieveDate,
        },
      })
    );
  };
  const onReportReleasedDateHandle = (dateString) => {
    setDateFour(dateString);
    const releaseDate = moment(dateString).format();
    dispatch(
      createDiagnostics({
        ...addedData,
        reportHead: {
          ...addedData?.reportHead,
          reportreleasedate: releaseDate,
        },
      })
    );
  };
  const onPlaceHandle = (value) => {
    dispatch(
      createDiagnostics({
        ...addedData,
        reportHead: { ...addedData?.reportHead, samplecollectedat: value },
      })
    );
  };
  const doctorReferalHandler = (value) => {
    dispatch(
      createDiagnostics({
        ...addedData,
        invoiceSummary: { ...addedData?.invoiceSummary, referral: value },
      })
    );
  };
  const hospitalReferalHandle = (value) => {
    dispatch(
      createDiagnostics({
        ...addedData,
        invoiceSummary: {
          ...addedData?.invoiceSummary,
          hospitalReferral: value,
        },
      })
    );
  };
  const crNoHandle = (e) => {
    dispatch(
      createDiagnostics({
        ...addedData,
        invoiceSummary: { ...addedData?.invoiceSummary, crno: e.target.value },
      })
    );
  };
  const onPaymentHandle = (value) => {
    dispatch(
      createDiagnostics({
        ...addedData,
        invoiceSummary: { ...addedData?.invoiceSummary, paymentMode: value },
      })
    );
  };
  const onCashHandle = (e) => {
    dispatch(
      createDiagnostics({
        ...addedData,
        invoiceSummary: {
          ...addedData?.invoiceSummary,
          payed: Number(e.target.value),
        },
      })
    );
  };
  window.onclick = (e) => {
    var numVal1 = Number(gross);
    var numVal2 = Number(disc) / 100;
    var num3 = numVal2 * numVal1;
    console.log("num3", num3);
    setDiscountValue(num3);
    setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(1));
  };
  const onKeyDown = (e) => {
    console.log("fhdfhuds", e);
    if (e.key === "Tab") {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3);
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(1));
    }
  };

  console.log("addedData", addedData);
  const diagnosticsSave = () => {
    dispatch(resetAddedData(""));
    console.log(
      "addedData?.invoice?.length === ",
      addedData?.invoice?.length === 0
    );
    // if (
    //   !addedData?.transactionDate &&
    //   !addedData?.reportHead?.samplecollecteddate &&
    //   !addedData?.reportHead?.samplereceiveddate &&
    //   !addedData?.reportHead?.reportreleasedate &&
    //   !addedData?.reportHead?.samplecollectedat &&
    //   !addedData?.invoiceSummary?.crno
    // ) {
    //   toast.error("Please add all details");
    // }
    if (!addedData?.transactionDate) {
      toast.error("Please add transactionDate");
    } else if (!addedData?.reportHead?.samplecollecteddate) {
      toast.error("Please add sample collected date");
    } else if (!addedData?.reportHead?.samplereceiveddate) {
      toast.error("Please add sample received date");
    } else if (!addedData?.reportHead?.reportreleasedate) {
      toast.error("Please add sample release date");
    } else if (!addedData?.reportHead?.samplecollectedat) {
      toast.error("Please add sample collected place");
    } else if (addedData?.invoice?.length === 0) {
      if (!disc) {
        toast.error("Please add disc%");
      } else if (!gross) {
        toast.error("Please add gross");
      } else if (!addButton) {
        toast.error("Please add all details");
      }
    }
    // if(!addedData.invoiceSummary. referral){
    //   toast.error("Please enter all the input fields")
    // }
    // if(!addedData.invoiceSummary.hospitalReferral){
    //   toast.error("Please enter all the input fields")
    // }
    else {
      dispatch(saveDiagnostics(addedData));
      dispatch(createDiagnostics({}));
      props.handleCancel();
      setDateOne("");
      setDateTwo("");
      setDateThree("");
      setDateFour("");
    }
  };
  // console.log("nfuijr4",addedData.reportHead);
  return (
    <div>
      <div className="book-edit-container">
        <div className="book-edit-left-section">
          <div className="left-section-inputs">
            <p>Transaction Date</p>
            <div>
              <Space direction="vertical" size={12}>
                <DatePicker
                  showTime={{
                    format: "h:mm a",
                  }}
                  format={"DD-MM-YYYY h:mm A"}
                  value={dateOne ? moment(dateOne, "DD-MM-YYYY h:mm A") : ""}
                  onChange={onTransitionChange}
                  use12Hours
                />
              </Space>
            </div>
          </div>
          <div className="left-section-inputs">
            <p>Sample Collected On (SCT)</p>
            <Space direction="vertical" size={12}>
              <DatePicker
                showTime={{
                  format: "h:mm a",
                }}
                format={"DD-MM-YYYY h:mm A"}
                value={dateTwo ? moment(dateTwo, "DD-MM-YYYY h:mm A") : ""}
                onChange={onDateTimeHandle}
                use12Hours
              />
            </Space>
          </div>

          <div className="left-section-inputs">
            <p>Sample Collected Place</p>
            <p></p>
            <Select
              className={"left-inputs"}
              data={collectedPlace}
              onChange={onPlaceHandle}
              value={
                addedData?.reportHead?.samplecollectedat
                  ? addedData?.reportHead?.samplecollectedat
                  : ""
              }
            ></Select>
          </div>
          <div className="left-section-inputs">
            <p>Sample Recieved On (SRT)</p>
            <Space direction="vertical" size={12}>
              <DatePicker
                showTime={{
                  format: "h:mm a",
                }}
                value={dateThree ? moment(dateThree, "DD-MM-YYYY h:mm A") : ""}
                onChange={onReleasedDateHandle}
                use12Hours
                format={"DD-MM-YYYY h:mm A"}
              />
            </Space>
          </div>
          <div className="left-section-inputs">
            <p>Report Released On (RRT)</p>
            <Space direction="vertical" size={30}>
              <DatePicker
                showTime={{
                  format: "h:mm a",
                }}
                onChange={onReportReleasedDateHandle}
                value={dateFour ? moment(dateFour, "DD-MM-YYYY h:mm A") : ""}
                use12Hours
                format={"DD-MM-YYYY h:mm A"}
              />
            </Space>
          </div>
          <div className="left-section-inputs">
            <p>Doctor Referral</p>
            <Select
              className={"left-inputs"}
              placeholder="Doctor"
              // data={doctor}
              onchange={doctorReferalHandler}
            ></Select>
          </div>
          <div className="left-section-inputs">
            <p>Hospital Referral</p>
            <Select
              className={"left-inputs"}
              placeholder="Hospital"
              // data={hospital}
              onchange={hospitalReferalHandle}
            ></Select>
          </div>
          <div className="left-section-inputs">
            <p>CR #</p>
            <Input
              className={"left-inputs"}
              onChange={crNoHandle}
              value={
                addedData?.invoiceSummary?.crno
                  ? addedData?.invoiceSummary?.crno
                  : ""
              }
            />
          </div>
        </div>

        <div className="book-edit-right-section">
          <h3>Services</h3>
          <div className="type-test-group-container">
            <div className="type-div">
              <p>Type</p>
              <Select
                value={types}
                data={typeData}
                onChange={onTypeHandler}
              ></Select>
            </div>
            <div className="test-div">
              <p>Test</p>
              <Select
                data={testingarray}
                value={test}
                onChange={serviceTypeHandler}
              ></Select>
            </div>
            <div className="group-div">
              <p>Group</p>
              <Select
                data={grouparray}
                value={grouparray}
                onChange={groupTypeHandler}
              ></Select>
            </div>
          </div>

          <div className="disc-rate-discount-net-container">
            <div className="inputs-sec">
              <div className="disc-content">
                <p>Disc %</p>
                <Input
                  type="number"
                  value={isNaN(disc) ? "0" : disc}
                  onChange={discHandler}
                />
              </div>
              <div className="disc-content">
                <p>Rate</p>
                <Input
                  type="number"
                  value={isNaN(gross) ? "" : gross}
                  onChange={grossHandler}
                  onKeyPress={onDiscSubmit}
                  onKeyDown={onKeyDown}
                />
              </div>
              <div className="disc-content">
                <p>Discount</p>
                <Input
                  disabled={true}
                  value={isNaN(discountValue) ? "" : discountValue}
                  onChange={discountHandler}
                />
              </div>
              <div className="disc-content">
                <p>Net</p>
                <Input
                  disabled={true}
                  type="number"
                  value={isNaN(totalValue) ? "" : totalValue}
                  onChange={onNetHandler}
                />
              </div>
            </div>
            <div className="button-sec">
              <Button
                children="ADD"
                className="book-edit-add-button"
                onClick={addServices}
              />
            </div>
          </div>

          <div>
            <table className="book-edit-services-table">
              <tr className="book-edit-services-head-row">
                <th className="book-edit-services-head">Services</th>
                <th>%</th>
                <th>Rate</th>
                <th>Disc</th>
                <th>Net</th>
              </tr>

              {addedData?.invoice?.map((item1, i) => (
                // < div >
                <tr className="book-edit-services-data-row">
                  <td>
                    {item1?.test} <br></br> {item1?.group}
                  </td>
                  <td>
                    <Input value={item1?.disc} />
                  </td>
                  <td>
                    <Input value={item1?.gross} />
                  </td>
                  <td>
                    <Input value={item1?.discAmt} />
                  </td>
                  <td>
                    <Input value={item1?.cost} />
                  </td>
                  <td>
                    <img
                      className="book-edit-table-delete"
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => deleteServiceData(i)}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div className="disc-rate-net-container">
            <div className="overall-disc-content">
              <p>Discount %</p>
              <Input
                type="number"
                value={advDiscPer}
                onChange={onAdvanceDiscHandle}
              />
            </div>

            <div className="overall-disc-content">
              <p className="book-edit-font-size">Discount Amount</p>
              <Input type="number" disabled={true} value={advanceDisc} />
            </div>
            <div className="net-gross-disc-containter">
              {finalNet ? (
                <p>Net - {finalNet ? finalNet : "0"}</p>
              ) : (
                <p>Net - {sumnet ? sumnet.toFixed(2) : "0"}</p>
              )}
              <span>|</span>{" "}
              <p>Gross - {sumgross ? sumgross.toFixed(2) : "0"} </p>
              <span>|</span>{" "}
              {finalDisc ? (
                <p>Disc - {finalDisc ? finalDisc : "0"} </p>
              ) : (
                <p>Disc - {sumdisc ? sumdisc.toFixed(2) : "0"} </p>
              )}
            </div>
          </div>

          <div className="book-edit-cash-type-container">
            <p>Type</p>
            <div className="book-edit-cash-input">
              <Select
                className={"cash-input"}
                data={paymentType}
                onChange={onPaymentHandle}
                value={
                  addedData?.invoiceSummary?.paymentMode
                    ? addedData?.invoiceSummary?.paymentMode
                    : ""
                }
              ></Select>
              <div className="book-edit-cash-grey-input">
                <Input
                  onChange={onCashHandle}
                  value={
                    addedData?.invoiceSummary?.payed
                      ? addedData?.invoiceSummary?.payed
                      : ""
                  }
                />
              </div>
            </div>
            <div className="book-edit-button-container">
              <Button
                onClick={diagnosticsSave}
                className="book-edit-save-button"
              >
                SAVE & UPDATE
              </Button>
              <Button
                onClick={props.handleCancel}
                className="book-edit-cancel-button"
              >
                CANCEL
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBookEdit;
