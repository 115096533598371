import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import './LH.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


function Lh() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'LH')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'LH'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
        <div className='lh-parent'>
          <div className='lh-left'>
            <div className='lh-grid'>
                <div className='lh-1'></div>
                <div className='lh-2 lh-pad'>Luteinising Hormone (LH)</div>
                <div className='lh-3'><Input type='number' className={'lh-input'} name="Luteinising Hormone (LH)" onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                <div className='lh-4 lh-pad'>mlU/ml</div>
                <div className='lh-5'>109-2000 <br/> M 202-109 </div>
                <div className='lh-6'></div>
                <span className='lh-line'></span>
            </div>
          </div>
          <div>
              <TestReportRightSection />
          </div>
        </div>
        <div className='lh-foot'>
            <ReportFooter />
        </div>
        <div className='lh-p'>
          <h4>Normal Range:</h4>
          <p>Males <span>1.7-11.2 mlU/ml</span></p>
          <p>Ovulating females <span>1.7-13.3 mlU/ml</span></p>
          <p>Mid-Cycle <span>4.1-68.7 mlU/ml </span></p>
          <p>Luetal Phase  <span>0.5-19.8 mlU/ml</span></p>
          <p>Postmenopaulsal females  <span>14.4-62.2 mlU/ml</span></p>
        </div>
        <div className='lh-msr'>
          Measured using Optical Fluorescence Method 
        </div>
    </div>
  )
}

export default Lh
