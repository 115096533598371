import React, { useState, useEffect } from "react";
import "./MasterList.css";
import plusIcon from "../../images/plus-icon.svg";
import Table from "../../UIComponents/Table/Table";
import Button from "../../UIComponents/Button/Button";
import deleteBackIcon from "../../images/Delete-red.svg";
import Input from "../../UIComponents/Input/Input";
import { SearchOutlined } from "@ant-design/icons";
import UpdateImage from "../../images/Update-button.svg";
import { useDispatch, useSelector } from "react-redux";
import { loadMasterList } from "../../redux/actions/doctorEditMaster/masterList";
import { loadMasterListValue } from "../../redux/actions/doctorEditMaster/masterListValue";
import { saveMasterListValue } from "../../redux/actions/doctorEditMaster/saveMasterList";
import AddList from "./AddList";
import { toast } from "../../UIComponents/Toast";
import InputField from "../../UIComponents/Input/Input";
import { Modal } from 'antd';
import { editMasterListValue } from "../../redux/actions/doctorEditMaster/masterList";
import deleteIcon from '../../images/Delete-red.svg';
import editIcon from '../../images/Edit-pencil.svg';


const MasterList = () => {
  let dispatch = useDispatch()
  const editMasterList = useSelector(state => state.editMaster.editMasterInfo.editMasterList)
  console.log("editMasterList", editMasterList)

  const listValue = useSelector(state => state.editMaster.masterListValueInfo.listValue)
  console.log("listValuelistValue", listValue)
  const [medType, setMedType] = useState('')
  const [medIndex, setMedIndex] = useState('')
  const [generic, setGeneric] = useState('')
  const [singleMasterListArray, setSingleMasterListArray] = useState()
  const [editModal, setEditModal] = useState(false)
  const [getListValue, setGetListValue] = useState('')
  const [foundIndex, setFoundIndex] = useState('')

  console.log("singleMasterListArrayssss", singleMasterListArray, medIndex)

  useEffect(() => {
    dispatch(loadMasterList())
  }, [])

  const masterListHandler = (type, index) => {
    console.log("dfghj", type, index)
    setMedType(type)
    setMedIndex(index)
    dispatch(loadMasterListValue({ type: type }))
  }

  const genericInputHandler = (e) => {
    setGeneric(e.target.value.replace(/[^\w\s]/gi, ""))
  }

  // const added = {
  //   type: medType,
  //   list: { ...listValue, list:[...listValue.list,generic] }
  // }

  // ['Pulse (bpm)', 'BP Sitting (mmHg)', 'BP Standing (mmHg)', 'Temp (F)', 'SpO2 (%)', 'JVP', 'Edema', 'Anemia', 'Cyanosis', 'Jaundice', 'Wt. (Kg)', 'Ht. (m)', 'Waist (cm)', 'Hip (cm)', 'BP Lying (mmHg)', 'pluse', 'Height', 'Weight', 'bp', 'Pulse', 'BMI', 'dolo']

  const addMasterHandler = () => {
    if (!medType) {
      toast.error('please select category from master list')
    }
    else if (!generic) {
      toast.error('please enter category values')
    }
    else {
      dispatch(saveMasterListValue({ type: medType, list: [...listValue.list, generic] }))
      setGeneric('')
    }
  }

  useEffect(() => {
    setSingleMasterListArray(listValue.list)
  }, [listValue.list])


  const searchHandler = (e) => {
    if (e.target.value) {
      const singleMasterObj = listValue.list.filter(item => (item.toUpperCase().indexOf(e.target.value.toUpperCase()) === 0))
      // console.log("getting filtered data", Object.values(item.name[0]).join('').toLowerCase())
      // return Object.values(item).join('').toLowerCase().includes(e.target.value.toLowerCase())
      // })
      console.log("singleMasterObj", singleMasterObj)
      setSingleMasterListArray(singleMasterObj)
    }
    else {
      console.log(listValue.list, "listValue.list")
      setSingleMasterListArray(listValue.list)
    }
  }




  console.log("SingleMasterListArray", singleMasterListArray, medType, medIndex)

  const editMasterListClick = (item, i) => {
    console.log("dhjdshjdshfjd", item, i)
    setEditModal(true)
    setGetListValue(item)
    setFoundIndex(i)
  }

  const handleOk = () => {

  }
  const handleCancel = () => {
    setEditModal(false)
  }
  const editMasterHandler = (e) => {
    setGetListValue(e.target.value)
  }

  const updateHandler = () => {
    if (!getListValue) {
      toast.error("please enter value")
    }
    else {
      // console.log("dsfsdfsdfsdfsdfsd", singleMasterListArray[foundIndex] = getListValue)
      singleMasterListArray[foundIndex] = getListValue
      dispatch(editMasterListValue({ type: medType, list: singleMasterListArray }, "edit"))
      setEditModal(false)
    }
  }

  const deleteMasterListClick = (item, i) => {
    console.log("dgdsgfdsagsasad", item, i);
    if (item) {
      console.log("testing item", item)
      // const list = singleMasterListArray?.filter((item11, index) => item11 !== item)
      // console.log("inginging",list)
      dispatch(editMasterListValue({ type: medType, list: singleMasterListArray?.filter((item11, index) => item11 !== item) }, "delete"))
    }
  }


  return (
    <div className="masterlist-container">
      <div className="masterlist-left-sec">
        <div className="masterlist-head">
          <h4>MASTERS LIST </h4>
          {/* <Button margin="0px" padding="5px">
            ADD NEW
          </Button> */}
        </div>

        <div className="masterlist-list-sec">
          {editMasterList?.map((item, index) => (
            <div className={index === medIndex ? 'white-color-background-med-list' : 'masterlist-items item-color'}>
              <div onClick={() => masterListHandler(item, index)}>
                <p>{item}</p>

              </div>
              {/* <div className="masterlist-items-icons">
                <img
                  className="master-table-edit"
                  src={editIcon}
                  alt="edit" />
                <img
                  className="master-table-delete"
                  src={deleteBackIcon}
                  alt="delete"
                />
              </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="masters-right-container">
        <div className="masterlist-head-sec">
          <p>{medType}</p>
          <div className="add-edit-wrapper">
            <Input
              className={"edit-master-search-input"}
              placeholder="Search"
              type="search"
              onChange={searchHandler}
              suffix={<SearchOutlined />}
            />
            <div className="add-list-input">
              <Input className={"edit-master-name-input"} placeholder="Name" value={generic} onChange={genericInputHandler} />
            </div>
            <div className="add-list-btn">
              <Button onClick={addMasterHandler} type="button" className={'add-btn-add-list'}>ADD</Button>
            </div>
            {/* <AddList data={medType} /> */}
            <div>
              <Button className="update-button-blue">
                <img src={UpdateImage} alt="" />
                UPDATE{" "}
              </Button>
            </div>
          </div>
          {/* <span className="red-notification">
            {" "}
            Once modified press update button
          </span> */}
        </div>

        <div className="masters-table-container1">
          <div className="table-container1">
            {listValue?.list?.length > 0 ? <h3>NAME</h3> : ''}
          </div>
          {singleMasterListArray && singleMasterListArray?.map((item, i) => (
            <div className="platlets-text">
              <div>
                <p>{item}</p>
              </div>
              <div>
                <img className="medicine-table-edit" src={editIcon} alt="edit" onClick={() => editMasterListClick(item, i)} />
                <img className="medicine-table-delete" src={deleteIcon} alt="delete" onClick={() => deleteMasterListClick(item, i)} />
              </div>
              {/* <img
                className="master-table-delete"
                src={deleteBackIcon}
                alt="delete"
              /> */}
            </div>
          ))}
        </div>
        <div>
          <Modal visible={editModal} onOk={handleOk} onCancel={handleCancel} className='edit-medicine-modal-div'>
            <h2>Edit {medType} value</h2>
            <div className='edit-medicine-div'>
              <InputField value={getListValue} onChange={editMasterHandler} />
            </div>
            <div className='edit-medicine-update-btn'>
              <Button onClick={updateHandler}>Update</Button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default MasterList;
