/* eslint-disable default-case */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appointmentsAllMultilistQueue, setHeAlertToggle } from "../../redux/actions/opd/patientreport";
import { Modal, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import InputField from "../../UIComponents/Input/Input";
import CommonButton from "../../UIComponents/Button/Button";

const Cast = () => {
  const dispatch = useDispatch();
  const allAppointments = useSelector(
    (state) => state.opdrecord.patientlist.allPatientList
  );
  console.log("apointment listsssss", allAppointments);
  window.patientOpdInvoiceInfo = useSelector(
    (state) => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo
  );
  console.log("patientOpdInvoiceInfo", window.patientOpdInvoiceInfo);
  window.loginDetails = useSelector((state) => state.login.loginInfo);
  console.log("loginDetails", window.loginDetails);
  const multilistQueue = useSelector(
    (state) => state.opdrecord.patientreport.multilistQueue
  );
  console.log("multilistQueue", multilistQueue);
  const alertToggle = useSelector(
    (state) => state.opdrecord.patientreport.alertToggle
  );
  console.log("alertToggle", alertToggle);
  window.singleAppointment = useSelector(
    (state) => state.opdrecord.patientHistory.singleAppointment?.appointment
  );
  console.log("singleAppointment", window.singleAppointment);
  window.profileInfo = useSelector((state) => state.profile.profileInfo);
  console.log("window.profileInfo", window.profileInfo);

  useEffect(() => {
    if (allAppointments.length) {
      const appointment = allAppointments.map((item) => item.appointment);
      setAppointmentList(appointment);
    }
  }, [allAppointments]);

  const [rt, setRt] = useState({
    castlist: {},
    applicationID: "F92B49E7",
    namespace: "urn:x-cast:com.google.cast.sample.apollopharmacyrx",
    appointments: null,
  });
  const [voiceMessage, setVoiceMessage] = useState("");
  const [textMessageToken, setTextMessageToken] = useState("");
  const [textMessageName, setTextMessageName] = useState("");
  const [appointmentList, setAppointmentList] = useState("");
  const [castIpAddressModal, setCastIpAddressModal] = useState(false)
  const [castIp, setCastIp] = useState(null)

  useEffect(() => {
    rt.castlist = multilistQueue;
    sendMessage(5);
  }, [multilistQueue]);

  useEffect(() => {
    if (appointmentList) {
      window.appointmentList = appointmentList;
      console.log("window.appointmentList", window.appointmentList);
      sendMessage(0);
      sendMessage(1);
    }
  }, [appointmentList]);

  const getUserIP = (onNewIP) => {
    let myPeerConnection =
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
    let pc = new myPeerConnection({
        iceServers: [],
      }),
      noop = function () {},
      localIPs = {},
      ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
      key;

    function iterateIP(ip) {
      if (!localIPs[ip]) onNewIP(ip);
      localIPs[ip] = true;
    }

    pc.createDataChannel("");
    pc.createOffer()
      .then(function (sdp) {
        sdp.sdp.split("\n").forEach(function (line) {
          if (line.indexOf("candidate") < 0) return;
          line.match(ipRegex).forEach(iterateIP);
        });

        pc.setLocalDescription(sdp, noop, noop);
      })
      .catch(function (reason) {});

    pc.onicecandidate = function (ice) {

      console.log("🚀 ~ ice:", ice)
      if (
        !ice ||
        !ice.candidate ||
        !ice.candidate.candidate ||
        !ice.candidate.candidate.match(ipRegex)
      )
        return;
      ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
  };

  const getCastData = () => {
    let mixBag = true;
    dispatch(
      appointmentsAllMultilistQueue({
        scheduledTime: moment().format("YYYY-MM-DD"),
        doctors: ["2a0e5fe2-f8af-4a0e-8b8e-a3d07582caa9"],
        mixBag: mixBag,
      })
    );
  };

  useEffect(() => {
    if (alertToggle) {
      console.log("calling doctorCheckin func", window.singleAppointment);
      setVoiceMessage(
        "Attention please, Token number " +
          window.singleAppointment.token +
          ", " +
          window.singleAppointment.patientName +
          ". Kindly proceed to see doctor. कृपया डॉक्टर से मिले."
      );
      setTextMessageToken("Token number " + window.singleAppointment.token);
      setTextMessageName(window.singleAppointment.patientName);
    } else {
      console.log("inside checkout patient");
      sendMessage(1);
    }
  }, [alertToggle]);

  const doctorCheckIn = (e) => {
    console.log("doctorCheckin", e);
    setVoiceMessage(
      "Attention please, Token number " +
        e.token +
        ", " +
        e.patientName +
        ". Kindly proceed to see doctor. कृपया डॉक्टर से मिले."
    );
    setTextMessageToken("Token number " + e.token);
    setTextMessageName(e.patientName);
  };

  useEffect(() => {
    if (voiceMessage && textMessageToken && textMessageName) {
      console.log(
        "inside useEffect",
        voiceMessage,
        textMessageToken,
        textMessageName,
        window.singleAppointment
      );
      sendMessage(3, voiceMessage, textMessageToken, textMessageName);
      setVoiceMessage('');
      setTextMessageToken('');
      setTextMessageName('');
      dispatch(setHeAlertToggle(false));
    }
  }, [voiceMessage, textMessageToken, textMessageName]);

  const sendMessage = (
    flag,
    voiceMessage,
    textMessageToken,
    textMessageName,
    castIpAddress
  ) => {
    console.log("send message ", window.appointmentList);
    let c =
      window.cast?.framework?.CastContext.getInstance().getCurrentSession();
    if (c) {
      if (flag == 2) {
        c.sendMessage(rt.namespace, {
          videoURL: "mytube",
          videoURLIP: castIpAddress,
          videoURLFile: "sample.mp4",
        });
      } else if (flag == 5) {
        c.sendMessage(rt.namespace, {
          multidoclist: JSON.stringify(rt.castlist),
        });
      } else if (flag == 3) {
        console.log(
          "inside checking patient",
          voiceMessage,
          textMessageToken,
          textMessageName,
          window.singleAppointment
        );
        c.sendMessage(rt.namespace, {
          voiceMessage: voiceMessage,
          textMessageToken: textMessageToken,
          textMessageName: textMessageName,
        });
      } else if (flag == 0) {
        c.sendMessage(rt.namespace, {
          name: `${window.profileInfo.name}`,
          id: `${window.profileInfo.id}`,
        });
      } else if (flag == 1) {
        console.log("flag 1 is working", window.appointmentList);
        c.sendMessage(rt.namespace, "start");
        let n = rt.namespace;
        console.log("namespace", n);

        window.appointmentList.forEach((item) => {
          console.log("item", item);
          let pobj = {
            patientName: item.patientName,
            locationName: item.locationName,
            timeslot: item.timeslot,
            status: item.status,
            locked: item.locked,
            token: item.token,
          };
          console.log("flage i for each", pobj);
          c.sendMessage(n, JSON.stringify(pobj));
        });
      }
    }
  };

  console.log("checking voice", voiceMessage);

  const init = () => {
    console.log("🚀 ~ file: Cast.js ~ line 13 ~ init ", window.appointmentList);
    window["__onGCastApiAvailable"] = function (isAvailable) {
      if (isAvailable) {
        console.log(
          "🚀 ~ file: Cast.js ~ line 15 ~ init ~ isAvailable",
          isAvailable
        );
        let c = window.cast.framework.CastContext.getInstance();
        c.setOptions({
          receiverApplicationId: rt.applicationID,
          autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        });

        c.addEventListener(
          window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
          function (event) {
            switch (event.sessionState) {
              case window.cast.framework.SessionState.SESSION_STARTED:
                if (window.loginDetails.role !== "assistant") {
                  sendMessage(0);
                  sendMessage(1);
                }
                console.log("Inside event listener", castIp)
                if (window.loginDetails.role === "assistant") {
                  getCastData();
                  var interval = setInterval(() => getCastData(), 120000);
                }
                break;
              case window.cast.framework.SessionState.SESSION_RESUMED:
                break;
              case window.cast.framework.SessionState.SESSION_ENDED:
                if (interval) clearInterval(interval);
                break;
            }
          }
        );
      }
    };
  };

  useEffect(() => {
    if (!window.customElements.get("google-cast-launcher")) {
      console.log("load scripts inside if", init());

      const loadScript = function (src) {
        const tag = document.createElement("script");
        tag.async = false;
        tag.src = src;
        const body = document.getElementsByTagName("body")[0];
        body.appendChild(tag);
      };
      loadScript(
        "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"
      );
      init();
    } else init();
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
      <google-cast-launcher></google-cast-launcher>
      <Tooltip title="Add IP Address for chromecast">
        <InfoCircleFilled  style={{ marginLeft: "10px", fontSize: '18px'}} onClick={() => {
          setCastIpAddressModal(true)
        }} />
      </Tooltip>

      <Modal
        visible={castIpAddressModal}
        onOk={() => setCastIpAddressModal(false)}
        onCancel={() => setCastIpAddressModal(false)}
        width="400px"
        className="edit-medicine-modal-div"
      >
        
        <h1 className="edit-patient-modal">Local Machine IP</h1>
        <div style={{ display: "flex", justifyContent: 'space-around', alignItems: 'center', marginTop: 20 }}>
          <InputField placeholder="Ex: 192.168.0.103" value={castIp} className={'template-input'} onChange={(e) => setCastIp(e.target.value)} />
          <CommonButton className={'template-save-btn'} onClick={() => {

            sendMessage(2, '', '', '', castIp);
            setCastIpAddressModal(false)
          }}>SAVE</CommonButton>
        </div>
      </Modal>
    </div>
  );
};

export default Cast;
