import * as types from "../../actionTypes";
import { baseUrl } from "../../../utils/Helper";
import { client } from "../../../utils/axios";

const doctorInvoiceLoading = () => ({
  type: types.GET_DOCTOR_INVOICE_SUMMARY_LOADING,
});

const doctorInvoiceSuccess = (data) => ({
  type: types.GET_DOCTOR_INVOICE_SUMMARY_SUCCESS,
  payload: data,
});

const doctorInvoiceFail = (error) => ({
  type: types.GET_DOCTOR_INVOICE_SUMMARY_FAIL,
  payload: error,
});

export const graphToggleSuccess = (data) => ({
  type: types.BAR_GRAPH_TOGGLE,
  payload: data,
});

export const exportToggleSuccess = (data) => ({
  type: types.EXPORT_TOGGLE,
  payload: data,
});

export const updateInvoiceDetails = (data) => ({
  type: types.UPDATE_INVOICE_DETAILS,
  payload: data,
});

const invoiceDetailsLoading = () => ({
  type: types.INVOICE_DETAILS_LOADING,
});

const invoiceDetailsSuccess = (invoiceDetailsInfo) => ({
  type: types.INVOICE_DETAILS_SUCCESS,
  payload: invoiceDetailsInfo,
});

const invoiceDetailsFail = (error) => ({
  type: types.INVOICE_DETAILS_FAIL,
  payload: error,
});

const dailyCollectionLoading = () => ({
  type: types.DAILY_COLLECTION_LOADING,
});
const dailyCollectionSuccess = (data) => ({
  type: types.DAILY_COLLECTION_SUCCESS,
  payload: data,
});
const dailyCollectionFail = (error) => ({
  type: types.DAILY_COLLECTION_FAIL,
  payload: error,
});
export const clearDatesOnTab = (payload) => ({
  type: types.CLEAR_DATES_TABCLICK,
  payload,
});

const patientStatsLoading = () => ({
  type: types.PATIENT_STATS_LOADING,
});
const patientStatsSuccess = (data) => ({
  type: types.PATIENT_STATS_SUCCESS,
  payload: data,
});
const patientStatsFail = (error) => ({
  type: types.PATIENT_STATS_FAIL,
  payload: error,
});

export const hideDataSuccess = (payload) => ({
  type: types.HIDE_DATAON_TABCLICK,
  payload,
});

export const clearDataSuccess = (payload) => ({
  type: types.CLEAR_DATAON_TABCLICK,
  payload,
});

export const clearDropDownData = (payload) => ({
  type: types.CLEAR_DROPDOWN,
  payload,
});

const prescriptionAnalysisLoading = () => ({
  type: types.PRESCRIPTION_ANALYSIS_LOADING,
});
const prescriptionAnalysisSuccess = (data) => ({
  type: types.PRESCRIPTION_ANALYSIS_SUCCESS,
  payload: data,
});
const prescriptionAnalysisFail = (error) => ({
  type: types.PRESCRIPTION_ANALYSIS_FAIL,
  payload: error,
});

let url = baseUrl();
export const doctorInvoice = (
  fromDate,
  toDate,
  locationId,
  mode,
  sinv,
  einv
) => {
  console.log("testing1234", fromDate, toDate, locationId, mode, sinv, einv);
  return function (dispatch) {
    dispatch(doctorInvoiceLoading());
    client
      .get(
        `/doctor/doctor-invoice-summary?fromDate=${fromDate}&toDate=${toDate}&locationId=${locationId}&mode=${mode}&sinv=${sinv}&einv=${einv}`
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((res) => {
        dispatch(doctorInvoiceSuccess(res.data));
        console.log("dataa", res.data.data);
        dispatch(exportToggleSuccess(true));
      })
      .catch((error) => {
        console.log(error);
        dispatch(doctorInvoiceFail(error.response));
      });
  };
};

export const invoiceDetailsData = (
  fromDate,
  toDate,
  locationId,
  mode,
  doctorId,
  sinv,
  einv
) => {
  console.log(
    "testing1234",
    fromDate,
    toDate,
    locationId,
    doctorId,
    mode,
    sinv,
    einv
  );
  return function (dispatch) {
    dispatch(invoiceDetailsLoading());
    client
      .get(
        `/doctor/doctor-invoice-detail?fromDate=${fromDate}&toDate=${toDate}&locationId=${locationId}&mode=${mode}&doctorId=${doctorId}&sinv=${sinv}&einv=${einv}`
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((res) => {
        dispatch(invoiceDetailsSuccess(res.data));
        console.log("dataaaa", res.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(invoiceDetailsFail(error.response));
      });
  };
};

export const dailyCollection = (fromDate) => {
  console.log("Datesss", fromDate);
  return function (dispatch) {
    dispatch(dailyCollectionLoading());
    client
      .get(
        `/doctor/doctor-patient-visit-stats?fromDate=${fromDate}`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((res) => {
        dispatch(dailyCollectionSuccess(res.data));
        console.log("dataa", res.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(dailyCollectionFail(error.response));
      });
  };
};

export const patientStats = (fromDate, toDate) => {
  console.log("Datesss", fromDate, toDate);
  return function (dispatch) {
    dispatch(patientStatsLoading());
    client
      .get(
        `/doctor/doctor-patient-stats?fromDate=${fromDate}&toDate=${toDate}`
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((res) => {
        dispatch(patientStatsSuccess(res.data));
        console.log("dataa", res.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(patientStatsFail(error.response));
      });
  };
};

export const prescriptionAnalysisData = (fromDate, toDate) => {
  console.log("Datesss", fromDate, toDate);
  return function (dispatch) {
    dispatch(prescriptionAnalysisLoading());
    client
      .get(
        `/doctor/doctor-prescription-stats?fromDate=${fromDate}&toDate=${toDate}`
        // { headers: { Authorization: `Bearer ${secure_token}` } }
      )
      .then((res) => {
        dispatch(prescriptionAnalysisSuccess(res.data));
        console.log("dataaaaa", res.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(prescriptionAnalysisFail(error.response));
      });
  };
};
