import * as types from '../../actionTypes';

const initialState = {
    accessTokens: [],
    accessTokenNos: {},
    error: {},
    loading: false,
}



export  const accessTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ACCESS_TOKEN_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.ACCESS_TOKEN_SUCCESS:
            console.log("actionddddd",action.payload)
            return {
                ...state,
                accessTokens: action.payload,
                loading: false,
                error: {}
            }
        case types.ACCESS_TOKEN_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                accessTokens: []
            }
        case types.ACCESS_TOKEN_NO:
            return{
                ...state,
                accessTokenNos: action.payload,
                loading:false
            }
        
        default:
            return state;
    }
}