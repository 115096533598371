import React from 'react'
import './Cranial.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


function Cranial() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)  
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'ULTRASONOGRAPHIC FINDINGS OF CRANIAL')
    let index = 0
    reportDataFields.testReport.map((item, i) => {
      if(item?.test === 'ULTRASONOGRAPHIC FINDINGS OF CRANIAL'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
  filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }

  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))   
  }

  return (
    <div>
    <div className="cranial-parent">
      <div className="cranial-left">
        <div className="cranial-grid">
          <div className="cranial-1 cranial-pad" onClick={() => onLabelClicked("OBSERVATION")}>OBSERVATION</div>
          <div className="cranial-2 cranial-text">
            <TextAreaAnt name="OBSERVATION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
          </div>
          <span className="cranial-border"></span>
          <div className=" cranial-pad" onClick={() => onLabelClicked("IMPRESSION")}>IMPRESSION</div>
          <div className=" cranial-text">
            <TextAreaAnt name="IMPRESSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
          </div>
          <span className="cranial-border1"></span>
        </div>
      </div>
      <div>
        <TestReportRightSection />
      </div>
    </div>
    <div className="cranial-foot">
      <ReportFooter />
    </div>
 
  </div>
  )
}

export default Cranial
