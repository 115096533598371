import React from 'react'
import './StoolTest.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


export const StoolTest = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'STOOL TEST FOR ROUTINE & MICROSCOPY')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'STOOL TEST FOR ROUTINE & MICROSCOPY'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))    
  }
  return (
    <div>
        
    <div className='stool-parent'>
      <div className='stool-left'>
          <div className='stool-grid'>
              <div className='stool1 stool-pad' onClick={() => onLabelClicked("COLOUR")}>COLOUR</div>
              <div className='stool2 stool-text'><TextAreaAnt name="COLOUR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("FORM & COSISTENCY")}>FORM & COSISTENCY</div>
              <div className='stool-text '><TextAreaAnt name="FORM & COSISTENCY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border2'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("MUCUS")}>MUCUS</div>
              <div className='stool-text'><TextAreaAnt name="MUCUS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border3'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("ODOUR")}>ODOUR</div>
              <div className='stool-text '><TextAreaAnt name="ODOUR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border4'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("PH")}>PH</div>
              <div className='stool-text '><TextAreaAnt name="PH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border5'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("REDUCING SUGAR")}>REDUCING SUGAR</div>
              <div className='stool-text '><TextAreaAnt name="REDUCING SUGAR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange}   /></div>
              <span className='stool-border6'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("PUS CELLS")}> PUS CELLS</div>
              <div className='stool-text '><TextAreaAnt name="PUS CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border7'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("RBCs")}> RBCs</div>
              <div className='stool-text '><TextAreaAnt name="RBCs" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border8'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("OVA")}> OVA</div>
              <div className='stool-text '><TextAreaAnt name="OVA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border9'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("TROPHOZOITES")}> TROPHOZOITES</div>
              <div className='stool-text '><TextAreaAnt name="TROPHOZOITES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange}   /></div>
              <span className='stool-border10'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("CYST")}> CYST</div>
              <div className='stool-text '><TextAreaAnt name="CYST" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border11'></span>
              <div className='stool-pad' onClick={() => onLabelClicked("LARVAE")}> LARVAE</div>
              <div className='stool-text '><TextAreaAnt name="LARVAE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='stool-border12'></span>
          </div>
      </div>
      <div>
          <TestReportRightSection />
      </div>
    </div>
    <div className='stool-foot'>
        <ReportFooter />
    </div>
</div>
  )
}
