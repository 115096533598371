import * as types from "../actionTypes";
import qs from "qs";
import { toast } from "../../UIComponents/Toast";
import { client } from "../../utils/axios";

export const resetAdminData = (payload) => ({
  type: types.RESET_ADMIN_DATA,
  payload,
});

const adminLoginLoading = () => ({
  type: types.ADMIN_LOGIN_LOADING,
});

const adminLoginSuccess = (data) => ({
  type: types.ADMIN_LOGIN_SUCCESS,
  payload: data,
});
const adminLoginFail = (error) => ({
  type: types.ADMIN_LOGIN_FAIL,
  payload: error,
});

export const updateAdminStatus = (data) => ({
  type: types.UPDATE_ADMIN_STATUS,
  payload: data,
});

export const adminLogin = (data) => {
  return function (dispatch) {
    dispatch(adminLoginLoading());

    client
      .post(`/user/login`, data)
      .then((resp) => {
        dispatch(adminLoginSuccess(resp.data));
        console.log("admin login data", resp.data);
        if (resp.data.success) {
          toast.success("Login Successful");
          console.log("status", resp.data);
          client.setJwtToken(resp.data.token);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`);
        dispatch(adminLoginFail(error));
      });
  };
};
