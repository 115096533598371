import * as types from '../../actionTypes';

const initialState = {
    UpdateVisitOpdInvoice: {},
    error: {},
    loading: false
}


export const UpdateVisitOpdInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_OPD_UPDATE_VISIT_INVOICE:
            return {
                ...state,
                loading: true
            }
        case types.GET_OPD_UPDATE_VISIT_INVOICE_SUCCESS:
            return {
                ...state,
                UpdateVisitOpdInvoice: action.payload,
                loading: false,
                UpdateVisitOpdInvoice:{},
                error: {}
            }
        case types.GET_OPD_UPDATE_VISIT_INVOICE_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                UpdateVisitOpdInvoice:{}
            } 


        default:
            return state;
    }
}