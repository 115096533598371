import * as types from "../actionTypes";

export const setMobileNumber = (payload) => ({
  type: types.RBI_MOBILE_NUMBER,
  payload,
});
export const setRelationship = (payload) => ({
  type: types.GET_RELATIONSHIP,
  payload,
});
export const setEmpId = (payload) => ({
  type: types.EMP_ID,
  payload,
});
export const setAddress = (payload) => ({
  type: types.CURRENT_ADDRESS,
  payload,
});
