import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { loadMasterListValue } from "./masterListValue";
import { client } from "../../../utils/axios";

export const getMasterListLoading = () => ({
  type: types.GET_MASTER_LIST_LOADING,
});

export const getMasterListSuccess = (payload) => ({
  type: types.GET_MASTER_LIST_SUCCESS,
  payload,
});

export const getMasterListError = (error) => ({
  type: types.GET_MASTER_LIST_FAIL,
  payload: error,
});

export const editMasterListLoading = () => ({
  type: types.EDIT_MASTER_LIST_VALUE_LOADING,
});

export const editMasterListSuccess = (payload) => ({
  type: types.EDIT_MASTER_LIST_VALUE_SUCCESS,
  payload,
});

export const editMasterListError = (error) => ({
  type: types.EDIT_MASTER_LIST_VALUE_FAIL,
  payload: error,
});

export const loadMasterList = () => {
  return function (dispatch) {
    dispatch(getMasterListLoading());
    client
      .post(
        `/doctor/get-list-type`
        // null
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getMasterListSuccess(resp.data));
        toast.success("List loaded successfully");
      })
      .catch((error) => {
        dispatch(getMasterListError(error.response));
      });
  };
};

export const editMasterListValue = (data, type) => {
  console.log("gsdfgsdfgdsgf", data, type);
  return function (dispatch) {
    dispatch(editMasterListLoading());
    client
      .post(
        `/doctor/update-to-list`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(editMasterListSuccess(resp.data));
        dispatch(loadMasterListValue({ type: data.type }));
        if (type === "edit") {
          toast.success("Updated successfully");
        } else {
          toast.success("Deleted successfully");
        }
      })
      .catch((error) => {
        dispatch(editMasterListError(error.response));
      });
  };
};
