import React, { useEffect } from 'react'
import Allergy from './Allergy/Allergy'
import Template from './Template/Template'
import '../PatientReports/PatientReports.css'
import Vitals from './Vitals/Vitals'
import Note from './Note/Note'
import Narratives from './Narratives/Narratives'
import ClinicalFindings from './ClinicalFindings/ClinicalFindings'
import RadiologicalFindings from './RadiologicalFindings/RadiologicalFindings'
import Diagnosis from './Diagnosis/Diagnosis'
import Medicines from './Medicines/Medicines'
import Tests from './Tests/Tests'
import Procedures from './Procedures/Procedures'
import SpecialNotes from './SpecialNotes/SpecialNotes'
import File from './Files/File'
import FollowUp from './FollowUp/FollowUp'
import Forward from './Forward/Forward'

import ViewVisit from '../ViewVisit/ViewVisit'
import { useDispatch, useSelector } from 'react-redux'
import { updateVisit } from '../../../redux/actions/opd/patientopdinvoice'
import { replicateTemplateSuccess } from '../../../redux/actions/opd/patientreport'

function PatientReports() {
  let dispatch = useDispatch()
  const active = useSelector(state => state.opdrecord.patientreport.active)
  console.log("single visit status", active)
  const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
  const replicated = useSelector(state => state.opdrecord.patientreport.replicated)
  console.log("replicated", replicated)


  useEffect(() => {
    if(replicated.visit) {
      dispatch(updateVisit({ ...visitInfo, clinicalFinding: replicated?.visit?.clinicalFinding, allergens: replicated?.visit?.allergens, diagnosis: replicated?.visit?.diagnosis, medicines: replicated?.visit?.medicines, radiologicalFinding: replicated?.visit?.radiologicalFinding, specialNote: replicated?.visit?.specialNote, lab: replicated?.visit?.lab, procedure: replicated?.visit?.procedure, narratives: replicated?.visit?.narratives }))
      dispatch(replicateTemplateSuccess({}));
    }
  }, [replicated])

  return (
    <div>
      {active ? <div>
        <ViewVisit />
      </div> :
        <div className='patient-reports'>
          <div className='patient-reports-title-container'>
            <h4>PATIENT REPORTS</h4>
            <div className='patient-reports-referal-container'>
              {visitInfo.referralName ? (<div><strong>Referral Name: </strong>{visitInfo.referralName || ''}</div>) : null}
              {visitInfo.referralId ? (
                <div><strong>Referral ID: </strong>{visitInfo.referralId || ''}</div>
              ) : null}
            </div>
          </div>
          <Allergy />
          <Template />
          <Vitals />
          <Note />
          <Narratives />
          <ClinicalFindings />
          <RadiologicalFindings />
          <Diagnosis />
          <Medicines />
          <Tests />
          <Procedures />
          <SpecialNotes />
          <File />
          <FollowUp />
          <Forward />

        </div>}

    </div>
  )
}

export default PatientReports