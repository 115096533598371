import { Modal } from "antd";
import copy from "copy-to-clipboard";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import {
  saveDiagnosticReport,
  sendSmsDiagnostic,
  updateReports,
} from "../../../redux/actions/Diagnostics/diagnostics";
import { toast } from "../../../UIComponents/Toast";
import { baseUrl } from "../../../utils/Helper";
import BookEdit from "../BookEdit/BookEdit";
import SearchBookEdit from "../BookEdit/SearchBookEdit";
import "./DiagnosticPatientInvoiceStatus.css";
import PrintReport from "./PrintReport";
import { createDiagnostics } from "../../../redux/actions/Diagnostics/diagnostics";
import { selectHandle } from "../../../redux/actions/Diagnostics/diagnostics";
import { resetDiagnosticData } from "../../../redux/actions/Diagnostics/saveDiagnostic";

const DiagnosticPatientInvoiceStatus = () => {
  let dispatch = useDispatch();
  const componentRef = useRef();
  const componentRefff = useRef();

  const getDiagnosticData = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.getdiagnostic
  );
  console.log("getDiagnosticDataaaa", getDiagnosticData?.active);
  const transactionId = getDiagnosticData?.transactionId;
  const doctorId = getDiagnosticData?.doctorId;
  const patientClick = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.patientClick
  );
  const reportData = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.getdiagnostic
  );
  console.log("report Datass in diag", reportData);
  const reportDataFieldss = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.getdiagnostic
  );
  const updatedDataReport = useSelector(
    (state) => state?.diagnosticsInvoiceSummary.updateReport
  );
  const testReportData = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.printTestReport
  );
  console.log("testReportData", testReportData);
  const invoiceDataPrint = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.printDiagnosticsInvoice
  );
  const newtransactionId = reportDataFieldss?.transactionId;
  const toggleButton = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.toggleButton
  );
  const reportDataFields = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.getdiagnostic
  );
  console.log("reportDataFields", reportDataFields?.active);
  const addedData = useSelector(
    (state) => state?.diagnosticsInvoiceSummary?.addedData
  );
  console.log("addedData in diagnostics", addedData);

  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const report = {
    transactionId: reportData?.transactionId,
    doctorId: reportData?.doctorId,
    testReport: updatedDataReport?.testReport,
  };

  const showModal = () => {
    dispatch(resetDiagnosticData());
    setIsModalVisible(true);
  };
  const searchModal = () => {
    setSearchModalVisible(true);
    dispatch(selectHandle("selected"));
  };
  const handleCancel = () => {
    dispatch(createDiagnostics({}));
    dispatch(selectHandle(""));
    setIsModalVisible(false);
    setSearchModalVisible(false);
  };

  const onSaveHandle = () => {
    console.log(
      "save test reports",
      report,
      reportData,
      doctorId,
      newtransactionId,
      reportDataFields
    );
    dispatch(
      saveDiagnosticReport(
        report ? report : reportData?.testReport,
        doctorId,
        newtransactionId
      )
    );
    dispatch(updateReports(reportDataFields));
  };

  let url = baseUrl();
  const copyReportLink = () => {
    copy(
      `${window.location.host}/diagnostic-report/${transactionId}/${doctorId}`
    );
    toast.success("Link Copied");
  };
  const onSendSmsClick = () => {
    dispatch(
      sendSmsDiagnostic({
        doctorId,
        patientId: reportData?.patient?.id,
        transactionId: reportData?.transactionId,
      })
    );
  };

  return (
    <div>
      <div>
        {patientClick === true ? (
          <div>
            {getDiagnosticData ? (
              <div>
                {getDiagnosticData?.active == true ? (
                  <div className="diagnostic-patient-invoice-status">
                    <div className="diagnostics-edit-copy-print-btns">
                      <div className="diagnostic-patient-sms-copy-save-print-download">
                        <div className="diagnostic-copylink-section">
                          <button onClick={showModal}>BOOK/EDIT</button>
                        </div>
                        <div className="diagnostic-copylink-section">
                          <button onClick={onSaveHandle}>
                            SAVE TEST REPORTS
                          </button>
                        </div>
                      </div>
                      <div className="diagnostic-patient-sms-copy-save-print-download">
                        <div className="diagnostic-sms-invoice">
                          <button
                            onClick={onSendSmsClick}
                            className="diag-sms-invoice"
                          >
                            SMS LINK
                          </button>
                        </div>
                        <div className="diagnostic-copylink-section">
                          <button
                            onClick={copyReportLink}
                            className="diag-copylink-title"
                          >
                            COPY LINK{" "}
                          </button>
                        </div>
                        <div className="diagnostic-copylink-section">
                          <ReactToPrint
                            trigger={() => <button>PRINT INVOICE</button>}
                            content={() => componentRefff.current}
                          />
                          <div style={{ display: "none" }}>
                            <div ref={componentRefff}>
                              <div
                                className="print-invoice-page"
                                dangerouslySetInnerHTML={{
                                  __html: invoiceDataPrint,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="diagnostic-copylink-section">
                          <ReactToPrint
                            trigger={() => (
                              <button disabled={!testReportData}>
                                PRINT TEST REPORTS
                              </button>
                            )}
                            content={() => componentRef.current}
                          />
                          <div style={{ display: "none" }}>
                            <div ref={componentRef}>
                              {testReportData ? <PrintReport /> : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="diagnostic-patient-invoice-status">
                    <div className="diagnostics-edit-copy-print-btns">
                      <div className="diagnostic-patient-sms-copy-save-print-download">
                        <div className="diagnostic-copylink-section">
                          <button onClick={searchModal}>BOOK/EDIT</button>
                        </div>
                        <div className="diagnostic-copylink-section">
                          <ReactToPrint
                            trigger={() => <button>PRINT INVOICE</button>}
                            content={() => componentRefff.current}
                          />
                          <div style={{ display: "none" }}>
                            <div ref={componentRefff}>
                              <div
                                className="print-invoice-page"
                                dangerouslySetInnerHTML={{
                                  __html: invoiceDataPrint,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : patientClick === false ? (
          ""
        ) : (
          ""
        )}
        {toggleButton ? (
          <div className="diagnostic-patient-invoice-status">
            <div className="diagnostics-edit-copy-print-btns">
              <div className="diagnostic-patient-sms-copy-save-print-download">
                <div className="diagnostic-copylink-section">
                  <button onClick={searchModal}>BOOK/EDIT</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        title="BOOK/EDIT"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={1200}
      >
        <BookEdit handleCancel={handleCancel} />
      </Modal>
      <Modal
        title="BOOK/EDIT"
        visible={searchModalVisible}
        onCancel={handleCancel}
        width={1200}
      >
        <SearchBookEdit handleCancel={handleCancel} />
      </Modal>
    </div>
  );
};

export default DiagnosticPatientInvoiceStatus;
