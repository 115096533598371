import React from 'react'
import './IuiReport.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { saveReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field, updateReports} from '../../../../redux/actions/Diagnostics/diagnostics'


export const IuiReport = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)

    const [ inputValue, setInputValue ] = useState("")
    const [ tableValueOne, setTableValueOne ] = useState("")
    
    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'IUI REPORT')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'IUI REPORT'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
   
    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))    }

const onTablesChange = (e) => {
    const { value, name } = e.target;
    console.log('onTableChange function', name, value)
    const [table, type, index] = name.split('.')
    console.log('table, type, index', table, type, index)
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'IUI REPORT')
        let ind = 0
        reportDataFields.testReport.map((item, i) => {
          if(item.test === 'IUI REPORT'){
            ind = i
          }
          return item
        })
        console.log("index",ind,filterData[0].table[0].data);
        const filterDataa = filterData[0].table[0].data.map((item,i) => {
          console.log("eokrlklmr",item);
          if(item.particular == table){
            return {
              ...item,
              [type]: value
            }
          }return item
        })
        filterData[0].table[0].data = filterDataa
        console.log("finaldata",reportDataFields);
        setTableValueOne(filterData[0].table[0].data)
        dispatch(updateReports(reportDataFields))
  }
    return (
        <div>
            <div className='iui-main'>
                <div className='iui-container'>
                    <div className='iui-grid-container'>
                        <div className='iui-1 padd' onClick={() => onLabelClicked("Method of Collection")} >Method of Collection</div>
                        <div className='iui-text1'><TextAreaAnt name="Method of Collection" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='iui-border1'></span>
                        <div className='iui-2 padd'  onClick={() => onLabelClicked("Abstinence")} >Abstinence</div>
                        <div className='iui-text2'><TextAreaAnt name="Abstinence" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='iui-border2'></span>
                        <div className='iui-3 padd' onClick={() => onLabelClicked("Time of Collection")} >Time of Collection</div>
                        <div className='iui-text3'><TextAreaAnt name="Time of Collection" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='iui-border3'></span>
                        <div className='iui-4 padd'  onClick={() => onLabelClicked("Color")} >Color</div>
                        <div className='iui-text4'><TextAreaAnt name="Color" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='iui-border4'></span>
                        <div className='iui-5 padd' onClick={() => onLabelClicked("Viscosity")}>Viscosity</div>
                        <div className='iui-text5'><TextAreaAnt name="Viscosity" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='iui-border5'></span>
                        <div className='iui-6 padd' onClick={() => onLabelClicked("Odour")} >Odour</div>
                        <div className='iui-text6'><TextAreaAnt name="Odour" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='iui-border6'></span>
                        <div className='iui-7 padd' onClick={() => onLabelClicked("Volume")} >Volume</div>
                        <div className='iui-text7'><TextAreaAnt name="Volume" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='iui-border7'></span>
                        <div className='iui-8 padd' onClick={() => onLabelClicked("Liquefaction Time")} >Liquefaction Time</div>
                        <div className='iui-text8'><TextAreaAnt name="Liquefaction Time" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='iui-border8'></span>
                        <div className='iui-9 padd'>Pre Wash</div>
                        <div className='iui-10 padd'>Post Wash</div>
                        <span className='iui-border9'></span>
                        <div className='iui-11 padd'>Total Count</div>
                        <div className='iui-12 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Total Count.prewash.0"  /></div>
                        <div className='iui-13'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Total Count.postwash.0"  /></div>
                        <span className='iui-border10'></span>
                        <div className='iui-14 padd'>Motility</div>
                        <div className='iui-15 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Motility.prewash.1"  /></div>
                        <div className='iui-16'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Motility.postwash.1"  /></div>
                        <span className='iui-border11'></span>
                        <div className='iui-17 padd'>Rapid Linear Progression</div>
                        <div className='iui-18 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Rapid Linear Progression.prewash.2"  /></div>
                        <div className='iui-19'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Rapid Linear Progression.postwash.2"  /></div>
                        <span className='iui-border12'></span>
                        <div className='iui-20 padd'>Slow Non Linear Progression</div>
                        <div className='iui-21 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Slow Non Linear Progression.prewash.3"  /></div>
                        <div className='iui-22'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Slow Non Linear Progression.postwash.3"  /></div>
                        <span className='iui-border13'></span>
                        <div className='iui-23 padd'>Non Progressive Motility</div>
                        <div className='iui-24 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Non Progressive Motility.prewash.4"  /></div>
                        <div className='iui-25'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[4]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Non Progressive Motility.postwash.4"  /></div>
                        <span className='iui-border14'></span>
                        <div className='iui-26 padd'>Immotile</div>
                        <div className='iui-27 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Immotile.prewash.5"  /></div>
                        <div className='iui-28'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[5]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Immotile.postwash.5"  /></div>
                        <span className='iui-border15'></span>
                        <div className='iui-29 padd'>Method used for Preparation</div>
                        <div className='iui-30 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Method used for Preparation.prewash.6"  /></div>
                        <div className='iui-31'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[6]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Method used for Preparation.postwash.6"  /></div>
                        <span className='iui-border16'></span>
                        <div className='iui-32 padd'>Pus Cell</div>
                        <div className='iui-33 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange}  name="Pus Cell.prewash.7"  /></div>
                        <div className='iui-34'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[7]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Pus Cell.postwash.7"  /></div>
                        <span className='iui-border17'></span>
                        <div className='iui-35 padd'>Epithelial Cells</div>
                        <div className='iui-36 '><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.prewash} disabled={!reportDataFields.active} onChange={onTablesChange}  name="Epithelial Cells.prewash.8"  /></div>
                        <div className='iui-37'><Input className='iui-inputs' type="text" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[8]?.postwash} disabled={!reportDataFields.active} onChange={onTablesChange} name="Epithelial Cells.postwash.8"  /></div>
                        <span className='iui-border18'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}
