import React, { useState, useEffect } from "react";
import InputField from "../../../UIComponents/Input/Input";
import { Button, Checkbox, Modal } from "antd";
import "../PatientForm/PatientForm.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import FamilyForm from "../FamilyMember/FamilyMember";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import rightIcon from "../../../images/correct-Icon.png";
import errorIcon from "../../../images/error-icon.png";

import { useSelector } from "react-redux";
import { baseUrl } from "../../../utils/Helper";
import { useDispatch } from "react-redux";

import { setAddress, setEmpId } from "../../../redux/actions/rbi";
import { pincodeData } from "../rbiData";

const serviceData = [
  { id: "Serving Employee", name: "Serving Employee" },
  { id: "Retired Employee", name: "Retired Employee" },
];

const genderData = [
  { id: "M", name: "M" },
  { id: "F", name: "F" },
  { id: "NA", name: "NA" },
];

const PatientForm = () => {
  const history = useHistory();
  const [service, setService] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isTcModalOpen, setIsTcModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pincodedata, setPincodeData] = useState("");
  const [isCheked, setIsChecked] = useState(null);
  const [genderdata, setGenderdata] = useState("");
  const [patientData, setPatientData] = useState(null);

  console.log("patientData", patientData);

  let url = baseUrl();

  const mobileNumber = useSelector((state) => state?.rbi?.number);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    employeeId: "",
    alternatePhone: "",
    currentAddress: "",
    pincode: "",
    dob: "",
  });
  const [errors, setErrors] = useState({});

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleErrorOk = () => {
    setIsErrorModalOpen(false);
  };
  const handleTcOk = () => {
    setIsTcModalOpen(false);
  };

  const handleDone = () => {
    history.push("/ihbanarik");
  };

  const [error, setError] = useState("");
  const handleChangePincode = (value) => {
    setPincodeData(value.toString());
    if (value) {
      setError(""); // Clear error if a service is selected
    }
  };

  const handleChangeService = (value) => {
    setService(value.toString());
    if (value) {
      setError(""); // Clear error if a service is selected
    }
  };

  const onChange = (e) => {
    setIsChecked(e.target.checked);
    setIsTcModalOpen(e.target.checked);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "currentAddress") {
      dispatch(setAddress(value));
    }

    if (name === "alternatePhone") {
      // Allow only digits and limit to a maximum of 10 digits
      if (/^\d{0,10}$/.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value, // Trim the value to 10 digits if it exceeds the limit
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (name === "employeeId") {
      if (/^\d{0,6}$/.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else if (/^[a-zA-Z][a-zA-Z0-9]{0,9}$/.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      dispatch(setEmpId(value));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      return;
    }
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;

    // Allow only digits and limit to a maximum of 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // Trim the value to 10 digits if it exceeds the limit
      }));
    }
  };

  useEffect(() => {
    const fetchPatientData = async () => {
      let data = JSON.stringify({
        mobile: mobileNumber,
        guardian: "self",
      });

      let config = {
        method: "post",
        url: `${url}user/rbi/find-patient`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        setPatientData(response.data); // Store the patient data from the response
      } catch (error) {
        setError(error.message); // Store any error encountered
      }
    };

    fetchPatientData(); // Make the API call when the component mounts
  }, []);

  const convertToDateFormat = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // This is for displaying dd-mm-yyyy
  };

  const formatForInputField = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${year}-${month}-${day}`; // yyyy-mm-dd format for InputField
  };

  const dateString = patientData?.data?.members?.[0]?.dob;
  const formattedDate = convertToDateFormat(dateString); // This is for display purposes
  const inputFormattedDate = formatForInputField(dateString); // Use this format for input field

  const validateForm = () => {
    let validationErrors = {};

    if (!formData.name && !patientData?.data?.members?.[0]?.name) {
      validationErrors.name = "Name is required";
    }
    if (!formData.dob && !inputFormattedDate) {
      validationErrors.dob = "DOB is required";
    }

    if (!formData.employeeId && !patientData?.data?.members[0]?.employeeId) {
      validationErrors.employeeId = "Employee ID is required";
    }

    if (!formData.currentAddress && !patientData?.data?.address?.address1) {
      validationErrors.currentAddress = "Current Address is required";
    }

    if (!genderdata && !patientData?.data?.members?.[0]?.gender) {
      validationErrors.genderdata = "Please select gender";
    }

    if (!pincodedata && !patientData?.data?.address?.pincode) {
      validationErrors.pincodedata = "Please select pincode";
    }
    if (!service && !patientData?.data?.designation) {
      validationErrors.service = "Please select service";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const doctorNumber = process.env.REACT_APP_RBI_DOCTOR_NUMBER;
  const handleSubmit = async () => {
    if (validateForm()) {
      const employeeData = {
        name: formData.name || patientData?.data?.members?.[0]?.name,
        // designation: formData.designation,
        designation: service || patientData?.data?.designation,
        employeeId:
          formData.employeeId || patientData?.data?.members[0]?.employeeId,
        alternatePhone: formData.alternatePhone || "",
        address: {
          address1:
            formData.currentAddress || patientData?.data?.address?.address1,
          city: "Mumbai",
          state: "Maharashtra",
          pincode: pincodedata || patientData?.data?.address?.pincode,
          country: "India",
        },
        mobile: mobileNumber,
        guardian: "self",
        // uniqueno: 230314025,
        // doctorId: "2a0e5fe2-f8af-4a0e-8b8e-a3d07582caa9",
        gender: genderdata || patientData?.data?.members?.[0]?.gender,
        doctorNumber: doctorNumber,
        // dob: formData?.dob.replace(/-/g, "/"),
        dob: formData?.dob.split("-").reverse().join("/") || formattedDate,
      };

      try {
        setIsSubmitting(true);

        const response = await axios.post(
          `${url}user/rbi/add-patient`,
          employeeData,
          {
            headers: {
              "Content-Type": "application/json",
              Credential: true,
            },
          }
        );

        if (response.status === 201) {
          // You can adjust based on the API's success code
          setIsModalOpen(true); // Show success modal
        } else {
          setIsErrorModalOpen(true); // Show error modal
        }
      } catch (error) {
        setIsErrorModalOpen(true); // Show error modal on network error
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emp = queryParams.get("emply");

  const handleGender = (value) => {
    setGenderdata(value.toString() || patientData?.data?.members?.[0]?.gender);
    if (value) {
      setError(""); // Clear error if a service is selected
    }
  };

  return (
    <div>
      {emp === "family-member" ? (
        <div>
          <FamilyForm />
        </div>
      ) : (
        <div className="rbi-main-content">
          {emp === "employee" ? (
            <div style={{ fontWeight: "400", padding: "5px", color: "white" }}>
              Employee Details
            </div>
          ) : null}
          <div className="login-content">
            <div className="login-text">
              <div className="login-input">
                <p>Name </p>
                <InputField
                  type="text"
                  maxLength={35}
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  className="phone-input-style"
                  value={formData.name || patientData?.data?.members?.[0]?.name}
                  onChange={handleChange}
                  disabled={patientData?.data?.members?.[0]?.name}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
              <div className="login-input">
                <p>Gender</p>

                <SingleSelect
                  data={genderData}
                  onChange={handleGender}
                  value={genderdata || patientData?.data?.members?.[0]?.gender}
                  placeholder="Select Gender"
                  id="gender"
                  disabled={patientData?.data?.members?.[0]?.gender}
                />
                {errors.genderdata && (
                  <span className="error">{errors.genderdata}</span>
                )}
              </div>
              <div className="login-input">
                <p>Date of Birth </p>
                <InputField
                  value={formData.dob || inputFormattedDate}
                  onChange={handleChange}
                  type="date"
                  maxLength={35}
                  name="dob"
                  id="dob"
                  placeholder="Enter dob"
                  className="phone-input-style"
                  // disabled={inputFormattedDate}
                />
                {errors.dob && <span className="error">{errors.dob}</span>}
              </div>
              <div className="login-input">
                <p>Service status of Employee</p>

                <SingleSelect
                  data={serviceData}
                  onChange={handleChangeService}
                  value={service || patientData?.data?.designation}
                  placeholder="Select Service"
                  id="service"
                  disabled={patientData?.data?.designation}
                />
                {errors.service && (
                  <span className="error">{errors.service}</span>
                )}
              </div>
              <div className="login-input">
                <p> Employee ID (6 digit SAMADHAN ID)</p>
                <InputField
                  type="text"
                  // maxLength={6}
                  id="employeeId"
                  name="employeeId"
                  placeholder="Enter Your Employee ID"
                  className="phone-input-style"
                  value={
                    formData.employeeId ||
                    patientData?.data?.members?.[0]?.employeeId
                  }
                  onChange={handleChange}
                  disabled={patientData?.data?.members?.[0]?.employeeId}
                />
                {errors.employeeId && (
                  <span className="error">{errors.employeeId}</span>
                )}
              </div>
              <div className="login-input">
                <p>Alternate Number</p>
                <InputField
                  type="number"
                  maxLength={10}
                  id="alternatePhone"
                  name="alternatePhone"
                  placeholder="Enter Emergency Contact Number"
                  className="phone-input-style"
                  value={formData.alternatePhone}
                  // onChange={handleChange}
                  onChange={handleChange1}
                />
                {/* {errors.alternatePhone && (
                  <span className="error">{errors.alternatePhone}</span>
                )} */}
              </div>
              <div className="login-input">
                <p>Current Address</p>
                <InputField
                  type="text"
                  id="currentAddress"
                  name="currentAddress"
                  placeholder="Enter Your Current Address"
                  className="phone-input-style"
                  value={
                    formData.currentAddress ||
                    patientData?.data?.address?.address1
                  }
                  onChange={handleChange}
                />
                {errors.currentAddress && (
                  <span className="error">{errors.currentAddress}</span>
                )}
              </div>
              <div className="login-input">
                <p>Pincode</p>
                <SingleSelect
                  data={pincodeData}
                  onChange={handleChangePincode}
                  value={pincodedata || patientData?.data?.address?.pincode}
                  placeholder="Enter Pincode"
                  id="pincode"
                  disabled={patientData?.data?.address?.pincode}
                />

                {errors.pincodedata && (
                  <span className="error">{errors.pincodedata}</span>
                )}
              </div>
              <div style={{ paddingTop: "10px" }}>
                <Checkbox onChange={onChange}>
                  I agree to the Terms & Conditions
                </Checkbox>
              </div>
              <div className="login-btn-section" style={{ paddingTop: "15px" }}>
                <Button
                  className="login-btn"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={isCheked ? false : true}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        width={210}
        onCancel={handleCancel}
        // closeIcon={<span style={{ fontSize: "16px" }}>✖</span>}
        closable={false}
        style={{ top: "50%", transform: "translateY(-50%)" }}
        maskClosable={false}
      >
        <div style={{ padding: "5px", textAlign: "center" }}>
          <div>
            <img
              className="user-icon-header"
              width={80}
              src={rightIcon}
              alt="user-icon"
            />
          </div>
          <h5>Your Submission has been sent Successfully</h5>
          <Button className="done-btn" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>

      {/* Error Modal */}
      <Modal
        visible={isErrorModalOpen}
        onOk={handleErrorOk}
        onCancel={handleErrorOk}
        closable={false}
        style={{ top: "50%", transform: "translateY(-50%)" }}
        maskClosable={false}
        width={250}
      >
        <div style={{ padding: "5px", textAlign: "center" }}>
          <div>
            <img
              className="user-icon-header"
              width={80}
              src={errorIcon}
              alt="user-icon"
            />
          </div>
          {/* <h5>There was an error submitting the form. Please try again.</h5> */}
          <h5>Employee Id already exists.</h5>
          <Button className="done-btn" onClick={handleErrorOk}>
            Close
          </Button>
        </div>
      </Modal>
      {/* tc Modal */}
      <Modal
        visible={isTcModalOpen}
        onOk={handleTcOk}
        onCancel={handleTcOk}
        closable={false}
        style={{ top: "50%", transform: "translateY(-50%)" }}
        maskClosable={false}
      >
        <div style={{ padding: "5px" }}>
          <h5>
            <p>
              {" "}
              1. I want to avail home delivery of Medicines on credit slip from
              Apollo Pharmacies Ltd.{" "}
            </p>
            <p>
              2. I understand that the details that I furnish here will be
              shared with Apollo Pharmacies Ltd and outside the Bank. I agree
              that I give my consent for sharing this information.
            </p>
            <p>
              3. I want the eligible medicines to be issued under the credit
              slip facility.
            </p>
            <p>
              4. I submit that I will avail medicines only for personal use of
              self and eligible dependents.
            </p>
            <p>
              5. I will check the medicines for expiry date and provide
              appropriate confirmation at the time of delivery, including
              handing over the Prescription slip.
            </p>
          </h5>
          <div style={{ textAlign: "center" }}>
            <Button className="done-btn" onClick={handleTcOk}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PatientForm;
