import React, { useState, useEffect } from 'react'
import './SubDomain.css'
import Input from '../../../UIComponents/Input/Input'
import Button from '../../../UIComponents/Button/Button'
import SingleSelect from '../../../UIComponents/Select/SingleSelect'
import { toast } from '../../../UIComponents/Toast'
import { subDomainRegister, subDomainUpdate, subdomainUser, subDomainStyles, subDomainProfilePic } from '../../../redux/actions/admin/subDomian'
import { useDispatch, useSelector } from 'react-redux'


const data = [
    { id: "index", name: "Default" },
    { id: "indexblue", name: "Blue" },
    { id: "indexPurple", name: "Purple" },
    { id: "indexRed", name: "Red" },
    { id: "indexMaroon", name: "Maroon" },
    { id: "indexBrown", name: "Brown" },
    { id: "indexOrange", name: "Orange" },
    { id: "indexNavy", name: "Navy Blue" },
    { id: "indexOlive", name: "Olive" },
    { id: "indexCharcoal", name: "Charcoal" },
    { id: "indexMagenta", name: "Magenta Haze" },
    { id: "indexGreen", name: "Green" }];

const SubDomain = () => {
    const dispatch = useDispatch();

    const [domainName, setDomainName] = useState('');
    const [domainUpdate, setDomainUpdate] = useState('');
    const [domainUpdatePic, setDomainUpdatePic] = useState('');
    const [domainUpdateStyle, setDomainUpdateStyle] = useState('');
    const [domainUser, setDomianUser] = useState('');
    const [chooseStyle, setChooseStyle] = useState('');
    const [tagline, setTagline] = useState('');
    const [formData, setFormData] = useState();
    const [fileName, setFileName] = useState();



    const domaintoUpdate = useSelector(state => state.subDomian.domainUpdate)
    console.log("subDomain update", domaintoUpdate)


    useEffect(() => {
        dispatch(subDomainUpdate());
    }, []);

    const domains = domaintoUpdate?.domains?.map(item => item.subdomain)
    console.log("subdomain names", domains)

    let usersDr = []
    domaintoUpdate?.users?.map(item => {
        return usersDr.push({ id: item.id, name: item.name })
    })
    console.log("subdomainkh names", usersDr);

    const onChangeDomain = (e) => {
        setDomainName(e.target.value)
    }
    const onChangeUpdate = (value) => {
        setDomainUpdate(value)
        console.log(value);
    }

    const onChangeUser = (value) => {
        console.log("id", value)
        setDomianUser(value);
    }

    const onChangeUpdatePic = (value) => {
        setDomainUpdatePic(value)
    }
    const onChangeUpdateStyle = (value) => {
        setDomainUpdateStyle(value)
    }
    const onChangeColor = (value) => {
        setChooseStyle(value)
    }
    const onChangeTagline = (e) => {
        setTagline(e.target.value)
    }

    const onFileChange = (e) => {
        setFormData(e.target.files)
        const fileN = e.target.files[0].name;
        setFileName(fileN);
    };

    const onFileUpload = (e) => {
        setDomainUpdatePic("")
        e.preventDefault();
        const fileExtension = formData[0].name.split('.').pop();
        if (!domainUpdatePic) {
            toast.error("please select domain")
        }

        else if (fileExtension === "jpg" || fileExtension === "png") {
            const formDataOne = new FormData();
            formDataOne.append(
                'file',
                formData[0],
                formData[0].name
            );
            formDataOne.append('domain', domainUpdatePic)
            console.log("formData", formData)
            dispatch(subDomainProfilePic(formDataOne));

        }



    }

    // const handleImageUpload = (event) => {
    //     event.preventDefault();
    //     if(!domainUpdatePic){
    //         toast.error("please select domain")
    //     }
    //     else{
    //         const file = event.currentTarget["fileInput"].files[0];

    //         const formData = new FormData();
    //         formData.append("file", file);
    //         formData.append('domain', domainUpdatePic)
    //         dispatch(subDomainProfilePic(formData))
    //         console.log("formdata",formData)
    //         event.target.value = null;
    //     }
    //   };

    const register = (e) => {
        if (!domainName) {
            toast.error("please enter domain name")
        }
        else {
            e.preventDefault();
            dispatch(subDomainRegister(domainName))
            setDomainName("")
        }
    }

    const addUser = (e) => {
        if (!domainUpdate || !domainUser) {
            toast.error("Please select both the fields")
        }
        else {
            e.preventDefault();
            dispatch(subdomainUser(domainUpdate, domainUser))
            setDomainUpdate("")
            setDomianUser("")
        }
    }

    const styles = (e) => {
        if (!domainUpdateStyle && !chooseStyle && !tagline) {
            toast.error("All the Three Fields are required")
        }
        else if (!domainUpdateStyle) {
            toast.error("Please select domain")
        }
        else if (!chooseStyle) {
            toast.error("Please select Choose Style")
        }
        else if (!tagline) {
            toast.error("Please Fill Tagline")
        }
        else {
            e.preventDefault();
            dispatch(subDomainStyles(domainUpdateStyle, chooseStyle))
            setDomainUpdateStyle("")
            setChooseStyle("")
            setTagline("")
        }
    }

    return (
        <div className='sub-domain'>
            <div className='sub-domain-container'>
                <h2>Subdomain Register</h2>
                <p>Subdomain Name</p>
                <div className='domain-input-button'>
                    <Input className={"domain-input"} value={domainName} onChange={onChangeDomain} />
                    <Button className={"domain-button-one"} onClick={register} >REGISTER </Button>
                </div>
            </div>

            <div className='sub-domain-container2'>
                <div>
                    <p>Subdomain to Update</p>
                    <div className='register-dropdown'>
                        <SingleSelect data={domains} value={domainUpdate} onChange={onChangeUpdate} placeholder="Select domain you have registered " />
                    </div>
                </div>
                <div className='drop-down-sec'>
                    <p>User to be added</p>
                    <div className='register-dropdown'>
                        <SingleSelect placeholder="Select User " data={usersDr} value={domainUser} onChange={onChangeUser} />

                    </div>
                </div>
                <Button onClick={addUser} className={"domain-button"} >ADD </Button>

            </div>
            <div className='sub-domain-container2'>
                <div>
                    <p>Subdomain to Update</p>
                    <div className='register-dropdown'>
                        <SingleSelect data={domains} value={domainUpdatePic} onChange={onChangeUpdatePic} listHeight={160} placeholder="Select subdomain  " />
                    </div>
                </div>
                <div className='drop-down-sec'>
                    <p>Profile Picture</p>
                    <div className='register-profile-pic'>
                        <label className='file-upload' for="upload-photo">Choose a file</label>
                        <input type={'file'} id="upload-photo" onChange={onFileChange} hidden />
                        <Button className={"domain-button-upload"} onClick={onFileUpload}  >UPLOAD / UPDATE </Button>
                    </div>
                    <div className='image-name'>{fileName}</div>
                </div>

            </div>
            <div className='sub-domain-container2'>
                <div>
                    <p>Subdomain to Update</p>
                    <div className='register-dropdown'>
                        <SingleSelect data={domains} value={domainUpdateStyle} onChange={onChangeUpdateStyle} listHeight={100} placeholder="Select subdomain " />
                    </div>
                </div>
                <div className='drop-down-sec'>
                    <p>Choose Style</p>
                    <div className='register-dropdown'>
                        <SingleSelect data={data} placeholder="Select Style " value={chooseStyle} listHeight={100} onChange={onChangeColor} />
                    </div>

                </div>
                <div className='drop-down-sec'>
                    <p>Tagline</p>
                    <div className='domain-input-button'>
                        <Input className={"domain-input-two"} placeholder="Welcome" value={tagline} onChange={onChangeTagline} />
                        <Button className={"domain-button-one"} onClick={styles} >UPDATE </Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SubDomain