// Declare build time variable
declare const __TARGET_ENV__;

// __TARGET_ENV__ is a build-time variable that is injected to create
// all of the needed outputs

// Import instance of FirebaseApp from ./app
const firebase:FirebaseNamespace = require(__TARGET_ENV__ === 'node' ? './app-node' : './app');
require(__TARGET_ENV__ === 'node' ? './auth-node' : './auth')

if (__TARGET_ENV__ === 'node') {
  require('./server-auth-node');
  require('./database-node');

  var Storage = require('dom-storage');
  var XMLHttpRequest = require("xmlhttprequest").XMLHttpRequest;

  firebase.INTERNAL.extendNamespace({
    'INTERNAL': {
      'node': {
        'localStorage': new Storage(null, { strict: true }),
        'sessionStorage': new Storage(null, { strict: true }),
        'XMLHttpRequest': XMLHttpRequest
      }
    }
  });
}
if (__TARGET_ENV__ !== 'node') {
  require('./database');
  require('./storage');
}

if (__TARGET_ENV__ === 'react-native') {
  var AsyncStorage = require('react-native').AsyncStorage;
  firebase.INTERNAL.extendNamespace({
    'INTERNAL': {
      'reactNative': {
        'AsyncStorage': AsyncStorage
      }
    }
  });
}


if (__TARGET_ENV__ !== 'node' && 
    __TARGET_ENV__ !== 'react-native') {
  require('./messaging');
}

// Export the single instance of firebase
export default firebase;
