import * as types from '../../actionTypes';

const initialState = {
    doctorStatisticsInfo: [],
    error: {},
    loading: false,
    cronJobStatus: ''
}


export const doctorStatisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DOCTOR_STATISTICS_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.DOCTOR_STATISTICS_SUCCESS:
            console.log("action.payload", action.payload)
            return {
                ...state,
                doctorStatisticsInfo: action.payload,
                loading: false,
                error: {}
            }
        case types.DOCTOR_STATISTICS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case types.CRON_JOB_STATUS_SUCCESS:
            return {
                ...state,
                cronJobStatus: action.payload
            }
        case types.CRON_JOB_STATUS_FAIL:
            return {
                ...state,
                cronJobStatus: action.payload
            }

        default:
            return state;
    }
}