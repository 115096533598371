import React from 'react'
import './ObstetricUsgTest11.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import {TestReportRightSection} from '../TestReportRightSection'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import ReportFooter from '../ReportFooter'
import {field,updateReports} from '../../../../redux/actions/Diagnostics/diagnostics'

export const ObstetricUsgTest11 = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'OBSTETRIC ULTRASONOGRAPHY 11test')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'OBSTETRIC ULTRASONOGRAPHY 11test'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
        <div className='ObstetricUsgTest11-parent'>
              <div className='ObstetricUsgTest11-left'>
                  <div className='ObstetricUsgTest11-grid'>
                      <div className='ObstetricUsgTest11-1 ObstetricUsgTest11-pad' onClick={() => onLabelClicked("OBSERVATION")} >OBSERVATION</div>
                      <div className='ObstetricUsgTest11-2 ObstetricUsgTest11-text'><TextAreaAnt  name="OBSERVATION"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                      <span className='ObstetricUsgTest11-border'></span>
                      <div className=' ObstetricUsgTest11-pad' onClick={() => onLabelClicked("IMPRESSION")}>IMPRESSION</div>
                      <div className=' ObstetricUsgTest11-text'><TextAreaAnt  name="IMPRESSION"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active}/></div>
                      <span className='ObstetricUsgTest11-border1'></span>
                  </div>
              </div>
              <div>
                  <TestReportRightSection />
              </div>
            </div>
            <div className='ObstetricUsgTest11-foot'>
                <ReportFooter />
            </div>
            
    </div>
  )
}
