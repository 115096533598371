import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadMasterListValue } from '../../../../redux/actions/doctorEditMaster/masterListValue'
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice'
import { getDoctorListValue } from '../../../../redux/actions/opd/patientreport'
import Divider from '../../../../UIComponents/Divider/Divider'
import MultiSelect from '../../../../UIComponents/MultiSelect/MultiSelect'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import AddListModal from '../../../EditMasters/AddListModal'
import '../Diagnosis/Diagnosis.css'



function Diagnosis() {
    const dispatch = useDispatch()
    const diagnosisList = useSelector(state => state.opdrecord.patientreport.listValue.diagnosis)
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)

    const [openModal, setOpenModal] = useState(false)
    const [state, setState] = useState([])


    const onDiagnosisChange = (value) => {
        const arr = value.map(item => ({
            name: item
        }))
        dispatch(updateVisit({ ...visitInfo, diagnosis: arr }))
        console.log("value of changes diagnosis", value, visitInfo)
    }

    const addNewHandler = () => {
        setOpenModal(!openModal)
        dispatch(loadMasterListValue({ type: "diagnosis" }))
    }

    useEffect(() => {
        dispatch(getDoctorListValue({ type: "diagnosis" }))
    }, [])

    useEffect(() => {
        if (visitInfo?.diagnosis) {
            const filteredData = visitInfo?.diagnosis?.filter(item =>
                diagnosisList?.data?.list?.includes(item.name)
            )
            console.log("filteredData", filteredData)
            setState(filteredData)
        }
    }, [visitInfo?.diagnosis, diagnosisList?.data?.list])

    return (
        <div>
            <div>
                <div className='diagnosis-div'>
                    <NumberButton className="numberButton-8">8</NumberButton>
                    <div className='diagnosis-8-div'>
                        <h6>DIAGNOSIS</h6>
                        <p onClick={addNewHandler} className="add-new-classname">Add New</p>
                    </div>
                    <div className='diagnosis-antSelect'>
                        <MultiSelect data={diagnosisList?.data?.list} value={state?.map(item => item.name)} onChange={onDiagnosisChange} placeholder="Select Diagnosis" className={'diagnosis-multiselect'} />
                    </div>
                </div>
                <Divider />
            </div>
            <AddListModal visible={openModal} type="diagnosis" />
        </div>
    )
}

export default Diagnosis