import React from 'react'
import { useSelector } from 'react-redux/es/exports'

const PrescriptionProdcedure = () => {
  const prescriptionAnalysisInfo = useSelector(state => state.analytics.prescriptionAnalysisInfo)
  return (

    <div className='pres-procedure-first-table'>
      {prescriptionAnalysisInfo?.procedure?.map(item => (
        <div className="procedure-medicine-section">
          <div className='diagnosis-section'>
            <span className='procedure-medicine-type'>{item.name}</span>
            <span className="procedure-count">{item.value}</span>
          </div>


        </div>
      ))}

    </div>
  )
}

export default PrescriptionProdcedure