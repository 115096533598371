import { Modal } from "antd";
import React, { useEffect, useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "tui-color-picker/dist/tui-color-picker.css";
import "tui-image-editor/dist/tui-image-editor.css";
import calculateGrayLogo from "../../../images/calculate-gray.png";
import saveLogo from "../../../images/save-icon.png";
import { imageUrl, updateReports } from '../../../redux/actions/Diagnostics/diagnostics';
import Button from "../../../UIComponents/Button/Button";
import Input from '../../../UIComponents/Input/Input';
import './Cbc.css';
import { toast } from '../../../UIComponents/Toast';
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";


const ReportFooter = (props) => {
  const dispatch = useDispatch();
  const Url = useSelector(state => state?.diagnosticsInvoiceSummary?.imageUrls);
  console.log("url of image",Url);
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const getDiagnosticDatas = useSelector((state) => state?.diagnosticsInvoiceSummary?.getdiagnostic);
  const ReportNamee = useSelector((state) => state?.diagnosticsInvoiceSummary?.reportNames);
  const reportData = useSelector(state => state?.diagnosticsInvoiceSummary?.singleReport)
  const indexValueOfReport = useSelector((state) => state?.diagnosticsInvoiceSummary?.indexValue);

  const [singleData,setSingleData] = useState(reportData && reportData[0]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalTwoVisible,setIsModalTwoVisible] = useState(false)
  const [imageHead, setImageHead] = useState("");
  const [url, setUrl] = useState()
  const [loadedImage, setLoadedImage] = useState(false)


  const showModal = () => {
    setIsModalVisible(true);
    setIsModalTwoVisible(false)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const editorRef = useRef();

  const handleClickButton = () => {
    const arr = editorRef.current.getInstance().toDataURL().split(",");
    const imageUrlarray = []
    imageUrlarray.push(arr)  
    console.log("resultObj", imageUrlarray);

    let resultObj = {...singleData}
    resultObj = { ...singleData, image: imageUrlarray}
    getDiagnosticDatas.testReport[indexValueOfReport] = resultObj
    console.log("getDiagnosticDatas",getDiagnosticDatas);
    dispatch(updateReports(getDiagnosticDatas))
    setIsModalVisible(false);
  };

  const onImgHeadChange = (e) => {
    const filterData = reportDataFields?.testReport?.filter(item => item.test == ReportNamee)
    let resultComment = { ...filterData[0] }
    resultComment = { ...filterData[0], imagehead: e.target.value }
    getDiagnosticDatas.testReport[indexValueOfReport] = resultComment
    setImageHead(getDiagnosticDatas?.testReport?.[indexValueOfReport]?.imagehead)
    dispatch(updateReports(getDiagnosticDatas))
  }

  const onDeleteHandle = (index) => {
    dispatch(imageUrl(Url.filter((url, i) => i !== index)))
    const urlImage = Url.filter((item, i) => i !== index)
    let resultDeleted = { ...singleData }
    resultDeleted = { ...singleData, image: urlImage }
    getDiagnosticDatas.testReport[indexValueOfReport] = resultDeleted
    dispatch(updateReports(getDiagnosticDatas))
  }

  const onDeleteApi = (index) => {
    // console.log("inside data",reportData[0],index,getDiagnosticDatas);
    const filteredUrl = getDiagnosticDatas?.testReport[indexValueOfReport]?.image.filter((url, i) => i !== index)
    let resultObject = { ...getDiagnosticDatas?.testReport[indexValueOfReport] }
    resultObject  = { ...getDiagnosticDatas?.testReport[indexValueOfReport],image: filteredUrl }
    getDiagnosticDatas.testReport[indexValueOfReport] = resultObject
    dispatch(updateReports(getDiagnosticDatas))
  }

  const showImageModal = (item) => {
      setIsModalTwoVisible(true)
      const findUrl = reportData[0]?.image?.find((url) => url === item)
      setUrl(findUrl)
     };

  const handleImageCancel = () => {
      setIsModalTwoVisible(false)
     };
    
  // useEffect(()=>{
  //   setSingleData(reportData[0])
  // },[singleData,reportData])
  
  // const onChangeImageLoad = e => {
  //   const file = e?.target?.files[0];
  //   if (file) {
  //     var pattern = /image-*/;
  //     if (!file.type.match(pattern)) {
  //       toast.error("Invalid image format")
  //       console.log('Invalid image format');
  //       setLoadedImage(true)
  //       console.log("ued");
  //     } else {
  //       setLoadedImage(false)
  //       console.log("uesd");
  //     }
  //   }
  // }

  return (
    <div>
      <div className='cbc-add-image'>
        <div className='add-image-btn'>
          <Button onClick={showModal}><img width={20} src={calculateGrayLogo} alt="calculate-logo"/>Add Image</Button>
        </div>
        <div className='add-input'>
          <Input className='add-image-input' value={getDiagnosticDatas?.testReport?.[indexValueOfReport]?.imagehead} onChange={onImgHeadChange} placeholder="Comment" />
        </div>
      </div>
      <br />

      <Modal
        title="Image Processor"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={1150}>
        <div > 
          <p>Click load to select image file. Once edited click save to confirm edits. You can download image for your future reference as well</p>
          <div className='patient-history-save'>
            <Button onClick={handleClickButton}><img width={20} src={saveLogo} alt="save-logo" />Save</Button>
          </div>
          <ImageEditor ref={editorRef}
           includeUI={{
              loadImage: {
                path: '',
                name: 'ReportImage',
              },
              uiSize: {
                width: "92%",
                height: "700px"
              },
              menu: ["shape", "filter", "text", "rotate", 'crop', "flip"],
              initMenu: '',
              menuBarPosition: 'bottom',
              cssMaxWidth: 700,
              cssMaxHeight: 500,
              selectionStyle: {
              cornerSize: 20,
              rotatingPointOffset: 70
              } 
        }}
        usageStatistics={true}
        // onChange={onChangeImageLoad}
      />
        </div>
      </Modal>
      
      <div>
        <span className='Report-Image'>
          {Url.length == 0 && singleData ? getDiagnosticDatas?.testReport?.[indexValueOfReport]?.image?.map((item, index) => (
            <div className='Report-Img'>
              <Button onClick={() => onDeleteApi(index)}>Delete</Button>
              <br />
              <img src={item ? item : ''} onClick={() => showImageModal(item)} className="report-footer-img" alt="report-img"/>
              {console.log("rijrjirjijr",singleData?.image)}
            </div>
          )) : <></>}

          {/* {Url ? Url?.map((item, index) => (
            <div className='Report-Img'>
              <Button onClick={() => onDeleteHandle(index)}>Delete</Button>
              <br />
              <img src={item ? item : ''} onClick={() => showImageModal(item.key)} className="report-footer-img" alt="report-img"/>
            </div>
          )) : <></>} */}
        </span>
      </div>
      <Modal
        title=""
        visible={isModalTwoVisible}
           onCancel={handleImageCancel}
           height={1230}>
           <div>
            <div>
             <img style={{ "width": "auto", "height": "400px" }} src={url ? url : ""} alt="uploaded images" />
            </div>
           </div>
</Modal>
    </div>
  )
}

export default ReportFooter