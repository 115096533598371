import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { createToggle } from "./searchPatient";
import { client } from "../../../utils/axios";

export const resetPatientSearchAdv = (payload) => ({
  type: types.RESET_SEARCH_PATIENT_ADV,
  payload,
});

const searchPatientAdvLoading = () => ({
  type: types.SEARCH_PATIENT_ADV_LOADING,
});

const searchPatientAdvSuccess = (data) => ({
  type: types.SEARCH_PATIENT_ADV_SUCCESS,
  payload: data,
});
const searchPatientAdvFail = (error) => ({
  type: types.SEARCH_PATIENT_ADV_FAIL,
  payload: error,
});


export const searchAdvPatient = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(searchPatientAdvLoading());
    client
      .post(
        `/patient/find-patient-advance`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        if (resp.data === "") {
          toast.error("Patient not found!");
        } else {
          dispatch(searchPatientAdvSuccess(resp.data));
          toast.success("Search results listed");
        }
      })
      .catch((error) => {
        dispatch(createToggle(""));
        console.log("error1111", error);
        dispatch(searchPatientAdvFail(error));
        toast.error("Patient nor found!");
      });
  };
};
