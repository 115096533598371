import React from 'react'
import './Hbsag.css'
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from './TestReportRightSection'
import ReportFooter from './ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../redux/actions/Diagnostics/diagnostics'
import {field,} from '../../../redux/actions/Diagnostics/diagnostics'

export const Hbsag = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")
    
    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      setInputValue(value)
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'HBsAg')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item.test === 'HBsAg'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
      reportDataFields.testReport[index]  = filterData[0]
      console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
      setInputValue(reportDataFields?.testReport[index]?.fields)
      dispatch(updateReports(reportDataFields))
    }
    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))    }
  return (
    <div>
     <div className='hbsag-parent'>
              <div className='hbsag-left'>
                  <div className='hbsag-grid'>
                      <div className='hbsag1 hbsag-pad' onClick={() => onLabelClicked("Hepatitis B Surface Ag (HBsAg)")} >Hepatitis B Surface Ag (HBsAg)</div>
                      <div className='hbsag2 hbsag-text'><TextAreaAnt name="Hepatitis B Surface Ag (HBsAg)"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                      <span className='hbsag-border'></span>
                  </div>
              </div>
              <div>
                  <TestReportRightSection />
              </div>
            </div>
            <div className='hbsag-foot'>
                <ReportFooter />
            </div>
            <div className='hbsag-method'>
              <h4>Method:</h4>
              <p>IMMUNOCHROMATOGRAPHY</p>
            </div>
       
    </div>
  )
}
