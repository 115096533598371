import * as types from "../../actionTypes";

const initialState = {
	twilioToken: {},
	error: {},
	loading: false,
};

export const videoCallReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.MAKE_VIDEO_CALL_LOADING:
			return {
				...state,
				loading: false,
			};
		case types.MAKE_VIDEO_CALL_SUCCESS:
			return {
				...state,
				twilioToken: action.payload,
				loading: false,
			};
		case types.MAKE_VIDEO_CALL_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case types.SEND_SMS_LOADING:
			return {
				...state,
				loading: false,
			};
		case types.SEND_SMS_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case types.SEND_SMS_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case types.RESET_TWILIO_TOKEN:
			return {
				...state,
				twilioToken: {},
				loading: false,
			};
		default:
			return state;
	}
};
