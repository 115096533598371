import React from 'react'
import './SemenAnylasis.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const SemenAnylasis = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'SEMEN ANALYSIS')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'SEMEN ANALYSIS'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
   filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))      }
  return (
    <div>     
    <div className='semen-parent'>
      <div className='semen-left'>
          <div className='semen-grid'>
              <div className='semen1 semen-pad' onClick={() => onLabelClicked("MICTURATING CYSTO")}>PERIOD OF ABSTAINENCE</div>
              <div className='semen2 semen-text'><TextAreaAnt name="PERIOD OF ABSTAINENCE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("METHOD OF COLLECTION")}>METHOD OF COLLECTION</div>
              <div className='semen-text '><TextAreaAnt name="METHOD OF COLLECTION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border2'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("QUANTITY")}>QUANTITY</div>
              <div className='semen-text'><TextAreaAnt name="QUANTITY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border3'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("CONSISTENCY")}>CONSISTENCY</div>
              <div className='semen-text '><TextAreaAnt name="CONSISTENCY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border4'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("COLOUR")}>COLOUR</div>
              <div className='semen-text '><TextAreaAnt name="COLOUR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border5'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("ODOUR")}> ODOUR</div>
              <div className='semen-text '><TextAreaAnt name="ODOUR" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border6'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" LIQUIFICATION TIME")}> LIQUIFICATION TIME</div>
              <div className='semen-text '><TextAreaAnt name="LIQUIFICATION TIME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border7'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("REACTION {PH}")}> REACTION {"{PH}"}</div>
              <div className='semen-text '><TextAreaAnt name="REACTION {PH}" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border8'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("SPERM COUNT")}> SPERM COUNT</div>
              <div className='semen-text '><TextAreaAnt name="SPERM COUNT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border9'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("RAPID")}> RAPID</div>
              <div className='semen-text '><TextAreaAnt name="RAPID" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange}  /></div>
              <span className='semen-border10'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("SLUGGISH")}> SLUGGISH</div>
              <div className='semen-text '><TextAreaAnt name="SLUGGISH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border11'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("NON MOTILE")}> NON MOTILE</div>
              <div className='semen-text '><TextAreaAnt name="NON MOTILE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border12'></span>
              <div className='semen-pad' onClick={() => onLabelClicked(" NORMAL FORM")}> NORMAL FORM</div>
              <div className='semen-text '><TextAreaAnt name="NORMAL FORM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border13'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("ABNORMAL FORM")}> ABNORMAL FORM</div>
              <div className='semen-text '><TextAreaAnt name="ABNORMAL FORM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border14'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("PUS CELLS")}> PUS CELLS</div>
              <div className='semen-text '><TextAreaAnt name="PUS CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border15'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("EPITHELIAL CELLS")}> EPITHELIAL CELLS</div>
              <div className='semen-text '><TextAreaAnt name="EPITHELIAL CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[15]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border16'></span>
              <div className='semen-pad' onClick={() => onLabelClicked("RED BLOOD CELLS")}> RED BLOOD CELLS</div>
              <div className='semen-text '><TextAreaAnt name="RED BLOOD CELLS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[16]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
              <span className='semen-border17'></span>
          </div>
      </div>
      <div>
          <TestReportRightSection />
      </div>
    </div>
    <div className='semen-foot'>
        <ReportFooter />
    </div>
</div>
  )
}
