import React,{useState} from 'react';
import '../Button/Button.css'
import 'antd/dist/antd.css';
import '../../UIComponents/UIComponents.css'


function CommonButton({children,className,onClick,disabled}) {
  return (
    <div>
      <button disabled={disabled} onClick={onClick} className={className}>{children}</button>
    </div>
  )
}

export default CommonButton