import { DatePicker } from 'antd';
import moment from "moment";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux/es/exports";
import { patientAudio, resetVisitAudioData } from '../../../redux/actions/opd/patientaudio';
import Input from '../../../UIComponents/Input/Input';
import "./Audio.css";

import { Button } from "antd";
import { useReactMediaRecorder } from "react-media-recorder";

import { faCloudArrowUp, faMicrophone, faMicrophoneSlash, faSave, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactAudioPlayer from 'react-audio-player';
import ViewAudio from './ViewAudio';



const Audio = () => {
  let dispatch = useDispatch();

  const active = useSelector(state => state.opdrecord.patientreport.active)
  const patientOpdInvoiceInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
  const opdAudioList = useSelector(state => state.opdrecord.patientaudio.getAudio.audio)
  console.log("opdAudioList", opdAudioList)
  const visitAudio = useSelector(state => state.opdrecord.patientaudio.visitAudio)
  console.log("visitAudioDDD", visitAudio)
  const patientInfo = useSelector(state => state?.opdrecord?.patientreport?.patientInfo)
  console.log("patientopdinfo", patientInfo)

  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [audioDate, setAudioDate] = useState();
  const [audioDelete, setAudioDelete] = useState();
  
 

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    echoCancellation: true
  });
  const UploadFile = async () => {
    var formData = new FormData();
    let blob = await fetch(mediaBlobUrl).then((r) => r.blob());
    console.log("fdsfsdfsdf", blob)
    formData.append("recording", blob, blob.type);
    formData.append("visitId", patientOpdInvoiceInfo?._id);
    dispatch(patientAudio(formData))
  };


  const deleteAudio = () => {
    setAudioDelete('');
    console.log("delete");
  }
  const selectAudioDate = (dateString) => {
    setAudioDate(moment(dateString?._d).format("YYYY-MM-DD"))
  }



  return (
    <div>
      {active ? <ViewAudio /> :
        <div className="audio-container">
          <h3 className="audio-header">AUDIO</h3>
          <div className='audio-bg'>
            <div className='p-name-audio-date'>
              <div className='patient-audio-name'>
                <span>Audio for</span>

                <Input value={patientInfo.name} />

                <div>
                  <h3> discussion with  {patientInfo.name}</h3>
                  {visitAudio !== null ? <ReactAudioPlayer src={visitAudio?.audio} controls /> : <audio src={mediaBlobUrl} controls loop />}
                  {/* {visitAudio?.audio ? <ReactAudioPlayer src={visitAudio?.audio} controls  /> : ""} */}

                </div>

                <div>
                  <p>Past Notes</p>
                  {opdAudioList?.map(item => (
                    <>
                      <ReactAudioPlayer
                        src={item.url}
                        controls
                      />

                      <p>{moment(item.visitStart).format('YYYY-MM-DD')}</p>

                    </>

                  ))}

                </div>


              </div>


              <div className='patient-audio-name-date'>

                <DatePicker className="audio-inp2" value={moment(audioDate)} onChange={selectAudioDate} />
              </div>
              <div className='patient-audio-name-cancel'>
                <div>
                  <label
                    style={{
                      fontSize: "18px",
                      fontWeight: "Normal"
                    }}
                    htmlFor="icon-button-file"
                  >
                    <h3 style={{ marginLeft: "15px", fontWeight: "normal" }}>
                      {/* Press the Start to record */}
                    </h3>

                    <div style={{ display: "flex" }} className='all-icons'>
                      <div className='time-sec'>
                        <span className="minute">{minute}</span>
                        <span>:</span>
                        <span className="second">{second}</span>
                      </div>
                      <div className="audio-ui">

                        {startRecording ? <button className='cancel-audio'
                          style={{

                          }}
                          onClick={stopTimer}
                        >
                          <FontAwesomeIcon icon={faXmarkCircle} size="2x" title="cancel Record" />
                        </button>
                          : ''}

                        <b></b>
                      </div>
                      <Button
                        style={{


                          border: "none",
                          marginLeft: "15px",
                          fontSize: "1rem",
                          cursor: "pointer",
                          borderRadius: "5px",
                          fontWeight: "bold",

                          color: "red",
                          transition: "all 300ms ease-in-out",
                          transform: "translateY(0)"
                        }}
                        onClick={() => {
                          if (!isActive) {
                            dispatch(resetVisitAudioData(null))
                            startRecording();

                          } else {
                            pauseRecording();
                          }

                          setIsActive(!isActive);
                        }}
                      >
                        {isActive ? <div><FontAwesomeIcon icon={faMicrophone} size="2x" title="Stop Record" /></div> : <div><FontAwesomeIcon icon={faMicrophoneSlash} size="2x" title="Start Record" /> </div>
                        }
                      </Button>
                      <Button
                        style={{

                          border: "none",

                          marginLeft: "15px",
                          fontSize: "1rem",
                          cursor: "pointer",
                          color: "green",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          transition: "all 300ms ease-in-out",
                          transform: "translateY(0)"
                        }}
                        onClick={() => {
                          pauseRecording();


                          stopRecording();




                          setTimeout(() => {
                            UploadFile();
                            stopTimer();

                          }, 10)

                          setIsActive(!isActive);
                        }}
                      >
                        <FontAwesomeIcon icon={faSave} size="2x" title="Save Record" />

                      </Button>
                      <Button
                        style={{
                          padding: "4.5rem rem",
                          border: "none",
                          marginTop: "1px",
                          fontSize: "1rem",
                          cursor: "pointer",
                          borderRadius: "50%",
                          fontWeight: "bold",

                          color: "black"
                        }}
                        onClick={() => {
                          UploadFile();
                          stopTimer();
                        }}
                        variant="contained"
                        color="default"
                      //   startIcon={<CloudUploadIcon />}
                      >
                        <FontAwesomeIcon icon={faCloudArrowUp} title="Upload" size="2x" />
                      </Button>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='audio-rec'>
          </div>
          {/* <AudioRecord /> */}
        </div>}
    </div>

  );
};

export default Audio;
