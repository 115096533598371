import React, { useState, useEffect } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../Tests/Tests.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import MultiSelect from '../../../../UIComponents/MultiSelect/MultiSelect'
import { getDoctorListValue } from '../../../../redux/actions/opd/patientreport';
import { useSelector, useDispatch } from 'react-redux'
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice'
import { loadMasterListValue } from '../../../../redux/actions/doctorEditMaster/masterListValue'
import AddListModal from '../../../EditMasters/AddListModal'


function Tests() {
    const dispatch = useDispatch()
    const labTestsList = useSelector(state => state.opdrecord.patientreport.listValue.labtests)
    console.log("lab tests", labTestsList);

    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)

    const [openModal, setOpenModal] = useState(false)
    const [state, setState] = useState([])


    const onTestsChange = (value) => {
        const arr = value.map(item => ({
            name: item
        }))
        dispatch(updateVisit({ ...visitInfo, lab: arr }))
    }
    const onChangeTestForNextVisit = (value) => {
        const arr = value.map(item => ({
            name: item
        }))
        dispatch(updateVisit({ ...visitInfo, labNextVisit: arr }))
    }

    const getSelectedLabTestNextVisit = () => {
        if (visitInfo.labNextVisit && visitInfo.labNextVisit.length) {

            return visitInfo.labNextVisit.map(arr => arr.name)
        }
        return []
    }

    const getSelectedLabTest = () => {
        if (visitInfo.lab && visitInfo.lab.length) {
            return visitInfo.lab.map(arr => arr.name)
        }
        return []
    }

    useEffect(() => {
        dispatch(getDoctorListValue({ type: "labtests" }))
    }, [])

    const addNewHandler = () => {
        setOpenModal(!openModal)
        dispatch(loadMasterListValue({ type: 'labtests' }))
    }

    return (
        <div>
            <div>
                <div className='tests-div'>
                    <NumberButton className="numberButton-10">10</NumberButton>
                    <div className='tests-10-div'>
                        <h6>TESTS</h6>
                        <p onClick={addNewHandler} className="add-new-classname">Add New</p>
                    </div>
                    <div className='tests-antSelect'>
                        <MultiSelect data={labTestsList?.data?.list} value={getSelectedLabTest()} onChange={onTestsChange} placeholder="Select Tests" className={'tests-multiselect'} />
                    </div>
                </div>
                <div className='tests-div'>
                    <NumberButton className="numberButton-10">10.1</NumberButton>
                    <div className='tests-10-div'>
                        <h6>TESTS For Next Visit</h6>
                    </div>
                    <div className='tests-antSelect'>
                        <MultiSelect data={labTestsList?.data?.list} value={getSelectedLabTestNextVisit()} onChange={onChangeTestForNextVisit} placeholder="Select Tests For Next Visit" className={'tests-multiselect'} />
                    </div>
                </div>
                <Divider />
            </div>
            <AddListModal visible={openModal} type="labtests" />
        </div>
    )
}

export default Tests