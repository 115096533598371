import React from 'react'
import { DatePicker } from 'antd';
import SingleSelect from '../../../UIComponents/Select/SingleSelect';
import Button from '../../../UIComponents/Button/Button';
import './Patientstats.css';
import InvoiceTable from './InvoiceTable';
import Invoicelogo from '../../../images/Invoicelogo.png';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { clearDropDownData, hideDataSuccess, patientStats } from '../../../redux/actions/analytics/analytics';
import moment from 'moment';
import { toast } from '../../../UIComponents/Toast'
import { clearDataSuccess } from '../../../redux/actions/analytics/analytics';
import calenderLogo from '../../../images/calender.svg';  

const data = [
    {
        id: "1",
        name: "No data"
    },

];

const Prescriptionanalysis = () => {

    const { RangePicker } = DatePicker;
    let dispatch = useDispatch();
    const patientStatsTable = useSelector(state => state.analytics.patientStatsInfo)
    // console.log("patientStatsTable", patientStatsTable)
    const hide = useSelector(state => state.analytics.hideData)
    const clear = useSelector(state => state.analytics.clearData)
    const dropDownInfo = useSelector(state => state.analytics.clearDropDowns)

    // const [consultant, setConsultant] = useState("")



    const handleDateChange = (date, dateString) => {
       
        dispatch(clearDataSuccess({startDate:dateString[0],endDate:dateString[1]}))

    }

    const handleConsultantChange = (value) => {
        dispatch(clearDropDownData({ ...dropDownInfo, consultant: value }))
    }



    const onGeneratePatientStatsClick = () => {
        if (clear.startDate===null && clear.endDate===null) {
            toast.error("Please select the dates")
            dispatch(hideDataSuccess(false))

        } else {
            dispatch(patientStats(clear.startDate,clear.endDate))
            dispatch(hideDataSuccess(true))
        }

    }


    return (
        <div>
            <div className="prescriptionanalysis-top-section">
                <div className="top-left-right-sec">
                    <div className="prescription-top-left-sec">
                        <div className="from-to-date">
                            <p className='date-title'>DATES</p>
                            <RangePicker className='pres-select-date' onChange={handleDateChange} value={clear.startDate !==null?[moment(clear.startDate), moment(clear.endDate)]:[null,null]} />
                            <img className='analytics-calender' src={calenderLogo} alt='calender'/>
                        </div>
                        <div className="prescript-consultant">
                            <p className="consultant-title">CONSULTANT </p>
                            <SingleSelect data={data} width="100%" onChange={handleConsultantChange} value={dropDownInfo?.consultant}/>
                        </div>
                        <div className="prescript-date"  >
                            <Button className="pres-generate-btn" onClick={onGeneratePatientStatsClick}>GENERATE</Button>
                        </div>
                    </div>
                </div>
            </div>
            {hide ?
                <div>
                    <img className="invoice-logo" src={Invoicelogo} alt="Invoicelogo" />

                    <p className='date-range'><b>Date Range:</b> {clear.startDate} to {clear.endDate}</p>
                    <InvoiceTable />
                </div>
                : ""}

            <br></br>
        </div>
    )
}

export default Prescriptionanalysis