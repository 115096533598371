import React from 'react'
import './Hb.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const Hb = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const singleReport = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic?.testReport)
  const setDiagnosticData = useSelector(state => state?.diagnosticsInvoiceSummary)
  const [ inputValue, setInputValue ] = useState("")


  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'Hb')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'Hb'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
        <div className='hb-parent'>
            <div className='hb-left'>
                <div className='hb-grid'>
                    <div className='hb1 h-pad'></div>
                    <div className='hb2 h-pad'>HAEMOGLOBIN (Hb) %</div>
                    <div className='hb3'><Input type='number' className={'hb-input'} name="HAEMOGLOBIN (Hb) %" onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='hb4 h-pad'>%</div>
                    <div className='hb5 h-pad'>M: 13-18 / F: 12-16</div>
                    <div className='hb6 h-pad'></div>
                    <span className='hb-border'></span>
                </div>
            </div>
                <div>
                    <TestReportRightSection />
                </div>
        </div>
        <div className='growth-btn'
        >
              <button className='gwrt'>No Growth</button>
              <button className='gwrt'>Escherichia Coli</button>
              <button className='gwrt'>Klebsiella Pneumoniae</button>
              <button className='gwrt'>Pseudomonas Aeruginosa</button>
              <button className='gwrt'>Staphylococcus</button>
        </div>
        <div className='hb-foot'>
              <ReportFooter />
        </div>
    </div>
  )
}
