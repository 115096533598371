import React from 'react'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './AbdomenAndPelvic.css'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function AbdomenAndPelvic() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic) 
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item.test === 'ULTRASONOGRAPHIC FINDINGS OF WHOLE ABDOMEN & PELVIC ORGANS')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'ULTRASONOGRAPHIC FINDINGS OF WHOLE ABDOMEN & PELVIC ORGANS'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0].fields.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
     dispatch(field({fieldKey:value}))   
  }

  return (
    <div>
      <div className="abdomenAndPelvic-parent">
        <div className="abdomenAndPelvic-left">
          <div className="abdomenAndPelvic-grid">
            <div className="abdomenAndPelvic-1 abdomenAndPelvic-pad" onClick={() => onLabelClicked("LIVER")}>LIVER</div>
            <div className="abdomenAndPelvic-2 abdomenAndPelvic-text">
              <TextAreaAnt name="LIVER" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border"></span>

            <div className=" abdomenAndPelvic-pad" onClick={() => onLabelClicked("GALL BLADDER")}>GALL BLADDER</div>
            <div className=" abdomenAndPelvic-text">
              <TextAreaAnt name="GALL BLADDER" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border1"></span>

            <div className=" abdomenAndPelvic-pad" onClick={() => onLabelClicked("PV/CBD")}>PV/CBD</div>
            <div className=" abdomenAndPelvic-text">
              <TextAreaAnt name="PV/CBD" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border2"></span>

            <div className=" abdomenAndPelvic-pad" onClick={() => onLabelClicked("SPLEEN")}>SPLEEN</div>
            <div className=" abdomenAndPelvic-text">
              <TextAreaAnt name="SPLEEN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border3"></span>

            <div className=" abdomenAndPelvic-pad" onClick={() => onLabelClicked("PANCREAS")}>PANCREAS</div>
            <div className=" abdomenAndPelvic-text">
              <TextAreaAnt name="PANCREAS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border4"></span>

            <div className="abdomenAndPelvic-pad" onClick={() => onLabelClicked("KIDNEYS")}>KIDNEYS</div>
            <div className="abdomenAndPelvic-text">
              <TextAreaAnt name="KIDNEYS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border5"></span>

            <div className=" abdomenAndPelvic-pad" onClick={() => onLabelClicked("URINARY BLADDER")}>URINARY BLADDER</div>
            <div className=" abdomenAndPelvic-text">
              <TextAreaAnt name="URINARY BLADDER" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border6"></span>

            <div className=" abdomenAndPelvic-pad" onClick={() => onLabelClicked("UTERUS")}>UTERUS</div>
            <div className=" abdomenAndPelvic-text">
              <TextAreaAnt name="UTERUS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border7"></span>

            <div className=" abdomenAndPelvic-pad" onClick={() => onLabelClicked("ADNEXA")}>ADNEXA</div>
            <div className=" abdomenAndPelvic-text">
              <TextAreaAnt name="ADNEXA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border8"></span>

            <div className=" abdomenAndPelvic-pad" onClick={() => onLabelClicked("IMPRESSION")}>IMPRESSION</div>
            <div className=" abdomenAndPelvic-text">
              <TextAreaAnt name="IMPRESSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="abdomenAndPelvic-border9"></span>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="abdomenAndPelvic-foot">
        <ReportFooter />
      </div>
    </div>
  )
}

export default AbdomenAndPelvic
