import { state } from '@antv/g2plot/lib/adaptor/common';
import * as types from '../../actionTypes';

const initialState = {
    patientOpdInvoiceInfo: {},
    patientEmpId:{},
    serviceDetails: [],
    error: {},
    loading: false
}


export const patientOpdInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_OPD_PATIENT_INVOICE_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_OPD_PATIENT_INVOICE_SUCCESS:
            return {
                ...state,
                patientOpdInvoiceInfo: action.payload,
                loading: false,
            }
        case types.GET_OPD_PATIENT_INVOICE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                patientOpdInvoiceInfo: {}
            }
        case types.ADD_SERVICE_DETAILS:
            console.log("patient invoice payload", action.payload)
            return {
                ...state,
                 patientOpdInvoiceInfo: action.payload,
                serviceDetails: action.payload,
                loading: false,
            }
        case types.UPDATE_VISIT:
            return {
                ...state,
                patientOpdInvoiceInfo: action.payload,
                loading: false,
            }
        case types.ADD_EMP_CARD_POST:
            return {
                ...state,
                patientOpdInvoiceInfo : action.payload,
                loading: false,
            }
        default:
            return state;
    }
} 