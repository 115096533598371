import React from 'react'
import TextArea from 'antd/lib/input/TextArea'
import './PatientProfile.css'
import Input from '../../../UIComponents/Input/Input'
import { TestReportRightSection } from './TestReportRightSection'
import ReportFooter from './ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field, updateReports } from '../../../redux/actions/Diagnostics/diagnostics'

export const PatientProfile = () => {
    const dispatch =  useDispatch()
    const [ inputValue, setInputValue ] = useState("")
    const [ tableValueOne, setTableValueOne ] = useState("")
    const [ tableValueTwo, setTableValueTwo ] = useState("")
    const [ tableValueThree, setTableValueThree ] = useState("")
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const testReport = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic?.testReport)

  
    const onInputChange = (e, i,type) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      const filterData = reportDataFields?.testReport?.filter(item => item.test === 'PATIENT PROFILE')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item.test === 'PATIENT PROFILE'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map((item,index) => {
        if(item.particular === name && index === i){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
    
    const onLabelClicked = (value) => {
      dispatch(field({fieldKey:value}))    
    }
    
    const onTableChange = (e) => {
      const { value, name } = e.target;
      console.log('onTableChange function', name, value)
      const [table, type, index] = name.split('.')
      console.log('table, type, index', table, type, index)
      const filterData = reportDataFields?.testReport?.filter(item => item.test === 'PATIENT PROFILE')
      let ind = 0
      reportDataFields.testReport.map((item, i) => {
        if(item.test === 'PATIENT PROFILE'){
          ind = i
        }
        return item
      })

      console.log("index",ind,filterData[0].table);
      const fill = filterData[0].table.filter(item => item.particular == table)
      console.log("filtered filter",fill);
      const finaldataaa = fill[0]?.data.map((item,i) => {
        if(i == index){
          console.log("finallllldaata",item,index,i);
          return {
            ...item,
            [type]: value
          }
        }return item
      })
      fill[0].data = finaldataaa
      console.log("finaldata",reportDataFields); 
      setTableValueOne(reportDataFields?.testReport?.[ind]?.table?.[0]?.data)
      setTableValueTwo(reportDataFields?.testReport?.[ind]?.table?.[1]?.data)
      setTableValueThree(reportDataFields?.testReport?.[ind]?.table?.[2]?.data)
      dispatch(updateReports(reportDataFields))
      console.log("for the value",reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data);
    }
return (
    <div>
        <div className='patient-parent'>
            <div className='patient-left'>
                <div className='pp1 pro-pad' onClick={() => onLabelClicked("PATIENT OCCUPATION")} >PATIENT OCCUPATION</div>
                <div className='pp2 '><TextArea name="PATIENT OCCUPATION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,0)} /></div>       
                <span className='profile-line-one'></span>
                <div className='pp3 pro-pad'  onClick={() => onLabelClicked("HUSBAND OCCUPATION")} >HUSBAND OCCUPATION</div>
                <div className='pp4'><TextArea name="HUSBAND OCCUPATION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active } onChange={(e) => onInputChange(e,1)} /></div>
                <span className='profile-line-two'></span>
                <div className='pp5 pro-pad' onClick={() => onLabelClicked("MARRIED LIFE")} >MARRIED LIFE</div>
                <div className='pp6 '><TextArea name="MARRIED LIFE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,2)} /></div>
                <span className='profile-line-three'></span>
                <div className='pp7 pro-pad' onClick={() => onLabelClicked("COHABITATION")} > COHABITATION</div>
                <div className='pp8 '><TextArea name="COHABITATION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,3)} /></div>
                <span className='profile-line-four'></span>
                <div className='pp9 pro-pad' onClick={() => onLabelClicked("PRIMARY")} > PRIMARY</div>
                <div className='pp10 '><TextArea name="PRIMARY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,4)} /></div>
                <span className='profile-line-five'></span>
                <div className='pp11 pro-pad'  onClick={() => onLabelClicked("SECONDARY")} >SECONDARY</div>
                <div className='pp12 '><TextArea name="SECONDARY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,5)} /></div>
                <span className='profile-line-six'></span>
                <div className='pp13 pro-pad' onClick={() => onLabelClicked("RPL")} >RPL</div>
                <div className='pp14 '><TextArea name="RPL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,6)} /></div>
                <span className='profile-line-seven'></span>
                <div className='pp15 pro-pad' onClick={() => onLabelClicked("DURATION OF INFERTINITY")} >DURATION OF INFERTINITY</div>
                <div className='pp16'><TextArea name="DURATION OF INFERTINITY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,7)} /></div>
                <span className='profile-line-eight'></span>
                <div className='pp17 pro-pad' onClick={() => onLabelClicked("GRAVIDA")} >GRAVIDA</div>
                <div className='pp18'><TextArea name="GRAVIDA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,8)} /></div>
                <span className='profile-line-nine'></span>
                <div className='pp19 pro-pad' onClick={() => onLabelClicked("PARITY")} >PARITY</div>
                <div className='pp20 '><TextArea name="PARITY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,9)} /></div>
                <span className='profile-line-ten'></span>
                <div className='pp21 pro-pad' onClick={() => onLabelClicked("LIVE ISSUE ")} >LIVE ISSUE</div>
                <div className='pp22 '><TextArea name="LIVE ISSUE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[10]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,10)} /></div>
                <span className='profile-line-eleven'></span>
                <div className='pp23 pro-pad' onClick={() => onLabelClicked("PRETERM OR NEONATAL DEATH")} >PRETERM OR NEONATAL DEATH</div>
                <div className='pp24 '><TextArea name="PRETERM OR NEONATAL DEATH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[11]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,11)} /></div>
                <span className='profile-line-twelve'></span>
                <div className='pp25 pro-pad' onClick={() => onLabelClicked("ABORTIONS")}>ABORTIONS</div>
                <div className='pp26 '><TextArea name="ABORTIONS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[12]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,12)} /></div>
                <span className='profile-line-thirteen'></span>
                <div className='pp27 pro-pad' onClick={() => onLabelClicked("NORMAL DELIVERY")} >NORMAL DELIVERY</div>
                <div className='pp28 '><TextArea name="NORMAL DELIVERY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[13]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,13)} /></div>
                <span className='profile-line-fourteen'></span>
                <div className='pp29 pro-pad' onClick={() => onLabelClicked("ANY DYSMENORRHOEA")} >ANY DYSMENORRHOEA </div>
                <div className='pp30 '><TextArea name="ANY DYSMENORRHOEA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[14]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,14)} /></div>
                <span className='profile-line-fifteen'></span>
                <div className='pp31 pro-pad' onClick={() => onLabelClicked("MENORRHAGIA")} >MENORRHAGIA</div>
                <div className='pp32 '><TextArea name="MENORRHAGIA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[15]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,15)} /></div>
                <span className='profile-line-sixteen'></span>
                <div className='pp33 pro-pad' onClick={() => onLabelClicked("POLYMENORRHOEA")}>POLYMENORRHOEA</div>
                <div className='pp34 '><TextArea name="POLYMENORRHOEA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[16]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,16)} /></div>
                <span className='profile-line-seventeen'></span>
                <div className='pp35 pro-pad'  onClick={() => onLabelClicked("PROLONGED CYCLES")} >PROLONGED CYCLES</div>
                <div className='pp36'><TextArea name="PROLONGED CYCLES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[17]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,17)} /></div>
                <span className='profile-line-eighteen'></span>
                <div className='pp37 pro-pad' onClick={() => onLabelClicked("AMENORRHEA")} >AMENORRHEA</div>
                <div className='pp38'><TextArea name="AMENORRHEA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[18]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,18)} /></div>
                <span className='profile-line-nineteen'></span>
                <div className='pp39 pro-pad' onClick={() => onLabelClicked("DYSPAREUNIA")} >DYSPAREUNIA</div>
                <div className='pp40'><TextArea name="DYSPAREUNIA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[19]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,19)} /></div>
                <span className='profile-line-twenty'></span>
                <div className='pp41 pro-pad' onClick={() => onLabelClicked("DIABETES")} >DIABETES </div>
                <div className='pp42'><TextArea name="DIABETES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[20]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,20)} /></div>
                <span className='profile-line-twentyone'></span>
                <div className='pp43 pro-pad' onClick={() => onLabelClicked("HYPERTENSION")} >HYPERTENSION </div>
                <div className='pp44'><TextArea name="HYPERTENSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[21]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,21)} /></div>
                <span className='profile-line-twentytwo'></span>
                <div className='pp45 pro-pad'  onClick={() => onLabelClicked("HYPOTHYROID")}>HYPOTHYROID </div>
                <div className='pp46'><TextArea name="HYPOTHYROID" value={reportDataFields?.testReport?.[indexValue]?.fields?.[22]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,22)} /></div>
                <span className='profile-line-twentythree'></span>
                <div className='pp45-1 pro-pad'  onClick={() => onLabelClicked("HYPERTHYROID")}>HYPERTHYROID</div>
                <div className='pp46-1'><TextArea name="HYPERTHYROID" value={reportDataFields?.testReport?.[indexValue]?.fields?.[23]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,23)} /></div>
                <span className='profile-line-twentythree-1'></span>
                <div className='pp47 pro-pad' onClick={() => onLabelClicked("MEDICINE FOR ANY OTHER DISEASE")} >MEDICINE FOR ANY OTHER DISEASE </div>
                <div className='pp48'><TextArea name="MEDICINE FOR ANY OTHER DISEASE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[24]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,24)} /></div>
                <span className='profile-line-twentyfour'></span>
                <div className='pp49 pro-pad' onClick={() => onLabelClicked("DURATION OF MEDICINE INTAKE")} >DURATION OF MEDICINE INTAKE </div>
                <div className='pp50'><TextArea name="DURATION OF MEDICINE INTAKE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[25]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,25)} /></div>
                <span className='profile-line-twentyfive'></span>
                <div className='pp51 pro-pad' onClick={() => onLabelClicked("FAMILY HISTORY IS SIGNIFICANT")} >FAMILY HISTORY IS SIGNIFICANT</div>
                <div className='pp52'><TextArea name="FAMILY HISTORY IS SIGNIFICANT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[26]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,26)} /></div>
                <span className='profile-line-twentysix'></span>
                <div className='pp53 pro-pad' onClick={() => onLabelClicked("DIABETES")} >DIABETES </div>
                <div className='pp54'><TextArea name="DIABETES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[27]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,27)} /></div>
                <span className='profile-line-twentyseven'></span>
                <div className='pp55 pro-pad'  onClick={() => onLabelClicked("HYPERTENSION")}>HYPERTENSION</div>
                <div className='pp56'><TextArea name="HYPERTENSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[28]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,28)} /></div>
                <span className='profile-line-twentyeight'></span>
                <div className='pp57 pro-pad' onClick={() => onLabelClicked("ANY ANOMELY")} >ANY ANOMELY </div>
                <div className='pp58'><TextArea name="ANY ANOMELY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[29]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,29)} /></div>
                <span className='profile-line-twentynine'></span>
                <div className='pp59 pro-pad' onClick={() => onLabelClicked("DISABILITY")} >DISABILITY   </div>
                <div className='pp60'><TextArea name="DISABILITY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[30]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,30)} /></div>
                <span className='profile-line-thirty'></span>
                <div className='pp61 pro-pad' onClick={() => onLabelClicked("FREQUENCY")} >FREQUENCY</div>
                <div className='pp62'><TextArea name="FREQUENCY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[31]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,31)} /></div>
                <span className='profile-line-thirtyone'></span>
                <div className='pp63 pro-pad' onClick={() => onLabelClicked("HSG")} >HSG </div>
                <div className='pp64'><TextArea name="HSG" value={reportDataFields?.testReport?.[indexValue]?.fields?.[32]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,32)} /></div>
                <span className='profile-line-thirtytwo'></span>
                <div className='pp65 pro-pad' onClick={() => onLabelClicked("LAPAROSCOPY")} >LAPAROSCOPY </div>
                <div className='pp66'><TextArea name="LAPAROSCOPY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[33]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,33)} /></div>
                <span className='profile-line-thirtythree'></span>
                <div className='pp67 pro-pad' onClick={() => onLabelClicked("HYSTEROSCOPY")} >HYSTEROSCOPY </div>
                <div className='pp68'><TextArea name="HYSTEROSCOPY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[34]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,34)} /></div>
                <span className='profile-line-thirtyfour'></span>
                <div className='pp69 pro-pad' onClick={() => onLabelClicked("CC")} >CC </div>
                <div className='pp70'><TextArea name="CC" value={reportDataFields?.testReport?.[indexValue]?.fields?.[35]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,35)} /></div>
                <span className='profile-line-thirtyfive'></span>
                <div className='pp71 pro-pad' onClick={() => onLabelClicked("LATROZOLE ")} >LATROZOLE</div>
                <div className='pp72'><TextArea name="LATROZOLE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[36]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,36)} /></div>
                <span className='profile-line-thirtysix'></span>
                <div className='pp73 pro-pad' onClick={() => onLabelClicked("INJECTIONS ")} > INJECTIONS</div>
                <div className='pp74'><TextArea name="INJECTIONS" value={reportDataFields?.testReport?.[indexValue]?.fields?.[37]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,37)} /></div>
                <span className='profile-line-thirtyseven'></span>
                <div className='pp75 pro-pad' onClick={() => onLabelClicked(" IUI WITH DRUGS AND NO. OF TIMES")} >IUI WITH DRUGS AND NO. OF TIMES</div>
                <div className='pp76'><TextArea name="IUI WITH DRUGS AND NO. OF TIMES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[38]?.result} disabled={!reportDataFields.active}  onChange={(e) => onInputChange(e,38)} /></div>
                <span className='profile-line-thirtyeight'></span>
                <div className='pp77 pro-pad' onClick={() => onLabelClicked("DOCTOR CLINIC NAME")} >DOCTOR CLINIC NAME</div>
                <div className='pp78'><TextArea name="DOCTOR CLINIC NAME" value={reportDataFields?.testReport?.[indexValue]?.fields?.[39]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,39)} /></div>
                <span className='profile-line-thirtynine'></span>
                <div className='pp79 pro-pad'  onClick={() => onLabelClicked("TOBACCO")} >TOBACCO </div>
                <div className='pp80'><TextArea name="TOBACCO" value={reportDataFields?.testReport?.[indexValue]?.fields?.[40]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,40)} /></div>
                <span className='profile-line-fourty'></span>
                <div className='pp81 pro-pad' onClick={() => onLabelClicked("ALCOHOL")} >ALCOHOL </div>
                <div className='pp82'><TextArea name="ALCOHOL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[41]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,41)} /></div>
                <span className='profile-line-fourtyone'></span>
                <div className='pp83 pro-pad' onClick={() => onLabelClicked("SMOKING")} >SMOKING </div>
                <div className='pp84'><TextArea name="SMOKING" value={reportDataFields?.testReport?.[indexValue]?.fields?.[42]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,42)} /></div>
                <span className='profile-line-fourtytwo'></span>
                <div className='pp85 pro-pad' onClick={() => onLabelClicked("RELEVANT FAMILY HISTORY")} >RELEVANT FAMILY HISTORY</div>
                <div className='pp86'><TextArea name="RELEVANT FAMILY HISTORY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[43]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,43)} /></div>
                <span className='profile-line-fourtythree'></span>
                <div className='pp87 pro-pad' onClick={() => onLabelClicked("MEDICAL HISTORY")} >MEDICAL HISTORY </div>
                <div className='pp88'><TextArea name="MEDICAL HISTORY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[44]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,44)} /></div>
                <span className='profile-line-fourtyfour'></span>
                <div className='pp89 pro-pad' onClick={() => onLabelClicked("SURGICAL HISTORY")}  >SURGICAL HISTORY</div>
                <div className='pp90'><TextArea name="SURGICAL HISTORY" value={reportDataFields?.testReport?.[indexValue]?.fields?.[45]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,45)} /></div>
                <span className='profile-line-fourtyfive'></span>
                <div className='pp91 pro-pad' onClick={() => onLabelClicked("ERECTILE DYSFUNCTION")} >ERECTILE DYSFUNCTION</div>
                <div className='pp92'><TextArea name="ERECTILE DYSFUNCTION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[46]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,46)} /></div>
                <span className='profile-line-fourtysix'></span>
                <div className='pp93 pro-pad' onClick={() => onLabelClicked("PREMATURE EJACULATION")} >PREMATURE EJACULATION</div>
                <div className='pp94'><TextArea name="PREMATURE EJACULATION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[47]?.result} disabled={!reportDataFields.active} onChange={(e) => onInputChange(e,47)} /></div>
                <span className='profile-line-fourtyseven'></span>
                <div  className='pp95 pro-pad1'>TYPE OF SURGERY</div>
                <div className='pp96 pro-pad1'>DATE OF SURGERY</div>
                <div className='pp97 pro-pad1'>OUTCOME</div>
                <span className='profile-line-fourtyeight'></span>
                <div className='pp98'><Input type='number' className='patient-input' name="SURGICAL HISTORY.typeofsurgery.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.typeofsurgery} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp99'><Input type='number' className='patient-input' name="SURGICAL HISTORY.dateofsurgery.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.dateofsurgery} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp100'><Input type='number' className='patient-input' name="SURGICAL HISTORY.outcome.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[0]?.outcome} disabled={!reportDataFields.active} onChange={onTableChange}/></div>
                <span className='profile-line-fourtynine'></span>
                <div className='pp101'><Input type='number' className='patient-input' name="SURGICAL HISTORY.typeofsurgery.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.typeofsurgery} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp102'><Input type='number' className='patient-input' name="SURGICAL HISTORY.dateofsurgery.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.dateofsurgery} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp103'><Input type='number' className='patient-input' name="SURGICAL HISTORY.outcome.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[1]?.outcome} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <span className='profile-line-fifty'></span>
                <div className='pp104'><Input type='number' className='patient-input' name="SURGICAL HISTORY.typeofsurgery.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.typeofsurgery} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp105'><Input type='number' className='patient-input' name="SURGICAL HISTORY.dateofsurgery.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.dateofsurgery} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp106'><Input type='number' className='patient-input' name="SURGICAL HISTORY.outcome.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[2]?.outcome} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <span className='profile-line-fiftyone'></span>
                <div className='pp107'><Input type='number' className='patient-input' name="SURGICAL HISTORY.typeofsurgery.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.typeofsurgery} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp108'><Input type='number' className='patient-input' name="SURGICAL HISTORY.dateofsurgery.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.dateofsurgery} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp109'><Input type='number' className='patient-input' name="SURGICAL HISTORY.outcome.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[0]?.data?.[3]?.outcome} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <span className='profile-line-fiftytwo'></span>
                <div className='pp110 pro-pad2'>CENTRE</div>
                <div className='pp111 pro-pad2'>DRUGS USED</div>
                <div className='pp112 pro-pad2'>EGG RET</div>
                <div className='pp113 pro-pad2'>EMBRYOS TRANS</div>
                <div className='pp114 pro-pad2'>FROZEN</div>
                <div className='pp115 pro-pad2'>RESULT</div>
                <span className='profile-line-fiftythree'></span> 
                <div className='pp116'><Input type='number' className='patient-input' name="IVF.centre.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.centre} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp117'><Input type='number' className='patient-input' name="IVF.drugsused.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.drugsused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp118'><Input type='number' className='patient-input' name="IVF.eggretrieved.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.eggretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp119'><Input type='number' className='patient-input' name="IVF.embryostransferred.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.embryostransferred} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp120'><Input type='number' className='patient-input' name="IVF.frozen.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.frozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp121'><Input type='number' className='patient-input' name="IVF.result.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[0]?.result} disabled={!reportDataFields.active} onChange={onTableChange} /></div> 
                <span className='profile-line-fiftyfour'></span> 
                <div className='pp122'><Input type='number' className='patient-input' name="IVF.centre.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.centre} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp123'><Input type='number' className='patient-input' name="IVF.drugsused.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.drugsused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp124'><Input type='number' className='patient-input' name="IVF.eggretrieved.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.eggretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp125'><Input type='number' className='patient-input' name="IVF.embryostransferred.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.embryostransferred} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp126'><Input type='number' className='patient-input' name="IVF.frozen.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.frozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp127'><Input type='number' className='patient-input' name="IVF.result.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[1]?.result} disabled={!reportDataFields.active} onChange={onTableChange} /></div> 
                <span className='profile-line-fiftyfive'></span> 
                <div className='pp128'><Input type='number' className='patient-input' name="IVF.centre.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.centre} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp129'><Input type='number' className='patient-input' name="IVF.drugsused.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.drugsused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp130'><Input type='number' className='patient-input' name="IVF.eggretrieved.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.eggretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp131'><Input type='number' className='patient-input' name="IVF.embryostransferred.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.embryostransferred} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp132'><Input type='number' className='patient-input' name="IVF.frozen.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.frozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp133'><Input type='number' className='patient-input' name="IVF.result.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[2]?.result} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <span className='profile-line-fiftysix'></span> 
                <div className='pp134'><Input type='number' className='patient-input' name="IVF.centre.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.centre} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp135'><Input type='number' className='patient-input' name="IVF.drugsused.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.drugsused} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp136'><Input type='number' className='patient-input' name="IVF.eggretrieved.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.eggretrieved} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp137'><Input type='number' className='patient-input' name="IVF.embryostransferred.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.embryostransferred} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp138'><Input type='number' className='patient-input' name="IVF.frozen.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.frozen} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp139'><Input type='number' className='patient-input' name="IVF.result.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[1]?.data?.[3]?.result} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <span className='profile-line-fiftyseven'></span>
                <div className='pp140 pro-pad2'>RPT DATE</div>
                <div className='pp141 pro-pad2'>TOTAL COUNT</div>
                <div className='pp142 pro-pad2'>MOTILITY</div>
                <div className='pp143 pro-pad2'>RAPID LINEAR MOTILITY</div>
                <div className='pp144 pro-pad2'>MORPHOLOGY</div>
                <div className='pp145 pro-pad2'>TESA</div>
                <span className='profile-line-fiftyeight'></span> 
                <div className='pp146'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.reportdate.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[0]?.reportdate} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp147'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.totalcount.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[0]?.totalcount} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp148'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.motility.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[0]?.motility} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp149'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.rapidmotility.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[0]?.rapidmotility} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp150'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.morphology.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[0]?.morphology} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp151'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.tesa.0" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[0]?.tesa} disabled={!reportDataFields.active} onChange={onTableChange} /></div> 
                <span className='profile-line-fiftynine'></span> 
                <div className='pp152'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.reportdate.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[1]?.reportdate} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp153'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.totalcount.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[1]?.totalcount} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp154'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.motility.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[1]?.motility} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp155'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.rapidmotility.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[1]?.rapidmotility} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp156'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.morphology.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[1]?.morphology} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp157'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.tesa.1" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[1]?.tesa} disabled={!reportDataFields.active} onChange={onTableChange} /></div> 
                <span className='profile-line-sixty'></span> 
                <div className='pp158'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.reportdate.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[2]?.reportdate} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp159'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.totalcount.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[2]?.totalcount} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp160'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.motility.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[2]?.motility} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp161'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.rapidmotility.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[2]?.rapidmotility} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp162'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.morphology.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[2]?.morphology} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp163'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.tesa.2" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[2]?.tesa} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <span className='profile-line-sixtyone'></span> 
                <div className='pp164'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.reportdate.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[3]?.reportdate} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp165'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.totalcount.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[3]?.totalcount} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp166'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.motility.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[3]?.motility} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp167'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.rapidmotility.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[3]?.rapidmotility} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp168'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.morphology.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[3]?.morphology} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <div className='pp169'><Input type='number' className='patient-input' name="SEMEN ANALYSIS.tesa.3" value={reportDataFields?.testReport?.[indexValue]?.table?.[2]?.data?.[3]?.tesa} disabled={!reportDataFields.active} onChange={onTableChange} /></div>
                <span className='profile-line-sixtytwo'></span>               
            </div>
            <div className='patient-right'>
                <TestReportRightSection />
            </div>
        </div>
        <div className='patient-pro-foot'>
            <ReportFooter />
        </div>
    </div>
  )
}
