import React from 'react'
import './DoNotUse.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function DoNotUse() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'DO NOT USE')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'DO NOT USE'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  
  const onLabelClicked = (value) =>{
        dispatch(field({fieldKey:value}))      }

  return (
    <div>
      <div className="doNotUse-parent">
        <div className="doNotUse-left">
          <div className="doNotUse-grid">
            <div className="doNotUse-1 doNotUse-pad" onClick={() => onLabelClicked("Method of Collection")}>Method of Collection</div>
            <div className="doNotUse-2 doNotUse-text">
              <TextAreaAnt name="Method of Collection" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="doNotUse-border"></span>

            <div className=" doNotUse-pad" onClick={() => onLabelClicked("Abstinence")}>Abstinence</div>
            <div className=" doNotUse-text">
              <TextAreaAnt name="Abstinence" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="doNotUse-border1"></span>

            <div className=" doNotUse-pad" onClick={() => onLabelClicked("Time of Collection")}>Time of Collection</div>
            <div className=" doNotUse-text">
              <TextAreaAnt name="Time of Collection" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="doNotUse-border2"></span>

            <div className=" doNotUse-pad" onClick={() => onLabelClicked("Method of Collection")}>Color</div>
            <div className=" doNotUse-text">
              <TextAreaAnt name="Color" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="doNotUse-border3"></span>

            <div className=" doNotUse-pad" onClick={() => onLabelClicked("Viscosity")}>Viscosity</div>
            <div className=" doNotUse-text">
              <TextAreaAnt name="Viscosity" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="doNotUse-border4"></span>

            <div className="doNotUse-pad" onClick={() => onLabelClicked("Odour")}>Odour</div>
            <div className="doNotUse-text">
              <TextAreaAnt name="Odour" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="doNotUse-border5"></span>

            <div className=" doNotUse-pad" onClick={() => onLabelClicked("Volume")}>Volume</div>
            <div className=" doNotUse-text">
              <TextAreaAnt name="Volume" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="doNotUse-border6"></span>

            <div className=" doNotUse-pad" onClick={() => onLabelClicked("Liquefaction Time")}>Liquefaction Time</div>
            <div className=" doNotUse-text">
              <TextAreaAnt name="Liquefaction Time" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>

            <span className="doNotUse-border7"></span>

            <div className=" doNotUse-pad"></div>
            <div className=" doNotUse-no-data">No Data
              
            </div>
            <span className="doNotUse-border8"></span>

            <div className=" doNotUse-pad"></div>
            <div className=" doNotUse-no-data">No Data
              
            </div>
            <span className="doNotUse-border9"></span>
            
            <div className=" doNotUse-pad"></div>
            <div className=" doNotUse-no-data">No Data
              
            </div>
            <span className="doNotUse-border10"></span>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="doNotUse-foot">
        <ReportFooter />
      </div>
    </div>
  )
}

export default DoNotUse
