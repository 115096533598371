import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

export const resetSearchPatient = (payload) => ({
  type: types.RESET_SEARCH_PATIENT,
  payload,
});
export const createToggle = (payload) => ({
  type: types.CREATE_TOGGLE,
  payload,
});

export const clearSearchPatientSuccess = (data) => ({
  type: types.CLEAR_SEARCH_PATIENT_SUCCESS,
  payload: data,
});

const searchPatientLoading = () => ({
  type: types.SEARCH_PATIENT_LOADING,
});

const searchPatientSuccess = (data) => ({
  type: types.SEARCH_PATIENT_SUCCESS,
  payload: data,
});
const searchPatientFail = (error) => ({
  type: types.SEARCH_PATIENT_FAIL,
  payload: error,
});

export const searchPatient = (data) => {
  console.log("testing key", data);
  return function (dispatch) {
    dispatch(searchPatientLoading());
    client
      .post(
        `/patient/find-patient`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        if (resp.data === "") {
          toast.error("Patient not found!");
        } else {
          console.log("maksksk", resp.data[0].id);
          dispatch(searchPatientSuccess(resp.data));
          toast.success("Search results listed");
          // dispatch(printPatientProfile({ patientId: resp.data[0].id }))
        }
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(createToggle(""));
        dispatch(searchPatientFail(error));
        toast.success("Patient not found!");
      });
  };
};
