import React from 'react'
import './BsfBspp.css'
import Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

 const BsfBspp = () => {
  const dispatch =  useDispatch()
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'BSF BSPP')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'BSF BSPP'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }

  return (
    <div>
        <div className='bsfbspp-main'>
            <div className='bsfbspp-container'>
                <div className='bsfbspp-grid-container'>
                <div className='padd burea-1'>FASTING BLOOD SUGAR EXAMINATION</div>
                    <div className='padd burea-2'>FASTING PLASMA GLUCOSE</div>
                    <Input className='aptinputs burea-3' type="number" name="FASTING PLASMA GLUCOSE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
                    <div className='padd burea-4'>mg/dl</div>
                    <div className='padd burea-5'>60-110</div>
                    <div className='padd burea-6'></div>
                    <span className='bil-border1'></span>
                    <div className='padd burea-7'>POST LUNCH BLOOD SUGAR EXAMINATION</div>
                    <div className='padd burea-8'>POST PRANDIAL PLASMA GLUCOSE</div>
                    <div className='burea-9'><Input className='aptinputs ' type="number" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}  name="POST PRANDIAL PLASMA GLUCOSE" onChange={onInputChange} /></div>
                    <div className='padd burea-10'>mg/dl</div>
                    <div className='padd burea-11'>60-150</div>
                    <div className='padd burea-12'></div>
                    <span className='bil-border2'></span>
                </div>
            </div>
            <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
        </div>
        <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
            <div className='abg-footer'>
                <br></br>
                <p className='abg-note'>Method:</p>
                <p className='abg-hct'>GLUCOSE OXIDASE PEROXIDASE. [GOD/POD]</p>
            </div>
    </div>
  )
}


export default BsfBspp