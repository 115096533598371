import React from 'react'
import './MpAntigen.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import {TestReportRightSection} from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field,updateReports} from '../../../../redux/actions/Diagnostics/diagnostics'

export const MpAntigen = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'MP ANTIGEN')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'MP ANTIGEN'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }

  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }
  return (
    <div>
        <div className='MpAntigen-parent'>
              <div className='MpAntigen-left'>

                  <div className='MpAntigen-grid'>
                      <div className='MpAntigen-1 MpAntigen-pad' onClick={() => onLabelClicked("MALARIA PARASITE")} >MALARIA PARASITE</div>
                      <div className='MpAntigen-2 MpAntigen-text'><TextAreaAnt name="MALARIA PARASITE"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                      <span className='MpAntigen-border'></span>
                      
                  </div>
              </div>
              <div>
                  <TestReportRightSection />
              </div>
            </div>
            <div className='MpAntigen-foot'>
                <ReportFooter />
            </div>
            <div className='MpAntigen-method'>
              <p><b>Malaria P.vivax/P.Falciparum Positive/Negative Malaria antigen rapid Diagnostic kit. </b></p>
            </div>
    </div>
  )
}
