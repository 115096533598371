import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import './SideNav.css'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import opdIcon from '../../images/opd-icon.png'
import appointmentsIcon from '../../images/appointments-icon.png'
import diagnosticsIcon from '../../images/diagnostics-icon.png'
import analyticsIcon from '../../images/analytics-icon.png'
import callsIcon from '../../images/calls-icon.png'
import profileIcon from '../../images/profile-icon.png'
import editmastersIcon from '../../images/editmasters-icon.png'
import editmedicinesIcon from '../../images/editmedicine-icon.png'
import activeOpd from '../../images/opd-active.svg'
import activeAppointments from '../../images/appointments-active.svg'
import activeDiagnostics from '../../images/diagnostics-active.svg'
import activeCalls from '../../images/calls-active.svg'
import activeProfile from '../../images/profile-active.svg'
import activeEditmedicine from '../../images/editmedicine-active.svg'
import activeAnalytics from '../../images/analytics-active.svg'
import activeEditmasters from '../../images/editmasters-active.svg'
import { addMedicineOpd, showOpdReport } from '../../redux/actions/opd/patientreport';
import { useDispatch } from 'react-redux';
import { clearDropDownData, clearDataSuccess, hideDataSuccess } from "../../redux/actions/analytics/analytics";
import { getDiagnosticProfile, getDiagnostics } from '../../redux/actions/Diagnostics/diagnostics'
import { storeMasterListValue } from '../../redux/actions/doctorEditMaster/masterListValue';
import { getPatientAppointmet } from '../../redux/actions/appointments/getPatient';
import { selectedIndex } from '../../redux/actions/profile';
import { resetGetPatient } from '../../redux/actions/appointments/getPatient';

const { Sider } = Layout
const SideNav = (props) => {

    const myDomainInfo = useSelector(state => state.login.myDomainInfo)
    console.log("myDomainInfo", myDomainInfo)
    const dispatch = useDispatch()

    const changeColor = (path) => {
        document.documentElement.style.setProperty(`--variablename`, myDomainInfo?.DomainStyles?.buttonColor ? myDomainInfo?.DomainStyles?.buttonColor : "#00576E");
        if (myDomainInfo?.DomainStyles?.buttonColor) {
            if (window && window.location.pathname === path)
                return {
                    background: "#ffffff",
                    color: "#000000",
                }

        } else {
            if (window && window.location.pathname === path)
                return {
                    background:"#FDC132",
                    color: "#000000",
                }

        }

    }

    const handleClick = () => {
        dispatch(showOpdReport(false))
        dispatch(resetGetPatient());
        dispatch(hideDataSuccess(false))
        dispatch(clearDataSuccess({
            startDate: null,
            endDate: null
        }))
        dispatch(selectedIndex({}))
        dispatch(addMedicineOpd([]))
         dispatch(clearDropDownData({ doctors: "", mode: "", location: "", consultant: "" }))
    }

    const editMastersHandler = () => {
        dispatch(storeMasterListValue())
    }

    return (
        <div>
            <Layout>
                <Sider style={{ background: myDomainInfo?.DomainStyles?.buttonColor ? myDomainInfo?.DomainStyles?.buttonColor : "#00576E" }}>
                    <div className="sider-section" >
                        <Link to="/opd" onClick={() => handleClick()}> <p style={changeColor("/opd")}><span>{changeColor("/opd") ? <img src={activeOpd} alt="active-opd-icon" /> : <img src={opdIcon} alt="opdBrandIcon" />}</span>OPD Record</p></Link>
                        <Link to="/appointments" onClick={() => handleClick()}><p style={changeColor("/appointments")}><span> {changeColor("/appointments") ? <img src={activeAppointments} alt="active-appointments" /> : <img src={appointmentsIcon} alt="appointments-brand-icon" />}</span>Appointments</p></Link>
                        <Link to="/diagnostics" onClick={() => handleClick()} >  <p style={changeColor("/diagnostics")}><span> {changeColor("/diagnostics") ? <img src={activeDiagnostics} alt="active-diagnostics" /> : <img src={diagnosticsIcon} alt="appointments-brand-icon" />}</span><span>Diagnostics</span></p></Link >
                        <Link to="/analytics" onClick={() => handleClick()}> <p style={changeColor("/analytics")}><span>{changeColor("/analytics") ? <img src={activeAnalytics} alt="active-analytics" /> : <img src={analyticsIcon} alt="appointments-brand-icon" />}</span>Analytics</p></Link >
                        <Link to="/calls" onClick={() => handleClick()}> <p style={changeColor("/calls")}><span>{changeColor("/calls") ? <img src={activeCalls} alt="active-calls" /> : <img src={callsIcon} alt="appointments-brand-icon" />}</span>Calls</p></Link >
                        <Link to="/profile" onClick={() => handleClick()}> <p style={changeColor("/profile")}><span>{changeColor("/profile") ? <img src={activeProfile} alt="active-profile" /> : <img src={profileIcon} alt="appointments-brand-icon" />}</span>Profile</p></Link >
                        <Link to="/editmedicines" onClick={() => handleClick()}><p style={changeColor("/editmedicines")}><span>{changeColor("/editmedicines") ? <img src={activeEditmedicine} alt="active-medicine" /> : <img src={editmedicinesIcon} alt="appointments-brand-icon" />}</span>Edit Medicine</p></Link>
                        <Link to="/editmasters" onClick={editMastersHandler}> <p style={changeColor("/editmasters")}><span>{changeColor("/editmasters") ? <img src={activeEditmasters} alt="active-matsers" /> : <img src={editmastersIcon} alt="appointments-brand-icon" />}</span>Edit Masters</p></Link >
                    </div>
                </Sider>
            </Layout>
        </div>
    )
}

export default SideNav
