import * as types from "../../actionTypes";

const initialState = {
  saveDiagnostic: "",
  error: {},
  loading: false,
};

export const saveDiagnosticReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_DIAGNOSTIC_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SAVE_DIAGNOSTIC_SUCCESS:
      return {
        ...state,
        saveDiagnostic: action.payload,
        loading: false,
        error: {},
      };
    case types.SAVE_DIAGNOSTIC_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        saveDiagnostic: [],
      };
    case types.RESET_DIAGNOSTIC_SUCCESS_DATA:
      return {
        ...state,
        loading: false,
        saveDiagnostic: "",
        error: {},
      };
    default:
      return state;
  }
};
