import React from 'react'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './VitalityTestReport.css'
import {field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'

export const VitalityTestReport = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
  
    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))    }

    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
      setInputValue(value)
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'VITALITY TEST REPORT')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'VITALITY TEST REPORT'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
   
  return (
    <div>
        <div className='vitality-main'>
            <div className='vitality-container'>
                <div className='vitality-grid-container'>
                    <div className='vital1 padd' onClick={() => onLabelClicked("Total Number of Sperms Counted")}  >Total Number of Sperms Counted</div>
                    <div className='vital-text1'><TextAreaAnt name="Total Number of Sperms Counted" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='iui-border1'></span>
                    <div className='vital2 padd' onClick={() => onLabelClicked("Total Number of Live Sperms")} >Total Number of Live Sperms</div>
                    <div className='vital-text2'><TextAreaAnt name="Total Number of Live Sperms" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='iui-border2'></span>
                    <div className='vital3 padd' onClick={() => onLabelClicked("Total Number of Dead Sperms")} >Total Number of Dead Sperms</div>
                    <div className='vital-text3'><TextAreaAnt name="Total Number of Dead Sperms" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='iui-border3'></span>
                    <div className='vital4 padd' onClick={() => onLabelClicked("Vitality %")} >Vitality %</div>
                    <div className='vital-text4'><TextAreaAnt name="Vitality %" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active}  onChange={onInputChange} /></div>
                    <span className='iui-border4'></span>
                    <div className='vital5 padd' onClick={() => onLabelClicked("Lower Reference value for Vitality % (WHO 5th Edition)")} >Lower Reference value for Vitality % (WHO 5th Edition)</div>
                    <div className='vital-text5'><TextAreaAnt name="Lower Reference value for Vitality % (WHO 5th Edition)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active}  onChange={onInputChange} /></div>
                    <span className='iui-border5'></span>
                    <div className='vital6 padd' onClick={() => onLabelClicked("Result")} >Result</div>
                    <div className='vital-text6'><TextAreaAnt name="Result" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                    <span className='iui-border6'></span>
                </div>
            </div>
            <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
        </div>
        <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
    </div>
  )
}
