import * as types from '../../actionTypes';

const initialState = {
    searchAdvPatient: [],
    error: {},
    loading: false,
}



export  const searchAdvPatientReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEARCH_PATIENT_ADV_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.SEARCH_PATIENT_ADV_SUCCESS:
            console.log("actionddddd",action.payload)
            return {
                ...state,
                searchAdvPatient: action.payload,
                loading: false,
                error: {}
            }
        case types.SEARCH_PATIENT_ADV_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                searchAdvPatient: []
            }
        case types.RESET_SEARCH_PATIENT_ADV:
            return {
                ...state,
                searchAdvPatient:action.payload,
                loading:false
            }
        default:
            return state;
    }
}