import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { field, updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import ReportFooter from '../ReportFooter'
import { TestReportRightSection } from '../TestReportRightSection'
import './BariumMeal.css'

export const BariumMeal = () => {
    const dispatch =  useDispatch()
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const [ inputValue, setInputValue ] = useState("")
    
    const onLabelClicked = (value) =>{
      dispatch(field({fieldKey:value}))   
     }

    const onInputChange = (e) => {
      const { value, name } = e.target;
      setInputValue(value)
      const filterData = reportDataFields?.testReport?.filter(item => item.test === 'BARIUM MEAL FOR STOMACH DUODENUM')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item.test === 'BARIUM MEAL FOR STOMACH DUODENUM'){
          index = i
        }
        return item
      })
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
    }
 
    return (
        <div>
            <div className='bmeal-main'>
                <div className='bmeal-container'>
                    <div className='bmeal-grid-container'>
                        <div className='bmeal-1 padd'  onClick={() => onLabelClicked("STOMACH")}>STOMACH</div>
                        <div className='bmeal-2'><TextAreaAnt name="STOMACH" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='bmeal-border1'></span>
                        <div className='bmeal-3 padd' onClick={() => onLabelClicked("DUODENUM")}>DUODENUM</div>
                        <div className='bmeal-4'><TextAreaAnt name="DUODENUM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='bmeal-border2'></span>
                        <div className='bmeal-5 padd' onClick={() => onLabelClicked("IMPRESSION")}>IMPRESSION</div>
                        <div className='bmeal-6'><TextAreaAnt name="IMPRESSION" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                        <span className='bmeal-border3'></span>
                    </div>
                </div>
                <div className='bgroup-right-sec'>
                    <TestReportRightSection />
                </div>
            </div>
            <br></br>
            <div className='ivf-footer'>
                <ReportFooter />
            </div>
        </div>
    )
}
