import * as types from '../../actionTypes';

const initialState = {
    addAppointmentDetails: [],
    error: {},
    loading: false,
}

export  const addAppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_APPOINTMENT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.ADD_APPOINTMENT_SUCCESS:
            console.log("action",action.payload)
            return {
                ...state,
                addAppointmentDetails: action.payload,
                loading: false,
                error: {}
            }
        case types.ADD_APPOINTMENT_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                addAppointmentDetails: []
            }
        
        default:
            return state;
    }
}