import React, { useState, useEffect } from 'react'
import NumberButton from '../../../../UIComponents/NumberButton/NumberButton'
import '../SpecialNotes/SpecialNotes.css'
import Divider from '../../../../UIComponents/Divider/Divider'
import InputField from '../../../../UIComponents/Input/Input'
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg"
import "draft-js/dist/Draft.css";
import { useDispatch, useSelector } from 'react-redux'
import { addBankSpecialNote, removeBankSpecialNoteOpd, showOpdReport } from '../../../../redux/actions/opd/patientreport'
import { updateVisit } from '../../../../redux/actions/opd/patientopdinvoice'
import { toast } from '../../../../UIComponents/Toast'

function SpecialNotes() {
    const dispatch = useDispatch()
    const profileInfo = useSelector(state => state.profile.profileInfo)
    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    console.log("visitInfovisitInfo",visitInfo)

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [value, setValue] = useState("")
    const [key, setKey] = useState("")

    const onEditorStateChange = (editorState) => {
        console.log("editor", editorState.getCurrentContent().getPlainText());
        let text = editorState.getCurrentContent().getPlainText()
        dispatch(updateVisit({ ...visitInfo, specialNote: text }))
        setValue(text)
        setEditorState(editorState)
    }
    const onKeyChange = (e) => {
        setKey(e.target.value)
    }

    const addSpecialNote = () => {
        if (!key) {
            toast.error("Please enter Bank note key")
        } else {
            dispatch(addBankSpecialNote({ key, value }, profileInfo?.id))
            setKey("")
            setEditorState(EditorState.createWithContent(ContentState.createFromText('')))

        }

    }

    const removeBankSpecialNote = (key) => {
        const bank = profileInfo?.noteTemplates?.find(item => item.key === key)
        dispatch(removeBankSpecialNoteOpd({ key: bank.key }, profileInfo?.id))
    }
    const applyBankNote = (key) => {
        const contentState = editorState.getCurrentContent();
        const rawContent = convertToRaw(contentState)?.blocks.map(arr => arr.text)?.join("\n");
        
        const bank = profileInfo?.noteTemplates?.find(item => item.key === key)
        setKey(bank.key)

        setEditorState(EditorState.createWithContent(ContentState.createFromText(`${bank.value} \n${rawContent}`)))
        dispatch(updateVisit({ ...visitInfo, specialNote: bank.value }))
    }
    useEffect(()=>{
        if(visitInfo?.specialNote){
        setEditorState(EditorState.createWithContent(ContentState.createFromText(`${visitInfo.specialNote}`)))
        }else{
        setEditorState(EditorState.createWithContent(ContentState.createFromText('')))

        }
    },[visitInfo?._id])


    return (
        <div>
            <div className='special-notes-div'>
                <NumberButton className="numberButton-12">12</NumberButton>
                <div className='special-notes-12-div'>
                    <h6>SPECIAL NOTES</h6>
                </div>
                <div className='special-notes-antSelect'>
                    <div className='new-note'>
                        <div className="bank-note-sec">
                            {profileInfo?.noteTemplates?.map((item) => (<div>
                                <p><span onClick={() => applyBankNote(item.key)}>{item.key}</span><span className="bank-note-remove" onClick={() => removeBankSpecialNote(item.key)}>x</span></p>

                            </div>))}
                        </div>
                        <InputField placeholder="New Note Name" className={'special-notes-multiselect1'} value={key} onChange={onKeyChange} />
                        <p onClick={addSpecialNote}>Save Note Template</p>
                    </div>

                    <div className="speacial-notes-editor">
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            placeholder="Enter text"
                        />

                    </div>
                </div>
            </div>
            <Divider />
        </div>
    )
}

export default SpecialNotes