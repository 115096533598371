import React, { useEffect, useRef } from "react";
import CommonButton from "../../../UIComponents/Button/Button";
import "../PatientInvoice/PatientInvoice.css";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import { Empty, Modal } from "antd";
import { useState } from "react";
import Input from "../../../UIComponents/Input/Input";
import Button from "../../../UIComponents/Button/Button";
import ProfileIcon from "../../../images/profileIcon.png";
import Table from "../../../UIComponents/Table/Table";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  addEmpCardPost,
  patientInvoiceInfo,
} from "../../../redux/actions/opd/patientopdinvoice";
import Divider from "../../../UIComponents/Divider/Divider";
import DeleteIcon from "../../../images/delete-icon.svg";
import deleteIcon from "../../../images/delete-icon.svg";
import { LoadUpdateVisitOpdInvoice } from "../../../redux/actions/opd/UpdateVisitOpdInvoice";
import { invoiceSms } from "../../../redux/actions/appointments/appointments";
import { addServiceDetails } from "../../../redux/actions/opd/patientopdinvoice";
import { selectedPatientId } from "../../../redux/actions/opd/patientlist";
// import { Toast } from "../../../UIComponents/Toast";
import { toast } from "../../../UIComponents/Toast";
import moment from "moment";
import { Switch } from "antd";
import {
  addVisit,
  loadPrintReport,
  patientReport,
} from "../../../redux/actions/opd/patientreport";
import ReactToPrint from "react-to-print";
import copy from "copy-to-clipboard";
import { getProfileDetails } from "../../../redux/actions/profile";
import { printInvoice } from "../../../redux/actions/appointments/printInvoice";
import { printPatientProfileToggle } from "../../../redux/actions/appointments/printPatientProfile";
import { baseUrl } from "../../../utils/Helper";

const serviceType = [
  {
    id: "H",
    name: "H",
  },
  {
    id: "E",
    name: "E",
  },
];
const paymentType = [
  {
    id: "Cash",
    name: "Cash",
  },
  {
    id: "Credit Card",
    name: "Credit Card",
  },
  {
    id: "Debit Card",
    name: "Debit Card",
  },
  {
    id: "Net Banking",
    name: "Net Banking",
  },

  {
    id: "Cheque",
    name: "Cheque",
  },
  {
    id: "Mobile Wallet",
    name: "Mobile Wallet",
  },
  {
    id: "Reciept",
    name: "Reciept",
  },
];
const paymentSelectType = [
  {
    id: "Paid",
    name: "Paid",
  },
  {
    id: "Complimentary",
    name: "Complimentary",
  },
  {
    id: "Discounted",
    name: "Discounted",
  },
];
const option = [
  {
    id: "2",
    name: "two",
  },
  {},
];
const columns = [
  {
    title: "Services List",
    dataIndex: "servicelist",
    width: "60%",
  },
  {
    title: "Disc.%",
    dataIndex: "disc",
    // width:'60%',
  },
  {
    title: "Gross",
    dataIndex: "gross",
    // width:'60%',
  },
  {
    title: "Discount",
    dataIndex: "discount",
    // width:'60%',
  },
  {
    title: "Net",
    dataIndex: "net",
    // width:'60%',
  },
  {
    title: "",
    dataIndex: "",
    render: () => (
      <div>
        <img className="invoice-table-delete" src={DeleteIcon} alt="delete" />
      </div>
    ),
  },
];

const data = [
  {
    key: 1,
    servicelist: "FIRST CONSULTATION FEE",
    disc: "0",
    gross: "400",
    discount: "0",
    net: "400",
    icons: "",
  },
];
const tablecolumns = [
  {
    title: "Payable Today",
    dataIndex: "",
    // width:'60%',
  },
  {
    title: "Past Pending",
    dataIndex: "pending",
    // width:'60%',
  },
  {
    title: "Total Pending",
    dataIndex: "totalPending",
    // width:'60%',
  },
  {
    title: "Total Due",
    dataIndex: "fees",
    // width:'60%',
  },
  {
    title: "Discount",
    dataIndex: "discountAmt",
    // width:'60%',
  },
];

const tabledata = [
  {
    key: 1,
    payabletoday: "400",
    pastpending: "0",
    totalpending: "0",
    totaldue: "0",
    discount: "0",
  },
];

const paymentmode = [
  {
    title: "Cash",
    dataIndex: "Cash",
  },
  {
    title: "Debit Card",
    dataIndex: "Debit Card",
  },
  {
    title: "Credit Card",
    dataIndex: "Credit Card",
  },
];

const language = [
  {
    title: "H",
    dataIndex: "H",
  },
  {
    title: "E",
    dataIndex: "E",
  },
];

function PatientInvoice() {
  const componentRef = useRef();
  const componentRefff = useRef();
  const [service, setService] = useState("");
  const [disc, setDisc] = useState("");
  const [gross, setGross] = useState("");
  const [discount, setDiscount] = useState();
  const [net, setNet] = useState();
  const [totalValue, setTotalValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [discAmt, setDiscAmt] = useState();
  const [pending, paid] = useState(false);
  const [switchChecked, setSwitchChecked] = useState("");
  const [updateInsurance, setUpdateInsurance] = useState();
  const [lang, setLang] = useState();
  const [printDefault, setPrintDefault] = useState("Default");
  const [prescriptionToggle, setPrescriptionToggle] = useState(false);
  const [newInvoce, setNewInvoice] = useState([]);

  const invoicePrint = useRef();
  const OpdSmsInvoice = useSelector((state) => state.opdrecord.OpdSmsInvoice);

  let dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getOpddata, setGetOpdData] = useState();
  const patientInfo = useSelector(
    (state) => state.opdrecord.patientreport.patientInfo
  );
  const [patientInvoicestate, setPatientInvoice] = useState();
  const [paidPendingToggle, setPaidPendingToggle] = useState(true);

  console.log("patient info", patientInfo);
  const patientOpdInvoiceInfo = useSelector(
    (state) => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo
  );
  console.log("patientOpdInvoiceInfodasdsada", patientOpdInvoiceInfo);
  const UpdatedOpdInvoiceSave = useSelector(
    (state) => state.opdrecord.UpdatedOpdInvoiceSave
  );
  console.log("UpdatedOpdInvoiceSave", UpdatedOpdInvoiceSave);
  const serviceDetails = useSelector(
    (state) =>
      state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo.serviceDetails
  );
  console.log("serviceDetails", serviceDetails);
  const UpdateVisitOpdInvoice = useSelector(
    (state) => state.opdrecord.UpdateVisitOpdInvoice
  );
  console.log("UpdateVisitOpdInvoice", UpdateVisitOpdInvoice);
  const printReport = useSelector(
    (state) => state.opdrecord.patientreport.printReport
  );
  console.log("printReport", printReport);

  const getDiagnosticData = useSelector(
    (state) => state.diagnosticsInvoiceSummary.getdiagnostic
  );
  console.log("getDiagnosticData", getDiagnosticData);

  const doctorId = getDiagnosticData?.doctorId;

  const patientId = useSelector(
    (state) => state.opdrecord.patientlist.selectedPatient
  );
  console.log("patientinvoiceid", patientId);

  const loadOpdSms = useSelector((state) => state.opdrecord.OpdSmsInvoice);
  console.log("loadOpdSms", loadOpdSms);

  const printToggle = useSelector(
    (state) => state.appointments?.printPatientProfile?.printPatientToggle
  );
  console.log("printToggle", printToggle);

  const printInvoiceInfo = useSelector(
    (state) => state.appointments.printInvoice.printInvoiceData
  );
  console.log("printInvoiceInfo", printInvoiceInfo);
  const visitInfo = useSelector(
    (state) => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo
  );
  console.log("visitInfo", visitInfo);

  const doctorInfoDetail = useSelector((state) => state.profile.profileInfo);
  console.log("doctorInfoDetail", doctorInfoDetail);

  const totalNetValue = getOpddata?.invoice?.map((item) => Number(item.gross));
  console.log("totalNetValue", totalNetValue);
  const sum = totalNetValue?.reduce((partialSum, a) => partialSum + a, 0);
  console.log("sum", totalNetValue);

  const totalDiscVal = getOpddata?.invoice?.map((item) => Number(item.discAmt));
  console.log("totalDiscVal", totalDiscVal);

  const discSums = totalDiscVal?.reduce(
    (partialDiscAmnt, d) => partialDiscAmnt + d,
    0
  );
  const discSum = discSums ? Number(discSums).toFixed(2) : 0;
  console.log("discSum", discSum);

  const discSubtract = totalDiscVal?.reduce(
    (partialDiscAmnt, d) => partialDiscAmnt - d,
    0
  );
  console.log("discSubtract", discSubtract);

  const active = useSelector((state) => state.opdrecord.patientreport.active);
  console.log("single visit status", active);

  const netAmount = getOpddata?.invoice?.map((item) => Number(item.cost));
  const sumNets = netAmount?.reduce((partialNet, a) => partialNet + a, 0);
  const sumNet = sumNets ? Number(sumNets).toFixed(2) : 0;

  const invoiceA = doctorInfoDetail.invoiceA
    ? doctorInfoDetail.invoiceA
    : false;
  const invoiceB = doctorInfoDetail.invoiceB
    ? doctorInfoDetail.invoiceB
    : false;
  const invoiceC = doctorInfoDetail.invoiceC
    ? doctorInfoDetail.invoiceC
    : false;
  const hipay = doctorInfoDetail?.hipay;
  const smsData = {
    visitId: visitInfo?._id,
    invoiceA: invoiceA,
    invoiceB: invoiceB,
    invoiceC: invoiceC,
    patientId: visitInfo.patientId,
    doctorId: visitInfo?.doctorId,
    hipay: hipay,
  };

  console.log("smsData", smsData);

  const handleSmsClick = () => {
    dispatch(invoiceSms(smsData));
  };

  useEffect(() => {
    setGetOpdData(patientOpdInvoiceInfo);
  }, [patientOpdInvoiceInfo]);
  console.log("getOpddata", getOpddata);

  useEffect(() => {
    dispatch(
      addServiceDetails({
        ...patientOpdInvoiceInfo,
        fees: sumNet,
        discountAmt: discSum,
      })
    );
  }, [sumNet, discSum]);

  const showModal = () => {
    setIsModalVisible(true);
    // dispatch(selectedPatientId({ patientId: patientId, aptId: aptId }))

    // dispatch(patientInvoiceInfo({aptId,full:true}))
    toast.success("Invoice loaded Successfully");
    if (patientOpdInvoiceInfo) {
      console.log("inside if useEffect");
      setSwitchChecked(patientOpdInvoiceInfo?.paymentStatus);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    console.log("switchChecked", switchChecked);
    dispatch(
      LoadUpdateVisitOpdInvoice({
        visit: { ...patientOpdInvoiceInfo, paymentStatus: switchChecked },
        aptId: visitInfo?.aptId,
        invoiceA: doctorInfoDetail.invoiceA ? doctorInfoDetail.invoiceA : false,
        invoiceB: doctorInfoDetail.invoiceB ? doctorInfoDetail.invoiceB : false,
        invoiceC: doctorInfoDetail.invoiceC ? doctorInfoDetail.invoiceC : false,
      })
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDisc("0");
    setService();
    setGross();
    setDiscountValue();
    setDiscAmt();
    setTotalValue();
    setSwitchChecked("");
  };

  const serviceTypeHandler = (e) => {
    setService(e.target.value);
  };

  const discHandler = (e) => {
    let value = e.target.value;
    if (value > 100) {
      toast.error("Discount cannot exceeds more than 100");
      setDisc("");
    } else {
      setDisc(value);
    }
  };

  const grossHandler = (e) => {
    setGross(e.target.value);
    var numVal1 = Number(e.target.value);
    var numVal2 = Number(disc) / 100;
    var num3 = numVal2 * numVal1;
    console.log("num3", num3);
    setDiscountValue(num3.toFixed(2));
    setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(2));
  };

  useEffect(() => {
    if (disc) {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3.toFixed(2));
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(2));
    }
  }, [disc]);

  const discountHandler = (e) => {
    setDiscountValue(e.target.value);
  };

  const netHandler = (e) => {
    setNet(e.target.value);
  };
  const grossValue = Number(gross).toFixed(2);

  const total = Number(totalValue).toFixed(2);
  const discValue = Number(disc).toFixed(2);
  const discAmount = Number(discountValue).toFixed(2);

  const addOpdInvoice = () => {
    console.log(
      "checking the values",
      service,
      total,
      discValue,
      discAmount,
      grossValue
    );
    if (!service) {
      toast.error("Please enter service type");
    }
    // else if (!disc) {
    //   toast.error("Please enter discount");
    // }
    else if (!gross) {
      toast.error("Please enter gross");
    } else if (
      patientOpdInvoiceInfo.hasOwnProperty("invoice") &&
      patientOpdInvoiceInfo?.invoice?.length
    ) {
      dispatch(
        addServiceDetails({
          ...patientOpdInvoiceInfo,
          invoice: [
            ...patientOpdInvoiceInfo.invoice,
            {
              service: service,
              cost: total,
              disc: discValue,
              discAmt: discAmount,
              gross: grossValue,
            },
          ],
        })
      );
      setNet();
      setDisc("0");
      setGross();
      setService("");
      setDiscountValue();
      setTotalValue();
    } else {
      // setNewInvoice([...newInvoce, { service: service, disc: disc, gross: gross, discAmt: discountValue, cost: totalValue }])
      dispatch(
        addServiceDetails({
          ...patientOpdInvoiceInfo,
          invoice: [
            ...newInvoce,
            {
              service: service,
              disc: discValue,
              gross: grossValue,
              discAmt: discAmount,
              cost: total,
            },
          ],
        })
      );
      setNet();
      setDisc("0");
      setGross();
      setService("");
      setDiscountValue();
      setTotalValue();
    }
  };

  useEffect(() => {
    const array = patientOpdInvoiceInfo;
  }, [patientOpdInvoiceInfo]);

  const deleteServiceData = (index) => {
    dispatch(
      addServiceDetails({
        ...patientOpdInvoiceInfo,
        invoice: [...patientOpdInvoiceInfo.invoice].filter(
          (item, i) => i !== index
        ),
      })
    );
    console.log("deleted", patientOpdInvoiceInfo);
  };
  // dispatch(updateProfileData({ ...profileInfo, locations: [...profileInfo.locations].filter((item, i) => i !== index) }))

  const onDiscSubmit = (e) => {
    if (e.key === "Enter") {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3);
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(1));
    }
  };

  const onKeyDown = (e) => {
    console.log("fhdfhuds", e);
    if (e.key === "Tab") {
      var numVal1 = Number(gross);
      var numVal2 = Number(disc) / 100;
      var num3 = numVal2 * numVal1;
      console.log("num3", num3);
      setDiscountValue(num3);
      setTotalValue(numVal1 - (numVal1 * numVal2).toFixed(1));
    }
  };

  const updateInputNet = () => {
    console.log(totalValue);
  };

  const deleteServiceHandler = (index) => {
    dispatch(
      addServiceDetails({
        ...patientOpdInvoiceInfo,
        invoice: [...patientOpdInvoiceInfo.invoice].filter(
          (item, i) => i !== index
        ),
        discountAmt: discSubtract,
      })
    );
    console.log("delete", index);
  };

  const paymentmodeHandler = (value) => {
    dispatch(
      addServiceDetails({ ...patientOpdInvoiceInfo, paymentMode: value })
    );
  };
  const paymentTypeHandler = (value) => {
    console.log("valuevalue", value);
    if (value === "Paid") {
      setPaidPendingToggle(true);
      console.log("inside if else", paidPendingToggle);
      dispatch(
        addServiceDetails({ ...patientOpdInvoiceInfo, visitType: value })
      );
    } else if (value === "Complimentary" || value === "Discounted") {
      console.log("inside else if", paidPendingToggle);
      setPaidPendingToggle(false);
      dispatch(
        addServiceDetails({ ...patientOpdInvoiceInfo, visitType: value })
      );
    }
  };

  const [switchPayment, setSwitchPayment] = useState(getOpddata?.visitType);

  const empIdHandler = (e) => {
    dispatch(
      addEmpCardPost({
        ...patientOpdInvoiceInfo,
        insurance: {
          ...patientOpdInvoiceInfo.insurance,
          empid: e.target.value,
        },
      })
    );
    // setInvoiceEmpId(e.target.value)
    // console.log("invoiceEmpId",invoiceEmpId)
  };
  const cardHandler = (e) => {
    // setOpdCard(e.target.value)
    dispatch(
      addEmpCardPost({
        ...patientOpdInvoiceInfo,
        insurance: {
          ...patientOpdInvoiceInfo.insurance,
          cardholder: e.target.value,
        },
      })
    );

    console.log("opdCard");
  };

  const relateHandler = (e) => {
    // setOpdRelate(e.target.value)
    dispatch(
      addEmpCardPost({
        ...patientOpdInvoiceInfo,
        insurance: {
          ...patientOpdInvoiceInfo.insurance,
          relationship: e.target.value,
        },
      })
    );
  };

  const depatmentHandler = (e) => {
    // setOpdDepartment(e.target.value);
    dispatch(
      addEmpCardPost({
        ...patientOpdInvoiceInfo,
        insurance: {
          ...patientOpdInvoiceInfo.insurance,
          department: e.target.value,
        },
      })
    );
    console.log("dept");
  };

  const postHandler = (e) => {
    // setOpdPost(e.target.value);
    dispatch(
      addEmpCardPost({
        ...patientOpdInvoiceInfo,
        insurance: { ...patientOpdInvoiceInfo.insurance, post: e.target.value },
      })
    );
    console.log("");
  };

  const switchHandler = (checked) => {
    console.log("checked", checked);
    setSwitchChecked(checked);
  };

  console.log("checkeddssdsd", switchChecked);

  // useEffect(() => {
  //   if (patientOpdInvoiceInfo) {
  //     console.log("inside if useEffect");
  //     setSwitchChecked(patientOpdInvoiceInfo?.paymentStatus)
  //   }
  // }, [patientOpdInvoiceInfo])

  const languageHandler = (value) => {
    console.log("sdgfsdfgsdf", value);
    setLang(value);
  };

  const handleCopylink = () => {
    let url = baseUrl();
    dispatch(getProfileDetails());
    const invoiceA = doctorInfoDetail.invoiceA
      ? doctorInfoDetail.invoiceA
      : false;
    const invoiceB = doctorInfoDetail.invoiceB
      ? doctorInfoDetail.invoiceB
      : false;
    const invoiceC = doctorInfoDetail.invoiceC
      ? doctorInfoDetail.invoiceC
      : false;
    const hipay = doctorInfoDetail?.hipay;
    const mypay = doctorInfoDetail?.mypay;

    let message = `${window.location.host}/opd-invoice/${patientOpdInvoiceInfo?._id}?alt=${invoiceA}&alp=${invoiceB}&alps=${invoiceC}`;

    toast.success("Link Copied");
    let msg = message;
    if (mypay) {
      msg = msg + " " + mypay;
    }
    if (hipay) {
      msg = msg + message + "processpayment/" + patientOpdInvoiceInfo?._id;
    }
    copy(msg).then(
      function (e) {},
      function (e) {}
    );
  };

  useEffect(() => {
    dispatch(printPatientProfileToggle(true));
  }, []);

  const prescriptionHandler = () => {
    setPrescriptionToggle(true);
    const { visitStart, variableForNarratives, ...rest } = visitInfo;
    if (lang && printDefault) {
      dispatch(addVisit({ ...rest, narratives: variableForNarratives }));
      dispatch(
        loadPrintReport({
          visitId: patientOpdInvoiceInfo?._id,
          lang: lang,
          verboseReport: printDefault,
        })
      );
    } else if (lang) {
      dispatch(addVisit({ ...rest, narratives: variableForNarratives }));
      dispatch(
        loadPrintReport({
          visitId: patientOpdInvoiceInfo?._id,
          lang: lang,
          verboseReport: "Default",
        })
      );
    } else if (printDefault === "Default") {
      dispatch(addVisit({ ...rest, narratives: variableForNarratives }));
      dispatch(
        loadPrintReport({
          visitId: patientOpdInvoiceInfo?._id,
          lang: lang,
          verboseReport: "Default",
        })
      );
      // setPrintDefault('')
    } else if (printDefault === "Verbose") {
      dispatch(addVisit({ ...rest, narratives: variableForNarratives }));
      dispatch(
        loadPrintReport({
          visitId: patientOpdInvoiceInfo?._id,
          lang: lang,
          verboseReport: "Verbose",
        })
      );
      // setPrintDefault('')
    } else if (printDefault === "Landscape") {
      dispatch(addVisit({ ...rest, narratives: variableForNarratives }));
      dispatch(
        loadPrintReport({
          visitId: patientOpdInvoiceInfo?._id,
          lang: lang,
          verboseReport: "Landscape",
        })
      );
      // setPrintDefault('')
    } else {
      dispatch(addVisit({ ...rest, narratives: variableForNarratives }));
      dispatch(
        loadPrintReport({
          visitId: patientOpdInvoiceInfo?._id,
          lang: "E",
          verboseReport: "Default",
        })
      );
    }
  };

  console.log("prescriptionToglee", prescriptionToggle, lang, switchChecked);

  const printDefaultClick = (data) => {
    console.log("datadata", data);
    setPrintDefault(data);
  };
  return (
    <div className="print-invoice-main-container">
      <div className="printOptions">
        <span>Print Options</span>
        <div className="print-options-main">
          <div className="print-options-container">
            <SingleSelect
              data={serviceType}
              className={"select-language-input"}
              value={lang || undefined}
              onChange={languageHandler}
              placeholder="Select language"
            >
              Select Language
            </SingleSelect>
            <div className="opd-print-invoice">
              <ReactToPrint
                trigger={() => (
                  <button className="print-inv">PRINT INVOICE</button>
                )}
                content={() => componentRefff.current}
              />

              <div style={{ display: "none" }}>
                <div ref={componentRefff}>
                  <div
                    dangerouslySetInnerHTML={{ __html: printInvoiceInfo }}
                  ></div>
                </div>
              </div>
            </div>
            <CommonButton onClick={showModal} className={"invoice-btn"}>
              INVOICE
            </CommonButton>
            <div onClick={prescriptionHandler}>
              <ReactToPrint
                trigger={() => (
                  <CommonButton className={"prescription-btn"}>
                    PRINT PRESCRIPTION
                  </CommonButton>
                )}
              />
            </div>
            <div style={{ display: "none" }}>
              <ReactToPrint
                trigger={() => (
                  <CommonButton className={"prescription-btn"} id="dummyClick">
                    PRESCRIPTION
                  </CommonButton>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div
              style={{ display: "none" }}
              className="previewReport-iframe-div"
            >
              <div ref={componentRef}>
                <div
                  className="report-style"
                  dangerouslySetInnerHTML={{ __html: printReport }}
                ></div>
              </div>
            </div>
          </div>
          <div className="prescription-options">
            <Button
              className={
                printDefault === "Default"
                  ? "print-active default-bg-color"
                  : "print-active"
              }
              onClick={() => printDefaultClick("Default")}
            >
              Default
            </Button>
            <Button
              className={
                printDefault === "Verbose"
                  ? "print-active default-bg-color"
                  : "print-active"
              }
              onClick={() => printDefaultClick("Verbose")}
            >
              Text
            </Button>
            <Button
              className={
                printDefault === "Landscape"
                  ? "print-active default-bg-color"
                  : "print-active"
              }
              onClick={() => printDefaultClick("Landscape")}
            >
              LS
            </Button>
          </div>
        </div>
      </div>
      <Modal
        title=""
        visible={isModalVisible}
        onCancel={handleCancel}
        width={920}
      >
        <div className="invoice-modal">
          <div className="invoice-header">
            <div>
              <h3 className="invoice-heading">EDIT INVOICE</h3>
              <div className="invoice-visit-date">
                <p>
                  {moment(patientOpdInvoiceInfo.visitStart).format(
                    "YYYY-MM-DD"
                  )}
                </p>
              </div>
            </div>
            {paidPendingToggle ? (
              <div
                className={
                  active ? "invoice-toggle disable-video" : "invoice-toggle"
                }
              >
                <span className="invoice-toggle-label">Pending</span>
                <Switch
                  checked={switchChecked ? true : false}
                  onChange={switchHandler}
                />
                <span className="invoice-toggle-label">paid</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr className="hr-line"></hr>
          <div className="invoice-detail-container">
            <div className="invoice-profile-detail">
              <img src={ProfileIcon} alt="" />
              <div className="invoice-profile-detail-text">
                <h3>{patientInfo.name}</h3>
                <p>
                  Male <span>|</span> {patientInfo.age} old <span>|</span> PH :
                  91 {patientInfo.mobile} <span>|</span> {patientInfo.address1}{" "}
                  {patientInfo.address2}{" "}
                </p>
              </div>
            </div>
            <hr className="hr-line"></hr>
            <div>
              <div className="invoice-table">
                <div className="opd-invoice-headings">
                  <div className="service-type-sec">
                    <p className="opd-invoice-service">Mode </p>
                    <SingleSelect
                      data={paymentType}
                      value={getOpddata?.paymentMode}
                      onChange={paymentmodeHandler}
                      className={"select-language-input"}
                    />
                  </div>
                  <div className="service-type-sec">
                    <p className="opd-invoice-service">Type</p>
                    <SingleSelect
                      data={paymentSelectType}
                      value={getOpddata?.visitType}
                      onChange={paymentTypeHandler}
                      className={"select-language-input"}
                    />
                  </div>
                </div>
                <hr className="hr-line"></hr>

                <div className="opd-invoice-headings">
                  <div className="service-type-sec">
                    <p className="opd-invoice-service">Service List</p>
                  </div>
                  <div className="disc-gross">
                    <p className="opd-invoice-disc">Disc%</p>
                    <p className="opd-invoice-gross">Gross</p>
                    <p className="opd-invoice-discount">Discount</p>
                    <p className="opd-invoice-action">Net</p>
                  </div>
                </div>
                <div>
                  <Divider />
                </div>
                {getOpddata?.invoice?.map((item, index) => (
                  <div className="opd-discount-input-sec">
                    <p className="empty-p-tag">{item.service}</p>
                    <p className="opd-name">{item.disc}</p>
                    <p className="opd-days">{item.gross}</p>
                    <p className="opd-doseage">{item.discAmt}</p>
                    <p className="opd-cost">{item.cost}</p>
                    <div
                      className={
                        active
                          ? "disable-video opd-delete-sec"
                          : "opd-delete-sec"
                      }
                      onClick={() => deleteServiceHandler(index)}
                    >
                      <img src={deleteIcon} alt="deleteIcon" />
                    </div>
                  </div>
                ))}
                {serviceDetails?.map((item, i) => (
                  <div className="opd-discount-input-sec">
                    <p className="empty-pp-tag">{item.service}</p>

                    <p className="opd-name">{item.disc}</p>
                    <p className="opd-days">{item.cost}</p>
                    <p className="opd-doseage">{item.discSum}</p>
                    <p className="opd-input-cost">{item.gross}</p>
                    <div
                      className={
                        active ? "disable-video opd-delete" : "opd-delete"
                      }
                    >
                      <img
                        src={deleteIcon}
                        onClick={() => deleteServiceData(i)}
                        alt="deleteIcon"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="invoice-input-container">
                <div className="invoice-input-one">
                  <p>Service Type</p>
                  <Input
                    className="invoice-input1"
                    placeholder="Select Medicine Name"
                    type="text"
                    value={service}
                    onChange={serviceTypeHandler}
                  />
                </div>
                <div className="invoice-input-one">
                  <p>Disc%</p>
                  <Input
                    className="invoice-input2"
                    name="disc"
                    value={disc}
                    onChange={discHandler}
                    type="number"
                    placeholder="%"
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value)
                      ).toString();
                    }}
                  />
                </div>
                <div className="invoice-input-one">
                  <p>Gross</p>
                  <Input
                    className="invoice-input2"
                    name="gross"
                    value={gross}
                    onChange={grossHandler}
                    onKeyPress={onDiscSubmit}
                    onKeyDown={onKeyDown}
                    id="click"
                    type="number"
                    placeholder="Gross"
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value)
                      ).toString();
                    }}
                  />
                </div>
                <div className="invoice-input-one">
                  <p>Discount</p>
                  <Input
                    className="invoice-input2"
                    name="discount"
                    disabled={true}
                    value={discountValue}
                    onChange={discountHandler}
                    type="number"
                    placeholder="Discount"
                  />
                </div>
                <div className="invoice-input-one">
                  <p>Net</p>
                  <Input
                    className="invoice-input3"
                    name="net"
                    value={totalValue}
                    disabled={true}
                    type="number"
                    placeholder=""
                  />
                </div>
                <Button
                  children="ADD"
                  className="invoice-modal-btn"
                  onClick={addOpdInvoice}
                  disabled={active}
                />
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div>
              <hr className="hr-line"></hr>
              <div className="invoice-table-display-sec">
                <div className="diplaytotalDue">
                  <div className="display-total-pending">
                    <div className="payable-day">
                      <p className="paytoday">Payable Today:</p>
                      <p>{sumNet}</p>
                    </div>
                    <div className="pastpending">
                      <p className="pasttitle">Past Pending : </p>
                      <p>{patientOpdInvoiceInfo.pastPending}</p>
                    </div>
                    <div className="totalpend">
                      <p className="totalpendtitle">Total Pending:</p>
                      <p>{patientOpdInvoiceInfo.totalPending}</p>
                    </div>
                    <div className="totaldue">
                      <p>Total Due:</p>
                      <p className="totalsum">{sumNet}</p>
                    </div>
                    <div className="totalDisc">
                      <p>Discount:</p>
                      <p className="opd-intake">{discSum}</p>
                    </div>
                  </div>
                </div>
                {/* ))} */}
              </div>
              <hr className="hr-line"></hr>
            </div>
            <div className="invoice-input-container1">
              <div className="invoice-input-one">
                <p>Employee ID</p>

                <Input
                  className="invoice-input4"
                  onChange={empIdHandler}
                  value={patientOpdInvoiceInfo?.insurance?.setEmployeeId}
                  //  onChange={changeInsurance}
                />
                {/* {console.log("patient insurance", patientOpdInvoiceInfo?.insurance.empid)} */}
              </div>
              <div className="invoice-input-one">
                <p>Card Holder</p>
                <Input
                  className="invoice-input-select"
                  onChange={cardHandler}
                  value={patientOpdInvoiceInfo?.insurance?.setCardHolder}
                />
              </div>
              <div className="invoice-input-one">
                <p>Relationship</p>
                <Input
                  className="invoice-input-select"
                  onChange={relateHandler}
                  value={patientOpdInvoiceInfo?.insurance?.setRelationship}
                />
              </div>
              <div className="invoice-input-one">
                <p>Department</p>
                <Input
                  className="invoice-input-select"
                  onChange={depatmentHandler}
                  value={patientOpdInvoiceInfo?.insurance?.setDepart}
                />
              </div>
              <div className="invoice-input-one">
                <p>Post</p>
                <Input
                  className="invoice-input-select"
                  onChange={postHandler}
                  value={patientOpdInvoiceInfo?.insurance?.setPost}
                />
              </div>
            </div>

            <div className="invoice-btn-container">
              <Button
                children="SMS INVOICE"
                className="invoice-modal-btn1"
                onClick={handleSmsClick}
              />
              <Button
                children="COPY LINK"
                className="invoice-modal-btn2"
                onClick={handleCopylink}
              />
            </div>
          </div>
          <div className="calls-modal-btn">
            <Button
              onClick={handleOk}
              children="SAVE"
              className={
                active ? "disable-video calls-modal-btn1" : "calls-modal-btn1"
              }
            />
            <Button
              onClick={handleCancel}
              children="CANCEL"
              className="calls-modal-btn2"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PatientInvoice;
