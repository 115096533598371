import React from 'react'
import './Rft.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const Rft = () => {
    const dispatch =  useDispatch()
    const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
    const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
    const [ inputValue, setInputValue ] = useState("")

    const onInputChange = (e) => {
      console.log("e",e.target);
      const { value, name } = e.target;
 
      const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'RFT')
      let index = 0
      reportDataFields?.testReport?.map((item, i) => {
        if(item?.test === 'RFT'){
          index = i
        }
        return item
      })
      console.log("index",index);
      const finalFilteredData = filterData[0]?.fields?.map(item => {
        if(item.particular === name){
          item.result = value
        }
        return item
      })
      filterData[0].fields = finalFilteredData
  reportDataFields.testReport[index] = filterData[0]
  console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
  setInputValue(reportDataFields?.testReport[index]?.fields)
  dispatch(updateReports(reportDataFields))


    }
  return (
    <div>
    <div className='rft-parent'>
        <div className='rft-left'>
            <div className='rft-grid'>
                <div className='rft1 h-pad'></div>
                <div className='rft2 h-pad'>BLOOD UREA</div>
                <div className='rft3'><Input type='number' className={'rft-input'} name="BLOOD UREA" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='rft4 h-pad'> MG%</div>
                <div className='rft5 h-pad'>13 - 45</div>
                <div className='rft6 h-pad'></div>
                <span className='rft-border'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>S. CREATININE</div>
                <div className=''><Input type='number' className={'rft-input'} name="S. CREATININE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' h-pad'> mg/dl</div>
                <div className=' h-pad'>0.5 - 1.5</div>
                <div className=' h-pad'></div>
                <span className='rft-border2'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>SERUM SODIUM [NA+]</div>
                <div className=''><Input type='number' className={'rft-input'} name="SERUM SODIUM [NA+]" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' h-pad'>mEq/l</div>
                <div className=' h-pad'>135 - 155</div>
                <div className=' h-pad'></div>
                <span className='rft-border3'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>SERUM POTASSIUM [K+]</div>
                <div className=''><Input type='number' className={'rft-input'} name="SERUM POTASSIUM [K+]" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' h-pad'> mEq/l</div>
                <div className=' h-pad'>3.4 - 5.5</div>
                <div className=' h-pad'></div>
                <span className='rft-border4'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>SERUM CALCIUM [Ca+]</div>
                <div className=''><Input type='number' className={'rft-input'} name="SERUM CALCIUM [Ca+]" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className=' h-pad'>  mg/dl</div>
                <div className=' h-pad'>8.5 - 11.0</div>
                <div className=' h-pad'></div>
                <span className='rft-border5'></span>
            </div>
        </div>
            <div>
                <TestReportRightSection />
            </div>
    </div>
   
    <div className='rft-foot'>
          <ReportFooter />
    </div>
</div>
  )
}
