import React from "react";
import "./Login.css";
import LoginPage from "../../components/Login/LoginPage";
import CustomLoader from "../../UIComponents/CustomLoader/CustomLoader";
const Login = () => {
  return (
    <div>
      <CustomLoader />

      <LoginPage />
    </div>
  );
};

export default Login;
