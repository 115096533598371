import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import { DatePicker } from "antd";
import Button from "../../../UIComponents/Button/Button";
import Invoicelogo from "../../../images/Invoicelogo.png";
import "../Patientstats/InvoiceTable.css";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import moment from "moment";
import { clearDatesOnTab, dailyCollection } from "../../../redux/actions/analytics/analytics";
import "./DailyCollection.css";
import { clearDropDownData, hideDataSuccess } from '../../../redux/actions/analytics/analytics';
import { toast } from '../../../UIComponents/Toast'
import calenderLogo from '../../../images/calender.svg';

const { Column } = Table;
const data = [
  {
    id: "1",
    name: "No data",
  },

];
const DailyCollection = () => {
  let dispatch = useDispatch();
  const dailyCollectionInfo = useSelector(
    (state) => state.analytics.dailyCollectionInfo);
  // console.log("dailyCollectionInfo", dailyCollectionInfo);

  const hide = useSelector(state => state.analytics.hideData)
  const clearDates = useSelector(state => state.analytics.clearDates)
  const dropDownInfo = useSelector(state => state.analytics.clearDropDowns)
  console.log("dailyCollectionInfo", dailyCollectionInfo);
  const [consultant, setConsultant] = useState("");
  const [total, setTotal] = useState()
  const [hash, setHash] = useState([]);


  const handleDateChange = (date, dateString) => {
    dispatch(clearDatesOnTab(dateString))
  };

  const handleConsultantChange = (value) => {
    dispatch(clearDropDownData({ ...dropDownInfo, consultant: value }))
  };
  console.log("one", consultant);

  const onGeneratedailyCollectionClick = () => {
    if (!clearDates) {
      toast.error("Please select the dates")
    } else {
      dispatch(dailyCollection(clearDates));
      dispatch(hideDataSuccess(true))
    }
  };

  function add3Dots(string) {
    var dots = "...";
    var limit = 20
    if (string ? string.length > limit : "") {
      string = string.substring(0, limit) + dots;
    }

    return string;
  }

  useEffect(() => {
    const checking = dailyCollectionInfo?.map(item => (
      item.fees
    ))
    console.log("checking", checking)
    const arrOfNum = [];

    // checking?.forEach(str => {
    //   arrOfNum.push(Number(str));
    // });
    checking?.forEach(str => {
      if(isNaN(str)){
        arrOfNum.push(Number(0));
      }
      else {
        arrOfNum.push(Number(str));
      }
    });
    console.log("arrOfNum", arrOfNum)
    const addNew = arrOfNum.reduce((partialSum, a) => partialSum + a, 0);
    console.log("addNew", addNew)
    setTotal(addNew.toFixed(2))
  }, [dailyCollectionInfo])

  useEffect(() => {
    let finaldailyCollectionInfo = dailyCollectionInfo?.map((item, i) => {
      let array = [];
      if (isNaN(item.fees)) {
        return{
          ...item, fees: 0, hash: `${i + 1}`
        }
      }
      else {
        return{
          ...item, fees: item.fees, hash: `${i + 1}`
        }
      }
      console.log("arrayaassds", array)

    })
    console.log("finaldailyCollectionInfo", finaldailyCollectionInfo)
    setHash(finaldailyCollectionInfo)

  }, [dailyCollectionInfo])



  return (
    <div>
      <div className="prescription-top-left-sec">
        <div className="from-to-date">
          <p className="date-title">DATES</p>
          <DatePicker onChange={handleDateChange} placeholder="DD/MM/YYYY" value={clearDates !== "" ? moment(clearDates) : ""} />
          <img className='analytics-calender' src={calenderLogo} alt='calender' />

        </div>
        <div className="prescript-consultant">
          <p className="consultant-title">CONSULTANT </p>
          <SingleSelect
            onChange={handleConsultantChange}
            data={data}
            value={dropDownInfo?.consultant}
            width="100%"
          />
        </div>
        <div className="prescript-date">
          <Button
            onClick={onGeneratedailyCollectionClick}
            className="pres-generate-btn"
          >
            GENERATE
          </Button>
        </div>
      </div>
      {hide ?
        <div>
          <img className="invoice-logo" src={Invoicelogo} alt="Invoicelogo" />
          <p className="date-range">
            <b>Date Range:</b> {clearDates}
          </p>
          <div className="invoice-container">






            <div>
              <Table dataSource={hash} pagination={{ current: 1, pageSize: dailyCollectionInfo?.length + 1 }}>
                <div className="table-div">
                  <Column title="#" dataIndex="hash" key="hash" />
                  <Column title="Patient" dataIndex="patientName" key="Patient" />
                  <Column title="Age/Sex" dataIndex="age" key="AgeSex" />
                  <Column title="ID" dataIndex={add3Dots("patientId")} key="ID" />
                  <Column title="City" dataIndex="city" key="City" />
                  <Column title="Mobile" dataIndex="mobile" key="Mobile" />
                  <Column title="Inv#" dataIndex="invoiceNumber" key="Type" />
                  <Column title="Fees" dataIndex="fees" key="Diagnosis" />
                  <Column title="Mode" dataIndex="paymentMode" key="In" />
                  <Column title="User" dataIndex="doctorName" key="Start" />
                  <Column title="Consultant" dataIndex="doctorName" key="End" />

                </div>

              </Table>
              <table className="total-table">
                <tr>
                  <td className="total-width">Total:</td>
                  <td className="total">{total}</td>

                </tr>
              </table>

            </div>

          </div>
          <br></br>
        </div>
        : ""}
    </div>
  );
};

export default DailyCollection;