import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

const getCreatePatientStatus = (status) => ({
  type: types.GET_CREATE_PATIENT_STATUS,
  payload: status,
});
export const getDates = (payload) => ({
  type: types.GET_DATE,
  payload,
});
const createPatientLoading = () => ({
  type: types.CREATE_PATIENT_LOADING,
});

const createPatientSuccess = (data) => ({
  type: types.CREATE_PATIENT_SUCCESS,
  payload: data,
});
const createPatientFail = (error) => ({
  type: types.CREATE_PATIENT_FAIL,
  payload: error,
});

export const createPatientToggle = (data) => ({
  type: types.CREATE_PATIENT_TOGGLE,
  payload: data,
});

export const newPatientModalToggle = (data) => ({
  type: types.NEW_PATIENT_TOGGLE,
  payload: data,
});

//fileno
const fileNoLoading = () => ({
  type: types.FILE_NO_LOADING,
});

const fileNoSuccess = (data) => ({
  type: types.FILE_NO_SUCCESS,
  payload: data,
});
const fileNoFail = (error) => ({
  type: types.FILE_NO_FAIL,
  payload: error,
});
export const getUrl = (payload) => ({
  type: types.GET_URL,
  payload,
});

// let url = baseUrl();
export const fileNo = ({ doctorId, afterApiData }) => {
  console.log("afterApidata", afterApiData);
  return function (dispatch) {
    dispatch(fileNoLoading());
    client
      .get(
        `/doctor/get-next-fileno?doctorId=${doctorId}`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(fileNoSuccess(resp.data));
        dispatch(
          createPatient({ ...afterApiData, uniqueno: resp.data.fileNo })
        );
        console.log("resp.data", resp.data);
      })
      .catch((error) => {
        console.log("error1111", error);
        dispatch(fileNoFail(error));
      });
  };
};

export const createPatient = (data) => {
  return function (dispatch) {
    dispatch(createPatientLoading());
    client
      .post(
        `/patient/add-patient`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(createPatientSuccess(resp.data));
        console.log("resp.data", resp.data);
        dispatch(getCreatePatientStatus(resp.status));
        // dispatch(createPatientSuccess(resp.status))
        console.log("appointments data", resp.data);
        toast.success("Patient created");
      })

      .catch((error) => {
        console.log("error1111", error);
        dispatch(createPatientFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
