import React from 'react'
import { useSelector } from 'react-redux'
import '../AppointmentDetails/AppointmentDetails.css'
const AppointmentDetails = () => {

    const singleAppointment = useSelector(state => state.opdrecord.patientHistory.singleAppointment)
    console.log("singleAppointment", singleAppointment);

    const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    console.log("visitInfohistory", visitInfo)

    const profileInfo = useSelector(state => state.profile.profileInfo)
    console.log("historyprofileInfo", profileInfo);
    const patientOpdInvoiceInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
    console.log("patientOpdInvoiceInfo", patientOpdInvoiceInfo);


    return (
        <div>

            <div className="appointments-payments-time">
                <p>Appointment Type: <span className="offline">{singleAppointment?.appointment?.aptType}</span></p>
                <p>Appointment Time: <span className="arrived-time">{singleAppointment?.appointment?.timeslot}</span></p>
                {/* <p>Time Duration: <span className="time-duration">{profileInfo?.locations?.[0]?.slotDuration}</span></p> */}
                <p>Payment: {patientOpdInvoiceInfo?.paymentStatus ? <span className="complete">paid</span> : <span className="pending">pending</span>}</p>
            </div>

        </div>
    )
}

export default AppointmentDetails