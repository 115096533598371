import * as types from "../../actionTypes";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

export const resetVisitAudioData = (payload) => ({
  type: types.RESET_VISIT_AUDIO_FILE,
  payload,
});

const patientAudioLoading = () => ({
  type: types.GET_PATIENT_AUDIO,
});

export const selectedPatientId = (payload) => ({
  type: types.GET_SELECTED_OPD_PATIENT,
  payload,
});

const patientAudioSuccess = (data) => ({
  type: types.GET_PATIENT_AUDIO_SUCCESS,
  payload: data,
});
const patientAudioFail = (error) => ({
  type: types.GET_PATIENT_AUDIO_FAIL,
  payload: error,
});
// GET AUDIO FILE
const getAudioFileload = () => ({
  type: types.GET_AUDIO_FILE,
});
const getAudioSuccess = (data) => ({
  type: types.GET_AUDIO_SUCCESS,
  payload: data,
});
const getAudioFail = (error) => ({
  type: types.GET_AUDIO_FAIL,
  payload: error,
});
const getVisitAudioSuccess = (data) => ({
  type: types.GET_VISIT_AUDIO_FILE,
  payload: data,
});

export const getAudioData = (patientId) => {
  console.log("audioid", patientId);
  return function (dispatch) {
    dispatch(getAudioFileload());

    client
      .get(
        `/visit/view-all-visit-audio?patientId=${patientId}`
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getAudioSuccess(resp.data));
        console.log("getaudio", resp.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(getAudioFail(error));
      });
  };
};

export const patientAudio = (data) => {
  console.log("data sent", data);
  return function (dispatch) {
    dispatch(patientAudioLoading());

    client
      .post(
        `/visit/save-visit-audio`,
        data
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(patientAudioSuccess(resp.data));
        console.log("patient audio data", resp.data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(patientAudioFail(error));
      });
  };
};
export const getVisitAudio = (id) => {
  return function (dispatch) {
    client
      .get(
        `/visit/view-visit-audio?visitId=${id}`
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(getVisitAudioSuccess(resp.data));
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(`${error.response.data.message}`);
        }
      });
  };
};
