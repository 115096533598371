import { Table } from 'antd';
import './InvoiceDetailsTable.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const { Column, ColumnGroup } = Table;

const InvoiceTable = () => {
  const invoiceDetailsInfo = useSelector(state => state.analytics.invoiceDetailsInfo.invoices)
  const updateInvoice = useSelector(state => state.analytics.updateInvoice)
  console.log("updateInvoice", updateInvoice)
  const [checking, setChecking] = useState([])
  const [modifiedDetails, setModifiedDetails] = useState()
  
  useEffect(() => {
    const array = [];
    {
      invoiceDetailsInfo?.map((item, i) => (
        item?.invoice?.map((item1, j) => {
          console.log("array", { ...item1 });
          console.log("array1", { ...item });
          item={...item,visitStart:moment(item.visitStart, "YYYY-MM-DD hh:mm a").format("YYYY-MM-DD hh:mm a")}
          console.log("arrayyy",item,array);
          array.push({ ...item, ...item1, _id_custom: `${i}+${j}` })
        })
      ))
    }

    setChecking(array)
    console.log("arrayhhhh", array);
  }, [invoiceDetailsInfo])
  console.log("setChecking", checking)

  const onDateSort = (checking) => {
    if (checking?.length) {
      const dates = Object.values(checking)
      dates.sort((a, b) => {
        // return new Date(b.visitStart) - new Date(a.visitStart);
        return a.invoiceNumber > b.invoiceNumber ? -1 : b.invoiceNumber > a.invoiceNumber ? 1 : 0

      })
      setModifiedDetails(dates)
    }
    else {
      setModifiedDetails([])

    }
  }
  useEffect(() => {
    if(checking?.length){
    const invoicesummary = [...checking]
    onDateSort(invoicesummary)
    } else {
      onDateSort()
    }
  }, [checking])

  return (
    <div className='invoice-container'>
      <Table dataSource={modifiedDetails} pagination={{ current: 1, pageSize: checking?.length + 1 }} >
        <ColumnGroup title="">
          <Column title="Inv#" dataIndex="invoiceNumber" key="Inv" />
          <Column title="Doctor" dataIndex="doctorName" key="Doctor" />
          <Column title="Patient" dataIndex="patientName" key="Patient" />
          <Column title="Visit Date" dataIndex="visitStart" key="VisitDate" />
          <Column title="Service" dataIndex='service' key="Service" />
          <Column title="Fees" dataIndex="cost" key="Fees" />
          <Column title="Referral" dataIndex="referral" key="Referral" />
        </ColumnGroup>
      </Table>
    </div>
  )

};

export default InvoiceTable;