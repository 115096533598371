
import React from "react";
import Header from "../../components/Header/Header";
import SideNav from "../../components/SideNav/SideNav";

const Layout = ({ children }) => {
    return<>
        <Header />
        <SideNav />
        {children}
    </>

}

export default Layout

