import React from "react";
import Input from "../../../../UIComponents/Input/Input";
import "./TumarMarker.css";
import { TestReportRightSection } from "../TestReportRightSection";
import ReportFooter from "../ReportFooter";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


function TumarMarker() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")

   const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'TUMOR MARKER')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'TUMOR MARKER'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
        reportDataFields.testReport[index]  = filterData[0]
        console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
        setInputValue(reportDataFields?.testReport[index]?.fields)
        dispatch(updateReports(reportDataFields))
    
  }
  return (
    <div>
      <div className="tumarMarker-parent">
        <div className="tumarMarker-left">
          <div className="tumarMarker-grid">
            <div className="tumarMarker-1"></div>
            <div className="tumarMarker-2 tumarMarker-pad">
              TOTAL PSA (Prostate Specific Antigen)
            </div>
            <div className="tumarMarker-3">
              <Input type='number' className={"tumarMarker-input"}  name="TOTAL PSA (Prostate Specific Antigen)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <div className="tumarMarker-4 tumarMarker-pad">ng/ml</div>
            <div className="tumarMarker-5"> </div>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="tumarMarker-foot">
        <ReportFooter />
      </div>
      <div className="tumarMarker-p">
        <table className="tumarMarker-table">
          <tr>
            <td>
              <b>AGE (years)</b>
            </td>
            <td>
              <b>REFERENCE RANGE (ng/ml)</b>
            </td>
          </tr>
          <tr>
            <td>40</td>
            <td>0.21 to 1.72</td>
          </tr>
          <tr>
            <td>40 - 49</td>
            <td>0.27 to 2.19</td>
          </tr>
          <tr>
            <td>50 - 59</td>
            <td>0.27 to 3.42.</td>
          </tr>
          <tr>
            <td>60 - 69</td>
            <td>0.22 to 6.16</td>
          </tr>
          <tr>
            <td>69</td>
            <td>0.21 to 6.77</td>
          </tr>
        </table>

        <br />
        <div className="tumarMaker-note-section">
          <p>
            <b>Note:</b>
          </p>
          <br />
          <ol>
            <li>
              This is a recommended test for detection of prostate cancer along
              with Digital RectalExamination (DRE) in males above 50 years
              ofage.
            </li>
            <li>
              False negative / positive results are observed in patients
              receiving mouse monoclonal antibodies fordiagnosis ortherapy.
            </li>
            <li>
              PSA levels may appear consistently elevated / depressed due to the
              interference by heterophilicantibodies & nonspecific
              proteinbinding.
            </li>
            <li>
              Immediate PSA testing following digital rectal examination,
              ejaculation, prostatic massage, indwelling
              catheterization,ultrasonographyandneedlebiopsyofprostateisnotrecommendedastheyfalse
              elevate levels
            </li>
          </ol>
        </div>

        <br />

        <div className="tumarMaker-clinical-section">
          <p>
            <b>Clinical Use</b>
          </p>
          <br />

          <ul>
            <li>
              An aid in the early detection of Prostate cancer when used in
              conjunction with digital rectal
              examinationinmalesmorethan50yearsofageandinthosewithtwoormoreaffectedfirstdegree
              relatives.
            </li>
            <li>Followup and management of Prostate cancerpatients.</li>
            <li>
              Detect metastatic or persistent disease in patients following
              surgical or medical treatment of Prostate Cancer.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TumarMarker;
