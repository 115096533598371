import * as types from "../../actionTypes";
import { loadMedicineList } from "./medicineList";
import { toast } from "../../../UIComponents/Toast";
import { client } from "../../../utils/axios";

export const addMedicineListLoading = () => ({
  type: types.ADD_MEDICINIE_LIST_LOADING,
});

export const addMedicineListSuccess = (data) => ({
  type: types.ADD_MEDICINIE_LIST_SUCCESS,
  payload: data,
});

export const addMedicineListError = (error) => ({
  type: types.ADD_MEDICINIE_LIST_FAIL,
  payload: error,
});

export const addMedicineList = (data) => {
  return function (dispatch) {
    dispatch(addMedicineListLoading());
    client
      .post(
        `/doctor/add-med-item`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(addMedicineListSuccess(resp.data));
        dispatch(loadMedicineList());
        toast.success("Medicine added successfully");
      })
      .catch((error) => {
        dispatch(addMedicineListError(error.response));
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };
};
