import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

const mastermedicineLoading = () => ({
  type: types.MEDICINE_MASTER_LOADING,
});

const mastermedicineSuccess = (data) => ({
  type: types.MEDICINE_MASTER_SUCCESS,
  payload: data,
});
const mastermedicineFail = (error) => ({
  type: types.MEDICINE_MASTER_FAIL,
  payload: error,
});

export const mastermedicine = (data) => {
  console.log("api call", data);
  return function (dispatch) {
    dispatch(mastermedicineLoading());
    client
      .post(
        `/admin/medicine-master-api`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(mastermedicineSuccess(resp.data));
        console.log("medicine master data", resp.data);
      })
      .catch((error) => {
        console.log("error from mastermedicine", error.response);
        dispatch(mastermedicineFail(error));
      });
  };
};
