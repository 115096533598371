import * as types from "../../actionTypes";
import toast from "react-hot-toast";
import qs from "qs";
import { patientdnd } from "./patientdnd";
import { client } from "../../../utils/axios";

const patientSubmitLoading = () => ({
  type: types.PATIENT_SUBMIT_LOADING,
});

const patientSubmitSuccess = (data) => ({
  type: types.PATIENT_SUBMIT_SUCCESS,
  payload: data,
});
const patientSubmitFail = (error) => ({
  type: types.PATIENT_SUBMIT_FAIL,
  payload: error,
});

// let url = baseUrl();

export const patientsubmit = (data) => {
  console.log("api call", data);
  return function (dispatch) {
    dispatch(patientSubmitLoading());
    client
      .post(
        `/admin/dnd`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(patientSubmitSuccess(resp.data));
        console.log("patient Submit data", resp.data);

        toast.success("Preference updated.");
        dispatch(patientdnd());
      })
      .catch((error) => {
        console.log("error from patientSubmit", error.response);
        dispatch(patientSubmitFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
