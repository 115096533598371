import * as types from '../../actionTypes';

const initialState = {
    patientList: [],
    error: {},
    selectedPatient: {},
    allPatientList: [],
    loading: false
}


export const patientListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PATIENT_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_PATIENT_LIST_LOADING_SUCCESS:
            return {
                ...state,
                patientList: action.payload,
                loading: false,
                error: {}
            }
        case types.GET_PATIENT_LIST_LOADING_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                patientList: []
            }
        case types.GET_SELECTED_OPD_PATIENT:
            return {
                ...state,
                selectedPatient: action.payload,
                loading: false
            }
        case types.GET_ALL_PATIENTS_LIST:
            return {
                ...state,
                allPatientList: action.payload,
                loading: false
            }

        default:
            return state;
    }
}