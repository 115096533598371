import React from 'react'
import MasterList from '../../components/EditMasters/MasterList'
import './EditMasters.css'
const EditMasters = () => {
  return (
    <div className="main-section">
      <div className="editmasters-section">
        <div className="edit-name" >
          <h1>EDIT MASTERS</h1>
        </div>
        <div>
          <MasterList />
        </div>

      </div>
    </div>
  )
}

export default EditMasters