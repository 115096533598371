import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveMasterListValue } from "../../redux/actions/doctorEditMaster/saveMasterList";
import Button from "../../UIComponents/Button/Button";
import Input from "../../UIComponents/Input/Input";


function AddList({ generic, setGeneric, handleOk }) {
    const genericInputHandler = (e) => {
        setGeneric(e.target.value)
    }

    return (
        <div className="add-master-list">
            <div className="add-list-input">
                <Input className={"edit-master-name-input"} placeholder="Name" value={generic} onChange={genericInputHandler} />
            </div>
            <div className="add-list-btn">
                <Button onClick={() => { console.log("fdnh"); handleOk() }} type="button" className={'add-btn-add-list'}>ADD</Button>
            </div>
        </div>
    )
}

export default AddList