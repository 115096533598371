import React from 'react'
import Input from '../../../UIComponents/Input/Input'
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea'
import './Gtt.css'
import { TestReportRightSection } from './TestReportRightSection'
import ReportFooter from './ReportFooter'
import { saveReports } from '../../../redux/actions/Diagnostics/diagnostics'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import {field,updateReports} from '../../../redux/actions/Diagnostics/diagnostics'

export const Gtt = () => {
    const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e,i) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'GTT')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'GTT'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map((item,index) => {
      if(item.particular === name && index === i){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }
  return (
    <div>
        <div className='gtt-parent'>
            <div className='gtt-left'>
                <div className='gtt-grid'>
                    <div className='gtt1 gtt-pad'>GLUCOSE TOLLERANCE TEST</div>
                    <div className='gtt2 gtt-pad'>Blood Sugar Fasting</div>
                    <div className='gtt3'><Input type='number' className={'gtt-input'} name="Blood Sugar Fasting" onChange={(e)=>onInputChange(e,0)} value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='gtt4 gtt-pad'>mg/dl</div>
                    <div className='gtt5 gtt-pad'>60-110</div>
                    <div className='gtt6'></div>
                    <span className='gtt-line'></span>
                    <div className='gtt7 gtt-pad' onClick={() => onLabelClicked("URINE FOR SUGAR")} >URINE FOR SUGAR</div>
                    <div className='gtt8 txt'><TextAreaAnt name="URINE FOR SUGAR"  onChange={(e)=>onInputChange(e,1)} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active}/></div>
                    <span className='gtt-line2'></span>
                    <div className='gtt9 gtt-pad'>After 1 Hours</div>
                    <div className='gtt10 gtt-pad'>After 1 Hours</div>
                    <div className='gtt11'><Input type='number' className={'gtt-input'} name="After 1 Hours" onChange={(e)=>onInputChange(e,2)} value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='gtt12 gtt-pad'>mg/dl</div>
                    <div className='gtt13 gtt-pad'>90-160</div>
                    <div className='gtt14'></div>
                    <span className='gtt-line3'></span>
                    <div className='gtt15 gtt-pad' onClick={() => onLabelClicked("URINE FOR SUGAR")} >URINE FOR SUGAR</div>
                    <div className='gtt16 txt'><TextAreaAnt name="URINE FOR SUGAR"  onChange={(e)=>onInputChange(e,3)} value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result}  disabled={!reportDataFields.active}/></div>
                    <span className='gtt-line4'></span>
                    <div className='gtt17 gtt-pad'>After 2 Hours </div>
                    <div className='gtt18 gtt-pad' >After 2 Hours</div>
                    <div className='gtt19'><Input type='number' className={'gtt-input'} name="After 2 Hours" onChange={(e)=>onInputChange(e,4)} value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result}  disabled={!reportDataFields.active}/></div>
                    <div className='gtt20 gtt-pad'>mg/dl</div>
                    <div className='gtt21 gtt-pad'>70-140</div>
                    <div className='gtt22'></div>
                    <span className='gtt-line5'></span>
                    <div className='gtt23 gtt-pad' onClick={() => onLabelClicked("URINE FOR SUGAR")} >URINE FOR SUGAR</div>
                    <div className='gtt24 txt'><TextAreaAnt name="URINE FOR SUGAR"  onChange={(e)=>onInputChange(e,5)} value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result}  disabled={!reportDataFields.active}/></div>
                    <span className='gtt-line6'></span>
                </div>
            </div>
            <div>
                <TestReportRightSection />
            </div>
        </div>
        <div className='gtt-foot'>
            <ReportFooter />
        </div>
        <div className='gtt-note'>
            <h4>NOTE:</h4>
            <p>For diagnosis of gestational diabeties 2 or more values should be above the expected values.</p>
        </div>
    </div>
  )
}
