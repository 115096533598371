import React from 'react'
import './Hiv.css'
import TextAreaAnt from '../../../../UIComponents/TextArea/TextArea'
import {TestReportRightSection} from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const Hiv = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;

    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'HIV')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'HIV'){
        index = i
      }
      return item
    })
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }

  return (
    <div>
        <div className='hiv-parent'>
              <div className='hiv-left'>
                  <div className='hiv-grid'>
                      <div className='hiv1 hiv-pad' onClick={() => onLabelClicked("HIV I")} >HIV I	</div>
                      <div className='hiv2 hiv-text'><TextAreaAnt name="HIV I"  onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active}/></div>
                      <span className='hiv-border'></span>
                      <div className=' hiv-pad' onClick={() => onLabelClicked("HIV II")} >HIV Ⅱ</div>
                      <div className=' hiv-text'><TextAreaAnt name="HIV II"  onChange={onInputChange} value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active}/></div>
                      <span className='hiv-border1'></span>
                  </div>
              </div>
              <div>
                  <TestReportRightSection />
              </div>
            </div>
            <div className='hiv-foot'>
                <ReportFooter />
            </div>
            <div className='hiv-method'>
              <p><b>HIV 1 & HIV Ⅱ:</b>   A negative result indicated the likely absence of detectable antibodies to HIV I & HIV Ⅱ in the specimen. A negative test result does not exclude the possibility of exposure to infection with HIV I & HIV Ⅱ. A positive test should be confirmed with Western bolt Technique</p>
            </div>
    </div>
  )
}
