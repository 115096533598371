import * as types from "../../actionTypes";
import { patientvaccine } from "./patientvaccine";
import toast from "react-hot-toast";
import { client } from "../../../utils/axios";
export const updateVaccineDate = (data) => ({
  type: types.UPDATE_VACCINE_DATE,
  payload: data,
});

const VaccineSaveLoding = () => ({
  type: types.GET_VACCINE_SAVE,
});

const VaccineSaveSuccess = (data) => ({
  type: types.GET_VACCINE_SAVE_SUCCESS,
  payload: data,
});
const VaccineSaveFail = (error) => ({
  type: types.GET_VACCINE_SAVE_FAIL,
  payload: error,
});
export const loadVaccineSave = (data) => {
  console.log("hjfdhsad", data);
  return function (dispatch) {
    dispatch(VaccineSaveLoding());

    client
      .post(
        `/visit/update-vaccination-chart`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(VaccineSaveSuccess(resp.data));
        console.log("patient Sms data", resp.data);
        dispatch(patientvaccine({ patientId: data.patientId }));
        toast.success("Vaccine chart updated");
      })
      .catch((error) => {
        console.log(error);
        dispatch(VaccineSaveFail(error));
      });
  };
};
