
import React, { useState, useEffect } from 'react'
import '../UpdateRisks/UpdateRisks.css'
import SingleSelect from '../../../UIComponents/Select/SingleSelect'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SaveOutlined, AudioFilled } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { toast } from '../../../UIComponents/Toast';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormSpeechData } from '../../../redux/actions/opd/patientreport';



const SpeechtoText = () => {
  const dispatch = useDispatch()
  const active = useSelector(state => state.opdrecord.patientreport.active)
  const speechData = useSelector(state => state.opdrecord.patientreport.speechData)
  console.log("speechdatainspeechtotext", speechData)

  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />;
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [lang, setLang] = useState('en-IN');
  const [start, setStart] = useState(false)

  const langlist = [{ id: 'en-IN', name: 'English' },
  { id: 'bn-IN', name: 'Bangla' },
  { id: 'gu-IN', name: 'Gujarati' },
  { id: 'hi-IN', name: 'Hindi' },
  { id: 'kn-IN', name: 'Kannada' },
  { id: 'ml-IN', name: 'Malyalam' },
  { id: 'mr-IN', name: 'Marathi' },
  { id: 'pa-Guru-IN', name: 'Punjabi' },
  { id: 'ta-IN', name: 'Tamil' },
  { id: 'te-IN', name: 'Telegu' },
  { id: 'ur-IN', name: 'Urdu' }
  ]


  const removeSpeechData = () => {
    dispatch(updateFormSpeechData(""))
    resetTranscript()
  }

  if (!browserSupportsSpeechRecognition) {
    toast("Browser doesn't support speech recognition.")
  }

  const startButton = () => {
    if (!start) {
      resetTranscript()
      setStart(true)
      SpeechRecognition.startListening({ language: lang, continuous: true })
    }

  }

  const stopSpeech = () => {
    setStart(false)
    SpeechRecognition.stopListening()
  }

  const hangeleTextChange = (e) => {
    dispatch(updateFormSpeechData(e.target.value))
  }

  const handleLangChange = (value) => {
    SpeechRecognition.stopListening()
    setStart(false)
    setLang(value)

  }
  const copyText = () => {
    if (speechData) {
      navigator.clipboard.writeText(speechData)
      toast.success("Text copied!")
    }

  }
  useEffect(() => {
    if (transcript)
      dispatch(updateFormSpeechData(transcript))
  }, [transcript])

  return (

    <div>
      <div className="opd-form-speech-sec">
        <div className='opd-form-speech-icon'>
          <div> {start === false ? <AudioFilled onClick={startButton} /> : <Spin indicator={antIcon} onClick={stopSpeech} />}</div>
        </div>
        <div className='opd-form-speech-select'>
          <SingleSelect data={langlist} value={lang} onChange={handleLangChange} />
        </div>
        <div><SaveOutlined className='opd-form-save' onClick={copyText} /></div>
        <div className={active ? "opd-form-speech-cross-prev" : "opd-form-speech-cross"} >
          <p onClick={removeSpeechData}>X</p>
        </div>
        <div className='opd-form-text-sec'><TextArea value={speechData} disabled={active ? true : false} placeholder="Dictation is captured here" onChange={hangeleTextChange} /></div>
      </div>
    </div>

  )
}

export default SpeechtoText