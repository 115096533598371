import { useSelector } from "react-redux";

const useDoctorId = () => {
  // Use useSelector to access the doctorId state from the Redux store
  const doctorId = useSelector((state) => state.login.doctorId);
  if (doctorId) {
    return doctorId;
  } else {
    setTimeout(() => (window.location = "/"), 2000);
  }
};

export default useDoctorId;
