import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import './TotalProtein.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function TotalProtein() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'TOTAL PROTEIN & SERUM ALBUMIN')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'TOTAL PROTEIN & SERUM ALBUMIN'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
 
  return (
    <div>
        <div className='totalProtein-parent'>
          <div className='totalProtein-left'>
            <div className='totalProtein-grid'>
                <div className='totalProtein-1'></div>
                <div className='totalProtein-2 totalProtein-pad'>PROTEIN</div>
                <div className='totalProtein-3'><Input type='number' className={'totalProtein-input'} name="PROTEIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                <div className='totalProtein-4 totalProtein-pad'>gm/dl</div>
                <div className='totalProtein-5 totalProtein-pad'>6 - 8</div>
                <span className='totalProtein-border-1'></span>

                <div className='totalProtein-6'></div>
                <div className='totalProtein-7 totalProtein-pad'>ALBUMIN</div>
                <div className='totalProtein-8'><Input type='number' className={'totalProtein-input'} name="ALBUMIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                <div className='totalProtein-9 totalProtein-pad'>gm/dl</div>
                <div className='totalProtein-10 totalProtein-pad'>3.2 - 4.5</div>
                <span className='totalProtein-border-2'></span>

                <div className='totalProtein-11'></div>
                <div className='totalProtein-12 totalProtein-pad'>GLOBULIN</div>
                <div className='totalProtein-13'><Input type='number' className={'totalProtein-input'} name="GLOBULIN" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                <div className='totalProtein-14 totalProtein-pad'>gm/dl</div>
                <div className='totalProtein-15 totalProtein-pad'>2.3 - 3.5</div>
                <span className='totalProtein-border-3'></span>

                <div className='totalProtein-16'></div>
                <div className='totalProtein-17 totalProtein-pad'>A:G RATIO</div>
                <div className='totalProtein-18'><Input type='number' className={'totalProtein-input'} name="A:G RATIO" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/></div>
                <div className='totalProtein-19 totalProtein-pad'>gm/dl</div>
                <div className='totalProtein-20 totalProtein-pad'>1.1 - 2.1</div>
                <span className='totalProtein-border-4'></span>

            </div>
          </div>
          <div>
              <TestReportRightSection />
          </div>
        </div>
        <div className='totalProtein-foot'>
            <ReportFooter />
        </div>
       
    </div>
  )
}

export default TotalProtein
