import React from 'react'
import TextAreaAnt from '../../../UIComponents/TextArea/TextArea'
import './Entrochek.css'
import { TestReportRightSection } from './TestReportRightSection'
import ReportFooter from './ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../redux/actions/Diagnostics/diagnostics'
import {field} from '../../../redux/actions/Diagnostics/diagnostics'

export const Entrochek = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")
  
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'ENTROCHEK')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item.test === 'ENTROCHEK'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0].fields.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }
  return (
    <div>
          <div className='entro-parent'>
                <div className='entro-left'>
                    <div className='entro-grid'>
                        <div className='entro1 entro-pad'  onClick={() => onLabelClicked("ENTROCHEK (TYPHI DOT IgM & IgG)")}  >ENTROCHEK (TYPHI DOT IgM & IgG)</div>
                        <div className='entro2 entro-textentro-text'><TextAreaAnt name="ENTROCHEK (TYPHI DOT IgM & IgG)" onChange={onInputChange}  value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}/></div>
                    </div>
                </div>
                <div>
                    <TestReportRightSection />
                </div>
          </div>
          <div className='entro-foot'>
              <ReportFooter />
          </div>
    </div>
  )
}
