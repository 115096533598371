import React from 'react'
import '../Iron/Iron.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'


export const ElectrolytesHari = (e) => {
  const dispatch =  useDispatch()
  const [ inputValue, setInputValue ] = useState("")
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  console.log("reportDataFields",reportDataFields);
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'Hari ELECTROLYTES')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'Hari ELECTROLYTES'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
    <div className='iron-parent'>
        <div className='iron-left'>
            <div className='iron-grid'>
                <div className='iron1 h-pad'></div>
                <div className='iron2 h-pad'>S. PHOSPHORUS (Inorganic)</div>
                <div className='iron3'><Input type='number' name="S. PHOSPHORUS (Inorganic)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className='iron4 h-pad'> mg/dl</div>
                <div className='iron5 h-pad'>2.5 to 5</div>
                <div className='iron6 h-pad'></div>
                <span className='iron-border'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>S. SODIUM</div>
                <div className=''><Input type='number' name="S. SODIUM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mEq/L</div>
                <div className=' h-pad'>137 to 155</div>
                <div className=' h-pad'></div>
                <span className='iron-border1'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>S. POTTASIUM</div>
                <div className=''><Input type='number' name="S. POTTASIUM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mEq/L</div>
                <div className=' h-pad'>3.5 to 5.5</div>
                <div className=' h-pad'></div>
                <span className='iron-border2'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>S. CHLORIDE</div>
                <div className=''><Input type='number' name="S. CHLORIDE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mEq/L</div>
                <div className=' h-pad'>98 to 110</div>
                <div className=' h-pad'></div>
                <span className='iron-border3'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>S. LITHIUM</div>
                <div className=''><Input type='number' name="S. LITHIUM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mEq/L</div>
                <div className=' h-pad'>0.6 to 1.2</div>
                <div className=' h-pad'></div>
                <span className='iron-border4'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>S. CALCIUM</div>
                <div className=''><Input type='number' name="S. CALCIUM" value={reportDataFields?.testReport?.[indexValue]?.fields?.[5]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>9 to 11</div>
                <div className=' h-pad'></div>
                <span className='iron-border5'></span>
                <div className=' h-pad'>CREATININE CLEARANCE TEST</div>
                <div className=' h-pad'>VOLUME OF URINE</div>
                <div className=''><Input type='number' name="VOLUME OF URINE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[6]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> dl/minutes</div>
                <div className=' h-pad'></div>
                <div className=' h-pad'></div>
                <span className='iron-border6'></span>
                <div className=' h-pad'>CREATININE CLEARANCE TEST</div>
                <div className=' h-pad'>PLASMA CREATININE</div>
                <div className=''><Input type='number' name="PLASMA CREATININE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[7]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mg/dl</div>
                <div className=' h-pad'></div>
                <div className=' h-pad'></div>
                <span className='iron-border7'></span>
                <div className=' h-pad'>CREATININE CLEARANCE TEST</div>
                <div className=' h-pad'>URINARY CREATININE</div>
                <div className=''><Input type='number' name="URINARY CREATININE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[8]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> mg/dl</div>
                <div className=' h-pad'></div>
                <div className=' h-pad'></div>
                <span className='iron-border8'></span>
                <div className=' h-pad'>CREATININE CLEARANCE TEST</div>
                <div className=' h-pad'>CLEARANCE RATE</div>
                <div className=''><Input type='number' name="CLEARANCE RATE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[9]?.result} disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'> ml/minute</div>
                <div className=' h-pad'></div>
                <div className=' h-pad'></div>
                <span className='iron-border9'></span>
            </div>
        </div>
            <div>
                <TestReportRightSection />
            </div>
    </div>

    <div className='iron-foot'>
        <ReportFooter />
    </div>
    <div className='irn-method'>
      <h4>Note:</h4>
      
      <p>These test have technical limitetion. Clinical Pathological interpretation is necessary <br />
in case of any discrepancy test may be repeated immediately. This Report is not for medicolegal purpose.</p>
    </div>
</div>
  )
}
