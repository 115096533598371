import React from "react";
import "./MalariaParasite.css";
import TextAreaAnt from "../../../../UIComponents/TextArea/TextArea";
import { TestReportRightSection } from "../TestReportRightSection";
import ReportFooter from "../ReportFooter";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { field,updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const MalariaParasite = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)  
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'MALARIA PARASITE')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'MALARIA PARASITE'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }
  const onLabelClicked = (value) =>{
    dispatch(field({fieldKey:value}))  }
    
  return (
    <div>
      <div className="MalariaParasite-parent">
        <div className="MalariaParasite-left">
          <div className="MalariaParasite-grid">
            <div className="MalariaParasite-1 MalariaParasite-pad" onClick={() => onLabelClicked("MP QBC")} >MP QBC </div>
            <div className="MalariaParasite-2 MalariaParasite-text">
              <TextAreaAnt name="MP QBC"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active}onChange={onInputChange} />
            </div>
            <span className="MalariaParasite-border"></span>
            <div className=" MalariaParasite-pad" onClick={() => onLabelClicked("SPECIES")} >SPECIES </div>
            <div className=" MalariaParasite-text">
              <TextAreaAnt name="SPECIES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="MalariaParasite-border1"></span>
            <div className=" MalariaParasite-pad" onClick={() => onLabelClicked("STAGE")} >STAGE</div>
            <div className=" MalariaParasite-text">
              <TextAreaAnt name="STAGE"  value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active} onChange={onInputChange} />
            </div>
            <span className="MalariaParasite-border2"></span>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="MalariaParasite-foot">
        <ReportFooter />
      </div>
      <div className="MalariaParasite-method">
          <p><b>INTERPRETATION:</b></p>
        <p>
          QBC method provides an accurate method of estimating the relative
          quantity of parasite in a secies using the * plus system* t(1+)= 1
          Parasite per QBC field
          <br />
          t(2+)= 1-10 Parasite per QBC field
          <br />
          t(3+)= 11-100 Parasite per QBC field
          <br />
          t(4+)= Than 100 Parasite per QBC field
        </p>
      </div>
    </div>
  );
};
