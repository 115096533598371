import React from 'react'
import '../Iron/Iron.css'
import  Input from '../../../../UIComponents/Input/Input'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

export const LipidProfileHari = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue) 
  const [ inputValue, setInputValue ] = useState("")
 
  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    setInputValue(value)
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'Hari LIPID PROFILE')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'Hari LIPID PROFILE'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
  filterData[0].fields =  finalFilteredData
  reportDataFields.testReport[index]  = filterData[0]
  console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
  setInputValue(reportDataFields?.testReport[index]?.fields)
  dispatch(updateReports(reportDataFields))
  }
  return (
    <div>
    <div className='iron-parent'>
        <div className='iron-left'>
            <div className='iron-grid'>
                <div className='iron1 h-pad'></div>
                <div className='iron2 h-pad'>SERUM CHOLESTEROL</div>
                <div className='iron3'><Input type='number' name="SERUM CHOLESTEROL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result}  disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className='iron4 h-pad'>mg/dl</div>
                <div className='iron5 h-pad'>150 to 250</div>
                <div className='iron6 h-pad'></div>
                <span className='iron-border'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>HDL CHOLESTEROL</div>
                <div className=''><Input type='number' name="HDL CHOLESTEROL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result}  disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>30 to 70</div>
                <div className=' h-pad'></div>
                <span className='iron-border1'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>LDL CHOLESTEROL</div>
                <div className=''><Input type='number' name="LDL CHOLESTEROL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result}  disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>75 to 150</div>
                <div className=' h-pad'></div>
                <span className='iron-border2'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>VLDL CHOLESTEROL</div>
                <div className=''><Input type='number' name="VLDL CHOLESTEROL" value={reportDataFields?.testReport?.[indexValue]?.fields?.[3]?.result}  disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>5 to 40</div>
                <div className=' h-pad'></div>
                <span className='iron-border3'></span>
                <div className=' h-pad'></div>
                <div className=' h-pad'>TRIGLYCERIDES</div>
                <div className=''><Input type='number' name="TRIGLYCERIDES" value={reportDataFields?.testReport?.[indexValue]?.fields?.[4]?.result}  disabled={!reportDataFields.active} onChange={onInputChange} className={'iron-input'} /></div>
                <div className=' h-pad'>mg/dl</div>
                <div className=' h-pad'>10 to 190</div>
                <div className=' h-pad'></div>
                <span className='iron-border4'></span>
                
            </div>
        </div>
            <div>
                <TestReportRightSection />
            </div>
    </div>

    <div className='iron-foot'>
        <ReportFooter />
    </div>
    <div className='irn-method'>
      <h4>Note:</h4>
      
      <p>These test have technical limitetion. Clinical Pathological interpretation is necessary <br />
in case of any discrepancy test may be repeated immediately. This Report is not for medicolegal purpose.</p>
    </div>
</div>
  )
}
