import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "./Layout";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isDoctorLogin } = useSelector(({ login }) => {
    return {
      isDoctorLogin: login.isDoctorLogin,
    };
  });
  console.log("isDoctorLogIn", isDoctorLogin);
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(props) =>
        isDoctorLogin ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/doctor-app/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
