import React from 'react'
import Input from '../../../../UIComponents/Input/Input'
import './ThyroidProfile.css'
import { TestReportRightSection } from '../TestReportRightSection'
import ReportFooter from '../ReportFooter'
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'

function ThyroidProfile() {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'THYROID PROFILE')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'THYROID PROFILE'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
   filterData[0].fields =  finalFilteredData
    reportDataFields.testReport[index]  = filterData[0]
    console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
    setInputValue(reportDataFields?.testReport[index]?.fields)
    dispatch(updateReports(reportDataFields))
  }

  return (
    <div>
        <div className='thyroidProfile-parent'>
          <div className='thyroidProfile-left'>
            <div className='thyroidProfile-grid'>
                <div className='thyroidProfile-1 thyroidProfile-pad'>T3, T4, TSH</div>
                <div className='thyroidProfile-2 thyroidProfile-pad'>TRI-IODO THYRONIN, (T3)</div>
                <div className='thyroidProfile-3'><Input type='number' className={'thyroidProfile-input'} name="TRI-IODO THYRONIN, (T3)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='thyroidProfile-4 thyroidProfile-pad'>ng/Ml</div>
                <div className='thyroidProfile-5 thyroidProfile-pad'>0.55 - 1.95</div>
                <span className='thyroidProfile-border-1'></span>

                <div className='thyroidProfile-6 thyroidProfile-pad'>T3, T4, TSH</div>
                <div className='thyroidProfile-7 thyroidProfile-pad'>THYROXIN, (T4)</div>
                <div className='thyroidProfile-8'><Input type='number' className={'thyroidProfile-input'}  name="THYROXIN, (T4)" value={reportDataFields?.testReport?.[indexValue]?.fields?.[1]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='thyroidProfile-9 thyroidProfile-pad'>µg/dl</div>
                <div className='thyroidProfile-10 thyroidProfile-pad'>4.6 - 9.8</div>
                <span className='thyroidProfile-border-2'></span>

                <div className='thyroidProfile-11 thyroidProfile-pad'>T3, T4, TSH</div>
                <div className='thyroidProfile-12 thyroidProfile-pad'>THYROID STIMULATING HORMONE</div>
                <div className='thyroidProfile-13'><Input type='number' className={'thyroidProfile-input'} name="THYROID STIMULATING HORMONE" value={reportDataFields?.testReport?.[indexValue]?.fields?.[2]?.result} disabled={!reportDataFields.active} onChange={onInputChange} /></div>
                <div className='thyroidProfile-14 thyroidProfile-pad'>µIU/Ml</div>
                <div className='thyroidProfile-15 thyroidProfile-pad'>0.35 - 5.50</div>
                <span className='thyroidProfile-border-3'></span>

                

            </div>
          </div>
          <div>
              <TestReportRightSection />
          </div>
        </div>
        <div className='thyroidProfile-foot'>
            <ReportFooter />
        </div>
        <div className='thyroidProfile-p'>
          <h4>Note:</h4>
          <p>The test is performed using VIDAS (ELFA) methods.</p>
          
        </div>
       
    </div>
  )
}

export default ThyroidProfile
