import * as types from '../../actionTypes';

const initialState = {
    alternateNum:[],
    error:{},
    loading:false
}

export const alternateNumReducer = (state = initialState, action) => {
    switch(action.type){
        case types.ALTERNATE_NUM_LOADING:
            return{
                ...state,
                loading:true
            }
        case types.ALTERNATE_NUM_SUCCESS:
            return{
                ...state,
                alternateNum: action.payload,
                loading:false,
                error:{}
            }
        case types.ALTERNATE_NUM_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                alternateNum:[]
            }
        default:
            return state
    }
}