import * as types from "../actionTypes";

const initialState = {
  adminLoginInfo: [],
  isAdminLogin: false,
  error: {},
  loading: false,
};

export const adminLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADMIN_LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        adminLoginInfo: action.payload,
        isAdminLogin: true,
        loading: false,
        error: {},
      };
    case types.GET_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        adminLoginInfo: [],
      };
    case types.RESET_ADMIN_DATA:
      return {
        ...state,
        adminLoginInfo: action.payload,
        loading: false,
      };
    case types.UPDATE_ADMIN_STATUS:
      return {
        ...state,
        isAdminLogin: false,
        loading: false,
      };
    default:
      return state;
  }
};
