import React from 'react'
import AdminTabs from '../../components/Admin/AdminTabs'

const Admin = () => {
    return (
        <div>
            <AdminTabs />

        </div>
    )
}

export default Admin