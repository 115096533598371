import * as types from "../../actionTypes";
import { client } from "../../../utils/axios";

const patientDndLoading = () => ({
  type: types.PATIENT_DND_LOADING,
});

const patientDndSuccess = (data) => ({
  type: types.PATIENT_DND_SUCCESS,
  payload: data,
});
const patientDndFail = (error) => ({
  type: types.PATIENT_DND_FAIL,
  payload: error,
});

// let url = baseUrl();
export const patientdnd = (data) => {
  console.log("api call", data);
  return function (dispatch) {
    dispatch(patientDndLoading());
    client
      .get(
        `/admin/dnd`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(patientDndSuccess(resp.data));
        console.log("patient DND data", resp.data);
      })
      .catch((error) => {
        console.log("error from patientDnd", error.response);
        dispatch(patientDndFail(error));
      });
  };
};
