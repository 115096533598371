import * as types from "../../actionTypes";
import toast from "react-hot-toast";
import { client } from "../../../utils/axios";

const doctorSignUpLoading = () => ({
  type: types.CREATE_DOCTOR_LOADING,
});

const doctorSignUpSuccess = (data) => ({
  type: types.CREATE_DOCTOR_SUCCESS,
  payload: data,
});

const doctorSignUpFail = (error) => ({
  type: types.CREATE_DOCTOR_FAIL,
  payload: error,
});

// export const docListOn = (payload) => ({
//     type: types.DOC_LIST,
//     payload
// })

//////////////Doctor List\\\\\\\\\\\\\\\

const doctorListLoading = () => ({
  type: types.DOCTOR_LIST_LOADING,
});

const doctorListSuccess = (data) => ({
  type: types.DOCTOR_LIST_SUCCESS,
  payload: data,
});

const doctorListFail = (error) => ({
  type: types.DOCTOR_LIST_FAIL,
  payload: error,
});

//////////////// ASSISTANT \\\\\\\\\\\\\\\

const assistantDocLoading = () => ({
  type: types.ASSISTANT_TO_DOC_LOADING,
});

const assistantDocSuccess = (data) => ({
  type: types.ASSISTANT_TO_DOC_SUCCESS,
  payload: data,
});

const assistantDocFail = (error) => ({
  type: types.ASSISTANT_TO_DOC_FAIL,
  payload: error,
});

////////// ASSISTANT MAPPED \\\\\\\\\\\\\

const assistantMapLoading = () => ({
  type: types.ASSISTANT_MAPPED_LOADING,
});

const assistantMapSuccess = (data) => ({
  type: types.ASSISTANT_MAPPED_SUCCESS,
  payload: data,
});

const assistantMapFail = (error) => ({
  type: types.ASSISTANT_MAPPED_FAIL,
  payload: error,
});

////////////DOC INFO\\\\\\\\\\\\\\\

const docInfoLoading = () => ({
  type: types.DOCTOR_INFO_LOADING,
});

const docInfoSuccess = (data) => ({
  type: types.DOCTOR_INFO_SUCCESS,
  payload: data,
});

const docInfoFail = (error) => ({
  type: types.DOCTOR_INFO_FAIL,
  payload: error,
});

//////////INITIALLIZE DOCTOR\\\\\\\\\\\\

const docInitializeLoading = () => ({
  type: types.DOCTOR_INITIALIZE_LOADING,
});

const docInitializeSuccess = (data) => ({
  type: types.DOCTOR_INITIALIZE_SUCCESS,
  payload: data,
});

const docInitializeFail = (error) => ({
  type: types.DOCTOR_INITIALIZE_FAIL,
  payload: error,
});

////////////EDIT DOC\\\\\\\\\\\

export const editDoc = (payload) => ({
  type: types.DOC_EDIT,
  payload,
});

///////////INPUT DOC\\\\\\\\\\\

export const inputDoc = (payload) => ({
  type: types.DOC_INPUT,
  payload,
});
export const clearDoc = () => ({
  type: types.DOC_CLEAR,
});

export const rendDoc = (payload) => ({
  type: types.DOC_REND,
  payload,
});

////////EDIT DOCTOR\\\\\\\\\\

const editDoctorLoading = () => ({
  type: types.EDIT_DOCTOR_LOADING,
});

const editDoctorSuccess = (data) => ({
  type: types.EDIT_DOCTOR_SUCCESS,
  payload: data,
});

const editDoctorFail = (error) => ({
  type: types.EDIT_DOCTOR_FAIL,
  payload: error,
});
export const doctorSignUp = (data) => {
  return function (dispatch) {
    dispatch(doctorSignUpLoading());
    client
      .post(
        "/doctor/signup",
        data
        // , {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(doctorSignUpSuccess(resp.data));
        toast.success("Doctor created successfully");
        console.log("naaaa", resp.data);
        dispatch(DoctorList());
      })
      .catch((error) => {
        dispatch(doctorSignUpFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const DoctorList = () => {
  return function (dispatch) {
    dispatch(doctorListLoading());
    client
      .get(
        `/admin/initialize-doctor`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(doctorListSuccess(resp.data));
        console.log("doctor-list", resp.data);
      })
      .catch((error) => {
        dispatch(doctorListFail(error));
        console.log("error initiliazed");
      });
  };
};

export const assistantDoc = () => {
  return function (dispatch) {
    dispatch(assistantDocLoading());
    client
      .get(
        `/admin/add-assistant`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(assistantDocSuccess(resp.data));
        console.log("assistant", resp.data);
      })
      .catch((error) => {
        dispatch(assistantDocFail(error));
        console.log("error for assistant");
      });
  };
};

export const assistantMap = (data) => {
  return function (dispatch) {
    dispatch(assistantMapLoading());
    client
      .post(
        `/admin/add-assistant`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(assistantMapSuccess(resp.data));
        toast.success("Assistant Mapped");
        console.log("Assistant Mapping", resp.data);
      })
      .catch((error) => {
        dispatch(assistantMapFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const doctorInformation = (id) => {
  return function (dispatch) {
    dispatch(docInfoLoading());
    client
      .get(
        `/admin/get-doctor-details/${id}`
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(docInfoSuccess(resp.data));
        console.log("doctor information", resp.data);
      })
      .catch((error) => {
        dispatch(docInfoFail(error));
      });
  };
};

export const initializeDoc = (data) => {
  return function (dispatch) {
    dispatch(docInitializeLoading());
    client
      .post(
        `/admin/initialize-doctor`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(docInitializeSuccess(resp.data));
        console.log("doctor initialized", resp.data);

        if (resp.data.message === "Cannot re-initialize Doctor") {
          toast.error(resp.data.message);
        } else {
          toast.success(resp.data.message);
        }
      })
      .catch((error) => {
        dispatch(docInitializeFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};

export const doctorEditing = (data) => {
  return function (dispatch) {
    dispatch(editDoctorLoading());
    client
      .post(
        `/admin/edit-doctor`,
        data
        // {
        //   headers: { Authorization: `Bearer ${secure_token}` },
        // }
      )
      .then((resp) => {
        dispatch(editDoctorSuccess(resp.data));
        toast.success(resp.data.message);
        dispatch(doctorInformation(data.id));
        dispatch(DoctorList());
        console.log("edit Doc", resp.data);
      })
      .catch((error) => {
        dispatch(editDoctorFail(error));
        toast.error(`${error.response.data.message}`);
      });
  };
};
