import React, { useState, useEffect } from "react";
import Button from "../../UIComponents/Button/Button";
import Table from "../../UIComponents/Table/Table";
import "./Medicine.css";
import deleteIcon from "../../images/Delete-red.svg";
import editIcon from "../../images/Edit-pencil.svg";
import Input from "../../UIComponents/Input/Input";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import UpdateImage from "../../images/Update-button.svg";
import { loadMedicineList } from "../../redux/actions/doctorEditMedicine/medicineList";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { getSingleMedicine } from "../../redux/actions/doctorEditMedicine/medicineList";
import InputField from "../../UIComponents/Input/Input";
import { updateMedicineList } from "../../redux/actions/doctorEditMedicine/updateMedicineList";
import { deleteMedicineList } from "../../redux/actions/doctorEditMedicine/deleteMedicineList";
import { addMedicineList } from "../../redux/actions/doctorEditMedicine/addMedicineList";

const Medicine = () => {
  let dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState();
  const [search, setSearch] = useState();
  const [medName, setMedName] = useState();
  const [medGeneric, setMedGeneric] = useState([]);
  const editMedicineList = useSelector(
    (state) => state.editMedicine.editMedicineInfo.editMedicineList
  );
  console.log("editMedicineList", editMedicineList);
  const singleMedicine = useSelector(
    (state) => state.editMedicine.editMedicineInfo.singleMedicine
  );
  console.log("singleMedicine", singleMedicine);
  const [singleMedicineArray, setSingleMedicineArray] = useState();

  const columns = [
    {
      title: "MEDICINE NAME",
      dataIndex: "name",
      width: "24%",
    },
    {
      title: "GENERIC NAME",
      dataIndex: "generic",
      width: "60%",
    },

    {
      title: "",
      dataIndex: "",
      key: "",
      render: (e) => (
        <div className="medicine-table-icons">
          <img
            src={editIcon}
            className="medicine-table-edit"
            alt="edit"
            onClick={() => editShowModal(e._id)}
          />
          <img
            className="medicine-table-delete"
            src={deleteIcon}
            alt="delete"
            onClick={() => deleteMedicine(e._id)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(loadMedicineList());
  }, []);

  const updatedMedicine = {
    item: {
      _id: singleMedicine?._id,
      name: singleMedicine?.name,
      type: null,
      generic: singleMedicine?.generic?.length ? singleMedicine?.generic : [],
    },
  };

  console.log("updatedMedicine", singleMedicine);

  useEffect(() => {
    dispatch(
      getSingleMedicine(editMedicineList.find((item) => item._id === editId))
    );
  }, [editId]);

  const editShowModal = (id) => {
    setEditId(id);
    setEditModal(true);
  };

  const deleteMedicine = (id) => {
    const deleted = {
      item: {
        _id: id,
      },
    };
    dispatch(deleteMedicineList(deleted));
  };

  const handleOk = () => {
    setEditModal(false);
  };

  const handleCancel = () => {
    setEditModal(false);
  };

  const editMedicineHandler = (e) => {
    dispatch(
      getSingleMedicine({
        ...singleMedicine,
        generic: e.target.value?.split(","),
      })
    );
  };

  const updateHandler = (event) => {
    // if (event.key === 'Enter') {
    dispatch(updateMedicineList(updatedMedicine));
    setEditModal(false);
    // }
  };

  // useEffect(() => {
  //   setSingleMedicineArray(editMedicineList)
  // }, [editMedicineList])

  const searchHandler = (e) => {
    console.log(
      "searched value in edit medicine",
      e.target.value,
      editMedicineList
    );
    setSearch(e.target.value);
    if (e.target.value) {
      const singleMedicineObj =
        editMedicineList?.length &&
        editMedicineList.filter(
          (item) =>
            (typeof item?.name === "string" &&
              item?.name
                ?.toUpperCase()
                .indexOf(e?.target?.value?.toUpperCase()) === 0) ||
            (item.generic?.length &&
              typeof item.generic[0] === "string" &&
              item.generic[0]
                ?.toUpperCase()
                .indexOf(e.target.value?.toUpperCase()) === 0)
        );
      // console.log("getting filtered data", Object.values(item.name[0]).join('').toLowerCase())
      // return Object.values(item).join('').toLowerCase().includes(e.target.value.toLowerCase())
      // })
      console.log("singleMedicineObj", singleMedicineObj);
      setSingleMedicineArray(singleMedicineObj);
    } else {
      console.log(editMedicineList, "editMedicineList");
      setSingleMedicineArray(editMedicineList);
    }
  };

  const nameChangeHandler = (e) => {
    setMedName(e.target.value);
  };

  const genericChangeHandler = (e) => {
    setMedGeneric(e.target.value.split(","));
  };

  const addHandler = () => {
    const added = {
      name: medName,
      generic: medGeneric?.length ? medGeneric : [],
    };
    dispatch(addMedicineList(added));
    setMedName("");
    setMedGeneric("");
  };

  const onSearchPress = (event) => {
    // if (event.key === "Enter") {
    //   console.log('entered')
    //   setSearch('')
    // }
  };

  useEffect(() => {
    // setSingleMedicineArray(editMedicineList)
    if (editMedicineList?.length) {
      let array = [];
      const abcd = editMedicineList.map((item) => {
        array.push({
          ...item,
          generic: item?.generic?.length ? item?.generic?.toString() : [],
        });
      });
      setSingleMedicineArray(array);
    }
  }, [editMedicineList]);

  return (
    <div>
      <div>
        <div className="medicine-top-sec">
          <p>Add / Edit / Delete Medicine </p>
          <div className="add-edit">
            <Input
              className={"medicine-input-cls"}
              placeholder="Search"
              type="search"
              value={search}
              suffix={<SearchOutlined />}
              onChange={searchHandler}
              onKeyPress={onSearchPress}
            />
            <Input
              className={"medicine-input-cls"}
              placeholder="Name"
              value={medName}
              onChange={nameChangeHandler}
            />
            <Input
              className={"generic-search"}
              placeholder="Generic"
              value={medGeneric}
              onChange={genericChangeHandler}
            />
            <Button className={"medicine-add-b"} onClick={addHandler}>
              ADD{" "}
            </Button>
            <div>
              <Button className="edit-update-button">
                <img src={UpdateImage} alt="" />
                UPDATE{" "}
              </Button>
            </div>
          </div>
          {/* <span className='red-message'> once modified press update button</span> */}
        </div>
        <div className="medicine-table-sec">
          <Table
            className="tableCSS"
            columns={columns}
            data={singleMedicineArray}
            pagination={false}
            scroll={{
              y: 700,
            }}
          />
        </div>
      </div>
      <div>
        <Modal
          visible={editModal}
          onOk={handleOk}
          onCancel={handleCancel}
          className="edit-medicine-modal-div"
        >
          <h2>Edit generic</h2>
          <div className="edit-medicine-div">
            <InputField
              value={singleMedicine?.generic}
              onChange={editMedicineHandler}
            />
          </div>
          <div className="edit-medicine-update-btn">
            <Button onClick={updateHandler}>Update</Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Medicine;
