import React from "react";
import "./Typhi.css";
import TextAreaAnt from "../../../../UIComponents/TextArea/TextArea";
import { TestReportRightSection } from "../TestReportRightSection";
import ReportFooter from "../ReportFooter";
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'
import { updateReports } from '../../../../redux/actions/Diagnostics/diagnostics'



export const Typhi = () => {
  const dispatch =  useDispatch()
  const reportDataFields = useSelector(state => state?.diagnosticsInvoiceSummary?.getdiagnostic)
  const indexValue = useSelector(state => state?.diagnosticsInvoiceSummary?.indexValue)
  const [ inputValue, setInputValue ] = useState("")

  const onInputChange = (e) => {
    console.log("e",e.target);
    const { value, name } = e.target;
    const filterData = reportDataFields?.testReport?.filter(item => item?.test === 'TYPHI-DOT')
    let index = 0
    reportDataFields?.testReport?.map((item, i) => {
      if(item?.test === 'TYPHI-DOT'){
        index = i
      }
      return item
    })
    console.log("index",index);
    const finalFilteredData = filterData[0]?.fields?.map(item => {
      if(item.particular === name){
        item.result = value
      }
      return item
    })
    filterData[0].fields =  finalFilteredData
        reportDataFields.testReport[index]  = filterData[0]
        console.log("knklenknek",reportDataFields?.testReport[index]?.fields);
        setInputValue(reportDataFields?.testReport[index]?.fields)
        dispatch(updateReports(reportDataFields))
  }
 
  return (
    <div>
      <div className="Typhi-parent">
        <div className="Typhi-left">
          <div className="Typhi-grid">
            <div className="Typhi-1 Typhi-pad">TYPHI-DOT</div>
            <div className="Typhi-2 Typhi-text">
              <TextAreaAnt   name="TYPHI-DOT" value={reportDataFields?.testReport?.[indexValue]?.fields?.[0]?.result} disabled={!reportDataFields.active} onChange={onInputChange}/>
            </div>
            <span className="Typhi-border"></span>
          </div>
        </div>
        <div>
          <TestReportRightSection />
        </div>
      </div>
      <div className="Typhi-foot">
        <ReportFooter />
      </div>
      <div className="Typhi-method">
        <p>
          <b>Method:</b>
        </p>
        <p>RAPID TEST</p>

        <br />
        <p>
          <b>Kit Used:</b>
        </p>
        <p>TNI WHOLE BLOOD/SERUM CASETTE TEST</p>
      </div>
    </div>
  );
};
