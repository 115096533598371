import React from 'react'
import { useEffect } from "react";
import TuiImageEditor from "tui-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";

import "tui-color-picker/dist/tui-color-picker.css";
import { patientHistory, vaultImagesData } from '../../../redux/actions/opd/patienthistory';
import { useDispatch, useSelector } from 'react-redux';
// import Compressor from 'compressorjs';


const ImageEditor = (props) => {
  const rootEl = React.useRef(null);

  const visitInfo = useSelector(state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo)
  console.log("visit", visitInfo);
  const patientHistoryData = useSelector(state => state.opdrecord.patientHistory.patientHistoryData)
  console.log("image", patientHistoryData);

  let dispatch = useDispatch()
  let imageEditorInst = null;

  useEffect(() => {
    const imageEditorInst = new TuiImageEditor(rootEl.current, {
      ...props
    });
    rootEl.current.addEventListener('change', (e) => {
      props.onChange(e);
    })
  }, []); 

  const tempImage = new Image()
  tempImage.crossOrigin = "Anonymous"
  console.log("tempImage.src", tempImage.crossOrigin)

  useEffect(() => {
    return () => {
      rootEl.current.removeEventListener('change');
      imageEditorInst?.destroy();
      imageEditorInst = null;
    }
  }, []);

  useEffect(() => {
    if (patientHistoryData.message === 'File added to visit') {
      dispatch(vaultImagesData(visitInfo?._id))
    }

  }, [patientHistoryData])

  const handleImageSaveClick = () => {
    console.log("On click save");
    console.log(rootEl.current);
    const canvas = document.getElementsByClassName("upper-canvas")[0];
    const dataURL = canvas.toDataURL();
    // let reader = new FileReader();
    // reader.readAsDataURL(dataURL);
    // reader.onload = (innfile) => {
    //   let fileSpl = innfile.target.result.split("base64,");
    //   const resBinary = { resumeBinary: fileSpl[1] };
    //   console.log("dataURL", dataURL);
      let formData = new FormData()
      formData.append('files', dataURL)
      formData.append('visitId', visitInfo?._id)
      if (formData) {

        dispatch(patientHistory(formData))
      }
    }
      
    // };


  // }


  console.log("ghjj", imageEditorInst, rootEl);
  return (
    <>
      <div ref={rootEl} />
    </>
  );
}
export default ImageEditor;