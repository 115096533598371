import React from 'react'
import { useSelector } from 'react-redux'
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment';
const ViewAudio = () => {
    const opdAudioList = useSelector(state => state.opdrecord.patientaudio.getAudio.audio)

    return (
        <div className="audio-container">
            <h3 className="audio-header">AUDIO</h3>

            <div className="audio-listing">
                <h4>Past Notes</h4>
                {opdAudioList?.map(item => (
                    <>
                        <p>{moment(item.visitStart).format('YYYY-MM-DD')}</p>
                        <ReactAudioPlayer src={item.url} controls />
                    </>

                ))}

            </div>
        </div>
    )
}

export default ViewAudio
