import React, { useEffect, useState } from 'react'
import Input from '../../../UIComponents/Input/Input'
import adminLogo from '../../../images/admin-login.png'
import { useDispatch, useSelector } from 'react-redux'
import './AdminLoginPage.css'
import { useHistory } from 'react-router-dom'
import { adminLogin } from '../../../redux/actions/adminlogin'
import { myDomainData } from '../../../redux/actions/login'

const AdminLoginPage = () => {

  const dispatch = useDispatch();
  
  const history = useHistory()
  const adminSuccess = useSelector(state=>state.adminLogin.adminLoginInfo)
  console.log("selector",adminSuccess);
  
  const [mobile, setMobile] = useState("")
  const [passwordL, setPassword] = useState("")

  const mobileOnchange = (e) => {
    console.log("checking number",e.target.value)
    setMobile(e.target.value)
  }

  const passwordOnchange = (e) => {
    setPassword(e.target.value)
    console.log("checking password",e.target.value)
  }

  const adminLoginSubmit = (e) => {
    e.preventDefault()
    dispatch(adminLogin(adminData)) 
  }

  const adminData = {
    mobile : mobile,
    password: passwordL
  }


useEffect(()=>{
  if (adminSuccess?.success===true) {
    history.push("/admin")
  }
},[adminSuccess])

useEffect(() => {

  dispatch(myDomainData())
},[])

  return (
    <div className='admin-login-container'>
      <div className='login-box'>
        <div >
          <img className='admin-logo' src={adminLogo} alt="admin-login-logo" />
        </div>
        <div>
            <div className='login-inputfield'>
              <label>Mobile Number</label>
              <Input type="number" placeholder="Mobile Number" value={mobile} onChange={mobileOnchange}/>
            </div>
            <div className='login-inputfield'>
              <label>Password</label>
              <Input type="password" placeholder="Password" value={passwordL} onChange={passwordOnchange} />
            </div>
            <div className='login-button'>
              <button onClick={adminLoginSubmit}>LOG IN</button>
            </div>
            <div className='login-forgot-password'>
              <a href="">Forgot Password?</a>
            </div>

        </div>
      </div>
    </div>
  )
}

export default AdminLoginPage