import * as types from '../../actionTypes';

const initialState = {
    deleteAppointment: [],
    error: {},
    loading: false,
}



export  const deleteAppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_APPOINTMENT_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.DELETE_APPOINTMENT_SUCCESS:
            console.log("actionddddd",action.payload)
            return {
                ...state,
                deleteAppointment: action.payload,
                loading: false,
                error: {}
            }
        case types.DELETE_APPOINTMENT_FAIL:
            return{
                ...state,
                error: action.payload,
                loading: false,
                deleteAppointment: []
            }
        
        default:
            return state;
    }
}