import React, { useRef, useState, useEffect } from "react";
import "./ProfileStyle.css";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import Button from "../../../UIComponents/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  doctorProfileThemeApply,
  uploadDoctorProfileImage,
} from "../../../redux/actions/profile";
import doctorLogo from "../../../images/defaultImage.jpg";
import InputField from "../../../UIComponents/Input/Input";
import { baseUrl } from "../../../utils/Helper";
import uuid from "react-uuid";
import { toast } from "../../../UIComponents/Toast";
import useDoctorId from "../../../utils/get-doctor-id";

const profileThemeData = [
  {
    id: "index",
    name: "default",
  },
  {
    id: "indexblue",
    name: "Blue",
  },
  {
    id: "indexBrown",
    name: "Brown",
  },
  {
    id: "indexCharcoal",
    name: "Charcoal",
  },
  {
    id: "indexGreen",
    name: "Green",
  },
  {
    id: "indexMagenta",
    name: "Magenta",
  },
  {
    id: "indexMaroon",
    name: "Maroon",
  },
  {
    id: "indexNavy",
    name: "Navy",
  },
  {
    id: "indexOlive",
    name: "Olive",
  },
  {
    id: "indexOrange",
    name: "Orange",
  },
  {
    id: "indexPurple",
    name: "Purple",
  },
  {
    id: "indexRed",
    name: "Red",
  },
];

const ProfileStyle = () => {
  const imageRef = useRef();
  const dispatch = useDispatch();
  let url = baseUrl();
  const doctorId = useDoctorId();
  console.log("doctorId", doctorId);
  const myDomainInfo = useSelector((state) => state.login.myDomainInfo);
  console.log("myDomainInfo", myDomainInfo);

  const [style, setStyle] = useState("");

  const onThemeChange = (value) => {
    setStyle(value);
  };
  const onUpdateStyleSubmit = () => {
    dispatch(doctorProfileThemeApply({ style: style, tagline: null }));
  };
  const changeImageHandler = (e) => {
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      const fileExtension = e.target.files[i].name.split(".").pop();
      console.log("fileExtension", fileExtension);
      if (
        fileExtension === "pdf" ||
        fileExtension === "docx" ||
        fileExtension === "csv" ||
        fileExtension === "zip"
      ) {
        toast.error("Please upload image only");
        // e.target.value = null
      } else {
        formData.append("files", e.target.files[i], e.target.files[i].name);
        formData.append("doctorId", doctorId);

        dispatch(uploadDoctorProfileImage(formData));
        e.target.value = null;
      }
    }
  };

  return (
    <div className="profile-details-sec">
      <div className="pro-detail-heading">
        <h4>Profile Style</h4>
        <h1>-</h1>
      </div>
      <div className="profile-details-left">
        <div className="each-profile-style-record">
          <p></p>
          <div>
            <Button onClick={() => imageRef.current.click()}>
              UPLOAD IMAGE
            </Button>
            <input
              type="file"
              onChange={changeImageHandler}
              multiple
              ref={imageRef}
              hidden
            />
          </div>
        </div>
        <div className="each-profile-style-record">
          <p>Image</p>
          <div className="profile-style-img-sec">
            {console.log("myDomainInfo in jsx", myDomainInfo)}
            {myDomainInfo === null ? (
              <img
                className="profile-style-image"
                src={doctorLogo}
                alt="profileImage"
              />
            ) : (
              <img
                className="profile-style-image"
                src={`${url}img/profile/${
                  myDomainInfo?.subdomain
                }.jpg?lastmod=${uuid()}`}
              />
            )}
          </div>
        </div>
        <div className="each-profile-style-record">
          <p>Theme</p>
          <diV style={{ width: "60%" }}>
            <SingleSelect
              data={profileThemeData}
              width="193px"
              placeholder="Style"
              onChange={onThemeChange}
            />
          </diV>
        </div>
        <div className="each-profile-style-record">
          <p>Tagline</p>
          <div style={{ width: "60%" }}>
            <InputField type="text" />
          </div>
        </div>
        <div className="update-style-btn-sec">
          <Button className="save-profile-btn" onClick={onUpdateStyleSubmit}>
            UPDATE STYLE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileStyle;
